import React from 'react';
import cx from 'classnames';
import { Modal } from 'react-bootstrap';

import Button from '../../atoms/Button';
import Icon from '../../atoms/Icon';
import Text from '../../atoms/Text';

import styles from './AlertModal.module.scss';
import usePresenter from './AlertModal.presenter';
import getProps from './AlertModal.props';
import { AlertModalCombinedProps } from './types';


const AlertModal: React.FC<AlertModalCombinedProps> = (props) => {
  const {
    className,
    classes,
    closeButton,
    icon,
    title,
    message,
    secondaryButton,
    primaryButton,
    open, // MANUAL OVERRIDE
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.alertModal;

  return (
    <Modal
      className={styles.modal}
      dialogClassName={styles.modalDialog}
      contentClassName={styles.modalContent}
      backdrop={'static'}
      show={open}
    >
      <div className={cx(currentStyle, className)}>
        <div className={cx(styles.topContent, classes?.topContent)}>
          <Button
            className={cx(styles.closeButton, classes?.closeButton)}
            {...internalProps.closeButton}
            {...closeButton}/>
          <div className={cx(styles.content, classes?.content)}>
            <Icon
              className={cx(styles.icon, classes?.icon)}
              {...internalProps.icon}
              {...icon}/>
            <Text
              className={cx(styles.title, classes?.title)}
              {...internalProps.title}
              {...title}/>
          </div>
        </div>
        <div className={cx(styles.bottomContent, classes?.bottomContent)}>
          <Text
            className={cx(styles.message, classes?.message)}
            {...internalProps.message}
            {...message}/>
          <div className={cx(styles.buttonPair, classes?.buttonPair)}>
            <Button
              className={cx(styles.secondaryButton, classes?.secondaryButton)}
              {...internalProps.secondaryButton}
              {...secondaryButton}/>
            <Button
              className={cx(styles.primaryButton, classes?.primaryButton)}
              {...internalProps.primaryButton}
              {...primaryButton}/>
          </div>
        </div>
      </div>
    </Modal>

  );
};

export default AlertModal;


import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { C1_BROKER_ID, REWARDS_ONLY_TAG } from '../../../lib/constants';
import {
  addQueryParam,
  formatCashPrice,
  getQueryParams,
  getTicketListingTranslationKey,
  getUnitDisplaySettings,
  handleAmountFormattingString,
  handleDateFormat,
  handleDecimalValuesForDisplay,
  handleDisplayUnitName,
  handleLocation,
  handleTimeFormat,
  handleVariableImage,
  isNotNullOrUndefined,
  isNYEvent,
  isVividEvent,
  RewardUnits,
  shouldShowSplitPaymentPrice,
} from '../../../lib/util';
import { useAnalyticsManager } from '../../../modules/analytics/useAnalyticsManager';
import { trackErrorEvent } from '../../../modules/analytics/util';
import { ApiError } from '../../../modules/error/types';
import type { DropdownItem } from '../../atoms/DropdownSelect/types';
import type { IconTextDescriptionCombinedProps } from '../../molecules/IconTextDescription/types';
import type { TicketAlertModalProps, TicketAlertModalTitleMessage } from '../../organisms/TicketAlertModal/types';
import useInteractor from './PrecheckoutBlock.interactor';
import { calculateListingDetailsPrices, checkIsEventInStates, determineDeliveryOption, findPreviousPurchases, formatPoints, getEventPurchaseLimit } from './PrecheckoutBlock.utils';
import type { PrecheckoutBlockProps } from './types';

const usePresenter = (props: PrecheckoutBlockProps): PrecheckoutBlockProps => {
  const { account, fetchOrders } = useInteractor(props);
  const {
    listing,
    isExclusive,
    breakdown,
    image,
    onBackButtonClick,
  } = props;
  const showSplitPaymentPrice =
    listing && shouldShowSplitPaymentPrice(listing.event);
  const isRewardsOnlyEvent = listing?.event.tags?.includes(REWARDS_ONLY_TAG);
  const { t } = useTranslation();
  const { search } = useLocation();
  const history = useHistory();
  const query = getQueryParams(search);
  const { trackEvent } = useAnalyticsManager();

  const { eventId } = useParams<{ eventId: string; }>();
  const [isTicketAlertModalOpen, setIsTicketAlertModalOpen] = useState(false);
  const hasCp1BrokerId = listing?.listing.broker_id === C1_BROKER_ID;
  const purchaseLimit = getEventPurchaseLimit(listing?.event.tags || []);
  const isMiles =
    account?.loyalty_program?.loyalty_unit_name.toLowerCase() ===
    RewardUnits.MILES.toLowerCase();
  const isVividEventListing = isVividEvent(listing);
  const isEventInCaliforniaOrMaryland: boolean = checkIsEventInStates(listing?.event, ['CA', 'MD']);
  const serviceFee: number | undefined = breakdown?.service_fee;

  const defaultTicketAlertModalTitleMessage: TicketAlertModalTitleMessage = {
    title: t('insufficientRewards.title'),
    message: t('insufficientRewards.message'),
  };
  const purchaseLimitModalTitleMessage: TicketAlertModalTitleMessage = {
    title: t('purchaseLimitError.title'),
    message: t('purchaseLimitError.message', {
      purchaseLimit,
    }),
  };
  const [ticketAlertModalTitleMessage, setTicketAlertModalTitleMessage] =
    useState({
      title: '',
      message: '',
    });
  const rewardType =
    account?.loyalty_program?.loyalty_unit_name.toLowerCase();
  const { rewardSign, useDecimals } = getUnitDisplaySettings(rewardType);
  // We need to show Rewards when there is no SPLIT_PAYMENT or CREDIT_CARD tag OR it is an Rewards only event
  const showAip = breakdown && isVividEventListing && !isExclusive;
  const { cashPrice, loyaltyPrice, showAllInPrice } = calculateListingDetailsPrices(listing, showAip);
  const formattedCashPrice = formatCashPrice(cashPrice);
  const formattedLoyaltyPrice = handleDecimalValuesForDisplay(handleAmountFormattingString(loyaltyPrice), useDecimals);
  const pointsValue = !showSplitPaymentPrice || isRewardsOnlyEvent ? formattedLoyaltyPrice : formattedCashPrice;
  const sign = showSplitPaymentPrice ? '$' : rewardSign;
  const [previousTickets, setPreviousTickets] = useState(0);
  const pointsDisplayValue = `${sign}${pointsValue}`;
  const quantitySelection = {
    value: String(query.quantity),
  };
  useEffect(() => {
    const doFetchEvents = async () => {
      try {
        if (fetchOrders && account && purchaseLimit) {
          const response = await fetchOrders();
          setPreviousTickets(findPreviousPurchases(response.orders, eventId));
        }
      } catch (error) {
        if (error && ApiError.isApiError(error)) {
          trackErrorEvent(
            trackEvent,
            error.code,
            error.message,
          );
        }
        // TODO
      }
    };
    void doFetchEvents();
  }, [fetchOrders]);

  useEffect(() => {
    trackEvent('select_ticket', {
      ecommerce: {
        content_type: 'select_ticket',
        items: listing,
      },
    });
  }, []);

  const updateQuantity = (quantity?: string | undefined) => {
    const params = addQueryParam(history.location.search, {
      quantity: quantity || '',
    });
    if (params) {
      history.push({
        search: params,
      });
    }
  };

  const handleCheckoutEvent = () => {
    if (listing && account) {
      const isLoyaltyPointLimitCrossed =
        isRewardsOnlyEvent &&
        listing?.listing.loyalty_price.price_per * +quantitySelection.value >
        account.loyalty_program.number_of_units;
      const isPurchaseLimitCrossed =
        purchaseLimit < Number(query.quantity) + previousTickets;
      if (isPurchaseLimitCrossed) {
        setTicketAlertModalTitleMessage(purchaseLimitModalTitleMessage);
        setIsTicketAlertModalOpen(true);
        return;
      }

      if (isLoyaltyPointLimitCrossed) {
        setTicketAlertModalTitleMessage(defaultTicketAlertModalTitleMessage);
        setIsTicketAlertModalOpen(true);
        return;
      }
    }

    history.push({
      pathname: `/checkout/${query.ticket_id}`,
      search: `?eventId=${eventId}&quantity=${query.quantity}&step=1&exclusive_listings=${hasCp1BrokerId}&delivery_id=${determineDeliveryOption(listing?.pricing.delivery.id || 0)}`,
    });
  };

  const guranteedDescription: IconTextDescriptionCombinedProps = {
    type: 'Default',
    icon: {
      asset: 'CheckmarkInCircle',
    },
    description: {
      value: t('precheckoutBlock.ticketsGuaranteed'),
    },
  };

  const descriptions: IconTextDescriptionCombinedProps[] | [] = [
    {
      type: 'WithLogo',
      description: {
        value: hasCp1BrokerId
          ? t('precheckoutBlock.cp1')
          : t('precheckoutBlock.vivid'),
      },
      logo: {
        asset: hasCp1BrokerId ? 'LogoC1Default' : 'VividLogoWithColour',
        logoAlt: hasCp1BrokerId
          ? t('precheckoutBlock.cp1LogoScreenReaderText')
          : t('precheckoutBlock.vividLogoScreenReaderText'),
      },
    },
    {
      type: 'Default',
      description: {
        value: t('precheckoutBlock.notes', {
          note: listing?.listing.notes,
        }),
      },
      icon: {
        asset: 'Profile',
      },
    },
    {
      type: 'Default',
      description: {
        value:
          listing &&
          t(getTicketListingTranslationKey(listing, 'ticket_details')),
      },
      icon: {
        asset: 'Delivery',
      },
    },
    guranteedDescription,
  ];

  const dropdownItems: DropdownItem[] | [] = listing?.listing.quantities
    ? listing?.listing.quantities
      .map((quantity) => {
        const totalLoyaltyPoints =
          quantity * Number(loyaltyPrice);
        const totalPrice =
          quantity *
          Number(cashPrice);
        const isSingleTicket = quantity === 1;
        const label = showSplitPaymentPrice
          ? t(
            isSingleTicket
              ? 'precheckoutBlock.singleCost'
              : 'precheckoutBlock.totalCost',
            {
              quantity: quantity,
              totalPrice: totalPrice.toFixed(2),
              totalPoints: handleDecimalValuesForDisplay(
                formatPoints(totalLoyaltyPoints, isMiles),
                !isMiles,
              ),
              type: handleDisplayUnitName(account),
            },
          )
          : t(
            isSingleTicket
              ? 'precheckoutBlock.singlePoints'
              : 'precheckoutBlock.totalPoints',
            {
              quantity: quantity,
              totalPoints: handleDecimalValuesForDisplay(
                formatPoints(totalLoyaltyPoints, isMiles),
                !isMiles,
              ),
              type: handleDisplayUnitName(account),
            },
          );

        return {
          label: label,
          value: String(quantity),
          ariaLabel: t('shopByWidget.selectedOptionAnnouncement', {
            option: label,
          }),
        } as DropdownItem;
      })
      .reverse()
    : [];

  const ticketAlertModalView: TicketAlertModalProps = {
    open: isTicketAlertModalOpen,
    closeButton: {
      onClick: () => setIsTicketAlertModalOpen(false),
    },
    title: {
      value: ticketAlertModalTitleMessage.title,
    },
    message: {
      value: ticketAlertModalTitleMessage.message,
    },
    primaryButton: {
      text: {
        value: t('insufficientRewards.button'),
      },
      onClick: () => setIsTicketAlertModalOpen(false),
    },
  };

  const precheckoutBlock: PrecheckoutBlockProps = {
    topNav: {
      listing: listing,
      onBackButtonClicked: onBackButtonClick,
      backButton: {
        onClick: onBackButtonClick,
      },
    },
    exclusiveEventBanner: {
      imageSrc:
        listing && handleVariableImage(listing.event, 'hero', 'mobile'),
      tag: {
        label: {
          value: t('ticketSelectionPage.cardholderExclusive'),
        },
      },
    },
    cardInfoHeader: {
      title: {
        value: listing?.event.name,
      },
      date: {
        value: listing && handleDateFormat(listing?.event.local_date),
      },
      time: {
        value: listing && handleTimeFormat(listing.event.local_date),
      },
      location: {
        value: listing && handleLocation(listing?.event.venue),
      },
    },
    image: image || {
      imageSrc:
        listing && handleVariableImage(listing.event, 'hero', 'desktop'),
    },
    cardNavigation: {
      button: {
        text: {
          value: t('precheckoutBlock.backToTickets'),
          ariaLabel: t('precheckoutBlock.backToTickets'),
        },
        onClick: onBackButtonClick,
      },
      closeButton: {
        icon: {
          asset: 'Close',
          style: 'DigitalGrey80',
        },
        onClick: onBackButtonClick,
        ariaLabel: t('precheckoutBlock.close'),
      },
    },
    ticketInfo: {
      ticketInformation: {
        title: {
          value:
            listing?.listing.verbose_section_name ||
            listing?.listing.seating.section,
        },
        location: {
          value: t('eventPage.location', {
            row: listing?.listing.seating.row,
          }),
        },
        value: {
          // For split payment or cash only payment events, it will be cash price
          // For rewards only payment events, it will be loyalty reward point
          value: pointsDisplayValue,
        },
        valueType: {
          // For split payment or cash only payment events, it will be dollars or rewards points format
          // For other events, it will be only loyalty reward points unit
          value: showSplitPaymentPrice
            ? t('precheckoutBlock.cost', {
              currency: listing?.listing.currency,
              points: handleDecimalValuesForDisplay(
                formatPoints(Number(loyaltyPrice), isMiles),
                !isMiles,
              ),
              type: handleDisplayUnitName(account),
            })
            : handleDisplayUnitName(account),
        },
        other: {
          value:
            showAllInPrice || isExclusive
              ? t('eventPage.each')
              : t('precheckoutBlock.eachPlusFees'),
        },
        breakdownText: showAllInPrice
          ? {
            // If event venue is in California or Maryland then show "Includes fees"
            // Otherwise show "Includes $X.XX in fees"
            value: isEventInCaliforniaOrMaryland || !isNotNullOrUndefined(serviceFee)
              ? t('precheckoutBlock.includesFees')
              : t('precheckoutBlock.includesInFees', {
                fees: String(serviceFee.toFixed(2)),
              }),
          }
          : undefined,
      },
      additionalTicketInformationList: {
        iconTextDescriptions: descriptions,
      },
      ticketQuantitySelection: {
        dropdown: {
          state: 'Default',
          label: {
            value: t('precheckoutBlock.quantity'),
          },
          dropdownSelect: {
            selection: quantitySelection,
            dropdownItems: dropdownItems,
            onDropdownSelectClicked: updateQuantity,
          },
        },
        button: {
          text: {
            value: isNYEvent(listing)
              ? t('precheckoutBlock.goToCheckout')
              : t('precheckoutBlock.checkout'),
          },
          onClick: () => handleCheckoutEvent(),
        },
      },
    },
    ticketAlertModalProps: ticketAlertModalView,
  };

  return {
    ...props,
    ...precheckoutBlock,
  };
};

export default usePresenter;

import React from 'react';
import cx from 'classnames';

import Text from '../../atoms/Text';
import CXEventInfoHeader from '../../molecules/CXEventInfoHeader';
import TicketList from '../../organisms/TicketList';

import styles from './TicketTypeSelectionBlock.module.scss';
import usePresenter from './TicketTypeSelectionBlock.presenter';
import getProps from './TicketTypeSelectionBlock.props';
import { TicketTypeSelectionBlockCombinedProps } from './types';
import PrecheckoutBlock from '../PrecheckoutBlock';
import TicketAvailabilityCardSoldOut from '../../molecules/TicketAvailabilityCardSoldOut';


const TicketTypeSelectionBlock: React.FC<TicketTypeSelectionBlockCombinedProps> = (props) => {
  const {
    className,
    classes,
    text,
    ticketList,
    cXEventInfoHeader,
    description,
    // MANUAL OVERRIDE STARTS
    type,
    ticketAvailabilityCardSoldOut,
    precheckout,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.ticketTypeSelectionBlock;

  // MANUAL OVERRIDE
  let componentView;
  switch (type) {
    case 'Soldout':
      componentView = (
        <TicketAvailabilityCardSoldOut
        className={styles.ticketsSoldOut}
        {...ticketAvailabilityCardSoldOut} />);
      break;
    case 'Precheckout':
      componentView = (
        <PrecheckoutBlock {...precheckout} />);
      break;
    default:
      componentView = (
        <>
          <Text
            className={cx(styles.text, classes?.text)}
            {...internalProps.text}
            {...text}/>
          <TicketList
            className={cx(styles.ticketList, classes?.ticketList)}
            {...internalProps.ticketList}
            {...ticketList}/>
        </>
      );
      break;
  }

  return (
    // MANUAL OVERRIDE STARTS
    <div className={cx(currentStyle, className)}>
      {/* desktop */}
      <div className={styles.desktopContent}>
        <div className={styles.ticketOptionsContainer}>
          {componentView}
        </div>
        <div className={styles.rightContent}>
          <CXEventInfoHeader
            className={cx(styles.cXEventInfoHeader, classes?.cXEventInfoHeader)}
            {...internalProps.cXEventInfoHeader}
            {...cXEventInfoHeader}/>
          <Text
            className={cx(styles.description, classes?.description)}
            {...internalProps.description}
            {...description} />
        </div>
      </div>
      {/* mobile + tablet */}
      <div className={styles.mobileContent}>
        <CXEventInfoHeader
          className={cx(styles.cXEventInfoHeader, classes?.cXEventInfoHeader)}
          {...internalProps.cXEventInfoHeader}
          {...cXEventInfoHeader}/>
        <div className={styles.ticketOptionsContainer}>
          {componentView}
        </div>
        <div className={styles.descriptionContainer}>
          <Text
            className={cx(styles.description, classes?.description)}
            {...internalProps.description}
            {...description} />
        </div>
      </div>
    </div>
    // MANUAL OVERRIDE ENDS
  );
};

export default TicketTypeSelectionBlock;


import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';

import styles from './ButtonPair.module.scss';
import usePresenter from './ButtonPair.presenter';
import getProps from './ButtonPair.props';
import { ButtonPairCombinedProps } from './types';


const ButtonPair: React.FC<ButtonPairCombinedProps> = (props) => {
  const {
    className,
    classes,
    primary,
    secondary,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.buttonPair;

  return (
    <div className={cx(currentStyle, className)}>
      <Button
        className={cx(styles.primary, classes?.primary)}
        {...internalProps.primary}
        {...primary}/>
      <Button
        className={cx(styles.secondary, classes?.secondary)}
        {...internalProps.secondary}
        {...secondary}/>
    </div>
  );
};

export default ButtonPair;


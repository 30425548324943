import React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import ThumbnailCardImage from '../../../resources/legacyImages/ThumbnailCard.png';

import Icon from '../../atoms/Icon';
import Text from '../../atoms/Text';

import styles from './ThumbnailCard.module.scss';
import usePresenter from './ThumbnailCard.presenter';
import getProps from './ThumbnailCard.props';
import { ThumbnailCardCombinedProps } from './types';

import { ReactComponent as IconIcon } from '../../../resources/icons/Icon.svg';

const ThumbnailCard: React.FC<ThumbnailCardCombinedProps> = (props) => {
  const {
    className,
    classes,
    imageSrc = ThumbnailCardImage, // MANUAL OVERRIDE - added default value
    imageSrcAlt,
    imageFallback,
    title,
    icon,
    linkPath, // MANUAL OVERRIDE
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.thumbnailCard;

  const cardView = (
    <div className={cx(currentStyle, className)} style={{ backgroundImage: `url(${imageSrc}), url(${imageFallback})` }}>
      <div className={cx(styles.content, classes?.content)}>
        <Text
          className={cx(styles.title, classes?.title)}
          {...internalProps.title}
          {...title}/>
        {/* <Icon // Removed Arrow Icon to avoid multiline text
          className={cx(styles.icon, classes?.icon)}
          {...internalProps.icon}
          {...icon}/> */}
      </div>
    </div>
  );

  if (linkPath) {
    return (
      <Link to={linkPath}>
        {cardView}
      </Link>
    );
  }

  return cardView;

};

export default ThumbnailCard;


import { ButtonProps } from '../../atoms/Button';
import { TextProps } from '../../atoms/Text';


type SecondaryBannerInternalProps = {
  title?: TextProps;
  message?: TextProps;
  button?: ButtonProps;
};

type SecondaryBannerPropsValues = {
  '': SecondaryBannerInternalProps;
};

const propValues: SecondaryBannerPropsValues = {
  '': {
    title: {
      type: 'Heading', // MANUAL OVERRIDE
      size: 'Small', // MANUAL OVERRIDE
      style: 'Regular',
      colour: 'BaseLight',
      align: 'Center',
    },
    message: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'BaseLight',
      align: 'Center',
    },
    button: {
      type: 'Text',
      style: 'ContainedWhite',
      size: 'Large',
    },
  },
};

const getProps = (type: string): SecondaryBannerInternalProps => {
  const values: SecondaryBannerInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

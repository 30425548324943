import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import AppProvider from './AppProvider';
import * as serviceWorker from './serviceWorker';
import { initializeTracking } from './lib/trackingUtils';
import { initSentry } from './lib/initSentry';
import { initRiskifiedBeacon } from './lib/initRiskifiedBeacon';

initializeTracking();
initSentry();
initRiskifiedBeacon();

ReactDOM.render(
  <React.StrictMode>
    <AppProvider>
      <App />
    </AppProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import { useContext, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useLocation } from 'react-router-dom';

import { getQueryParams, handleTagFilter, removeIgnoredParams } from '../../../lib/util';
import { AuthContext } from '../../../modules/auth/AuthContext';
import exclusiveCategoryEvents from '../../../modules/navigation/ExclusiveCategoryEvents.json';
import { Event, EventsQueryParams, Performer, PerformersQueryParams } from '../../../modules/partnership';
import { getEvents, getTopPerformers } from '../../../modules/partnership/api';
import { RegionContext } from '../../../modules/region';

import { filterOutMLB, removeDuplicates, removePastEvents } from '../../../legacy/blocks/ExclusivesEventBlock/util';

import { PerformerCategoryEnum } from '../../pages/Homepage/constants';

import { EventCardsBlockCombinedProps, EventCardsBlockPresenterProps } from './types';
import { defaultDateToTodayIfEmpty, filterTaylorSwiftFromTopPerformers, isTheSameObject } from './utils';
import { trackErrorEvent } from '../../../modules/analytics/util';
import { ApiError } from '../../../modules/error/types';
import { useAnalyticsManager } from '../../../modules/analytics/useAnalyticsManager';

const useDefaultInteractor = (props: EventCardsBlockCombinedProps): EventCardsBlockPresenterProps => {
  const { account } = useContext(AuthContext);
  const { currentRegion, loading: regionLoading } = useContext(RegionContext);
  const { mutateAsync: fetchEvents, isLoading } = useMutation(getEvents);
  const [loading, setLoading] = useState<boolean>(true);
  const [events, setEvents] = useState<Event[]>([]);

  const { trackEvent } = useAnalyticsManager();

  const { pathname, search } = useLocation();
  const initialQueryParams = removeIgnoredParams(getQueryParams(search), props.ignoreParams || []);
  const isExclusivePage = pathname.startsWith('/exclusive-events');
  const isPerformerPage = pathname.startsWith('/performers');
  const isVenuesPage = pathname.startsWith('/venues');

  const [apiParams, setApiParams] = useState<EventsQueryParams>({
    page: 1,
    per_page: props.defaultParams?.per_page || 10,
    category: isExclusivePage ? 'exclusive' : '',
    ...initialQueryParams,
  });
  const [currentPage, setCurrentPage] = useState({
    page: 1,
    numberOfPages: 1,
  });

  useEffect(() => {
    const queryParams = { ...removeIgnoredParams(getQueryParams(search), props.ignoreParams || []) };
    let currentRegionParams;
    if (!isExclusivePage && !isPerformerPage && !isVenuesPage) {
      currentRegionParams = {
        region_id: currentRegion?.id,
      };
    }

    if (isExclusivePage && !queryParams.date_start) {
      queryParams.date_start = defaultDateToTodayIfEmpty(queryParams.date_start);
    }
    const newApiParams = {
      ...currentRegionParams,
      ...props.defaultParams,
      ...queryParams,
      page: 1,
      per_page: props.ignoreParams?.includes('per_page') ? undefined : props.defaultParams?.per_page || 10,
    };

    if (!isTheSameObject(apiParams, newApiParams)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      setApiParams(newApiParams);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, currentRegion, props.defaultParams, props.ignoreParams]);

  useEffect(() => {
    if (!account || ('region_id' in apiParams && regionLoading)) {
      return;
    }
    let fetchingEvents = true;
    const doFetchEvents = async (queryParams: EventsQueryParams) => {
      try {
        if (account) {
          let updatedQueryParam = queryParams;
          if (!props.ignoreParams?.includes('tag_filter')) {
            updatedQueryParam = handleTagFilter(
              queryParams,
              account,
              !isExclusivePage && queryParams.category !== 'exclusive',
              isExclusivePage,
            );
          }

          const response = await fetchEvents(updatedQueryParam);
          if (isExclusivePage && props.showMLBCard) {
            response.items = filterOutMLB(response.items);
          }
          response.items = removePastEvents(response.items);
          setLoading(false);
          if (fetchingEvents) {
            setCurrentPage({
              page: response.page,
              numberOfPages: response.number_of_pages,
            });
            const updatedEvents = response.page >= 2 ? [...events, ...response.items] : response.items;
            setEvents(updatedEvents);
          }
        }
      } catch (error) {
        if (error && ApiError.isApiError(error)) {
          trackErrorEvent(
            trackEvent,
            error.code,
            error.message,
          );
        }
        setEvents([]);
      }
    };
    const queryParams = { ...apiParams };
    if (apiParams.opponent_id === -1) {
      queryParams.opponent_id = undefined;
    }

    let hasAllRequiredParams = true; // check if all required params is defined or not
    if (props.requiredFields && props.requiredFields.length) {
      for (let index = 0; index < props.requiredFields.length; index++) {
        if (!queryParams[props.requiredFields[index]]) {
          hasAllRequiredParams = false;
          break;
        }
      }
    }

    if (hasAllRequiredParams) {
      void doFetchEvents(queryParams);
    }

    // To avoid race conditions to change events
    return () => {
      fetchingEvents = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiParams, fetchEvents, account, regionLoading]);

  const showMoreData = () => {
    setApiParams({
      ...apiParams,
      page: (apiParams.page || 1) + 1,
    });
  };

  return {
    ...props,
    cardData: events,
    isLoading: loading || isLoading,
    isExclusivePage: isExclusivePage,
    hasMorePages: currentPage.numberOfPages > currentPage.page,
    showMoreData: showMoreData,
    sliderInformationType: 'Events',
  };
};

const useExclusiveInteractor = (props: EventCardsBlockCombinedProps): EventCardsBlockPresenterProps => {
  const { account } = useContext(AuthContext);
  const { mutateAsync: fetchEvents, isLoading } = useMutation(getEvents);
  const [events, setEvents] = useState<Event[]>([]);
  const [eventsNumber, setEventsNumber] = useState(0);

  const { trackEvent } = useAnalyticsManager();

  useEffect(() => {
    const doFetchEvents = async () => {
      try {
        if (account) {
          const updatedQueryParam = handleTagFilter(
            {
              category: 'exclusive',
            },
            account,
            undefined,
            undefined,
            true, // Exclude collapsed events
          );
          const response = await fetchEvents(updatedQueryParam);
          response.items = removeDuplicates(response?.items || []);
          response.items = filterOutMLB(response.items);
          response.items = removePastEvents(response.items);
          let eventsArr = [...response.items];
          // Only if there are not enough events to show exclusive widget
          if (eventsArr.length < 2) {
            for (let index = 0; index < exclusiveCategoryEvents.length; index++) {
              const category = exclusiveCategoryEvents[index];
              const categoryRequestQueryParam = handleTagFilter(
                {
                  category: category.id,
                },
                account,
              );
              const categoryResponse = await fetchEvents(categoryRequestQueryParam);
              categoryResponse.items = filterOutMLB(categoryResponse.items);
              categoryResponse.items = removePastEvents(categoryResponse.items);
              eventsArr = removeDuplicates([...eventsArr, ...categoryResponse.items]);
            }
          }
          // Sort events based on the event date
          eventsArr.sort((a, b) => {
            const aDate = (new Date(a.utc_date)).getTime();
            const bDate = (new Date(b.utc_date)).getTime();
            return aDate - bDate;
          });

          setEventsNumber(eventsArr.length);

          if (eventsArr.length > 4) {
            eventsArr = eventsArr.splice(0, 4);
          }

          setEvents(eventsArr);
        }
      } catch (error) {
        if (error && ApiError.isApiError(error)) {
          trackErrorEvent(
            trackEvent,
            error.code,
            error.message,
          );
        }
        // TODO
      }
    };
    void doFetchEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchEvents, account]);

  return {
    ...props,
    cardData: events,
    headerType: 'WithButton',
    isLoading: isLoading,
    sliderInformationType: 'Events',
  };
};

const useTopPerformersInteractor = (props: EventCardsBlockCombinedProps): EventCardsBlockPresenterProps => {
  const { defaultParams } = props;
  const { account } = useContext(AuthContext);
  const { mutateAsync: fetchTopPerformers, isLoading } = useMutation(getTopPerformers);
  const [topPerformers, setTopPerformers] = useState<Performer[]>([]);
  const { trackEvent } = useAnalyticsManager();
  useEffect(() => {
    const doFetchTopPerformers = async () => {
      try {
        if (account) {
          const response = await fetchTopPerformers(defaultParams);
          const filteredTopPerformers = (defaultParams as PerformersQueryParams)?.categoryId === PerformerCategoryEnum.MUSIC
            ? filterTaylorSwiftFromTopPerformers(response)
            : response;
          setTopPerformers(filteredTopPerformers);
        }
      } catch (error) {
        if (error && ApiError.isApiError(error)) {
          trackErrorEvent(
            trackEvent,
            error.code,
            error.message,
          );
        }
        // TODO
      }
    };
    void doFetchTopPerformers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchTopPerformers, account]);


  return {
    ...props,
    cardData: topPerformers,
    isLoading: isLoading,
    sliderInformationType: 'Performers',
  };
};

const useInteractor = (props: EventCardsBlockCombinedProps): EventCardsBlockPresenterProps => {
  const { type, showExclusiveEvents } = props;

  const performerCardsProps = useTopPerformersInteractor(props);
  const exclusiveEventCardProps = useExclusiveInteractor(props);
  const defaultProps = useDefaultInteractor(props);

  if (type === 'PerformersLightBlock') {
    return performerCardsProps;
  }

  if (showExclusiveEvents) {
    return exclusiveEventCardProps;
  }

  return defaultProps;
};

export default useInteractor;
import React, { createContext, RefObject, useCallback, useRef, useState } from 'react';
import { Props } from '../../../lib/types';

export type SnapListValue = {
  listElementRef: RefObject<HTMLDivElement> | undefined;
  currentIndex: number;
  canGoForward: boolean;
  canGoBackward: boolean;
  scrollOffset: number;
  updateItemCount: (itemCount: number) => void;
  updateScrollBy: (scrollBy: number) => void;
  onBackwardClicked: () => void;
  onForwardClicked: () => void;
  updateScrollOffset: (offset: number) => void;
};

const initialAuthContext: SnapListValue = {
  listElementRef: undefined,
  currentIndex: 0,
  canGoBackward: false,
  canGoForward: false,
  scrollOffset: 16,
  updateItemCount: () => {},
  updateScrollBy: () => {},
  onBackwardClicked: () => {},
  onForwardClicked: () => {},
  updateScrollOffset: () => {},
};

type SnapListState = {
  currentIndex: number;
  itemCount: number;
  scrollBy: number;
  canGoForward: boolean;
  canGoBackward: boolean;
  scrollOffset: number;
};

export const SnapListContext = createContext<SnapListValue>(initialAuthContext);

export const SnapListProvider: React.FC<Props> = ({ children }) => {
  const listElementRef = useRef<HTMLDivElement>(null);
  const [snapListState, setSnapListState] = useState<SnapListState>({
    currentIndex: 0,
    itemCount: 0,
    scrollBy: 4,
    canGoBackward: false,
    canGoForward: false,
    scrollOffset: 16,
  });

  const updateItemCount = useCallback((itemCount: number) => {
    setSnapListState((prevState) => ({
      ...prevState,
      itemCount,
      canGoBackward: prevState.currentIndex > (prevState.scrollBy - 1),
      canGoForward: prevState.currentIndex < (itemCount - prevState.scrollBy),
    }));
  }, []);

  const updateScrollBy = useCallback((scrollBy: number) => {
    setSnapListState((prevState) => ({
      ...prevState,
      scrollBy,
      canGoBackward: prevState.currentIndex > (scrollBy - 1),
      canGoForward: prevState.currentIndex < (prevState.itemCount - scrollBy),
    }));
  }, []);

  const updateScrollOffset = useCallback((offset: number) => {
    setSnapListState((prevState) => ({
      ...prevState,
      offset,
    }));
  }, []);

  const onBackwardClicked = useCallback(() => {
    const newIndex = Math.max(0, snapListState.currentIndex - snapListState.scrollBy);
    setSnapListState((prevState) => ({
      ...prevState,
      currentIndex: newIndex,
      canGoBackward: newIndex > 0,
      canGoForward: newIndex < (snapListState.itemCount - snapListState.scrollBy),
    }));
  }, [snapListState]);

  const onForwardClicked = useCallback(() => {
    const newIndex = Math.min(snapListState.itemCount - snapListState.scrollBy, snapListState.currentIndex + snapListState.scrollBy);
    setSnapListState((prevState) => ({
      ...prevState,
      currentIndex: newIndex,
      canGoBackward: newIndex > 0,
      canGoForward: newIndex < (snapListState.itemCount - snapListState.scrollBy),
    }));
  }, [snapListState]);

  return (
    <SnapListContext.Provider value={{
      listElementRef,
      currentIndex: snapListState.currentIndex,
      canGoBackward: snapListState.canGoBackward,
      canGoForward: snapListState.canGoForward,
      scrollOffset: snapListState.scrollBy,
      updateItemCount,
      updateScrollBy,
      onBackwardClicked,
      onForwardClicked,
      updateScrollOffset,
    }}>
      {children}
    </SnapListContext.Provider>
  );
};
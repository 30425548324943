import { EventCardNoImageProps } from '../../molecules/EventCardNoImage';


type EventCardNoImageListInternalProps = {
  eventCardNoImage?: EventCardNoImageProps;
};

type EventCardNoImageListPropsValues = {
  '': EventCardNoImageListInternalProps;
};

const propValues: EventCardNoImageListPropsValues = {
  '': {
    eventCardNoImage: {
    },
  },
};

const getProps = (type: string): EventCardNoImageListInternalProps => {
  const values: EventCardNoImageListInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import { IconProps } from '../Icon';
import { TextProps } from '../Text';


type ClickableSectionInternalProps = {
  text?: TextProps;
  icon?: IconProps;
};

type ClickableSectionPropsValues = {
  '': ClickableSectionInternalProps;
};

const propValues: ClickableSectionPropsValues = {
  '': {
    text: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'ActionHover',
      align: 'Left',
    },
    icon: {
      asset: 'ArrowRight',
      style: 'ActionBase',
    },
  },
};

const getProps = (type: string): ClickableSectionInternalProps => {
  const values: ClickableSectionInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

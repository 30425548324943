import Panzoom, { PanzoomObject } from '@panzoom/panzoom';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { MapContext } from '../../..';
import { MAP_VALUES } from '../../../constants';
// import { trackEvent } from '../../../../../utils/analytics';
import SimpleMap, { SimpleMapProps } from '../SimpleMap';
import styles from './styles.module.scss';

const passiveListener = () => undefined;
let supportsPassive = false;
try {
  const opts = Object.defineProperty({}, 'passive', {
    get: function () {
      supportsPassive = true;
    },
  });
  window.addEventListener('testPassive', passiveListener, opts);
  window.removeEventListener('testPassive', passiveListener, opts);
// eslint-disable-next-line no-empty
} catch (e) {}

type Props = SimpleMapProps & {
  style?: React.CSSProperties;
};

const SvgPanZoom: React.FC<SimpleMapProps> = (props) => {
  const {
    dispatch: { setIsDragging, setIsDoubleClicked },
    selectors: { mapState },
  } = useContext(MapContext);

  const [mapPanZoom, setMapPanZoom] = useState<PanzoomObject>();
  const [showControls, setShowControls] = useState(false);

  const container = useRef<HTMLDivElement>(null);
  const svgImage = useRef<SVGSVGElement>(null);

  useEffect(() => {
    const containerElement = container.current;
    const svgImageElement = svgImage.current;

    if (!containerElement || !svgImageElement) {
      return;
    }
    const panZoomObject: PanzoomObject = Panzoom(svgImageElement, {
      minScale: MAP_VALUES.MIN_SCALE,
      canvas: true,
      step: MAP_VALUES.STEP,
      handleStartEvent: () => {},
    });

    svgImageElement.addEventListener('panzoomchange', () => {
      setIsDragging?.(true);
    }, supportsPassive ? { passive: true } : false);

    svgImageElement.addEventListener('panzoomstart', () => {
      setIsDragging?.(false);
    }, supportsPassive ? { passive: true } : false);

    svgImageElement.addEventListener('panzoomend', () => {
      const { x, y } = panZoomObject.getPan();
      if (x !== 0 || y !== 0) {
        setShowControls(true);
      }
    }, supportsPassive ? { passive: true } : false);

    containerElement.addEventListener('wheel', (event) => {
      panZoomObject.zoomWithWheel(event);
      if (panZoomObject.getScale() > MAP_VALUES.MIN_SCALE) {
        setShowControls(true);
      }
    }, supportsPassive ? { passive: true } : false);
    setMapPanZoom(panZoomObject);
  }, [container, svgImage]);

  useEffect(() => {
    if (mapState.isDoubleClicked) {
      mapPanZoom?.zoomIn({ step: 0.25 });
      setIsDoubleClicked(false);
      setShowControls(true);
    }
  }, [mapState.isDoubleClicked]);

  const onResetHandler = () => {
    mapPanZoom?.reset();
    setShowControls(false);
    // trackEvent('FilterUse', 'UsedTicketMap', 'Reset', undefined, false)
  };

  return (
        <div className={styles.mapContainer} data-testid="map-container" ref={container}>
            <SimpleMap {...props} ref={svgImage} />
            {showControls && (
                <div className={styles.mapControlsContainer}>
                    {/* <Button onClick={onResetHandler} className={styles.resetButton} data-testid={'reset-button'}>
                        <Icon size="md" type="refresh" />
                    </Button> */}
                </div>
            )}
        </div>
  );
};

export default SvgPanZoom;

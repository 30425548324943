import { useCallback, useState } from 'react';
import { useQuery, UseQueryResult } from 'react-query';
import { SearchSuggestionsQueryParams, SearchSuggestionsResponse } from '.';

export type SearchResponseType = [
  (params: SearchSuggestionsQueryParams) => void,
  UseQueryResult<SearchSuggestionsResponse | undefined, Error>,
];

export const useSuggestionSearchQuery = (
  key: string, 
  fn: (params: SearchSuggestionsQueryParams) => Promise<SearchSuggestionsResponse>,
): SearchResponseType => {
  const [searchParams, setSearchParams] = useState<SearchSuggestionsQueryParams>({ q: '' });
  const [enabled, setEnabled] = useState(false);

  const queryState = useQuery<SearchSuggestionsResponse | undefined, Error>(
    [key, searchParams],
    () => fn(searchParams),
    { enabled },
  );

  const { refetch } = queryState;
  const doSearch = useCallback((params: SearchSuggestionsQueryParams) => {
    setSearchParams(params);
    setEnabled(true);
    void refetch();
  }, [refetch]);

  return [doSearch, queryState];
};
import { SubmenuItemCombinedProps } from '../../molecules/SubmenuItem/types';
import { SubmenuItemListCombinedProps } from '../../molecules/SubmenuItemList/types';
import { SubmenuWithoutTabsCombinedProps } from './types';

const usePresenter = (props: SubmenuWithoutTabsCombinedProps): SubmenuWithoutTabsCombinedProps => {
  const { data, id, onItemClicked } = props;
  const eventObject = data?.find((category) => category.id === id);

  const events: SubmenuItemListCombinedProps = {
    submenuItems: eventObject?.children.map((event): SubmenuItemCombinedProps => {
      return {
        role: 'link',
        text: {
          value: event.name,
        },
        linkPath: event.slug,
        onItemClicked: onItemClicked,
      };
    }),
  };

  return {
    submenuItemList: events,
  };
};

export default usePresenter;

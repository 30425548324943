import axios from 'axios';
import { ApiError } from './types';

export const handleError = (error: unknown) => {
  if (axios.isAxiosError(error) && error.response) {
    return new ApiError({
      code: error.response.status,
      type: error.response.statusText,
      message: error.message,
    });
  }
  return error;
};
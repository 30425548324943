import { IconProps } from '../../atoms/Icon';
import { TextProps } from '../../atoms/Text';


type ThumbnailCardInternalProps = {
  title?: TextProps;
  icon?: IconProps;
};

type ThumbnailCardPropsValues = {
  '': ThumbnailCardInternalProps;
};

const propValues: ThumbnailCardPropsValues = {
  '': {
    title: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseLight',
      align: 'Left',
    },
    icon: {
      asset: 'ChevronRight',
      style: 'White',
    },
  },
};

const getProps = (type: string): ThumbnailCardInternalProps => {
  const values: ThumbnailCardInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import React from 'react';
import cx from 'classnames';

import PrimaryBanner from '../../molecules/PrimaryBanner';

import styles from './PrimaryBannerBlock.module.scss';
import usePresenter from './PrimaryBannerBlock.presenter';
import getProps from './PrimaryBannerBlock.props';
import { PrimaryBannerBlockCombinedProps } from './types';


const PrimaryBannerBlock: React.FC<PrimaryBannerBlockCombinedProps> = (props) => {
  const {
    className,
    classes,
    primaryBanner,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.primaryBannerBlock;

  return (
    <div className={cx(currentStyle, className)}>
      <PrimaryBanner
        className={cx(styles.primaryBanner, classes?.primaryBanner)}
        {...internalProps.primaryBanner}
        {...primaryBanner}/>
    </div>
  );
};

export default PrimaryBannerBlock;


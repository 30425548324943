import React from 'react';
import cx from 'classnames';

import Text from '../../atoms/Text';

import styles from './StepperItem.module.scss';
import usePresenter from './StepperItem.presenter';
import getProps from './StepperItem.props';
import { StepperItemCombinedProps } from './types';


const StepperItem: React.FC<StepperItemCombinedProps> = (props) => {
  const {
    type,
    state,
    className,
    classes,
    stepNumber,
    stepLabel,
  } = usePresenter(props);

  const variantName = `${type}${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`stepperItem${variantName}`];


  let componentView;

  switch (variantName) {
    case 'SelectedIncomplete':
    case 'UnselectedComplete':
    case 'UnselectedIncomplete': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.indicator, classes?.indicator)}/>
          <div className={cx(styles.content, classes?.content)}>
            <Text
              className={cx(styles.stepNumber, classes?.stepNumber)}
              {...internalProps.stepNumber}
              {...stepNumber}/>
            <Text
              className={cx(styles.stepLabel, classes?.stepLabel)}
              {...internalProps.stepLabel}
              {...stepLabel}/>
          </div>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default StepperItem;


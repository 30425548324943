import { matchPath } from 'react-router-dom';

export const checkIfPathMatch = (currentPathName: string, pathName: string | string[], exact: boolean): boolean => {
  return !!matchPath(currentPathName, {
    path: pathName,
    exact,
  });
};

export const shouldShowUpdatedTopNav = (
  pathname: string, 
  hasEventGuestList?: boolean,
  isSoldOutEvent?: boolean,
): boolean => {
  const isHomePage = checkIfPathMatch(pathname, '/', true);
  const isC1ExclusivePage = checkIfPathMatch(pathname, '/exclusive-events', true);
  const isPerformersPage = checkIfPathMatch(pathname, '/performers/:performerId', true);
  const isVenuesPage = checkIfPathMatch(pathname, '/venues/:venueId', true);
  const isSportsTeamPage = checkIfPathMatch(pathname, ['/nba', '/nhl', '/nfl', '/mls', '/mlb'], true);
  const isEventsPage = checkIfPathMatch(pathname, '/events', false);
  const isGeneralAdmissionEvent = isEventsPage && (!!hasEventGuestList || !!isSoldOutEvent);

  return (
    isHomePage || isC1ExclusivePage || isPerformersPage
    || isVenuesPage || isSportsTeamPage || isGeneralAdmissionEvent
  );
};
import React from 'react';
import { SnapListProvider } from '../../../modules/snap/SnapListContext/SnapListContext';
import EventCardsBlock from './EventCardsBlock';
import { EventCardsBlockCombinedProps, EventCardsBlockProps, EventCardsBlockValueProps } from './types';

export type { EventCardsBlockProps, EventCardsBlockValueProps };

const EventCardsBlockView: React.FC<EventCardsBlockCombinedProps> = (props) => {
  return (
    <SnapListProvider>
      <EventCardsBlock {...props} />
    </SnapListProvider>
  );
};
export default EventCardsBlockView;

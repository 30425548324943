import { TextProps } from '../../atoms/Text';


type SubmenuTabItemInternalProps = {
  text?: TextProps;
};

type SubmenuTabItemPropsValues = {
  '': SubmenuTabItemInternalProps;
};

const propValues: SubmenuTabItemPropsValues = {
  '': {
    text: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseLight',
      align: 'Left',
    },
  },
};

const getProps = (type: string): SubmenuTabItemInternalProps => {
  const values: SubmenuTabItemInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import { DividerProps } from '../../atoms/Divider';
import { TextProps } from '../../atoms/Text';


type TicketSelectionInformationInternalProps = {
  title?: TextProps;
  location?: TextProps; // MANUAL OVERRIDE
  points?: TextProps;
  text1?: TextProps;
  value?: TextProps;
  text2?: TextProps;
  divider?: DividerProps;
};

type TicketSelectionInformationPropsValues = {
  '': TicketSelectionInformationInternalProps;
};

const propValues: TicketSelectionInformationPropsValues = {
  '': {
    title: {
      type: 'Subheading',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    // MANUAL OVERRIDE
    location: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    points: {
      type: 'Body',
      size: 'Small',
      style: 'SemiBold',
      colour: 'SubduedDark',
      align: 'Left',
    },
    text1: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    value: {
      type: 'Body',
      size: 'Small',
      style: 'SemiBold',
      colour: 'SubduedDark',
      align: 'Left',
    },
    text2: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    divider: {
      type: 'Horizontal',
      style: 'Thin',
      colour: 'DigitalGrey10',
    },
  },
};

const getProps = (type: string): TicketSelectionInformationInternalProps => {
  const values: TicketSelectionInformationInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { formatDateToApiDate, handleLocation, handleTagFilterForSearch, handleTimeFormat } from '../../../lib/util';
import { AuthContext } from '../../../modules/auth';
import useRecentSearches from '../../../modules/localstorage/useRecentSearches';
import { Performer, SearchSuggestionsResponse, Venue } from '../../../modules/partnership';
import { getSearchSuggestions } from '../../../modules/partnership/api';
import { useSuggestionSearchQuery } from '../../../modules/partnership/useSuggestionSearchQuery';
import { SearchBlockCombinedProps } from './types';
import { getItemIfMatch } from '../../../modules/navigation/utils';
import { ButtonPairValueProps } from '../../molecules/ButtonPair';
import { defaultDateToTodayIfEmpty } from '../../molecules/Filter/utils';
import { SearchReturnItemValueProps } from '../../molecules/SearchReturnItem';
import { useAnalyticsManager } from '../../../modules/analytics/useAnalyticsManager';

const usePresenter = (props: SearchBlockCombinedProps): SearchBlockCombinedProps => {
  const { t } = useTranslation();
  const history = useHistory();
  const { account } = useContext(AuthContext);
  const [doSearch, { data: suggestionSearchResults }] = useSuggestionSearchQuery('suggestionsearch', getSearchSuggestions);
  const { recentSearches, addRecentSearch, removeRecentSearch } = useRecentSearches();
  const { closeSearchBlock } = props;

  const [showNavList, setShowNavList] = useState(false);
  const [showResults, setSuggestionResultsView] = useState(false);
  const [textValue, setValue] = useState<string>('');
  const [apiSuggestionResults, setSuggestionResults] = useState<
  SearchSuggestionsResponse | undefined
  >(suggestionSearchResults);
  const searchBarRef = useRef<HTMLInputElement>(null);

  const { trackEvent }  = useAnalyticsManager();

  useEffect(() => {
    if (textValue !== '') {
      setSuggestionResultsView(true);
      doSearch({
        q: textValue,
        tag_filter: handleTagFilterForSearch(account),
        date_start: formatDateToApiDate(new Date()),
      });
      trackEvent('search', {
        search_term: textValue,
      });
    } else {
      setSuggestionResultsView(false);
    }
  }, [textValue, doSearch]);

  useEffect(() => {
    if (suggestionSearchResults) {
      setSuggestionResults(suggestionSearchResults);
    }
  }, [suggestionSearchResults]);

  const closeSearchBlockAndNavList = () => {
    setShowNavList(false);
    if (closeSearchBlock) closeSearchBlock();
  };

  const handleSearchValue = ({ target: { value } }): void => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    setValue(value);
    setShowNavList(false);
  };

  const clearSearchValue = () => {
    setValue('');
    setShowNavList(false);
  };

  const returnToSearch = () => {
    clearSearchValue();
    searchBarRef.current?.focus();
  };

  const getRecents = (): ButtonPairValueProps[] | undefined => {
    if (!recentSearches) return undefined;
    return recentSearches.map((search): ButtonPairValueProps => {
      return {
        primary: {
          text: {
            value: search.title,
          },
          ariaLabel: t('recent_searches.recentSearchScreenReaderText', {
            title: search.title,
          }),
          href: search.path,
        },
        secondary: {
          ariaLabel: t(
            'recent_searches.recentSearchCloseButtonScreenReaderText',
          ),
          onClick: () => {
            if (removeRecentSearch) {
              removeRecentSearch(search);
            }
          },
        },
      };
    });
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      const trimmedValue = textValue.trim();
      let title = trimmedValue;
      let searchPath = `/search?q=${encodeURIComponent(
        trimmedValue,
      )}&date_start=${defaultDateToTodayIfEmpty(undefined)}`;

      if (trimmedValue.length) {
        const performer = getItemIfMatch(
          textValue,
          apiSuggestionResults?.performers,
        );
        if (performer) {
          title = performer.name;
          searchPath = `/performers/${performer.id}`;
        } else {
          const event = getItemIfMatch(
            textValue,
            apiSuggestionResults?.events,
          );
          if (event) {
            title = event.name;
            searchPath = `/events/${event.id}`;
          } else {
            const venue = getItemIfMatch(
              textValue,
              apiSuggestionResults?.venues,
            );
            if (venue) {
              title = venue.name;
              searchPath = `/venues/${venue.id}`;
            }
          }
        }

        if (addRecentSearch) {
          addRecentSearch({ title: title, path: searchPath });
        }
        setValue('');
        closeSearchBlockAndNavList();
        return history.push(searchPath, {
          canGoBack: true,
        });
      }
    }
  };

  const handleSearchFieldFocus = () => {
    if (textValue.length) {
      return;
    }
    setShowNavList(true);
  };

  const getItems = (
    category: keyof SearchSuggestionsResponse,
    size: number,
  ): Venue[] | Event[] | Performer[] => {
    if (apiSuggestionResults) {
      const getList: Venue[] | Event[] | Performer[] =
        apiSuggestionResults[category];
      const resultList: Venue[] | Event[] | Performer[] = getList.slice(
        0,
        size,
      );
      return resultList;
    }
    return [];
  };

  const venueItems = (): SearchReturnItemValueProps[] => {
    const venues = getItems('venues', 2);
    return venues?.map((venue): SearchReturnItemValueProps => {
      return {
        text: {
          value: venue.name,
        },
        onEsc: returnToSearch,
        linkPath: `/venues/${venue.id}`,
        onClick: () => {
          if (addRecentSearch) {
            addRecentSearch({
              title: venue.name,
              path: `/venues/${venue.id}`,
            });
          }
          if (props.searchResult && props.searchResult.onClick) {
            props.searchResult.onClick();
          }
        },
      };
    });
  };

  const eventItems = (): SearchReturnItemValueProps[] => {
    const events = getItems('events', 5);
    return events?.map((event): SearchReturnItemValueProps => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      const localDate = handleTimeFormat(event.local_date);
      return {
        text: {
          value: event.name,
        },
        description: {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          value: `${localDate} - ${handleLocation(event.venue)}`,
        },
        linkPath: `/events/${event.id}`,
        onEsc: returnToSearch,
        onClick: () => {
          if (addRecentSearch) {
            addRecentSearch({
              title: event.name,
              path: `/events/${event.id}`,
            });
          }
          if (props.searchResult && props.searchResult.onClick) {
            props.searchResult.onClick();
          }
        },
      };
    });
  };

  const performerItems = (): SearchReturnItemValueProps[] => {
    const performers = getItems('performers', 3);
    return performers?.map((performer): SearchReturnItemValueProps => {
      return {
        text: {
          value: performer.name,
        },
        linkPath: `/performers/${performer.id}`,
        onEsc: returnToSearch,
        onClick: () => {
          if (addRecentSearch) {
            addRecentSearch({
              title: performer.name,
              path: `/performers/${performer.id}`,
            });
          }
          if (props.searchResult && props.searchResult.onClick) {
            props.searchResult.onClick();
          }
        },
      };
    });
  };

  const buttonPairs = getRecents();

  return {
    ...props,
    showResults: showResults
      && !!apiSuggestionResults
      && !!apiSuggestionResults.events.length
      && !!apiSuggestionResults.performers.length
      && !!apiSuggestionResults.venues.length,
    searchContainer: {
      searchField: {
        textInput: {
          inputRef: searchBarRef,
          state: textValue ? 'Filled' : 'Empty',
          textPlaceholder: t('search.searchMessage'),
          onTextChanged: handleSearchValue,
          textValue: textValue,
          button: {
            ariaLabel: t('search.eraseSearchScreenReaderText'),
            onClick: clearSearchValue,
          },
          ariaLabel: t('search.menuUnderneathSearchFieldScreenReaderText'),
        },
        onKeyPress: handleKeyPress,
      },
      buttonPairList: {
        buttonPairs,
      },
      state:
        buttonPairs?.length && !props.searchContainer?.state
          ? 'ShowRecentSearches'
          : 'Default',
      label: {
        value: t('recent_searches.recent'),
      },
    },
    searchResult: {
      searchSuggestions: apiSuggestionResults,
      upcomingCategory: {
        type: 'DividerBottom',
        icon: {
          asset: 'Clock',
        },
        title: {
          value: t('search.events'),
        },
      },
      upcomingReturnList: {
        searchReturnItems: eventItems(),
      },
      performerCategory: {
        type: 'DividerBottom',
        icon: {
          asset: 'Shirt',
        },
        title: {
          value: t('search.performers'),
        },
      },
      performerReturnList: {
        searchReturnItems: performerItems(),
      },
      venueCategory: {
        type: 'DividerBottom',
        icon: {
          asset: 'Arena',
        },
        title: {
          value: t('search.venues'),
        },
      },
      venueReturnList: {
        searchReturnItems: venueItems(),
      },
    },
    navMenuList: {
      ...(showNavList && {
        closeSearchBlock: closeSearchBlockAndNavList,
        type: 'List',
      }),
    },
    closeSearchBlock: closeSearchBlockAndNavList,
    openSearchBlock: handleSearchFieldFocus,
    searchSuggestions: apiSuggestionResults,
  };
};

export default usePresenter;

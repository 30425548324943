import React from 'react';
import cx from 'classnames';

import Divider from '../../atoms/Divider';
import Text from '../../atoms/Text';

import styles from './SubmenuItem.module.scss';
import usePresenter from './SubmenuItem.presenter';
import getProps from './SubmenuItem.props';
import { SubmenuItemCombinedProps } from './types';
import { getClickAccessibilityProps } from '../../../legacy/organisms/TopNav/util';


const SubmenuItem: React.FC<SubmenuItemCombinedProps> = (props) => {
  const {
    className,
    classes,
    divider,
    text,
    // MANUAL OVERRIDE STARTS
    elementRef,
    focusable = true,
    handleTab,
    linkPath,
    onItemClicked,
    role = 'submenuitem',
  // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const variantName = '';
  const internalProps = getProps(variantName);
  const currentStyle = styles[`submenuItem${variantName}`];

  const accessibilityProps = getClickAccessibilityProps({
    onClick: onItemClicked,
    onTab: (forward) => handleTab && handleTab(forward),
  });

  let componentView;

  switch (variantName) {
    case '': {
      componentView = (
        <a
          href={linkPath}
          ref={elementRef}
          tabIndex={focusable ? 0 : -1}
          className={cx(currentStyle, className)}
          onClick={onItemClicked}
          {...accessibilityProps}
          role={role}
        >
          <Divider
            className={cx(styles.divider, classes?.divider)}
            {...internalProps.divider}
            {...divider}/>
          <div className={cx(styles.content, classes?.content)}>
            <Text
              className={cx(styles.text, classes?.text)}
              {...internalProps.text}
              {...text}/>
          </div>
        </a>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default SubmenuItem;


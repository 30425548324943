import { TextProps } from '../../atoms/Text';
import { DividerProps } from '../../atoms/Divider';
import { EventStatusTagProps } from '../../atoms/EventStatusTag';
import { EventTypeTagProps } from '../../atoms/EventTypeTag';
import { IconProps } from '../../atoms/Icon';
import { ImageProps } from '../../atoms/Image';
import { DateAndTimeCombinedProps } from '../DateAndTime/types';


type SpecialEventCardInternalProps = {
  image?: ImageProps;
  eventTypeTag?: EventTypeTagProps;
  eventStatusTag?: EventStatusTagProps;
  titleText?: TextProps;
  descriptionText?: TextProps;
  dateAndTime?: DateAndTimeCombinedProps; // MANUAL OVERRIDE
  location?: DateAndTimeCombinedProps; // MANUAL OVERRIDE
  divider?: DividerProps;
  acttionText?: TextProps;
  actionText?: TextProps;
  icon?: IconProps;
};

type SpecialEventCardPropsValues = {
  'Dark': SpecialEventCardInternalProps;
};

const propValues: SpecialEventCardPropsValues = {
  'Dark': {
    image: {
    },
    eventTypeTag: {
      type: 'CardholderExclusive',
    },
    eventStatusTag: {
      type: 'JustAnnounced',
    },
    titleText: {
      type: 'Body',
      size: 'Large',
      style: 'SemiBold',
      colour: 'BaseDark',
      align: 'Left',
    },
    descriptionText: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    dateAndTime: {
    },
    location: {
    },
    divider: {
      type: 'Horizontal',
      style: 'Thin',
      colour: 'DigitalGrey15',
    },
    acttionText: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'ActionHover',
      align: 'Left',
    },
    actionText: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'ActionHover',
      align: 'Left',
    },
    icon: {
      asset: 'ArrowRightFilled',
      style: 'ActionHover',
    },
  },
};

const getProps = (type: string): SpecialEventCardInternalProps => {
  const values: SpecialEventCardInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import React, { useContext } from 'react';
import { ListingsContext } from '../../../../listings';
import styles from './styles.module.scss';

const StaticImageMap: React.FC = () => {
  const { selectors: listingsSelectors } = useContext(ListingsContext);
  const { seatViewObject } = listingsSelectors;

  const altText: string = seatViewObject?.mapTitle ? `${seatViewObject.mapTitle} Venue Map` : 'Venue Map';
  return seatViewObject ? (
    <div className={styles.staticMapWrapper}>
      <img src={seatViewObject.staticMapUrl} alt={altText} className={styles.staticMap} />
    </div>
  ) : null;
};

export default StaticImageMap;
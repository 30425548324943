import { SliderTypeEnum } from './types';

/**
 * rounds a real number to based on a given precision
 * slider needs this to control {step} property
 * @param value number to be rounded
 * @param precision how many decimal cases, example: precision=2 can return 1.05
 * @returns rounded value
 */
export const round = (
  value,
  decimalPoints = 0,
) => {
  const p = Math.pow(10, decimalPoints);
  const n = (value * p) * (1 + Number.EPSILON);
  return Math.round(n) / p;
};

export const inRange = (x: number, range: number[]) => {
  return (x - range[0]) * (x - range[1]) <= 0;
};

export const defaultProps = {
  maxPoints: 100,
  sliderValue: 50,
  type: 'Default' as SliderTypeEnum,
};
import React from 'react';
import cx from 'classnames';

import Text from '../../atoms/Text';
import TextItem from '../../molecules/TextItem';

import styles from './CustomerInfoReadOnlyBlock.module.scss';
import usePresenter from './CustomerInfoReadOnlyBlock.presenter';
import getProps from './CustomerInfoReadOnlyBlock.props';
import { CustomerInfoReadOnlyBlockCombinedProps } from './types';


const CustomerInfoReadOnlyBlock: React.FC<CustomerInfoReadOnlyBlockCombinedProps> = (props) => {
  const {
    className,
    classes,
    blockTitle,
    edit,
    textItem,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.customerInfoReadOnlyBlock;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.topContent, classes?.topContent)}>
        <Text
          className={cx(styles.blockTitle, classes?.blockTitle)}
          {...internalProps.blockTitle}
          {...blockTitle}/>
        <Text
          className={cx(styles.edit, classes?.edit)}
          {...internalProps.edit}
          {...edit}/>
      </div>
      <TextItem
        className={cx(styles.textItem, classes?.textItem)}
        {...internalProps.textItem}
        {...textItem}/>
    </div>
  );
};

export default CustomerInfoReadOnlyBlock;


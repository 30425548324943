import { TextProps } from '../../atoms/Text';
import { EmptyStateBannerProps } from '../../molecules/EmptyStateBanner';
import { TabListProps } from '../../molecules/TabList';
import { EventsCardListProps } from '../../organisms/EventsCardList';


type MyTicketsBlockInternalProps = {
  title?: TextProps;
  tabList?: TabListProps;
  emptyStateBanner?: EmptyStateBannerProps;
  eventsCardList?: EventsCardListProps;
};

type MyTicketsBlockPropsValues = {
  'Empty': MyTicketsBlockInternalProps;
  'Filled': MyTicketsBlockInternalProps;
};

const propValues: MyTicketsBlockPropsValues = {
  'Empty': {
    title: {
      type: 'Subheading',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    tabList: {
    },
    emptyStateBanner: {
    },
  },
  'Filled': {
    title: {
      type: 'Subheading',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    tabList: {
    },
    eventsCardList: {
    },
  },
};

const getProps = (type: string): MyTicketsBlockInternalProps => {
  const values: MyTicketsBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

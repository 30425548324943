import { IconProps } from '../Icon';
import { TextProps } from '../Text';


type DropdownSelectInternalProps = {
  text?: TextProps;
  icon?: IconProps;
};

type DropdownSelectPropsValues = {
  'Empty': DropdownSelectInternalProps;
  'Filled': DropdownSelectInternalProps;
};

const propValues: DropdownSelectPropsValues = {
  'Empty': {
    text: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    icon: {
      asset: 'ChevronDown',
      style: 'DigitalGrey80',
    },
  },
  'Filled': {
    text: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
    icon: {
      asset: 'ChevronDown',
      style: 'DigitalGrey80',
    },
  },
};

const getProps = (type: string): DropdownSelectInternalProps => {
  const values: DropdownSelectInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import React from 'react';
import cx from 'classnames';

import Icon from '../Icon';
import Text from '../Text';

import styles from './DropdownSelect.module.scss';
import usePresenter from './DropdownSelect.presenter';
import getProps from './DropdownSelect.props';
import { DropdownSelectCombinedProps } from './types';


const DropdownSelect: React.FC<DropdownSelectCombinedProps> = (props) => {
  const {
    state,
    className,
    classes,
    disabled,
    buttonType,
    buttonForm,
    href,
    target,
    externalLink,
    onClick,
    text,
    icon,
    // MANUAL OVERRIDE STARTS
    dropdownItems,
    onChange,
    selection,
    ariaLabel,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const variantName = `${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`dropdownSelect${variantName}`];

  // MANUAL OVERRIDE STARTS
  const options: React.ReactNode[] = (dropdownItems || []).map((item) => {
    const selected = item.value.toLocaleLowerCase() === selection?.value.toLocaleLowerCase();
    return (<option className={cx(styles.option, styles.customOption)}
      selected={selected}
      aria-label={selected ? item.ariaLabel : ''}
      key={item.value}
      value={item.value}>
      {item.label || item.value}
    </option>);
  });
  // MANUAL OVERRIDE ENDS

  let componentView;

  switch (variantName) {
    case 'Empty':
    case 'Filled': {
      componentView = (
        <select onChange={onChange} className={cx(currentStyle, styles.customSelect, className)} aria-label={ariaLabel}>  {/* MANUAL OVERRIDE */}
          {options}
        </select>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default DropdownSelect;


import { ButtonProps } from '../../atoms/Button';
import { TextProps } from '../../atoms/Text';

type OnBoardingContentInternalProps = {
  button?: ButtonProps;
  footer?: TextProps;
};

type OnBoardingContentPropsValues = {
  '': OnBoardingContentInternalProps;
};

const propValues: OnBoardingContentPropsValues = {
  '': {
    button: {
      type: 'Text',
      style: 'Contained',
      size: 'Medium',
      text: {
        type: 'ButtonText',
        size: 'Medium',
        style: 'Regular',
        colour: 'BaseLight',
        align: 'Left',
      },
    } as ButtonProps,
    footer: {
      type: 'Body',
      colour: 'BaseLight',
      align: 'Left',
      size: 'Medium',
      style: 'Regular',
    } as TextProps,
  },
};

const getProps = (type: string): OnBoardingContentInternalProps => {
  const values: OnBoardingContentInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
import React, { RefObject } from 'react';
import cx from 'classnames';


import customStyles from './Custom.module.scss';
import styles from './Image.module.scss';
import usePresenter from './Image.presenter';
import getProps from './Image.props';
import { ImageCombinedProps } from './types';
import { useSnapItem } from '../../../modules/snap/SnapListContext/useSnapItem';


const Image: React.FC<ImageCombinedProps> = (props) => {
  const {
    className,
    classes,
    imageSrc,
    imageSrcAlt,
    imageFallback,
    // MANUAL OVERRIDE STARTS
    imageSize,
    index,
    overlay,
    position,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const { elementRef } = useSnapItem(index);

  const internalProps = getProps('');
  const currentStyle = styles.image;

  return (
    // MANUAL OVERRIDE STARTS
    <div className={cx(currentStyle, className)} style={{ 
      backgroundRepeat: 'no-repeat',
      backgroundImage: `url(${imageSrc}), url(${imageFallback})`,
      backgroundSize: imageSize || 'cover',
      backgroundPosition: position,
    }}
    ref={elementRef as RefObject<HTMLDivElement>}
    >
      {
        overlay &&
        <div className={cx(customStyles.overlay)} style={{
          backgroundImage: overlay,
        }} />
      }
    </div>
    // MANUAL OVERRIDE ENDS
  );
};

export default Image;


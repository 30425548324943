import React from 'react';
import cx from 'classnames';

import SliderButton from '../../atoms/SliderButton';

import styles from './C1XEventDetailsSliderBlock.module.scss';
import usePresenter from './C1XEventDetailsSliderBlock.presenter';
import getProps from './C1XEventDetailsSliderBlock.props';
import { C1XEventDetailsSliderBlockCombinedProps } from './types';
import ImageList from '../../organisms/ImageList';


const C1XEventDetailsSliderBlock: React.FC<C1XEventDetailsSliderBlockCombinedProps> = (props) => {
  const {
    className,
    classes,
    // MANUAL OVERRIDE STARTS
    imageList,
    isSnapEnabled,
    slideBackwardButton,
    slideForwardButton,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.c1XEventDetailsSliderBlock;
  // MANUAL OVERRIDE STARTS
  let containerViewWrapper;
  const containerView = (
    <div className={cx(styles.container, classes?.container)}>
      <ImageList
        className={cx(styles.imageList, classes?.imageList)}
        {...internalProps.imageList}
        {...imageList} />
    </div>
  );

  if (isSnapEnabled) {
    containerViewWrapper = (
      <div className={classes?.containerWrapper}>
        {containerView}
        <SliderButton
          className={cx(styles.slideBackwardButton, classes?.slideBackwardButton)}
          {...internalProps.slideBackwardButton}
          {...slideBackwardButton} />
        <SliderButton
          className={cx(styles.slideForwardButton, classes?.slideForwardButton)}
          {...internalProps.slideForwardButton}
          {...slideForwardButton} />
      </div>
    );
  } else {
    containerViewWrapper = containerView;
  }

  return (
    <div className={cx(currentStyle, className)}>
      {containerViewWrapper}
    </div>
  );
  // MANUAL OVERRIDE ENDS
};

export default C1XEventDetailsSliderBlock;


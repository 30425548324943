import { ImageProps } from '../../atoms/Image';
import { IconProps } from '../../atoms/Icon';


type MenuButtonInternalProps = {
  cardImage?: ImageProps;
  icon?: IconProps;
};

type MenuButtonPropsValues = {
  'Default': MenuButtonInternalProps;
  'IconOnly': MenuButtonInternalProps;
};

const propValues: MenuButtonPropsValues = {
  'Default': {
    cardImage: {
    },
    icon: {
      asset: 'Menu',
      style: 'White',
    },
  },
  'IconOnly': {
    icon: {
      asset: 'Menu',
      style: 'White',
    },
  },
};

const getProps = (type: string): MenuButtonInternalProps => {
  const values: MenuButtonInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

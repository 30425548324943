import { EventCardVenueProps } from '../../molecules/EventCardVenue';


type EventCardVenueListInternalProps = {
  eventCardVenue?: EventCardVenueProps;
};

type EventCardVenueListPropsValues = {
  '': EventCardVenueListInternalProps;
};

const propValues: EventCardVenueListPropsValues = {
  '': {
    eventCardVenue: {
    },
  },
};

const getProps = (type: string): EventCardVenueListInternalProps => {
  const values: EventCardVenueListInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import { NavMenuItemProps } from '../../molecules/NavMenuItem';


type NavMenuListInternalProps = {
  navMenuItem?: NavMenuItemProps;
};

type NavMenuListPropsValues = {
  '': NavMenuListInternalProps;
};

const propValues: NavMenuListPropsValues = {
  '': {
    navMenuItem: {
      type: 'Next',
    },
  },
};

const getProps = (type: string): NavMenuListInternalProps => {
  const values: NavMenuListInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import { ButtonProps } from '../../atoms/Button';
import { IconProps } from '../../atoms/Icon';
import { TextProps } from '../../atoms/Text';


type FilterInternalProps = {
  icon?: IconProps;
  filterButton?: ButtonProps;
  text?: TextProps;
};

type FilterPropsValues = {
  'IconFilter': FilterInternalProps;
  'IconFilterClose': FilterInternalProps;
  'IconTextFilter': FilterInternalProps;
  'TextIconFilter': FilterInternalProps;
};

const propValues: FilterPropsValues = {
  'IconFilter': {
    icon: {
      asset: 'Calendar',
      style: 'ActionActive',
    },
    filterButton: {
      type: 'Text', // MANUAL OVERRIDE
      style: 'TextUnderline',
      size: 'Small',
    },
  },
  'IconFilterClose': {
    icon: {
      asset: 'Calendar',
      style: 'ActionActive',
    },
    filterButton: {
      type: 'Text', // MANUAL OVERRIDE
      style: 'TextUnderline',
      size: 'Small',
    },
  },
  'IconTextFilter': {
    icon: {
      asset: 'LocationMarker',
      style: 'ActionActive',
    },
    text: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'ActionHover',
      align: 'Left',
    },
    filterButton: {
      type: 'Text', // MANUAL OVERRIDE
      style: 'TextUnderline',
      size: 'Small',
    },
  },
  'TextIconFilter': {
    text: {
      type: 'Subheading',
      size: 'Medium',
      style: 'Regular',
      colour: 'ActionHover',
      align: 'Left',
    },
    filterButton: {
      type: 'Text', // MANUAL OVERRIDE
      style: 'Text',
      size: 'Medium',
    },
  },
};

const getProps = (type: string): FilterInternalProps => {
  const values: FilterInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import React from 'react';
import cx from 'classnames';


import styles from './Icon.module.scss';
import usePresenter from './Icon.presenter';
import getProps from './Icon.props';
import { IconCombinedProps } from './types';

import { ReactComponent as ArenaIcon } from '../../../resources/icons/Arena.svg';
import { ReactComponent as ArrowLeftIcon } from '../../../resources/icons/ArrowLeft.svg';
import { ReactComponent as ArrowRightFilledIcon } from '../../../resources/icons/ArrowRightFilled.svg';
import { ReactComponent as ArrowRightIcon } from '../../../resources/icons/ArrowRight.svg';
import { ReactComponent as CalendarIllustrationIcon } from '../../../resources/icons/CalendarIllustration.svg';
import { ReactComponent as CalendarLargeIcon } from '../../../resources/icons/CalendarLarge.svg';
import { ReactComponent as CalendarIcon } from '../../../resources/icons/Calendar.svg';
import { ReactComponent as CalenderNewIcon } from '../../../resources/icons/CalenderNew.svg';
import { ReactComponent as CheckboxEmptyIcon } from '../../../resources/icons/CheckboxEmpty.svg';
import { ReactComponent as CheckboxFilledIcon } from '../../../resources/icons/CheckboxFilled.svg';
import { ReactComponent as CheckmarkInCircleFilledIcon } from '../../../resources/icons/CheckmarkInCircleFilled.svg';
import { ReactComponent as CheckmarkInCircleIcon } from '../../../resources/icons/CheckmarkInCircle.svg';
import { ReactComponent as CheckmarkIcon } from '../../../resources/icons/Checkmark.svg';
import { ReactComponent as ChevronDownFilledIcon } from '../../../resources/icons/ChevronDownFilled.svg';
import { ReactComponent as ChevronDownIcon } from '../../../resources/icons/ChevronDown.svg';
import { ReactComponent as ChevronLeftFilledIcon } from '../../../resources/icons/ChevronLeftFilled.svg';
import { ReactComponent as ChevronLeftIcon } from '../../../resources/icons/ChevronLeft.svg';
import { ReactComponent as ChevronRightFilledIcon } from '../../../resources/icons/ChevronRightFilled.svg';
import { ReactComponent as ChevronRightIcon } from '../../../resources/icons/ChevronRight.svg';
import { ReactComponent as ChevronUpFilledIcon } from '../../../resources/icons/ChevronUpFilled.svg';
import { ReactComponent as ChevronUpIcon } from '../../../resources/icons/ChevronUp.svg';
import { ReactComponent as ClockLargeIcon } from '../../../resources/icons/ClockLarge.svg';
import { ReactComponent as ClockIcon } from '../../../resources/icons/Clock.svg';
import { ReactComponent as CloseCircleFillIcon } from '../../../resources/icons/CloseCircleFill.svg';
import { ReactComponent as CloseCircleIcon } from '../../../resources/icons/CloseCircle.svg';
import { ReactComponent as CloseIcon } from '../../../resources/icons/Close.svg';
import { ReactComponent as DeliveryIcon } from '../../../resources/icons/Delivery.svg';
import { ReactComponent as FootballIcon } from '../../../resources/icons/Football.svg';
import { ReactComponent as InfoFilledIcon } from '../../../resources/icons/InfoFilled.svg';
import { ReactComponent as InfoIcon } from '../../../resources/icons/Info.svg';
import { ReactComponent as LocationMarkerSmallIcon } from '../../../resources/icons/LocationMarkerSmall.svg';
import { ReactComponent as LocationMarkerIcon } from '../../../resources/icons/LocationMarker.svg';
import { ReactComponent as LocationPinFilledIcon } from '../../../resources/icons/LocationPinFilled.svg';
import { ReactComponent as LocationPinIcon } from '../../../resources/icons/LocationPin.svg';
import { ReactComponent as MailIcon } from '../../../resources/icons/Mail.svg';
import { ReactComponent as MasterCardIcon } from '../../../resources/icons/MasterCard.svg';
import { ReactComponent as MenuIcon } from '../../../resources/icons/Menu.svg';
import { ReactComponent as MinusIcon } from '../../../resources/icons/Minus.svg';
import { ReactComponent as MobileScanIcon } from '../../../resources/icons/MobileScan.svg';
import { ReactComponent as MobileIcon } from '../../../resources/icons/Mobile.svg';
import { ReactComponent as MyLocationIcon } from '../../../resources/icons/MyLocation.svg';
import { ReactComponent as PlusIcon } from '../../../resources/icons/Plus.svg';
import { ReactComponent as PrinterIcon } from '../../../resources/icons/Printer.svg';
import { ReactComponent as ProfileIcon } from '../../../resources/icons/Profile.svg';
import { ReactComponent as SearchSmallIcon } from '../../../resources/icons/SearchSmall.svg';
import { ReactComponent as SearchIcon } from '../../../resources/icons/Search.svg';
import { ReactComponent as SettingsIcon } from '../../../resources/icons/Settings.svg';
import { ReactComponent as ShirtIcon } from '../../../resources/icons/Shirt.svg';
import { ReactComponent as ThumbtackIcon } from '../../../resources/icons/Thumbtack.svg';
import { ReactComponent as TicketBigIcon } from '../../../resources/icons/TicketBig.svg';
import { ReactComponent as TicketCutIcon } from '../../../resources/icons/TicketCut.svg';
import { ReactComponent as TicketIcon } from '../../../resources/icons/Ticket.svg';
import { ReactComponent as TicketWithLinesIcon } from '../../../resources/icons/TicketWithLines.svg';
import { ReactComponent as VSIcon } from '../../../resources/icons/VS.svg';
import { ReactComponent as VisaIcon } from '../../../resources/icons/Visa.svg';
import { ReactComponent as WarningOutlineIcon } from '../../../resources/icons/WarningOutline.svg';
import { ReactComponent as WarningIcon } from '../../../resources/icons/Warning.svg';
import { ReactComponent as IconIcon } from '../../../resources/icons/Icon.svg';

const Icon: React.FC<IconCombinedProps> = (props) => {
  const {
    asset,
    style,
    className,
    classes,
    assetAlt,
  } = usePresenter(props);

  const variantName = `${asset}${style}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`icon${variantName}`];


  let componentView;

  switch (variantName) {
    case 'ArenaBaseDark':
    case 'ArenaWhite': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ArenaIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'ArrowLeftActionBase':
    case 'ArrowLeftBaseDark':
    case 'ArrowLeftWhite': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ArrowLeftIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'ArrowRightFilledActionBase':
    case 'ArrowRightFilledActionHover':
    case 'ArrowRightFilledWhite': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ArrowRightFilledIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'ArrowRightActionBase':
    case 'ArrowRightWhite': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ArrowRightIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'CalendarLargeDigitalGrey30': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <CalendarLargeIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'CalendarActionActive': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <CalendarIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'CalenderNewSubduedDark': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <CalenderNewIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'CheckboxFilledDigitalGrey100': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <CheckboxFilledIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'CheckmarkInCircleFilledGreen60': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <CheckmarkInCircleFilledIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'CheckmarkInCircleDigitalGrey80': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <CheckmarkInCircleIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'CheckmarkActionActive':
    case 'CheckmarkActionBase':
    case 'CheckmarkDigitalGrey80':
    case 'CheckmarkWhite': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <CheckmarkIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'ChevronDownFilledDigitalGrey100': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ChevronDownFilledIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'ChevronDownActionActive':
    case 'ChevronDownActionHover':
    case 'ChevronDownDigitalGrey80':
    case 'ChevronDownWhite': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ChevronDownIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'ChevronLeftFilledDigitalGrey100': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ChevronLeftFilledIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'ChevronLeftActionActive':
    case 'ChevronLeftActionBase':
    case 'ChevronLeftActionHover':
    case 'ChevronLeftDigitalGrey100':
    case 'ChevronLeftWhite': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ChevronLeftIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'ChevronRightFilledDigitalGrey100': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ChevronRightFilledIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'ChevronRightActionActive':
    case 'ChevronRightActionBase':
    case 'ChevronRightDigitalGrey100':
    case 'ChevronRightWhite': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ChevronRightIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'ChevronUpFilledDigitalGrey100': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ChevronUpFilledIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'ChevronUpActionActive':
    case 'ChevronUpActionHover':
    case 'ChevronUpDigitalGrey80':
    case 'ChevronUpWhite': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ChevronUpIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'ClockLargeDigitalGrey60': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ClockLargeIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'ClockBaseDark': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ClockIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'CloseCircleFillDigitalGrey100': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <CloseCircleFillIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'CloseCircleWhite': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <CloseCircleIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'CloseActionBase':
    case 'CloseDigitalGrey80':
    case 'CloseWhite': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <CloseIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'DeliveryDigitalGrey80': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <DeliveryIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'FootballDigitalGrey30': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <FootballIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'InfoDigitalGrey80':
    case 'InfoWhite': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <InfoIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'LocationMarkerSmallWhite': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <LocationMarkerSmallIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'LocationMarkerActionActive': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <LocationMarkerIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'LocationPinFilledSubduedDark': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <LocationPinFilledIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'LocationPinActionBase':
    case 'LocationPinDigitalGrey100':
    case 'LocationPinWhite': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <LocationPinIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'MailDigitalGrey80': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <MailIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'MenuWhite': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <MenuIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'MobileScanDigitalGrey80': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <MobileScanIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'MobileDigitalGrey80': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <MobileIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'MyLocationActionBase':
    case 'MyLocationDigitalGrey100':
    case 'MyLocationWhite': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <MyLocationIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'PlusActionActive':
    case 'PlusActionBase':
    case 'PlusDigitalGrey60': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <PlusIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'PrinterActionActive':
    case 'PrinterActionBase': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <PrinterIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'ProfileDigitalGrey80': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ProfileIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'SearchSmallCharcoal20':
    case 'SearchSmallWhite': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <SearchSmallIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'SearchBaseDark': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <SearchIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'SettingsDigitalGrey60': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <SettingsIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'ShirtBaseDark':
    case 'ShirtWhite': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ShirtIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'TicketBigDigitalGrey30': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <TicketBigIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'TicketCutDigitalGrey80': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <TicketCutIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'TicketDigitalGrey80': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <TicketIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'TicketWithLinesDigitalGrey80': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <TicketWithLinesIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'VSActionActive': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <VSIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'WarningOutlineDigitalGrey60': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <WarningOutlineIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'WarningDigitalGrey80': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <WarningIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'CalendarIllustrationDigitalGrey80': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <CalendarIllustrationIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'CheckboxEmptyDigitalGrey100': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <CheckboxEmptyIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'CloseCircleActionHover':
    case 'CloseCircleDigitalGrey100': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <CloseCircleIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'InfoFilledDigitalGrey80': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <InfoFilledIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'MasterCardDigitalGrey80': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <MasterCardIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'VisaDigitalGrey80': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <VisaIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'MinusActionBase':
    case 'MinusDigitalGrey60': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <MinusIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'ThumbtackDigitalGrey30': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ThumbtackIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default Icon;


import { IconProps } from '../../atoms/Icon';
import { TextProps } from '../../atoms/Text';
import { ContentProps } from '../Content';


type RadioItemInternalProps = {
  icon?: IconProps;
  title?: TextProps;
  text?: TextProps;
  content1?: ContentProps;
};

type RadioItemPropsValues = {
  'ApplyDefault': RadioItemInternalProps;
  'ApplySelected': RadioItemInternalProps;
  'NotApplyDefault': RadioItemInternalProps;
  'NotApplySelected': RadioItemInternalProps;
};

const propValues: RadioItemPropsValues = {
  'ApplyDefault': {
    icon: {
      asset: 'RadioEmpty',
      style: 'DigitalGrey100',
    },
    title: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    content1: {
      state: 'Default',
    },
  },
  'ApplySelected': {
    icon: {
      asset: 'RadioFilled',
      style: 'DigitalGrey100',
    },
    title: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    content1: {
      state: 'Default',
    },
  },
  'NotApplyDefault': {
    icon: {
      asset: 'RadioEmpty',
      style: 'DigitalGrey100',
    },
    title: {
      type: 'Body',
      size: 'Medium',
      style: 'SemiBold',
      colour: 'BaseDark',
      align: 'Left',
    },
  },
  'NotApplySelected': {
    icon: {
      asset: 'RadioFilled',
      style: 'DigitalGrey100',
    },
    title: {
      type: 'Body',
      size: 'Medium',
      style: 'SemiBold',
      colour: 'BaseDark',
      align: 'Left',
    },
  },
};

const getProps = (type: string): RadioItemInternalProps => {
  const values: RadioItemInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

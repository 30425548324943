import React from 'react';
import cx from 'classnames';

import LandingPageBanner from '../../molecules/LandingPageBanner';

import styles from './LandingPageBannerBlock.module.scss';
import usePresenter from './LandingPageBannerBlock.presenter';
import getProps from './LandingPageBannerBlock.props';
import { LandingPageBannerBlockCombinedProps } from './types';


const LandingPageBannerBlock: React.FC<LandingPageBannerBlockCombinedProps> = (props) => {
  const {
    className,
    classes,
    landingPageBanner,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.landingPageBannerBlock;

  return (
    <div className={cx(currentStyle, className)}>
      <LandingPageBanner
        className={cx(styles.landingPageBanner, classes?.landingPageBanner)}
        {...internalProps.landingPageBanner}
        {...landingPageBanner} />
    </div>
  );
};

export default LandingPageBannerBlock;


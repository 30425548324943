import { EventCardsBlockProps } from '../../blocks/EventCardsBlock';
import { FiltersBlockProps } from '../../blocks/FiltersBlock';
import { PrimaryBannerBlockProps } from '../../blocks/PrimaryBannerBlock';
import { SecondaryBannerDarkBlockProps } from '../../blocks/SecondaryBannerDarkBlock';


type ExclusivesEventsLandingPageInternalProps = {
  primaryBannerBlock?: PrimaryBannerBlockProps;
  exclusiveEvents?: EventCardsBlockProps;
  filtersBlock?: FiltersBlockProps;
  sportsEvents?: EventCardsBlockProps;
  musicEvents?: EventCardsBlockProps;
  diningEvents?: EventCardsBlockProps;
  getawaysEvents?: EventCardsBlockProps;
  artTheaterEvents?: EventCardsBlockProps;
  secondaryBannerDarkBlock?: SecondaryBannerDarkBlockProps;
};

type ExclusivesEventsLandingPagePropsValues = {
  '': ExclusivesEventsLandingPageInternalProps;
};

const propValues: ExclusivesEventsLandingPagePropsValues = {
  '': {
    primaryBannerBlock: {
    },
    exclusiveEvents: {
      type: 'CardSpecificEventsDarkBlock',
      state: 'Default',
    },
    filtersBlock: {
    },
    sportsEvents: {
      type: 'EventsDarkBlock',
      state: 'Default',
    },
    musicEvents: {
      type: 'EventsDarkBlock',
      state: 'Default',
    },
    diningEvents: {
      type: 'EventsDarkBlock',
      state: 'Default',
    },
    getawaysEvents: {
      type: 'EventsDarkBlock',
      state: 'Default',
    },
    artTheaterEvents: {
      type: 'EventsDarkBlock',
      state: 'Default',
    },
    secondaryBannerDarkBlock: {
    },
  },
};

const getProps = (type: string): ExclusivesEventsLandingPageInternalProps => {
  const values: ExclusivesEventsLandingPageInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

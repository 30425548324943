import { DividerProps } from '../../atoms/Divider';
import { SportsMenuTabListProps } from '../../molecules/SportsMenuTabList';
import { SubmenuItemListProps } from '../../molecules/SubmenuItemList';


type SubmenuWithTabsInternalProps = {
  divider?: DividerProps;
  sportsMenuTabList?: SportsMenuTabListProps;
  divider1?: DividerProps;
  submenuItemList?: SubmenuItemListProps;
  divider2?: DividerProps;
};

type SubmenuWithTabsPropsValues = {
  '': SubmenuWithTabsInternalProps;
};

const propValues: SubmenuWithTabsPropsValues = {
  '': {
    divider: {
      type: 'Horizontal',
      style: 'Thin',
      colour: 'White',
    },
    sportsMenuTabList: {
    },
    divider1: {
      type: 'Vertical',
      style: 'Thin',
      colour: 'White',
    },
    submenuItemList: {
    },
    divider2: {
      type: 'Horizontal',
      style: 'Thin',
      colour: 'White',
    },
  },
};

const getProps = (type: string): SubmenuWithTabsInternalProps => {
  const values: SubmenuWithTabsInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

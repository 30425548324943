import { ButtonProps } from '../../atoms/Button';
import { DividerProps } from '../../atoms/Divider';
import { IconProps } from '../../atoms/Icon';


type CardNavigationInternalProps = {
  button?: ButtonProps;
  divider?: DividerProps;
  icon?: IconProps;
};

type CardNavigationPropsValues = {
  'DefaultLightBackground': CardNavigationInternalProps;
  'WithCloseOptionLightBackground': CardNavigationInternalProps;
};

const propValues: CardNavigationPropsValues = {
  'DefaultLightBackground': {
    button: {
      type: 'IconText',
      style: 'Text',
      size: 'Small',
    },
    divider: {
      type: 'Horizontal',
      style: 'Thin',
      colour: 'DigitalGrey15',
    },
  },
  'WithCloseOptionLightBackground': {
    button: {
      type: 'IconText',
      style: 'Text',
      size: 'Small',
    },
    icon: {
      asset: 'Close',
      style: 'DigitalGrey80',
    },
    divider: {
      type: 'Horizontal',
      style: 'Thin',
      colour: 'DigitalGrey10',
    },
  },
};

const getProps = (type: string): CardNavigationInternalProps => {
  const values: CardNavigationInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import React from 'react';

import Footer from '../../components/organisms/Footer';
import TermsAndPrivacyModal from '../../components/organisms/TermsAndPrivacy';
import TopNav from '../../components/organisms/TopNav/TopNav';
import ErrorBlock from '../../legacy/blocks/ErrorBlock';
import { Logout } from '../auth/AuthContext';
import styles from './ErrorHandler.module.scss';
import usePresenter from './ErrorHandler.presenter';
import { NotAccessibleEmailErrorMessage, NotEligibleCardErrorMessage } from './constants';
import { ApiError, ErrorHandlerProps } from './types';
import { useAnalyticsManager } from '../analytics/useAnalyticsManager';
import { trackErrorEvent } from '../analytics/util';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ErrorHandler: React.FC<ErrorHandlerProps> = (props) => {
  const {
    error,
    shouldLogout,
    isAuthError,
    errorBlockProps,
    termsAndPrivacyModalProps,
    notFountErrorBlockProps,
    notAccessibleErrorBlockProps,
    notAccessibleEmailErrorBlockProps,
    notEligibleCardErrorBlockProps,
  } = usePresenter(props);

  const { trackEvent } = useAnalyticsManager();

  if (shouldLogout) {
    return <Logout />;
  }

  const isAPIError = ApiError.isApiError(error);
  if (isAPIError) {
    trackErrorEvent(
      trackEvent,
      error.code,
      error.message,
    );
    if (error.code === 401) {
      return <Logout />;
    } else if (
      isAuthError &&
      (error.code === 406 ||
        error.code === 403 ||
        error.code === 404 ||
        error.code === 400)
    ) {
      let blockProps = errorBlockProps;
      if (error.code === 404 || error.code === 400) {
        blockProps = notFountErrorBlockProps;
      }
      if (error.code === 403) {
        blockProps = notAccessibleErrorBlockProps;
      }
      if (error.code === 406 && error.message.includes(NotEligibleCardErrorMessage)) {
        blockProps = notEligibleCardErrorBlockProps;
      }
      if (error.code === 406 && error.message.includes(NotAccessibleEmailErrorMessage)) {
        blockProps = notAccessibleEmailErrorBlockProps;
      }
      return (
        <div className={styles.errorContainer}>
          <div className={styles.navContainer}>
            <TopNav type="SignedOut" className={styles.topNav} />
          </div>
          <ErrorBlock className={styles.errorBlock} {...blockProps} />
          <Footer />
          <TermsAndPrivacyModal {...termsAndPrivacyModalProps} />
        </div>
      );
    }
  }

  return <div>{error?.message}</div>;
};

export default ErrorHandler;

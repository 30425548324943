import React from 'react';
import cx from 'classnames';

import AdditionalTicketInformationList from '../../molecules/AdditionalTicketInformationList';
import CardInfoHeader from '../../molecules/CardInfoHeader';
import ExclusiveEventBanner from '../../molecules/ExclusiveEventBanner';
import TicketQuantitySelection from '../../molecules/TicketQuantitySelection';
import TicketSelectionInformation from '../../molecules/TicketSelectionInformation';

import styles from './GeneralPrecheckoutBlock.module.scss';
import usePresenter from './GeneralPrecheckoutBlock.presenter';
import getProps from './GeneralPrecheckoutBlock.props';
import { GeneralPrecheckoutBlockCombinedProps } from './types';
import TopNav from '../../organisms/TopNav';
import TicketInformation from '../../molecules/TicketInformation';


const GeneralPrecheckoutBlock: React.FC<GeneralPrecheckoutBlockCombinedProps> = (props) => {
  const {
    className,
    classes,
    exclusiveEventBanner,
    cardInfoHeader,
    additionalTicketInformationList,
    ticketQuantitySelection,
    // MANUAL OVERRIDE STARTS
    topNav,
    ticketInformation,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.generalPrecheckoutBlock;

  return (
    <div className={cx(currentStyle, className)}>
      <TopNav {...topNav} />
      <ExclusiveEventBanner
        className={cx(styles.exclusiveEventBanner, classes?.exclusiveEventBanner)}
        {...internalProps.exclusiveEventBanner}
        {...exclusiveEventBanner}
      />
      <CardInfoHeader
        className={cx(styles.cardInfoHeader, classes?.cardInfoHeader)}
        {...internalProps.cardInfoHeader}
        {...cardInfoHeader}/>
      <TicketInformation
        className={styles.ticketInformation}
        {...internalProps.ticketInformation}
        {...ticketInformation}
      />
      <AdditionalTicketInformationList
        className={cx(styles.additionalTicketInformationList, classes?.additionalTicketInformationList)}
        {...internalProps.additionalTicketInformationList}
        {...additionalTicketInformationList}
      />
      <div className={styles.bottomContent}>
        <TicketQuantitySelection
          className={cx(styles.ticketQuantitySelection, classes?.ticketQuantitySelection)}
          {...internalProps.ticketQuantitySelection}
          {...ticketQuantitySelection}
        />
      </div>
    </div>
  );
};

export default GeneralPrecheckoutBlock;


import React from 'react';
import cx from 'classnames';

import Divider from '../../atoms/Divider';
import SubmenuList from '../../molecules/SubmenuList';

import styles from './Submenu.module.scss';
import usePresenter from './Submenu.presenter';
import getProps from './Submenu.props';
import { SubmenuCombinedProps } from './types';


const Submenu: React.FC<SubmenuCombinedProps> = (props) => {
  const {
    className,
    classes,
    divider,
    submenuList,
    isOpen, // MANUAL OVERRIDE
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.submenu;

  return (
    <div className={cx(currentStyle, className)}>
      <Divider
        className={cx(styles.divider, classes?.divider)}
        {...internalProps.divider}
        {...divider}/>
      <SubmenuList
        focusable={isOpen} // MANUAL OVERRIDE
        className={cx(styles.submenuList, classes?.submenuList)}
        {...internalProps.submenuList}
        {...submenuList}/>
    </div>
  );
};

export default Submenu;


import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  Svg,
  Path,
  Defs,
  ClipPath,
  View,
} from '@react-pdf/renderer';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Style } from '@react-pdf/types';
import * as React from 'react';
import { ReceiptBlockCombinedProps } from '../../../blocks/ReceiptBlock/types';
export interface DocumentProps {
  receiptBlock?: ReceiptBlockCombinedProps;
}

const mainTitleStyles: Style = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  flex: '0 0 auto',
};

const mainBlockStyles: Style = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  alignSelf: 'flex-start',
};

const sectionTitleStyles: Style = {
  ...mainTitleStyles,
  fontSize: 16,
  color: '#696969',
};
const dataLabelStyles: Style = {
  ...mainTitleStyles,
  marginTop: 15,
  fontSize: 10,
  color: '#696969',
};
const dataTextStyles: Style = {
  ...mainTitleStyles,
  fontSize: 12,
  color: 'black',
};

export const styles = StyleSheet.create({
  font: { fontFamily: 'Optimist, sans-serif' },
  border: { left: 10 },
  image: {
    width: 209,
    marginTop: 37,
    marginLeft: 50,
  },
  mainTitleStyles,
  receiptBlock: {
    ...mainBlockStyles,
  },
  middlePart: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    alignSelf: 'flex-start',
    height: '48%',
  },
  primaryOrdertitle: {
    ...mainTitleStyles,
    color: '#000000',
    marginLeft: 50,
    marginTop: 30,
    marginBottom: 15,
    fontSize: 20,
    fontWeight: 400,
    textAlign: 'center',
  },
  infoHeader: {
    ...mainBlockStyles,
    marginLeft: 50,
  },
  secondaryOrderTitle: {
    ...mainTitleStyles,
    fontSize: 20,
    fontWeight: 400,
    marginTop: 30,
    color: '#444649',
  },
  orderNumber: {
    ...mainTitleStyles,
    marginTop: 20,
    fontSize: 14,
    color: '#696969',
  },
  divider: {
    ...mainBlockStyles,
    height: 1.3,
    backgroundColor: '#d4d4d4',
    marginTop: 40,
    paddingRight: 350,
    paddingLeft: 140,
    marginLeft: 50,
  },
  verticalDivider: {
    ...mainBlockStyles,
    backgroundColor: '#d4d4d4',
    marginTop: 15,
    marginLeft: 0,
    paddingTop: 230,
    paddingBottom: 30,
    width: 1.3,
    display: 'flex',
    position: 'relative',
  },
  ticketContent: {
    ...mainBlockStyles,
    marginTop: 25,
    paddingLeft: 50,
    paddingBottom: 30,
    width: '55%',
  },
  deliveryContent: {
    ...mainBlockStyles,
    marginTop: 25,
    marginLeft: 18,
    paddingBottom: 30,
  },
  ticketContentText: {
    ...mainTitleStyles,
    color: '#696969',
    fontSize: 16,
  },
  infoDetails: {
    ...mainBlockStyles,
    marginTop: 35,
  },
  infoDetailTitle: {
    ...mainTitleStyles,
    color: '#444649',
    fontSize: 14,
    paddingBottom: 15,
  },
  eventLogistics: {
    ...mainBlockStyles,
    marginTop: 30,
  },
  dateAndTime: {
    ...mainTitleStyles,
    color: '#444649',
    fontSize: 12,
  },
  time: {
    ...mainTitleStyles,
    flex: '1 1 auto',
  },
  venue: {
    ...mainTitleStyles,
    marginTop: 15,
    color: '#444649',
    fontSize: 12,
  },
  seatInfo: {
    ...mainTitleStyles,
    marginTop: 15,
  },
  seatInfoSection: {
    ...mainBlockStyles,
    marginTop: 20,
  },
  seatInfoSectionSiblings: {
    ...mainBlockStyles,
    marginTop: 20,
    marginLeft: '30',
  },
  seatInfoSectionLabel: {
    ...mainTitleStyles,
    fontSize: 10,
    color: '#696969',
  },
  seatInfoSectionDescription: {
    ...mainTitleStyles,
    fontSize: 12,
    color: '#353639',
    marginTop: 13,
  },
  sellerNotes: {
    ...mainBlockStyles,
    marginTop: 70,
    width: '90%',
  },

  additionalTicketInformationList: {
    display: 'flex',
    flexdirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginTop: 15,
    alignSelf: 'stretch',
  },
  additionalTicketInformationListText: {
    ...mainTitleStyles,
    color: '#696969',
    fontSize: 16,
    marginLeft: 5,
  },
  icon: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'flex-start',
    flexWrap: 'nowrap',
    marginTop: 12,
  },

  deliveryIcon: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    width: '35%',
  },
  deliveryText: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    alignSelf: 'stretch',
    flexWrap: 'wrap',
    fontSize: 11,
    color: '#444649',
    marginTop: 5,
    marginLeft: 6,
  },
  deliveryNotes: {
    ...mainBlockStyles,
    marginTop: 20,
    marginLeft: 5,
  },
  capitalOneLogo: {
    marginTop: 10,
  },
  iconLabel: {
    ...mainTitleStyles,
    fontSize: 10,
    color: '#696969',
    marginLeft: 50,
  },
  imageLower: {
    height: 55,
    width: 209,
    marginTop: 4,
    marginLeft: 50,
  },

  // Third section
  ticketNotice: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'stretch',
    border: '3 solid black',
    marginLeft: 50,
    position: 'absolute',
    top: 530,
    width: '84%',
  },
  noticeText: {
    fontWeight: 260,
    fontSize: 12,
    padding: 10,
  },
  billingSummarySection: {
    ...mainBlockStyles,
    marginLeft: 50,
    position: 'absolute',
    top: 575,
  },
  billingSummaryTitle: {
    ...sectionTitleStyles,
    marginTop: 25,
  },
  billingSummaryItems: {
    display: 'flex',
    flexDirection: 'row',
  },
  billingSummaryData: {
    marginRight: 70,
    display: 'flex',
    flexDirection: 'column',
  },
  billingSummaryLabel: {
    ...dataLabelStyles,
  },
  billingSummaryLine: {
    ...dataTextStyles,
    color: '#444649',
    marginTop: 10,
  },
  billingSummaryLongLine: {
    ...dataTextStyles,
    marginTop: 10,
    lineHeight: 1.5,
    color: '#444649',
  },
  spacing: {
    marginTop: 1,
  },
});

const fontSrc = '../../../../resources/fonts/Optimist/Optimist_W_Rg.woff)';
Font.register({ family: 'Optimist', src: fontSrc });
const PDFDocument: React.FC<DocumentProps> = (props) => {
  return (
    <Document>
      <Page size="A4" style={[styles.receiptBlock]}>
        <Svg width="123" viewBox="0 0 123 20" style={styles.image}>
          <Path
            d="M37.2336 14.1933C37.2315 14.1955 37.2293 14.1976 37.2272 14.1976C34.8907 15.8454 32.1232 17.5769 29.1466 19.3943L29.028 19.4631C28.9892 19.491 28.9785 19.5468 29.0065 19.5855C29.0345 19.6263 29.0905 19.6349 29.1293 19.607L29.2285 19.5554C31.7525 18.2428 34.6536 16.6832 37.5699 15.0849C37.585 15.0763 37.6 15.0677 37.6173 15.0591C37.4599 14.797 37.3263 14.5091 37.2336 14.1933ZM52.565 1.8235C48.8835 -2.22387 25.1914 1.41748 15.115 3.61087L14.88 3.66243C14.8326 3.67103 14.8003 3.71829 14.8067 3.76555C14.8154 3.81496 14.8606 3.84719 14.9102 3.83859L15.143 3.79348C23.4908 2.31331 40.8504 0.257403 44.5857 3.9503C45.7259 5.076 45.4543 6.52824 44.133 8.23828C44.8465 8.69371 45.353 9.41124 45.5815 10.3092C50.7393 6.88701 54.2052 3.62806 52.565 1.8235Z"
            fill="#CC2427"
          />
          <Path
            d="M60.7857 12.7108L60.3094 15.1642H63.5791L63.4239 15.9676H59.1519L60.5594 8.74939H64.812L64.6568 9.55285H61.4086L60.9431 11.9031H63.7666L63.6114 12.7065H60.7857V12.7108Z"
            fill="#004A79"
          />
          <Path
            d="M67.739 15.9695L68.4115 12.5258C68.4395 12.3603 68.4525 12.2207 68.4525 12.1026C68.4525 11.8491 68.3727 11.6536 68.2154 11.5204C68.0559 11.3872 67.8253 11.3184 67.5213 11.3184C67.3209 11.3184 67.1075 11.3507 66.879 11.4172C66.6506 11.4817 66.4436 11.5698 66.2583 11.6793L65.4198 15.9695H64.4779L65.491 10.7921L66.2669 10.7105V11.0606C66.8316 10.7384 67.3662 10.5751 67.8705 10.5751C68.3598 10.5751 68.7391 10.6976 69.0086 10.9403C69.278 11.1852 69.4116 11.5225 69.4116 11.9565C69.4116 12.1348 69.3944 12.3045 69.3599 12.4613L68.6766 15.9673H67.739V15.9695Z"
            fill="#004A79"
          />
          <Path
            d="M72.1769 11.413L71.6187 14.2788C71.5842 14.4571 71.567 14.6225 71.567 14.7729C71.567 15.1725 71.7976 15.3701 72.261 15.3701C72.5434 15.3701 72.8128 15.3293 73.0671 15.2455L72.9335 15.9372C72.6231 16.0404 72.302 16.0919 71.9722 16.0919C71.5583 16.0919 71.2264 15.9845 70.9785 15.7718C70.7307 15.5591 70.6057 15.2734 70.6057 14.9168C70.6057 14.7321 70.6229 14.5516 70.6574 14.3797L71.2372 11.4108H70.4828L70.6078 10.762L71.4139 10.7319L71.7459 9.44298L72.5843 9.33986L72.3149 10.7319H73.7331L73.5995 11.413H72.1769Z"
            fill="#004A79"
          />
          <Path
            d="M77.5978 15.774C77.3779 15.8835 77.1257 15.9695 76.8434 16.0318C76.561 16.0941 76.2851 16.1241 76.0157 16.1241C75.326 16.1241 74.7958 15.9394 74.4272 15.572C74.0586 15.2047 73.8732 14.6762 73.8732 13.9887C73.8732 13.3228 73.9983 12.7277 74.2461 12.2057C74.494 11.6836 74.8238 11.2819 75.2333 10.9983C75.6428 10.7169 76.0976 10.5751 76.5934 10.5751C77.1581 10.5751 77.6021 10.7255 77.9232 11.0284C78.2444 11.3313 78.4039 11.7459 78.4039 12.2766C78.4039 12.6482 78.3349 13.0714 78.197 13.544H74.8346C74.8281 13.6257 74.8216 13.7116 74.813 13.7975C74.8065 13.8835 74.8022 13.9673 74.8022 14.051C74.8022 14.9383 75.257 15.3808 76.1687 15.3808C76.4446 15.3808 76.7227 15.3508 77.0007 15.2928C77.2788 15.2347 77.5266 15.1574 77.74 15.0607L77.5978 15.774ZM76.5438 11.2669C76.1709 11.2669 75.8497 11.4087 75.576 11.6901C75.3044 11.9715 75.1018 12.3668 74.9703 12.8759H77.4641C77.5051 12.6761 77.5266 12.4806 77.5266 12.2873C77.5266 11.95 77.4447 11.6965 77.2788 11.5247C77.1128 11.3549 76.8671 11.2669 76.5438 11.2669Z"
            fill="#004A79"
          />
          <Path
            d="M78.891 15.9695L79.904 10.7922L80.68 10.7105V11.1853C81.1908 10.8008 81.6865 10.6074 82.1693 10.6074C82.3547 10.6074 82.5142 10.6311 82.6457 10.6805L82.4905 11.4538C82.3245 11.413 82.1737 11.3915 82.0357 11.3915C81.5529 11.3915 81.0873 11.5462 80.639 11.8556L79.8329 15.9695H78.891Z"
            fill="#004A79"
          />
          <Path
            d="M84.6546 11.413L84.0964 14.2788C84.0619 14.4571 84.0446 14.6225 84.0446 14.7729C84.0446 15.1725 84.2753 15.3701 84.7387 15.3701C85.021 15.3701 85.2905 15.3293 85.5448 15.2455L85.4112 15.9372C85.1008 16.0404 84.7796 16.0919 84.4499 16.0919C84.036 16.0919 83.7041 15.9845 83.4562 15.7718C83.2083 15.5591 83.0833 15.2734 83.0833 14.9168C83.0833 14.7321 83.1006 14.5516 83.1351 14.3797L83.7149 11.4108H82.9605L83.0855 10.762L83.8916 10.7319L84.2235 9.44298L85.062 9.33986L84.7926 10.7319H86.2108L86.0772 11.413H84.6546Z"
            fill="#004A79"
          />
          <Path
            d="M89.3102 15.9914V15.6413C88.7455 15.9635 88.2109 16.1268 87.7066 16.1268C87.2302 16.1268 86.853 15.9957 86.5728 15.7358C86.2926 15.4737 86.1547 15.1214 86.1547 14.6745C86.1547 14.3179 86.2495 14.0021 86.4392 13.7314C86.6289 13.4608 86.8854 13.2502 87.2108 13.102C87.5341 12.9538 87.8962 12.8807 88.2971 12.8807C88.7519 12.8807 89.2283 12.943 89.724 13.0655L89.8275 12.5284C89.849 12.4038 89.8576 12.2942 89.8576 12.1976C89.8576 11.9097 89.7563 11.6906 89.5516 11.5423C89.349 11.3941 89.0537 11.3211 88.6679 11.3211C88.0601 11.3211 87.5083 11.4199 87.0125 11.6197L87.1462 10.9193C87.685 10.6916 88.2562 10.5799 88.864 10.5799C89.4783 10.5799 89.9525 10.7109 90.2866 10.9709C90.6206 11.233 90.7888 11.6003 90.7888 12.0751C90.7888 12.2599 90.7715 12.4317 90.737 12.5907L90.0861 15.912L89.3102 15.9914ZM88.0579 15.3921C88.2713 15.3921 88.4933 15.3598 88.7196 15.2932C88.9459 15.2266 89.155 15.1364 89.3404 15.0204L89.599 13.6691C89.2326 13.5789 88.8532 13.5359 88.461 13.5359C88.0342 13.5359 87.7044 13.6262 87.4738 13.8088C87.2432 13.9914 87.1268 14.2535 87.1268 14.5972C87.1268 15.1278 87.4371 15.3921 88.0579 15.3921Z"
            fill="#004A79"
          />
          <Path
            d="M91.4614 15.9697L92.4658 10.8245L93.4271 10.7107L92.4033 15.9697H91.4614ZM93.2008 9.66018C93.0283 9.66018 92.8925 9.61721 92.7912 9.53128C92.6921 9.44535 92.6404 9.32719 92.6404 9.17466C92.6404 8.95554 92.705 8.78153 92.8322 8.65478C92.9594 8.52803 93.1339 8.46358 93.3538 8.46358C93.5262 8.46358 93.662 8.50655 93.7633 8.59248C93.8646 8.67841 93.9142 8.79657 93.9142 8.9491C93.9142 9.17037 93.8495 9.34223 93.7224 9.46898C93.5952 9.59573 93.4206 9.66018 93.2008 9.66018Z"
            fill="#004A79"
          />
          <Path
            d="M97.0696 15.9695L97.7421 12.5258C97.7701 12.3603 97.783 12.2207 97.783 12.1026C97.783 11.8491 97.7033 11.6536 97.5459 11.5204C97.3864 11.3872 97.1558 11.3184 96.8519 11.3184C96.6514 11.3184 96.4381 11.3507 96.2096 11.4172C95.9811 11.4817 95.7742 11.5698 95.5888 11.6793L94.7504 15.9695H93.8084L94.8215 10.7921L95.5974 10.7105V11.0606C96.1622 10.7384 96.6967 10.5751 97.2011 10.5751C97.6903 10.5751 98.0697 10.6976 98.3391 10.9403C98.6086 11.1852 98.7422 11.5225 98.7422 11.9565C98.7422 12.1348 98.7249 12.3045 98.6905 12.4613L98.0072 15.9673H97.0696V15.9695Z"
            fill="#004A79"
          />
          <Path
            d="M106.09 12.4119C106.118 12.2615 106.131 12.1197 106.131 11.9887C106.131 11.5418 105.879 11.3184 105.377 11.3184C105.163 11.3184 104.941 11.3571 104.708 11.4323C104.478 11.5075 104.269 11.6084 104.083 11.7309C104.09 11.7782 104.094 11.834 104.094 11.8963C104.094 12.0209 104.077 12.1777 104.042 12.3711L103.34 15.9695H102.398L103.092 12.4119C103.12 12.2615 103.133 12.1197 103.133 11.9887C103.133 11.5418 102.881 11.3184 102.378 11.3184C102.178 11.3184 101.973 11.3507 101.762 11.4172C101.551 11.4817 101.357 11.5698 101.178 11.6793L100.339 15.9695H99.3975L100.411 10.7921L101.187 10.7105V11.0606C101.732 10.7384 102.249 10.5751 102.738 10.5751C103.312 10.5751 103.708 10.7706 103.928 11.1637C104.57 10.7728 105.174 10.5751 105.739 10.5751C106.165 10.5751 106.5 10.6868 106.737 10.9103C106.974 11.1337 107.094 11.4473 107.094 11.8533C107.094 12.0381 107.077 12.2121 107.043 12.3689L106.34 15.9673H105.398L106.09 12.4119Z"
            fill="#004A79"
          />
          <Path
            d="M111.647 15.774C111.427 15.8835 111.175 15.9695 110.892 16.0318C110.61 16.0941 110.334 16.1241 110.065 16.1241C109.375 16.1241 108.845 15.9394 108.476 15.572C108.107 15.2047 107.922 14.6762 107.922 13.9887C107.922 13.3228 108.047 12.7277 108.295 12.2057C108.543 11.6836 108.873 11.2819 109.282 10.9983C109.692 10.7169 110.146 10.5751 110.642 10.5751C111.207 10.5751 111.651 10.7255 111.972 11.0284C112.293 11.3313 112.453 11.7459 112.453 12.2766C112.453 12.6482 112.384 13.0714 112.246 13.544H108.883C108.877 13.6257 108.87 13.7116 108.862 13.7975C108.855 13.8835 108.851 13.9673 108.851 14.051C108.851 14.9383 109.306 15.3808 110.218 15.3808C110.493 15.3808 110.771 15.3508 111.05 15.2928C111.33 15.2347 111.575 15.1574 111.789 15.0607L111.647 15.774ZM110.59 11.2669C110.218 11.2669 109.896 11.4087 109.623 11.6901C109.349 11.9715 109.148 12.3668 109.017 12.8759H111.511C111.552 12.6761 111.573 12.4806 111.573 12.2873C111.573 11.95 111.491 11.6965 111.325 11.5247C111.159 11.3549 110.916 11.2669 110.59 11.2669Z"
            fill="#004A79"
          />
          <Path
            d="M116.199 15.9695L116.871 12.5258C116.899 12.3603 116.912 12.2207 116.912 12.1026C116.912 11.8491 116.832 11.6536 116.675 11.5204C116.516 11.3872 116.285 11.3184 115.981 11.3184C115.781 11.3184 115.567 11.3507 115.339 11.4172C115.11 11.4817 114.903 11.5698 114.718 11.6793L113.88 15.9695H112.938L113.951 10.7921L114.727 10.7105V11.0606C115.291 10.7384 115.826 10.5751 116.33 10.5751C116.82 10.5751 117.199 10.6976 117.468 10.9403C117.738 11.1852 117.871 11.5225 117.871 11.9565C117.871 12.1348 117.854 12.3045 117.82 12.4613L117.136 15.9673H116.199V15.9695Z"
            fill="#004A79"
          />
          <Path
            d="M120.637 11.413L120.078 14.2788C120.044 14.4571 120.027 14.6225 120.027 14.7729C120.027 15.1725 120.257 15.3701 120.721 15.3701C121.003 15.3701 121.273 15.3293 121.527 15.2455L121.393 15.9372C121.083 16.0404 120.762 16.0919 120.432 16.0919C120.018 16.0919 119.686 15.9845 119.438 15.7718C119.19 15.5591 119.065 15.2734 119.065 14.9168C119.065 14.7321 119.083 14.5516 119.117 14.3797L119.697 11.4108H118.943L119.068 10.762L119.874 10.7319L120.206 9.44298L121.044 9.33986L120.775 10.7319H122.193L122.059 11.413H120.637Z"
            fill="#004A79"
          />
          <Path
            d="M38.7231 13.1317C38.7705 14.556 39.6736 15.7225 40.8548 15.7225C43.1395 15.7225 44.1137 12.9255 44.0448 11.0565C43.9973 9.62786 43.0684 8.46564 41.8915 8.46564C39.9258 8.46564 38.6562 11.2498 38.7231 13.1317ZM37.516 13.0544C37.432 10.6247 39.3417 8.1391 42.1178 8.1391C43.9736 8.1391 45.1893 9.37221 45.2561 11.2606C45.3445 13.8235 43.5598 16.178 40.6543 16.178C38.7985 16.1758 37.585 14.9449 37.516 13.0544Z"
            fill="#004A79"
          />
          <Path
            d="M11.2266 12.4119C11.1274 12.4484 11.0003 12.4871 10.8408 12.5193C10.6834 12.5559 10.3924 12.6053 9.97215 12.6719C9.68332 12.717 9.48287 12.7771 9.36648 12.8588C9.25655 12.9404 9.19189 13.0371 9.17465 13.1531C9.15094 13.2777 9.19405 13.3787 9.30182 13.4581C9.41174 13.5355 9.59926 13.5741 9.86006 13.5741C10.0562 13.5741 10.2502 13.5419 10.4356 13.4839C10.6252 13.4216 10.7826 13.3378 10.9076 13.2347C10.9981 13.1574 11.0628 13.0586 11.1102 12.9404C11.1404 12.8631 11.1792 12.6891 11.2266 12.4119ZM13.6385 10.9167C13.6277 11.1401 13.5911 11.3356 13.5307 11.6772L13.1212 13.9608C13.0867 14.1628 13.1621 14.3217 13.3497 14.4313L13.3367 14.5215L11.0003 14.5172L10.9787 13.9372C10.6382 14.1413 10.2286 14.3153 9.85575 14.4163C9.48503 14.5108 9.18542 14.5538 8.73495 14.5538C7.98056 14.5538 7.54732 14.4764 7.24988 14.2143C6.94597 13.9458 6.7994 13.7675 6.81449 13.3958C6.82527 13.1832 6.93735 12.9103 7.09685 12.7191C7.2585 12.5258 7.47835 12.399 7.71975 12.2852C7.96116 12.1713 8.24352 12.0875 8.65735 12.0231C9.07119 11.9565 9.64668 11.8899 10.4183 11.8254C10.8235 11.7911 11.0692 11.6987 11.1856 11.6514C11.3473 11.5891 11.4098 11.5204 11.4335 11.3893C11.4766 11.1552 11.4012 11.0198 11.0886 10.9597C10.2459 10.7943 8.66813 11.0671 7.85555 11.269L8.2974 10.081C9.37079 9.92421 10.3752 9.82539 11.4335 9.82539C13.1169 9.82324 13.6557 10.3023 13.6385 10.9167Z"
            fill="#004A79"
          />
          <Path
            d="M20.4128 14.5208L21.2233 9.95788H23.4606L22.6501 14.5208H20.4128ZM21.3483 8.90092C21.4151 8.52497 22.01 8.22206 22.6717 8.22206C23.3355 8.22206 23.8205 8.52712 23.758 8.90092C23.689 9.27687 23.0941 9.57978 22.4303 9.57978C21.7643 9.57978 21.2815 9.27687 21.3483 8.90092Z"
            fill="#004A79"
          />
          <Path
            d="M31.4097 12.4222C31.3084 12.4566 31.179 12.4931 31.0217 12.5296C30.8644 12.5662 30.5755 12.6134 30.1531 12.6822C29.8664 12.7273 29.6616 12.7874 29.5517 12.8691C29.444 12.9464 29.3814 13.0452 29.3577 13.1634C29.334 13.2901 29.3814 13.3911 29.4849 13.4663C29.597 13.5436 29.7845 13.5823 30.0475 13.5823C30.2414 13.5823 30.4333 13.5522 30.6208 13.4942C30.8105 13.4298 30.97 13.3503 31.0928 13.245C31.179 13.1677 31.2459 13.0667 31.2911 12.9486C31.3213 12.8734 31.3601 12.6972 31.4097 12.4222ZM33.8216 10.9249C33.8129 11.1483 33.772 11.3459 33.7116 11.6854L33.3064 13.9711C33.2719 14.1731 33.3452 14.3321 33.5349 14.4438L33.522 14.5232L31.1855 14.5275L31.1683 13.9454C30.8277 14.1516 30.4139 14.3235 30.0453 14.4223C29.6724 14.519 29.3728 14.5598 28.9202 14.5598C28.1658 14.5598 27.7369 14.4846 27.4373 14.2225C27.1334 13.9518 26.9846 13.7756 27.0019 13.4061C27.0105 13.1913 27.1226 12.9163 27.2842 12.7273C27.4459 12.5296 27.6658 12.4072 27.9072 12.2933C28.1486 12.1795 28.4331 12.0957 28.8404 12.0291C29.2564 11.9646 29.8341 11.8959 30.6036 11.8314C31.0045 11.7971 31.2523 11.7068 31.3666 11.6639C31.5304 11.6016 31.595 11.5307 31.6187 11.3996C31.6597 11.1698 31.5864 11.0323 31.276 10.97C30.429 10.8046 28.8555 11.0774 28.0429 11.2793L28.4826 10.0913C29.5603 9.93451 30.5604 9.83354 31.6187 9.83354C33.2978 9.83139 33.8345 10.3126 33.8216 10.9249Z"
            fill="#004A79"
          />
          <Path
            d="M6.51058 12.8804C5.78206 13.0244 5.37253 13.0802 4.65263 13.0802C3.58355 13.0802 2.73433 12.5517 2.77959 11.5807C2.81192 10.9341 3.57924 9.57206 5.52988 9.57206C6.14416 9.57206 6.62266 9.67948 7.26928 10.0468L7.55164 8.4614C6.67439 8.13056 6.09244 8.08115 5.3402 8.08975C2.7839 8.11767 0.326749 9.2756 0.0228378 11.6323C-0.287539 14.0255 2.64596 14.627 3.94782 14.6227C4.70005 14.6205 5.4803 14.5905 6.21098 14.5153L6.51058 12.8804Z"
            fill="#004A79"
          />
          <Path
            d="M24.6956 9.93265L24.8572 9.0046L27.198 8.4976L26.9479 9.9348H28.0946L27.8769 10.85L26.7647 10.8478L26.0901 14.5171C26.0901 14.5171 23.8377 14.5021 23.8291 14.5171L24.5016 10.8564H23.6502L23.8226 9.93051H24.6956V9.93265Z"
            fill="#004A79"
          />
          <Path
            d="M36.0933 14.5174L33.8129 14.5217L34.9596 8.15632L37.1797 8.21862L36.0933 14.5174Z"
            fill="#004A79"
          />
          <Path
            d="M17.4536 11.0286C17.1604 11.0286 16.8953 11.1167 16.6582 11.295C16.4254 11.4733 16.2767 11.7311 16.2164 12.077C16.1452 12.4744 16.1862 12.7602 16.3457 12.9385C16.5052 13.1146 16.7358 13.2027 17.0376 13.2027C17.2402 13.2027 17.4514 13.1662 17.6066 13.091C17.7984 12.99 17.9299 12.8826 18.0441 12.715C18.167 12.5389 18.2489 12.3412 18.2899 12.1135C18.3545 11.7526 18.3006 11.4841 18.1325 11.3058C17.9709 11.1189 17.7445 11.0286 17.4536 11.0286ZM13.3799 16.0964L14.4683 9.98242H16.4341L16.281 10.7622C16.4513 10.5302 16.725 10.3369 17.1001 10.1822C17.4794 10.0275 17.9062 9.91367 18.3696 9.91367C18.8826 9.91367 19.1994 9.9416 19.5831 10.1392C19.9646 10.339 20.2297 10.6269 20.3763 11.005C20.5228 11.3767 20.5552 11.7934 20.4754 12.2467C20.3418 13.0008 19.9732 13.5958 19.3697 14.0319C18.7705 14.468 18.2295 14.584 17.46 14.5862C17.1863 14.5862 16.9578 14.5583 16.7681 14.511C16.5828 14.4616 16.4362 14.4036 16.322 14.3327C16.2142 14.2597 16.1 14.1608 15.9534 14.0019L15.587 16.0986H13.3799V16.0964Z"
            fill="#004A79"
          />
          <Path
            d="M54.0046 11.5868C53.9917 11.2195 53.8063 10.9724 53.4248 10.9724C52.5066 10.9724 51.5303 12.7641 51.3621 13.4859C52.8688 13.4881 54.0413 12.5922 54.0046 11.5868ZM54.2008 14.3968L54.3775 14.5171C53.9292 15.4258 53.0175 16.1562 51.9463 16.1562C51.0712 16.1562 50.3534 15.5762 50.3146 14.4849C50.2435 12.5299 52.082 10.7125 53.5865 10.7125C54.2331 10.7125 54.8302 10.9939 54.8582 11.6856C54.9142 13.241 52.7222 13.7158 51.2802 13.748C51.2328 13.9113 51.2156 14.0745 51.2242 14.3216C51.2479 14.9661 51.6402 15.5096 52.4485 15.5096C53.149 15.5096 53.8473 14.9489 54.2008 14.3968Z"
            fill="#004A79"
          />
          <Path
            d="M45.2236 11.5362C45.3788 11.5147 45.6288 11.4889 45.7366 11.4889C45.9026 11.4889 46.0556 11.519 46.0642 11.6823C46.0664 11.779 45.8918 12.4707 45.8551 12.6318L45.506 14.1657C45.3659 14.793 45.2128 15.4504 45.077 15.9703H45.9737L46.4781 13.5406C48.017 11.9444 48.6076 11.4223 48.9718 11.4223C49.1357 11.4223 49.2477 11.5083 49.2564 11.7016C49.265 11.983 49.0904 12.6211 49.03 12.8037L48.5149 14.576C48.3985 14.9735 48.3123 15.3322 48.3188 15.59C48.3338 15.9789 48.5709 16.1529 48.9201 16.1529C49.5754 16.1529 50.0517 15.4719 50.4677 14.8037L50.347 14.5846C50.1789 14.8532 49.7909 15.4396 49.4827 15.4396C49.3857 15.4396 49.2952 15.3752 49.2908 15.2141C49.2822 15.0057 49.3684 14.6963 49.4288 14.4922L50 12.417C50.153 11.8456 50.2263 11.4761 50.222 11.2806C50.2069 10.8939 49.9806 10.7113 49.6335 10.7113C49.0451 10.7113 48.2606 11.1409 46.6009 13.0443H46.5772L46.7863 12.1162C46.9005 11.6092 47.0104 11.0786 47.1182 10.7134C46.5449 10.9196 45.7215 11.1495 45.215 11.2483L45.2236 11.5362Z"
            fill="#004A79"
          />

          <Defs>
            <ClipPath id="clip0_8189_20393">
              <rect width="122.581" height="20" fill="white" />
            </ClipPath>
          </Defs>
        </Svg>
        <View style={styles.receiptBlock}>
          {!props?.receiptBlock?.isCp1Exclusive &&
            props?.receiptBlock?.title && (
              <View>
                <Text style={styles.primaryOrdertitle}>
                  {props?.receiptBlock?.title?.value ?? ''}
                </Text>
              </View>
          )}
          <View style={styles.infoHeader}>
            <Text style={styles.secondaryOrderTitle}>
              {props?.receiptBlock?.orderDetails?.infoHeader?.title?.value ??
                ''}
            </Text>
            <Text style={styles.orderNumber}>
              {props?.receiptBlock?.orderDetails?.infoHeader?.details?.value ??
                ''}
            </Text>
          </View>
          <View style={styles.divider}></View>
          <View style={styles.middlePart}>
            <View style={styles.ticketContent}>
              <Text style={styles.ticketContentText}>
                {props?.receiptBlock?.orderDetails?.ticketInfoTitle?.value}
              </Text>

              <View style={styles.infoDetails}>
                <Text style={styles.infoDetailTitle}>
                  {props?.receiptBlock?.orderDetails?.infoDetails?.title?.value}
                </Text>
                <View style={styles.eventLogistics}>
                  <Text style={styles.dateAndTime}>
                    {
                      props?.receiptBlock?.orderDetails?.infoDetails
                        ?.eventDateStatus?.date?.value
                    }
                  </Text>
                  <Text style={styles.time}>
                    {
                      props?.receiptBlock?.orderDetails?.infoDetails
                        ?.eventDateStatus?.time?.value
                    }
                  </Text>
                  <Text style={styles.venue}>
                    {
                      props?.receiptBlock?.orderDetails?.infoDetails?.venue
                        ?.value
                    }
                  </Text>
                </View>
              </View>

              <View style={styles.seatInfo}>
                <View style={styles.seatInfoSection}>
                  <Text style={styles.seatInfoSectionLabel}>
                    {
                      props?.receiptBlock?.orderDetails?.seatInfo?.section
                        ?.label?.value
                    }
                  </Text>
                  <Text style={styles.seatInfoSectionDescription}>
                    {
                      props?.receiptBlock?.orderDetails?.seatInfo?.section
                        ?.description?.value
                    }
                  </Text>
                </View>
                <View style={styles.seatInfoSectionSiblings}>
                  <Text style={styles.seatInfoSectionLabel}>
                    {
                      props?.receiptBlock?.orderDetails?.seatInfo?.row?.label
                        ?.value
                    }
                  </Text>
                  <Text style={styles.seatInfoSectionDescription}>
                    {
                      props?.receiptBlock?.orderDetails?.seatInfo?.row
                        ?.description?.value
                    }
                  </Text>
                </View>
                <View style={styles.seatInfoSectionSiblings}>
                  <Text style={styles.seatInfoSectionLabel}>
                    {
                      props?.receiptBlock?.orderDetails?.seatInfo?.quantity
                        ?.label?.value
                    }
                  </Text>
                  <Text style={styles.seatInfoSectionDescription}>
                    {
                      props?.receiptBlock?.orderDetails?.seatInfo?.quantity
                        ?.description?.value
                    }
                  </Text>
                </View>
              </View>
              <View style={styles.sellerNotes}>
                <Text style={styles.seatInfoSectionLabel}>
                  {props?.receiptBlock?.orderDetails?.sellerNotes?.label?.value}
                </Text>
                <Text style={styles.seatInfoSectionDescription}>
                  {
                    props?.receiptBlock?.orderDetails?.sellerNotes?.description
                      ?.value
                  }
                </Text>
              </View>
            </View>
            <View style={styles.verticalDivider}></View>
            <View style={styles.deliveryContent}>
              <Text style={styles.additionalTicketInformationListText}>
                {props?.receiptBlock?.orderDetails?.deliveryInfoTitle?.value}
              </Text>

              <View style={styles.additionalTicketInformationList}>
                <View style={styles.deliveryIcon}>
                  <View style={styles.icon}>
                    <Text style={styles.deliveryText}>
                      {(props?.receiptBlock?.orderDetails
                        ?.additionalTicketInformationList
                        ?.iconTextDescriptions || [])[0]?.description?.value ??
                        ''}
                    </Text>
                  </View>
                  <View style={styles.icon}>
                    <Text style={styles.deliveryText}>
                      {(props?.receiptBlock?.orderDetails
                        ?.additionalTicketInformationList
                        ?.iconTextDescriptions || [])[1]?.description?.value ??
                        ''}
                    </Text>
                  </View>
                  <View style={styles.icon}>
                    <Text style={styles.deliveryText}>
                      {(props?.receiptBlock?.orderDetails
                        ?.additionalTicketInformationList
                        ?.iconTextDescriptions || [])[2]?.description?.value ??
                        ''}
                    </Text>
                  </View>
                  <View style={styles.icon}>
                    <Text style={styles.deliveryText}>
                      {(props?.receiptBlock?.orderDetails
                        ?.additionalTicketInformationList
                        ?.iconTextDescriptions || [])[3]?.description?.value ??
                        ''}
                    </Text>
                  </View>
                  <View style={styles.icon}>
                    <Text style={styles.deliveryText}>
                      {(props?.receiptBlock?.orderDetails
                        ?.additionalTicketInformationList
                        ?.iconTextDescriptions || [])[4]?.description?.value ??
                        ''}
                    </Text>
                  </View>
                </View>
              </View>
              <View style={styles.deliveryNotes}>
                <Text style={styles.seatInfoSectionLabel}>
                  {
                    props?.receiptBlock?.orderDetails?.deliveryDateInfo?.label
                      ?.value
                  }
                </Text>
                <Text style={styles.seatInfoSectionDescription}>
                  {
                    props?.receiptBlock?.orderDetails?.deliveryDateInfo
                      ?.description?.value
                  }
                </Text>
              </View>
            </View>
          </View>
          {props?.receiptBlock?.isCp1Exclusive && (
            <View style={styles.capitalOneLogo}>
              <Text style={styles.iconLabel}>
                {props?.receiptBlock?.cardHolderExclusiveLabel}
              </Text>
              <Svg width="123" viewBox="0 0 123 20" style={styles.imageLower}>
                <Path
                  d="M37.2336 14.1933C37.2315 14.1955 37.2293 14.1976 37.2272 14.1976C34.8907 15.8454 32.1232 17.5769 29.1466 19.3943L29.028 19.4631C28.9892 19.491 28.9785 19.5468 29.0065 19.5855C29.0345 19.6263 29.0905 19.6349 29.1293 19.607L29.2285 19.5554C31.7525 18.2428 34.6536 16.6832 37.5699 15.0849C37.585 15.0763 37.6 15.0677 37.6173 15.0591C37.4599 14.797 37.3263 14.5091 37.2336 14.1933ZM52.565 1.8235C48.8835 -2.22387 25.1914 1.41748 15.115 3.61087L14.88 3.66243C14.8326 3.67103 14.8003 3.71829 14.8067 3.76555C14.8154 3.81496 14.8606 3.84719 14.9102 3.83859L15.143 3.79348C23.4908 2.31331 40.8504 0.257403 44.5857 3.9503C45.7259 5.076 45.4543 6.52824 44.133 8.23828C44.8465 8.69371 45.353 9.41124 45.5815 10.3092C50.7393 6.88701 54.2052 3.62806 52.565 1.8235Z"
                  fill="#CC2427"
                />
                <Path
                  d="M38.7231 13.1317C38.7705 14.556 39.6736 15.7225 40.8548 15.7225C43.1395 15.7225 44.1137 12.9255 44.0448 11.0565C43.9973 9.62786 43.0684 8.46564 41.8915 8.46564C39.9258 8.46564 38.6562 11.2498 38.7231 13.1317ZM37.516 13.0544C37.432 10.6247 39.3417 8.1391 42.1178 8.1391C43.9736 8.1391 45.1893 9.37221 45.2561 11.2606C45.3445 13.8235 43.5598 16.178 40.6543 16.178C38.7985 16.1758 37.585 14.9449 37.516 13.0544Z"
                  fill="#004A79"
                />
                <Path
                  d="M11.2266 12.4119C11.1274 12.4484 11.0003 12.4871 10.8408 12.5193C10.6834 12.5559 10.3924 12.6053 9.97215 12.6719C9.68332 12.717 9.48287 12.7771 9.36648 12.8588C9.25655 12.9404 9.19189 13.0371 9.17465 13.1531C9.15094 13.2777 9.19405 13.3787 9.30182 13.4581C9.41174 13.5355 9.59926 13.5741 9.86006 13.5741C10.0562 13.5741 10.2502 13.5419 10.4356 13.4839C10.6252 13.4216 10.7826 13.3378 10.9076 13.2347C10.9981 13.1574 11.0628 13.0586 11.1102 12.9404C11.1404 12.8631 11.1792 12.6891 11.2266 12.4119ZM13.6385 10.9167C13.6277 11.1401 13.5911 11.3356 13.5307 11.6772L13.1212 13.9608C13.0867 14.1628 13.1621 14.3217 13.3497 14.4313L13.3367 14.5215L11.0003 14.5172L10.9787 13.9372C10.6382 14.1413 10.2286 14.3153 9.85575 14.4163C9.48503 14.5108 9.18542 14.5538 8.73495 14.5538C7.98056 14.5538 7.54732 14.4764 7.24988 14.2143C6.94597 13.9458 6.7994 13.7675 6.81449 13.3958C6.82527 13.1832 6.93735 12.9103 7.09685 12.7191C7.2585 12.5258 7.47835 12.399 7.71975 12.2852C7.96116 12.1713 8.24352 12.0875 8.65735 12.0231C9.07119 11.9565 9.64668 11.8899 10.4183 11.8254C10.8235 11.7911 11.0692 11.6987 11.1856 11.6514C11.3473 11.5891 11.4098 11.5204 11.4335 11.3893C11.4766 11.1552 11.4012 11.0198 11.0886 10.9597C10.2459 10.7943 8.66813 11.0671 7.85555 11.269L8.2974 10.081C9.37079 9.92421 10.3752 9.82539 11.4335 9.82539C13.1169 9.82324 13.6557 10.3023 13.6385 10.9167Z"
                  fill="#004A79"
                />
                <Path
                  d="M20.4128 14.5208L21.2233 9.95788H23.4606L22.6501 14.5208H20.4128ZM21.3483 8.90092C21.4151 8.52497 22.01 8.22206 22.6717 8.22206C23.3355 8.22206 23.8205 8.52712 23.758 8.90092C23.689 9.27687 23.0941 9.57978 22.4303 9.57978C21.7643 9.57978 21.2815 9.27687 21.3483 8.90092Z"
                  fill="#004A79"
                />
                <Path
                  d="M31.4097 12.4222C31.3084 12.4566 31.179 12.4931 31.0217 12.5296C30.8644 12.5662 30.5755 12.6134 30.1531 12.6822C29.8664 12.7273 29.6616 12.7874 29.5517 12.8691C29.444 12.9464 29.3814 13.0452 29.3577 13.1634C29.334 13.2901 29.3814 13.3911 29.4849 13.4663C29.597 13.5436 29.7845 13.5823 30.0475 13.5823C30.2414 13.5823 30.4333 13.5522 30.6208 13.4942C30.8105 13.4298 30.97 13.3503 31.0928 13.245C31.179 13.1677 31.2459 13.0667 31.2911 12.9486C31.3213 12.8734 31.3601 12.6972 31.4097 12.4222ZM33.8216 10.9249C33.8129 11.1483 33.772 11.3459 33.7116 11.6854L33.3064 13.9711C33.2719 14.1731 33.3452 14.3321 33.5349 14.4438L33.522 14.5232L31.1855 14.5275L31.1683 13.9454C30.8277 14.1516 30.4139 14.3235 30.0453 14.4223C29.6724 14.519 29.3728 14.5598 28.9202 14.5598C28.1658 14.5598 27.7369 14.4846 27.4373 14.2225C27.1334 13.9518 26.9846 13.7756 27.0019 13.4061C27.0105 13.1913 27.1226 12.9163 27.2842 12.7273C27.4459 12.5296 27.6658 12.4072 27.9072 12.2933C28.1486 12.1795 28.4331 12.0957 28.8404 12.0291C29.2564 11.9646 29.8341 11.8959 30.6036 11.8314C31.0045 11.7971 31.2523 11.7068 31.3666 11.6639C31.5304 11.6016 31.595 11.5307 31.6187 11.3996C31.6597 11.1698 31.5864 11.0323 31.276 10.97C30.429 10.8046 28.8555 11.0774 28.0429 11.2793L28.4826 10.0913C29.5603 9.93451 30.5604 9.83354 31.6187 9.83354C33.2978 9.83139 33.8345 10.3126 33.8216 10.9249Z"
                  fill="#004A79"
                />
                <Path
                  d="M6.51058 12.8804C5.78206 13.0244 5.37253 13.0802 4.65263 13.0802C3.58355 13.0802 2.73433 12.5517 2.77959 11.5807C2.81192 10.9341 3.57924 9.57206 5.52988 9.57206C6.14416 9.57206 6.62266 9.67948 7.26928 10.0468L7.55164 8.4614C6.67439 8.13056 6.09244 8.08115 5.3402 8.08975C2.7839 8.11767 0.326749 9.2756 0.0228378 11.6323C-0.287539 14.0255 2.64596 14.627 3.94782 14.6227C4.70005 14.6205 5.4803 14.5905 6.21098 14.5153L6.51058 12.8804Z"
                  fill="#004A79"
                />
                <Path
                  d="M24.6956 9.93265L24.8572 9.0046L27.198 8.4976L26.9479 9.9348H28.0946L27.8769 10.85L26.7647 10.8478L26.0901 14.5171C26.0901 14.5171 23.8377 14.5021 23.8291 14.5171L24.5016 10.8564H23.6502L23.8226 9.93051H24.6956V9.93265Z"
                  fill="#004A79"
                />
                <Path
                  d="M36.0933 14.5174L33.8129 14.5217L34.9596 8.15632L37.1797 8.21862L36.0933 14.5174Z"
                  fill="#004A79"
                />
                <Path
                  d="M17.4536 11.0286C17.1604 11.0286 16.8953 11.1167 16.6582 11.295C16.4254 11.4733 16.2767 11.7311 16.2164 12.077C16.1452 12.4744 16.1862 12.7602 16.3457 12.9385C16.5052 13.1146 16.7358 13.2027 17.0376 13.2027C17.2402 13.2027 17.4514 13.1662 17.6066 13.091C17.7984 12.99 17.9299 12.8826 18.0441 12.715C18.167 12.5389 18.2489 12.3412 18.2899 12.1135C18.3545 11.7526 18.3006 11.4841 18.1325 11.3058C17.9709 11.1189 17.7445 11.0286 17.4536 11.0286ZM13.3799 16.0964L14.4683 9.98242H16.4341L16.281 10.7622C16.4513 10.5302 16.725 10.3369 17.1001 10.1822C17.4794 10.0275 17.9062 9.91367 18.3696 9.91367C18.8826 9.91367 19.1994 9.9416 19.5831 10.1392C19.9646 10.339 20.2297 10.6269 20.3763 11.005C20.5228 11.3767 20.5552 11.7934 20.4754 12.2467C20.3418 13.0008 19.9732 13.5958 19.3697 14.0319C18.7705 14.468 18.2295 14.584 17.46 14.5862C17.1863 14.5862 16.9578 14.5583 16.7681 14.511C16.5828 14.4616 16.4362 14.4036 16.322 14.3327C16.2142 14.2597 16.1 14.1608 15.9534 14.0019L15.587 16.0986H13.3799V16.0964Z"
                  fill="#004A79"
                />
                <Path
                  d="M54.0046 11.5868C53.9917 11.2195 53.8063 10.9724 53.4248 10.9724C52.5066 10.9724 51.5303 12.7641 51.3621 13.4859C52.8688 13.4881 54.0413 12.5922 54.0046 11.5868ZM54.2008 14.3968L54.3775 14.5171C53.9292 15.4258 53.0175 16.1562 51.9463 16.1562C51.0712 16.1562 50.3534 15.5762 50.3146 14.4849C50.2435 12.5299 52.082 10.7125 53.5865 10.7125C54.2331 10.7125 54.8302 10.9939 54.8582 11.6856C54.9142 13.241 52.7222 13.7158 51.2802 13.748C51.2328 13.9113 51.2156 14.0745 51.2242 14.3216C51.2479 14.9661 51.6402 15.5096 52.4485 15.5096C53.149 15.5096 53.8473 14.9489 54.2008 14.3968Z"
                  fill="#004A79"
                />
                <Path
                  d="M45.2236 11.5362C45.3788 11.5147 45.6288 11.4889 45.7366 11.4889C45.9026 11.4889 46.0556 11.519 46.0642 11.6823C46.0664 11.779 45.8918 12.4707 45.8551 12.6318L45.506 14.1657C45.3659 14.793 45.2128 15.4504 45.077 15.9703H45.9737L46.4781 13.5406C48.017 11.9444 48.6076 11.4223 48.9718 11.4223C49.1357 11.4223 49.2477 11.5083 49.2564 11.7016C49.265 11.983 49.0904 12.6211 49.03 12.8037L48.5149 14.576C48.3985 14.9735 48.3123 15.3322 48.3188 15.59C48.3338 15.9789 48.5709 16.1529 48.9201 16.1529C49.5754 16.1529 50.0517 15.4719 50.4677 14.8037L50.347 14.5846C50.1789 14.8532 49.7909 15.4396 49.4827 15.4396C49.3857 15.4396 49.2952 15.3752 49.2908 15.2141C49.2822 15.0057 49.3684 14.6963 49.4288 14.4922L50 12.417C50.153 11.8456 50.2263 11.4761 50.222 11.2806C50.2069 10.8939 49.9806 10.7113 49.6335 10.7113C49.0451 10.7113 48.2606 11.1409 46.6009 13.0443H46.5772L46.7863 12.1162C46.9005 11.6092 47.0104 11.0786 47.1182 10.7134C46.5449 10.9196 45.7215 11.1495 45.215 11.2483L45.2236 11.5362Z"
                  fill="#004A79"
                />

                <Defs>
                  <ClipPath id="clip0_8189_20393">
                    <rect width="122.581" height="20" fill="white" />
                  </ClipPath>
                </Defs>
              </Svg>
            </View>
          )}
        </View>
        <View style={styles.ticketNotice}>
          <Text style={styles.noticeText}>
            {props?.receiptBlock?.ticketNotice}
          </Text>
        </View>
        <View style={styles.billingSummarySection}>
          <Text style={styles.billingSummaryTitle}>
            {props?.receiptBlock?.orderDetails?.billingInfoTitle?.value ?? ''}
          </Text>
          <View style={styles.billingSummaryItems}>
            <View style={styles.billingSummaryData}>
              <Text style={styles.billingSummaryLabel}>
                {props?.receiptBlock?.orderDetails?.infoOnMultipleLine?.label
                  ?.value ?? ''}
              </Text>
              <Text style={styles.billingSummaryLongLine}>
                {props?.receiptBlock?.orderDetails?.infoOnMultipleLine?.address
                  ?.value ?? ''}
              </Text>
              <View style={styles.spacing} />
              <Text style={styles.billingSummaryLabel}>
                {props?.receiptBlock?.orderDetails?.phoneNumber?.label?.value ??
                  ''}
              </Text>
              <Text style={styles.billingSummaryLine}>
                {props?.receiptBlock?.orderDetails?.phoneNumber?.description
                  ?.value ?? ''}
              </Text>
            </View>
            <View style={styles.billingSummaryData}>
              <Text style={styles.billingSummaryLabel}>
                {props?.receiptBlock?.orderDetails?.totalCardPayment?.label
                  ?.value ?? ''}
              </Text>
              <Text style={styles.billingSummaryLine}>
                {props?.receiptBlock?.orderDetails?.totalCardPayment
                  ?.description?.value ?? ''}
              </Text>
              <Text style={styles.billingSummaryLabel}>
                {props?.receiptBlock?.orderDetails?.paymentMethod?.label
                  ?.value ?? ''}
              </Text>
              <Text style={styles.billingSummaryLine}>
                {props?.receiptBlock?.orderDetails?.paymentMethod?.description
                  ?.value ?? ''}
              </Text>
            </View>
            <View style={styles.billingSummaryData}>
              <Text style={styles.billingSummaryLabel}>
                {props?.receiptBlock?.orderDetails?.rightContent?.label
                  ?.value ?? ''}
              </Text>
              <Text style={styles.billingSummaryLine}>
                {props?.receiptBlock?.orderDetails?.rightContent?.description
                  ?.value ?? ''}
              </Text>

            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PDFDocument;

import React from 'react';
import cx from 'classnames';

import Text from '../../atoms/Text';

import styles from './TextItem.module.scss';
import usePresenter from './TextItem.presenter';
import getProps from './TextItem.props';
import { TextItemCombinedProps } from './types';


const TextItem: React.FC<TextItemCombinedProps> = (props) => {
  const {
    style,
    className,
    classes,
    label,
    description,
  } = usePresenter(props);

  const variantName = `${style}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`textItem${variantName}`];

  let componentView;

  switch (variantName) {
    case 'BoldedLabel':
    case 'SubduedLabel': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Text
            className={cx(styles.label, classes?.label)}
            {...internalProps.label}
            {...label}/>
          <Text
            className={cx(styles.description, classes?.description)}
            {...internalProps.description}
            {...description}/>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default TextItem;


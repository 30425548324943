import { DividerProps } from '../../atoms/Divider';
import { SubmenuListProps } from '../../molecules/SubmenuList';


type SubmenuInternalProps = {
  divider?: DividerProps;
  submenuList?: SubmenuListProps;
};

type SubmenuPropsValues = {
  '': SubmenuInternalProps;
};

const propValues: SubmenuPropsValues = {
  '': {
    divider: {
      type: 'Horizontal',
      style: 'Thin',
      colour: 'White',
    },
    submenuList: {
    },
  },
};

const getProps = (type: string): SubmenuInternalProps => {
  const values: SubmenuInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import { useContext, useEffect, useLayoutEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Anchors } from './types';

import { BREAKPOINTS, C1_CULTUREARTS_TAG, CURRENT_LOCATION_FALLBACK_VALUE, DEFAULT_PER_PAGE, HEADER_HEIGHT } from '../../../lib/constants';
import { useWindowSize } from '../../../lib/util';
import { useAnalyticsManager } from '../../../modules/analytics/useAnalyticsManager';
import { RegionContext } from '../../../modules/region';
import C1XLandingPageHero1Asset from '../../../resources/images/C1XLandingPageHero1.png';
import DiningBannar2Asset from '../../../resources/images/DiningBannar2.png';

import { ExclusivesEventsLandingPageCombinedProps } from './types';
import { trackPageViewEvent, trackSelectContentEvent } from '../../../modules/analytics/util';

const diningUrl = 'https://www.capitalone.com/credit-cards/benefits/dining/';

const usePresenter = (props: ExclusivesEventsLandingPageCombinedProps): ExclusivesEventsLandingPageCombinedProps => {
  const { currentRegion, allRegions } = useContext(RegionContext);

  const { trackEvent } = useAnalyticsManager();
  const { t } = useTranslation();
  const { search, hash } = useLocation();
  const { width } = useWindowSize();

  const pageName = 'Exclusive Event Landing Page';
  useEffect(() => {
    trackPageViewEvent(
      trackEvent,
      pageName,
    );
  }, []);

  const query = new URLSearchParams(search);
  const regionId = query.get('region_id');

  useEffect(() => {
    trackEvent('exclusive_landing_page_view');
    trackEvent('view_item_list', {
      ecommerce: {
        item_list_name: 'exclusive_landing_page_view',
      },
    });
  }, []);

  const targetRefs = useRef<Anchors>({});
  useLayoutEffect(() => {
    let headerOffset = HEADER_HEIGHT.desktop;
    if (width && width < BREAKPOINTS.tablet) {
      headerOffset = HEADER_HEIGHT.mobile;
    }

    if (hash && typeof window !== 'undefined') {
      const targetId = hash.slice(1);
      const targetRef = targetRefs.current[targetId];
      if (targetRef) {
        const { top } = targetRef.getBoundingClientRect() || { top: 0 };
        window.scrollTo({
          top: top + window.scrollY - headerOffset,
          behavior: 'smooth',
        });
      }
    }
  }, [hash, width]);

  return {
    ...props,
    primaryBannerBlock: {
      primaryBanner: {
        isExclusive: true,
        imageSrc: C1XLandingPageHero1Asset,
        title: {
          value: t('cardHolderExclusiveHeroBanner.cardHolderExclusive'),
        },
        message: {
          value: t('cardHolderExclusiveHeroBanner.message'),
        },
      },
    },
    exclusiveEvents: {
      ignoreParams: ['region_id', 'date_end', 'date_start'],
      defaultParams: {
        category: 'C1_PROMOTED_PRODUCTION',
        per_page: 4,
      },
      minimumDisplayCount: 2,
      blockHeader: {
        text: {
          value: t('cardHolderExclusivesLandingPage.spotlight'),
        },
      },
      pageName,
      pageCarouselIndex: 1,
    },
    filtersBlock: {
      filterOne: {
        state: 'Closed',
        style: 'Dark',
        currentRegion,
        showUseMyLocation: true,
        defaultOption: {
          value: t('shopByWidget.anyLocation'),
          id: regionId === '' ? CURRENT_LOCATION_FALLBACK_VALUE : '',
        },
        locationList: allRegions,
        type: 'SearchLocationFilter',
      },
      filterTwo: {
        state: 'Closed',
        style: 'Dark',
        defaultDateOption: {
          title: t('dateFilters.any'),
          type: 'Default',
        },
        type: 'DateFilter',
      },
    },
    sportsEvents: {
      showMLBCard: true,
      defaultParams: {
        category: 'C1_Sports',
        per_page: DEFAULT_PER_PAGE,
      },
      blockHeader: {
        text: {
          value: t('cardHolderExclusivesLandingPage.sports'),
        },
      },
      setRef: (ref) => (targetRefs.current.sports = ref),
      pageName,
      pageCarouselIndex: 2,
    },
    musicEvents: {
      defaultParams: {
        category: 'C1_Music',
        per_page: DEFAULT_PER_PAGE,
      },
      blockHeader: {
        text: {
          value: t('cardHolderExclusivesLandingPage.music'),
        },
      },
      setRef: (ref) => (targetRefs.current.music = ref),
      pageName,
      pageCarouselIndex: 3,
    },
    diningEvents: {
      defaultParams: {
        category: 'C1_Dining',
        per_page: DEFAULT_PER_PAGE,
      },
      blockHeader: {
        text: {
          value: t('cardHolderExclusivesLandingPage.dining'),
        },
      },
      setRef: (ref) => (targetRefs.current.dining = ref),
      pageName,
      pageCarouselIndex: 4,
    },
    getawaysEvents: {
      defaultParams: {
        category: 'C1_TRAVEL',
        per_page: DEFAULT_PER_PAGE,
      },
      blockHeader: {
        text: {
          value: t('cardHolderExclusivesLandingPage.getaways'),
        },
      },
      setRef: (ref) => (targetRefs.current.getaway = ref),
      pageName,
      pageCarouselIndex: 5,
    },
    artTheaterEvents: {
      defaultParams: {
        category: C1_CULTUREARTS_TAG,
        per_page: DEFAULT_PER_PAGE,
      },
      blockHeader: {
        text: {
          value: t('cardHolderExclusivesLandingPage.artAndTheater'),
        },
      },
      setRef: (ref) => (targetRefs.current['art+theater'] = ref),
      pageName,
      pageCarouselIndex: 6,
    },
    secondaryBannerDarkBlock: {
      secondaryBanner: {
        imageSrc: DiningBannar2Asset,
        title: {
          value: t('secondaryBanner.exclusive.title'),
        },
        message: {
          value: t('secondaryBanner.exclusive.message'),
        },
        button: {
          text: {
            type: 'Body',
            size: 'Large',
            style: 'SemiBold',
            colour: 'ActionBase',
            align: 'Center',
            value: t('secondaryBanner.exclusive.button'),
          },
          onClick: () => {
            trackSelectContentEvent(
              trackEvent,
              'Exclusive Events Landing Page',
              'Button',
              t('secondaryBanner.exclusive.button'),
            );
          },
          href: diningUrl,
        },
      },
    },
  };
};

export default usePresenter;

import React from 'react';
import cx from 'classnames';

import Text from '../../atoms/Text';
import Button from '../../atoms/Button';
import Divider from '../../atoms/Divider';

import styles from './TicketInformation.module.scss';
import usePresenter from './TicketInformation.presenter';
import getProps from './TicketInformation.props';
import { TicketInformationCombinedProps } from './types';
import { getClickAccessibilityProps } from '../../organisms/TopNav/util';


const TicketInformation: React.FC<TicketInformationCombinedProps> = (props) => {
  const {
    type,
    className,
    classes,
    title,
    location,
    availability,
    value,
    valueType,
    other,
    divider,
    pointsValue,
    button,
    // MANUAL OVERRIDE STARTS
    handleMouseOver,
    handleMouseLeave,
    onClicked,
    breakdownText,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const variantName = `${type}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`ticketInformation${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Default': {
      componentView = (
        // MANUAL OVERRRIDE
        <>
          <div className={cx(styles.content, classes?.content)}>
            <div className={cx(styles.description, classes?.description)}>
              <Text
                className={cx(styles.title, classes?.title)}
                {...internalProps.title}
                {...title} />
              <Text
                className={cx(styles.location, classes?.location)}
                {...internalProps.location}
                {...location} />
              <Text
                className={cx(styles.availability, classes?.availability)}
                {...internalProps.availability}
                {...availability} />
            </div>
            <div className={cx(styles.cost, classes?.cost)}>
              <div className={cx(styles.cost1, classes?.cost1)}>
                <Text
                  className={cx(styles.value, classes?.value)}
                  {...internalProps.value}
                  {...value} />
                <Text
                  className={cx(styles.valueType, classes?.valueType)}
                  {...internalProps.valueType}
                  {...valueType} />
              </div>
              {/* MANUAL OVERRIDE */}
              <Text
                className={cx(styles.pointsValue, classes?.pointsValue)}
                {...internalProps.pointsValue}
                {...pointsValue} />
              <Text
                className={cx(styles.other, classes?.other)}
                {...internalProps.other}
                {...other} />
            </div>
          </div>
          <Divider
            className={cx(styles.divider, classes?.divider)}
            {...internalProps.divider}
            {...divider}/>
        </>
      );
      break;
    }
    case 'LargeTitle': {
      componentView = (
        // MANUAL OVERRRIDE
        <>
          <div className={cx(styles.content, classes?.content)}>
            <div className={cx(styles.description, classes?.description)}>
              <Text
                className={cx(styles.title, classes?.title)}
                {...internalProps.title}
                {...title} />
              <div className={cx(styles.frame1, classes?.frame1)}>
                <Text
                  className={cx(styles.location, classes?.location)}
                  {...internalProps.location}
                  {...location} />
                <div className={cx(styles.cost, classes?.cost)}>
                  <div className={cx(styles.cost1, classes?.cost1)}>
                    <Text
                      className={cx(styles.value, classes?.value)}
                      {...internalProps.value}
                      {...value} />
                    <Text
                      className={cx(styles.valueType, classes?.valueType)}
                      {...internalProps.valueType}
                      {...valueType} />
                  </div>
                  <Text
                    className={cx(styles.other, classes?.other)}
                    {...internalProps.other}
                    {...other} />
                </div>
              </div>
              {/* MANUAL OVERRIDE */}
              {!!breakdownText && (
                <Text
                  className={styles.breakdownText}
                  {...internalProps.breakdownText}
                  {...breakdownText}/>
              )}
            </div>
          </div>
          <Divider
            className={cx(styles.divider, classes?.divider)}
            {...internalProps.divider}
            {...divider}/>
        </>
      );
      break;
    }
    case 'MoreDetails': {
      componentView = (
        // MANUAL OVERRRIDE
        <>
          <div className={cx(styles.content, classes?.content)}>
            <div className={cx(styles.description, classes?.description)}>
              <Text
                className={cx(styles.title, classes?.title)}
                {...internalProps.title}
                {...title} />
              <Text
                className={cx(styles.location, classes?.location)}
                {...internalProps.location}
                {...location} />
              <Text
                className={cx(styles.availability, classes?.availability)}
                {...internalProps.availability}
                {...availability} />
            </div>
            <div className={cx(styles.cost, classes?.cost)}>
              <div className={cx(styles.cost1, classes?.cost1)}>
                <Text
                  className={cx(styles.value, classes?.value)}
                  {...internalProps.value}
                  {...value} />
                <Text
                  className={cx(styles.valueType, classes?.valueType)}
                  {...internalProps.valueType}
                  {...valueType} />
              </div>
              <Text
                className={cx(styles.pointsValue, classes?.pointsValue)}
                {...internalProps.pointsValue}
                {...pointsValue} />
              <Text
                className={cx(styles.other, classes?.other)}
                {...internalProps.other}
                {...other} />
            </div>
          </div>
          <Divider
            className={cx(styles.divider, classes?.divider)}
            {...internalProps.divider}
            {...divider}/>
        </>
      );
      break;
    }
    case 'WithPin': {
      componentView = (
        // MANUAL OVERRIDE
        <>
          <div className={cx(styles.content, classes?.content)}>
            <div className={cx(styles.description, classes?.description)}>
              <Text
                className={cx(styles.title, classes?.title)}
                {...internalProps.title}
                {...title} />
              <Text
                className={cx(styles.availability, classes?.availability)}
                {...internalProps.availability}
                {...availability} />
            </div>
            <div className={cx(styles.rightContent, classes?.rightContent)}>
              <div className={cx(styles.cost, classes?.cost)}>
                <div className={cx(styles.cost1, classes?.cost1)}>
                  <Text
                    className={cx(styles.value, classes?.value)}
                    {...internalProps.value}
                    {...value} />
                  <Text
                    className={cx(styles.valueType, classes?.valueType)}
                    {...internalProps.valueType}
                    {...valueType} />
                </div>
                <Text
                  className={cx(styles.other, classes?.other)}
                  {...internalProps.other}
                  {...other} />
              </div>
              <Button
                className={cx(styles.button, classes?.button)}
                {...internalProps.button}
                {...button} />
            </div>
          </div>
          <Divider
            className={cx(styles.divider, classes?.divider)}
            {...internalProps.divider}
            {...divider}/>
        </>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  // MANUAL OVERRIDE STARTS
  const accessibilityProps = getClickAccessibilityProps(
    { onClick: onClicked },
    !!onClicked,
  );

  return (
    <div
      {...accessibilityProps}
      className={cx(currentStyle, className)}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      onClick={onClicked}
    >
      {componentView}
    </div>
  );
  // MANUAL OVERRIDE ENDS
};

export default TicketInformation;


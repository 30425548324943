import { ButtonProps } from '../Button';
import { IconProps } from '../Icon';
import { TextProps } from '../Text';


type TextInputInternalProps = {
  icon?: IconProps;
  text?: TextProps;
  button?: ButtonProps;
};

type TextInputPropsValues = {
  'EmptyClearRegular': TextInputInternalProps;
  'EmptyClearWithIconRegular': TextInputInternalProps;
  'EmptyDefaultCenterRegular': TextInputInternalProps;
  'EmptyDefaultRegular': TextInputInternalProps;
  'EmptyDefaultSmall': TextInputInternalProps;
  'FilledClearRegular': TextInputInternalProps;
  'FilledDefaultCenterRegular': TextInputInternalProps;
  'FilledDefaultRegular': TextInputInternalProps;
  'FilledDefaultSmall': TextInputInternalProps;
};

const propValues: TextInputPropsValues = {
  'EmptyClearRegular': {
    text: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'Charcoal20',
      align: 'Left',
    },
  },
  'EmptyClearWithIconRegular': {
    icon: {
      asset: 'Search',
      style: 'BaseDark',
    },
    text: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
  },
  'EmptyDefaultCenterRegular': {
    text: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Center',
    },
  },
  'EmptyDefaultRegular': {
    text: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
  },
  'EmptyDefaultSmall': {
    text: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
  },
  'FilledClearRegular': {
    text: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'BaseLight',
      align: 'Left',
    },
    button: {
      type: 'Icon',
      style: 'Text',
      size: 'Small',
    },
  },
  'FilledDefaultCenterRegular': {
    text: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Center',
    },
  },
  'FilledDefaultRegular': {
    text: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
  },
  'FilledDefaultSmall': {
    text: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
  },
};

const getProps = (type: string): TextInputInternalProps => {
  const values: TextInputInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

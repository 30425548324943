import React from 'react';
import cx from 'classnames';

import EventCardDark from '../../molecules/EventCardDark';

import styles from './EventCardDarkList.module.scss';
import usePresenter from './EventCardDarkList.presenter';
import getProps from './EventCardDarkList.props';
import { EventCardDarkListCombinedProps } from './types';
import MlbCard from '../../molecules/MlbCard';
import { isMLBCard } from './utils';


const EventCardDarkList: React.FC<EventCardDarkListCombinedProps> = (props) => {
  const {
    className,
    classes,
    eventCardDarks,
    listElementRef, // MANUAL OVERRIDE
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.eventCardDarkList;

  // MANUAL OVERRIDE STARTS
  const eventCardDarkViews = eventCardDarks?.map((eventCardDark, index) => {
    const eventCardDarkView = (
      <EventCardDark
      key={index}
      index={index}
      className={cx(styles.eventCardDark, classes?.eventCardDark)}
      {...internalProps.eventCardDark}
      {...eventCardDark} />
    );

    const mlbCardView = (
      <MlbCard
        className={styles.eventCardDark}
        key={index}
        index={index}
        {...eventCardDark} />
    );
    return isMLBCard(eventCardDark) ? mlbCardView : eventCardDarkView;
  });
  // MANUAL OVERRIDE ENDS

  return (
    // MANUAL OVERRIDE - added ref
    <div ref={listElementRef} className={cx(currentStyle, className)}>
      {eventCardDarkViews}
    </div>
  );
};

export default EventCardDarkList;


import axios from 'axios';
import Cookie from 'js-cookie';
import { BFF_URL, IS_DEV, SESSION_COOKIE_NAME } from '../../lib/config';
import { ApiError } from '../error/types';
import { Account, AuthResponse, VerifyTokenPayload } from './types';

export const getAuthData = async (): Promise<Account> => {
  try {
    const { data } = await axios.get<Account>(
      `${BFF_URL}/auth/me`,
      { withCredentials: true },
    );
    return data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const errorResponseStatus = error.response.status;
      // extract message from response if available
      let errorMessage;
      try {
        errorMessage = errorResponseStatus === 406 && error.response.data
          ? JSON.stringify(error.response.data)
          : error.message;
      } catch {
        errorMessage = error.message;
      }
      throw new ApiError({
        code: error.response.status,
        type: error.response.statusText,
        message: errorMessage,
      });
    }
    throw error;
  }
};

export const verifyToken = async (payload:VerifyTokenPayload ): Promise<AuthResponse> => {
  try {
    const { data } = await axios.post<AuthResponse>(
      `${BFF_URL}/auth`,
      payload, { withCredentials: true },
    );
    // for local development manually set the cookie
    if (IS_DEV) {
      const expiryDate = new Date();
      expiryDate.setTime(expiryDate.getTime() + 45 * 60 * 1000);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      Cookie.set(SESSION_COOKIE_NAME, data.jwt_token, {
        path: '/',
        secure: true,
        sameSite: 'none',
        expires: expiryDate,
      });
    }

    return data;
  } catch (error) {
    // return {
    //   jwt_token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0dGwiOjE5MjIwMTI5NDUwMDB9.SPG-FWZOjMUy-Kd8nMOb6rUlFTLTrS86xTv4dL7EwC4',
    // };
    if (axios.isAxiosError(error) && error.response) {
      const errorResponseStatus = error.response.status;
      // extract message from response if available
      let errorMessage;
      try {
        errorMessage = errorResponseStatus === 406 && error.response.data
          ? JSON.stringify(error.response.data)
          : error.message;
      } catch {
        errorMessage = error.message;
      }
      throw new ApiError({
        code: error.response.status,
        type: error.response.statusText,
        message: errorMessage,
      });
    }
    throw error;
  }
};

export const logoutUser = async (): Promise<void> => {
  try {
    const { data } = await axios.post<void>(
      `${BFF_URL}/user/logout`, {},
      { withCredentials: true },
    );
    return data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      throw new ApiError({
        code: error.response.status,
        type: error.response.statusText,
        message: error.message,
      });
    }
    throw error;
  }
};

export const updateNewUserExperience = async (): Promise<void> => {
  try {
    const { data } = await axios.post<void>(
      `${BFF_URL}/user/experience`, {},
      { withCredentials: true },
    );
    return data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      throw new ApiError({
        code: error.response.status,
        type: error.response.statusText,
        message: error.message,
      });
    }
    throw error;
  }
};

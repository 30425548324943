import React from 'react';
import cx from 'classnames';

import Text from '../../atoms/Text';
import EventTypeTag from '../../atoms/EventTypeTag';

import styles from './CXEventInfoHeader.module.scss';
import usePresenter from './CXEventInfoHeader.presenter';
import getProps from './CXEventInfoHeader.props';
import { CXEventInfoHeaderCombinedProps } from './types';


const CXEventInfoHeader: React.FC<CXEventInfoHeaderCombinedProps> = (props) => {
  const {
    className,
    classes,
    eventTypeTag,
    title,
    date,
    time,
    location,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.cXEventInfoHeader;

  return (
    <div className={cx(currentStyle, className)}>
      <EventTypeTag
        className={cx(styles.eventTypeTag, classes?.eventTypeTag)}
        {...internalProps.eventTypeTag}
        {...eventTypeTag}/>
      <div className={cx(styles.content, classes?.content)}>
        <Text
          className={cx(styles.title, classes?.title)}
          {...internalProps.title}
          {...title}/>
        <div className={cx(styles.details, classes?.details)}>
          <div className={cx(styles.dateAndTime, classes?.dateAndTime)}>
            <Text
              className={cx(styles.date, classes?.date)}
              {...internalProps.date}
              {...date}/>
            <Text
              className={cx(styles.time, classes?.time)}
              {...internalProps.time}
              {...time}/>
          </div>
          <Text
            className={cx(styles.location, classes?.location)}
            {...internalProps.location}
            {...location}/>
        </div>
      </div>
    </div>
  );
};

export default CXEventInfoHeader;


import React from 'react';
import cx from 'classnames';

import DropdownSelect from '../../atoms/DropdownSelect';
import Text from '../../atoms/Text';

import styles from './Dropdown.module.scss';
import usePresenter from './Dropdown.presenter';
import getProps from './Dropdown.props';
import { DropdownCombinedProps } from './types';


const Dropdown: React.FC<DropdownCombinedProps> = (props) => {
  const {
    className,
    classes,
    label,
    dropdownSelect,
    ariaLabel, // MANUAL OVERRIDE
    state, // MANUAL OVERRIDE
    error, // MANUAL OVERRIDE
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles[`dropdown${state}`];  // MANUAL OVERRIDE

  let errorView;
  if (state === 'Error') { // MANUAL OVERRIDE
    errorView = (
      <Text
        className={styles.error}
        {...error} />
    );
  }

  return (
    <div className={cx(currentStyle, className)}>
      <Text
        className={cx(styles.label, classes?.label)}
        {...internalProps.label}
        {...label} />
      <DropdownSelect
        className={cx(styles.dropdownSelect, classes?.dropdownSelect)}
        ariaLabel={ariaLabel} // MANUAL OVERRIDE
        {...internalProps.dropdownSelect}
        {...dropdownSelect} />
      {errorView}
    </div>
  );
};

export default Dropdown;


import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import Text from '../../atoms/Text';
import Divider from '../../atoms/Divider';
import Logo from '../../atoms/Logo';
import AccountMenu from '../../molecules/AccountMenu';
import MenuButton from '../../molecules/MenuButton';
import SearchField from '../../molecules/SearchField';

import styles from './TopNav.module.scss';
import usePresenter from './TopNav.presenter';
import getProps from './TopNav.props';
import { TopNavCombinedProps } from './types';
import SearchNavWrapper from '../../blocks/SearchNavWrapper';
import EventDetailsModal from '../EventDetailsModal';
import SearchReturn from '../../blocks/SearchReturn';


const TopNav: React.FC<TopNavCombinedProps> = (props) => {
  const {
    type,
    className,
    classes,
    logo,
    accountMenu,
    menuButton,
    searchButton,
    backButton,
    title,
    button,
    description,
    searchField,
    divider,
    divider1,
    // MANUAL OVERRIDE STARTS
    eventDetailsModal,
    searchNavWrapper,
    searchReturn,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const variantName = `${type}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`topNav${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Checkout': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.logoContainer, classes?.logoContainer)}>
            <Logo
              className={cx(styles.logo, classes?.logo)}
              {...internalProps.logo}
              {...logo}/>
          </div>
          <AccountMenu
            className={cx(styles.accountMenu, classes?.accountMenu)}
            {...internalProps.accountMenu}
            {...accountMenu}/>
          <MenuButton
            className={cx(styles.menuButton, classes?.menuButton)}
            {...internalProps.menuButton}
            {...menuButton}/>
        </div>
      );
      break;
    }
    case 'SearchInNav': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.logoContainer, classes?.logoContainer)}>
            <Logo
              className={cx(styles.logo, classes?.logo)}
              {...internalProps.logo}
              {...logo}/>
          </div>
          <Button
            className={cx(styles.searchButton, classes?.searchButton)}
            {...internalProps.searchButton}
            {...searchButton}/>
          <MenuButton
            className={cx(styles.menuButton, classes?.menuButton)}
            {...internalProps.menuButton}
            {...menuButton}/>
          <AccountMenu
            className={cx(styles.accountMenu, classes?.accountMenu)}
            {...internalProps.accountMenu}
            {...accountMenu}/>
        </div>
      );
      break;
    }
    case 'SignedOut': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.logoContainer, classes?.logoContainer)}>
            <Logo
              className={cx(styles.logo, classes?.logo)}
              {...internalProps.logo}
              {...logo}/>
          </div>
        </div>
      );
      break;
    }
    case 'TicketSeatSelection': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.logoContainer, classes?.logoContainer)}>
            <Logo
              className={cx(styles.logo, classes?.logo)}
              {...internalProps.logo}
              {...logo}/>
          </div>
          <Button
            className={cx(styles.backButton, classes?.backButton)}
            {...internalProps.backButton}
            {...backButton}/>
          <div className={cx(styles.content, classes?.content)}>
            <div className={cx(styles.topTitle, classes?.topTitle)}>
              <Text
                className={cx(styles.title, classes?.title)}
                {...internalProps.title}
                {...title}/>
              <Button
                className={cx(styles.button, classes?.button)}
                {...internalProps.button}
                {...button}/>
            </div>
            <Text
              className={cx(styles.description, classes?.description)}
              {...internalProps.description}
              {...description}/>
          </div>
          <SearchField
            className={cx(styles.searchField, classes?.searchField)}
            {...internalProps.searchField}
            {...searchField}/>
          <AccountMenu
            className={cx(styles.accountMenu, classes?.accountMenu)}
            {...internalProps.accountMenu}
            {...accountMenu}/>
        </div>
      );
      break;
    }
    case 'WithoutSearchInNav': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.topContent, classes?.topContent)}>
            <div className={cx(styles.leftContent, classes?.leftContent)}>
              <Logo
                className={cx(styles.logo, classes?.logo)}
                {...internalProps.logo}
                {...logo}/>
              {/* MANUAL OVERRIDE */}
              <SearchNavWrapper
                {...searchNavWrapper}
              />
            </div>
            <div className={cx(styles.rightContent, classes?.rightContent)}>
              <AccountMenu
                className={cx(styles.accountMenu, classes?.accountMenu)}
                {...internalProps.accountMenu}
                {...accountMenu}/>
              <Divider
                className={cx(styles.divider, classes?.divider)}
                {...internalProps.divider}
                {...divider}/>
              <MenuButton
                className={cx(styles.menuButton, classes?.menuButton)}
                {...internalProps.menuButton}
                {...menuButton}/>
            </div>
          </div>
          <Divider
            className={cx(styles.divider1, classes?.divider1)}
            {...internalProps.divider1}
            {...divider1}/>
          <div className={cx(styles.bottomContent, classes?.bottomContent)}>
            <SearchField
              className={cx(styles.searchField, classes?.searchField)}
              {...internalProps.searchField}
              {...searchField}/>
          </div>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return (
    <>
      {componentView}
      <EventDetailsModal
        {...eventDetailsModal} />
      <SearchReturn
        {...searchReturn} />
    </>
  );
};

export default TopNav;


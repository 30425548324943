

type TeamLogoInternalProps = {
};

type TeamLogoPropsValues = {
  '': TeamLogoInternalProps;
};

const propValues: TeamLogoPropsValues = {
  '': {
  },
};

const getProps = (type: string): TeamLogoInternalProps => {
  const values: TeamLogoInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

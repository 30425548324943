/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { useEffect, useState } from 'react';
import useInteractor from './SubmenuWithTabs.interactor';
import { SubmenuWithTabsCombinedProps } from './types';
import { TabMenu } from '../../../modules/navigation/types';
import { SportsMenuTabListCombinedProps } from '../../molecules/SportsMenuTabList/types';
import { SubmenuTabItemCombinedProps, SubmenuTabItemProps } from '../../molecules/SubmenuTabItem/types';
import { SubmenuItemCombinedProps } from '../../molecules/SubmenuItem/types';
import { SubmenuItemListCombinedProps } from '../../molecules/SubmenuItemList/types';
import { MenuItemPositions } from '../../../components/organisms/TopNav/utils';

const usePresenter = (props: SubmenuWithTabsCombinedProps): SubmenuWithTabsCombinedProps => {
  const sportsData = useInteractor(props);
  const { onItemClicked } = props;
  const firstLeague = sportsData?.children[0].name;
  const arrayOfLeagues = sportsData?.children;
  const [hoveredLeague, setHoveredLeague] = useState<string | undefined>(firstLeague);
  const [focusPending, setFocusPending] = useState<boolean>(false);

  const arrayOfSportRefs: HTMLDivElement[] = [];
  const setSportRef = element => {
    if (element !== null) {
      arrayOfSportRefs.push(element);
    }
  };
  const arrayOfTeamRefs: HTMLDivElement[] = [];
  const setTeamRef = element => {
    if (element !== null) {
      arrayOfTeamRefs.push(element);
    }
  };
  const selectLeague = (league: string | undefined) => {
    if (league) {
      setHoveredLeague(league);
    }
  };

  const selectedLeague: TabMenu | undefined = arrayOfLeagues?.find(
    (league) => league.name === hoveredLeague,
  );

  const teams: SubmenuItemListCombinedProps = {
    submenuItems: selectedLeague?.children?.map((team, index): SubmenuItemCombinedProps => {
      const position: MenuItemPositions | undefined =
          index === (selectedLeague.children?.length || 1) - 1 ? MenuItemPositions.LAST : undefined;
      return {
        text: {
          value: team.name,
        },
        linkPath: team.slug,
        elementRef: setTeamRef,
        onItemClicked: onItemClicked,
        // SHIFT handler
        handleTab: (forward) => {
          if (forward && position === MenuItemPositions.LAST) {
            const selectedIndex = arrayOfLeagues?.findIndex((league) => league.name === selectedLeague.name) || 0;
            if ((arrayOfLeagues || [])[selectedIndex + 1]) {
              selectLeague((arrayOfLeagues || [])[selectedIndex + 1].name);
              // using timeout asserts that the league tab is active before focusing it
              setTimeout(() => arrayOfSportRefs[selectedIndex + 1].focus());
            }
          }
        },
      } as SubmenuItemCombinedProps;
    }),
  };

  const sportTabItems: SubmenuTabItemCombinedProps[] | undefined =
    sportsData?.children.map((sport, index): SubmenuTabItemProps => {
      const position: MenuItemPositions | undefined = index === 0 ? MenuItemPositions.FIRST : undefined;
      const item: SubmenuTabItemCombinedProps = {
        state: hoveredLeague === sport.name ? 'Selected' : 'Default',
        text: {
          value: sport.name,
        },
        linkPath: sport.slug && sport.slug?.length > 0 ? sport.slug : undefined,
        onItemClicked: onItemClicked,
        id: sport.name, // TODO: replace with slug?
        elementRef: setSportRef,
        // SHIFT+TAB handler
        handleTab: (forward) => {
          if (!forward && position !== MenuItemPositions.FIRST && selectedLeague) {
            // have to re-enable previous tab when shift+tabbing
            const selectedIndex = arrayOfLeagues?.findIndex((league) => league.name === selectedLeague.name) || 0;
            selectLeague((arrayOfLeagues || [])[selectedIndex - 1].name);
            // set state to make next render focus on last element
            setFocusPending(true);
          }
        },
      };
      return item;
    });

  useEffect(() => {
    // forces focus to go to the last element, to be triggered when shift+tabbing
    if (focusPending && teams.submenuItems && arrayOfTeamRefs.length > 0) {
      setFocusPending(false);
      arrayOfTeamRefs[(arrayOfTeamRefs.length || 1) - 1].focus();
    }
  }, [focusPending]);

  return {
    ...props,
    sportsMenuTabList: {
      submenuTabItems: sportTabItems,
      activeTab: hoveredLeague,
    } as SportsMenuTabListCombinedProps,
    submenuItemList: teams,
    hoveredLeague,
    selectLeague,
  };
};

export default usePresenter;

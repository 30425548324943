import React from 'react';
import cx from 'classnames';

import EventCardsBlock from '../../blocks/EventCardsBlock';
import FiltersBlock from '../../blocks/FiltersBlock';
import ImageBlock from '../../blocks/ImageBlock';

import styles from './VenueLandingPage.module.scss';
import usePresenter from './VenueLandingPage.presenter';
import getProps from './VenueLandingPage.props';
import { VenueLandingPageCombinedProps } from './types';


const VenueLandingPage: React.FC<VenueLandingPageCombinedProps> = (props) => {
  const {
    className,
    classes,
    imageBlock,
    filtersBlock,
    eventCardsBlock,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.venueLandingPage;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.container, classes?.container)}>
        <ImageBlock
          className={cx(styles.imageBlock, classes?.imageBlock)}
          {...internalProps.imageBlock}
          {...imageBlock}/>
        <FiltersBlock
          className={cx(styles.filtersBlock, classes?.filtersBlock)}
          {...internalProps.filtersBlock}
          {...filtersBlock}/>
        <EventCardsBlock
          className={cx(styles.eventCardsBlock, classes?.eventCardsBlock)}
          {...internalProps.eventCardsBlock}
          {...eventCardsBlock}/>
      </div>
    </div>
  );
};

export default VenueLandingPage;


import { TicketSelectionPageCombinedProps, TicketSelectionPagePresenterProps } from './types';
import { ListingMutation } from '../../../legacy/pages/SeatSelectionPage/utils';
import { getListingDetails } from '../../../modules/partnership/api';
import { useMutation } from 'react-query';

const useInteractor = (props: TicketSelectionPageCombinedProps): TicketSelectionPagePresenterProps => {
  const listingDetails = async (params: ListingMutation) => {
    const details = await getListingDetails(params.event_id, params.ticket_id, params.params);
    return details;
  };
  const { error, mutateAsync, isLoading } = useMutation(listingDetails);

  return {
    ...props,
    fetchListing: mutateAsync,
    isLoading: isLoading,
    apiError: error as Error || undefined,
  };
};

export default useInteractor;
import React from 'react';
import cx from 'classnames';

import DropdownMenuItem from '../../atoms/DropdownMenuItem';

import styles from './DropdownSelectList.module.scss';
import usePresenter from './DropdownSelectList.presenter';
import getProps from './DropdownSelectList.props';
import { DropdownSelectListCombinedProps } from './types';


const DropdownSelectList: React.FC<DropdownSelectListCombinedProps> = (props) => {
  const {
    className,
    classes,
    dropdownMenuItems,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.dropdownSelectList;

  const dropdownMenuItemViews = dropdownMenuItems?.map((dropdownMenuItem, index) => (
    <DropdownMenuItem
      key={index}
      className={cx(styles.dropdownMenuItem, classes?.dropdownMenuItem)}
      // MANUAL OVERRIDE STARTS
      classes={{
        divider: styles.divider,
      }}
      // MANUAL OVERRIDE ENDS
      {...internalProps.dropdownMenuItem}
      {...dropdownMenuItem} />
  )) || [];

  return (
    <div className={cx(currentStyle, className)}>
      {dropdownMenuItemViews}
    </div>
  );
};

export default DropdownSelectList;


import { useTranslation } from 'react-i18next';
import { getUnitDisplaySettings, handleAmountFormattingString, handleDecimalValuesForDisplay, handleDisplayUnitName } from '../../../lib/util';
import { round } from '../../pages/CheckoutManualPage/CheckoutManualPage.utils';
import { CardInfoNotRequiredBlockCombinedProps } from './types';
import { useContext } from 'react';
import { AuthContext } from '../../../modules/auth';
import { getTranslation } from '../../../lib/reactIUtils';

export const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const usePresenter = (props: CardInfoNotRequiredBlockCombinedProps): CardInfoNotRequiredBlockCombinedProps => {
  const { account } = useContext(AuthContext);
  const { onFormSubmit, rewardsCost } = props;
  const { t } = useTranslation();
  const handleSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    if (onFormSubmit) {
      onFormSubmit();
    }
  };
  const rewardType = account?.loyalty_program.loyalty_unit_name.toLowerCase();
  const { rewardSign, useDecimals } = getUnitDisplaySettings(rewardType);
  const numberUnits = handleDecimalValuesForDisplay(handleAmountFormattingString(rewardsCost?.currentSelection), useDecimals);
  return {
    ...props,
    blockTitle: {
      value: t('cardInfoNotRequired.infoText'),
    },
    description: {
      value: getTranslation('cardInfoNotRequired.description', {
        points: `${rewardSign}${numberUnits}`,
        unit: handleDisplayUnitName(account),
        currency: formatter.format(rewardsCost?.dollarWorth || 0),
      }),
    },
    button: {
      text: {
        value: t('cardInfoNotRequired.button'),
      },
    },
    handleSubmit,
  };
};

export default usePresenter;

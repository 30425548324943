import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import Text from '../../atoms/Text';
import TabList from '../../molecules/TabList';
import FAQList from '../../organisms/FAQList';

import customStyles from './Custom.module.scss';
import styles from './QuestionsBlock.module.scss';
import usePresenter from './QuestionsBlock.presenter';
import getProps from './QuestionsBlock.props';
import { QuestionsBlockCombinedProps } from './types';
import ContactUs from '../../organisms/ContactUs';
import { Dropdown } from 'react-bootstrap';
import DropdownSelectList from '../../organisms/DropdownSelectList';


const QuestionsBlock: React.FC<QuestionsBlockCombinedProps> = (props) => {
  const {
    className,
    classes,
    title,
    button,
    fAQList,
    // MANUAL OVERRIDE STARTS
    contactUs,
    dropdownSelectList,
    showDropdown,
    tabList,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.questionsBlock;

  // MANUAL OVERRIDE STARTS
  const bodyView = fAQList?.fAQItems ? (
    <FAQList
      className={cx(styles.fAQList, classes?.fAQList)}
      {...internalProps.fAQList}
      {...fAQList}/>
  ) : (
    <ContactUs className={customStyles.contactUs} {...contactUs} />
  );

  return (
    <div className={cx(currentStyle, className)}>
      <Text
        className={cx(styles.title, classes?.title)}
        {...internalProps.title}
        {...title}/>
      {/* MANUAL OVERRIDE */}
      <TabList className={customStyles.tabList} {...tabList} />
      {/* MANUAL OVERRIDE - wrapped button with dropdown */}
      <Dropdown show={showDropdown}>
        <Dropdown.Toggle>
          <Button
            className={cx(styles.button, classes?.button)}
            {...internalProps.button}
            {...button}/>
        </Dropdown.Toggle>
        {showDropdown ? (
          <Dropdown.Menu>
            <DropdownSelectList {...dropdownSelectList} />
          </Dropdown.Menu>
        ) : null}
      </Dropdown>
      {/* MANUAL OVERRIDE - replaced faqList with bodyView */}
      {bodyView}
    </div>
  );
};

export default QuestionsBlock;


import React from 'react';
import cx from 'classnames';

import Text from '../../atoms/Text';
import Button from '../../atoms/Button';
import Divider from '../../atoms/Divider';
import Icon from '../../atoms/Icon';

import styles from './TicketInformation.module.scss';
import usePresenter from './TicketInformation.presenter';
import getProps from './TicketInformation.props';
import { TicketInformationCombinedProps } from './types';
import { getClickAccessibilityProps } from '../../organisms/TopNav/utils';


const TicketInformation: React.FC<TicketInformationCombinedProps> = (props) => {
  const {
    type,
    style,
    className,
    classes,
    title,
    location,
    availability,
    value,
    valueType,
    other,
    divider,
    icon,
    pointsValue,
    button,
    // MANUAL OVERRIDE STARTS
    pointsValueType,
    handleMouseOver,
    handleMouseLeave,
    onClicked,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const variantName = `${type}${style}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`ticketInformation${variantName}`];

  let componentView;

  switch (variantName) {
    case 'DefaultDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.content, classes?.content)}>
            <div className={cx(styles.description, classes?.description)}>
              <Text
                className={cx(styles.title, classes?.title)}
                {...internalProps.title}
                {...title}/>
              <Text
                className={cx(styles.location, classes?.location)}
                {...internalProps.location}
                {...location}/>
              <Text
                className={cx(styles.availability, classes?.availability)}
                {...internalProps.availability}
                {...availability}/>
            </div>
            <div className={cx(styles.cost, classes?.cost)}>
              <div className={cx(styles.cost1, classes?.cost1)}>
                <Text
                  className={cx(styles.value, classes?.value)}
                  {...internalProps.value}
                  {...value}/>
                <Text
                  className={cx(styles.valueType, classes?.valueType)}
                  {...internalProps.valueType}
                  {...valueType}/>
              </div>
              <Text
                className={cx(styles.other, classes?.other)}
                {...internalProps.other}
                {...other}/>
            </div>
          </div>
          <Divider
            className={cx(styles.divider, classes?.divider)}
            {...internalProps.divider}
            {...divider}/>
        </div>
      );
      break;
    }
    case 'DefaultRounded': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.content, classes?.content)}>
            <div className={cx(styles.description, classes?.description)}>
              <Text
                className={cx(styles.title, classes?.title)}
                {...internalProps.title}
                {...title}/>
              <Text
                className={cx(styles.location, classes?.location)}
                {...internalProps.location}
                {...location}/>
              <Text
                className={cx(styles.availability, classes?.availability)}
                {...internalProps.availability}
                {...availability}/>
            </div>
            <div className={cx(styles.cost, classes?.cost)}>
              <div className={cx(styles.miles, classes?.miles)}>
                <Text
                  className={cx(styles.pointsValue, classes?.pointsValue)}
                  {...internalProps.pointsValue}
                  {...pointsValue}/>
                <Text
                  className={cx(styles.pointsValueType, classes?.pointsValueType)}
                  {...internalProps.pointsValueType}
                  {...pointsValueType}/>
              </div>
              <div className={cx(styles.dollar, classes?.dollar)}>
                <Text
                  className={cx(styles.value, classes?.value)}
                  {...internalProps.value}
                  {...value}/>
                <Text
                  className={cx(styles.valueType, classes?.valueType)}
                  {...internalProps.valueType}
                  {...valueType}/>
              </div>
              <Icon
                className={cx(styles.icon, classes?.icon)}
                {...internalProps.icon}
                {...icon}/>
            </div>
          </div>
          <Divider
            className={cx(styles.divider, classes?.divider)}
            {...internalProps.divider}
            {...divider}/>
        </div>
      );
      break;
    }
    case 'LargeTitleDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.content, classes?.content)}>
            <div className={cx(styles.description, classes?.description)}>
              <Text
                className={cx(styles.title, classes?.title)}
                {...internalProps.title}
                {...title}/>
              <div className={cx(styles.frame1, classes?.frame1)}>
                <Text
                  className={cx(styles.location, classes?.location)}
                  {...internalProps.location}
                  {...location}/>
                <div className={cx(styles.cost, classes?.cost)}>
                  <div className={cx(styles.cost1, classes?.cost1)}>
                    <Text
                      className={cx(styles.value, classes?.value)}
                      {...internalProps.value}
                      {...value}/>
                    <Text
                      className={cx(styles.valueType, classes?.valueType)}
                      {...internalProps.valueType}
                      {...valueType}/>
                  </div>
                  <Text
                    className={cx(styles.other, classes?.other)}
                    {...internalProps.other}
                    {...other}/>
                </div>
              </div>
            </div>
          </div>
          <Divider
            className={cx(styles.divider, classes?.divider)}
            {...internalProps.divider}
            {...divider}/>
        </div>
      );
      break;
    }
    case 'MoreDetailsDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.content, classes?.content)}>
            <div className={cx(styles.description, classes?.description)}>
              <Text
                className={cx(styles.title, classes?.title)}
                {...internalProps.title}
                {...title}/>
              <Text
                className={cx(styles.location, classes?.location)}
                {...internalProps.location}
                {...location}/>
              <Text
                className={cx(styles.availability, classes?.availability)}
                {...internalProps.availability}
                {...availability}/>
            </div>
            <div className={cx(styles.cost, classes?.cost)}>
              <div className={cx(styles.cost1, classes?.cost1)}>
                <Text
                  className={cx(styles.value, classes?.value)}
                  {...internalProps.value}
                  {...value}/>
                <Text
                  className={cx(styles.valueType, classes?.valueType)}
                  {...internalProps.valueType}
                  {...valueType}/>
              </div>
              <Text
                className={cx(styles.pointsValue, classes?.pointsValue)}
                {...internalProps.pointsValue}
                {...pointsValue}/>
              <Text
                className={cx(styles.other, classes?.other)}
                {...internalProps.other}
                {...other}/>
            </div>
          </div>
          <Divider
            className={cx(styles.divider, classes?.divider)}
            {...internalProps.divider}
            {...divider}/>
        </div>
      );
      break;
    }
    case 'WithPinDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.content, classes?.content)}>
            <div className={cx(styles.description, classes?.description)}>
              <Text
                className={cx(styles.title, classes?.title)}
                {...internalProps.title}
                {...title}/>
              <Text
                className={cx(styles.availability, classes?.availability)}
                {...internalProps.availability}
                {...availability}/>
            </div>
            <div className={cx(styles.rightContent, classes?.rightContent)}>
              <div className={cx(styles.cost, classes?.cost)}>
                <div className={cx(styles.cost1, classes?.cost1)}>
                  <Text
                    className={cx(styles.value, classes?.value)}
                    {...internalProps.value}
                    {...value}/>
                  <Text
                    className={cx(styles.valueType, classes?.valueType)}
                    {...internalProps.valueType}
                    {...valueType}/>
                </div>
                <Text
                  className={cx(styles.other, classes?.other)}
                  {...internalProps.other}
                  {...other}/>
              </div>
              <Button
                className={cx(styles.button, classes?.button)}
                {...internalProps.button}
                {...button}/>
            </div>
          </div>
          <Divider
            className={cx(styles.divider, classes?.divider)}
            {...internalProps.divider}
            {...divider}/>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  // MANUAL OVERRIDE STARTS
  const accessibilityProps = getClickAccessibilityProps({ onClick: onClicked }, !!onClicked);
  return (
    <div
      {...accessibilityProps} 
      onMouseOver={handleMouseOver} 
      onMouseLeave={handleMouseLeave} 
      onClick={onClicked} >
        {componentView}
    </div>
  );
  // MANUAL OVERRIDE ENDS
};

export default TicketInformation;


import cx from 'classnames';
import React from 'react';

import Button from '../../atoms/Button';
import Text from '../../atoms/Text';
import TextFields from '../../molecules/TextFields';

import CheckBoxItem from '../../atoms/CheckBoxItem';
import TextItem from '../../molecules/TextItem';
import ToolTip from '../../atoms/ToolTip';

import styles from './BillingInfoBlock.module.scss';
import usePresenter from './BillingInfoBlock.presenter';
import getProps from './BillingInfoBlock.props';
import customStyles from './Custom.module.scss';
import { BillingInfoBlockCombinedProps } from './types';


const BillingInfoBlock: React.FC<BillingInfoBlockCombinedProps> = (props) => {
  const {
    className,
    classes,
    blockTitle,
    firstName,
    lastName,
    address,
    secondaryAddress, // MANUAL OVERRIDE - resolved a typo
    city,
    state,
    postalCode,
    country,
    phoneNumber,
    button,
    // MANUAL OVERRIDE STARTS
    ariaLive,
    checkBoxItem,
    checkBoxItemError,
    phoneNumberEditButton,
    phoneNumberField,
    phoneNumberSaveButton,
    phoneNumberToolTip,
    shouldEnableTextField,
    toolTipIcon,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.billingInfoBlock;

  let secondaryAddressFieldView;
  if (secondaryAddress?.description?.value) {
    secondaryAddressFieldView = (
      <TextItem
        className={cx(styles.secondaryAddress, classes?.secondaryAddress)}
        {...internalProps.secondaryAddress}
        {...secondaryAddress}/>
    );
  }

  return (
    // MANUAL OVERRIDE - added aria-live
    <div className={cx(currentStyle, className)} aria-live={ariaLive}>
      <Text
        className={cx(styles.blockTitle, classes?.blockTitle)}
        {...internalProps.blockTitle}
        {...blockTitle}/>
      {/* MANUAL OVERRIDE - removed HighlightMessage */}
      <div className={cx(styles.content, classes?.content)}>
        {/* MANUAL OVERRIDE STARTS - replaced TextField with TextItem */}
        <div className={cx(styles.fieldGroup1, classes?.fieldGroup1)}>
          <TextItem
            className={cx(styles.firstName, classes?.firstName)}
            {...internalProps.firstName}
            {...firstName}/>
          <TextItem
            className={cx(styles.lastName, classes?.lastName)}
            {...internalProps.lastName}
            {...lastName}/>
        </div>
        <TextItem
          className={cx(styles.address, classes?.address)}
          {...internalProps.address}
          {...address}/>
        {/*  MANUAL OVERRIDE */}
        {secondaryAddressFieldView}
        <div className={cx(styles.fieldGroup2, classes?.fieldGroup2)}>
          <TextItem
            className={cx(styles.city, classes?.city)}
            {...internalProps.city}
            {...city}/>
          <TextItem
            className={cx(styles.state, classes?.state)}
            {...internalProps.state}
            {...state}/>
        </div>
        <div className={cx(styles.fieldGroup3, classes?.fieldGroup3)}>
          <TextItem
            className={cx(styles.postalCode, classes?.postalCode)}
            {...internalProps.postalCode}
            {...postalCode}/>
          <TextItem
            className={cx(styles.country, classes?.country)}
            {...internalProps.country}
            {...country}/>
        </div>
        {/* MANUAL OVERRIDE ENDS */}
        {/* MANUAL OVERRIDE - replace TextField */}
        <div className={customStyles.phoneNumberGroup}>
          {!shouldEnableTextField && (
            <TextItem
              className={styles.phoneNumber}
              {...internalProps.phoneNumber}
              {...phoneNumber} />
          )}
          {shouldEnableTextField && (
            <TextFields
              className={customStyles.phoneNumberField}
              {...internalProps.phoneNumberField}
              {...phoneNumberField}
            />
          )}
          <div
            className={cx({
              [customStyles.phoneNumberSave]: shouldEnableTextField,
              [customStyles.phoneNumberEdit]: !shouldEnableTextField,
            })}
          >
            <ToolTip {...phoneNumberToolTip}>
              <Button
                className={customStyles.toolTipIcon}
                {...internalProps.toolTipIcon}
                {...toolTipIcon} />
            </ToolTip>
            {shouldEnableTextField && (
              <Button
                {...internalProps.phoneNumberSaveButton}
                {...phoneNumberSaveButton} />
            )}
            {!shouldEnableTextField && (
              <Button
                {...internalProps.phoneNumberEditButton}
                {...phoneNumberEditButton} />)}
          </div>
        </div>
        <CheckBoxItem className={styles.checkBoxItem} {...internalProps.checkBoxItem} {...checkBoxItem} />
        <Text
          className={cx(customStyles.checkBoxItemError, classes?.checkBoxItemError)}
          {...internalProps.checkBoxItemError}
          {...checkBoxItemError} />
        <Button
          className={cx(styles.button, classes?.button)}
          {...internalProps.button}
          {...button}/>
      </div>
    </div>
  );
};

export default BillingInfoBlock;


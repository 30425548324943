import { TextProps } from '../../atoms/Text';
import { TextItemProps } from '../TextItem';

type OnBoardingContactInformationInternalProps = {
  name?: TextItemProps;
  email?: TextItemProps;
  phone?: TextItemProps;
  address?: TextItemProps;
};

type OnBoardingContactInformationPropsValues = {
  '': OnBoardingContactInformationInternalProps;
};

const propValues: OnBoardingContactInformationPropsValues = {
  '': {
    name: {
      style: 'BoldedLabel',
      label: {
        align: 'Left',
        style: 'SemiBold',
        size: 'Medium',
        colour: 'BaseLight',
        type: 'Body',
      } as TextProps,
      description: {
        align: 'Left',
        style: 'Light',
        size: 'Large',
        colour: 'BaseLight',
        type: 'BannerHeading',
      } as TextProps,
    } as TextItemProps,
    email: {
      style: 'BoldedLabel',
      label: {
        align: 'Left',
        style: 'SemiBold',
        size: 'Medium',
        colour: 'BaseLight',
        type: 'Body',
      } as TextProps,
      description: {
        align: 'Left',
        style: 'Regular',
        size: 'Large',
        colour: 'BaseLight',
        type: 'Decoration',
      } as TextProps,
    } as TextItemProps,
    address: {
      style: 'BoldedLabel',
      label: {
        align: 'Left',
        style: 'SemiBold',
        size: 'Medium',
        colour: 'BaseLight',
        type: 'Body',
      } as TextProps,
      description: {
        align: 'Left',
        style: 'Regular',
        size: 'Large',
        colour: 'BaseLight',
        type: 'Decoration',
      } as TextProps,
    } as TextItemProps,
    phone: {
      style: 'BoldedLabel',
      label: {
        align: 'Left',
        style: 'SemiBold',
        size: 'Medium',
        colour: 'BaseLight',
        type: 'Body',
      } as TextProps,
      description: {
        align: 'Left',
        style: 'Regular',
        size: 'Large',
        colour: 'BaseLight',
        type: 'Decoration',
      } as TextProps,
    } as TextItemProps,
  },
};

const getProps = (type: string): OnBoardingContactInformationInternalProps => {
  const values: OnBoardingContactInformationInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
import React from 'react';
import cx from 'classnames';

import SearchReturnItem from '../../molecules/SearchReturnItem';

import styles from './SearchReturnList.module.scss';
import usePresenter from './SearchReturnList.presenter';
import getProps from './SearchReturnList.props';
import { SearchReturnListCombinedProps } from './types';


const SearchReturnList: React.FC<SearchReturnListCombinedProps> = (props) => {
  const {
    className,
    classes,
    searchReturnItems,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.searchReturnList;

  const searchReturnItemViews = searchReturnItems?.map((searchReturnItem, index) => (
    <SearchReturnItem
      key={index}
      className={cx(styles.searchReturnItem, classes?.searchReturnItem)}
      {...internalProps.searchReturnItem}
      {...searchReturnItem} />
  )) || [];

  return (
    <div className={cx(currentStyle, className)}>
      {searchReturnItemViews}
    </div>
  );
};

export default SearchReturnList;


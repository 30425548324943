import { ListingDetailsQueryParams } from '../../../modules/partnership/types';

export type ListingMutation = {
  event_id: string;
  ticket_id: string;
  params: ListingDetailsQueryParams;
};

export const displayWithQuantity = (quantity?:number, amount?:string): string => {
  if (quantity &&  quantity > 1) {
    return `${amount} x ${quantity}`;
  }
  return `${amount}`;
};

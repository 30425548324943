import React from 'react';
import cx from 'classnames';

import ErrorHandler from '../../../modules/error/ErrorHandler';
import Spinner from '../../atoms/Spinner';

import C1XEventDetailsSliderBlock from '../../blocks/C1XEventDetailsSliderBlock';
import TicketTypeSelectionBlock from '../../blocks/TicketTypeSelectionBlock';

import styles from './TicketSelectionPage.module.scss';
import customStyles from './Custom.module.scss';

import usePresenter from './TicketSelectionPage.presenter';
import getProps from './TicketSelectionPage.props';
import { TicketSelectionPageCombinedProps } from './types';
import { CONTENT_ID } from '../../../lib/constants';


const TicketSelectionPage: React.FC<TicketSelectionPageCombinedProps> = (props) => {
  const {
    className,
    classes,
    c1XEventDetailsSliderBlock,
    ticketTypeSelectionBlock,
    // MANUAL OVERRIDE STARTS
    apiError,
    isLoading,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.ticketSelectionPage;

  // MANUAL OVERRIDE STARTS
  if (apiError) {
    return <ErrorHandler error={apiError} />;
  }
  if (isLoading) {
    return <div className={cx(customStyles.spinnerContainer, className)}>
      <Spinner></Spinner>
    </div>;
  }
  // MANUAL OVERRIDE ENDS

  return (
    <div className={cx(currentStyle, className)}>
      <C1XEventDetailsSliderBlock
        className={cx(styles.c1XEventDetailsSliderBlock, classes?.c1XEventDetailsSliderBlock)}
        {...internalProps.c1XEventDetailsSliderBlock}
        {...c1XEventDetailsSliderBlock} />
      <div className={cx(styles.container, classes?.container)} id={CONTENT_ID}>
        <TicketTypeSelectionBlock
          className={cx(styles.ticketTypeSelectionBlock, classes?.ticketTypeSelectionBlock)}
          {...internalProps.ticketTypeSelectionBlock}
          {...ticketTypeSelectionBlock} />
      </div>
    </div>
  );
};

export default TicketSelectionPage;


import { ButtonProps } from '../../atoms/Button';
import { TextProps } from '../../atoms/Text';
import { ButtonPairListProps } from '../../molecules/ButtonPairList';
import { SearchFieldProps } from '../../molecules/SearchField';


type SearchModalHeaderInternalProps = {
  closeButton?: ButtonProps;
  searchField?: SearchFieldProps;
  label?: TextProps;
  buttonPairList?: ButtonPairListProps;
};

type SearchModalHeaderPropsValues = {
  'Default': SearchModalHeaderInternalProps;
  'ShowRecentSearches': SearchModalHeaderInternalProps;
};

const propValues: SearchModalHeaderPropsValues = {
  'Default': {
    closeButton: {
      type: 'Icon',
      style: 'Text',
      size: 'Small',
    },
    searchField: {
      type: 'SearchBubble',
    },
  },
  'ShowRecentSearches': {
    closeButton: {
      type: 'Icon',
      style: 'Text',
      size: 'Small',
    },
    searchField: {
      type: 'SearchBubble',
    },
    label: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'BaseLight',
      align: 'Left',
    },
    buttonPairList: {
    },
  },
};

const getProps = (type: string): SearchModalHeaderInternalProps => {
  const values: SearchModalHeaderInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

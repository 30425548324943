import { TextProps } from '../../atoms/Text';


type PerformerPageBannerInternalProps = {
  title?: TextProps;
};

type PerformerPageBannerPropsValues = {
  '': PerformerPageBannerInternalProps;
};

const propValues: PerformerPageBannerPropsValues = {
  '': {
    title: {
      type: 'NewBannerHeading',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseLight',
      align: 'Left',
    },
  },
};

const getProps = (type: string): PerformerPageBannerInternalProps => {
  const values: PerformerPageBannerInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

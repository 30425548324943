import React from 'react';
import cx from 'classnames';

import Icon from '../Icon';
import Text from '../Text';

import styles from './NewDropdownMenuItem.module.scss';
import usePresenter from './NewDropdownMenuItem.presenter';
import getProps from './NewDropdownMenuItem.props';
import { NewDropdownMenuItemCombinedProps } from './types';


const NewDropdownMenuItem: React.FC<NewDropdownMenuItemCombinedProps> = (props) => {
  const {
    type,
    state,
    className,
    classes,
    disabled,
    buttonType,
    buttonForm,
    href,
    target,
    externalLink,
    onClick,
    icon,
    text,
    trailingIcon,
    hideIcon, // MANUAL OVERRIDE
  } = usePresenter(props);

  const variantName = `${type}${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`newDropdownMenuItem${variantName}`];


  let componentView;

  // MANUAL OVERRIDE
  const iconView = hideIcon ? undefined : <Icon
    className={cx(styles.icon, classes?.icon)}
    {...icon} />;

  switch (variantName) {
    case 'IconTextSelected': {
      componentView = (
        <button
          disabled={disabled}
          type={buttonType}
          form={buttonForm}
          onClick={onClick}
          className={cx(currentStyle, className)}>
          <div className={cx(styles.content, classes?.content)}>
            {/* MANUAL OVERRIDE */}
            { iconView }
            <Text
              className={cx(styles.text, classes?.text)}
              {...internalProps.text}
              {...text}/>
            <Icon
              className={cx(styles.trailingIcon, classes?.trailingIcon)}
              {...internalProps.trailingIcon}
              {...trailingIcon}/>
          </div>
        </button>
      );
      break;
    }
    case 'IconTextUnselected': {
      componentView = (
        <button
          disabled={disabled}
          type={buttonType}
          form={buttonForm}
          onClick={onClick}
          className={cx(currentStyle, className)}>
          <div className={cx(styles.content, classes?.content)}>
            {/* MANUAL OVERRIDE */}
            { iconView }
            <Text
              className={cx(styles.text, classes?.text)}
              {...internalProps.text}
              {...text}/>
          </div>
        </button>
      );
      break;
    }
    case 'TextUnselected':
    case 'TextCriticalUnselected': {
      componentView = (
        <button
          disabled={disabled}
          type={buttonType}
          form={buttonForm}
          onClick={onClick}
          className={cx(currentStyle, className)}>
          <div className={cx(styles.content, classes?.content)}>
            <Text
              className={cx(styles.text, classes?.text)}
              {...internalProps.text}
              {...text}/>
          </div>
        </button>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default NewDropdownMenuItem;


import { PrimaryBannerProps } from '../../molecules/PrimaryBanner';


type PrimaryBannerBlockInternalProps = {
  primaryBanner?: PrimaryBannerProps;
};

type PrimaryBannerBlockPropsValues = {
  '': PrimaryBannerBlockInternalProps;
};

const propValues: PrimaryBannerBlockPropsValues = {
  '': {
    primaryBanner: {
    },
  },
};

const getProps = (type: string): PrimaryBannerBlockInternalProps => {
  const values: PrimaryBannerBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

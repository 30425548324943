import { ImageProps } from '../../atoms/Image';
import { TextProps } from '../../atoms/Text';


type PaymentMethodInternalProps = {
  label?: TextProps;
  cardImage?: ImageProps;
  description?: TextProps;
};

type PaymentMethodPropsValues = {
  '': PaymentMethodInternalProps;
};

const propValues: PaymentMethodPropsValues = {
  '': {
    label: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    cardImage: {
    },
    description: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
  },
};

const getProps = (type: string): PaymentMethodInternalProps => {
  const values: PaymentMethodInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

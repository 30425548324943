import React from 'react';
import cx from 'classnames';

import NewDropdownMenuItem from '../../atoms/NewDropdownMenuItem';

import styles from './NewDropdownSelectList.module.scss';
import usePresenter from './NewDropdownSelectList.presenter';
import getProps from './NewDropdownSelectList.props';
import { NewDropdownSelectListCombinedProps } from './types';


const NewDropdownSelectList: React.FC<NewDropdownSelectListCombinedProps> = (props) => {
  const {
    className,
    classes,
    newDropdownMenuItems,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.newDropdownSelectList;

  const newDropdownMenuItemViews = newDropdownMenuItems?.map((newDropdownMenuItem, index) => (
    <NewDropdownMenuItem
      key={index}
      className={cx(styles.newDropdownMenuItem, classes?.newDropdownMenuItem)}
      {...internalProps.newDropdownMenuItem}
      {...newDropdownMenuItem} />
  )) || [];

  return (
    <div className={cx(currentStyle, className)}>
      {newDropdownMenuItemViews}
    </div>
  );
};

export default NewDropdownSelectList;


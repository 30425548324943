import React from 'react';
import cx from 'classnames';

import Divider from '../../atoms/Divider';
import StepperList from '../../organisms/StepperList';

import styles from './StepperBlock.module.scss';
import usePresenter from './StepperBlock.presenter';
import getProps from './StepperBlock.props';
import { StepperBlockCombinedProps } from './types';


const StepperBlock: React.FC<StepperBlockCombinedProps> = (props) => {
  const {
    className,
    classes,
    stepperList,
    divider,
    activeStep, // MANUAL OVERRIDE
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.stepperBlock;

  return (
    <div className={cx(currentStyle, className)}>
      <StepperList
        className={cx(styles.stepperList, classes?.stepperList)}
        activeStep={activeStep}
        {...internalProps.stepperList}
        {...stepperList}/>
      <Divider
        className={cx(styles.divider, classes?.divider)}
        {...internalProps.divider}
        {...divider}/>
    </div>
  );
};

export default StepperBlock;


import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IS_DEV } from '../../../lib/config';
import { AriaRoles } from '../../../lib/types';
import { AuthContext } from '../../../modules/auth';
import { BraintreeContext } from '../../../modules/braintree';

import customStyles from './Custom.module.scss';
import { binArray, dummyLastFour } from './creditCardsBin';
import { CardInfoBlockCombinedProps } from './types';
import { useAnalyticsManager } from '../../../modules/analytics/useAnalyticsManager';
import { trackSelectContentEvent } from '../../../modules/analytics/util';

const usePresenter = (props: CardInfoBlockCombinedProps): CardInfoBlockCombinedProps => {
  const { instance, loading: isLoading } = useContext(BraintreeContext);
  const { account } = useContext(AuthContext);
  const { onCardTokenized, isCardDisabled, preSubmitValidation } = props;
  const { t } = useTranslation();

  const { trackEvent } = useAnalyticsManager();

  const [hasValidationError, setHasValidationError] = useState(false);

  useEffect(() => {
    if (isCardDisabled) {
      instance?.clear('number');
      instance?.clear('expirationDate');
      instance?.clear('cvv');
    }
  }, [isCardDisabled, instance]);

  const handleSubmit = async (e?: React.FormEvent<HTMLFormElement>) => {
    try {
      e?.preventDefault();

      const isFormValidated = preSubmitValidation ? preSubmitValidation() : true;
      if (!isFormValidated) {
        onCardTokenized?.(null, true);
        return;
      }

      if (!isCardDisabled && isFormValidated) {
        if (!instance) {
          return;
        }
        // TODO confirm if we need to pass this fields in.
        const tokenPayload = await instance.tokenize({
          // cardholderName: '',
          // billingAddress: {},
        });

        if (tokenPayload) {
          if ((tokenPayload.details?.lastFour !== account?.loyalty_program?.last_four
            && (!IS_DEV && !dummyLastFour.includes(tokenPayload.details?.lastFour)))
            || !binArray.includes(tokenPayload.details?.bin)) {
            setHasValidationError(true);
            if (onCardTokenized) {
              onCardTokenized(null, true);
            }
          } else if (onCardTokenized) {
            onCardTokenized(tokenPayload, false);
          }
        }
      } else if (onCardTokenized && isCardDisabled) {
        onCardTokenized(null, false);
      }
    } catch (err) {
      setHasValidationError(true);
    }
    // TODO confirm requirements
    // payload.details.bin <-- there's some logic are validating this. not sure if part of the current story
  };

  // braintree styling documentation can be found here
  // https://developer.paypal.com/braintree/docs/guides/hosted-fields/styling
  // https://braintree.github.io/braintree-web/3.82.0/module-braintree-web_hosted-fields.html#~styleOptions
  const braintreeStyles = {
    input: {
      'font-size': '16px',
      color: '#3A3A3A',
      padding: '0.75rem 1rem 0.75rem 1rem',
    },
  };

  return {
    ...props,
    blockTitle: {
      className: customStyles.cardInfoBlockLabels,
      value: t('cardInfo.cardInfoLabel'),
    },
    description: {
      className: customStyles.cardInfoBlockLabels,
      value: t('cardInfo.cardInfoDescription', { lastFour: account?.loyalty_program?.last_four }),
    },
    cardNumber: {
      className: customStyles.cardInfoBlockLabels,
      value: t('cardInfo.cardNumberLabel'),
    },
    expiration: {
      className: customStyles.cardInfoBlockLabels,
      value: t('cardInfo.expirationLabel'),
    },
    securityCode: {
      className: customStyles.cardInfoBlockLabelsSecurity,
      value: t('cardInfo.securityCodeLabel'),
    },
    button: {
      text: {
        value: t('cardInfo.button'),
      },
    },
    isLoading,
    hasValidationError: hasValidationError,
    braintreeStyles,
    cardNumberErrorMessage: hasValidationError && !isCardDisabled ? {
      value: t('cardInfo.cardValidationError'),
      ariaRole: AriaRoles.ALERT,
      ariaAtomic: true,
    } : undefined,
    handleSubmit: handleSubmit,
  };
};

export default usePresenter;

import { ButtonProps } from '../../atoms/Button';
import { DividerProps } from '../../atoms/Divider';


type SubmenuListInternalProps = {
  button?: ButtonProps;
  divider?: DividerProps; // MANUAL OVERRIDE
};

type SubmenuListPropsValues = {
  '': SubmenuListInternalProps;
};

const propValues: SubmenuListPropsValues = {
  '': {
    // MANUAL OVERRIDE STARTS
    divider: {
      type: 'Vertical',
      style: 'Thick',
      colour: 'White',
    },
    // MANUAL OVERRIDE ENDS
    button: {
      type: 'Text',
      style: 'Outline',
      size: 'Small',
    },
  },
};

const getProps = (type: string): SubmenuListInternalProps => {
  const values: SubmenuListInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

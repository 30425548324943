import { SearchReturnItemProps } from '../../molecules/SearchReturnItem';


type SearchReturnListInternalProps = {
  searchReturnItem?: SearchReturnItemProps;
};

type SearchReturnListPropsValues = {
  '': SearchReturnListInternalProps;
};

const propValues: SearchReturnListPropsValues = {
  '': {
    searchReturnItem: {
      style: 'LightBackground',
    },
  },
};

const getProps = (type: string): SearchReturnListInternalProps => {
  const values: SearchReturnListInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import { TextProps } from '../Text';
import { DividerProps } from '../Divider';
import { IconProps } from '../Icon';


type DropdownMenuItemInternalProps = {
  divider?: DividerProps;
  divider1?: DividerProps;
  title?: TextProps;
  description?: TextProps;
  text?: TextProps;
  icon?: IconProps;
};

type DropdownMenuItemPropsValues = {
  'IconText': DropdownMenuItemInternalProps;
  'IndicatorText': DropdownMenuItemInternalProps;
  'Text': DropdownMenuItemInternalProps;
};

const propValues: DropdownMenuItemPropsValues = {
  'IconText': {
    divider: {
      type: 'Horizontal',
      style: 'Thin',
      colour: 'DigitalGrey10',
    },
    icon: {
      asset: 'Checkmark',
      style: 'ActionActive',
    },
    text: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'ActionActive',
      align: 'Left',
    },
  },
  'IndicatorText': {
    divider: {
      type: 'Horizontal',
      style: 'Thin',
      colour: 'DigitalGrey15',
    },
    divider1: {
      type: 'Vertical',
      style: 'Thick',
      colour: 'White',
    },
    title: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    description: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
  },
  'Text': {
    divider: {
      type: 'Horizontal',
      style: 'Thin',
      colour: 'DigitalGrey10',
    },
    text: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
  },
};

const getProps = (type: string): DropdownMenuItemInternalProps => {
  const values: DropdownMenuItemInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

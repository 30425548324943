import React from 'react';
import cx from 'classnames';

import SearchMatchesBlock from '../../blocks/SearchMatchesBlock';

import styles from './SearchMatchPage.module.scss';
import usePresenter from './SearchMatchPage.presenter';
import getProps from './SearchMatchPage.props';
import { SearchMatchPageCombinedProps } from './types';
import SearchBlock from '../../blocks/SearchBlock';


const SearchMatchPage: React.FC<SearchMatchPageCombinedProps> = (props) => {
  const {
    className,
    classes,
    searchMatchesBlock,
    searchBlock, //MANUAL OVERRIDE
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.searchMatchPage;

  return (
    <div className={cx(currentStyle, className)}>
      <SearchBlock className={styles.searchBlock} {...searchBlock} />{/* MANUAL OVERRIDE */}
      <div className={styles.container}>
        <SearchMatchesBlock
          className={cx(styles.searchMatchesBlock, classes?.searchMatchesBlock)}
          {...internalProps.searchMatchesBlock}
          {...searchMatchesBlock}
        />
      </div>
    </div>
  );
};

export default SearchMatchPage;


import { IconProps } from '../../atoms/Icon';
import { TextProps } from '../../atoms/Text';


type EventsEmptyStateInternalProps = {
  icon?: IconProps;
  title?: TextProps;
  message?: TextProps;
};

type EventsEmptyStatePropsValues = {
  'Dark': EventsEmptyStateInternalProps;
  'Light': EventsEmptyStateInternalProps;
  'LightPerformer': EventsEmptyStateInternalProps;
};

const propValues: EventsEmptyStatePropsValues = {
  'Dark': {
    icon: {
      asset: 'CalendarIllustration',
      style: 'DigitalGrey80',
    },
    title: {
      type: 'Subheading',
      size: 'Small',
      style: 'Regular',
      colour: 'BaseLight',
      align: 'Center',
    },
    message: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseLight',
      align: 'Center',
    },
  },
  'Light': {
    icon: {
      asset: 'CalendarIllustration',
      style: 'DigitalGrey80',
    },
    title: {
      type: 'Subheading',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Center',
    },
    message: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Center',
    },
  },
  'LightPerformer': {
    icon: {
      asset: 'CalendarIllustration',
      style: 'DigitalGrey80',
    },
    title: {
      type: 'Subheading',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Center',
    },
    message: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Center',
    },
  },
};

const getProps = (type: string): EventsEmptyStateInternalProps => {
  const values: EventsEmptyStateInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

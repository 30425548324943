import { useContext } from 'react';
import { MapContext } from '../../../modules/map';
import { FilterSettingCombinedProps } from './types';

const usePresenter = (props: FilterSettingCombinedProps): FilterSettingCombinedProps => {
  const { handleClick } = props;
  const {
    dispatch: { setMinPrice },
  } = useContext(MapContext);

  const clickAction = () => {
    if (handleClick) {
      handleClick(setMinPrice?.({
        id: '',
        value: 0,
      }),
      );
    }
  };
  return {
    ...props,
    handleClick: clickAction,
  };
};

export default usePresenter;
import { LandingPageBannerProps } from '../../molecules/LandingPageBanner';


type LandingPageBannerBlockInternalProps = {
  landingPageBanner?: LandingPageBannerProps;
};

type LandingPageBannerBlockPropsValues = {
  '': LandingPageBannerBlockInternalProps;
};

const propValues: LandingPageBannerBlockPropsValues = {
  '': {
    landingPageBanner: {
    },
  },
};

const getProps = (type: string): LandingPageBannerBlockInternalProps => {
  const values: LandingPageBannerBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { BREAKPOINTS } from '../../../lib/constants';
import { getTranslation } from '../../../lib/reactIUtils';
import { useWindowSize } from '../../../lib/util';
import { Event, Performer } from '../../../modules/partnership';
import { useSnapList } from '../../../modules/snap/SnapListContext/useSnapList';

import { Style } from '../../atoms/Spinner/Spinner';
import { MlbCardCombinedProps } from '../../molecules/MlbCard/types';

import customStyles from './Custom.module.scss';
import useInteractor from './EventCardsBlock.interactor';
import { EventCardsBlockCombinedProps } from './types';
import {
  mapEventsAsCardDark, mapEventsAsCardLight, mapEventsAsCardNoImage, mapEventsAsCardPerformer,
  mapEventsAsCardSpecificDark, mapEventsAsCardVenue,
} from './utils';
import { useAnalyticsManager } from '../../../modules/analytics/useAnalyticsManager';
import { trackSelectContentEvent } from '../../../modules/analytics/util';

const usePresenter = (props: EventCardsBlockCombinedProps): EventCardsBlockCombinedProps => {
  const {
    blockHeader,
    type,
    showMLBCard,
    pageName,
    pageCarouselIndex,
  } = props;

  const {
    cardData,
    isExclusivePage,
    hasMorePages,
    showMoreData,
    headerType,
    sliderInformationType,
    isLoading,
  } = useInteractor(props);
  const history = useHistory();
  const { t } = useTranslation();
  const { trackEvent } = useAnalyticsManager();

  const trackCardEvents = (index: number, eventName: string) => {
    trackEvent('select_item');
    trackSelectContentEvent(
      trackEvent,
      pageName || '',
      blockHeader?.text?.value?.toString() || '',
      eventName,
      {
        carousel_location: pageCarouselIndex,
        click_tile_location: index,
      },
    );
  };

  const [scrollHeight, setScrollHeight] = useState<number>(0);

  useEffect(() => {
    // scroll back to original position of last event before fetching for more events in performer pages
    // TODO: recheck necessity of this hook when we finalize on show more's loading state behaviour
    if (type === 'EventsNoImageBlock') {
      window.scrollTo({
        top: scrollHeight,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardData]);

  const onShowMoreButtonClick = () => {
    trackSelectContentEvent(
      trackEvent,
      'Button',
      'EventList',
      t('shopByWidget.showMore'),
    );
    if (showMoreData) {
      setScrollHeight(window.scrollY);
      showMoreData();
    }
  };

  const redirectTo = (link: string) => {
    history.push(link);
  };

  const {
    listElementRef,
    currentIndex,
    canGoBackward,
    canGoForward,
    onBackwardClicked,
    onForwardClicked,
    updateItemCount,
    updateScrollBy,
  } = useSnapList();

  const [scrollBy, setScrollBy] = useState<number>(4);
  const { width } = useWindowSize();
  const isDesktop = !!width && width >= BREAKPOINTS.tablet;
  useEffect(() => {
    if (width && width >= BREAKPOINTS.desktop) {
      const desktopLarge = type === 'CardSpecificEventsDarkBlock' ? 2 : 4;
      const desktopSmall = type === 'CardSpecificEventsDarkBlock' ? 2 : 3;

      const scrollByValue = width < BREAKPOINTS.desktopXl ? desktopSmall : desktopLarge;
      setScrollBy(scrollByValue);
      updateScrollBy(scrollByValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, type]);

  useEffect(() => {
    if (showMLBCard) {
      updateItemCount(cardData.length >= 23 ? 24 : cardData.length + 1);
      return;
    }
    updateItemCount(cardData.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardData.length]);

  const getLoadingStyle = (): Style => {
    switch (type) {
      case 'CardSpecificEventsDarkBlock':
      case 'EventsDarkBlock':
        return 'light';

      default:
        return 'dark';
    }
  };

  const MLBCard: MlbCardCombinedProps = {
    type: 'Light',
    className: customStyles.snapItemContainer,
    titleText: {
      value: getTranslation('mlbCard.title'),
    },
    bodyText: {
      value: t('mlbCard.body', {
        currentYear: new Date().getFullYear(),
      }),
    },
    actionText: {
      value: t('mlbCard.action'),
    },
    onClick: () => {
      trackSelectContentEvent(
        trackEvent,
        'Exclusive Events Landing Page',
        'MLBCard',
        t('mlbCard.title'),
      );
    },
    href: '/mlb',
  };

  // snap is enabled on most types except for EventsNoImageBlock and EventsVenueBlock (they are vertical).
  // snap is disabled when there's not enough items or on smaller breakpoints.
  const isSnapEnabled = !['EventsNoImageBlock', 'EventsVenueBlock'].includes(type || '') &&
    cardData.length > scrollBy &&
    (width || 0) >= BREAKPOINTS.desktop;
  const sliderInformationTextOptions = {
    startEventIndex: currentIndex + 1,
    endEventIndex: Math.min(currentIndex + scrollBy, cardData.length),
    totalEvents: cardData.length,
  };
  return {
    ...props,
    shouldDisplay: isLoading || cardData.length >= (props.minimumDisplayCount || -1),
    type: type,
    state: isLoading ? 'Loading' : (showMLBCard ? 'Default' : (cardData.length ? 'Default' : 'Empty')),
    // filterGroup: props.filterGroup,
    classes: {
      container: customStyles.snapItemListContainer,
      containerWrapper: customStyles.containerWrapper,
      slideBackwardButton: canGoBackward ? customStyles.slideBackwardButton : customStyles.hideSlideButton,
      slideForwardButton: canGoForward ? customStyles.slideForwardButton : customStyles.hideSlideButton,
    },
    blockHeader: {
      ...blockHeader,
      type: headerType ?? 'WithToolTip',
      toolTipIcon: {
        asset: 'InfoFilled',
      },
    },
    sliderInfo: {
      type: (type === 'EventsDarkBlock' || type === 'CardSpecificEventsDarkBlock') ? 'Dark' : 'Light',
      text: {
        colour: (type === 'EventsDarkBlock' || type === 'CardSpecificEventsDarkBlock') ? 'BaseLight' : 'SubduedDark',
        value: sliderInformationType === 'Events' ?
          t('sliderInfo.counterEvents', sliderInformationTextOptions) :
          t('sliderInfo.counterPerformers', sliderInformationTextOptions),
      },
    },
    isSnapEnabled,
    slideBackwardButton: {
      onClick: onBackwardClicked,
      ariaLabel: t('sliderButton.backwardButton'),
    },
    slideForwardButton: {
      onClick: onForwardClicked,
      ariaLabel: t('sliderButton.forwardButton'),
    },
    cardSpecificEventsList: type === 'CardSpecificEventsDarkBlock' ? {
      listElementRef,
      specialEventCards: mapEventsAsCardSpecificDark(cardData as Event[], t, trackCardEvents),
    } : {},
    eventCardDarkList: type === 'EventsDarkBlock' ? {
      listElementRef,
      eventCardDarks: showMLBCard ? [MLBCard, ...mapEventsAsCardDark(cardData as Event[], t, trackCardEvents)] : mapEventsAsCardDark(cardData as Event[], t, trackCardEvents),
    } : {},
    eventCardLightList: type === 'EventsLightBlock' ? {
      listElementRef,
      eventCardLights: mapEventsAsCardLight(cardData as Event[], t, trackCardEvents),
    } : {},
    eventCardNoImageList: type === 'EventsNoImageBlock' ? {
      eventCardNoImages: mapEventsAsCardNoImage(cardData as Event[], t, redirectTo, trackCardEvents, isDesktop),
    } : {},
    eventCardVenueList: type === 'EventsVenueBlock' ? {
      eventCardVenues: mapEventsAsCardVenue(cardData as Event[], t, redirectTo, trackCardEvents, isDesktop),
    } : {},
    performerCardList: type === 'PerformersLightBlock' ? {
      listElementRef,
      performerCards: mapEventsAsCardPerformer(cardData as Performer[], t, trackCardEvents),
    } : {},
    hasMorePages,
    showMoreButton: {
      text: {
        value: t('shopByWidget.showMore'),
      },
      onClick: onShowMoreButtonClick,
    },
    eventsEmptyState: {
      className: customStyles.eventsEmptyState,
      style: isExclusivePage ? 'Dark' : type === 'PerformersLightBlock' ? 'LightPerformer' : 'Light',
      title: {
        align: width && width >= BREAKPOINTS.desktop ? 'Left' : 'Center',
        value: t('search.noEventsTitle'),
      },
      message: {
        align: width && width >= BREAKPOINTS.desktop ? 'Left' : 'Center',
        value: isExclusivePage ? t('search.noCardHolderExclusiveEventsMessage') : t('search.noEventsMessage'),
      },
    },
    spinner: {
      style: getLoadingStyle(),
    },
  };
};

export default usePresenter;

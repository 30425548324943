import React from 'react';
import cx from 'classnames';

import Divider from '../../atoms/Divider';
import Text from '../../atoms/Text';
import ThumbnailTextList from '../../molecules/ThumbnailTextList';

import styles from './SportTeamsListBlock.module.scss';
import usePresenter from './SportTeamsListBlock.presenter';
import getProps from './SportTeamsListBlock.props';
import { SportTeamsListBlockCombinedProps } from './types';


const SportTeamsListBlock: React.FC<SportTeamsListBlockCombinedProps> = (props) => {
  const {
    className,
    classes,
    text,
    divider,
    thumbnailTextList,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.sportTeamsListBlock;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.title, classes?.title)}>
        <Text
          className={cx(styles.text, classes?.text)}
          {...internalProps.text}
          {...text}/>
      </div>
      <Divider
        className={cx(styles.divider, classes?.divider)}
        {...internalProps.divider}
        {...divider}/>
      <div className={cx(styles.content, classes?.content)}>
        <ThumbnailTextList
          className={cx(styles.thumbnailTextList, classes?.thumbnailTextList)}
          {...internalProps.thumbnailTextList}
          {...thumbnailTextList}/>
      </div>
    </div>
  );
};

export default SportTeamsListBlock;


import React, { createContext, useContext, useReducer, useRef } from 'react';
import { ListingsContext } from '../listings';
import { DEFAULT_CONTEXT_VALUES } from './constants';
import { getMapState, mapContent } from './selectors';
import type {
  HoveredSectionAction,
  MapContextType,
  MapProviderProps,
  MapState,
  MinPrice,
  MinPriceAction,
  MouseAction,
  Pos,
  PosAction,
  JSONMap,
} from './types';
import { ActionType } from './types';
import useQueryHook from './useQueryHook';

export const MapContext = createContext<MapContextType>(DEFAULT_CONTEXT_VALUES);
const { Provider } = MapContext;

const initialState: MapState = {
  minPrice: { id: '', value: 0 },
  pos: { x: 0, y: 0 },
  hoveredSectionId: '',
  isMouseDown: false,
  isDragging: false,
  isDoubleClicked: false,
};

type Action = MinPriceAction | PosAction | HoveredSectionAction | MouseAction | { type: ActionType.SET_MAP_STATE; payload: Partial<MapState> };

const reducer = (mapState: MapState, action: Action) => {
  switch (action.type) {
    case ActionType.SET_MIN_PRICE:
      return { ...mapState, minPrice: action.payload };
    case ActionType.SET_POS:
      return { ...mapState, pos: action.payload };
    case ActionType.SET_HOVERED_SECTION_ID:
      return { ...mapState, hoveredSectionId: action.payload };
    case ActionType.SET_MOUSE_DOWN:
      return { ...mapState, isMouseDown: action.payload };
    case ActionType.SET_DRAGGING:
      return { ...mapState, isDragging: action.payload };
    case ActionType.SET_DOUBLE_CLICK:
      return { ...mapState, isDoubleClicked: action.payload };
    case ActionType.SET_MAP_STATE:
      return { ...mapState, ...action.payload };
    default:
      return mapState;
  }
};
const setMinPrice = (dispatch: React.Dispatch<MinPriceAction>, payload: MinPrice): void => {
  dispatch({ type: ActionType.SET_MIN_PRICE, payload });
};

const setPos = (dispatch: React.Dispatch<PosAction>, payload: Pos): void => {
  dispatch({ type: ActionType.SET_POS, payload });
};

const setHoveredSectionId = (dispatch: React.Dispatch<HoveredSectionAction>, payload: string): void => {
  dispatch({ type: ActionType.SET_HOVERED_SECTION_ID, payload });
};
const setIsMouseDown = (dispatch: React.Dispatch<MouseAction>, payload: boolean): void => {
  dispatch({ type: ActionType.SET_MOUSE_DOWN, payload });
};
const setIsDragging = (dispatch: React.Dispatch<MouseAction>, payload: boolean): void => {
  dispatch({ type: ActionType.SET_DRAGGING, payload });
};
const setIsDoubleClicked = (dispatch: React.Dispatch<MouseAction>, payload: boolean): void => {
  dispatch({ type: ActionType.SET_DOUBLE_CLICK, payload });
};

const setMapState = (dispatch: React.Dispatch<Action>, payload: Partial<MapState>): void => {
  dispatch({ type: ActionType.SET_MAP_STATE, payload });
};

export const MapProvider: React.FC<MapProviderProps> = ({
  children,
  initialFetchDisabled,
  initialProps,
  initialEnabled = true,
}) => {
  const [mapState, dispatch] = useReducer(reducer, initialState);

  const { selectors: listingsSelectors } = useContext(ListingsContext);
  const { mapJsonFileName } = listingsSelectors;

  const mapContentQuery = useQueryHook<JSONMap>({
    endpoint: mapJsonFileName,
    params: {},
    enabled: !!mapJsonFileName && initialEnabled,
    initialData: initialProps?.data,
    initialFetchDisabled,
  });

  return (
        <Provider
            value={{
              state: mapContentQuery,
              selectors: {
                mapContent: mapContent(mapContentQuery),
                mapState: getMapState(mapState),
              },
              dispatch: {
                setMapState: (payload: Partial<MapState>) => setMapState(dispatch, payload),
                setIsMouseDown: (isDown: boolean) => setIsMouseDown(dispatch, isDown),
                setIsDragging: (isDragging: boolean) => setIsDragging(dispatch, isDragging),
                setIsDoubleClicked: (isDoubleClicked: boolean) => setIsDoubleClicked(dispatch, isDoubleClicked),
              },
            }}
        >
            {children}
        </Provider>
  );
};

export default MapContext;

import React from 'react';
import cx from 'classnames';

import QuestionsBlock from '../../blocks/QuestionsBlock';

import styles from './HelpPage.module.scss';
import usePresenter from './HelpPage.presenter';
import getProps from './HelpPage.props';
import { HelpPageCombinedProps } from './types';


const HelpPage: React.FC<HelpPageCombinedProps> = (props) => {
  const {
    className,
    classes,
    questionsBlock,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.helpPage;

  return (
    <div className={cx(currentStyle, className)}>
      <QuestionsBlock
        className={cx(styles.questionsBlock, classes?.questionsBlock)}
        {...internalProps.questionsBlock}
        {...questionsBlock}/>
    </div>
  );
};

export default HelpPage;


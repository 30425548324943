import i18n from '../../../modules/locale/i18n';
import { ToggleOptionType } from '../../blocks/ToggleBlock/types';

export const HomeAwayFilterOptions: ToggleOptionType[] = [
  {
    title: i18n.t('sportsTeamPage.all'),
    value: '',
  },
  {
    title: i18n.t('sportsTeamPage.HOME'),
    value: 'HOME',
  },
  {
    title: i18n.t('sportsTeamPage.AWAY'),
    value: 'AWAY',
  },
];
import React from 'react';
import cx from 'classnames';
import { Dropdown } from 'react-bootstrap';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.css';

import Button from '../../atoms/Button';
import Icon from '../../atoms/Icon';
import Text from '../../atoms/Text';
import TextInput from '../../atoms/TextInput';
import DropdownSelectList from '../../organisms/DropdownSelectList';

import styles from './Filter.module.scss';
import usePresenter from './Filter.presenter';
import getProps from './Filter.props';
import { FilterCombinedProps } from './types';


const Filter: React.FC<FilterCombinedProps> = (props) => {
  const {
    type,
    className,
    classes,
    icon,
    filterButton,
    text,
    dropdownButton,
    closeButton,
    dropdownSelectList,
    showDropdown,
    showCalendar,
    showTextInput,
    handleToggle,
    setRange,
    textInput,
  } = usePresenter(props);

  const variantName = `${type}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`filter${variantName}`];

  // MANUAL OVERRIDE STARTS
  let componentView;
  let textView;
  let iconView;


  switch (variantName) {
    case 'IconFilter':
    case 'IconFilterClose': {
      iconView = (
        <Icon
          className={cx(styles.icon, classes?.icon)}
          {...internalProps.icon}
          {...icon}/>
      );
      break;
    }
    case 'IconTextFilter': {
      iconView = (
        <Icon
          className={cx(styles.icon, classes?.icon)}
          {...internalProps.icon}
          {...icon}/>
      );
      textView = (
        <Text
          className={cx(styles.text, classes?.text)}
          {...internalProps.text}
          {...text}/>
      );
      break;
    }
    case 'TextIconFilter': {
      textView = (
        <Text
          className={cx(styles.text, classes?.text)}
          {...internalProps.text}
          {...text}/>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }


  const filterButtonView = (
    <Button
      className={cx(styles.filterButton, classes?.filterButton)}
      {...internalProps.filterButton}
      {...filterButton}/>
  );

  const dropdownButtonView =
    dropdownButton && !showTextInput ? (
      <Icon className={styles.dropdownButton} {...dropdownButton.icon} />
    ) : undefined;

  const closeButtonView = closeButton ? (
    <Button className={styles.closeButton} {...closeButton} />
  ) : undefined;

  let contentView;
  if (showTextInput) {
    contentView = (
      <>
        {iconView}
        {textView}
        <TextInput
          className={styles.textInput}
          {...textInput} />
      </>
    );
  } else {
    contentView = (
      <>
        {iconView}
        {textView}
        {filterButtonView}
        {closeButtonView}
      </>
    );
  }
  // MANUAL OVERRIDE ENDS

  return (
    <div className={cx(currentStyle, className)}>
      <Dropdown
        className={styles.dropdown}
        onToggle={handleToggle}
        show={showDropdown || showTextInput}>
        {contentView}
        <Dropdown.Toggle
          className={styles.toggle} onClick={dropdownButton?.onClick}>
          {dropdownButtonView}
        </Dropdown.Toggle>
        {showDropdown || showTextInput ? <Dropdown.Menu className={styles.dropdownMenu} align={'right'}>
          {showCalendar ?
            <Flatpickr
              className={styles.flatpickr}
              options={{ mode: 'range', inline: true, minDate: 'today' }}
              onChange={(value: Date[]) => {
                if (setRange) {
                  setRange(value);
                }
              }} /> :
            <DropdownSelectList {...dropdownSelectList} />
          }
        </Dropdown.Menu> : null}
      </Dropdown>
    </div>
  );
};

export default Filter;


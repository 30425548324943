import { TextProps } from '../../atoms/Text';
import { TextItemProps } from '../../molecules/TextItem';
import { PaymentMethodProps } from '../../molecules/PaymentMethod';
import { ButtonProps } from '../../atoms/Button';


type PaymentInformationReadOnlyBlockInternalProps = {
  blockTitle?: TextProps;
  edit?: ButtonProps; // MANUAL OVERRIDE - replaced Text with Button
  address?: TextItemProps;
  phoneNumber?: TextItemProps;
  paymentMethod?: PaymentMethodProps;
  rewards?: TextItemProps;
};

type PaymentInformationReadOnlyBlockPropsValues = {
  '': PaymentInformationReadOnlyBlockInternalProps;
};

const propValues: PaymentInformationReadOnlyBlockPropsValues = {
  '': {
    blockTitle: {
      type: 'Subheading',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    edit: {
      // MANUAL OVERRIDE STARTS - replaced Text with Button
      type: 'Text',
      style: 'Contained',
      size: 'Small',
      // MANUAL OVERRIDE ENDS
    },
    address: {
      style: 'SubduedLabel',
    },
    phoneNumber: {
      style: 'SubduedLabel',
    },
    paymentMethod: {
    },
    rewards: {
      style: 'SubduedLabel',
    },
  },
};

const getProps = (type: string): PaymentInformationReadOnlyBlockInternalProps => {
  const values: PaymentInformationReadOnlyBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import React, { useEffect } from 'react';
import styles from './App.module.scss';
import {
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';
import MainLayout from './components/layout/MainLayout';
import ProtectedRoute from './modules/auth/ProtectedRoute';

const App: React.FC<{}> = () => {
  const props: any = {
    className: styles.layout,
  };

  return (
    <Router>
      <Switch>
        <ProtectedRoute path='/'>
          <MainLayout {...props} />
        </ProtectedRoute>
      </Switch>
    </Router>
  );
};

export default App;

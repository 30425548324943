import React from 'react';
import cx from 'classnames';
import { Modal } from 'react-bootstrap';

import InModalSearchReturnByCategory from '../../organisms/InModalSearchReturnByCategory';
import SearchModalHeader from '../../organisms/SearchModalHeader';
import SearchResultsBlock from '../SearchResultsBlock';

import styles from './SearchReturn.module.scss';
import customStyles from './Custom.module.scss';

import usePresenter from './SearchReturn.presenter';
import getProps from './SearchReturn.props';
import { SearchReturnCombinedProps } from './types';

const SearchReturn: React.FC<SearchReturnCombinedProps> = (props) => {
  const {
    className,
    classes,
    searchModalHeader,
    inModalSearchReturnByCategory,
    // MANUAL OVERRIDE START
    isModalOpen,
    searchSuggestions,
    searchResult,
    // MANUAL OVERRIDE START
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.searchReturn;

  // MANUAL OVERRIDE START
  let searchResultView;
  if (searchSuggestions && (searchSuggestions.events.length 
    || searchSuggestions.performers.length || searchSuggestions.venues.length)) {
    searchResultView = <SearchResultsBlock
      className={styles.searchResult}
      searchSuggestions={searchSuggestions}
      {...searchResult} />;
  }

  return (
    <Modal
      className={cx(styles.searchReturn, customStyles.searchReturn, className)}
      dialogClassName={customStyles.modalDialog}
      contentClassName={customStyles.modalContent}
      show={isModalOpen}
    >
      <Modal.Header className={customStyles.modalHeader}>
        <SearchModalHeader
          className={cx(styles.searchModalHeader, classes?.searchModalHeader)}
          {...searchModalHeader} />
      </Modal.Header>
      <Modal.Body className={customStyles.modalBody}>
        {/* TODO: implement showing search result */}
        {searchResultView}
      </Modal.Body>
      <Modal.Footer className={customStyles.modalFooter}>
      </Modal.Footer>
    </Modal>
  );
  // MANUAL OVERRIDE ENDS
};

export default SearchReturn;


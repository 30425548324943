import React from 'react';
import cx from 'classnames';
import { Modal } from 'react-bootstrap';

import AccountMenu from '../../molecules/AccountMenu';
import NavMenuItem from '../../molecules/NavMenuItem';

import customStyles from './Custom.module.scss';
import styles from './AccountMenuBlock.module.scss';
import usePresenter from './AccountMenuBlock.presenter';
import getProps from './AccountMenuBlock.props';
import { AccountMenuBlockCombinedProps } from './types';


const AccountMenuBlock: React.FC<AccountMenuBlockCombinedProps> = (props) => {
  const {
    className,
    classes,
    accountMenu,
    // MANUAL OVERRIDE STARTS
    logOut,
    myTickets,
    isModalOpen,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.accountMenuBlock;

  // MANUAL OVERRIDE STARTS
  return (
    <>
      <Modal
        className={cx(currentStyle, customStyles.customAccountMenuBlock, className)}
        dialogClassName={customStyles.modalDialog}
        contentClassName={customStyles.modalContent}
        show={isModalOpen}
      >
        <Modal.Header className={customStyles.modalHeader}>
          <div className={cx(styles.topContent, classes?.topContent)}>
            <AccountMenu
              className={cx(styles.accountMenu, classes?.accountMenu)}
              {...internalProps.accountMenu}
              {...accountMenu}
              renderType="Element"
            />
          </div>
        </Modal.Header>
        <Modal.Body className={customStyles.modalContent}>
          <NavMenuItem className={customStyles.myTickets} {...internalProps.myTickets} {...myTickets} />
          <NavMenuItem className={customStyles.logOut} {...internalProps.logOut} {...logOut} />
        </Modal.Body>
        <Modal.Footer className={customStyles.modalFooter}></Modal.Footer>
      </Modal>
    </>
  );
  // MANUAL OVERRIDE ENDS
};

export default AccountMenuBlock;


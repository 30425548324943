import React, { MutableRefObject, RefObject } from 'react';
import {
  Event,
} from '../modules/partnership/types';
import { TextColourEnum } from '../components/atoms/Text/types';

export type RefPropType<T> = RefObject<T> | MutableRefObject<T> | ((element: T) => void);

export type TicketDeliveryInfo = {
  ticketListing: string;
  ticketDetails: string;
  deliveryMethod: string;
  confirmation_page: string;
};

export type UnitDisplaySettings = {
  rewardSign: '$' | '';
  useDecimals: boolean;
};

export enum AriaRoles {
  ALERT = 'alert',
}

export enum AriaLiveRegions {
  ASSERTIVE = 'assertive',
  OFF = 'off',
  POLITE = 'polite',
}

export interface Props {
  children: React.ReactNode;
}

export type WindowSize = {
  width?: number;
  height?: number;
};

export type ScrollOffset = {
  scrollX?: number;
  scrollY?: number;
};

export type UseGetEventStatus = {
  getEventStatus: (event: Event) => EventStatus;
};

export type EventStatus = {
  statusLabel?: string;
  statusColor?: TextColourEnum;
};

import React from 'react';
import cx from 'classnames';

import Image from '../../atoms/Image';
import TicketInformation from '../../molecules/TicketInformation';
import AdditionalTicketInformationList from '../../molecules/AdditionalTicketInformationList';
import TicketQuantitySelection from '../../molecules/TicketQuantitySelection';

import styles from './SportsPrecheckoutBlock.module.scss';
import usePresenter from './SportsPrecheckoutBlock.presenter';
import getProps from './SportsPrecheckoutBlock.props';
import { SportsPrecheckoutBlockCombinedProps } from './types';


const SportsPrecheckoutBlock: React.FC<SportsPrecheckoutBlockCombinedProps> = (props) => {
  const {
    className,
    classes,
    image,
    ticketInformation,
    additionalTicketInformationList,
    ticketQuantitySelection,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.sportsPrecheckoutBlock;

  return (
    <div className={cx(currentStyle, className)}>
      <Image
        className={cx(styles.image, classes?.image)}
        {...internalProps.image}
        {...image}/>
      <TicketInformation
        className={cx(styles.ticketInformation, classes?.ticketInformation)}
        {...internalProps.ticketInformation}
        {...ticketInformation}/>
      <AdditionalTicketInformationList
        className={cx(styles.additionalTicketInformationList, classes?.additionalTicketInformationList)}
        {...internalProps.additionalTicketInformationList}
        {...additionalTicketInformationList}/>
      <div className={cx(styles.bottomContent, classes?.bottomContent)}>
        <TicketQuantitySelection
          className={cx(styles.ticketQuantitySelection, classes?.ticketQuantitySelection)}
          {...internalProps.ticketQuantitySelection}
          {...ticketQuantitySelection}/>
      </div>
    </div>
  );
};

export default SportsPrecheckoutBlock;


import i18n from '../../../modules/locale/i18n';
import { formatDateToApiDate, formatDateToMonthDay } from '../../../lib/util';
import { today, tomorrow, getCertainDay, DayOfWeek } from '../../../modules/date/utils';
import { DropdownOption } from './types';
import { Region } from '../../../modules/partnership/types';
import { isValidDate } from '../../../lib/util';

export const dateFilterOptions: DropdownOption[] = [
  {
    type: 'SelectValue',
    title: i18n.t('dateFilters.any'),
    value: {},
  },
  {
    type: 'SelectValue',
    title: i18n.t('dateFilters.today'),
    value: {
      startDate: today(),
      endDate: today(),
    },
  },
  {
    type: 'SelectValue',
    title: i18n.t('dateFilters.tomorrow'),
    value: {
      startDate: tomorrow(),
      endDate: tomorrow(),
    },
  },
  {
    type: 'SelectValue',
    title: i18n.t('dateFilters.weekend'),
    value: {
      startDate: getCertainDay(DayOfWeek.Friday),
      endDate: getCertainDay(DayOfWeek.Sunday),
    },
  },
  {
    type: 'PickDate',
    title: i18n.t('dateFilters.chooseDate'),
  },
];

export const exclusiveDateFilterOptions: DropdownOption[] = [
  {
    type: 'SelectValue',
    title: i18n.t('dateFilters.any'),
    value: { startDate: today() },
  },
  {
    type: 'SelectValue',
    title: i18n.t('dateFilters.today'),
    value: {
      startDate: today(),
      endDate: today(),
    },
  },
  {
    type: 'SelectValue',
    title: i18n.t('dateFilters.tomorrow'),
    value: {
      startDate: tomorrow(),
      endDate: tomorrow(),
    },
  },
  {
    type: 'SelectValue',
    title: i18n.t('dateFilters.weekend'),
    value: {
      startDate: getCertainDay(DayOfWeek.Friday),
      endDate: getCertainDay(DayOfWeek.Sunday),
    },
  },
  {
    type: 'PickDate',
    title: i18n.t('dateFilters.chooseDate'),
  },
];

export const formatStartEndDateOption = (startDate?: Date, endDate?: Date): string => {
  if (startDate && isValidDate(startDate) && endDate && isValidDate(endDate)) {
    return `${formatDateToMonthDay(startDate)} - ${formatDateToMonthDay(endDate)}`;
  }
  return ' - ';
};

export const getDefaultOption = (options: DropdownOption[]): DropdownOption | undefined => {
  return options.find((option) => option.type !== 'SearchInput') || options[0];
};

export const getLocation = (locations?: Region[], regionId?: string | null) : DropdownOption | undefined => {
  const location = locations?.find((region) => String(region.id) === regionId);
  return location ?
    {
      title: `${location?.city}, ${location?.state_code}`,
      type: 'SelectValue',
      value: {
        value: String(location?.id),
      },
    } : undefined;
};

export const locationFilterDefaultOptions = (currentRegion?: Region, includeAllOption?: boolean): DropdownOption[] => {
  const options: DropdownOption[] = [{
    type: 'SearchInput',
    title: i18n.t('regionFilters.searchLocation'),
    value: {},
  }];

  if (currentRegion) {
    options.push({
      title: `${currentRegion?.city}, ${currentRegion?.state_code}`,
      dropdownTitle: i18n.t('regionFilters.currentLocation') || undefined,
      type: 'SelectValue',
      value: {
        value: String(currentRegion?.id),
      },
    });
  }
  if (includeAllOption) {
    options.unshift({
      title: i18n.t('regionFilters.allLocations'),
      type: 'SelectValue',
      value: {},
    });
  }
  return options;
};


export const dynamicRegionList = (regions: Region[], filterText: string): DropdownOption[] => {
  const normalizedFilter = filterText.toLowerCase();
  return regions
    .filter((region) => {
      return region.city.toLowerCase().includes(normalizedFilter) ||
        region.state.toLowerCase().includes(normalizedFilter);
    })
    .map((region): DropdownOption => {
      return {
        type: 'SearchResult',
        title: `${region.city}, ${region.state_code}`,
        value: {
          value: String(region.id),
        },
      };
    });
};

export const defaultDateToTodayIfEmpty = (date_start?: string): string => {
  if (date_start === undefined || !date_start) {
    return formatDateToApiDate(today());
  }
  return date_start;
};

import { EventCardsBlockProps } from '../../blocks/EventCardsBlock';
import { FiltersBlockProps } from '../../blocks/FiltersBlock';
import { ImageBlockProps } from '../../blocks/ImageBlock';


type PerformerLandingPageInternalProps = {
  imageBlock?: ImageBlockProps;
  filtersBlock?: FiltersBlockProps;
  eventCardsBlock?: EventCardsBlockProps;
};

type PerformerLandingPagePropsValues = {
  '': PerformerLandingPageInternalProps;
};

const propValues: PerformerLandingPagePropsValues = {
  '': {
    imageBlock: {
    },
    filtersBlock: {
    },
    eventCardsBlock: {
      type: 'EventsNoImageBlock',
      state: 'Default',
    },
  },
};

const getProps = (type: string): PerformerLandingPageInternalProps => {
  const values: PerformerLandingPageInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

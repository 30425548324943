

type SeatMapBlockInternalProps = {
};

type SeatMapBlockPropsValues = {
  '': SeatMapBlockInternalProps;
};

const propValues: SeatMapBlockPropsValues = {
  '': {
  },
};

const getProps = (type: string): SeatMapBlockInternalProps => {
  const values: SeatMapBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import React from 'react';
import cx from 'classnames';

import ErrorBlock from '../../blocks/ErrorBlock';
import SearchBlock from '../../blocks/SearchBlock';

import styles from './ErrorPage.module.scss';
import usePresenter from './ErrorPage.presenter';
import getProps from './ErrorPage.props';
import { ErrorPageCombinedProps } from './types';


const ErrorPage: React.FC<ErrorPageCombinedProps> = (props) => {
  const {
    className,
    classes,
    searchBlock,
    errorBlock,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.errorPage;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.container, classes?.container)}>
        <SearchBlock
          className={cx(styles.searchBlock, classes?.searchBlock)}
          {...internalProps.searchBlock}
          {...searchBlock}/>
        <ErrorBlock
          className={cx(styles.errorBlock, classes?.errorBlock)}
          {...internalProps.errorBlock}
          {...errorBlock}/>
      </div>
    </div>
  );
};

export default ErrorPage;


import { DividerProps } from '../../atoms/Divider';
import { LogoCombinedProps } from '../../atoms/Logo/types';
import { FooterMenuListProps } from '../../molecules/FooterMenuList';


type FooterInternalProps = {
  divider?: DividerProps; // MANUAL OVERRIDE
  logo?: LogoCombinedProps; // MANUAL OVERRIDE
  footerMenuList?: FooterMenuListProps;
};

type FooterPropsValues = {
  'Dark': FooterInternalProps;
  'Default': FooterInternalProps;
};

const propValues: FooterPropsValues = {
  'Dark': {
    // MANUAL OVERRIDE STARTS
    divider: {
      type: 'Horizontal',
      style: 'Thin',
      colour: 'PrimaryBlue60',
    },
    // MANUAL OVERRIDE ENDS
    logo: {
      asset: 'LogoC1Dark',
    },
    footerMenuList: {
    },
  },
  'Default': {
    // MANUAL OVERRIDE STARTS
    divider: {
      type: 'Horizontal',
      style: 'Thin',
      colour: 'DigitalGrey10',
    },
    // MANUAL OVERRIDE ENDS
    logo: {
      asset: 'LogoC1Default',
    },
    footerMenuList: {
    },
  },
};

const getProps = (type: string): FooterInternalProps => {
  const values: FooterInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

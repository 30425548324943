import React, { useRef } from 'react';
import cx from 'classnames';
import { Dropdown } from 'react-bootstrap';

import Image from '../../atoms/Image';
import Text from '../../atoms/Text';
import Button from '../../atoms/Button';
import Divider from '../../atoms/Divider';
import AccountMenuBlock from '../../blocks/AccountMenuBlock';
import DropdownSelectList from '../../organisms/DropdownSelectList';

import customStyles from './Custom.module.scss';
import styles from './AccountMenu.module.scss';
import usePresenter from './AccountMenu.presenter';
import getProps from './AccountMenu.props';
import { AccountMenuCombinedProps } from './types';


const AccountMenu: React.FC<AccountMenuCombinedProps> = (props) => {
  const {
    state,
    className,
    classes,
    cardImage,
    cardNumber,
    greetingFirstName,
    loyaltyPoints,
    divider,
    expandButton,
    collapseButton,
    closeButton,
    // MANUAL OVERRIDE STARTS
    renderType,
    dropdownSelectList,
    accountMenuBlock,
    handleToggle,
    isLoading,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const toggleRef = useRef<HTMLDivElement | null>(null);

  const variantName = `${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`accountMenu${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Collapsed': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.content, classes?.content)}>
            <div className={cx(styles.capitalOneCard, classes?.capitalOneCard)}>
              <Image
                className={cx(styles.cardImage, classes?.cardImage)}
                {...internalProps.cardImage}
                {...cardImage}/>
              <Text
                className={cx(styles.cardNumber, classes?.cardNumber)}
                {...internalProps.cardNumber}
                {...cardNumber}/>
            </div>
            <Text
              className={cx(styles.greetingFirstName, classes?.greetingFirstName)}
              {...internalProps.greetingFirstName}
              {...greetingFirstName}/>
            <Text
              className={cx(styles.loyaltyPoints, classes?.loyaltyPoints)}
              {...internalProps.loyaltyPoints}
              {...loyaltyPoints}/>
            <div className={cx(styles.cardInfo, classes?.cardInfo)}>
              <div className={cx(styles.capitalOneCard, classes?.capitalOneCard)}>
                <Image
                  className={cx(styles.cardImage, classes?.cardImage)}
                  {...internalProps.cardImage}
                  {...cardImage}/>
                <Text
                  className={cx(styles.cardNumber, classes?.cardNumber)}
                  {...internalProps.cardNumber}
                  {...cardNumber}/>
              </div>
              <Divider
                className={cx(styles.divider, classes?.divider)}
                {...internalProps.divider}
                {...divider}/>
              <Text
                className={cx(styles.loyaltyPoints, classes?.loyaltyPoints)}
                {...internalProps.loyaltyPoints}
                {...loyaltyPoints}/>
            </div>
          </div>
          <Button
            className={cx(styles.expandButton, classes?.expandButton)}
            {...internalProps.expandButton}
            {...expandButton}/>
        </div>
      );
      break;
    }
    case 'Expanded': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.content, classes?.content)}>
            <Text
              className={cx(styles.greetingFirstName, classes?.greetingFirstName)}
              {...internalProps.greetingFirstName}
              {...greetingFirstName}/>
            <div className={cx(styles.cardInfo, classes?.cardInfo)}>
              <div className={cx(styles.capitalOneCard, classes?.capitalOneCard)}>
                <Image
                  className={cx(styles.cardImage, classes?.cardImage)}
                  {...internalProps.cardImage}
                  {...cardImage}/>
                <Text
                  className={cx(styles.cardNumber, classes?.cardNumber)}
                  {...internalProps.cardNumber}
                  {...cardNumber}/>
              </div>
              <Divider
                className={cx(styles.divider, classes?.divider)}
                {...internalProps.divider}
                {...divider}/>
              <Text
                className={cx(styles.loyaltyPoints, classes?.loyaltyPoints)}
                {...internalProps.loyaltyPoints}
                {...loyaltyPoints}/>
            </div>
          </div>
          <Button
            className={cx(styles.collapseButton, classes?.collapseButton)}
            {...internalProps.collapseButton}
            {...collapseButton}/>
          <Button
            className={cx(styles.closeButton, classes?.closeButton)}
            {...internalProps.closeButton}
            {...closeButton}/>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  // MANUAL OVERRIDE STARTS
  if (isLoading) {
    return <></>;
  }

  if (renderType) {
    return componentView;
  }

  return (
    <>
      <Dropdown
        className={customStyles.dropdown}
        onToggle={handleToggle}
        show={accountMenuBlock?.isModalOpen}
      >
        <Dropdown.Toggle as="div" ref={toggleRef} aria-expanded={undefined}>
          {componentView}
        </Dropdown.Toggle>
        {accountMenuBlock?.isModalOpen ? (
          <Dropdown.Menu
            className={customStyles.dropdownMenu}
            align="right"
            style={{
              minWidth: toggleRef?.current?.clientWidth,
            }}
          >
            <DropdownSelectList {...dropdownSelectList} />
          </Dropdown.Menu>
        ) : null}
      </Dropdown>
      <AccountMenuBlock {...accountMenuBlock} />
    </>
  );
  // MANUAL OVERRIDE ENDS
};

export default AccountMenu;


import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import Button from '../../atoms/Button';
import Divider from '../../atoms/Divider';
import Dropdown from '../Dropdown';

import styles from './TicketQuantitySelection.module.scss';
import usePresenter from './TicketQuantitySelection.presenter';
import getProps from './TicketQuantitySelection.props';
import { TicketQuantitySelectionCombinedProps } from './types';


const TicketQuantitySelection: React.FC<TicketQuantitySelectionCombinedProps> = (props) => {
  const {
    className,
    classes,
    dropdown,
    button,
    divider,
  } = usePresenter(props);
  const { t } = useTranslation();
  const internalProps = getProps('');
  const currentStyle = styles.ticketQuantitySelection;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.content, classes?.content)}>
        <Dropdown
          className={cx(styles.dropdown, classes?.dropdown)}
          ariaLabel={t('precheckoutBlock.quantityDropdownSelectReaderText')} // MANUAL OVERRIDE
          {...internalProps.dropdown}
          {...dropdown}/>
        <Button
          className={cx(styles.button, classes?.button)}
          {...internalProps.button}
          {...button}/>
      </div>
      <Divider
        className={cx(styles.divider, classes?.divider)}
        {...internalProps.divider}
        {...divider}/>
    </div>
  );
};

export default TicketQuantitySelection;


import React from 'react';
import cx from 'classnames';

import Icon from '../Icon';

import styles from './SliderButton.module.scss';
import usePresenter from './SliderButton.presenter';
import getProps from './SliderButton.props';
import { SliderButtonCombinedProps } from './types';


const SliderButton: React.FC<SliderButtonCombinedProps> = (props) => {
  const {
    style,
    type,
    className,
    classes,
    disabled,
    buttonType,
    buttonForm,
    href,
    target,
    externalLink,
    onClick,
    icon,
    ariaLabel, // MANUAL OVERRIDE
  } = usePresenter(props);

  const variantName = `${style}${type}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`sliderButton${variantName}`];


  let componentView;

  switch (variantName) {
    case 'DarkNext':
    case 'DarkPrev':
    case 'LightNext':
    case 'LightPrev': {
      componentView = (
        <button
          aria-label={ariaLabel} // MANUAL OVERRIDE
          disabled={disabled}
          type={buttonType}
          form={buttonForm}
          onClick={onClick}
          className={cx(currentStyle, className)}>
          <Icon
            className={cx(styles.icon, classes?.icon)}
            {...internalProps.icon}
            {...icon}/>
        </button>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default SliderButton;


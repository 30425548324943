import React, { useMemo } from 'react';
import { PathSVG } from '../../../types';
import { getTransform } from '../../../utils';
import styles from './styles.module.scss';

interface PathsContainerProps {
  paths?: Array<PathSVG>
}

const PathsContainer: React.FC<PathsContainerProps> = ({ paths = [] }) => {
  const pathsView = useMemo(() => {
    return paths.map((item, index) => {
      const renderProps = {
        d: item.path,
        path: item.path,
        transform: getTransform(item.transform),
        stroke: item.stroke,
        fillOpacity: item['fill-opacity'],
        id: item.id || '',
        'data-testid': item.id || '',
        'data-svg-section-id': item.id || '',
        style: {
          opacity: item.id ? '1' : item.opacity,
          pointerEvents: item.id ? undefined : 'none',
        } as React.CSSProperties,
        fill: item.fill,
        'data-for': item.id,
      };
      return (
            <path key={`${renderProps.id || ''}-${index}`} className={styles.path} data-tip {...renderProps} />
      );
    });
  }, [paths]);
  return (
        <>
            {pathsView}
        </>
  );
};

export default PathsContainer;

import { ButtonPairProps } from '../ButtonPair';


type ButtonPairListInternalProps = {
  buttonPair?: ButtonPairProps;
};

type ButtonPairListPropsValues = {
  '': ButtonPairListInternalProps;
};

const propValues: ButtonPairListPropsValues = {
  '': {
    buttonPair: {
    },
  },
};

const getProps = (type: string): ButtonPairListInternalProps => {
  const values: ButtonPairListInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import React from 'react';
import { SnapListProvider } from '../../../modules/snap/SnapListContext/SnapListContext';
import C1XEventDetailsSliderBlock from './C1XEventDetailsSliderBlock';
import { C1XEventDetailsSliderBlockProps, C1XEventDetailsSliderBlockValueProps, C1XEventDetailsSliderBlockCombinedProps } from './types';

export type { C1XEventDetailsSliderBlockProps, C1XEventDetailsSliderBlockValueProps };

const C1XEventDetailsSliderBlockView: React.FC<C1XEventDetailsSliderBlockCombinedProps> = (props) => {
  return (
    <SnapListProvider>
      <C1XEventDetailsSliderBlock {...props} />
    </SnapListProvider>
  );
};
export default C1XEventDetailsSliderBlockView;

import { DividerProps } from '../../atoms/Divider';
import { IconProps } from '../../atoms/Icon';
import { TextProps } from '../../atoms/Text';


type SearchCategoriesInternalProps = {
  icon?: IconProps;
  title?: TextProps;
  divider?: DividerProps;
};

type SearchCategoriesPropsValues = {
  'DividerBottom': SearchCategoriesInternalProps;
  'DividerTop': SearchCategoriesInternalProps;
  'DividerTopWhite': SearchCategoriesInternalProps;
};

const propValues: SearchCategoriesPropsValues = {
  'DividerBottom': {
    icon: {
      asset: 'Clock',
      style: 'BaseDark',
    },
    title: {
      type: 'Body',
      size: 'Small',
      style: 'SemiBold',
      colour: 'SubduedDark',
      align: 'Left',
    },
    divider: {
      type: 'Horizontal',
      style: 'Thick',
      colour: 'White',
    },
  },
  'DividerTop': {
    divider: {
      type: 'Horizontal',
      style: 'Thin',
      colour: 'DigitalGrey15',
    },
    icon: {
      asset: 'Clock',
      style: 'BaseDark',
    },
    title: {
      type: 'Body',
      size: 'Small',
      style: 'SemiBold',
      colour: 'SubduedDark',
      align: 'Left',
    },
  },
  'DividerTopWhite': {
    divider: {
      type: 'Horizontal',
      style: 'Thin',
      colour: 'DigitalGrey15',
    },
    title: {
      type: 'Subheading',
      size: 'Medium',
      style: 'Regular',
      colour: 'ActionBase',
      align: 'Left',
    },
  },
};

const getProps = (type: string): SearchCategoriesInternalProps => {
  const values: SearchCategoriesInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;



type TeamHomeAwayImageInternalProps = {
};

type TeamHomeAwayImagePropsValues = {
  'Long': TeamHomeAwayImageInternalProps;
  'Square': TeamHomeAwayImageInternalProps;
};

const propValues: TeamHomeAwayImagePropsValues = {
  'Long': {
  },
  'Square': {
  },
};

const getProps = (type: string): TeamHomeAwayImageInternalProps => {
  const values: TeamHomeAwayImageInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import React from 'react';
import cx from 'classnames';

import ReceiptBlock from '../../blocks/ReceiptBlock';

import styles from './ReceiptPage.module.scss';
import usePresenter from './ReceiptPage.presenter';
import getProps from './ReceiptPage.props';
import { ReceiptPageCombinedProps } from './types';
import PdfDocument from './checkoutOrderPrint/PdfDocumentGenerator';
import PDFDoc from './checkoutOrderPrint/PdfDocument';


const ReceiptPage: React.FC<ReceiptPageCombinedProps> = (props) => {
  const {
    className,
    classes,
    receiptBlock,
    setUrl,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.receiptPage;

  return (
    <div className={cx(currentStyle, className)}>
      {/* MANUAL OVERRIDE STARTS */}
      <PdfDocument
        setUrl={setUrl}
        title=""
        document={<PDFDoc receiptBlock={receiptBlock} />}
      />
      <div className={styles.container}>
        {/* MANUAL OVERRIDE ENDS */}
        <ReceiptBlock className={styles.receiptBlock}
          {...internalProps.receiptBlock}
          {...receiptBlock} />
      </div>
    </div>
  );
};

export default ReceiptPage;


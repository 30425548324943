import { useCallback, useContext, useMemo } from 'react';
import { EventKeys } from './types';
import TagManager from 'react-gtm-module';
import { AuthContext } from '../auth';

type UseAnalyticsManagerResponse = {
  trackEvent: (event: EventKeys, extraData?: Record<string, unknown>) => void;
};

const NEW_DESIGN = 'homepage';

export const useAnalyticsManager = (): UseAnalyticsManagerResponse => {
  const { account, hashedEmail: accountEmailHash } = useContext(AuthContext);
  const accountId = useMemo(() => account?.id, [account?.id]);

  const trackGTMEvent = useCallback((event: EventKeys, extraData?: Record<string, unknown>) => {
    const eventData = {
      event,
      variation: NEW_DESIGN,
      userId: accountId,
      user: {
        user_id: accountId,
        crm_id: accountEmailHash,
      },
      ...extraData,
    };
    TagManager.dataLayer({ dataLayer: eventData });
  }, [accountId, accountEmailHash]);

  const trackEvent = useCallback((event: EventKeys, extraData?: Record<string, unknown>) => {
    trackGTMEvent(event, extraData);
  }, [accountId, accountEmailHash]);

  return {
    trackEvent,
  };
};
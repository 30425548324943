import { TextProps } from '../../atoms/Text';
import { SliderProps } from '../Slider';


type SliderFilterInternalProps = {
  leftValue?: TextProps;
  slider?: SliderProps;
  rightValue?: TextProps;
};

type SliderFilterPropsValues = {
  '': SliderFilterInternalProps;
};

const propValues: SliderFilterPropsValues = {
  '': {
    leftValue: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    slider: {
      type: 'Dual',
    },
    rightValue: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Right',
    },
  },
};

const getProps = (type: string): SliderFilterInternalProps => {
  const values: SliderFilterInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import React from 'react';
import cx from 'classnames';

import Text from '../../atoms/Text';
import Button from '../../atoms/Button';

import styles from './LegalBlock.module.scss';
import usePresenter from './LegalBlock.presenter';
import getProps from './LegalBlock.props';
import { LegalBlockCombinedProps } from './types';
import CheckBoxItem from '../../atoms/CheckBoxItem';


const LegalBlock: React.FC<LegalBlockCombinedProps> = (props) => {
  const {
    className,
    classes,
    blockTitle,
    description,
    button,
    // MANUAL OVERRIDE STARTS
    showTermsAndConditionsCheckbox,
    hasAcceptedTerms,
    checkBoxItem,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.legalBlock;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.topContent, classes?.topContent)}>
        <Text
          className={cx(styles.blockTitle, classes?.blockTitle)}
          {...internalProps.blockTitle}
          {...blockTitle}/>
        <Text
          className={cx(styles.description, classes?.description)}
          {...internalProps.description}
          {...description}/>
      </div>
      {/* MANUAL OVERRIDE */}
      { showTermsAndConditionsCheckbox ? 
        <>
          <CheckBoxItem {...checkBoxItem} />
          <Button
            className={cx(styles.button, classes?.button)}
            {...internalProps.button}
            {...button} />
        </> : 
        <Button
          className={cx(styles.button, classes?.button)}
          {...internalProps.button}
          {...button} />
      }
    </div>
  );
};

export default LegalBlock;


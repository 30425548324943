import { SearchCategoriesProps } from '../../molecules/SearchCategories';
import { SearchReturnListProps } from '../../organisms/SearchReturnList';


type SearchResultsBlockInternalProps = {
  upcomingCategory?: SearchCategoriesProps;
  upcomingSearchReturnList?: SearchReturnListProps;
  performersCategory?: SearchCategoriesProps;
  performersSearchReturnList?: SearchReturnListProps;
  venueCategory?: SearchCategoriesProps;
  venueSearchReturnList?: SearchReturnListProps;
};

type SearchResultsBlockPropsValues = {
  '': SearchResultsBlockInternalProps;
};

const propValues: SearchResultsBlockPropsValues = {
  '': {
    upcomingCategory: {
      type: 'DividerBottom',
    },
    upcomingSearchReturnList: {
    },
    performersCategory: {
      type: 'DividerBottom',
    },
    performersSearchReturnList: {
    },
    venueCategory: {
      type: 'DividerBottom',
    },
    venueSearchReturnList: {
    },
  },
};

const getProps = (type: string): SearchResultsBlockInternalProps => {
  const values: SearchResultsBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import { TicketInformationProps } from '../../molecules/TicketInformation';


type TicketListInternalProps = {
  ticketInformation?: TicketInformationProps;
};

type TicketListPropsValues = {
  'Default': TicketListInternalProps;
  'WithSpace': TicketListInternalProps;
};

const propValues: TicketListPropsValues = {
  'Default': {
    ticketInformation: {
      type: 'Default',
      style: 'Default',
    },
  },
  'WithSpace': {
    ticketInformation: {
      type: 'Default',
      style: 'Default',
    },
  },
};

const getProps = (type: string): TicketListInternalProps => {
  const values: TicketListInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

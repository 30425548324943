import React from 'react';
import cx from 'classnames';

import Text from '../../atoms/Text';
import Divider from '../../atoms/Divider';
import Icon from '../../atoms/Icon';

import styles from './FAQItem.module.scss';
import usePresenter from './FAQItem.presenter';
import getProps from './FAQItem.props';
import { FAQItemCombinedProps } from './types';


const FAQItem: React.FC<FAQItemCombinedProps> = (props) => {
  const {
    state,
    className,
    classes,
    question,
    icon,
    divider,
    answer,
    // MANUAL OVERRIDE STARTS
    onClick,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const variantName = `${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`fAQItem${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Collapsed': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          {/* MANUAL OVERRIDE - added onClick */}
          <div className={cx(styles.content, classes?.content)} onClick={onClick}>
            <Text
              className={cx(styles.question, classes?.question)}
              {...internalProps.question}
              {...question}/>
            <Icon
              className={cx(styles.icon, classes?.icon)}
              {...internalProps.icon}
              {...icon}/>
          </div>
          <Divider
            className={cx(styles.divider, classes?.divider)}
            {...internalProps.divider}
            {...divider}/>
        </div>
      );
      break;
    }
    case 'Expanded': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          {/* MANUAL OVERRIDE - added onClick */}
          <div className={cx(styles.topContent, classes?.topContent)} onClick={onClick}>
            <Text
              className={cx(styles.question, classes?.question)}
              {...internalProps.question}
              {...question}/>
            <Icon
              className={cx(styles.icon, classes?.icon)}
              {...internalProps.icon}
              {...icon}/>
          </div>
          <Text
            className={cx(styles.answer, classes?.answer)}
            {...internalProps.answer}
            {...answer}/>
          <Divider
            className={cx(styles.divider, classes?.divider)}
            {...internalProps.divider}
            {...divider}/>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default FAQItem;


/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useContext } from 'react';
import { CP1_EXCLUSIVE_TAG } from '../../lib/constants';
import { handleTagFilterForListings } from '../../lib/util';
import { AuthContext } from '../auth';
import { Listing, ListingsQueryParams, ListingsResponse, Event } from '../partnership';
import { getListings } from '../partnership/api';
import { ListingsSelectorsType, ListingsStateType, TicketSections } from './types';
import { useAnalyticsManager } from '../analytics/useAnalyticsManager';
import { trackErrorEvent } from '../analytics/util';
import { ApiError } from '../error/types';
export type ListingsContextValue = {
  state: ListingsStateType;
  selectors: ListingsSelectorsType;
  handleChangeParam?: (params: ListingsQueryParams) => void;
  handleSectionFilter?: (listing: Listing[]) => void;
  handleReset?: VoidFunction;
  isLoading?: boolean;
  filterValue?: ListingsQueryParams;
};
export type ListingsProviderValue = {
  eventId: string;
  eventData?: Event;
  children?: React.ReactNode;
};
const initialContext: ListingsContextValue = {
  state: {},
  selectors: {
    composedListings: [],
    seatViewObject: undefined,
    mapJsonFileName: '',
  },
  isLoading: true,
  filterValue: undefined,
};
export const ListingsContext = createContext<ListingsContextValue>(initialContext);
export const ListingsProvider: React.FC<ListingsProviderValue> = ({ children, eventId, eventData }) => {
  const { trackEvent } = useAnalyticsManager();
  const { account } = useContext(AuthContext);
  const [data, setData] = useState<ListingsResponse | undefined>();
  const [isLoading, setIsLoading] = useState(!data);
  const [filterValue, setFilterValue] = useState<ListingsQueryParams>({
    event_id: eventId,
  });
  const fetchListings = useCallback(async (params: ListingsQueryParams) => {
    try {
      if (params.event_id !== filterValue.event_id || params.listing_ids !== filterValue.listing_ids) {
        setIsLoading(true);
      }
      const listings = await getListings(params);
      setData(listings);
      setIsLoading(false);
    } catch (error) {
      if (error && ApiError.isApiError(error)) {
        trackErrorEvent(
          trackEvent,
          error.code,
          error.message,
        );
      }
      setData(undefined);
      setIsLoading(false);
    }
  }, []);

  const handleChangeParam = (params: ListingsQueryParams) => {
    setFilterValue({ ...filterValue, ...params });
  };
  const handleReset = () => {
    setFilterValue({
      event_id: eventId,
    });
  };

  useEffect(() => {
    setFilterValue({
      ...filterValue,
      event_id: eventId,
    });
  }, [eventId]);

  useEffect(() => {
    if (account && eventData) {
      let updatedQueryParam: ListingsQueryParams = { ...filterValue };
      if (eventData && eventData.tags?.includes(CP1_EXCLUSIVE_TAG) && account) {
        updatedQueryParam = handleTagFilterForListings(filterValue, account);
      }
      void fetchListings(updatedQueryParam);
    }

  }, [fetchListings, filterValue, account, eventData]);
  const handleSectionFilter = (listing: Listing[]) => {
    if (data) {
      setData({
        ...data,
        listings: listing,
      });
    }
  };
  const contextValue: ListingsContextValue = useMemo(() => {
    const state: ListingsStateType = { data };
    let selectors: ListingsSelectorsType = initialContext.selectors;
    if (data) {
      const {
        map_cdn_url: mapCdnUrl,
        svg_file_name: svgFileName,
        json_file_name: jsonFileName,
        static_map_url: staticMapUrl,
      } = data.meta.legacy_map_info;
      const ticketSections: TicketSections = {};
      data.listings.forEach((ticket: Listing) => {
        const { section: ticketSection } = ticket;
        const section = data.sections.find(({ name }) => name === ticketSection);
        if (section) {
          ticketSections[`${section.id}`] = { ticket, section };
        }
      });
      selectors = {
        composedListings: data.listings,
        seatViewObject: {
          mapTitle: 'TO DO',
          svgFileName: `${mapCdnUrl}/${svgFileName}`,
          jsonFileName: jsonFileName ? `${mapCdnUrl}/${jsonFileName}` : '',
          staticMapUrl: staticMapUrl,
          ticketSections: ticketSections,
        },
        mapJsonFileName: jsonFileName ? `${mapCdnUrl}/${jsonFileName}` : '',
        minPrice: data.meta.min_price,
        maxPrice: data.meta.max_price,
      };
    }
    return { state, selectors, handleChangeParam, handleSectionFilter, handleReset, isLoading, filterValue };
  }, [data, filterValue, isLoading]);
  return (
    <ListingsContext.Provider value={contextValue}>
      {children}
    </ListingsContext.Provider>
  );
};

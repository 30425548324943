import { SearchMatchesBlockProps } from '../../blocks/SearchMatchesBlock';


type SearchMatchPageInternalProps = {
  searchMatchesBlock?: SearchMatchesBlockProps;
};

type SearchMatchPagePropsValues = {
  '': SearchMatchPageInternalProps;
};

const propValues: SearchMatchPagePropsValues = {
  '': {
    searchMatchesBlock: {
      state: 'Match',
    },
  },
};

const getProps = (type: string): SearchMatchPageInternalProps => {
  const values: SearchMatchPageInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import { InModalSearchReturnByCategoryProps } from '../../organisms/InModalSearchReturnByCategory';
import { SearchModalHeaderProps } from '../../organisms/SearchModalHeader';


type SearchReturnInternalProps = {
  searchModalHeader?: SearchModalHeaderProps;
  inModalSearchReturnByCategory?: InModalSearchReturnByCategoryProps;
};

type SearchReturnPropsValues = {
  '': SearchReturnInternalProps;
};

const propValues: SearchReturnPropsValues = {
  '': {
    searchModalHeader: {
      state: 'Default',
    },
    inModalSearchReturnByCategory: {
    },
  },
};

const getProps = (type: string): SearchReturnInternalProps => {
  const values: SearchReturnInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

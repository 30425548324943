import { Listing } from '../partnership';

export enum ActionType {
  ON_TYPE_FILTER_CHANGE,
  ON_QUANTITY_FILTER_CHANGE,
  ON_SORT_FILTER_CHANGE,
  ON_PRICE_FILTER_CHANGE,
  RESET_FILTERS,
  ON_SELECT_SECTION,
  ON_HIGHLIGHT_SECTION,
  ON_SHOW_ALL_IN_PRICE_CHANGE,
  RESET_SECTION_SELECTION,
}

export enum SortFilterNames {
  PRICE,
  ROW,
  SECTION,
}

export interface TypeFilterName {
  name: 'greatDeal' | 'superSeller' | 'instantDelivery' | 'stock_category' | 'electronic'
}

export interface OnTypeFilterChangeAction {
  type: ActionType.ON_TYPE_FILTER_CHANGE
  payload: {
    filterName: TypeFilterName
    filterValue: boolean
  }
}

export interface OnQuantityFilterChangeAction {
  type: ActionType.ON_QUANTITY_FILTER_CHANGE
  payload: {
    quantity: number
  }
}

export interface OnSortFilterChangeAction {
  type: ActionType.ON_SORT_FILTER_CHANGE
  payload: {
    name: SortFilterNames
  }
}

export interface OnPriceFilterChangeAction {
  type: ActionType.ON_PRICE_FILTER_CHANGE
  payload: {
    field: string
    value: number
  }
}

export interface ResetFiltersAction {
  type: ActionType.RESET_FILTERS
}

export interface OnSelectSectionAction {
  type: ActionType.ON_SELECT_SECTION
  payload: SectionDefinition
}

export interface OnHighlightSectionAction {
  type: ActionType.ON_HIGHLIGHT_SECTION
  payload: SectionDefinition
}

export interface OnShowAllInPriceChangeAction {
  type: ActionType.ON_SHOW_ALL_IN_PRICE_CHANGE
}

export interface ResetSectionAction {
  type: ActionType.RESET_SECTION_SELECTION
}

export type Action =
  | OnTypeFilterChangeAction
  | ResetFiltersAction
  | OnQuantityFilterChangeAction
  | OnSortFilterChangeAction
  | OnPriceFilterChangeAction
  | OnSelectSectionAction
  | OnHighlightSectionAction
  | OnShowAllInPriceChangeAction
  | ResetSectionAction;

export interface TypeFilterState {
  superSeller: boolean
  greatDeal: boolean
  instantDelivery: boolean
}

export interface QuantityFilterState {
  quantity: number
}

export interface SortFilterState {
  name: SortFilterNames
}

export interface PriceFilterState {
  currentMin: number
  currentMax: number
}

export interface SectionDefinition {
  id: string
}

export interface FilterState {
  typeFilterState: TypeFilterState
  quantityFilterState: QuantityFilterState
  sortFilterState: SortFilterState
  priceFilterState: PriceFilterState
  selectedSectionsFilterState: Array<SectionDefinition>
  highlightedSectionsState: Array<SectionDefinition>
  showAllInPrice: boolean
}
export interface SectionsPrice {
  [section: string]: {
    price: number
    allInPrice: number
  }
}
export interface Selectors {
  filteredListingsSelector: Listing[]
  getCurrentSortByValueSelector: string
  getCurrentSortByButtonTextSelector: string
  filteredSections: Array<string>
  filteredSectionsWithPrice: SectionsPrice
  getViewResultsButtonTextSelector: string
  getHighlightedSections: Array<string>
  // hasGreatDealsSelector: boolean
}

export interface Dispatch {
  onTypeFilterChangeAction: (payload: { filterName: TypeFilterName; filterValue: boolean }) => void
  onQuantityFilterChangeAction: (payload: { quantity: number }) => void
  onSortFilterChangeAction: (payload: { name: SortFilterNames }) => void
  onPriceFilterChangeAction: (payload: { field: string; value: number }) => void
  onFilterResetAction: () => void
  onSectionSelectAction: (payload: SectionDefinition) => void
  onSectionHighlightAction: (payload: SectionDefinition) => void
  onShowAllInPriceChangeAction: () => void
  onResetSectionSelectionAction: () => void
}


export const defaultState: FilterState = {
  typeFilterState: {
    superSeller: false,
    greatDeal: false,
    instantDelivery: false,
  },
  quantityFilterState: {
    quantity: 0,
  },
  sortFilterState: {
    name: SortFilterNames.PRICE,
  },
  priceFilterState: {
    currentMin: 0,
    currentMax: 0,
  },
  selectedSectionsFilterState: [],
  highlightedSectionsState: [],
  showAllInPrice: false,
};
import React from 'react';
import cx from 'classnames';

import Image from '../../atoms/Image';
import Text from '../../atoms/Text';

import styles from './LandingPageBanner.module.scss';
import usePresenter from './LandingPageBanner.presenter';
import getProps from './LandingPageBanner.props';
import { LandingPageBannerCombinedProps } from './types';


const LandingPageBanner: React.FC<LandingPageBannerCombinedProps> = (props) => {
  const {
    className,
    classes,
    imageSrc,
    imageSrcAlt,
    imageFallback,
    image,
    text,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.landingPageBanner;

  return (
    <div className={cx(currentStyle, className)} style={{ backgroundImage: `url(${imageSrc}), url(${imageFallback})` }}>
      <Image
        className={cx(styles.image, classes?.image)}
        {...internalProps.image}
        {...image}/>
      <Text
        className={cx(styles.text, classes?.text)}
        {...internalProps.text}
        {...text}/>
    </div>
  );
};

export default LandingPageBanner;


import { EventCardsBlockProps } from '../../blocks/EventCardsBlock';
import { FiltersBlockProps } from '../../blocks/FiltersBlock';
import { SecondaryBannerLightBlockProps } from '../../blocks/SecondaryBannerLightBlock';
import { PrimaryBannerBlockProps } from '../../blocks/PrimaryBannerBlock';


type HomepageInternalProps = {
  primaryBannerBlock?: PrimaryBannerBlockProps;
  exclusiveEvents?: EventCardsBlockProps;
  mLBBanner?: SecondaryBannerLightBlockProps;
  filtersBlock?: FiltersBlockProps;
  highlightedEvents?: EventCardsBlockProps;
  musicEvents?: EventCardsBlockProps;
  sportsEvents?: EventCardsBlockProps;
  comedyTheaterEvents?: EventCardsBlockProps;
  secondaryBannerLightBlock?: SecondaryBannerLightBlockProps;
};

type HomepagePropsValues = {
  '': HomepageInternalProps;
};

const propValues: HomepagePropsValues = {
  '': {
    primaryBannerBlock: {
    },
    exclusiveEvents: {
      type: 'EventsDarkBlock',
      state: 'Default',
    },
    mLBBanner: {
    },
    filtersBlock: {
    },
    highlightedEvents: {
      type: 'EventsLightBlock',
      state: 'Default',
    },
    musicEvents: {
      type: 'PerformersLightBlock',
      state: 'Default',
    },
    sportsEvents: {
      type: 'PerformersLightBlock',
      state: 'Default',
    },
    comedyTheaterEvents: {
      type: 'PerformersLightBlock',
      state: 'Default',
    },
    secondaryBannerLightBlock: {
    },
  },
};

const getProps = (type: string): HomepageInternalProps => {
  const values: HomepageInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

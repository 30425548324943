import React from 'react';
import cx from 'classnames';

import Divider from '../../atoms/Divider';
import Text from '../../atoms/Text';

import customStyles from './Custom.module.scss';
import styles from './PrimaryBanner.module.scss';
import usePresenter from './PrimaryBanner.presenter';
import getProps from './PrimaryBanner.props';
import { PrimaryBannerCombinedProps } from './types';


const PrimaryBanner: React.FC<PrimaryBannerCombinedProps> = (props) => {
  const {
    className,
    classes,
    imageSrc,
    imageSrcAlt,
    imageFallback,
    title,
    divider,
    message,
    // MANUAL OVERRIDE STARTS
    isExclusive,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.primaryBanner;

  // MANUAL OVERRIDE STARTS
  const backgroundImageClass = isExclusive ? customStyles.primaryBannerC1XLandingPage : customStyles.primaryBannerHomePage;
  // MANUAL OVERRIDE ENDS

  return (
    // MANUAL OVERRIDE STARTS - added custom class
    <div className={cx(currentStyle, className, backgroundImageClass)}>
      <div className={cx(styles.textContainer, classes?.textContainer, customStyles.textContainer)}>
        <div className={cx(styles.content, classes?.content, customStyles.content)}>
          <div className={cx(styles.messageContent, classes?.messageContent, customStyles.messageContent)}>
    {/* MANUAL OVERRIDE ENDS  */}
            <Text
              className={cx(styles.title, classes?.title)}
              {...internalProps.title}
              {...title}/>
            <Divider
              className={cx(styles.divider, classes?.divider)}
              {...internalProps.divider}
              {...divider}/>
            <Text
              className={cx(styles.message, classes?.message)}
              {...internalProps.message}
              {...message}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrimaryBanner;


import { TextProps } from '../Text';


type TabInternalProps = {
  text?: TextProps;
};

type TabPropsValues = {
  'RoundedDarkActive': TabInternalProps;
  'RoundedDarkDefault': TabInternalProps;
  'SquareLightActive': TabInternalProps;
  'SquareLightDefault': TabInternalProps;
};

const propValues: TabPropsValues = {
  'RoundedDarkActive': {
    text: {
      type: 'Body',
      size: 'Large',
      style: 'SemiBold',
      colour: 'BaseDark',
      align: 'Left',
    },
  },
  'RoundedDarkDefault': {
    text: {
      type: 'Body',
      size: 'Large',
      style: 'SemiBold',
      colour: 'SubduedLight',
      align: 'Left',
    },
  },
  'SquareLightActive': {
    text: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'BaseLight',
      align: 'Left',
    },
  },
  'SquareLightDefault': {
    text: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'BaseLight',
      align: 'Left',
    },
  },
};

const getProps = (type: string): TabInternalProps => {
  const values: TabInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

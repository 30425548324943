import { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import categoryEvents from '../../../modules/navigation/CategoryEvents.json';

import { CP1_EXCLUSIVE_TAG } from '../../../lib/constants';
import { typedDeepMerge } from '../../../lib/objectUtils';
import { setEventDescription } from '../../../lib/util';
import { AuthContext } from '../../../modules/auth';
import { TopNavTab } from '../../../modules/navigation/types';
import { TopNavContext } from '../../../modules/topNav';
import { TabCombinedProps } from '../../atoms/Tab/types';
import { NavMenuItemCombinedProps } from '../../molecules/NavMenuItem/types';
import { TopNavCombinedProps } from './types';
import { SEARCH_ICON_ON_TAB_BAR, getTopNavType } from './util';
import { useAnalyticsManager } from '../../../modules/analytics/useAnalyticsManager';
import { trackSelectContentEvent } from '../../../modules/analytics/util';

const usePresenter = (props: TopNavCombinedProps): TopNavCombinedProps => {
  const { listing, onBackButtonClicked } = props;

  const { pathname } = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const searchBlockTextFieldActive = useRef(false);
  const { trackEvent } = useAnalyticsManager();

  const data: TopNavTab[] = JSON.parse(JSON.stringify(categoryEvents)) as TopNavTab[];
  const { account } = useContext(AuthContext);
  const { currentEvent, hasNoEventMap, hasEventDescription, hasEventGuestList, isSoldOutEvent } = useContext(TopNavContext);

  const [showEventDetails, setShowEventDetails] = useState(false);
  
  const isCp1Exclusive = currentEvent?.tags?.includes(CP1_EXCLUSIVE_TAG);
  
  // manage tabs start
  const [openDropdownId, setOpenDropdownId] = useState<string>();
  const [focusState, setFocusState] = useState<string>();
  const onTabHover = (id: string | undefined) => {
    setOpenDropdownId(id);
  };
  const onTabFocus = (id: string | undefined) => {
    if (id !== openDropdownId) {
      setOpenDropdownId(undefined);
    }
    setFocusState(id);
  };
  const onTabLeave = () => {
    setOpenDropdownId(undefined);
  };
  const onKeyPress = () => {
    if (document.activeElement?.getAttribute('aria-haspopup')) {
      setOpenDropdownId(
        openDropdownId === focusState ? undefined : focusState,
      );
    }
  };
  // manage tabs end

  // manage search focus starts
  const topNavType = props.type || getTopNavType(pathname, account);
  const onSearchIconFocus = (id?: string) => {
    if (id !== openDropdownId) {
      setOpenDropdownId(undefined);
    }
    setFocusState(id);
  };
  const onSearchIconHover = () => {
    setOpenDropdownId(SEARCH_ICON_ON_TAB_BAR);
  };

  const onSearchIconLeave = () => {
    setOpenDropdownId(undefined);
  };
  // manage search focus ends

  // manage event details modal starts
  let eventDescription;
  if (currentEvent) {
    eventDescription = setEventDescription(currentEvent);
  }

  const openEventDetails = () => {
    trackSelectContentEvent(
      trackEvent,
      'Header',
      'Button',
    );
    setShowEventDetails(true);
  };

  const closeEventDetails = () => {
    trackSelectContentEvent(
      trackEvent,
      'EventDetailsModal',
      'Button',
      t('topnav.close'),
    );
    setShowEventDetails(false);
  };
  // manage event details modal ends

  // set title starts
  const backToAllTicketText = t('precheckoutBlock.backToTickets');
  const setTextValue = () => {
    if (topNavType === 'TicketSeatSelection' && currentEvent) {
      if (listing) {
        return backToAllTicketText;
      }
      return currentEvent.name;
    }
    return undefined;
  };

  const titleTextValue = setTextValue();
  // set title ends


  // manage back button starts
  const goBackFunction = () => {
    trackSelectContentEvent(
      trackEvent,
      'Header',
      'Button',
      t('precheckoutBlock.backButton'),
    );
    if (history.action !== 'POP') {
      history.goBack();
    } else {
      history.push('/');
    }
  };
  const isPreCheckoutPage =
    topNavType === 'TicketSeatSelection' && currentEvent && listing;
  // on pre checkout page redirection should be to last url and in event page it should be home page
  const onBackButtonClick = isPreCheckoutPage
    ? goBackFunction
    : onBackButtonClicked || goBackFunction;
  // manage back button ends


  // sets navmenu category tabs
  const headerTabs: TabCombinedProps[] = [];
  const menuListItems: NavMenuItemCombinedProps[] = [];

  data?.forEach((category) => {
    headerTabs.push({
      id: category.id,
      text: {
        value: category.name,
      },
    });

    menuListItems.push({
      title: {
        value: category.name,
      },
    });
  });

  return typedDeepMerge(props, {
    type: topNavType,
    logo: {
      linkPath: '/',
      assetAlt: t('topnav.capitalOneLogoScreenReaderText'),
    },
    title: {
      value: titleTextValue,
      ariaLabel: titleTextValue,
    },
    description:{
      value: topNavType === 'TicketSeatSelection' && currentEvent && !listing ? eventDescription : '',
    },
    button: {
      onClick: openEventDetails,
    },
    navMenuList: {
      tabs: headerTabs,
      navMenuItems: menuListItems,
      type: 'Tabs',
    },
    backButton: {
      onClick: onBackButtonClick,
      ariaLabel: t('precheckoutBlock.backButton'),
    },
    searchButton: {
      icon: {
        asset: 'Search',
        style: 'White',
        assetAlt: t('search.searchIconScreenReaderText'),
      },
    },
    searchBlock: {
      closeSearchBlock: () => setOpenDropdownId(undefined),
      searchResult: {
        // contains search suggestions
        onClick: () => {
          setOpenDropdownId(undefined);
        },
      },
      searchContainer: {
        searchField: {
          textInput: {
            onFocusChanged: () => {
              searchBlockTextFieldActive.current = true;
            },
            onBlurChanged: () => {
              searchBlockTextFieldActive.current = false;
            },
          },
        },
        state: 'Default',
      },
    },
    eventDetailsModal: {
      event: currentEvent,
      show: showEventDetails,
      closeModal: closeEventDetails,
    },
    showEventInfoButton: currentEvent?.name === titleTextValue && !listing && !isSoldOutEvent && !hasEventGuestList && (
      (hasNoEventMap && !isCp1Exclusive) ||
      (!hasNoEventMap && !!hasEventDescription) ||
      (isCp1Exclusive && !hasEventGuestList && !!hasEventDescription)
    ),
    onSearchIconHover,
    onSearchIconLeave,
    openDropdownId,
    onTabLeave,
    onTabHover,
    onTabFocus,
    onKeyPress,
    onSearchIconFocus,
  });
};

export default usePresenter;

import React from 'react';
import cx from 'classnames';

import LandingPageBannerBlock from '../../../legacy/blocks/LandingPageBannerBlock';
import SportTeamsNearLocationBlock from '../../../legacy/blocks/SportTeamsNearLocationBlock';
import SportTeamsListBlock from '../../../legacy/blocks/SportTeamsListBlock';

import styles from './SportsLandingPage.module.scss';
import usePresenter from './SportsLandingPage.presenter';
import getProps from './SportsLandingPage.props';
import { SportsLandingPageCombinedProps } from './types';

const SportsLandingPage: React.FC<SportsLandingPageCombinedProps> = (props) => {
  const {
    className,
    classes,
    landingPageBannerBlock,
    sportTeamsNearLocationBlock,
    sportTeamsListBlock,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.sportsLandingPage;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.container, classes?.container)}>
        <LandingPageBannerBlock
          className={cx(styles.landingPageBannerBlock, classes?.landingPageBannerBlock)}
          {...internalProps.landingPageBannerBlock}
          {...landingPageBannerBlock}/>
        <SportTeamsNearLocationBlock
          className={cx(styles.sportTeamsNearLocationBlock, classes?.sportTeamsNearLocationBlock)}
          {...internalProps.sportTeamsNearLocationBlock}
          {...sportTeamsNearLocationBlock}/>
        <SportTeamsListBlock
          className={cx(styles.sportTeamsListBlock, classes?.sportTeamsListBlock)}
          {...internalProps.sportTeamsListBlock}
          {...sportTeamsListBlock}/>
      </div>
    </div>
  );
};

export default SportsLandingPage;


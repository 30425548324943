import { DropdownSelectCombinedProps } from './types';

const usePresenter = (props: DropdownSelectCombinedProps): DropdownSelectCombinedProps => {
  const { onDropdownSelectClicked } = props;
  const handleSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (onDropdownSelectClicked) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      onDropdownSelectClicked(e.target.value);
    }
  };
  return {
    ...props,
    onChange: handleSelection,
  };
};

export default usePresenter;

import React from 'react';
import cx from 'classnames';

import TextItem from '../TextItem';

import customStyles from './Custom.module.scss';
import styles from './OnBoardingContactInformation.module.scss';
import usePresenter from './OnBoardingContactInformation.presenter';
import getProps from './OnBoardingContactInformation.props';
import { OnBoardingContactInformationCombinedProps } from './types';

const OnBoardingContactInformation: React.FC<OnBoardingContactInformationCombinedProps> = (props) => {
  const {
    className,
    classes,
    name,
    email,
    phone,
    address,
  } = usePresenter(props);

  const internalProps = getProps('');

  const desktopView = (
    <div className={cx(styles.container, customStyles.customOnBoardingContactInformation)}>
      <div className={styles.field}>
        <TextItem
          className={cx(customStyles.customTextItem)}
          classes={{
            description: customStyles.customDescription,
          }}
          {...internalProps.name}
          {...name} />
      </div>
      <div className={styles.fieldGroup}>
        <div className={styles.fieldColumn}>
          <div className={styles.field}>
            <TextItem
              className={cx(customStyles.customTextItem)}
              classes={{
                description: customStyles.customDescription,
              }}
              {...internalProps.email}
              {...email} />
          </div>
          <div className={styles.field}>
            <TextItem
              className={cx(customStyles.customTextItem)}
              classes={{
                description: customStyles.customDescription,
              }}
              {...internalProps.phone}
              {...phone} />
          </div>
        </div>
        <div className={styles.fieldColumn}>
          <div className={styles.field}>
            <TextItem
              className={cx(customStyles.customTextItem)}
              classes={{
                description: customStyles.customDescription,
              }}
              {...internalProps.address}
              {...address} />
          </div>
        </div>
      </div>
    </div>
  );

  const mobileView = (
    <div className={cx(styles.mobileContainer, customStyles.customOnBoardingContactInformation)}>
      <div className={styles.field}>
        <TextItem
          className={cx(customStyles.customTextItem)}
          classes={{
            description: customStyles.customDescription,
          }}
            {...internalProps.name}
            {...name} />
      </div>
      <div className={styles.field}>
        <TextItem
          className={cx(customStyles.customTextItem)}
          classes={{
            description: customStyles.customDescription,
          }}
          {...internalProps.email}
          {...email} />
      </div>
      <div className={styles.field}>
        <TextItem
          className={cx(customStyles.customTextItem)}
          classes={{
            description: customStyles.customDescription,
          }}
          {...internalProps.address}
          {...address} />
      </div>
      <div className={styles.field}>
        <TextItem
          className={cx(customStyles.customTextItem)}
          classes={{
            description: customStyles.customDescription,
          }}
          {...internalProps.phone}
          {...phone} />
      </div>
    </div>
  );
  return (
    <>
      {desktopView}
      {mobileView}
    </>
  );
};

export default OnBoardingContactInformation;
import { QuestionsBlockProps } from '../../blocks/QuestionsBlock';


type HelpPageInternalProps = {
  questionsBlock?: QuestionsBlockProps;
};

type HelpPagePropsValues = {
  '': HelpPageInternalProps;
};

const propValues: HelpPagePropsValues = {
  '': {
    questionsBlock: {
    },
  },
};

const getProps = (type: string): HelpPageInternalProps => {
  const values: HelpPageInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

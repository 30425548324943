import React from 'react';
import cx from 'classnames';

import SearchCategories from '../../molecules/SearchCategories';
import SearchReturnList from '../../organisms/SearchReturnList';

import styles from './SearchResultsBlock.module.scss';
import usePresenter from './SearchResultsBlock.presenter';
import getProps from './SearchResultsBlock.props';
import { SearchResultsBlockCombinedProps } from './types';


const SearchResultsBlock: React.FC<SearchResultsBlockCombinedProps> = (props) => {
  const {
    className,
    classes,
    // MANUAL OVERRIDE STARTS - renamed categories
    upcomingCategory,
    upcomingReturnList,
    venueCategory,
    venueReturnList,
    performerCategory,
    performerReturnList,
    // MANUAL OVERRIDE ENDS
    // MANUAL OVERRIDE - removed cities search results
    // MANUAL OVERRIDE STARTS
    searchSuggestions,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.searchResultsBlock;

  let eventViewWithLeftContent;
  let eventView;
  let performerView;
  let venueView;

  if (searchSuggestions?.events.length) {
    eventView = (
      <>
        <SearchCategories
          className={cx(styles.upcomingCategory, classes?.upcomingCategory)}
          {...internalProps.searchCategories}
          {...upcomingCategory}/>
        <SearchReturnList
          className={cx(styles.upcomingReturnList, classes?.upcomingReturnList)}
          {...internalProps.searchReturnList}
          {...upcomingReturnList}/>
      </>
    );
    eventViewWithLeftContent = (
      <div className={cx(styles.leftContent, classes?.leftContent)}>
        {eventView}
      </div>
    );
  }

  if (searchSuggestions?.performers.length) {
    performerView = (
      <>
        <SearchCategories
          className={cx(styles.performerCategory, classes?.performerCategory)}
          {...internalProps.searchCategories2}
          {...performerCategory}/>
        <SearchReturnList
          className={cx(styles.performerReturnList, classes?.performerReturnList)}
          {...internalProps.searchReturnList2}
          {...performerReturnList}/>
      </>
    );
  }

  if (searchSuggestions?.venues.length) {
    venueView = (
      <>
        <SearchCategories
          className={cx(styles.venueCategory, classes?.venueCategory)}
          {...internalProps.searchCategories1}
          {...venueCategory}/>
        <SearchReturnList
          className={cx(styles.venueReturnList, classes?.venueReturnList)}
          {...internalProps.searchReturnList1}
          {...venueReturnList}/>
      </>
    );
  }

  if (!searchSuggestions?.events.length) {
    return (
      <div className={cx(currentStyle, className)}>
        <div className={cx(styles.menuContainer, classes?.menuContainer)}>
          <div className={cx(styles.leftContent, classes?.leftContent)}>
            {performerView}
            {venueView}
          </div>
          <div className={cx(styles.rightContent, classes?.rightContent)}></div>
        </div>
      </div>
    );
  }

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.menuContainer, classes?.menuContainer)}>
        {eventViewWithLeftContent}
        <div className={cx(styles.rightContent, classes?.rightContent)}>
          {performerView}
          {venueView}
          {/* MANUAL OVERRIDE - removed cities category results */}
        </div>
      </div>
      {/* MANUAL OVERRIDE - added menuContainerMobile to hide desktop results in mobile breakpoint */}
      <div className={cx(styles.menuContainerMobile, classes?.menuContainerMobile)}>
        {performerView}
        {eventView}
        {venueView}
        {/* MANUAL OVERRIDE - removed cities search results */}
      </div>
    </div>
  );
};

export default SearchResultsBlock;


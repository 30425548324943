import React, { createContext, useContext, useEffect } from 'react';
import { ListingsContext } from '../listings';
import {
  filteredListingsSelector, filteredSections, filteredSectionsWithPrice,
  filterResetAction, getCurrentSortByButtonTextSelector, getCurrentSortByValueSelector,
  getHighlightedSections, getViewResultsButtonTextSelector, priceFilterChangeAction,
  quantityFilterChangeAction, sectionHighlightAction, sectionSelectFilterChangeAction,
  sectionSelectionResetAction, showAllInPriceChangeAction, sortFilterChangeAction,
  typeFilterChangeAction, useFilterReducer,
} from './selectors';
import { defaultState, Dispatch, FilterState, Selectors, SortFilterNames } from './types';
import { Props } from '../../lib/types';

interface ContextValue {
  state: FilterState;
  selectors: Selectors;
  dispatch: Dispatch;
}

export const defaultContextValue: ContextValue = {
  state: defaultState,
  selectors: {
    filteredListingsSelector: [],
    getCurrentSortByValueSelector: 'none',
    getCurrentSortByButtonTextSelector: 'Sort By',
    filteredSections: [],
    filteredSectionsWithPrice: {},
    getViewResultsButtonTextSelector: 'No Results',
    getHighlightedSections: [],
  },
  dispatch: {
    onTypeFilterChangeAction: () => undefined,
    onQuantityFilterChangeAction: () => undefined,
    onSortFilterChangeAction: () => undefined,
    onPriceFilterChangeAction: () => undefined,
    onFilterResetAction: () => undefined,
    onSectionSelectAction: () => undefined,
    onSectionHighlightAction: () => undefined,
    onShowAllInPriceChangeAction: () => undefined,
    onResetSectionSelectionAction: () => undefined,
  },
};

export const FilterContext = createContext<ContextValue>(defaultContextValue);
const { Provider } = FilterContext;

export const FilterProvider: React.FC<Props> = ({ children }) => {
  const { selectors: listingsSelectors } = useContext(ListingsContext);
  const { composedListings } = listingsSelectors;
  const [state, dispatch] = useFilterReducer();

  // useEffect(() => {
  //     if (showAip) {
  //         showAllInPriceChangeAction(dispatch)
  //         priceFilterChangeAction(dispatch, {
  //             field: 'currentMin',
  //             value: globalPriceRangeObject.lowestAllInPrice,
  //         })
  //         priceFilterChangeAction(dispatch, {
  //             field: 'currentMax',
  //             value: globalPriceRangeObject.highestAllInPrice,
  //         })
  //     }
  // }, [globalPriceRangeObject, showAip, dispatch])

  return (
    <Provider
      value={{
        state,
        selectors: {
          filteredListingsSelector: filteredListingsSelector(composedListings, state),
          getCurrentSortByValueSelector: getCurrentSortByValueSelector(state),
          getCurrentSortByButtonTextSelector: getCurrentSortByButtonTextSelector(state),
          filteredSectionsWithPrice: filteredSectionsWithPrice(composedListings, state),
          filteredSections: filteredSections(composedListings, state),
          getViewResultsButtonTextSelector: getViewResultsButtonTextSelector(
            filteredListingsSelector(composedListings, state),
          ),
          getHighlightedSections: getHighlightedSections(state),
        },
        dispatch: {
          onTypeFilterChangeAction: (payload) => typeFilterChangeAction(dispatch, payload),
          onQuantityFilterChangeAction: (payload) => quantityFilterChangeAction(dispatch, payload),
          onSortFilterChangeAction: (payload) => sortFilterChangeAction(dispatch, payload),
          onPriceFilterChangeAction: (payload) => priceFilterChangeAction(dispatch, payload),
          onFilterResetAction: () => filterResetAction(dispatch),
          onSectionSelectAction: (payload) => sectionSelectFilterChangeAction(dispatch, payload),
          onSectionHighlightAction: (payload) => sectionHighlightAction(dispatch, payload),
          onShowAllInPriceChangeAction: () => showAllInPriceChangeAction(dispatch),
          onResetSectionSelectionAction: () => sectionSelectionResetAction(dispatch),
        },
      }}
    >
      {children}
    </Provider>
  );
};

export default FilterContext;

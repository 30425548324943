import { TextProps } from '../../atoms/Text';
import { ButtonProps } from '../../atoms/Button';
import { DividerProps } from '../../atoms/Divider';
import { LogoProps } from '../../atoms/Logo';
import { AdditionalTicketInformationListProps } from '../../molecules/AdditionalTicketInformationList';
import { TextItemProps } from '../../molecules/TextItem';
import { HighlightMessageProps } from '../../molecules/HighlightMessage';
import { InfoDetailsProps } from '../../molecules/InfoDetails';
import { InfoHeaderProps } from '../../molecules/InfoHeader';
import { InfoOnMultipleLineProps } from '../../molecules/InfoOnMultipleLine';
import { PaymentMethodProps } from '../../molecules/PaymentMethod';
import { SeatInfoProps } from '../../molecules/SeatInfo';


type OrderDetailsInternalProps = {
  closeButton?: ButtonProps;
  infoHeader?: InfoHeaderProps;
  printButton?: ButtonProps;
  divider?: DividerProps;
  ticketInfoTitle?: TextProps;
  highlightMessage?: HighlightMessageProps;
  infoDetails?: InfoDetailsProps;
  seatInfo?: SeatInfoProps;
  sellerNotes?: TextItemProps;
  label?: TextProps;
  logo?: LogoProps;
  divider1?: DividerProps;
  deliveryInfoTitle?: TextProps;
  additionalTicketInformationList?: AdditionalTicketInformationListProps;
  deliveryDateInfo?: TextItemProps;
  downloadButton?: ButtonProps;
  divider2?: DividerProps;
  billingInfoTitle?: TextProps;
  infoOnMultipleLine?: InfoOnMultipleLineProps;
  phoneNumber?: TextItemProps;
  paymentMethod?: PaymentMethodProps;
  totalCardPayment?: TextItemProps;
  rightContent?: TextItemProps;
  rewardsMilesRedeemed?: TextItemProps;
};

type OrderDetailsPropsValues = {
  '': OrderDetailsInternalProps;
};

const propValues: OrderDetailsPropsValues = {
  '': {
    closeButton: {
      type: 'Icon',
      style: 'Text',
      size: 'Small',
    },
    infoHeader: {
    },
    printButton: {
      type: 'IconText',
      style: 'Text',
      size: 'Small',
    },
    divider: {
      type: 'Horizontal',
      style: 'Thin',
      colour: 'DigitalGrey15',
    },
    ticketInfoTitle: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    highlightMessage: {
      type: 'Info',
    },
    infoDetails: {
    },
    seatInfo: {
    },
    sellerNotes: {
      style: 'SubduedLabel',
    },
    label: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    logo: {
      asset: 'VividLogo',
    },
    divider1: {
      type: 'Horizontal',
      style: 'Thin',
      colour: 'DigitalGrey15',
    },
    deliveryInfoTitle: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    additionalTicketInformationList: {
    },
    deliveryDateInfo: {
      style: 'SubduedLabel',
    },
    downloadButton: {
      type: 'Text',
      style: 'ContainedGreen',
      size: 'Medium',
    },
    divider2: {
      type: 'Horizontal',
      style: 'Thin',
      colour: 'DigitalGrey15',
    },
    billingInfoTitle: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    infoOnMultipleLine: {
    },
    phoneNumber: {
      style: 'SubduedLabel',
    },
    paymentMethod: {
    },
    totalCardPayment: {
      style: 'SubduedLabel',
    },
    rightContent: {
      style: 'SubduedLabel',
    },
    rewardsMilesRedeemed: {
      style: 'SubduedLabel',
    },
  },
};

const getProps = (type: string): OrderDetailsInternalProps => {
  const values: OrderDetailsInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

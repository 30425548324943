import React from 'react';
import cx from 'classnames';

import Text from '../../atoms/Text';
import Divider from '../../atoms/Divider';

import styles from './CardInfoHeader.module.scss';
import usePresenter from './CardInfoHeader.presenter';
import getProps from './CardInfoHeader.props';
import { CardInfoHeaderCombinedProps } from './types';


const CardInfoHeader: React.FC<CardInfoHeaderCombinedProps> = (props) => {
  const {
    className,
    classes,
    title,
    date,
    time,
    location,
    divider,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.cardInfoHeader;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.content, classes?.content)}>
        <Text
          className={cx(styles.title, classes?.title)}
          {...internalProps.title}
          {...title}/>
        <div className={cx(styles.details, classes?.details)}>
          <div className={cx(styles.dateAndTime, classes?.dateAndTime)}>
            <Text
              className={cx(styles.date, classes?.date)}
              {...internalProps.date}
              {...date}/>
            <Text
              className={cx(styles.time, classes?.time)}
              {...internalProps.time}
              {...time}/>
          </div>
          <Text
            className={cx(styles.location, classes?.location)}
            {...internalProps.location}
            {...location}/>
        </div>
      </div>
      <Divider
        className={cx(styles.divider, classes?.divider)}
        {...internalProps.divider}
        {...divider}/>
    </div>
  );
};

export default CardInfoHeader;


import React from 'react';
import cx from 'classnames';

import Icon from '../../atoms/Icon';
import Text from '../../atoms/Text';

import styles from './ContentLoadErrorBlock.module.scss';
import usePresenter from './ContentLoadErrorBlock.presenter';
import getProps from './ContentLoadErrorBlock.props';
import { ContentLoadErrorBlockCombinedProps } from './types';


const ContentLoadErrorBlock: React.FC<ContentLoadErrorBlockCombinedProps> = (props) => {
  const {
    className,
    classes,
    icon,
    message,
    title,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.contentLoadErrorBlock;

  return (
    <div className={cx(currentStyle, className)}>
      <Icon
        className={cx(styles.icon, classes?.icon)}
        {...internalProps.icon}
        {...icon}/>
      <div className={cx(styles.content, classes?.content)}>
        <Text
          className={cx(styles.message, classes?.message)}
          {...internalProps.message}
          {...message}/>
        <Text
          className={cx(styles.title, classes?.title)}
          {...internalProps.title}
          {...title}/>
      </div>
    </div>
  );
};

export default ContentLoadErrorBlock;


import React from 'react';
import cx from 'classnames';

import Divider from '../../atoms/Divider';
import EmptyStateBanner from '../../molecules/EmptyStateBanner';
import Filter from '../../molecules/Filter';
import ThumbnailCardList from '../../organisms/ThumbnailCardList';

import styles from './SportTeamsNearLocationBlock.module.scss';
import usePresenter from './SportTeamsNearLocationBlock.presenter';
import getProps from './SportTeamsNearLocationBlock.props';
import { SportTeamsNearLocationBlockCombinedProps } from './types';


const SportTeamsNearLocationBlock: React.FC<SportTeamsNearLocationBlockCombinedProps> = (props) => {
  const {
    state,
    className,
    classes,
    filter,
    emptyStateBanner,
    divider,
    thumbnailCardList,
  } = usePresenter(props);

  const variantName = `${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`sportTeamsNearLocationBlock${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Empty': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Filter
            className={cx(styles.filter, classes?.filter)}
            {...internalProps.filter}
            {...filter}/>
          <EmptyStateBanner
            className={cx(styles.emptyStateBanner, classes?.emptyStateBanner)}
            {...internalProps.emptyStateBanner}
            {...emptyStateBanner}/>
          <Divider
            className={cx(styles.divider, classes?.divider)}
            {...internalProps.divider}
            {...divider}/>
        </div>
      );
      break;
    }
    case 'Filed': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Filter
            className={cx(styles.filter, classes?.filter)}
            {...internalProps.filter}
            {...filter}/>
          <ThumbnailCardList
            className={cx(styles.thumbnailCardList, classes?.thumbnailCardList)}
            {...internalProps.thumbnailCardList}
            {...thumbnailCardList}/>
        </div>
      );
      break;
    }
    case 'Filled': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Filter
            className={cx(styles.filter, classes?.filter)}
            {...internalProps.filter}
            {...filter}/>
          <ThumbnailCardList
            className={cx(styles.thumbnailCardList, classes?.thumbnailCardList)}
            {...internalProps.thumbnailCardList}
            {...thumbnailCardList}/>
          <Divider
            className={cx(styles.divider, classes?.divider)}
            {...internalProps.divider}
            {...divider}/>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default SportTeamsNearLocationBlock;


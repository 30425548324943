import { useState } from 'react';
import { SubmenuWithDoubleTabsCombinedProps } from './types';
import customStyles from './Custom.module.scss';
import { useAnalyticsManager } from '../../../modules/analytics/useAnalyticsManager';
import { SubmenuTabItemCombinedProps } from '../../molecules/SubmenuTabItem/types';
import categoryEvents from '../../../modules/navigation/CategoryEvents.json';

type CategoryEvent = {
  id: string;
  name: string;
  children: any;
};

const usePresenter = (props: SubmenuWithDoubleTabsCombinedProps): SubmenuWithDoubleTabsCombinedProps => {
  const { trackEvent } = useAnalyticsManager();
  const topNavTabsData: CategoryEvent[] = JSON.parse(JSON.stringify(categoryEvents));
  const [openDropdownId, setOpenDropdownId] = useState<string | undefined>(topNavTabsData[0].id);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const openCategory = topNavTabsData.find((tab) => tab.id === openDropdownId);
  const onTabHover = (id: string | undefined) => setOpenDropdownId(id);

  // construct the desktop navigation tabs
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const categoryTabs: SubmenuTabItemCombinedProps[] = topNavTabsData.map((category): SubmenuTabItemCombinedProps => ({
    state: openDropdownId === category.id ? 'Selected' : 'Default',
    id: category.id,
    // active: openDropdownId === category.id ? 'Active' : 'Default',
    text: {
      value: category.name,
    },
    onItemClicked: () => setOpenDropdownId(category.id),
    onMouseOver: () => onTabHover(category.id),
  }));
  
  return {
    ...props,
    openDropdownId,
    sportsMenuTabList: {
      submenuTabItems: categoryTabs,
    },
    activeMenu: openCategory,
    submenuWithTabs: {
      data: topNavTabsData,
      isOpen: true,
      classes: {
        submenuItemList: customStyles.submenuItemList,
      },
    },
    submenuWithoutTabs: {
      data: topNavTabsData,
      id: openDropdownId,
      isOpen: true,
      classes: {
        submenuItemList: customStyles.submenuItemList,
      },
      onItemClicked: (event) => {
        if (openCategory?.id === 'exclusive' && event?.name !== 'Presales') {
          trackEvent('select_content_c1x_navigation-menu');
        }
      },
    },
  };
};

export default usePresenter;

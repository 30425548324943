import React from 'react';
import cx from 'classnames';

import Divider from '../../atoms/Divider';
import Icon from '../../atoms/Icon';
import TextInput from '../../atoms/TextInput';

import styles from './SearchField.module.scss';
import usePresenter from './SearchField.presenter';
import getProps from './SearchField.props';
import { SearchFieldCombinedProps } from './types';


const SearchField: React.FC<SearchFieldCombinedProps> = (props) => {
  const {
    type,
    className,
    classes,
    icon,
    textInput,
    divider,
    onKeyPress,
    onFocus,
  } = usePresenter(props);

  const variantName = `${type}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`searchField${variantName}`];

  let componentView;

  switch (variantName) {
    case 'SearchBubble': {
      componentView = (
        // MANUAL OVERRIDE
        <div className={cx(currentStyle, className)} onKeyPress={onKeyPress} onFocus={onFocus}>
          <Icon
            className={cx(styles.icon, classes?.icon)}
            {...internalProps.icon}
            {...icon}/>
          <TextInput
            className={cx(styles.textInput, classes?.textInput)}
            {...internalProps.textInput}
            {...textInput}/>
        </div>
      );
      break;
    }
    case 'SearchLine': {
      componentView = (
        // MANUAL OVERRIDE
        <div className={cx(currentStyle, className)} onKeyPress={onKeyPress} onFocus={onFocus}>
          <div className={cx(styles.content, classes?.content)}>
            <Icon
              className={cx(styles.icon, classes?.icon)}
              {...internalProps.icon}
              {...icon}/>
            <TextInput
              className={cx(styles.textInput, classes?.textInput)}
              {...internalProps.textInput}
              {...textInput}/>
          </div>
          <Divider
            className={cx(styles.divider, classes?.divider)}
            {...internalProps.divider}
            {...divider}/>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default SearchField;


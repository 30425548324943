import React from 'react';
import cx from 'classnames';

import Text from '../../atoms/Text';
import Logo from '../../atoms/Logo';
import CostBreakdown from '../../molecules/CostBreakdown';
import TextItem from '../../molecules/TextItem';
import SeatInfo from '../../molecules/SeatInfo';

import styles from './OrderInfo.module.scss';
import usePresenter from './OrderInfo.presenter';
import getProps from './OrderInfo.props';
import { OrderInfoCombinedProps } from './types';


const OrderInfo: React.FC<OrderInfoCombinedProps> = (props) => {
  const {
    className,
    classes,
    title1,
    amount,
    miles,
    costBreakdown,
    title2,
    dateAndTime,
    venue,
    seatInfo,
    sellerNotes,
    deliveryMethod,
    logo,
    // MANUAL OVERRIDE STARTS
    isMobileDevice,
    faceValue,
    showFaceValue,
    showOrderDetails,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.orderInfo;

  // MANUAL OVERRIDE STARTS
  const orderDetailsView = showOrderDetails ? (
    <>
      <div className={cx(styles.titleContent, classes?.titleContent)}>
        <div className={cx(styles.title, classes?.title)}>
          <Text
            className={cx(styles.title1, classes?.title1)}
            {...internalProps.title1}
            {...title1} />
        </div>
        <div className={cx(styles.price, classes?.price)}>
          <Text
            className={cx(styles.amount, classes?.amount)}
            {...internalProps.amount}
            {...amount} />
          <Text
            className={cx(styles.miles, classes?.miles)}
            {...internalProps.miles}
            {...miles} />
        </div>
      </div>
      <CostBreakdown
        className={cx(styles.costBreakdown, classes?.costBreakdown)}
        {...internalProps.costBreakdown}
        {...costBreakdown} />
    </>
  ) : null;

  const faceValueView = showFaceValue ? (
    <Text
      className={styles.faceValue}
      {...internalProps.faceValue}
      {...faceValue} />
  ) : null;
  // MANUAL OVERRIDE ENDS

  return (
    <div className={cx(currentStyle, className)}>
      {/* MANUAL OVERRIDE */}
      {orderDetailsView}
      {isMobileDevice ? faceValueView : null}
      <div className={cx(styles.topContent, classes?.topContent)}>
        <Text
          className={cx(styles.title2, classes?.title2)}
          {...internalProps.title2}
          {...title2} />
        <div className={cx(styles.eventInfo, classes?.eventInfo)}>
          <Text
            className={cx(styles.dateAndTime, classes?.dateAndTime)}
            {...internalProps.dateAndTime}
            {...dateAndTime} />
          <Text
            className={cx(styles.venue, classes?.venue)}
            {...internalProps.venue}
            {...venue} />
        </div>
      </div>
      <SeatInfo
        className={cx(styles.seatInfo, classes?.seatInfo)}
        {...internalProps.seatInfo}
        {...seatInfo} />
      <TextItem
        className={cx(styles.sellerNotes, classes?.sellerNotes)}
        {...internalProps.sellerNotes}
        {...sellerNotes} />
      <TextItem
        className={cx(styles.deliveryMethod, classes?.deliveryMethod)}
        {...internalProps.deliveryMethod}
        {...deliveryMethod} />
      {/* MANUAL OVERRIDE */}
      {!isMobileDevice ? faceValueView : null}
      <Logo
        className={cx(styles.logo, classes?.logo)}
        {...internalProps.logo}
        {...logo} />
    </div>
  );
};

export default OrderInfo;


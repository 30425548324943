import { useHistory } from 'react-router-dom';
import { AccountMenuBlockCombinedProps } from './types';
import { useTranslation } from 'react-i18next';
import { getUnitDisplaySettings, handleAmountFormattingString, handleDecimalValuesForDisplay, handleDisplayUnitName } from '../../../lib/util';
import { useContext, useState } from 'react';
import { AuthContext } from '../../../modules/auth';
import CardAsset from '../../../resources/icons/CreditCard.svg';
import { useAnalyticsManager } from '../../../modules/analytics/useAnalyticsManager';
import { trackSelectContentEvent } from '../../../modules/analytics/util';

const usePresenter = (props: AccountMenuBlockCombinedProps): AccountMenuBlockCombinedProps => {
  const { isModalOpen } = props;
  const { account } = useContext(AuthContext);
  const history = useHistory();
  const { t } = useTranslation();
  const { trackEvent } = useAnalyticsManager();
  const rewardType = account?.loyalty_program?.loyalty_unit_name.toLowerCase();
  const { rewardSign, useDecimals } = getUnitDisplaySettings(rewardType);
  const numberUnits = handleDecimalValuesForDisplay(handleAmountFormattingString(account?.loyalty_program?.number_of_units, !useDecimals), useDecimals);

  const [isSelectedNavMenuItem, setIsSelectedNavMenuItem] = useState<boolean>(false);

  return {
    ...props,
    isModalOpen,
    accountMenu: {
      ...props.accountMenu,
      state: props.accountMenu?.state,
      greetingFirstName: {
        value: `${t('topnav.welcome', {
          greetingFirstName: account?.first_name,
        })}`,
      },
      cardImage: {
        imageSrc: account?.loyalty_program?.program_logo_url,
        imageFallback: CardAsset,
      },
      cardNumber: {
        value: `${t('topnav.cardNumber', {
          cardNumber: account?.loyalty_program?.last_four,
        })}`,
      },
      loyaltyPoints: {
        value: `${rewardSign}${numberUnits}  ${handleDisplayUnitName(account)}`,
      },
      closeButton: {
        ...props.accountMenu?.closeButton,
        icon: {
          asset: 'Close',
        },
      },
      divider: {
        ...props.accountMenu?.divider,
        type: 'Vertical',
        style: 'Thin',
        colour: 'White',
      },
    },
    navMenuList: {
      setIsSelectedNavMenuItem,
    },
    myTickets: {
      title: {
        value: t('topnav.accountMenu.myTickets'),
      },
      type: 'Default',
      onClick: () => {
        trackSelectContentEvent(
          trackEvent,
          'AccountMenu',
          'MenuItem',
          t('topnav.accountMenu.myTickets'),
        );
        history.push('/mytickets');
      },
    },
    logOut: {
      title: {
        value: t('topnav.accountMenu.logOut'),
      },
      type: 'Critical',
      onClick: () => {
        trackSelectContentEvent(
          trackEvent,
          'AccountMenu',
          'MenuItem',
          t('topnav.accountMenu.logOut'),
        );
        history.push('/logout');
      },
    },
    isSelectedNavMenuItem,
  };
};

export default usePresenter;

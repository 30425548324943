import { MaintenanceBlockValueProps } from '../../blocks/MaintenanceBlock';


type MaintenancePageInternalProps = {
  maintenanceBlock?: MaintenanceBlockValueProps
};

type MaintenancePagePropsValues = {
  '': MaintenancePageInternalProps;
};

const propValues: MaintenancePagePropsValues = {
  '': {
    maintenanceBlock: {
      title: {
        type: 'Subheading',
        size: 'Large',
        style: 'Regular',
        colour: 'SubduedDark',
        align: 'Left',
      },
      message: {
        type: 'Body',
        size: 'Large',
        style: 'Regular',
        colour: 'SubduedDark',
        align: 'Center',
      },
    },
  },
};

const getProps = (type: string): MaintenancePageInternalProps => {
  const values: MaintenancePageInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

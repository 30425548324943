import { ButtonProps } from '../../atoms/Button';
import { PriceItemProps } from '../PriceItem';


type CostBreakdownInternalProps = {
  ordarTotal?: PriceItemProps;
  tickets?: PriceItemProps;
  serviceFee?: PriceItemProps;
  delivery?: PriceItemProps;
  button?: ButtonProps;
  // MANUAL OVERRIDE STARTS
  tax?: PriceItemProps;
  // MANUAL OVERRIDE ENDS
};

type CostBreakdownPropsValues = {
  '': CostBreakdownInternalProps;
};

const propValues: CostBreakdownPropsValues = {
  '': {
    ordarTotal: {
      style: 'Dark',
    },
    tickets: {
      style: 'Light',
    },
    serviceFee: {
      style: 'Light',
    },
    delivery: {
      style: 'Light',
    },
    button: {
      type: 'IconText',
      style: 'Text',
      size: 'ExtraSmall',
    },
    // MANUAL OVERRIDE STARTS
    tax: {
      style: 'Light',
    },
    // MANUAL OVERRIDE ENDS
  },
};

const getProps = (type: string): CostBreakdownInternalProps => {
  const values: CostBreakdownInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

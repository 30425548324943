import { ErrorBlockProps } from '../../blocks/ErrorBlock';
import { SearchBlockProps } from '../../blocks/SearchBlock';


type ErrorPageInternalProps = {
  searchBlock?: SearchBlockProps;
  errorBlock?: ErrorBlockProps;
};

type ErrorPagePropsValues = {
  '': ErrorPageInternalProps;
};

const propValues: ErrorPagePropsValues = {
  '': {
    searchBlock: {
    },
    errorBlock: {
    },
  },
};

const getProps = (type: string): ErrorPageInternalProps => {
  const values: ErrorPageInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import { AccountMenuProps } from '../../molecules/AccountMenu';
import { NavMenuItemProps } from '../../molecules/NavMenuItem';


type AccountMenuBlockInternalProps = {
  accountMenu?: AccountMenuProps;
  // MANUAL OVERRIDE STARTS - removed navmenu list - added navmenu items
  myTickets?: NavMenuItemProps;
  logOut?: NavMenuItemProps;
  // MANUAL OVERRIDE ENDS
};

type AccountMenuBlockPropsValues = {
  '': AccountMenuBlockInternalProps;
};

const propValues: AccountMenuBlockPropsValues = {
  '': {
    accountMenu: {
      state: 'Expanded',
    },
    // MANUAL OVERRIDE STARTS - removed navmenu list - added navmenu items
    myTickets: {
      type: 'Next',
    },
    logOut: {
      type: 'Default',
    },
    // MANUAL OVERRIDE ENDS
  },
};

const getProps = (type: string): AccountMenuBlockInternalProps => {
  const values: AccountMenuBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import Icon from '../../atoms/Icon';
import Text from '../../atoms/Text';
import ToolTip from '../../atoms/ToolTip';

import styles from './BlockHeader.module.scss';
import usePresenter from './BlockHeader.presenter';
import getProps from './BlockHeader.props';
import { BlockHeaderCombinedProps } from './types';


const BlockHeader: React.FC<BlockHeaderCombinedProps> = (props) => {
  const {
    style,
    type,
    className,
    classes,
    text,
    button,
    // MANUAL OVERRIDE STARTS
    toolTipIcon,
    tooltip,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const variantName = `${style}${type}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`blockHeader${variantName}`];

  let componentView;

  switch (variantName) {
    case 'DarkNoButton':
    case 'LightNoButton': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Text
            className={cx(styles.text, classes?.text)}
            {...internalProps.text}
            {...text}/>
        </div>
      );
      break;
    }
    case 'DarkWithButton':
    case 'LightWithButton': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Text
            className={cx(styles.text, classes?.text)}
            {...internalProps.text}
            {...text}/>
          <Button
            className={cx(styles.button, classes?.button)}
            // MANUAL OVERRIDE - added classes
            classes={{
              icon: styles.icon,
            }}
            {...internalProps.button}
            {...button}/>
        </div>
      );
      break;
    }
    case 'DarkWithToolTip':
    case 'LightWithToolTip': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Text
            className={cx(styles.text, classes?.text)}
            {...internalProps.text}
            {...text}/>
          { tooltip ? (
          <ToolTip
            className={styles.tooltip}
            {...tooltip} >
            <Icon
              className={styles.toolTipIcon}
              {...internalProps.toolTipIcon}
              {...toolTipIcon} />
          </ ToolTip>) : undefined}
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default BlockHeader;


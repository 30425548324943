import React from 'react';
import cx from 'classnames';

import Text from '../../atoms/Text';
import Button from '../../atoms/Button';
import Icon from '../../atoms/Icon';

import styles from './CardInfoNotRequiredBlock.module.scss';
import usePresenter from './CardInfoNotRequiredBlock.presenter';
import getProps from './CardInfoNotRequiredBlock.props';
import { CardInfoNotRequiredBlockCombinedProps } from './types';
import { CHECKOUT_NONE_BRAINTREE_FORM } from '../../../lib/constants';


const CardInfoNotRequiredBlock: React.FC<CardInfoNotRequiredBlockCombinedProps> = (props) => {
  const {
    className,
    classes,
    icon,
    blockTitle,
    description,
    button,
    handleSubmit, // MANUAL OVERRIDE
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.cardInfoNotRequiredBlock;

  // MANUAL OVERRIDE STARTS
  return (
    <form
      id={CHECKOUT_NONE_BRAINTREE_FORM}
      className={cx(currentStyle, className)}
      onSubmit={handleSubmit}
    >
      <div className={styles.topContent}>
        <Icon
          className={cx(styles.icon, classes?.icon)}
          {...internalProps.icon}
          {...icon}/>
        <Text
          className={cx(styles.blockTitle, classes?.blockTitle)}
          {...internalProps.blockTitle}
          {...blockTitle}/>
        <Text
          className={cx(styles.description, classes?.description)}
          {...internalProps.description}
          {...description}/>
      </div>
      <Button
        buttonForm={CHECKOUT_NONE_BRAINTREE_FORM}
        className={cx(styles.button, classes?.button)}
        {...internalProps.button}
        {...button}/>
    </form>
  );
  // MANUAL OVERRIDE ENDS
};

export default CardInfoNotRequiredBlock;


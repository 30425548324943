import { ButtonCombinedProps } from '../Button';
import { TextProps } from '../Text';


type TextInputInternalProps = {
  text?: TextProps;
  button?: ButtonCombinedProps; // MANUAL OVERRIDE
};

type TextInputPropsValues = {
  'EmptyClearRegular': TextInputInternalProps;
  'EmptyDefaultCenterRegular': TextInputInternalProps;
  'EmptyDefaultRegular': TextInputInternalProps;
  'EmptyDefaultSmall': TextInputInternalProps;
  'FilledClearRegular': TextInputInternalProps;
  'FilledDefaultCenterRegular': TextInputInternalProps;
  'FilledDefaultRegular': TextInputInternalProps;
  'FilledDefaultSmall': TextInputInternalProps;
};

const propValues: TextInputPropsValues = {
  'EmptyClearRegular': {
    text: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'BaseLight',
      align: 'Left',
    },
  },
  'EmptyDefaultCenterRegular': {
    text: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Center',
    },
  },
  'EmptyDefaultRegular': {
    text: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
  },
  'EmptyDefaultSmall': {
    text: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
  },
  'FilledClearRegular': {
    text: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'BaseLight',
      align: 'Left',
    },
    button: {
      type: 'Icon',
      style: 'Text',
      size: 'Small',
      // MANUAL OVERRIDE STARTS
      icon: {
        asset: 'Close',
        style: 'White',
      },
      // MANUAL OVERRIDE ENDS
    },
  },
  'FilledDefaultCenterRegular': {
    text: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Center',
    },
  },
  'FilledDefaultRegular': {
    text: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
  },
  'FilledDefaultSmall': {
    text: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
  },
};

const getProps = (type: string): TextInputInternalProps => {
  const values: TextInputInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

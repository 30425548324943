import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { TERMS_MODAL } from '../../../lib/constants';
import { displayMaintenanceWindow } from '../../../lib/util';
import { AuthContext } from '../../../modules/auth';
import { TopNavContext } from '../../../modules/topNav';
import termsHtml from '../../../resources/termsAndPrivacy/terms';

import { ErrorBlockCombinedProps } from '../../../legacy/blocks/ErrorBlock/types';
import { MaintenanceBlockCombinedProps } from '../../../legacy/blocks/MaintenanceBlock/types';

import { ButtonValueProps } from '../../atoms/Button';

import { MainLayoutCombinedProps } from './types';
import { shouldShowUpdatedTopNav } from './utils';

const usePresenter = (props: MainLayoutCombinedProps): MainLayoutCombinedProps => {
  const { t } = useTranslation();
  const location = useLocation<ErrorBlockCombinedProps>();
  const { pathname, search } = useLocation();
  const { hasEventGuestList, isSoldOutEvent } = useContext(TopNavContext);
  const history = useHistory();
  
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const modalQuery = query.get('modal');
  let modalContent = '';
  if (modalQuery === TERMS_MODAL)
    modalContent = termsHtml;
  const [openModal, setModalOpen] = useState<boolean>(false);
  const [openOnBoardingModal, setOpenOnBoardingModal] = useState<boolean>(false);
  const { disableTopNav } = useContext(TopNavContext);
  const { account, refetchAccount, loading } = useContext(AuthContext);

  const handleClose = useCallback(() => {
    if (modalQuery) {
      query.delete('modal');
      history.replace({
        search: query.toString(),
      });
    }
    setModalOpen(false);
  }, [history, modalQuery, query]);

  const handleCloseOnBoardingModal = useCallback(() => {
    if (refetchAccount) {
      refetchAccount();
    }
    setOpenOnBoardingModal(false);
  }, [refetchAccount]);

  useEffect(() => {
    if (modalQuery === TERMS_MODAL) {
      setModalOpen(true);
    } else {
      setModalOpen(false);
    }
  }, [modalQuery]);

  useEffect(() => {
    if (account && account.new_user) {
      setOpenOnBoardingModal(account.new_user);
    }
  }, [account]);

  useEffect(() => {
    if (!pathname.startsWith('/exclusive-events')) {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  const skipToMainContentButton: ButtonValueProps = {
    text: {
      value: t('mainLayout.skipToMainContent'),
    },
  };

  // maintenance page
  const locationMaintenance = useLocation<MaintenanceBlockCombinedProps>();
  const inMaintenance = displayMaintenanceWindow();
  return {
    ...props,
    disableTopNav,
    errorBlock: location.state,
    handleCloseOnBoardingModal,
    handleCloseTermsAndPrivacyModal: handleClose,
    inMaintenance,
    loading,
    locationMaintenance: locationMaintenance.state,
    openOnBoardingModal,
    openTermsAndPrivacyModal: openModal,
    shouldShowFooter: !(pathname.startsWith('/events/') && !hasEventGuestList && !isSoldOutEvent),
    shouldShowUpdatedTopNav: shouldShowUpdatedTopNav(pathname, hasEventGuestList, isSoldOutEvent),
    skipToMainContentButton,
    termsAndPrivacyModalContent: modalContent,
  };
};

export default usePresenter;

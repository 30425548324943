import { ButtonProps } from '../../atoms/Button';
import { DividerProps } from '../../atoms/Divider';
import { DropdownProps } from '../Dropdown';


type TicketQuantitySelectionInternalProps = {
  dropdown?: DropdownProps;
  button?: ButtonProps;
  divider?: DividerProps;
};

type TicketQuantitySelectionPropsValues = {
  '': TicketQuantitySelectionInternalProps;
};

const propValues: TicketQuantitySelectionPropsValues = {
  '': {
    dropdown: {
    },
    button: {
      type: 'Text',
      style: 'Contained',
      size: 'Large',
    },
    divider: {
      type: 'Horizontal',
      style: 'Thin',
      colour: 'DigitalGrey15', // MANUAL OVERRIDE
    },
  },
};

const getProps = (type: string): TicketQuantitySelectionInternalProps => {
  const values: TicketQuantitySelectionInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

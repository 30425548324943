import { TextProps } from '../../atoms/Text';
import { DividerProps } from '../../atoms/Divider';
import { IconProps } from '../../atoms/Icon';


type MlbCardInternalProps = {
  titleText?: TextProps;
  bodyText?: TextProps;
  divider?: DividerProps;
  actionText?: TextProps;
  icon?: IconProps;
};

type MlbCardPropsValues = {
  'Light': MlbCardInternalProps;
};

const propValues: MlbCardPropsValues = {
  'Light': {
    titleText: {
      type: 'MLBCardTitle',
      size: 'Medium',
      style: 'SemiBold',
      colour: 'BaseLight',
      align: 'Left',
    },
    bodyText: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseLight',
      align: 'Left',
    },
    divider: {
      type: 'Horizontal',
      style: 'Thin',
      colour: 'DigitalGrey15',
    },
    actionText: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseLight',
      align: 'Left',
    },
    icon: {
      asset: 'ArrowRightFilled',
      style: 'White',
    },
  },
};

const getProps = (type: string): MlbCardInternalProps => {
  const values: MlbCardInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import { FAQItemProps } from '../../molecules/FAQItem';


type FAQListInternalProps = {
  fAQItem?: FAQItemProps;
};

type FAQListPropsValues = {
  '': FAQListInternalProps;
};

const propValues: FAQListPropsValues = {
  '': {
    fAQItem: {
      state: 'Collapsed',
    },
  },
};

const getProps = (type: string): FAQListInternalProps => {
  const values: FAQListInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import { useState } from 'react';
import { CheckBoxItemCombinedProps, CheckBoxItemStateEnum } from './types';

const usePresenter = (props: CheckBoxItemCombinedProps): CheckBoxItemCombinedProps => {
  const { state, onCheckBoxItemClicked } = props;
  const [newState, setNewState] = useState<CheckBoxItemStateEnum | undefined>(state);
  const handleClick = (e?: React.MouseEvent<HTMLElement>) => {
    e?.preventDefault();
    const updatedState = newState === 'Selected' ? 'Unselected' : 'Selected';
    setNewState(updatedState);

    if (onCheckBoxItemClicked) {
      onCheckBoxItemClicked(updatedState);
    }
  };
  return {
    ...props,
    state: newState,
    onClick: handleClick,
  };
};

export default usePresenter;

import { useEffect, useRef } from 'react';
import { SearchReturnCombinedProps } from './types';

const usePresenter = (props: SearchReturnCombinedProps): SearchReturnCombinedProps => {
  const { isModalOpen } = props;
  const searchInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isModalOpen) {
      searchInputRef.current?.focus();
    }
  }, [isModalOpen]);

  return {
    ...props,
    searchModalHeader: {
      state: 'Default',
      searchField: {
        ...props?.searchModalHeader?.searchField,
        textInput: {
          ...props?.searchModalHeader?.searchField?.textInput,
          inputRef: searchInputRef,
        },
      },
      closeButton: {
        ...props.searchModalHeader?.closeButton,
      },
    },
  };
};

export default usePresenter;

import React from 'react';
import cx from 'classnames';

import ButtonPair from '../ButtonPair';

import styles from './ButtonPairList.module.scss';
import usePresenter from './ButtonPairList.presenter';
import getProps from './ButtonPairList.props';
import { ButtonPairListCombinedProps } from './types';


const ButtonPairList: React.FC<ButtonPairListCombinedProps> = (props) => {
  const {
    className,
    classes,
    buttonPairs,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.buttonPairList;

  const buttonPairViews = buttonPairs?.map((buttonPair, index) => (
    <ButtonPair
      key={index}
      className={cx(styles.buttonPair, classes?.buttonPair)}
      {...internalProps.buttonPair}
      {...buttonPair} />
  )) || [];

  return (
    <div className={cx(currentStyle, className)}>
      {buttonPairViews}
    </div>
  );
};

export default ButtonPairList;


import React from 'react';
import cx from 'classnames';

import Divider from '../../atoms/Divider';
import SportsMenuTabList from '../../molecules/SportsMenuTabList';
import SubmenuItemList from '../../molecules/SubmenuItemList';

import styles from './SubmenuWithTabs.module.scss';
import usePresenter from './SubmenuWithTabs.presenter';
import getProps from './SubmenuWithTabs.props';
import { SubmenuWithTabsCombinedProps } from './types';


const SubmenuWithTabs: React.FC<SubmenuWithTabsCombinedProps> = (props) => {
  const {
    className,
    classes,
    divider,
    sportsMenuTabList,
    divider1,
    submenuItemList, // MANUAL OVERRIDE
    divider2,
    // MANUAL OVERRIDE STARTS
    selectLeague,
    hoveredLeague,
    isOpen,
  // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.submenuWithTabs;

  return (
    <div className={cx(currentStyle, className)}>
      <Divider
        className={cx(styles.divider, classes?.divider)}
        {...internalProps.divider}
        {...divider}/>
      <div className={cx(styles.content, classes?.content)}>
        <div className={cx(styles.leftContent, classes?.leftContent)}>
          <SportsMenuTabList
            activeTab={hoveredLeague} // MANUAL OVERRIDE
            setHoveredLeague={selectLeague} // MANUAL OVERRIDE
            focusable={isOpen} // MANUAL OVERRIDE
            className={cx(styles.sportsMenuTabList, classes?.sportsMenuTabList)}
            {...internalProps.sportsMenuTabList}
            {...sportsMenuTabList}/>
        </div>
        <Divider
          className={cx(styles.divider1, classes?.divider1)}
          {...internalProps.divider1}
          {...divider1}/>
        <div className={cx(styles.rightContent, classes?.rightContent)}>
          {/* MANUAL OVERRIDE STARTS */}
          <SubmenuItemList
            className={cx(styles.submenuItemList, classes?.submenuItemList)}
            {...internalProps.submenuItemList}
            {...submenuItemList}/>
          {/* MANUAL OVERRIDE ENDS */}
        </div>
      </div>
      <Divider
        className={cx(styles.divider2, classes?.divider2)}
        {...internalProps.divider2}
        {...divider2}/>
    </div>
  );
};

export default SubmenuWithTabs;


import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import Text from '../../atoms/Text';
import Filter from '../Filter';

import styles from './SearchResult.module.scss';
import usePresenter from './SearchResult.presenter';
import getProps from './SearchResult.props';
import { SearchResultCombinedProps } from './types';


const SearchResult: React.FC<SearchResultCombinedProps> = (props) => {
  const {
    className,
    classes,
    label,
    searchedValue,
    numberOfMatches,
    eventsMatchString,
    recommendationString,
    button,
    filter,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.searchResult;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.title, classes?.title)}>
        <Text
          className={cx(styles.label, classes?.label)}
          {...internalProps.label}
          {...label}/>
        <Text
          className={cx(styles.searchedValue, classes?.searchedValue)}
          {...internalProps.searchedValue}
          {...searchedValue}/>
      </div>
      <div className={cx(styles.bottomContent, classes?.bottomContent)}>
        <div className={cx(styles.numberOfSearchMatches, classes?.numberOfSearchMatches)}>
          <Text
            className={cx(styles.numberOfMatches, classes?.numberOfMatches)}
            {...internalProps.numberOfMatches}
            {...numberOfMatches}/>
          <Text
            className={cx(styles.eventsMatchString, classes?.eventsMatchString)}
            {...internalProps.eventsMatchString}
            {...eventsMatchString}/>
        </div>
        <div className={cx(styles.content, classes?.content)}>
          {/* MANUAL OVERRIDE - removed recommendationContent */}
          <Filter
            className={cx(styles.filter, classes?.filter)}
            {...internalProps.filter}
            {...filter}/>
        </div>
      </div>
    </div>
  );
};

export default SearchResult;


import { TFunction } from 'react-i18next';
import { CP1_EXCLUSIVE_TAG, TAYLOR_SWIFT_PERFORMER_ID } from '../../../lib/constants';
import customStyles from './Custom.module.scss';
import { formatDate, handleVariableImage, DateFormats, handleLocationShort, formatDateToMonthDay, getWeekdayAndTime, getEventStatus, handleLocation, handleEventCardDateFormat, removeHtmlTags, checkSoldOut, formatDateToApiDate } from '../../../lib/util';
import { Event, Performer } from '../../../modules/partnership';
import { EventCardDarkCombinedProps } from '../../molecules/EventCardDark';
import { EventCardLightCombinedProps } from '../../molecules/EventCardLight';
import { EventCardNoImageCombinedProps } from '../../molecules/EventCardNoImage';
import { EventCardVenueCombinedProps } from '../../molecules/EventCardVenue';
import { PerformerCardCombinedProps } from '../../molecules/PerformerCard';
import { SpecialEventCardCombinedProps } from '../../molecules/SpecialEventCard';
import { DEFAULT_PAGE_SIZE } from '../../pages/Homepage/constants';
import { matchesTaylorSwift } from '../../../modules/partnership/utils';
import { today } from '../../../modules/date/utils';
import { EventKeys } from '../../../modules/analytics/types';

const isExclusiveEvent = (event: Event) => {
  return event.tags?.includes(CP1_EXCLUSIVE_TAG);
};

export const mapEventsAsCardSpecificDark = (
  events: Event[],
  t: TFunction,
  trackCardEvents: (index: number, eventName: string) => void,
): SpecialEventCardCombinedProps[] => {
  return events.map((item, eventIndex): SpecialEventCardCombinedProps => {
    const isCp1Exclusive = isExclusiveEvent(item);
    const eventStatus = getEventStatus(item, t, isCp1Exclusive);
    const isSoldOut = checkSoldOut(item, isCp1Exclusive);
    return {
      className: customStyles.snapItemContainer,
      image: {
        imageSrc: item && handleVariableImage(item, 'modal', 'mobile'),
        overlay: 'linear-gradient(246.2deg, rgba(0, 66, 106, 0.75) 0%, rgba(0, 19, 43, 0) 51.56%)',
        position: 'center',
      },
      eventTypeTag: isCp1Exclusive ? {
        label: {
          value: t('homepage.cardHolderExclusive'),
        },
      } : undefined,
      eventStatusTag: {
        label: {
          colour: eventStatus.statusColor || 'Positive',
          value: eventStatus.statusLabel,
        },
      },
      titleText: {
        value: item.name,
      },
      descriptionText: {
        value: removeHtmlTags(item.supplemental_data?.event_description),
      },
      dateAndTime: {
        icon: {
          asset: 'CalenderNew',
          style: 'SubduedDark',
        },
        text: {
          type: 'Body',
          size: 'Medium',
          style: 'Regular',
          colour: 'SubduedDark',
          align: 'Left',
          value: handleEventCardDateFormat(new Date(item.local_date)),
        },
      },
      location: {
        icon: {
          asset: 'LocationPinFilled',
          style: 'SubduedDark',
        },
        text: {
          type: 'Body',
          size: 'Medium',
          style: 'Regular',
          colour: 'SubduedDark',
          align: 'Left',
          value: handleLocationShort(item.venue),
        },
      },
      actionText: {
        value: isSoldOut ? t('eventCard.learnMore') : t('eventCard.getTicketsButton'),
      },
      href: `/events/${item.id}`,
      onClick: () => {
        trackCardEvents(eventIndex, item.name);
      },
    };
  });
};

export const mapEventsAsCardDark = (
  events: Event[],
  t: TFunction,
  trackCardEvents: (index: number, eventName: string) => void,
): EventCardDarkCombinedProps[] => {
  return events.map((item, eventIndex): EventCardDarkCombinedProps => {
    const isCp1Exclusive = isExclusiveEvent(item);
    const eventStatus = getEventStatus(item, t, isCp1Exclusive);
    const isSoldOut = checkSoldOut(item, isCp1Exclusive);
    return {
      className: customStyles.snapItemContainer,
      image: {
        imageSrc: item && handleVariableImage(item, 'modal', 'mobile'),
        overlay: 'linear-gradient(246.2deg, rgba(0, 66, 106, 0.75) 0%, rgba(0, 19, 43, 0) 51.56%)',
        position: 'center',
      },
      displayEventTypeTag: isCp1Exclusive,
      eventTypeTag: isCp1Exclusive ? {
        label: {
          value: t('homepage.cardHolderExclusive'),
        },
      } : undefined,
      displayEventStatusTag: isCp1Exclusive,
      eventStatusTag: {
        label: {
          colour: eventStatus.statusColor || 'Positive',
          value: eventStatus.statusLabel,
        },
      },
      titleText: {
        className: customStyles.titleText,
        value: item.name,
      },
      dateAndTimeText: {
        className: customStyles.dateAndTimeText,
        value: handleEventCardDateFormat(new Date(item.local_date)),
      },
      locationAndPriceText: {
        className: customStyles.locationAndPriceText,
        value: handleLocation(item.venue),
      },
      actionText: {
        value: isSoldOut ? t('eventCard.learnMore') : t('eventCard.getTicketsButton'),
      },
      href: `/events/${item.id}`,
      onClick: () => {
        trackCardEvents(eventIndex, item.name);
      },
    };
  });
};

export const mapEventsAsCardLight = (
  events: Event[],
  t: TFunction,
  trackCardEvents: (index: number, eventName: string) => void,
): EventCardLightCombinedProps[] => {
  return events.map((item, eventIndex): EventCardLightCombinedProps => {
    const isCp1Exclusive = isExclusiveEvent(item);
    const eventStatus = getEventStatus(item, t, isCp1Exclusive);
    const isSoldOut = checkSoldOut(item, isCp1Exclusive);
    return {
      className: customStyles.snapItemContainer,
      image: {
        imageSrc: item && handleVariableImage(item, 'modal', 'mobile'),
        position: 'center',
      },
      displayEventTypeTag: isCp1Exclusive,
      eventTypeTag: isCp1Exclusive ? {
        label: {
          value: 'CARDHOLDER EXCLUSIVE',
        },
      } : undefined,
      displayEventStatusTag: isCp1Exclusive,
      eventStatusTag: {
        label: {
          colour: eventStatus.statusColor || 'Positive',
          value: eventStatus.statusLabel,
        },
      },
      titleText: {
        className: customStyles.titleText,
        value: item.name,
      },
      dateAndTimeText: {
        className: customStyles.dateAndTimeText,
        value: handleEventCardDateFormat(new Date(item.local_date)),
      },
      locationAndPriceText: {
        className: customStyles.locationAndPriceText,
        value: handleLocation(item.venue),
      },
      actionText: {
        colour: 'ActionBase',
        value: isSoldOut ? t('eventCard.learnMore') : t('eventCard.getTicketsButton'),
      },
      href: `/events/${item.id}`,
      onClick: () => {
        trackCardEvents(eventIndex, item.name);
      },
    };
  });
};

export const mapEventsAsCardNoImage = (
  events: Event[],
  t: TFunction,
  redirectTo: (link: string) => void,
  trackCardEvents: (index: number, eventName: string) => void,
  isDesktop?: boolean,
): EventCardNoImageCombinedProps[] => {
  return events.map((item, eventIndex): EventCardNoImageCombinedProps => {
    const redirectToEvent = () => {
      trackCardEvents(eventIndex, item.name);
      redirectTo(`/events/${item.id}`);
    };
    const localDate = new Date(item.local_date);
    const isCp1Exclusive = isExclusiveEvent(item);
    const eventStatus = getEventStatus(item, t, isCp1Exclusive);
    const isSoldOut = checkSoldOut(item, isCp1Exclusive);
    const formattedDateTime = formatDate(localDate, t, DateFormats.TICKETINFO);
    const formattedDate = formatDateToMonthDay(localDate);
    const weekDayAndTime = getWeekdayAndTime(localDate);
    const locationPriceText = handleLocationShort(item.venue);
    const actionText = isSoldOut ? t('eventCard.learnMore') : t('eventCard.getTicketsButton');
    const buttonMessageTranslationKey = isSoldOut ? 'learnMoreMessage' : 'getTicketsMessage';
    const buttonMessage = t(`eventCard.${buttonMessageTranslationKey}`, {
      name: item.name,
      dateTime: formattedDateTime,
      location: locationPriceText,
    });
    const eventCardMessage = t('eventCard.eventCardNoImageMessage', {
      date: isDesktop ? formattedDate : '',
      weekDayTime: isDesktop ? weekDayAndTime : '',
      cardHolderExclusiveTag: isCp1Exclusive ? t('homepage.cardHolderExclusive') : '',
      eventStatusTag: isCp1Exclusive ? eventStatus.statusLabel || '' : '',
      eventName: item.name,
      dateTime: isDesktop ? '' : formattedDateTime,
      location: locationPriceText,
    });
    return {
      displayEventTypeTag: isCp1Exclusive,
      displayEventStatusTag: isCp1Exclusive,
      ariaLabel: isDesktop ? eventCardMessage : buttonMessage,
      dateText: {
        value: formattedDate,
      },
      text: {
        value: weekDayAndTime,
      },
      dateAndTimeText: {
        value: formattedDateTime,
        colour: isDesktop ? 'SubduedDark' : 'SubduedLight',
      },
      eventTypeTag: {
        label: {
          value: t('homepage.cardHolderExclusive'),
        },
      },
      eventStatusTag: {
        label: {
          value: eventStatus.statusLabel,
          colour: eventStatus.statusColor || 'Positive',
        },
      },
      titleText: {
        value: item.name,
      },
      locationAndPriceText: {
        className: customStyles.locationAndPriceText,
        value: locationPriceText,
        colour: 'SubduedLight',
      },
      button: {
        ariaLabel: buttonMessage,
        text: {
          value: actionText,
        },
        onClick: redirectToEvent,
      },
      actionText: {
        value: actionText,
      },
      onClick: redirectToEvent,
    };
  });
};

export const mapEventsAsCardVenue = (
  events: Event[],
  t: TFunction,
  redirectTo: (link: string) => void,
  trackCardEvents: (index: number, eventName: string) => void,
  isDesktop: boolean,
): EventCardVenueCombinedProps[] => {
  return events.map((item, eventIndex): EventCardVenueCombinedProps => {
    const link = `/events/${item.id}`;
    const redirectToEvent = () => {
      trackCardEvents(eventIndex, item.name);
      redirectTo(link);
    };
    const localDate = new Date(item.local_date);
    const isCp1Exclusive = isExclusiveEvent(item);
    const eventStatus = getEventStatus(item, t, isCp1Exclusive);
    const isSoldOut = checkSoldOut(item, isCp1Exclusive);
    const formattedDateTime = formatDate(localDate, t, DateFormats.TICKETINFO);
    const locationPriceText = handleLocationShort(item.venue);
    const actionText = isSoldOut ? t('eventCard.learnMore') : t('eventCard.getTicketsButton');
    const buttonMessageTranslationKey = isSoldOut ? 'learnMoreMessage' : 'getTicketsMessage';
    const buttonMessage = t(`eventCard.${buttonMessageTranslationKey}`, {
      name: item.name,
      dateTime: formattedDateTime,
      venue: item.venue.name,
      location: locationPriceText,
    });
    const eventCardMessage = t('eventCard.eventCardVenueMessage', {
      cardHolderExclusiveTag: isCp1Exclusive ? t('homepage.cardHolderExclusive') : '',
      eventStatusTag: isCp1Exclusive ? eventStatus.statusLabel : '',
      eventName: item.name,
      dateTime: formattedDateTime,
      venue: item.venue.name || '',
      location: locationPriceText,
    });
    return {
      ariaLabel: isDesktop ? eventCardMessage : buttonMessage,
      displayEventStatusTag: isCp1Exclusive,
      displayEventTypeTag: isCp1Exclusive,
      image: {
        imageSrc: item && handleVariableImage(item, 'modal', 'mobile'),
        position: 'center',
      },
      eventTypeTag: {
        label: {
          value: t('homepage.cardHolderExclusive'),
        },
      },
      eventStatusTag: {
        label: {
          value: eventStatus.statusLabel,
          colour: eventStatus.statusColor || 'Positive',
        },
      },
      titleText: {
        value: item.name,
      },
      dateAndTimeText: {
        value: formattedDateTime,
      },
      locationAndPriceText: {
        value: locationPriceText,
      },
      button: {
        ariaLabel: buttonMessage,
        text: {
          value: actionText,
        },
        onClick: redirectToEvent,
      },
      actionText: {
        value: actionText,
      },
      href: link,
      onClick: redirectToEvent,
    };
  });
};

export const mapEventsAsCardPerformer = (
  performers: Performer[],
  t: TFunction,
  trackCardEvents: (index: number, eventName: string) => void,
): PerformerCardCombinedProps[] => {
  return performers.map((item, eventIndex): PerformerCardCombinedProps => ({
    className: customStyles.snapItemContainer,
    style: 'Light',
    state: 'Default',
    image: {
      imageSrc: item.image,
      position: 'center',
    },
    titleText: {
      className: customStyles.performerTitleText,
      value: item.name,
    },
    href: `/performers/${item.id}`,
    onClick: () => {
      trackCardEvents(eventIndex, item.name);
    },
  }));
};

export const isTheSameObject = <T extends {}>(obj1: T, obj2: T): boolean => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  return true;
};

const isPerformerTaylorSwift = (performer: Performer) => {
  const { id, name } = performer;
  return id === TAYLOR_SWIFT_PERFORMER_ID || matchesTaylorSwift(name);
};

export const filterTaylorSwiftFromTopPerformers = (performers: Performer[]): Performer[] => {
  // filter taylor swift
  const taylorSwiftFilteredOutPerformers = performers.filter((performer) => !isPerformerTaylorSwift(performer));
  if (taylorSwiftFilteredOutPerformers.length > DEFAULT_PAGE_SIZE) {
    // return only first 24 performers when no performer is filtered out
    return taylorSwiftFilteredOutPerformers.filter((performer, index) => index < DEFAULT_PAGE_SIZE);
  }
  return taylorSwiftFilteredOutPerformers;
};

export const defaultDateToTodayIfEmpty = (date_start?: string): string => {
  if (date_start === undefined || !date_start) {
    return formatDateToApiDate(today());
  }
  return date_start;
};

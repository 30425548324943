import React from 'react';
import cx from 'classnames';

import EventCardVenue from '../../molecules/EventCardVenue';

import styles from './EventCardVenueList.module.scss';
import usePresenter from './EventCardVenueList.presenter';
import getProps from './EventCardVenueList.props';
import { EventCardVenueListCombinedProps } from './types';


const EventCardVenueList: React.FC<EventCardVenueListCombinedProps> = (props) => {
  const {
    className,
    classes,
    eventCardVenues,
    listElementRef, // MANUAL OVERRIDE
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.eventCardVenueList;

  const eventCardVenueViews = eventCardVenues?.map((eventCardVenue, index) => (
    <EventCardVenue
      key={index}
      className={cx(styles.eventCardVenue, classes?.eventCardVenue)}
      {...internalProps.eventCardVenue}
      {...eventCardVenue} />
  )) || [];

  return (
    // MANUAL OVERRIDE - added ref
    <div className={cx(currentStyle, className)} ref={listElementRef}>
      {eventCardVenueViews}
    </div>
  );
};

export default EventCardVenueList;


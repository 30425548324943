import { DividerProps } from '../../atoms/Divider';
import { TextProps } from '../../atoms/Text';


type SubmenuItemInternalProps = {
  text?: TextProps;
  divider?: DividerProps;
};

type SubmenuItemPropsValues = {
  '': SubmenuItemInternalProps;
};

const propValues: SubmenuItemPropsValues = {
  '': {
    text: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
    divider: {
      type: 'Vertical',
      style: 'Thick',
      colour: 'CoreBlue70',
    },
  },
};

const getProps = (type: string): SubmenuItemInternalProps => {
  const values: SubmenuItemInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

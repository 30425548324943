import { useTranslation } from 'react-i18next';
import useInteractor from './VenueLandingPage.interactor';
import { VenueLandingPageCombinedProps } from './types';
import { useEffect } from 'react';
import { CONTENT_ID } from '../../../lib/constants';
import { useAnalyticsManager } from '../../../modules/analytics/useAnalyticsManager';
import { trackPageViewEvent } from '../../../modules/analytics/util';

const usePresenter = (props: VenueLandingPageCombinedProps): VenueLandingPageCombinedProps => {
  const { venue, venueId, categories, fetchVenue } = useInteractor(props);
  const { t } = useTranslation();

  const { trackEvent } = useAnalyticsManager();

  const pageName = 'Venue Landing Page';
  useEffect(() => {
    if (venue) {
      trackPageViewEvent(
        trackEvent,
        'Venue',
        {
          venue: venue?.name,
          venue_id: venueId,
          venue_city: venue?.city,
          venue_state: venue?.state,
        },
      );
    }
  }, [venue]);

  useEffect(() => {
    if (fetchVenue && venueId) {
      void fetchVenue(venueId);
    }
  }, [venueId, fetchVenue]);

  useEffect(() => {
    trackEvent('view_item_list', {
      ecommerce: {
        item_list_name: 'venue_landing_page_View',
      },
    });
  }, []);
  return {
    ...props,
    imageBlock: {
      performerPageBanner: {
        imageSrc: venue?.image,
        title: {
          value: venue?.name,
          id: CONTENT_ID,
        },
      },
    },
    filtersBlock: {
      filterOne: {
        type: 'CategoryFilter',
        optionFilterList: categories,
        defaultOption: categories ? categories[0] : undefined,
      },
      filterTwo: {
        defaultDateOption: {
          title: t('dateFilters.any'),
          type: 'Default',
        },
        type: 'DateFilter',
      },
    },
    eventCardsBlock: {
      defaultParams: {
        venue_id: Number(venueId),
      },
      pageName,
      pageCarouselIndex: 1,
    },
  };
};

export default usePresenter;

import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import Divider from '../../atoms/Divider';
import Text from '../../atoms/Text';

import styles from './InstructionLabel.module.scss';
import usePresenter from './InstructionLabel.presenter';
import getProps from './InstructionLabel.props';
import { InstructionLabelCombinedProps } from './types';


const InstructionLabel: React.FC<InstructionLabelCombinedProps> = (props) => {
  const {
    size,
    type,
    style,
    className,
    classes,
    text,
    divider,
    button,
    divider1,
  } = usePresenter(props);

  const variantName = `${size}${type}${style}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`instructionLabel${variantName}`];

  let componentView;

  switch (variantName) {
    case 'LargeDefaultSubduedBG':
    case 'LargeDefaultWhiteBG':
    case 'MediumDefaultWhiteBG': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.content, classes?.content)}>
            <Text
              className={cx(styles.text, classes?.text)}
              {...internalProps.text}
              {...text}/>
          </div>
          <Divider
            className={cx(styles.divider, classes?.divider)}
            {...internalProps.divider}
            {...divider}/>
        </div>
      );
      break;
    }
    case 'MediumWithIconWhiteBG': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Divider
            className={cx(styles.divider, classes?.divider)}
            {...internalProps.divider}
            {...divider}/>
          <div className={cx(styles.content, classes?.content)}>
            <Text
              className={cx(styles.text, classes?.text)}
              {...internalProps.text}
              {...text}/>
            <Button
              className={cx(styles.button, classes?.button)}
              {...internalProps.button}
              {...button}/>
          </div>
          <Divider
            className={cx(styles.divider1, classes?.divider1)}
            {...internalProps.divider1}
            {...divider1}/>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default InstructionLabel;


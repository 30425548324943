import { InstructionLabelProps } from '../../molecules/InstructionLabel';
import { SelectTicketProps } from '../../organisms/SelectTicket';
import { TicketListProps } from '../../organisms/TicketList';


type SeatSelectionBlockInternalProps = {
  selectTicket?: SelectTicketProps;
  instructionLabel?: InstructionLabelProps;
  ticketList?: TicketListProps;
};

type SeatSelectionBlockPropsValues = {
  '': SeatSelectionBlockInternalProps;
};

const propValues: SeatSelectionBlockPropsValues = {
  '': {
    selectTicket: {
    },
    instructionLabel: {
      size: 'Medium',
      type: 'WithIcon',
      style: 'WhiteBG',
    },
    ticketList: {
    },
  },
};

const getProps = (type: string): SeatSelectionBlockInternalProps => {
  const values: SeatSelectionBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

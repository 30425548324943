import React from 'react';
import cx from 'classnames';
import { OverlayTrigger, Tooltip as BootstrapToolTip } from 'react-bootstrap';
import './bootstrap.scss';

import Text from '../../atoms/Text';

import styles from './ToolTip.module.scss';
import usePresenter from './ToolTip.presenter';
import getProps from './ToolTip.props';
import { ToolTipCombinedProps } from './types';


const ToolTip: React.FC<ToolTipCombinedProps> = (props) => {
  const {
    className,
    classes,
    text,
    // MANUAL OVERRIDE STARTS
    placement,
    children,    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.toolTip;

  // MANUAL OVERRIDE STARTS
  const overlayView = (
    <BootstrapToolTip
      id=''
      {...props}
    >
      {text}
    </BootstrapToolTip>
  );

  return (
    <OverlayTrigger
      key="bottom"
      placement={placement}
      transition={false}
      overlay={overlayView}
    >
      <div {...props}>{children}</div>
    </OverlayTrigger>
  );
  // MANUAL OVERRIDE ENDS
};

export default ToolTip;


import { useCallback, useEffect, useState } from 'react';
import useLocalStorage from 'react-use-localstorage';

const useRecentlyViewedEvents = (): {
  recentEventIds: number[];
  addRecentEvent: (eventId: number) => void;
  removeRecentEvent: (eventId: number) => void;
} => {
  const [storageItem, setStorageItem] = useLocalStorage('recentlyViewedEventIds', '[]');
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const [eventIds, updateEventIds] = useState<number[]>(JSON.parse(storageItem));

  useEffect(() => {
    const ids = JSON.parse(storageItem);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    updateEventIds(ids);
  }, [storageItem]);

  const setEventIds = useCallback((newEventIds: number[]) => {
    updateEventIds(newEventIds);
    setStorageItem(JSON.stringify(newEventIds));
  }, [setStorageItem]);

  const addRecentEvent = useCallback((eventId: number) => {        
    if (!eventIds.find((item) => item === eventId)) {            
      const newEventIds = [eventId, ...eventIds];
      if (newEventIds.length > 3) {
        newEventIds.pop();
      }
      setEventIds(newEventIds);
    } else {
      const filteredEventIds = eventIds.filter(item=> item !== eventId);
      const newEventIds = [eventId, ...filteredEventIds];
      setEventIds(newEventIds);
    }
  }, [eventIds, setEventIds]);

  const removeRecentEvent = useCallback((eventId: number) => {
    const newEventIds = eventIds.filter((item) => eventId !== item);
    setEventIds(newEventIds);
  }, [eventIds, setEventIds]);

  return { recentEventIds: eventIds, addRecentEvent, removeRecentEvent };
};

export default useRecentlyViewedEvents;

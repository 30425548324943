import { useTranslation } from 'react-i18next';
import { SubmenuCombinedProps } from './types';
import { ButtonCombinedProps } from '../../atoms/Button';
import { useAnalyticsManager } from '../../../modules/analytics/useAnalyticsManager';

const usePresenter = (props: SubmenuCombinedProps): SubmenuCombinedProps => {
  const { data, onItemClicked } = props;
  const { t } = useTranslation();
  const { trackEvent } = useAnalyticsManager();

  const exclusiveObject = data?.find(
    (exclusive) => exclusive.id === 'exclusive',
  );

  const exclusiveItems: ButtonCombinedProps[] | undefined =
  exclusiveObject?.children.map((event): ButtonCombinedProps => {
    const exclusivesCheck = event.slug === '/exclusive-events';
    return {
      type: 'Text',
      ariaLabel:
        t('submenu.screenReaderSubMenuText', {
          subCategoryName: event.name,
        }) || undefined,
      style: exclusivesCheck ? 'Outline' : 'TextWhite',
      size: 'Small',
      text: {
        size: 'Medium',
        value: event.name,
      },
      newTab: event.url ? true : false,
      href: event.slug || event.url,
      onClick: () => {
        if (event.slug?.startsWith('/exclusive-events')) {
          trackEvent('select_content_c1x_navigation-menu');
        }
        onItemClicked?.();
      },
    };
  });

  return {
    submenuList: {
      buttons: exclusiveItems,
    },
  };
};

export default usePresenter;

import { ButtonProps } from '../../atoms/Button';


type ButtonPairInternalProps = {
  primary?: ButtonProps;
  secondary?: ButtonProps;
};

type ButtonPairPropsValues = {
  '': ButtonPairInternalProps;
};

const propValues: ButtonPairPropsValues = {
  '': {
    primary: {
      type: 'Text',
      style: 'ContainedWhite',
      size: 'Medium',
    },
    secondary: {
      type: 'Icon',
      style: 'Text',
      size: 'Medium',
    },
  },
};

const getProps = (type: string): ButtonPairInternalProps => {
  const values: ButtonPairInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

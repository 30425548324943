import React from 'react';
import cx from 'classnames';

import SearchContainer from '../../organisms/SearchContainer';

import styles from './SearchBlock.module.scss';
import usePresenter from './SearchBlock.presenter';
import getProps from './SearchBlock.props';
import { SearchBlockCombinedProps } from './types';
import SearchResultsBlock from '../SearchResultsBlock';
import NavMenuList from '../../organisms/NavMenuList';


const SearchBlock: React.FC<SearchBlockCombinedProps> = (props) => {
  const {
    className,
    classes,
    searchContainer,
    // MANUAL OVERRIDE STARTS
    showResults,
    closeSearchBlock,
    openSearchBlock,
    navMenuList,
    searchResult,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.searchBlock;

  const resultView = showResults ? (
    <SearchResultsBlock {...searchResult} />
  ) : null;

  const navMenuView = navMenuList && (
    <NavMenuList {...navMenuList} />
  );

  return (
    <div
      className={cx(currentStyle, className)}
      onFocus={openSearchBlock}
      onBlur={(e) => {
        if (!e.currentTarget.contains(e.relatedTarget) && closeSearchBlock) {
          closeSearchBlock();
        }
      }}
    >
      <SearchContainer
        className={cx(styles.searchContainer, classes?.searchContainer)}
        {...internalProps.searchContainer}
        {...searchContainer}/>
      {navMenuView}
      {resultView}
    </div>
  );
};

export default SearchBlock;


import React from 'react';
import cx from 'classnames';

import SpecialEventCard from '../../molecules/SpecialEventCard';

import styles from './CardSpecificEventsList.module.scss';
import usePresenter from './CardSpecificEventsList.presenter';
import getProps from './CardSpecificEventsList.props';
import { CardSpecificEventsListCombinedProps } from './types';


const CardSpecificEventsList: React.FC<CardSpecificEventsListCombinedProps> = (props) => {
  const {
    className,
    classes,
    specialEventCards,
    listElementRef, // MANUAL OVERRIDE
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.cardSpecificEventsList;

  const specialEventCardViews = specialEventCards?.map((specialEventCard, index) => (
    <SpecialEventCard
      key={index}
      index={index} // MANUAL OVERRIDE
      className={cx(styles.specialEventCard, classes?.specialEventCard)}
      {...internalProps.specialEventCard}
      {...specialEventCard} />
  )) || [];

  return (
    // MANUAL OVERRIDE - added ref
    <div ref={listElementRef} className={cx(currentStyle, className)}>
      {specialEventCardViews}
    </div>
  );
};

export default CardSpecificEventsList;


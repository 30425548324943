import { SubmenuTabItemProps } from '../SubmenuTabItem';


type SportsMenuTabListInternalProps = {
  submenuTabItem?: SubmenuTabItemProps;
};

type SportsMenuTabListPropsValues = {
  '': SportsMenuTabListInternalProps;
};

const propValues: SportsMenuTabListPropsValues = {
  '': {
    submenuTabItem: {
      state: 'Default',
    },
  },
};

const getProps = (type: string): SportsMenuTabListInternalProps => {
  const values: SportsMenuTabListInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import React from 'react';
import cx from 'classnames';

import styles from './MaintenancePage.module.scss';

import MaintenanceBlock from '../../blocks/MaintenanceBlock';
import usePresenter from './MaintenancePage.presenter';
import { MaintenancePageCombinedProps } from './types';
import getProps from './MaintenancePage.props';

const MaintenancePage: React.FC<MaintenancePageCombinedProps> = (props) => {
  const {
    className,
    classes,
    maintenanceBlock,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.maintenancePage;
  return (
    <div className={cx(currentStyle, className)}>
      <div className={styles.container}>
        <MaintenanceBlock
          className={cx(styles.maintenanceBlock, classes?.maintenanceBlock)}
          {...internalProps.maintenanceBlock}
          {...maintenanceBlock} />
      </div>
    </div>
  );
};

export default MaintenancePage;



type DividerInternalProps = {
};

type DividerPropsValues = {
  'HorizontalThickWhite': DividerInternalProps;
  'HorizontalThinCP1Red': DividerInternalProps;
  'HorizontalThinDigitalGrey10': DividerInternalProps;
  'HorizontalThinDigitalGrey15': DividerInternalProps;
  'HorizontalThinDigitalGrey5': DividerInternalProps;
  'HorizontalThinWhite': DividerInternalProps;
  'VerticalThickCP1Red': DividerInternalProps;
  'VerticalThickClear': DividerInternalProps;
  'VerticalThickInteractionBlue50': DividerInternalProps;
  'VerticalThickWhite': DividerInternalProps;
  'VerticalThinDigitalGrey10': DividerInternalProps;
  'VerticalThinDigitalGrey15': DividerInternalProps;
  'VerticalThinWhite': DividerInternalProps;
};

const propValues: DividerPropsValues = {
  'HorizontalThickWhite': {
  },
  'HorizontalThinCP1Red': {
  },
  'HorizontalThinDigitalGrey10': {
  },
  'HorizontalThinDigitalGrey15': {
  },
  'HorizontalThinDigitalGrey5': {
  },
  'HorizontalThinWhite': {
  },
  'VerticalThickCP1Red': {
  },
  'VerticalThickClear': {
  },
  'VerticalThickInteractionBlue50': {
  },
  'VerticalThickWhite': {
  },
  'VerticalThinDigitalGrey10': {
  },
  'VerticalThinDigitalGrey15': {
  },
  'VerticalThinWhite': {
  },
};

const getProps = (type: string): DividerInternalProps => {
  const values: DividerInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import cx from 'classnames';

import customStyles from './Custom.module.scss';
import { SearchFieldCombinedProps } from './types';

const usePresenter = (props: SearchFieldCombinedProps): SearchFieldCombinedProps => {
  return {
    ...props,
    className: cx(props.className, customStyles.customSearchField),
    classes: {
      textInput: cx(props.classes?.textInput, customStyles.customTextInput),
    },
    textInput: {
      ...props.textInput,
      classes: {
        button: customStyles.customButton,
      },
    },
  };
};

export default usePresenter;

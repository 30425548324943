import { TextProps } from '../../atoms/Text';
import { ButtonProps } from '../../atoms/Button';
import { CheckBoxItemProps } from '../../atoms/CheckBoxItem';
import { TextFieldsProps } from '../../molecules/TextFields';
import { DropdownProps } from '../../molecules/Dropdown';
import { HighlightMessageProps } from '../../molecules/HighlightMessage';


type ShippingInfoBlockInternalProps = {
  blockTitle?: TextProps;
  highlightMessage?: HighlightMessageProps;
  checkBoxItem?: CheckBoxItemProps;
  firstName?: TextFieldsProps;
  lastName?: TextFieldsProps;
  address?: TextFieldsProps;
  secondaryAddress?: TextFieldsProps;
  city?: TextFieldsProps;
  state?: DropdownProps;
  postalCode?: TextFieldsProps;
  country?: DropdownProps;
  phoneNumber?: TextFieldsProps;
  button?: ButtonProps;
};

type ShippingInfoBlockPropsValues = {
  '': ShippingInfoBlockInternalProps;
};

const propValues: ShippingInfoBlockPropsValues = {
  '': {
    blockTitle: {
      type: 'Subheading',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    highlightMessage: {
      type: 'Error',
    },
    checkBoxItem: {
      state: 'Unselected',
    },
    firstName: {
      state: 'Default',
    },
    lastName: {
      state: 'Default',
    },
    address: {
      state: 'Default',
    },
    secondaryAddress: {
      state: 'Default',
    },
    city: {
      state: 'Default',
    },
    state: {
    },
    postalCode: {
      state: 'Default',
    },
    country: {
    },
    phoneNumber: {
      state: 'Default',
    },
    button: {
      type: 'Text',
      style: 'Contained',
      size: 'Large',
    },
  },
};

const getProps = (type: string): ShippingInfoBlockInternalProps => {
  const values: ShippingInfoBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import { TextProps } from '../../atoms/Text';


type TextItemInternalProps = {
  label?: TextProps;
  description?: TextProps;
};

type TextItemPropsValues = {
  'BoldedLabel': TextItemInternalProps;
  'SubduedLabel': TextItemInternalProps;
};

const propValues: TextItemPropsValues = {
  'BoldedLabel': {
    label: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
    description: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
  },
  'SubduedLabel': {
    label: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    description: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
  },
};

const getProps = (type: string): TextItemInternalProps => {
  const values: TextItemInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import { ImageProps } from '../../atoms/Image';
import { TextProps } from '../../atoms/Text';
import { ButtonProps } from '../../atoms/Button';
import { DividerProps } from '../../atoms/Divider';


type AccountMenuInternalProps = {
  cardImage?: ImageProps;
  cardNumber?: TextProps;
  greetingFirstName?: TextProps;
  loyaltyPoints?: TextProps;
  divider?: DividerProps;
  expandButton?: ButtonProps;
  collapseButton?: ButtonProps;
  closeButton?: ButtonProps;
};

type AccountMenuPropsValues = {
  'Collapsed': AccountMenuInternalProps;
  'Expanded': AccountMenuInternalProps;
};

const propValues: AccountMenuPropsValues = {
  'Collapsed': {
    cardImage: {
    },
    cardNumber: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'BaseLight',
      align: 'Left',
    },
    greetingFirstName: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'BaseLight',
      align: 'Left',
    },
    loyaltyPoints: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'BaseLight',
      align: 'Left',
    },
    divider: {
      type: 'Vertical',
      style: 'Thin',
      colour: 'White',
    },
    expandButton: {
      type: 'Icon',
      style: 'Text',
      size: 'Small',
    },
  },
  'Expanded': {
    greetingFirstName: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'BaseLight',
      align: 'Left',
    },
    cardImage: {
    },
    cardNumber: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'BaseLight',
      align: 'Left',
    },
    divider: {
      type: 'Vertical',
      style: 'Thin',
      colour: 'White',
    },
    loyaltyPoints: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'BaseLight',
      align: 'Left',
    },
    collapseButton: {
      type: 'Icon',
      style: 'Text',
      size: 'Small',
    },
    closeButton: {
      type: 'Icon',
      style: 'Text',
      size: 'Small',
    },
  },
};

const getProps = (type: string): AccountMenuInternalProps => {
  const values: AccountMenuInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import React from 'react';
import { BraintreeProvider } from '../../../modules/braintree';
import CardInfoBlock from './CardInfoBlock';
import { CardInfoBlockCombinedProps, CardInfoBlockProps, CardInfoBlockValueProps } from './types';

export type { CardInfoBlockProps, CardInfoBlockValueProps };

const CardInfoBlockView: React.FC<CardInfoBlockCombinedProps> = (props) => {
  return (
    <BraintreeProvider>
      <CardInfoBlock {...props} />
    </BraintreeProvider>
  );
};

export default CardInfoBlockView;

import { PerformerPageBannerProps } from '../../organisms/PerformerPageBanner';


type ImageBlockInternalProps = {
  performerPageBanner?: PerformerPageBannerProps;
};

type ImageBlockPropsValues = {
  '': ImageBlockInternalProps;
};

const propValues: ImageBlockPropsValues = {
  '': {
    performerPageBanner: {
    },
  },
};

const getProps = (type: string): ImageBlockInternalProps => {
  const values: ImageBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import React from 'react';
import cx from 'classnames';

import Text from '../../atoms/Text';

import styles from './SubmenuTabItem.module.scss';
import usePresenter from './SubmenuTabItem.presenter';
import getProps from './SubmenuTabItem.props';
import { SubmenuTabItemCombinedProps } from './types';
import { getClickAccessibilityProps } from '../../../legacy/organisms/TopNav/util';


const SubmenuTabItem: React.FC<SubmenuTabItemCombinedProps> = (props) => {
  const {
    className,
    classes,
    text,
    // MANUAL OVERRIDE STARTS
    onMouseOver,
    linkPath,
    onItemClicked,
    handleTab,
    focusable = true,
    elementRef,
    role = 'submenuitem',
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const variantName = '';
  const internalProps = getProps(variantName);
  const currentStyle = styles[`submenuTabItem${variantName}`];

  const accessibilityProps = getClickAccessibilityProps({
    onClick: onItemClicked,
    onTab: (forward) => handleTab?.(forward),
  });

  let componentView;

  switch (variantName) {
    case '': {
      componentView = (
        // MANUAL OVERRIDE - replaced div with a tag
        <a
          href={linkPath}
          ref={elementRef}
          onClick={onItemClicked}
          tabIndex={focusable ? 0 : -1}
          onMouseOver={onMouseOver}
          className={cx(currentStyle, className)}
          {...accessibilityProps}
          role={role}
        >
          <Text
            className={cx(styles.text, classes?.text)}
            {...internalProps.text}
            {...text}/>
        </a>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default SubmenuTabItem;


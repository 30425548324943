import React from 'react';
import cx from 'classnames';

import Image from '../../atoms/Image';
import Text from '../../atoms/Text';

import styles from './PaymentMethod.module.scss';
import usePresenter from './PaymentMethod.presenter';
import getProps from './PaymentMethod.props';
import { PaymentMethodCombinedProps } from './types';


const PaymentMethod: React.FC<PaymentMethodCombinedProps> = (props) => {
  const {
    className,
    classes,
    label,
    cardImage,
    description,
    showCard = true, //MANUAL OVERRIDE
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.paymentMethod;
  // MANUAL OVERRIDE STARTS
  let card;
  if (showCard) {
    card = <Image
      className={cx(styles.cardImage, classes?.cardImage)}
      {...internalProps.cardImage}
      {...cardImage} />;
  }
  // MANUAL OVERRIDE ENDS
  return (
    <div className={cx(currentStyle, className)}>
      <Text
        className={cx(styles.label, classes?.label)}
        {...internalProps.label}
        {...label} />
      <div className={cx(styles.capitalOneCard, classes?.capitalOneCard)}>
        {/* MANUAL OVERRIDE */}
        {card}
        <Text
          className={cx(styles.description, classes?.description)}
          {...internalProps.description}
          {...description} />
      </div>
    </div>
  );
};

export default PaymentMethod;


import { useCallback, useContext, useEffect, useState } from 'react';
import { SeatSelectionPageCombinedProps } from './types';
import { FilterContext } from '../../../modules/filter';
import { ListingsContext } from '../../../modules/listings';
import { useMutation } from 'react-query';
import { ListingMutation } from './utils';
import { getListingDetails } from '../../../modules/partnership/api';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { getQueryParams, useWindowSize } from '../../../lib/util';
import { ListingDetails } from '../../../modules/partnership';
import { C1_BROKER_ID, CP1_EXCLUSIVE_TAG, MOBILE_BREAKPOINT, REWARDS_ONLY_TAG } from '../../../lib/constants';
import { ButtonCombinedProps } from '../../atoms/Button';
import { ToggleSwitchStateEnum } from '../../atoms/ToggleSwitch/types';
import { useAnalyticsManager } from '../../../modules/analytics/useAnalyticsManager';
import { trackErrorEvent, trackSelectContentEvent } from '../../../modules/analytics/util';
import { ApiError } from '../../../modules/error/types';

const usePresenter = (props: SeatSelectionPageCombinedProps): SeatSelectionPageCombinedProps => {
  const listingDetails = async (params: ListingMutation) => {
    const details = await getListingDetails(params.event_id, params.ticket_id, params.params);
    return details;
  };
  const { mutateAsync: fetchListing, isLoading } = useMutation(listingDetails);

  const {
    dispatch: {
      onFilterResetAction,
      onQuantityFilterChangeAction,
      onTypeFilterChangeAction,
    },
  } = useContext(FilterContext);
  const { handleChangeParam, handleReset, selectors, filterValue } = useContext(ListingsContext);

  const { trackEvent } = useAnalyticsManager();
  const { t } = useTranslation();
  const { eventId } = useParams<{ eventId: string }>();
  const { search, pathname } = useLocation();
  const history = useHistory();
  const { width } = useWindowSize();
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [ticketQuantity, setTicketQuantity] = useState(0);
  const [categoryToggle, setCategoryToggle] = useState<ToggleSwitchStateEnum>('Off');
  const [dualSliderValue, setDualSliderValue] = useState<number[]>([0, 0]);
  const [listing, setListing] = useState<ListingDetails>();
  const [apiParams, setApiParams] = useState<ListingMutation>({
    event_id: '',
    ticket_id: '',
    params: {
      quantity: 0,
    },
  });
  const isMobile = !!width && (width < MOBILE_BREAKPOINT);
  const isExclusive = props.event?.tags?.includes(CP1_EXCLUSIVE_TAG);
  const getPreSelectedQuantity = (): number => {
    const queryParams = getQueryParams(search);
    return Number(queryParams.quantity) || 0;
  };
  useEffect(() => {
    const queryParams = { ...getQueryParams(search) };
    if (Object.keys(queryParams).length !== 0) {
      setApiParams({
        event_id: eventId || '',
        ticket_id: queryParams.ticket_id || '',
        params: {
          quantity: Number(queryParams.quantity) || 0,
          exclusive_listings: queryParams.exclusive_listings === 'true',
        },
      });
    }
  }, [search, eventId]);
  const handleSliderMoved = (value: number | number[]) => {
    setDualSliderValue(value as number[]);
  };
  useEffect(() => {
    if (filterValue) {
      if (filterValue.quantity) {
        setTicketQuantity(filterValue?.quantity);
      }
      if (filterValue.min_price && filterValue.max_price) {
        handleSliderMoved([filterValue.min_price, filterValue.max_price]);
      }
    }
  }, [filterValue]);
  useEffect(() => {
    const doFetchListing = async (queryParams: ListingMutation) => {
      try {
        if (queryParams?.ticket_id) {
          const response = await fetchListing(queryParams);
          setListing(response);
        }
      } catch (error) {
        if (error && ApiError.isApiError(error)) {
          trackErrorEvent(
            trackEvent,
            error.code,
            error.message,
          );
        }
        // TODO
        setListing(undefined);
      }
    };
    void doFetchListing(apiParams);
  }, [apiParams, fetchListing]);
  useEffect(() => {
    if (history.location.search.length <= 0) {
      setListing(undefined);
    }
  }, [history.location]);
  useEffect(() => {
    const sliderValue: number[] = [0, 0];
    if (selectors.minPrice && selectors.minPrice >= 0) {
      sliderValue[0] = selectors.minPrice;
    }
    if (selectors.maxPrice && selectors.maxPrice >= 0) {
      sliderValue[1] = selectors.maxPrice;
    }
    setDualSliderValue(sliderValue);
  }, [selectors.maxPrice, selectors.minPrice]);

  const handleResetFilter = () => {
    trackSelectContentEvent(
      trackEvent,
      'Production',
      'Filter',
      t('eventPage.filterSetting.resetFilter'),
    );
    setTicketQuantity(0);
    setCategoryToggle('Off');
    const sliderValue = dualSliderValue;
    if (selectors.minPrice && selectors.minPrice >= 0) {
      sliderValue[0] = selectors.minPrice;
    }
    if (selectors.maxPrice && selectors.maxPrice >= 0) {
      sliderValue[1] = selectors.maxPrice;
    }
    setDualSliderValue(sliderValue);
    if (onFilterResetAction) {
      onFilterResetAction();
    }
    if (handleReset) {
      handleReset();
    }
  };
  const applyFilter: ButtonCombinedProps = {
    type: 'Text',
    size: 'Medium',
    style: 'Contained',
    text: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      value: t('eventPage.filterSetting.applyFilter'),
    },
    onClick: () => {
      trackSelectContentEvent(
        trackEvent,
        'Production',
        'Filter',
        t('eventPage.filterSetting.applyFilter'),
      );
      setFilterModalOpen(false);
    },
  };
  const resetFilter: ButtonCombinedProps = {
    type: 'Text',
    style: 'Text',
    size: 'Medium',
    text: {
      type: 'Body',
      size: 'Large',
      style: 'RegularUnderline',
      colour: 'ActionHover',
      value: t('eventPage.filterSetting.resetFilter'),
    },
    onClick: () => handleResetFilter(),
  };
  const minusButton = () => {
    trackSelectContentEvent(
      trackEvent,
      'Production',
      'Quantity Filter',
      t(
        'eventPage.filterSetting.decreaseTicketButtonScreenReaderText',
      ),
    );
    setTicketQuantity(ticketQuantity - 1);
    if (handleChangeParam) {
      handleChangeParam({ quantity: ticketQuantity - 1, event_id: String(props.event?.id) });
    }
    if (onQuantityFilterChangeAction) {
      onQuantityFilterChangeAction({ quantity: ticketQuantity - 1 });
    }
  };
  const plusButton = () => {
    trackSelectContentEvent(
      trackEvent,
      'Production',
      'Quantity Filter',
      t(
        'eventPage.filterSetting.increaseTicketButtonScreenReaderText',
      ),
    );
    setTicketQuantity(ticketQuantity + 1);
    if (handleChangeParam) {
      handleChangeParam({ quantity: ticketQuantity + 1, event_id: String(props.event?.id) });
    }
    if (onQuantityFilterChangeAction) {
      onQuantityFilterChangeAction({ quantity: ticketQuantity + 1 });
    }
  };
  const stockCategoryToggle = () => {
    if (categoryToggle === 'Off') {
      setCategoryToggle('On');
      if (handleChangeParam) {
        handleChangeParam({ stock_category: 'electronic', event_id: String(props.event?.id) });
      }
      if (onTypeFilterChangeAction) {
        onTypeFilterChangeAction({
          filterName: {
            name: 'electronic',
          }, filterValue: true,
        });
      }
    } else if (categoryToggle === 'On') {
      setCategoryToggle('Off');
      if (handleChangeParam) {
        handleChangeParam({ stock_category: '', event_id: String(props.event?.id) });
      }
      if (onTypeFilterChangeAction) {
        onTypeFilterChangeAction({
          filterName: {
            name: 'electronic',
          }, filterValue: false,
        });
      }
    }
  };
  const onAfterChange = () => {
    if (handleChangeParam) {
      handleChangeParam({
        min_price: dualSliderValue[0],
        max_price: dualSliderValue[1],
        event_id: String(props.event?.id),
      });
    }
  };
  const handleClick = useCallback(() => {
    return (callBack: () => void) => {
      if (callBack) {
        void callBack();
      }
    };
  }, []);


  return {
    ...props,
    type: listing ? 'Precheckout' : 'Listings',
    isModalOpen: filterModalOpen,
    setModalOpen: () => setFilterModalOpen(!filterModalOpen),
    seatSelectionBlockMobile: {
      instructionLabel: {
        button: {
          type: 'Icon',
          style: 'Text',
          size: 'Small',
          icon: {
            asset: 'Settings',
            style: 'DigitalGrey60',
          },
          onClick: () => {
            trackSelectContentEvent(
              trackEvent,
              'Production',
              'Filter Toggle',
            );
            setFilterModalOpen(true);
          },
        },
      },
      quantity: ticketQuantity,
    },
    seatSelectionBlock: {
      quantity: ticketQuantity,
      handleClick: handleClick,
    },
    filterSetting: {
      label: {
        value: t('eventPage.filterSetting.filter'),
      },
      button: {
        onClick: () => {
          trackSelectContentEvent(
            trackEvent,
            'Production',
            'Filter Modal',
            'Close Filter',
          );
          setFilterModalOpen(false);
        },
        icon: {
          asset: 'Close',
          style: 'DigitalGrey80',
        },
      },
      ticketQuantity: {
        label: {
          value: t('eventPage.filterSetting.howMany'),
        },
        label1: {
          value: ticketQuantity > 0 ? ticketQuantity : t('eventPage.filterSetting.any'),
        },
        minusButton: {
          type: 'Icon',
          icon: {
            asset: 'Minus',
            style: 'DigitalGrey60',
          },
          size: 'Small',
          style: 'Text',
          disabled: ticketQuantity > 0 ? false : true,
          onClick: minusButton,
          ariaLabel: t('eventPage.filterSetting.decreaseTicketButtonScreenReaderText'),
        },
        plusButton: {
          type: 'Icon',
          icon: {
            asset: 'Plus',
            style: 'DigitalGrey60',
          },
          size: 'Small',
          style: 'Text',
          onClick: plusButton,
          ariaLabel: t('eventPage.filterSetting.increaseTicketButtonScreenReaderText'),
        },
      },
      sliderFilter: {
        leftValue: {
          value: `$${dualSliderValue[0]}`,
        },
        rightValue: {
          value: `$${dualSliderValue[1]}`,
        },
        slider: {
          maxPoints: selectors.maxPrice ? selectors.maxPrice : 0,
          minPoints: selectors.minPrice ? selectors.minPrice : 0,
          type: 'Dual',
          dualSliderValue: dualSliderValue,
          onDualSliderMoved: handleSliderMoved,
          onAfterChange: onAfterChange,
        },
      },
      handleClick: handleClick,
      showSlider: !!(!props.event?.tags?.includes(REWARDS_ONLY_TAG) && selectors.maxPrice),
    },
    isExclusive: isExclusive,
    applyFilter: applyFilter,
    resetFilter: resetFilter,
    precheckout: {
      listing: listing,
      breakdown: listing?.listing?.price_breakdown,
      // preSelectedQuantity: getPreSelectedQuantity(),
      // isVividEvent: !(listing?.listing?.broker_id === C1_BROKER_ID) || false,
      isExclusive: props.event?.tags?.includes(CP1_EXCLUSIVE_TAG),
      onBackButtonClick: () => {
        trackSelectContentEvent(
          trackEvent,
          'Production',
          'Precheckout',
          t('precheckoutBlock.backButton'),
        );
        setListing(undefined);
        history.push(pathname);
      },
    },
    emptyStateBanner: {
      type: 'NoIcon',
      title: {
        value: t('eventPage.noTicketListing.title'),
      },
      message: {
        value: t('eventPage.noTicketListing.message'),
      },
      button: {
        text: {
          value: t('eventPage.noTicketListing.buttonText'),
        },
        onClick: () => {
          trackSelectContentEvent(
            trackEvent,
            'Production',
            'Empty State',
            t('eventPage.noTicketListing.buttonText'),
          );
        },
        href: '/',
      },
    },
    emptyStateBannerDivider: {
      type: 'Horizontal',
      style: 'Thin',
      colour: 'DigitalGrey10',
    },
    isListingsEmpty: (!listing && !isExclusive && !selectors.composedListings.length),
    isMobile,
    isLoading,
  };
};

export default usePresenter;

import React from 'react';
import cx from 'classnames';

import EventCardsBlock from '../../blocks/EventCardsBlock';
import FiltersBlock from '../../blocks/FiltersBlock';
import SecondaryBannerLightBlock from '../../blocks/SecondaryBannerLightBlock';
import PrimaryBannerBlock from '../../blocks/PrimaryBannerBlock';

import styles from './Homepage.module.scss';
import usePresenter from './Homepage.presenter';
import getProps from './Homepage.props';
import { HomepageCombinedProps } from './types';


const Homepage: React.FC<HomepageCombinedProps> = (props) => {
  const {
    className,
    classes,
    primaryBannerBlock,
    exclusiveEvents,
    mLBBanner,
    filtersBlock,
    highlightedEvents,
    musicEvents,
    sportsEvents,
    comedyTheaterEvents,
    secondaryBannerLightBlock,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.homepage;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.topContainer, classes?.topContainer)}>
        <PrimaryBannerBlock
          className={cx(styles.primaryBannerBlock, classes?.primaryBannerBlock)}
          {...internalProps.primaryBannerBlock}
          {...primaryBannerBlock}/>
        <EventCardsBlock
          className={cx(styles.exclusiveEvents, classes?.exclusiveEvents)}
          {...internalProps.exclusiveEvents}
          {...exclusiveEvents}/>
      </div>
      <div className={cx(styles.container, classes?.container)}>
        <SecondaryBannerLightBlock
          className={cx(styles.mLBBanner, classes?.mLBBanner)}
          {...internalProps.mLBBanner}
          {...mLBBanner}/>
        <FiltersBlock
          className={cx(styles.filtersBlock, classes?.filtersBlock)}
          {...internalProps.filtersBlock}
          {...filtersBlock}/>
        <EventCardsBlock
          className={cx(styles.highlightedEvents, classes?.highlightedEvents)}
          {...internalProps.highlightedEvents}
          {...highlightedEvents}/>
        <EventCardsBlock
          className={cx(styles.musicEvents, classes?.musicEvents)}
          {...internalProps.musicEvents}
          {...musicEvents}/>
        <EventCardsBlock
          className={cx(styles.sportsEvents, classes?.sportsEvents)}
          {...internalProps.sportsEvents}
          {...sportsEvents}/>
        <EventCardsBlock
          className={cx(styles.comedyTheaterEvents, classes?.comedyTheaterEvents)}
          {...internalProps.comedyTheaterEvents}
          {...comedyTheaterEvents}/>
        <SecondaryBannerLightBlock
          className={cx(styles.secondaryBannerLightBlock, classes?.secondaryBannerLightBlock)}
          {...internalProps.secondaryBannerLightBlock}
          {...secondaryBannerLightBlock}/>
      </div>
    </div>
  );
};

export default Homepage;


import React, { useEffect, useRef } from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import Divider from '../../atoms/Divider';
import Logo from '../../atoms/Logo';
import Text from '../../atoms/Text';

import IconTextDescription from '../IconTextDescription';
import OnBoardingContactInformation from '../OnBoardingContactInformation';

import { useProgressiveImage } from '../../../lib/util';

import styles from './StepContent.module.scss';
import usePresenter from './StepContent.presenter';
import getProps from './StepContent.props';
import { StepContentCombinedProps } from './types';

const StepContent: React.FC<StepContentCombinedProps> = (props) => {
  const {
    type,
    step,
    header,
    subHeader,
    message,
    divider,
    description,
    contactInformation,
    button,
    footer,
    className,
    imgSource,
    changeIsStepRendered,
  } = usePresenter(props);

  const desktopContainerRef = useRef<HTMLDivElement>(null);
  const mobileContainerRef = useRef<HTMLDivElement>(null);
  const backDropView = `step${step}View`;
  const loadedImage = useProgressiveImage(imgSource);

  const internalProps = getProps('');

  let subHeaderView, messageView, descriptionView, contactInformationView;

  if (subHeader) {
    subHeaderView = (
      <Text
        className={styles.subHeader}
        {...internalProps.subHeader}
        {...subHeader} />
    );
  }

  if (message) {
    messageView = (
      <Text
        className={styles.message}
        {...internalProps.message}
        {...message} />
    );
  }

  switch (type) {
    case 'Default':
      descriptionView = (description?.map((iconTextDescription, index) => (
        <IconTextDescription
          className={styles.description}
          key={index}
          {...iconTextDescription} />
      )));
      break;
    case 'Contact':
      contactInformationView = (
        <OnBoardingContactInformation
          {...internalProps.contactInformation}
          {...contactInformation} />
      );
      break;
  }
  const desktopView = (
    <>
      <Text
        className={styles.header}
        {...internalProps.header}
        {...header} />
      {messageView}
      <Divider
        className={styles.divider}
        {...internalProps.divider}
        {...divider} />
      {subHeaderView}
      {descriptionView}
      {contactInformationView}
    </>
  );

  const mobileView = (
    <>
      <div className={cx(styles[`${backDropView}`], className)}
            style={{ 
              backgroundSize: 'cover',
              backgroundImage: `linear-gradient(180deg, rgba(16, 37, 63, 0) 40%, rgba(16, 37, 63, 0.7) 75%, #10253F 100%), url(${loadedImage})`, 
              backgroundPosition: '50% 50%',
              backgroundRepeat: 'no-repeat',
            }}>
        <Text
          className={styles.header}
          {...internalProps.header}
          {...header} />
      </div>
      <div className={styles.onBoardingBlockMobile}>
        {messageView}
        <Divider
          className={styles.divider}
          {...internalProps.divider}
          {...divider} />
        {subHeaderView}
        {descriptionView}
        {contactInformationView}
      </div>
    </>);

  useEffect(() => {
    if (changeIsStepRendered && loadedImage) {
      setTimeout(() => {
        void changeIsStepRendered();
        if (mobileContainerRef?.current?.offsetParent) { // check to see if mobile container is hidden or not on dom
          mobileContainerRef?.current?.focus();
        } else {
          desktopContainerRef?.current?.focus();
        }
      }, 2000);   
    }
  }, [loadedImage]);

  return (
    <React.Fragment>
      <div tabIndex={0} ref={desktopContainerRef} className={styles.desktopContainer}>
        <div className={styles.leftView}>
          <div className={styles.logoContainer}>
            <Logo asset='LogoC1EntertainmentWhite' className={styles.logo}></Logo>
          </div>
          <div className={styles.onBoardingBlock}>
            {desktopView}
          </div>
          <div className={styles.footer}>
            <Button
              className={styles.button}
              {...internalProps.button}
              {...button} />
            <Text
              {...internalProps.footer}
              {...footer} />
          </div>
        </div>
        <div className={cx(styles[`${backDropView}`], className)}
          style={{ 
            backgroundSize: 'cover',
            backgroundImage: `linear-gradient(270deg, rgba(16, 37, 63, 0) 0%, rgba(16, 37, 63, 0.7) 51.54%, #10253F 100%), url(${loadedImage})`, 
            backgroundPosition: '50% 50%',
            backgroundRepeat: 'no-repeat',
          }}>
        </div>
      </div>
      <div tabIndex={0} ref={mobileContainerRef} className={styles.mobileContainer}>
        <div className={styles.logoContainer}>
          <Logo asset='LogoC1EntertainmentWhite' className={styles.logo}></Logo>
        </div>
        {mobileView}
        <div className={styles.bottomView}>
          <Button
            className={styles.button}
            {...internalProps.button}
            {...button} />
          <div className={styles.footer}>
            <Text
              {...internalProps.footer}
              {...footer} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default StepContent;

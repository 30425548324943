import { ImageProps } from '../../atoms/Image';
import { AdditionalTicketInformationListProps } from '../../molecules/AdditionalTicketInformationList';
import { TicketInformationProps } from '../../molecules/TicketInformation';
import { TicketQuantitySelectionProps } from '../../molecules/TicketQuantitySelection';


type SportsPrecheckoutBlockInternalProps = {
  image?: ImageProps;
  ticketInformation?: TicketInformationProps;
  additionalTicketInformationList?: AdditionalTicketInformationListProps;
  ticketQuantitySelection?: TicketQuantitySelectionProps;
};

type SportsPrecheckoutBlockPropsValues = {
  '': SportsPrecheckoutBlockInternalProps;
};

const propValues: SportsPrecheckoutBlockPropsValues = {
  '': {
    image: {
    },
    ticketInformation: {
      type: 'LargeTitle',
      style: 'Default',
    },
    additionalTicketInformationList: {
    },
    ticketQuantitySelection: {
    },
  },
};

const getProps = (type: string): SportsPrecheckoutBlockInternalProps => {
  const values: SportsPrecheckoutBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import Text from '../../atoms/Text';

import styles from './ContactUsItem.module.scss';
import usePresenter from './ContactUsItem.presenter';
import getProps from './ContactUsItem.props';
import { ContactUsItemCombinedProps } from './types';


const ContactUsItem: React.FC<ContactUsItemCombinedProps> = (props) => {
  const {
    className,
    classes,
    title,
    message,
    button,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.contactUsItem;

  // MANUAL OVERRIDE STARTS
  const buttonView = button?.text?.value ? (
    <Button
      className={cx(styles.button, classes?.button)}
      {...internalProps.button}
      {...button}/>
  ) : undefined;
  // MANUAL OVERRIDE ENDS


  return (
    <div className={cx(currentStyle, className)}>
      <Text
        className={cx(styles.title, classes?.title)}
        {...internalProps.title}
        {...title}/>
      <Text
        className={cx(styles.message, classes?.message)}
        {...internalProps.message}
        {...message}/>
      {/* MANUAL OVERRIDE */}
      {buttonView}
    </div>
  );
};

export default ContactUsItem;


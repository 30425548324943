import React from 'react';
import cx from 'classnames';

import AdditionalTicketInformationList from '../../molecules/AdditionalTicketInformationList';
import CardNavigation from '../../molecules/CardNavigation';
import TicketQuantitySelection from '../../molecules/TicketQuantitySelection';

import styles from './GeneralPrecheckout.module.scss';
import usePresenter from './GeneralPrecheckout.presenter';
import getProps from './GeneralPrecheckout.props';
import { GeneralPrecheckoutCombinedProps } from './types';
import Image from '../../atoms/Image';
import TicketInformation from '../../molecules/TicketInformation';


const GeneralPrecheckout: React.FC<GeneralPrecheckoutCombinedProps> = (props) => {
  const {
    className,
    classes,
    cardNavigation,
    ticketInformation, // MANUAL OVERRIDE
    ticketQuantitySelection,
    additionalTicketInformationList,
    image, // MANUAL OVERRIDE
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.generalPrecheckout;

  return (
    <div className={cx(currentStyle, className)}>
      <CardNavigation
        className={cx(styles.cardNavigation, classes?.cardNavigation)}
        {...internalProps.cardNavigation}
        {...cardNavigation}/>
      {/* MANUAL OVERRIDE */}
      <Image className={styles.image} {...image} />
      {/* MANUAL OVERRIDE */}
      <TicketInformation
        className={cx(styles.ticketInformation, classes?.ticketInformation)}
        {...internalProps.ticketInformation}
        {...ticketInformation}/>
      <TicketQuantitySelection
        className={cx(styles.ticketQuantitySelection, classes?.ticketQuantitySelection)}
        {...internalProps.ticketQuantitySelection}
        {...ticketQuantitySelection}/>
      <AdditionalTicketInformationList
        className={cx(styles.additionalTicketInformationList, classes?.additionalTicketInformationList)}
        {...internalProps.additionalTicketInformationList}
        {...additionalTicketInformationList}/>
    </div>
  );
};

export default GeneralPrecheckout;


import React, { forwardRef, useContext, useMemo } from 'react';
import MapContext from '../../../MapContext';
import { TextSVG, PathSVG, JSONMap } from '../../../types';
import PathsContainer from '../PathsContainer';
import TextsContainer from '../TextContainer';
import { svgConstantsProps } from './constants';

interface MapTypes {
  [otherData: string]: Array<TextSVG | PathSVG>
}
interface MapCallbacks {
  onClick?: any
  onMouseOver?: any
  onMouseOut?: any
  onPointerDownCapture?: any
  onPointerUpCapture?: any
  onDoubleClick?: any
}

export interface SimpleMapProps {
  jsonMap?: JSONMap
  callbacks?: MapCallbacks
  disableTouchEventListeners?: boolean
  className?: string
}

const SimpleMap: React.ForwardRefRenderFunction<SVGSVGElement, SimpleMapProps> = (
  { jsonMap, callbacks, className },
  ref,
) => {
  const {
    selectors: { mapState },
  } = useContext(MapContext);

  if (!jsonMap) return null;
  const { elements, width, height } = jsonMap;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const mapByType = useMemo(() => {
    if (elements) {
      return elements.reduce<MapTypes>((acc, item) => {
        const { type } = item;
        if (acc[type]) acc[type].push(item);
        else acc[type] = [item];
        return acc;
      }, {});
    }
    return {};
  }, [elements]);
  return (
        <svg
            ref={ref}
            className={className}
            data-testid="svg-map"
            id="svg-map"
            viewBox={`0 0 ${width} ${height}`}
            {...callbacks}
            {...svgConstantsProps}
            style={{ width: '100%', height: '100%', cursor: mapState.isMouseDown ? 'grabbing' : 'pointer', pointerEvents: 'visibleFill' }}
            //style={{ width: '100%', height: '100%', pointerEvents: 'visibleFill' }}
        >
            <PathsContainer paths={mapByType.path as Array<PathSVG>} />
            <TextsContainer texts={mapByType.text as Array<TextSVG>} />
        </svg>
  );
};

export default forwardRef(SimpleMap);

import React from 'react';
import cx from 'classnames';

import Divider from '../../atoms/Divider';
import Button from '../../atoms/Button';

import customStyles from './Custom.module.scss';
import styles from './SubmenuList.module.scss';
import usePresenter from './SubmenuList.presenter';
import getProps from './SubmenuList.props';
import { SubmenuListCombinedProps } from './types';


const SubmenuList: React.FC<SubmenuListCombinedProps> = (props) => {
  const {
    className,
    classes,
    buttons,
    // MANUAL OVERRIDE STARTS
    divider,
    focusable,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.submenuList;

  // MANUAL OVERRIDE STARTS
  const buttonViews = buttons?.map((button, index) => {
    if (button.style === 'Outline') {
      return <Button
        focusable={focusable}
        key={index}
        className={cx(customStyles.allButton, classes?.button)}
        {...internalProps.button}
        {...button} />;
    }

    return (
      <div key={index} className={customStyles.content}>
        <Divider
          className={customStyles.divider}
          {...internalProps.divider}
          {...divider} />
        <Button
          focusable={focusable}
          className={cx(customStyles.button, classes?.button)}
          {...internalProps.button}
          {...button} />
      </div>
    );
  }) || [];
  // MANUAL OVERRIDE ENDS

  return (
    <div className={cx(currentStyle, className)}>
      {buttonViews}
    </div>
  );
};

export default SubmenuList;


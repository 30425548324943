import { Region } from '../../../modules/partnership';
import { DateFilterOption, DropdownOption } from './types';
import i18n from '../../../modules/locale/i18n';

export const formatSelectedDate = (date: Date): string => {
  return new Intl.DateTimeFormat('en-US', {
    month: 'short', day: 'numeric',
  }).format(date);
};

export const dynamicRegionList = (options: DropdownOption[], filterText: string): DropdownOption[] => {
  const normalizedFilter = filterText.toLowerCase();
  return options
    .filter((option) => {
      return option.value.toLowerCase().includes(normalizedFilter);
    });
};

export const getRegion = (regions?: Region[], regionId?: string | null): Region | undefined => {
  return regions?.find((region) => String(region.id) === regionId);
};

export const formatWeekendDropdownTitle = (option: DateFilterOption): string => {
  if (option.title === i18n.t('dateFilters.weekend') 
      && option.value?.startDate
      && option.value.endDate
  ) {
    return i18n.t('dateFilters.selectedDateRange', {
      startDate: formatSelectedDate(option.value.startDate),
      endDate: formatSelectedDate(option.value.endDate),
    });
  }

  return option.title;
};
import { IconProps } from '../Icon';
import { TextProps } from '../Text';


type CheckBoxItemInternalProps = {
  icon?: IconProps;
  text?: TextProps;
};

type CheckBoxItemPropsValues = {
  'Selected': CheckBoxItemInternalProps;
  'Unselected': CheckBoxItemInternalProps;
};

const propValues: CheckBoxItemPropsValues = {
  'Selected': {
    icon: {
      asset: 'CheckboxFilled',
      style: 'DigitalGrey100',
    },
    text: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
  },
  'Unselected': {
    icon: {
      asset: 'CheckboxEmpty',
      style: 'DigitalGrey100',
    },
    text: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
  },
};

const getProps = (type: string): CheckBoxItemInternalProps => {
  const values: CheckBoxItemInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

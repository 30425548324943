import { useCallback, useEffect, useState } from 'react';
import useLocalStorage from 'react-use-localstorage';

export type RecentSearch = {
  title: string;
  path: string;
};

const useRecentSearches = (): {
  recentSearches: RecentSearch[];
  addRecentSearch: (search: RecentSearch) => void;
  removeRecentSearch: (search: RecentSearch) => void;
} => {
  const [storageItem, setStorageItem] = useLocalStorage('recentSearches', '[]');
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const [searchedItems, updateSearchedItems] = useState<RecentSearch[]>(JSON.parse(storageItem));

  useEffect(() => {
    const ids = JSON.parse(storageItem);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    updateSearchedItems(ids);
  }, [storageItem]);

  const setSearchedItems = useCallback((newEventIds: RecentSearch[]) => {
    updateSearchedItems(newEventIds);
    setStorageItem(JSON.stringify(newEventIds));
  }, [setStorageItem]);

  const addRecentSearch = useCallback((search: RecentSearch) => {
    if (!searchedItems.find((item) => item.title === search.title)) {
      const newSearchedItems = [search, ...searchedItems];
      if (newSearchedItems.length > 4) {
        newSearchedItems.pop();
      }
      setSearchedItems(newSearchedItems);
    }
  }, [searchedItems, setSearchedItems]);

  const removeRecentSearch = useCallback((search: RecentSearch) => {
    const newSearchedItems = searchedItems.filter((item) => item.title !== search.title);
    setSearchedItems(newSearchedItems);
  }, [searchedItems, setSearchedItems]);

  return { recentSearches: searchedItems, addRecentSearch, removeRecentSearch };
};

export default useRecentSearches;

import React from 'react';
import cx from 'classnames';

import StepperItem from '../../molecules/StepperItem';

import styles from './StepperList.module.scss';
import usePresenter from './StepperList.presenter';
import getProps from './StepperList.props';
import { StepperListCombinedProps } from './types';


const StepperList: React.FC<StepperListCombinedProps> = (props) => {
  const {
    className,
    classes,
    stepperItems,
    activeStep, // MANUAL OVERRIDE
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.stepperList;

  // MANUAL OVERRIDE STARTS
  const stepperItemViews = stepperItems?.map((stepperItem, index) => {
    const isActive: boolean = (activeStep || 1) === index + 1;
    const isComplete: boolean = (activeStep || 1) > index + 1;
    return <StepperItem
      key={index}
      className={cx(styles.stepperItem, classes?.stepperItem)}
      {...internalProps.stepperItem}
      {...stepperItem}
      type={isActive ? 'Selected' : 'Unselected'}
      state={isComplete ? 'Complete' : 'Incomplete'} />;
  });
  // MANUAL OVERRIDE ENDS
  return (
    <div className={cx(currentStyle, className)}>
      {stepperItemViews}
    </div>
  );
};

export default StepperList;


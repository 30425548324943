import { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AuthContext } from '../../../modules/auth/AuthContext';
import { OrderItems } from '../CheckoutManualPage/CheckoutManualPage.utils';
import { ReceiptPageCombinedProps, ReceiptPagePresenterProps } from './types';
import { LocationState } from '../CheckoutManualPage/types';

const useInteractor = (props: ReceiptPageCombinedProps): ReceiptPagePresenterProps => {

  const { account } = useContext(AuthContext);
  const location = useLocation<LocationState>();
  const [orderItem] = useState<OrderItems>(
    location.state?.currentOrderItems,
  );
  let error: Error | null = null;
  if (!orderItem) {
    error = new Error('Order not found');
  }
  return {
    ...props,
    error: error || undefined,
    account: account,
    orderItems: orderItem,
  };

};
export default useInteractor;

import { ButtonProps } from '../../atoms/Button';
import { DividerProps } from '../../atoms/Divider';
import { TextProps } from '../../atoms/Text';
import { OnBoardingContactInformationProps } from '../OnBoardingContactInformation';

type StepContentInternalProps = {
  header?: TextProps;
  subHeader?: TextProps;
  message?: TextProps;
  divider?: DividerProps;
  contactInformation?: OnBoardingContactInformationProps;
  button?: ButtonProps;
  footer?: TextProps;
};

type StepContentPropsValue = {
  '': StepContentInternalProps;
};

const propValues: StepContentPropsValue = {
  '': {
    header: {
      type: 'Subheading',
      size: 'Large',
      style: 'Regular',
      colour: 'BaseLight',
      align: 'Left',
    } as TextProps,
    divider: {
      type: 'Horizontal',
      style: 'Thin',
      colour: 'CP1Red',
    } as DividerProps,
    button: {
      type: 'Text',
      style: 'Contained',
      size: 'Medium',
      text: {
        type: 'Body',
        size: 'Large',
        style: 'SemiBold',
        colour: 'BaseLight',
        align: 'Left',
      },
    } as ButtonProps,
    footer: {
      type: 'Body',
      colour: 'BaseLight',
      align: 'Left',
      size: 'Medium',
      style: 'Regular',
    } as TextProps,
  },
};

const getProps = (type: string): StepContentInternalProps => {
  const values: StepContentInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
import React from 'react';
import cx from 'classnames';

import PerformerPageBanner from '../../organisms/PerformerPageBanner';

import styles from './ImageBlock.module.scss';
import usePresenter from './ImageBlock.presenter';
import getProps from './ImageBlock.props';
import { ImageBlockCombinedProps } from './types';


const ImageBlock: React.FC<ImageBlockCombinedProps> = (props) => {
  const {
    className,
    classes,
    performerPageBanner,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.imageBlock;

  return (
    <div className={cx(currentStyle, className)}>
      <PerformerPageBanner
        className={cx(styles.performerPageBanner, classes?.performerPageBanner)}
        {...internalProps.performerPageBanner}
        {...performerPageBanner}/>
    </div>
  );
};

export default ImageBlock;


import { TextProps } from '../../atoms/Text';
import { DividerProps } from '../../atoms/Divider';
import { IconProps } from '../../atoms/Icon';


type FAQItemInternalProps = {
  question?: TextProps;
  icon?: IconProps;
  divider?: DividerProps;
  answer?: TextProps;
};

type FAQItemPropsValues = {
  'Collapsed': FAQItemInternalProps;
  'Expanded': FAQItemInternalProps;
};

const propValues: FAQItemPropsValues = {
  'Collapsed': {
    question: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
    icon: {
      asset: 'ChevronDown',
      style: 'DigitalGrey80',
    },
    divider: {
      type: 'Horizontal',
      style: 'Thin',
      colour: 'DigitalGrey15',
    },
  },
  'Expanded': {
    question: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
    icon: {
      asset: 'ChevronUp',
      style: 'DigitalGrey80',
    },
    answer: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    divider: {
      type: 'Horizontal',
      style: 'Thin',
      colour: 'DigitalGrey15',
    },
  },
};

const getProps = (type: string): FAQItemInternalProps => {
  const values: FAQItemInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import cx from 'classnames';

import customStyles from './Custom.module.scss';
import { CardNavigationCombinedProps } from './types';

const usePresenter = (props: CardNavigationCombinedProps): CardNavigationCombinedProps => {
  const { className, classes, style, button } = props;
  const isVariantLightBG = style === 'LightBackground';
  return {
    ...props,
    className: cx(className, isVariantLightBG ? customStyles.customCardNavigation : ''),
    classes: {
      button: cx(classes?.button, isVariantLightBG ? customStyles.customButton : ''),
    },
    button: {
      ...button,
      classes: {
        text: cx(button?.classes?.text, isVariantLightBG ? customStyles.customButtonText : ''),
      },
    },
  };
};

export default usePresenter;

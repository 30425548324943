import { useTranslation } from 'react-i18next';
import { SportTeamsLandingPageCombinedProps } from './types';
import { CONTENT_ID, QUERY_HOME_OR_AWAY } from '../../../lib/constants';
import { HomeAwayFilterOptions } from './constants';
import useInteractor from './SportTeamsLandingPage.interactor';
import { useAnalyticsManager } from '../../../modules/analytics/useAnalyticsManager';
import { useEffect } from 'react';

const usePresenter = (props: SportTeamsLandingPageCombinedProps): SportTeamsLandingPageCombinedProps => {
  const { team, performerId, opponents, locationList } = useInteractor(props);
  const { t } = useTranslation();
  const { trackEvent } = useAnalyticsManager();

  const pageName = 'Sports Performer Landing Page';
  useEffect(() => {
    trackEvent('view_item_list', {
      ecommerce: {
        item_list_name: 'sport_teams_landing_page_View',
      },
    });
  }, []);
  // const blockProps: SportTeamsLandingPageProps = ;
  return {
    ...props,
    imageBlock: {
      performerPageBanner: {
        imageSrc: team?.image,
        title: {
          value: team?.name,
          id: CONTENT_ID,
        },
      },
    },
    toggleBlock: {
      toggleFilterName: QUERY_HOME_OR_AWAY,
      options: HomeAwayFilterOptions,
      defaultOption: HomeAwayFilterOptions[0],
    },
    filtersBlock: {
      filterOne: {
        type: 'LocationFilter',
        showUseMyLocation: false,
        showAllLocations: true,
        locationList,
        defaultOption: {
          id: '',
          value: t('shopByWidget.anyLocation'),
        },
        newDropdownMenu: {
          type: 'Default',
        },
      },
      filterTwo: {
        defaultDateOption: {
          title: t('dateFilters.any'),
        },
        type: 'DateFilter',
      },
    },
    eventCardsBlock: {
      defaultParams: {
        performer_id: performerId,
      },
      pageName,
      pageCarouselIndex: 1,
    },
  };
};

export default usePresenter;

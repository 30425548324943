import React from 'react';
import cx from 'classnames';

import GeneralPrecheckoutBlock from '../GeneralPrecheckoutBlock';
import GeneralPrecheckout from '../../organisms/GeneralPrecheckout';
import SportsPrecheckoutBlock from '../SportsPrecheckoutBlock';
import SportsPrecheckout from '../../organisms/SportsPrecheckout';

import TicketAlertModal from '../../organisms/TicketAlertModal';
import ErrorHandler from '../../../modules/error/ErrorHandler';
import { PrecheckoutBlockProps } from './types';
import usePresenter from './PrecheckoutBlock.presenter';

const PrecheckoutBlock: React.FC<PrecheckoutBlockProps> = (props) => {
  const {
    topNav,
    isExclusive,
    image,
    cardNavigation,
    cardInfoHeader,
    exclusiveEventBanner,
    ticketInfo,
    className,
    ticketAlertModalProps,
    error,
  } = usePresenter(props);
  let view;

  if (error) {
    return <ErrorHandler error={error} />;
  }

  if (isExclusive) {
    view = (
      <>
        <GeneralPrecheckout
          className={cx(className)}
          {...ticketInfo}
          cardNavigation={cardNavigation}
          image={image}
        />
        <GeneralPrecheckoutBlock
          className={cx(className)}
          {...ticketInfo}
          topNav={topNav}
          cardInfoHeader={cardInfoHeader}
          exclusiveEventBanner={exclusiveEventBanner}
        />
      </>
    );
  } else {
    view = (
      <>
        <SportsPrecheckoutBlock
          className={cx(className)}
          {...ticketInfo}
          image={image}
        />
        <SportsPrecheckout
          className={cx(className)}
          {...ticketInfo}
          cardNavigation={cardNavigation}
          image={image}
        />
      </>
    );
  }

  return (
    <>
      {view}
      <TicketAlertModal {...ticketAlertModalProps} />
    </>
  );
};

export default PrecheckoutBlock;

import { TabProps } from '../../atoms/Tab';
import { NavMenuItemProps } from '../../molecules/NavMenuItem';


type NavMenuListInternalProps = {
  navMenuItem?: NavMenuItemProps;
  tab?: TabProps; // MANUAL OVERRIDE
};

type NavMenuListPropsValues = {
  '': NavMenuListInternalProps;
};

const propValues: NavMenuListPropsValues = {
  '': {
    navMenuItem: {
      type: 'Next',
    },
    // MANUAL OVERRIDE
    tab: {
      colour: 'Light',
      style: 'Square',
      type: 'Default',
    },
  },
};

const getProps = (type: string): NavMenuListInternalProps => {
  const values: NavMenuListInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

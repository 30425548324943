import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from './Text.module.scss';
import usePresenter from './Text.presenter';
import getProps from './Text.props';
import { TextCombinedProps } from './types';


const Text: React.FC<TextCombinedProps> = (props) => {
  const {
    type,
    size,
    style,
    colour,
    align,
    className,
    classes,
    value,
    // MANUAL OVERRIDE STARTS
    ariaLabel = '',
    ariaRole = '',
    ariaAtomic = false,
    shouldFocus = false,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const variantName = `${type}${size}${style}${colour}${align}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`text${variantName}`];

  const { t } = useTranslation();


  let componentView;

  switch (variantName) {
    case 'BodyExtraExtraSmallRegularDisabledCenter':
    case 'BodyExtraExtraSmallRegularSubduedDarkCenter':
    case 'BodyExtraSmallRegularActionBaseLeft':
    case 'BodyExtraSmallRegularBaseDarkLeft':
    case 'BodyExtraSmallSemiBoldDisabledCenter':
    case 'BodyExtraSmallSemiBoldNegativeLeft':
    case 'BodyExtraSmallSemiBoldSubduedDarkCenter':
    case 'BodyExtraSmallRegularSubduedLightCenter': // MANUAL OVERRIDE
    case 'BodyExtraSmallRegularSubduedDarkCenter': // MANUAL OVERRIDE
    case 'BodyExtraSmallSemiBoldSubduedDarkLeft':
    case 'BodyExtraSmallSemiBoldWarningLeft':
    case 'BodyLargeRegularActionActiveLeft':
    case 'BodyLargeRegularActionBaseCenter':
    case 'BodyLargeRegularActionBaseLeft':
    case 'BodyLargeRegularActionBaseRight':
    case 'BodyLargeRegularActionHoverLeft':
    case 'BodyLargeRegularBaseDarkCenter':
    case 'BodyLargeRegularBaseDarkLeft':
    case 'BodyLargeRegularBaseLightCenter':
    case 'BodyLargeRegularBaseLightLeft':
    case 'BodyLargeRegularDisabledCenter':
    case 'BodyLargeRegularDisabledLeft':
    case 'BodyLargeRegularNegativeLeft':
    case 'BodyLargeRegularSubduedDarkCenter':
    case 'BodyLargeRegularSubduedDarkLeft':
    case 'BodyLargeRegularSubduedLightCenter':
    case 'BodyLargeRegularSubduedLightLeft':
    case 'BodyLargeSemiBoldActionBaseLeft':
    case 'BodyLargeSemiBoldBaseDarkLeft':
    case 'BodyLargeSemiBoldBaseLightLeft':
    case 'BodyLargeSemiBoldDisabledLeft':
    case 'BodyLargeSemiBoldPositiveLeft':
    case 'BodyLargeSemiBoldSubduedDarkLeft':
    case 'BodyLargeSemiBoldSubduedLightLeft':
    case 'BodyMediumRegularActionBaseLeft':
    case 'BodyMediumRegularActionLeft': // MANUAL OVERRIDE
    case 'BodyMediumRegularActionActiveCenter': // MANUAL OVERRIDE
    case 'BodyMediumRegularActionHoverLeft':
    case 'BodyMediumRegularBaseDarkCenter':
    case 'BodyMediumRegularBaseDarkLeft':
    case 'BodyMediumRegularBaseLightCenter':
    case 'BodyMediumRegularBaseLightLeft':
    case 'BodyMediumRegularBrandLeft':
    case 'BodyMediumRegularCharcoal10Left':
    case 'BodyMediumRegularDisabledLeft':
    case 'BodyMediumRegularNegativeLeft': // MANUAL OVERRIDE
    case 'BodyMediumRegularPositiveLeft':
    case 'BodyMediumRegularSubduedDarkLeft':
    case 'BodyMediumRegularSubduedDarkRight':
    case 'BodyMediumRegularSubduedDarkCenter':
    case 'BodyMediumRegularSubduedLightLeft':
    case 'BodyMediumRegularSubduedLightCenter': // MANUAL OVERRIDE
    case 'BodyMediumSemiBoldBaseDarkLeft':
    case 'BodyMediumSemiBoldDisabledCenter':
    case 'BodyMediumSemiBoldPositiveLeft':
    case 'BodyMediumSemiBoldSubduedDarkCenter':
    case 'BodyMediumSemiBoldSubduedDarkLeft':
    case 'BodyMediumSemiBoldPositiveLightLeft':
    case 'BodySmallRegularActionBaseLeft':
    case 'BodySmallRegularBaseLightCenter':
    case 'BodySmallRegularBaseLightLeft':
    case 'BodySmallRegularCharcoal20Left':
    case 'BodySmallRegularDisabledCenter':
    case 'BodySmallRegularNegativeLeft':
    case 'BodySmallRegularSubduedDarkCenter':
    case 'BodySmallRegularSubduedDarkLeft':
    case 'BodySmallRegularSubduedLightCenter':
    case 'BodySmallRegularSubduedLightLeft':
    case 'BodySmallSemiBoldActionLeft':
    case 'BodySmallSemiBoldBaseLightLeft':
    case 'BodySmallSemiBoldDisabledCenter':
    case 'BodySmallSemiBoldNegativeLeft':
    case 'BodySmallSemiBoldPositiveLeft':
    case 'BodySmallSemiBoldSubduedDarkCenter':
    case 'BodySmallSemiBoldSubduedDarkLeft':
    case 'BodySmallSemiBoldWarningLeft':
    case 'ButtonTextMediumRegularActionActiveLeft':
    case 'ButtonTextMediumRegularActionBaseLeft':
    case 'ButtonTextMediumRegularActionHoverLeft':
    case 'ButtonTextMediumRegularBaseLightLeft':
    case 'ButtonTextMediumRegularDisabledLeft':
    case 'ButtonTextMediumRegularUnderlineActionActiveLeft':
    case 'ButtonTextMediumRegularUnderlineActionHoverLeft':
    case 'CardBodyLargeRegularSubduedDarkLeft':
    case 'CardBodyMediumRegularBaseLightLeft':
    case 'CardBodyMediumRegularPositiveLeft':
    case 'CardBodyMediumRegularSubduedDarkLeft':
    case 'CardBodyMediumRegularSubduedLightLeft':
    case 'CardBodySmallRegularSubduedLightLeft':
    case 'ContactBodyLargeRegularSubduedDarkLeft':
    case 'ContactBodyLargeSemiBoldSubduedDarkLeft':
    case 'BodyLargeRegularUnderlineActionHoverLeft': // MANUAL OVERRIDE
    case 'MLBCardTitleMediumSemiBoldBaseLightLeft':
    case 'NewBannerBodyMediumRegularBaseLightLeft':
    case 'CardBodyMediumRegularCharcoal10Left':
    case 'BodyMediumSemiBoldBaseLightLeft': {
      // MANUAL OVERRIDE
      componentView = (
        <div className={cx(currentStyle, className)} tabIndex={shouldFocus ? 0 : -1}>
          <p className={cx(value === t('breakdown.deliveryFeeFreee') ? styles.valueFree : styles.value, classes?.value)} aria-label={ariaLabel} aria-atomic={ariaAtomic} role={ariaRole}>
            {value}
          </p>
        </div>
      );
      break;
    }
    case 'BannerHeadingSmallSemiBoldActionBaseLeft':
    case 'BannerHeadingLargeLightBaseLightLeft': // MANUAL OVERRIDE
    case 'BannerSubheadingSmallRegularBaseLightLeft': // MANUAL OVERRIDE
    case 'BannerSubheadingLargeLightBaseLightLeft':
    case 'HeadingSmallLightBaseDarkLeft':
    case 'HeadingSmallRegularBaseLightCenter':
    case 'HeadingSmallRegularSubduedDarkLeft':
    case 'NewBannerHeadingMediumRegularBaseDarkLeft':
    case 'NewBannerHeadingMediumRegularBaseLightLeft':
    case 'NewEventDetailsTitleMediumSemiBoldBaseLightLeft':
    case 'SubheadingLargeRegularBaseLightCenter':
    case 'BannerSubheadingLargeRegularBaseLightLeft':
    case 'HeadingSmallLightBaseLightLeft':
    case 'HeadingSmallSemiBoldActionBaseLeft':
    case 'HeadingSmallSemiBoldBaseLightLeft':
    case 'NewBannerHeadingLargeRegularBaseDarkLeft':
    case 'NewBannerHeadingLargeRegularBaseLightLeft':
    case 'NewEventDetailsTitleLargeSemiBoldBaseLightLeft': {
      // MANUAL OVERRIDE
      componentView = (
        <div className={cx(currentStyle, className)} tabIndex={shouldFocus ? 0 : -1}>
          <h1 className={cx(styles.value, classes?.value)} aria-label={ariaLabel} aria-atomic={ariaAtomic} role={ariaRole}>
            {value}
          </h1>
        </div>
      );
      break;
    }
    // MANUAL OVERRIDE STARTS - all the text variant with type 'Subheading' used h2 tag in legacy version
    // so moved the 'Subheading' variants here
    case 'SubheadingLargeLightSubduedDarkCenter':
    case 'SubheadingLargeLightSubduedDarkLeft':
    case 'SubheadingLargeRegularBaseLightLeft':
    case 'SubheadingLargeRegularSubduedDarkLeft':
    case 'SubheadingLargeRegularSubduedDarkCenter': // MANUAL OVERRIDE
    case 'SubheadingLargeRegularSubduedLightLeft':
    case 'SubheadingLargeSemiBoldActionBaseLeft':
    case 'SubheadingLargeSemiBoldBaseLightLeft': // MANUAL OVERRIDE ENDS
    case 'SubheadingMediumLightSubduedDarkLeft':
    case 'SubheadingMediumLightSubduedLightLeft':
    case 'SubheadingMediumLightSubduedLightRight':
    case 'SubheadingMediumRegularActionActiveLeft':
    case 'SubheadingMediumRegularActionBaseLeft':
    case 'SubheadingMediumRegularActionHoverLeft':
    case 'SubheadingMediumRegularBaseLightLeft':
    case 'SubheadingMediumRegularDisabledLeft':
    case 'SubheadingMediumRegularSubduedDarkLeft':
    case 'SubheadingMediumRegularSubduedDarkRight':
    case 'SubheadingMediumRegularUnderlineActionHoverLeft':
    case 'SubheadingMediumSemiBoldActionBaseLeft':
    case 'SubheadingSmallRegularBaseLightCenter':
    case 'SubheadingSmallRegularSubduedDarkCenter':
    case 'SubheadingSmallRegularSubduedDarkLeft':
    case 'SubheadingSmallRegularBaseLightLeft': {
      // MANUAL OVERRIDE
      componentView = (
        <div className={cx(currentStyle, className)} tabIndex={shouldFocus ? 0 : -1}>
          <h2 className={cx(styles.value, classes?.value)} aria-label={ariaLabel} aria-atomic={ariaAtomic} role={ariaRole}>
            {value}
          </h2>
        </div>
      );
      break;
    }
    case 'DecorationLargeRegularBaseLightLeft': // MANUAL OVERRIDE
    case 'DecorationLargeRegularSubduedDarkLeft':
    case 'DecorationMediumRegularActionActiveLeft':
    case 'DecorationMediumRegularActionBaseLeft':
    case 'DecorationMediumRegularActionHoverLeft':
    case 'DecorationMediumRegularBaseLightLeft':
    case 'DecorationMediumSemiBoldSubduedDarkLeft':
    case 'DecorationSmallRegularActionActiveLeft':
    case 'DecorationSmallRegularBaseLightLeft': {
      componentView = (
        <div className={cx(currentStyle, className)} tabIndex={shouldFocus ? 0 : -1}>
          <h4 className={cx(styles.value, classes?.value)} aria-label={ariaLabel} aria-atomic={ariaAtomic} role={ariaRole}>
            {value}
          </h4>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default Text;


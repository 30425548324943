import React from 'react';
import cx from 'classnames';

import Icon from '../Icon';
import Text from '../Text';

import styles from './CheckBoxItem.module.scss';
import usePresenter from './CheckBoxItem.presenter';
import getProps from './CheckBoxItem.props';
import { CheckBoxItemCombinedProps } from './types';


const CheckBoxItem: React.FC<CheckBoxItemCombinedProps> = (props) => {
  const {
    state,
    className,
    classes,
    disabled,
    buttonType,
    buttonForm,
    href,
    target,
    externalLink,
    onClick,
    icon,
    text,
  } = usePresenter(props);

  const variantName = `${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`checkBoxItem${variantName}`];


  let componentView;

  switch (variantName) {
    case 'Selected':
    case 'Unselected': {
      componentView = (
        <button
          disabled={disabled}
          type={buttonType}
          form={buttonForm}
          onClick={onClick}
          className={cx(currentStyle, className)}>
          <Icon
            className={cx(styles.icon, classes?.icon)}
            {...internalProps.icon}
            {...icon}/>
          <Text
            className={cx(styles.text, classes?.text)}
            {...internalProps.text}
            {...text}/>
        </button>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default CheckBoxItem;


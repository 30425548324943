import React from 'react';
import cx from 'classnames';
import { Dropdown } from 'react-bootstrap';
import Flatpickr from 'react-flatpickr';

import 'flatpickr/dist/flatpickr.css';
import './Flatpickr.custom.css';

import Icon from '../Icon';
import Text from '../Text';
import NewDropdownMenu from '../../organisms/NewDropdownMenu';

import styles from './NewDropdown.module.scss';
import customStyles from './Custom.module.scss';
import usePresenter from './NewDropdown.presenter';
import getProps from './NewDropdown.props';
import { NewDropdownCombinedProps } from './types';

const NewDropdown: React.FC<NewDropdownCombinedProps> = (props) => {
  const {
    state,
    style,
    className,
    classes,
    disabled,
    buttonType,
    buttonForm,
    href,
    target,
    externalLink,
    onClick,
    text,
    icon,
    // MANUAL OVERRIDE STARTS
    showMenu,
    handleToggle,
    ariaLabel,
    showCalendar,
    setDateRange,
    newDropdownMenu,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const variantName = `${state}${style}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`newDropdown${variantName}`];


  let componentView;

  switch (variantName) {
    case 'ClosedDark':
    case 'ClosedLight':
    case 'DisabledDark':
    case 'DisabledLight':
    case 'OpenDark':
    case 'OpenLight': {
      componentView = (
        <Dropdown className={customStyles.dropdown} show={showMenu} onToggle={handleToggle} >
          <Dropdown.Toggle
            as='div'
            className={customStyles.dropdownToggle}
            aria-label={ariaLabel}
            aria-expanded={undefined}
            onClick={onClick}
          >
          <button
            aria-expanded={showMenu}
            disabled={disabled}
            type={buttonType}
            form={buttonForm}
            onClick={onClick}
            className={cx(currentStyle, className)}>
            <Text
              className={cx(styles.text, classes?.text)}
              {...internalProps.text}
              {...text}/>
            <Icon
              className={cx(styles.icon, classes?.icon)}
              {...internalProps.icon}
              {...icon}/>
          </button>
          </Dropdown.Toggle>
          <Dropdown.Menu className={customStyles.dropdownMenu} >
            {showCalendar ? 
              <Flatpickr
                className={customStyles.flatpickr}
                options={{ mode: 'range', inline: true, minDate: 'today' }}
                onChange={(value: Date[]) => {
                  if (setDateRange) {
                    setDateRange(value);
                  }
                }}
              />
              : <NewDropdownMenu {...newDropdownMenu} showMenu={showMenu}/>
            }
          </Dropdown.Menu>
        </Dropdown>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default NewDropdown;


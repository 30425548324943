import React from 'react';
import cx from 'classnames';

import Text from '../../atoms/Text';
import Button from '../../atoms/Button';
import CheckBoxItem from '../../atoms/CheckBoxItem';
import TextFields from '../../molecules/TextFields';
import Dropdown from '../../molecules/Dropdown';
import HighlightMessage from '../../molecules/HighlightMessage';

import styles from './ShippingInfoBlock.module.scss';
import usePresenter from './ShippingInfoBlock.presenter';
import getProps from './ShippingInfoBlock.props';
import { ShippingInfoBlockCombinedProps } from './types';
import { CHECKOUT_SHIPPING_FORM } from '../../../lib/constants';


const ShippingInfoBlock: React.FC<ShippingInfoBlockCombinedProps> = (props) => {
  const {
    className,
    classes,
    blockTitle,
    highlightMessage,
    checkBoxItem,
    firstName,
    lastName,
    address,
    secondaryAddress,
    city,
    state,
    postalCode,
    country,
    phoneNumber,
    button,
    // MANUAL OVERRIDE STARTS
    showError,
    handleSubmit,
    showInvalidZipAgainstPOBoxCodeListError,
    invalidZipCodeAgainPOBoxCodeListError,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.shippingInfoBlock;

  let errorHighlightSection;
  let invalidZipCodeAgainPOBoxCodeListErrorSection;
  if (showError) {
    errorHighlightSection = <HighlightMessage
      className={cx(styles.highlightMessage, classes?.highlightMessage)}
      {...internalProps.highlightMessage}
      {...highlightMessage}/>;
  }
  // MANUAL OVERRIDE
  if (showInvalidZipAgainstPOBoxCodeListError) {
    invalidZipCodeAgainPOBoxCodeListErrorSection = <HighlightMessage
      className={cx(styles.highlightMessage, classes?.highlightMessage)}
      {...internalProps.highlightMessage}
      {...invalidZipCodeAgainPOBoxCodeListError}/>;
  }

  return (
    <form
      id={CHECKOUT_SHIPPING_FORM}
      onSubmit={(event) => {
        event.preventDefault();
        if (handleSubmit) {
          handleSubmit(event);
        }
      }}
      className={cx(currentStyle, className)}
    >
      <Text
        className={cx(styles.blockTitle, classes?.blockTitle)}
        {...internalProps.blockTitle}
        {...blockTitle}/>
      {/*  MANUAL OVERRIDE */}
      {errorHighlightSection}
      {invalidZipCodeAgainPOBoxCodeListErrorSection}
      <CheckBoxItem
        className={cx(styles.checkBoxItem, classes?.checkBoxItem)}
        {...internalProps.checkBoxItem}
        {...checkBoxItem}/>
      <div className={cx(styles.content, classes?.content)}>
        <div className={cx(styles.fieldGroup1, classes?.fieldGroup1)}>
          <TextFields
            className={cx(styles.firstName, classes?.firstName)}
            {...internalProps.firstName}
            {...firstName}/>
          <TextFields
            className={cx(styles.lastName, classes?.lastName)}
            {...internalProps.lastName}
            {...lastName}/>
        </div>
        <TextFields
          className={cx(styles.address, classes?.address)}
          {...internalProps.address}
          {...address}/>
        <TextFields
          className={cx(styles.secondaryAddress, classes?.secondaryAddress)}
          {...internalProps.secondaryAddress}
          {...secondaryAddress}/>
        <div className={cx(styles.fieldGroup2, classes?.fieldGroup2)}>
          <TextFields
            className={cx(styles.city, classes?.city)}
            {...internalProps.city}
            {...city}/>
          <Dropdown
            className={cx(styles.state, classes?.state)}
            {...internalProps.state}
            {...state}/>
        </div>
        <div className={cx(styles.fieldGroup3, classes?.fieldGroup3)}>
          <TextFields
            className={cx(styles.postalCode, classes?.postalCode)}
            {...internalProps.postalCode}
            {...postalCode}/>
          <Dropdown
            className={cx(styles.country, classes?.country)}
            {...internalProps.country}
            {...country}/>
        </div>
        <TextFields
          className={cx(styles.phoneNumber, classes?.phoneNumber)}
          {...internalProps.phoneNumber}
          {...phoneNumber}/>
        <Button
          className={cx(styles.button, classes?.button)}
          {...internalProps.button}
          {...button}
          buttonForm={CHECKOUT_SHIPPING_FORM}
          buttonType='submit'/>
      </div>
    </form>
  );
};

export default ShippingInfoBlock;


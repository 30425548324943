import React from 'react';
import cx from 'classnames';

import styles from './TicketAlertModal.module.scss';

import Button from '../../atoms/Button';
import Icon from '../../atoms/Icon';
import Text from '../../atoms/Text';
import { Modal } from 'react-bootstrap';
import { TicketAlertModalProps } from './types';
import getProps from './TicketAlertModal.props';

const TicketAlertModal: React.FC<TicketAlertModalProps> = ({
  closeButton,
  icon,
  title,
  message,
  primaryButton,
  className,
  open,
}) => {
  const internalProps = getProps('');

  return (
    <Modal
      className={styles.modal}
      dialogClassName={styles.modalDialog}
      show={open}
      onHide={closeButton?.onClick}
    >
      <div className={cx(styles.alertModal, className)}>
        <div className={styles.topContent}>
          <Button
            className={styles.closeButton}
            {...internalProps.closeButton}
            {...closeButton} />
          <div className={styles.content}>
            <Icon
              className={styles.icon}
              {...internalProps.icon}
              {...icon} />
            <Text
              className={styles.title}
              {...internalProps.title}
              {...title} />
          </div>
        </div>
        <div className={styles.bottomContent}>
          <Text
            className={styles.message}
            {...internalProps.message}
            {...message} />
          <div className={styles.buttonPair}>
            <Button
              className={styles.primaryButton}
              {...internalProps.primaryButton}
              {...primaryButton} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TicketAlertModal;

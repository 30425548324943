import { TextProps } from '../../atoms/Text';
import { EventDateStatusProps } from '../EventDateStatus';
import { TagProps } from '../Tag';


type TicketStatusInternalProps = {
  date?: TextProps;
  quantity?: TextProps;
  amount?: TextProps;
  tag?: TagProps;
  title?: TextProps;
  eventDateStatus?: EventDateStatusProps;
  location?: TextProps;
  date1?: TextProps;
  time?: TextProps;
};

type TicketStatusPropsValues = {
  'Negative': TicketStatusInternalProps;
  'Positive': TicketStatusInternalProps;
};

const propValues: TicketStatusPropsValues = {
  'Negative': {
    date: {
      type: 'CardBody',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    quantity: {
      type: 'CardBody',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    amount: {
      type: 'CardBody',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    tag: {
      type: 'Negative',
      size: 'Regular',
    },
    title: {
      type: 'CardBody',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    eventDateStatus: {
      type: 'WithTag',
    },
    location: {
      type: 'CardBody',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
  },
  'Positive': {
    date: {
      type: 'CardBody',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    quantity: {
      type: 'CardBody',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    amount: {
      type: 'CardBody',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    tag: {
      type: 'Positive',
      size: 'Regular',
    },
    title: {
      type: 'CardBody',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    date1: {
      type: 'CardBody',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    time: {
      type: 'CardBody',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    eventDateStatus: {
      type: 'Default',
    },
    location: {
      type: 'CardBody',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
  },
};

const getProps = (type: string): TicketStatusInternalProps => {
  const values: TicketStatusInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import React from 'react';
import cx from 'classnames';

import SecondaryBanner from '../../molecules/SecondaryBanner';

import styles from './SecondaryBannerDarkBlock.module.scss';
import usePresenter from './SecondaryBannerDarkBlock.presenter';
import getProps from './SecondaryBannerDarkBlock.props';
import { SecondaryBannerDarkBlockCombinedProps } from './types';


const SecondaryBannerDarkBlock: React.FC<SecondaryBannerDarkBlockCombinedProps> = (props) => {
  const {
    className,
    classes,
    secondaryBanner,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.secondaryBannerDarkBlock;

  return (
    <div className={cx(currentStyle, className)}>
      <SecondaryBanner
        className={cx(styles.secondaryBanner, classes?.secondaryBanner)}
        {...internalProps.secondaryBanner}
        {...secondaryBanner}/>
    </div>
  );
};

export default SecondaryBannerDarkBlock;


import React from 'react';
import cx from 'classnames';

import ThumbnailCard from '../../molecules/ThumbnailCard';

import styles from './ThumbnailCardList.module.scss';
import usePresenter from './ThumbnailCardList.presenter';
import getProps from './ThumbnailCardList.props';
import { ThumbnailCardListCombinedProps } from './types';


const ThumbnailCardList: React.FC<ThumbnailCardListCombinedProps> = (props) => {
  const {
    className,
    classes,
    thumbnailCards,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.thumbnailCardList;

  const thumbnailCardViews = thumbnailCards?.map((thumbnailCard, index) => (
    <ThumbnailCard
      key={index}
      className={cx(styles.thumbnailCard, classes?.thumbnailCard)}
      {...internalProps.thumbnailCard}
      {...thumbnailCard} />
  )) || [];

  return (
    <div className={cx(currentStyle, className)}>
      {thumbnailCardViews}
    </div>
  );
};

export default ThumbnailCardList;


import { SubmenuItemCombinedProps } from '../../molecules/SubmenuItem/types';
import { SubmenuItemListCombinedProps } from '../../molecules/SubmenuItemList/types';
import { SubmenuWithoutTabsCombinedProps } from './types';

const usePresenter = (props: SubmenuWithoutTabsCombinedProps): SubmenuWithoutTabsCombinedProps => {
  const { data, id, onItemClicked } = props;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const eventObject = data?.find(category => category.id === id);

  const events: SubmenuItemListCombinedProps = {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    submenuItems: eventObject?.children.map((event): SubmenuItemCombinedProps => {
      return {
        text: {
          value: event.name,
        },
        linkPath: event.slug,
        externalLink: event.external_link,
        onItemClicked: () => {
          onItemClicked?.(event); 
        },
      };
    }),
  };

  return {
    submenuItemList: events,
  };

};

export default usePresenter;

import { SpecialEventCardProps } from '../../molecules/SpecialEventCard';


type CardSpecificEventsListInternalProps = {
  specialEventCard?: SpecialEventCardProps;
};

type CardSpecificEventsListPropsValues = {
  '': CardSpecificEventsListInternalProps;
};

const propValues: CardSpecificEventsListPropsValues = {
  '': {
    specialEventCard: {
      style: 'Dark',
    },
  },
};

const getProps = (type: string): CardSpecificEventsListInternalProps => {
  const values: CardSpecificEventsListInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

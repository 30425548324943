import React from 'react';
import cx from 'classnames';

import Text from '../../atoms/Text';
import Icon from '../../atoms/Icon';
import Logo from '../../atoms/Logo';

import styles from './IconTextDescription.module.scss';
import usePresenter from './IconTextDescription.presenter';
import getProps from './IconTextDescription.props';
import { IconTextDescriptionCombinedProps } from './types';


const IconTextDescription: React.FC<IconTextDescriptionCombinedProps> = (props) => {
  const {
    type,
    className,
    classes,
    icon,
    description,
    logo,
  } = usePresenter(props);

  const variantName = `${type}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`iconTextDescription${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Default':
    case 'Large': { // MANUAL OVERRIDE
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Icon
            className={cx(styles.icon, classes?.icon)}
            {...internalProps.icon}
            {...icon}/>
          <Text
            className={cx(styles.description, classes?.description)}
            {...internalProps.description}
            {...description}/>
        </div>
      );
      break;
    }
    case 'WithLogo': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Icon
            className={cx(styles.icon, classes?.icon)}
            {...internalProps.icon}
            {...icon}/>
          <Text
            className={cx(styles.description, classes?.description)}
            {...internalProps.description}
            {...description}/>
          <div className={cx(styles.logoContainer, classes?.logoContainer)}>
            <Logo
              className={cx(styles.logo, classes?.logo)}
              {...internalProps.logo}
              {...logo}/>
          </div>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default IconTextDescription;


import { ImageProps } from '../../atoms/Image';
import { TextProps } from '../../atoms/Text';


type MaintenanceBlockInternalProps = {
  image?: ImageProps;
  title?: TextProps;
  message?: TextProps;
};

type MaintenanceBlockPropsValues = {
  '': MaintenanceBlockInternalProps;
};

const propValues: MaintenanceBlockPropsValues = {
  '': {
    title: {
      type: 'Subheading',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    message: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Center',
    },
  },
};

const getProps = (type: string): MaintenanceBlockInternalProps => {
  const values: MaintenanceBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

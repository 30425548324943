import React from 'react';
import cx from 'classnames';

import Text from '../../atoms/Text';
import Button from '../../atoms/Button';
import TextFields from '../../molecules/TextFields';
import ToolTip from '../../atoms/ToolTip';

import styles from './CustomerInfoBlock.module.scss';
import usePresenter from './CustomerInfoBlock.presenter';
import getProps from './CustomerInfoBlock.props';
import { CustomerInfoBlockCombinedProps } from './types';


const CustomerInfoBlock: React.FC<CustomerInfoBlockCombinedProps> = (props) => {
  const {
    className,
    classes,
    blockTitle,
    emailField,
    message,
    button,
    // MANUAL OVERRIDE STARTS
    emailLabel,
    emailToolTip,
    toolTipIcon,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.customerInfoBlock;

  return (
    <div className={cx(currentStyle, className)}>
      <Text
        className={cx(styles.blockTitle, classes?.blockTitle)}
        {...internalProps.blockTitle}
        {...blockTitle}/>
      {/* MANUAL OVERRIDE STARTS - added emailLabel */}
      <Text
        className={cx(styles.emailLabel, classes?.emailLabel)}
        {...internalProps.emailLabel}
        {...emailLabel}/>
      {/* MANUAL OVERRIDE ENDS */}
      <div className={cx(styles.content, classes?.content)}>
        <Text
          className={cx(styles.emailField, classes?.emailField)}
          {...internalProps.emailField}
          {...emailField}/>
        {/* MANUAL OVERRIDE STARTS - added a tooltip */}
        <ToolTip {...emailToolTip}>
          <Button className={styles.icon} {...internalProps.toolTipIcon} {...toolTipIcon} />
        </ToolTip>
        {/* MANUAL OVERRIDE ENDS */}
      </div>
      {/* MANUAL OVERRIDE - removed 2nd emailField and moved message and button outside of content */}
      <Text
        className={cx(styles.message, classes?.message)}
        {...internalProps.message}
        {...message}/>
      <Button
        className={cx(styles.button, classes?.button)}
        {...internalProps.button}
        {...button}/>
      {/* MANUAL OVERRIDE ENDS */}
    </div>
  );
};

export default CustomerInfoBlock;


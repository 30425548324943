import { IconProps } from '../Icon';
import { TextProps } from '../Text';


type NewDropdownInternalProps = {
  text?: TextProps;
  icon?: IconProps;
};

type NewDropdownPropsValues = {
  'ClosedDark': NewDropdownInternalProps;
  'ClosedLight': NewDropdownInternalProps;
  'DisabledDark': NewDropdownInternalProps;
  'DisabledLight': NewDropdownInternalProps;
  'OpenDark': NewDropdownInternalProps;
  'OpenLight': NewDropdownInternalProps;
};

const propValues: NewDropdownPropsValues = {
  'ClosedDark': {
    text: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'BaseLight',
      align: 'Left',
    },
    icon: {
      asset: 'ChevronDown',
      style: 'White',
    },
  },
  'ClosedLight': {
    text: {
      type: 'Body',
      size: 'Large', // MANUAL OVERRIDE
      style: 'Regular',
      colour: 'ActionBase',
      align: 'Left',
    },
    icon: {
      asset: 'ChevronDown',
      style: 'ActionHover',
    },
  },
  'DisabledDark': {
    text: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'BaseLight',
      align: 'Left',
    },
    icon: {
      asset: 'ChevronDown',
      style: 'White',
    },
  },
  'DisabledLight': {
    text: {
      type: 'Body',
      size: 'Large', // MANUAL OVERRIDE
      style: 'Regular',
      colour: 'ActionBase',
      align: 'Left',
    },
    icon: {
      asset: 'ChevronDown',
      style: 'ActionHover',
    },
  },
  'OpenDark': {
    text: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'BaseLight',
      align: 'Left',
    },
    icon: {
      asset: 'ChevronDown',
      style: 'White',
    },
  },
  'OpenLight': {
    text: {
      type: 'Body',
      size: 'Large', // MANUAL OVERRIDE
      style: 'Regular',
      colour: 'ActionBase',
      align: 'Left',
    },
    icon: {
      asset: 'ChevronDown',
      style: 'ActionHover',
    },
  },
};

const getProps = (type: string): NewDropdownInternalProps => {
  const values: NewDropdownInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

export const getTransform = (transform = ''): string => {
  const transformSplit = transform.split('m');
  return transform ? `matrix(${transformSplit[1]})` : transform;
};

export const isOrientationLandscape = (orientationType: OrientationType): boolean => {
  return orientationType === 'landscape-primary' || orientationType === 'landscape-secondary';
};

export const checkIfMobile = (isLandscape: boolean): boolean => {
  // the height for the map + 'Choose ticket' Label + one ticket
  const mapAndLabelHeight = 432;
  let isMobile: boolean;
  // To check if the device is mobile
  // comparing height if landscape
  if (isLandscape) {
    isMobile = Math.min(window.screen.height, window.outerHeight) < mapAndLabelHeight;
  } else {
    isMobile = Math.min(window.screen.width, window.outerWidth) < mapAndLabelHeight;
  }
  return isMobile;
};

export const getTooltipPositionFromDimensions = (
  x: number,
  y: number,
  width: number,
  height: number,
): { x: number, y: number } => {
  // For most map elements the best placement will be at the top of the section being hovered, but the ones that wrap
  // around the map should be centered in the middle of the map.
  const bestY = height > 255 ? y + height / 2 : y;
  return { x: x + width / 2, y: bestY };
};
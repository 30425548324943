import { TextProps } from '../../atoms/Text';
import { ButtonProps } from '../../atoms/Button';
import { DividerProps } from '../../atoms/Divider';
import { IconProps } from '../../atoms/Icon';


type TicketInformationInternalProps = {
  title?: TextProps;
  location?: TextProps;
  availability?: TextProps;
  value?: TextProps;
  valueType?: TextProps;
  other?: TextProps;
  divider?: DividerProps;
  icon?: IconProps;
  pointsValue?: TextProps;
  pointsValueType?: TextProps; // MANUAL OVERRIDE
  button?: ButtonProps;
};

type TicketInformationPropsValues = {
  'DefaultDefault': TicketInformationInternalProps;
  'DefaultRounded': TicketInformationInternalProps;
  'LargeTitleDefault': TicketInformationInternalProps;
  'MoreDetailsDefault': TicketInformationInternalProps;
  'WithPinDefault': TicketInformationInternalProps;
};

const propValues: TicketInformationPropsValues = {
  'DefaultDefault': {
    title: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    location: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    availability: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    value: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    valueType: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    other: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    divider: {
      type: 'Horizontal',
      style: 'Thin',
      colour: 'DigitalGrey10',
    },
  },
  'DefaultRounded': {
    title: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    location: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    availability: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    value: {
      type: 'Body',
      size: 'Small',
      style: 'SemiBold',
      colour: 'SubduedDark',
      align: 'Left',
    },
    valueType: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    pointsValue: {
      type: 'Body',
      size: 'Small',
      style: 'SemiBold',
      colour: 'SubduedDark',
      align: 'Left',
    },
    pointsValueType: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    icon: {
      asset: 'ArrowRightFilled',
      style: 'ActionHover',
    },
    divider: {
      type: 'Horizontal',
      style: 'Thin',
      colour: 'DigitalGrey10',
    },
  },
  'LargeTitleDefault': {
    title: {
      type: 'Subheading',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    location: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    value: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    valueType: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    other: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    divider: {
      type: 'Horizontal',
      style: 'Thin',
      colour: 'DigitalGrey10',
    },
  },
  'MoreDetailsDefault': {
    title: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    location: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    availability: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    value: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    valueType: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    pointsValue: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    other: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    divider: {
      type: 'Horizontal',
      style: 'Thin',
      colour: 'DigitalGrey10',
    },
  },
  'WithPinDefault': {
    title: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    availability: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    value: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    valueType: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    other: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    button: {
      type: 'Icon',
      style: 'Text',
      size: 'Small',
    },
    divider: {
      type: 'Horizontal',
      style: 'Thin',
      colour: 'DigitalGrey10',
    },
  },
};

const getProps = (type: string): TicketInformationInternalProps => {
  const values: TicketInformationInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import { TextItemProps } from '../TextItem';


type SeatInfoInternalProps = {
  section?: TextItemProps;
  row?: TextItemProps;
  quantity?: TextItemProps;
};

type SeatInfoPropsValues = {
  '': SeatInfoInternalProps;
};

const propValues: SeatInfoPropsValues = {
  '': {
    section: {
      style: 'SubduedLabel',
    },
    row: {
      style: 'SubduedLabel',
    },
    quantity: {
      style: 'SubduedLabel',
    },
  },
};

const getProps = (type: string): SeatInfoInternalProps => {
  const values: SeatInfoInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import React from 'react';
import StepContent from '../StepContent';
import Spinner from '../../atoms/Spinner';

import styles from './OnBoardingContent.module.scss';
import getProps from './OnBoardingContent.props';
import { OnBoardingContentCombinedProps } from './types';
import usePresenter from './OnBoardingContent.presenter';

const OnBoardingContent: React.FC<OnBoardingContentCombinedProps> = (props) => {

  const {
    step,
    stepContent,
    button,
    footer,
    isLoading,
    changeIsStepRendered,
  } = usePresenter(props);

  const internalProps = getProps('');

  let contentView;

  const loadingView = (
    <div className={styles.loader}>
      <Spinner></Spinner>
    </div>
  );

  if (stepContent && step) {
    contentView = (
      <StepContent {...stepContent[step - 1]}
        button={{
          ...internalProps.button,
          ...button,
        }}
        footer={{
          ...internalProps.footer,
          ...footer,
        }}
        step={step}
        changeIsStepRendered={changeIsStepRendered}
      />
    );
  }

  return (
    <>
      { isLoading ? loadingView : <></> }
      <div className={styles.container}>
        {contentView}
      </div>
    </>
  );
};

export default OnBoardingContent;

import React from 'react';
import cx from 'classnames';
import { Modal } from 'react-bootstrap';

import Image from '../../atoms/Image';
import Button from '../../atoms/Button';
import Text from '../../atoms/Text';

import styles from './EventDetailsModal.module.scss';
import usePresenter from './EventDetailsModal.presenter';
import getProps from './EventDetailsModal.props';
import { EventDetailsModalCombinedProps } from './types';


const EventDetailsModal: React.FC<EventDetailsModalCombinedProps> = (props) => {
  const {
    className,
    classes,
    button,
    bannerImage,
    title,
    eventInfo,
    title1,
    description1,
    description,
    // MANUAL OVERRIDE STARTS
    show,
    closeModal,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.eventDetailsModal;

  return (
    <Modal
      className={styles.modal}
      dialogClassName={styles.modalDialog}
      show={show}
      onHide={closeModal}
    >
      <div className={cx(currentStyle, className)}>
        <div className={cx(styles.buttonContainer, classes?.buttonContainer)}>
          <Button
            className={cx(styles.button, classes?.button)}
            {...internalProps.button}
            {...button}/>
        </div>
        <div className={cx(styles.content, classes?.content)}>
          <div className={cx(styles.topContent, classes?.topContent)}>
            <Image
              className={cx(styles.bannerImage, classes?.bannerImage)}
              {...internalProps.bannerImage}
              {...bannerImage}/>
            <div className={cx(styles.rightContent, classes?.rightContent)}>
              <div className={cx(styles.description, classes?.description)}>
                <Text
                  className={cx(styles.title, classes?.title)}
                  {...internalProps.title}
                  {...title}/>
                <Text
                  className={cx(styles.eventInfo, classes?.eventInfo)}
                  {...internalProps.eventInfo}
                  {...eventInfo}/>
              </div>
            </div>
          </div>
          <div className={cx(styles.rightContent, classes?.rightContent)}>
            <div className={cx(styles.content, classes?.content)}>
              <Text
                className={cx(styles.title, classes?.title)}
                {...internalProps.title}
                {...title}/>
              <Text
                className={cx(styles.eventInfo, classes?.eventInfo)}
                {...internalProps.eventInfo}
                {...eventInfo}/>
            </div>
            <Text
              className={cx(styles.title, classes?.title)}
              {...internalProps.title}
              {...title}/>
            <Text
              className={cx(styles.description, classes?.description)}
              {...internalProps.description}
              {...description}/>
          </div>
          <div className={cx(styles.bottomContent, classes?.bottomContent)}>
            <Text
              className={cx(styles.title, classes?.title)}
              {...internalProps.title}
              {...title}/>
            <Text
              className={cx(styles.description, classes?.description)}
              {...internalProps.description}
              {...description}/>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EventDetailsModal;


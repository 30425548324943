import { ThumbnailTextItemProps } from '../ThumbnailTextItem';


type ThumbnailTextListInternalProps = {
  thumbnailTextItem?: ThumbnailTextItemProps;
};

type ThumbnailTextListPropsValues = {
  '': ThumbnailTextListInternalProps;
};

const propValues: ThumbnailTextListPropsValues = {
  '': {
    thumbnailTextItem: {
    },
  },
};

const getProps = (type: string): ThumbnailTextListInternalProps => {
  const values: ThumbnailTextListInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import { DividerProps } from '../../atoms/Divider';
import { IconProps } from '../../atoms/Icon';
import { TextProps } from '../../atoms/Text';


type HighlightMessageInternalProps = {
  divider?: DividerProps;
  icon?: IconProps;
  message?: TextProps;
};

type HighlightMessagePropsValues = {
  'Error': HighlightMessageInternalProps;
  'Info': HighlightMessageInternalProps;
};

const propValues: HighlightMessagePropsValues = {
  'Error': {
    divider: {
      type: 'Vertical',
      style: 'Thick',
      colour: 'CP1Red',
    },
    icon: {
      asset: 'CloseCircleFill',
      style: 'DigitalGrey100',
    },
    message: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
  },
  'Info': {
    divider: {
      type: 'Vertical',
      style: 'Thick',
      colour: 'InteractionBlue50',
    },
    icon: {
      asset: 'Mail',
      style: 'DigitalGrey80',
    },
    message: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
  },
};

const getProps = (type: string): HighlightMessageInternalProps => {
  const values: HighlightMessageInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

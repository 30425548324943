import { TextProps } from '../../atoms/Text';
import { ButtonProps } from '../../atoms/Button';
import { DividerProps } from '../../atoms/Divider';
import { EventStatusTagProps } from '../../atoms/EventStatusTag';
import { EventTypeTagProps } from '../../atoms/EventTypeTag';
import { IconProps } from '../../atoms/Icon';


type EventCardNoImageInternalProps = {
  eventTypeTag?: EventTypeTagProps;
  eventStatusTag?: EventStatusTagProps;
  titleText?: TextProps;
  dateAndTimeText?: TextProps;
  locationAndPriceText?: TextProps;
  divider?: DividerProps;
  actionText?: TextProps;
  icon?: IconProps;
  dateText?: TextProps;
  text?: TextProps;
  button?: ButtonProps;
};

type EventCardNoImagePropsValues = {
  '': EventCardNoImageInternalProps;
};

const propValues: EventCardNoImagePropsValues = {
  '': {
    dateText: {
      type: 'Body',
      size: 'Large',
      style: 'SemiBold',
      colour: 'SubduedDark',
      align: 'Left',
    },
    text: {
      type: 'CardBody',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    divider: {
      type: 'Vertical',
      style: 'Thin',
      colour: 'DigitalGrey5',
    },
    eventTypeTag: {
      type: 'Blue',
    },
    eventStatusTag: {
      type: 'JustAnnounced',
    },
    titleText: {
      type: 'Body',
      size: 'Large',
      style: 'SemiBold',
      colour: 'BaseDark',
      align: 'Left',
    },
    dateAndTimeText: {
      type: 'CardBody',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    locationAndPriceText: {
      type: 'CardBody',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    button: {
      type: 'Text',
      style: 'Contained',
      size: 'Medium',
    },
    // MANUAL OVERRIDE STARTS
    actionText: { 
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'ActionBase',
      align: 'Left',
    },
    icon: {
      asset: 'ArrowRightFilled',
      style: 'ActionBase',
    },
    // MANUAL OVERRIDE ENDS
  },
};

const getProps = (type: string): EventCardNoImageInternalProps => {
  const values: EventCardNoImageInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

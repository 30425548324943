import { ButtonProps } from '../../atoms/Button';
import { CheckBoxItemCombinedProps } from '../../atoms/CheckBoxItem/types';
import { TextProps } from '../../atoms/Text';
import { HighlightMessageProps } from '../../molecules/HighlightMessage';
import { TextFieldsProps } from '../../molecules/TextFields';
import { TextItemProps } from '../../molecules/TextItem';


type BillingInfoBlockInternalProps = {
  blockTitle?: TextProps;
  highlightMessage?: HighlightMessageProps;
  // MANUAL OVERRIDE STARTS - replaced TextField with TextItem
  firstName?: TextItemProps; // MANUAL OVERRIDE
  lastName?: TextItemProps;
  address?: TextItemProps;
  secondaryAddress?: TextItemProps; // resolved a typo
  city?: TextItemProps;
  state?: TextItemProps;
  postalCode?: TextItemProps;
  country?: TextItemProps;
  phoneNumber?: TextItemProps;
  // MANUAL OVERRIDE ENDS
  // MANUAL OVERRIDE STARTS - added props
  checkBoxItem?: CheckBoxItemCombinedProps;
  checkBoxItemError?: TextProps;
  phoneNumberEditButton?: ButtonProps;
  phoneNumberField?: TextFieldsProps;
  phoneNumberSaveButton?: ButtonProps;
  toolTipIcon?: ButtonProps;
  // MANUAL OVERRIDE ENDS
  button?: ButtonProps;
};

type BillingInfoBlockPropsValues = {
  '': BillingInfoBlockInternalProps;
};

const propValues: BillingInfoBlockPropsValues = {
  '': {
    blockTitle: {
      type: 'Subheading',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    highlightMessage: {
      type: 'Error',
    },
    // MANUAL OVERRIDE STARTS - replaced TextField with TextItem
    firstName: {
      style: 'BoldedLabel',
    },
    lastName: {
      style: 'BoldedLabel',
    },
    address: {
      style: 'BoldedLabel',
    },
    secondaryAddress: { // resolved a typo
      style: 'BoldedLabel',
    },
    city: {
      style: 'BoldedLabel',
    },
    state: {
      style: 'BoldedLabel',
    },
    postalCode: {
      style: 'BoldedLabel',
    },
    country: {
      style: 'BoldedLabel',
    },
    phoneNumber: {
      style: 'BoldedLabel',
    },
    // MANUAL OVERRIDE ENDS
    // MANUAL OVERRIDE STARTS - added props
    phoneNumberEditButton: {
      type: 'Text',
      style: 'Contained',
      size: 'ExtraSmall',
    } as ButtonProps,
    phoneNumberSaveButton: {
      type: 'Text',
      style: 'Contained',
      size: 'ExtraSmall',
    } as ButtonProps,
    phoneNumberField: {
      state: 'Default',
    } as TextFieldsProps,
    checkBoxItem: {
      state: 'Unselected',
    },
    checkBoxItemError: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'Negative',
      align: 'Left',
    },
    toolTipIcon: {
      type: 'Icon',
      style: 'Text',
      size: 'Medium',
    },
    // MANUAL OVERRIDE ENDS
    button: {
      type: 'Text',
      style: 'Contained',
      size: 'Large',
    },
  },
};

const getProps = (type: string): BillingInfoBlockInternalProps => {
  const values: BillingInfoBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import React from 'react';
import cx from 'classnames';

import ThumbnailTextItem from '../ThumbnailTextItem';

import styles from './ThumbnailTextList.module.scss';
import usePresenter from './ThumbnailTextList.presenter';
import getProps from './ThumbnailTextList.props';
import { ThumbnailTextListCombinedProps } from './types';


const ThumbnailTextList: React.FC<ThumbnailTextListCombinedProps> = (props) => {
  const {
    className,
    classes,
    thumbnailTextItems,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.thumbnailTextList;

  const thumbnailTextItemViews = thumbnailTextItems?.map((thumbnailTextItem, index) => (
    <ThumbnailTextItem
      key={index}
      className={cx(styles.thumbnailTextItem, classes?.thumbnailTextItem)}
      {...internalProps.thumbnailTextItem}
      {...thumbnailTextItem} />
  )) || [];

  return (
    <div className={cx(currentStyle, className)}>
      {thumbnailTextItemViews}
    </div>
  );
};

export default ThumbnailTextList;


import React from 'react';
import cx from 'classnames';

import Text from '../../atoms/Text';

import styles from './InfoHeader.module.scss';
import usePresenter from './InfoHeader.presenter';
import getProps from './InfoHeader.props';
import { InfoHeaderCombinedProps } from './types';


const InfoHeader: React.FC<InfoHeaderCombinedProps> = (props) => {
  const {
    className,
    classes,
    title,
    details,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.infoHeader;

  return (
    <div className={cx(currentStyle, className)}>
      <Text
        className={cx(styles.title, classes?.title)}
        {...internalProps.title}
        {...title}/>
      <Text
        className={cx(styles.details, classes?.details)}
        {...internalProps.details}
        {...details}/>
    </div>
  );
};

export default InfoHeader;


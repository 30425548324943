import { IconProps } from '../Icon';
import { TextProps } from '../Text';


type NewDropdownMenuItemInternalProps = {
  icon?: IconProps;
  text?: TextProps;
  trailingIcon?: IconProps;
};

type NewDropdownMenuItemPropsValues = {
  'IconTextSelected': NewDropdownMenuItemInternalProps;
  'IconTextUnselected': NewDropdownMenuItemInternalProps;
  'TextUnselected': NewDropdownMenuItemInternalProps;
  'TextCriticalUnselected': NewDropdownMenuItemInternalProps;
};

const propValues: NewDropdownMenuItemPropsValues = {
  'IconTextSelected': {
    icon: {
      asset: 'MyLocation',
      style: 'ActionBase',
    },
    text: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'ActionHover',
      align: 'Left',
    },
    trailingIcon: {
      asset: 'CheckmarkInCircleFilled', // MANUAL OVERRIDE
      style: 'Green60', // MANUAL OVERRIDE
    },
  },
  'IconTextUnselected': {
    icon: {
      asset: 'MyLocation',
      style: 'DigitalGrey100',
    },
    text: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
  },
  'TextUnselected': {
    text: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
  },
  'TextCriticalUnselected': {
    text: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'Negative',
      align: 'Left',
    },
  },
};

const getProps = (type: string): NewDropdownMenuItemInternalProps => {
  const values: NewDropdownMenuItemInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

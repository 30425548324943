import { ButtonProps } from '../../atoms/Button';
import { DividerProps } from '../../atoms/Divider';
import { IconProps } from '../../atoms/Icon';
import { TextProps } from '../../atoms/Text';


type CardNavigationInternalProps = {
  button?: ButtonProps;
  divider?: DividerProps;
  backButton?: ButtonProps;
  title?: TextProps;
  closeButton?: ButtonProps;
  icon?: IconProps;
};

type CardNavigationPropsValues = {
  'DefaultLightBackground': CardNavigationInternalProps;
  'WithCloseOptionDarkBackground': CardNavigationInternalProps;
  'WithCloseOptionLightBackground': CardNavigationInternalProps;
};

const propValues: CardNavigationPropsValues = {
  'DefaultLightBackground': {
    button: {
      type: 'IconText',
      style: 'Text',
      size: 'Small',
    },
    divider: {
      type: 'Horizontal',
      style: 'Thin',
      colour: 'DigitalGrey15', // MANUAL OVERRIDE
    },
  },
  'WithCloseOptionDarkBackground': {
    backButton: {
      type: 'Icon',
      style: 'Text',
      size: 'Small',
    },
    title: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'BaseLight',
      align: 'Left',
    },
    closeButton: {
      type: 'Icon',
      style: 'Text',
      size: 'Small',
    },
  },
  'WithCloseOptionLightBackground': {
    button: {
      type: 'IconText',
      style: 'Text',
      size: 'Small',
    },
    icon: {
      asset: 'Close',
      style: 'DigitalGrey80',
    },
    divider: {
      type: 'Horizontal',
      style: 'Thin',
      colour: 'DigitalGrey15', // MANUAL OVERRIDE
    },
    // MANUAL OVERRIDE STARTS
    closeButton: {
      type: 'Icon',
      style: 'Text',
      size: 'Small',
    },
    // MANUAL OVERRIDE ENDS
  },
};

const getProps = (type: string): CardNavigationInternalProps => {
  const values: CardNavigationInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import { ButtonProps } from '../../atoms/Button';
import { DividerProps } from '../../atoms/Divider';
import { TextProps } from '../../atoms/Text';


type TicketQuantityInternalProps = {
  divider?: DividerProps;
  label?: TextProps;
  minusButton?: ButtonProps; // MANUAL OVERRIDE
  label1?: TextProps;
  plusButton?: ButtonProps; // MANUAL OVERRIDE
  divider1?: DividerProps;
};

type TicketQuantityPropsValues = {
  '': TicketQuantityInternalProps;
};

const propValues: TicketQuantityPropsValues = {
  '': {
    divider: {
      type: 'Horizontal',
      style: 'Thin',
      colour: 'DigitalGrey10',
    },
    label: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    // MANUAL OVERRIDE
    minusButton: {
      type: 'Icon',
      style: 'Text',
      size: 'Small',
    },
    label1: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Center',
    },
    // MANUAL OVERRIDE
    plusButton: {
      type: 'Icon',
      style: 'Text',
      size: 'Small',
    },
    divider1: {
      type: 'Horizontal',
      style: 'Thin',
      colour: 'DigitalGrey10',
    },
  },
};

const getProps = (type: string): TicketQuantityInternalProps => {
  const values: TicketQuantityInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import { ClickableSectionProps } from '../../atoms/ClickableSection';
import { DividerProps } from '../../atoms/Divider';
import { ImageProps } from '../../atoms/Image';
import { TextProps } from '../../atoms/Text';


type TicketAvailabilityCardSoldOutInternalProps = {
  image?: ImageProps;
  label?: TextProps;
  message?: TextProps;
  divider?: DividerProps;
  clickableSection?: ClickableSectionProps;
};

type TicketAvailabilityCardSoldOutPropsValues = {
  '': TicketAvailabilityCardSoldOutInternalProps;
};

const propValues: TicketAvailabilityCardSoldOutPropsValues = {
  '': {
    image: {
    },
    label: {
      type: 'Body',
      size: 'Small',
      style: 'SemiBold',
      colour: 'Negative',
      align: 'Left',
    },
    message: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Center',
    },
    divider: {
      type: 'Horizontal',
      style: 'Thin',
      colour: 'DigitalGrey15',
    },
    clickableSection: {
    },
  },
};

const getProps = (type: string): TicketAvailabilityCardSoldOutInternalProps => {
  const values: TicketAvailabilityCardSoldOutInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import { StepperItemProps } from '../../molecules/StepperItem';


type StepperListInternalProps = {
  stepperItem?: StepperItemProps;
};

type StepperListPropsValues = {
  '': StepperListInternalProps;
};

const propValues: StepperListPropsValues = {
  '': {
    stepperItem: {
      type: 'Unselected',
      state: 'Incomplete',
    },
  },
};

const getProps = (type: string): StepperListInternalProps => {
  const values: StepperListInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import { MyTicketsBlockProps } from '../../blocks/MyTicketsBlock';


type MyTicketsPageInternalProps = {
  myTicketsBlock?: MyTicketsBlockProps;
};

type MyTicketsPagePropsValues = {
  '': MyTicketsPageInternalProps;
};

const propValues: MyTicketsPagePropsValues = {
  '': {
    myTicketsBlock: {
      state: 'Filled',
    },
  },
};

const getProps = (type: string): MyTicketsPageInternalProps => {
  const values: MyTicketsPageInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import { ButtonProps } from '../../atoms/Button';
import { DividerProps } from '../../atoms/Divider';
import { IconProps } from '../../atoms/Icon';
import { TeamLogoProps } from '../../atoms/TeamLogo';


type ThumbnailTextItemInternalProps = {
  teamLogo?: TeamLogoProps;
  button?: ButtonProps;
  icon?: IconProps;
  divider?: DividerProps;
};

type ThumbnailTextItemPropsValues = {
  '': ThumbnailTextItemInternalProps;
};

const propValues: ThumbnailTextItemPropsValues = {
  '': {
    teamLogo: {
    },
    button: {
      type: 'Text',
      style: 'TextDark',
      size: 'Medium',
    },
    icon: {
      asset: 'ChevronRight',
      style: 'DigitalGrey100',
    },
    divider: {
      type: 'Horizontal',
      style: 'Thin',
      colour: 'DigitalGrey15',
    },
  },
};

const getProps = (type: string): ThumbnailTextItemInternalProps => {
  const values: ThumbnailTextItemInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import { EventCardDarkProps } from '../../molecules/EventCardDark';


type EventCardDarkListInternalProps = {
  eventCardDark?: EventCardDarkProps;
};

type EventCardDarkListPropsValues = {
  '': EventCardDarkListInternalProps;
};

const propValues: EventCardDarkListPropsValues = {
  '': {
    eventCardDark: {
    },
  },
};

const getProps = (type: string): EventCardDarkListInternalProps => {
  const values: EventCardDarkListInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import { ButtonProps } from '../../atoms/Button';
import { TextProps } from '../../atoms/Text';


type NavMenuItemInternalProps = {
  backButton?: ButtonProps;
  title?: TextProps;
  nextButton?: ButtonProps;
};

type NavMenuItemPropsValues = {
  'Back': NavMenuItemInternalProps;
  'Critical': NavMenuItemInternalProps;
  'Default': NavMenuItemInternalProps;
  'Next': NavMenuItemInternalProps;
};

const propValues: NavMenuItemPropsValues = {
  'Back': {
    backButton: {
      type: 'Icon',
      style: 'Text',
      size: 'Small',
    },
    title: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
  },
  'Critical': {
    title: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'Negative',
      align: 'Left',
    },
  },
  'Default': {
    title: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
  },
  'Next': {
    title: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
    nextButton: {
      type: 'Icon',
      style: 'Text',
      size: 'Small',
    },
  },
};

const getProps = (type: string): NavMenuItemInternalProps => {
  const values: NavMenuItemInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

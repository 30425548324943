import { AdditionalTicketInformationListProps } from '../../molecules/AdditionalTicketInformationList';
import { CardInfoHeaderProps } from '../../molecules/CardInfoHeader';
import { ExclusiveEventBannerProps } from '../../molecules/ExclusiveEventBanner';
import { TicketInformationProps } from '../../molecules/TicketInformation';
import { TicketQuantitySelectionProps } from '../../molecules/TicketQuantitySelection';
import { TicketSelectionInformationProps } from '../../molecules/TicketSelectionInformation';


type GeneralPrecheckoutBlockInternalProps = {
  exclusiveEventBanner?: ExclusiveEventBannerProps;
  cardInfoHeader?: CardInfoHeaderProps;
  ticketInformation?: TicketInformationProps;
  additionalTicketInformationList?: AdditionalTicketInformationListProps;
  ticketQuantitySelection?: TicketQuantitySelectionProps;
};

type GeneralPrecheckoutBlockPropsValues = {
  '': GeneralPrecheckoutBlockInternalProps;
};

const propValues: GeneralPrecheckoutBlockPropsValues = {
  '': {
    exclusiveEventBanner: {
    },
    cardInfoHeader: {
    },
    ticketInformation: {
      type: 'LargeTitle', // MANUAL OVERRIDE
    },
    additionalTicketInformationList: {
    },
    ticketQuantitySelection: {
    },
  },
};

const getProps = (type: string): GeneralPrecheckoutBlockInternalProps => {
  const values: GeneralPrecheckoutBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import { IconProps } from '../../atoms/Icon';
import { TextProps } from '../../atoms/Text';


type DateAndTimeInternalProps = {
  icon?: IconProps;
  text?: TextProps;
};

type DateAndTimePropsValues = {
  '': DateAndTimeInternalProps;
};

const propValues: DateAndTimePropsValues = {
  '': {
    icon: {
      asset: 'CalenderNew',
      style: 'SubduedDark',
    },
    text: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'Charcoal10',
      align: 'Left',
    },
  },
};

const getProps = (type: string): DateAndTimeInternalProps => {
  const values: DateAndTimeInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import { ButtonProps } from '../../atoms/Button';
import { TextProps } from '../../atoms/Text';
import { CustomerInfoBlockProps } from '../../blocks/CustomerInfoBlock';
import { OrderInfoProps } from '../../blocks/OrderInfo';
import { StepperBlockProps } from '../../blocks/StepperBlock';


type CheckoutManualPageInternalProps = {
  stepperBlock?: StepperBlockProps;
  customerInfoBlock?: CustomerInfoBlockProps;
  message?: TextProps;
  button?: ButtonProps;
  orderInfo?: OrderInfoProps;
};

type CheckoutManualPagePropsValues = {
  '': CheckoutManualPageInternalProps;
};

const propValues: CheckoutManualPagePropsValues = {
  '': {
    stepperBlock: {
    },
    customerInfoBlock: {
    },
    message: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    button: {
      type: 'Text',
      style: 'Contained',
      size: 'Large',
    },
    orderInfo: {
    },
  },
};

const getProps = (type: string): CheckoutManualPageInternalProps => {
  const values: CheckoutManualPageInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import React from 'react';
import cx from 'classnames';

import { CHECKOUT_BRAINTREE_FORM } from '../../../lib/constants';
import BraintreeElements from '../../../modules/braintree/BraintreeElements';

import Button from '../../atoms/Button';
import Icon from '../../atoms/Icon';
import Spinner from '../../atoms/Spinner';
import Text from '../../atoms/Text';

import customStyles from './Custom.module.scss';
import styles from './CardInfoBlock.module.scss';
import usePresenter from './CardInfoBlock.presenter';
import getProps from './CardInfoBlock.props';
import { CardInfoBlockCombinedProps } from './types';

const CardInfoBlock: React.FC<CardInfoBlockCombinedProps> = (props) => {
  const {
    className,
    classes,
    blockTitle,
    description,
    mastercard,
    visa,
    cardNumber,
    expiration,
    securityCode,
    button,
    // MANUAL OVERRIDE STARTS
    cardNumberErrorMessage,
    braintreeStyles,
    handleSubmit,
    hasValidationError,
    isCardDisabled,
    isLoading,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.cardInfoBlock;

  // MANUAL OVERRIDE STARTS
  if (isLoading) {
    return (
      <div className={cx(currentStyle, className)}>
        <Spinner />
      </div>
    );
  }

  const cardFields = (
    <BraintreeElements isDisabled={isCardDisabled} styles={braintreeStyles}>
      {(cardNumberRef, expiryRef, cvvRef) => {
        return (
          <>
            <Text className={styles.label} {...internalProps.cardNumber} {...cardNumber} />
            <div
              className={cx(
                { [customStyles.disable]: isCardDisabled },
                {
                  [customStyles.errorBoundary]: hasValidationError && !isCardDisabled,
                },
                styles.cardNumber,
              )}
              ref={cardNumberRef}
            />
            <Text
              className={customStyles.errorMessage}
              {...internalProps.cardNumberErrorMessage}
              {...cardNumberErrorMessage} />
            <div className={styles.fieldGroup1}>
              <div>
                <Text
                  className={styles.label}
                  {...internalProps.expiration}
                  {...expiration} />
                <div
                  className={cx(
                    { [customStyles.disable]: isCardDisabled },
                    styles.expiration,
                  )}
                  ref={expiryRef}
                />
              </div>
              <div>
                <Text
                  className={styles.label}
                  {...internalProps.securityCode}
                  {...securityCode} />
                <div
                  className={cx(
                    { [customStyles.disable]: isCardDisabled },
                    styles.securityCode,
                  )}
                  ref={cvvRef}
                />
              </div>
            </div>
          </>
        );
      }}
    </BraintreeElements>
  );

  return (
    <form
      id={CHECKOUT_BRAINTREE_FORM}
      className={cx(currentStyle, className, customStyles.customCardInfoBlock)} /* MANUAL OVERRIDE - added custom style */
      onSubmit={handleSubmit}
    >
      <div className={cx(styles.topContent, classes?.topContent)}>
        <Text
          className={cx(styles.blockTitle, classes?.blockTitle)}
          {...internalProps.blockTitle}
          {...blockTitle}/>
        <Text
          className={cx(styles.description, classes?.description)}
          {...internalProps.description}
          {...description}/>
        <div className={cx(styles.icons, classes?.icons)}>
          <Icon
            className={cx(styles.mastercard, classes?.mastercard)}
            {...internalProps.mastercard}
            {...mastercard}/>
          <Icon
            className={cx(styles.visa, classes?.visa)}
            {...internalProps.visa}
            {...visa}/>
        </div>
      </div>
      {/* MANUAL OVERRIDE */}
      {cardFields}
      <Button
        buttonForm={CHECKOUT_BRAINTREE_FORM}
        className={cx(styles.button, classes?.button)}
        {...internalProps.button}
        {...button}/>
    </form>
  );
};

export default CardInfoBlock;


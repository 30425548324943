import { TextProps } from '../../atoms/Text';
import { IconProps } from '../../atoms/Icon';
import { LogoProps } from '../../atoms/Logo';


type IconTextDescriptionInternalProps = {
  icon?: IconProps;
  description?: TextProps;
  logo?: LogoProps;
};

type IconTextDescriptionPropsValues = {
  'Default': IconTextDescriptionInternalProps;
  'WithLogo': IconTextDescriptionInternalProps;
  'Large': IconTextDescriptionInternalProps; // MANUAL OVERRIDE
};

const propValues: IconTextDescriptionPropsValues = {
  'Default': {
    icon: {
      asset: 'Profile',
      style: 'DigitalGrey80',
    },
    description: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
  },
  'WithLogo': {
    icon: {
      asset: 'TicketWithLines',
      style: 'DigitalGrey80',
    },
    description: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    logo: {
      asset: 'VividLogoWithColour',
    },
  },
  // MANUAL OVERRIDE STARTS
  'Large': {
    icon: {
      asset: 'Profile',
      style: 'DigitalGrey80',
    },
    description: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
  },
  // MANUAL OVERRIDE ENDS
};

const getProps = (type: string): IconTextDescriptionInternalProps => {
  const values: IconTextDescriptionInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import React from 'react';
import cx from 'classnames';

import Divider from '../../atoms/Divider';
import TextInput from '../../atoms/TextInput';
import NewDropdownSelectList from '../NewDropdownSelectList';

import styles from './NewDropdownMenu.module.scss';
import usePresenter from './NewDropdownMenu.presenter';
import getProps from './NewDropdownMenu.props';
import { NewDropdownMenuCombinedProps } from './types';


const NewDropdownMenu: React.FC<NewDropdownMenuCombinedProps> = (props) => {
  const {
    type,
    className,
    classes,
    newDropdownSelectList,
    textInput,
    divider,
    // MANUAL OVERRIDE STARTS
    showMenu,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const variantName = `${type}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`newDropdownMenu${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Default': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <NewDropdownSelectList
            className={cx(styles.newDropdownSelectList, classes?.newDropdownSelectList)}
            {...internalProps.newDropdownSelectList}
            {...newDropdownSelectList}/>
        </div>
      );
      break;
    }
    case 'WithSearch': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <TextInput
            className={cx(styles.textInput, classes?.textInput)}
            {...internalProps.textInput}
            {...textInput}/>
          <Divider
            className={cx(styles.divider, classes?.divider)}
            {...internalProps.divider}
            {...divider}/>
          <NewDropdownSelectList
            className={cx(styles.newDropdownSelectList, classes?.newDropdownSelectList)}
            {...internalProps.newDropdownSelectList}
            {...newDropdownSelectList}/>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return showMenu ? componentView : <></>;
};

export default NewDropdownMenu;


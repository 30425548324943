import { TextProps } from '../../atoms/Text';
import { ButtonProps } from '../../atoms/Button';
import { TextFieldsProps } from '../../molecules/TextFields';


type CustomerInfoBlockInternalProps = {
  blockTitle?: TextProps;
  emailField?: TextProps; // MANUAL OVERRIDE - replaced TextField with Text 
  message?: TextProps;
  button?: ButtonProps;

  // MANUAL OVERRIDE STARTS
  emailLabel?: TextProps;
  toolTipIcon?: ButtonProps;
  // MANUAL OVERRIDE ENDS
};

type CustomerInfoBlockPropsValues = {
  '': CustomerInfoBlockInternalProps;
};

const propValues: CustomerInfoBlockPropsValues = {
  '': {
    blockTitle: {
      type: 'Subheading',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    // MANUAL OVERRIDE - replace TextField with Text
    emailField: {
      type: 'Body',
      colour: 'SubduedLight',
      align: 'Left',
      style: 'Regular',
      size: 'Medium',
    },
    // MANUAL OVERRIDE STARTS
    emailLabel: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
    toolTipIcon: {
      type: 'Icon',
      style: 'Text',
      size: 'Medium',
    },
    // MANUAL OVERRIDE ENDS
    message: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    button: {
      type: 'Text',
      style: 'Contained',
      size: 'Large',
    },
  },
};

const getProps = (type: string): CustomerInfoBlockInternalProps => {
  const values: CustomerInfoBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

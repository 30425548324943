import React from 'react';
import ReactSlider from 'react-slider';
import cx from 'classnames';

import Toggle from '../../atoms/Toggle';
import Track from '../../atoms/Track';

import styles from './Slider.module.scss';
import usePresenter from './Slider.presenter';
import getProps from './Slider.props';
import { SliderCombinedProps } from './types';


const Slider: React.FC<SliderCombinedProps> = (props) => {
  const {
    type,
    className,
    classes,
    trackEmpty,
    trackFilled,
    rightToggle,
    leftToggle,
    toggle,
    // MANUAL OVERRIDE STARTS
    maxPoints,
    minPoints,
    onSliderMoved,
    sliderValue,
    dualSliderValue,
    onDualSliderMoved,
    onAfterChange,
    step = 1,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const variantName = `${type}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`slider${variantName}`];

  let componentView;

  // MANUAL OVERRIDE STARTS
  switch (variantName) {
    case 'Dual': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ReactSlider
            className={styles.dualSlider}
            thumbClassName={styles.thumb}
            trackClassName={cx(styles.dualTrack)}
            value={dualSliderValue}
            min={minPoints}
            max={maxPoints}
            step={step}
            ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
            ariaLabel={['Leftmost thumb', 'Rightmost thumb']}
            onAfterChange={onAfterChange}
            onChange={(value) => {
              if (value && onDualSliderMoved) {
                onDualSliderMoved(value);
              }
            }}
          />
        </div>
      );
      break;
    }
    case 'Single': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ReactSlider
            className={styles.slider}
            thumbClassName={styles.thumb}
            trackClassName={cx(styles.track)}
            value={(sliderValue ?? (maxPoints || 1) / 2)}
            min={0}
            max={maxPoints}
            step={step}
            ariaLabel={'thumb'}
            ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
            onChange={(value) => {
              if (value != null && onSliderMoved) {
                onSliderMoved(value);
              }
            }}
          />
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }
  // MANUAL OVERRIDE ENDS

  return componentView;
};

export default Slider;


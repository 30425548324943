import { ToggleItemListProps } from '../../organisms/ToggleItemList';


type ToggleBlockInternalProps = {
  toggleItemList?: ToggleItemListProps;
};

type ToggleBlockPropsValues = {
  '': ToggleBlockInternalProps;
};

const propValues: ToggleBlockPropsValues = {
  '': {
    toggleItemList: {
    },
  },
};

const getProps = (type: string): ToggleBlockInternalProps => {
  const values: ToggleBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

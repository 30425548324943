import React from 'react';
import styles from './TopDisclaimer.module.scss';

export type TopDisclaimerProps = {
  disclaimer?: string;
};

const TopDisclaimer: React.FC<TopDisclaimerProps> = ({ disclaimer }) => {
  if (!disclaimer) return null;
  return (
    <div className={styles.topDisclaimerBackground}>
        <span className={styles.topDisclaimerText}>
            {disclaimer}
        </span>
    </div>);
};

export default TopDisclaimer;

import React from 'react';
import cx from 'classnames';

import EventCardsBlock from '../../blocks/EventCardsBlock';
import FiltersBlock from '../../blocks/FiltersBlock';
import PrimaryBannerBlock from '../../blocks/PrimaryBannerBlock';
import SecondaryBannerDarkBlock from '../../blocks/SecondaryBannerDarkBlock';

import styles from './ExclusivesEventsLandingPage.module.scss';
import usePresenter from './ExclusivesEventsLandingPage.presenter';
import getProps from './ExclusivesEventsLandingPage.props';
import { ExclusivesEventsLandingPageCombinedProps } from './types';


const ExclusivesEventsLandingPage: React.FC<ExclusivesEventsLandingPageCombinedProps> = (props) => {
  const {
    className,
    classes,
    primaryBannerBlock,
    exclusiveEvents,
    filtersBlock,
    sportsEvents,
    musicEvents,
    diningEvents,
    getawaysEvents,
    artTheaterEvents,
    secondaryBannerDarkBlock,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.exclusivesEventsLandingPage;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.topContainer, classes?.topContainer)}>
        <PrimaryBannerBlock
          className={cx(styles.primaryBannerBlock, classes?.primaryBannerBlock)}
          {...internalProps.primaryBannerBlock}
          {...primaryBannerBlock}/>
        <EventCardsBlock
          className={cx(styles.exclusiveEvents, classes?.exclusiveEvents)}
          {...internalProps.exclusiveEvents}
          {...exclusiveEvents}/>
      </div>
      <div className={cx(styles.container, classes?.container)}>
        <FiltersBlock
          className={cx(styles.filtersBlock, classes?.filtersBlock)}
          {...internalProps.filtersBlock}
          {...filtersBlock}/>
        <EventCardsBlock
          className={cx(styles.sportsEvents, classes?.sportsEvents)}
          {...internalProps.sportsEvents}
          {...sportsEvents}/>
        <EventCardsBlock
          className={cx(styles.musicEvents, classes?.musicEvents)}
          {...internalProps.musicEvents}
          {...musicEvents}/>
        <EventCardsBlock
          className={cx(styles.diningEvents, classes?.diningEvents)}
          {...internalProps.diningEvents}
          {...diningEvents}/>
        <EventCardsBlock
          className={cx(styles.getawaysEvents, classes?.getawaysEvents)}
          {...internalProps.getawaysEvents}
          {...getawaysEvents}/>
        <EventCardsBlock
          className={cx(styles.artTheaterEvents, classes?.artTheaterEvents)}
          {...internalProps.artTheaterEvents}
          {...artTheaterEvents}/>
        <SecondaryBannerDarkBlock
          className={cx(styles.secondaryBannerDarkBlock, classes?.secondaryBannerDarkBlock)}
          {...internalProps.secondaryBannerDarkBlock}
          {...secondaryBannerDarkBlock}/>
      </div>
    </div>
  );
};

export default ExclusivesEventsLandingPage;


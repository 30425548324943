import React from 'react';
import cx from 'classnames';

import DropdownSelect from '../../atoms/DropdownSelect';
import Text from '../../atoms/Text';

import styles from './Dropdown.module.scss';
import usePresenter from './Dropdown.presenter';
import getProps from './Dropdown.props';
import { DropdownCombinedProps } from './types';


const Dropdown: React.FC<DropdownCombinedProps> = (props) => {
  const {
    className,
    classes,
    label,
    dropdownSelect,
    ariaLabel,
    // MANUAL OVERRIDE STARTS
    ariaLive,
    state,
    error,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles[`dropdown${state}`];  // MANUAL OVERRIDE

  return (
    <div className={cx(currentStyle, className)} aria-live={ariaLive}>
      <Text
        className={cx(styles.label, classes?.label)}
        {...internalProps.label}
        {...label} />
      <DropdownSelect
        className={cx(styles.dropdownSelect, classes?.dropdownSelect)}
        ariaLabel={ariaLabel} // MANUAL OVERRIDE
        {...internalProps.dropdownSelect}
        {...dropdownSelect} />
      {/* MANUAL OVERRIDE */}
      {state === 'Error' ?
        <Text
          className={cx(styles.error, classes?.error)}
          {...internalProps.error}
          {...error} /> : undefined}
    </div>
  );
};

export default Dropdown;


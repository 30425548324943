import React from 'react';
import cx from 'classnames';

import EventCardNoImage from '../../molecules/EventCardNoImage';

import styles from './EventCardNoImageList.module.scss';
import usePresenter from './EventCardNoImageList.presenter';
import getProps from './EventCardNoImageList.props';
import { EventCardNoImageListCombinedProps } from './types';


const EventCardNoImageList: React.FC<EventCardNoImageListCombinedProps> = (props) => {
  const {
    className,
    classes,
    eventCardNoImages,
    listElementRef, // MANUAL OVERRIDE
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.eventCardNoImageList;

  const eventCardNoImageViews = eventCardNoImages?.map((eventCardNoImage, index) => (
    <EventCardNoImage
      key={index}
      className={cx(styles.eventCardNoImage, classes?.eventCardNoImage)}
      {...internalProps.eventCardNoImage}
      {...eventCardNoImage} />
  )) || [];

  return (
    // MANUAL OVERRIDE - added ref
    <div className={cx(currentStyle, className)} ref={listElementRef}>
      {eventCardNoImageViews}
    </div>
  );
};

export default EventCardNoImageList;




type LogoInternalProps = {
};

type LogoPropsValues = {
  'LogoC1Default': LogoInternalProps;
  'LogoC1EntertainmentBlue': LogoInternalProps;
  'LogoC1EntertainmentWhite': LogoInternalProps;
  'VividLogo': LogoInternalProps;
  'VividLogoWithColour': LogoInternalProps;
};

const propValues: LogoPropsValues = {
  'LogoC1Default': {
  },
  'LogoC1EntertainmentBlue': {
  },
  'LogoC1EntertainmentWhite': {
  },
  'VividLogo': {
  },
  'VividLogoWithColour': {
  },
};

const getProps = (type: string): LogoInternalProps => {
  const values: LogoInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import { SecondaryBannerProps } from '../../molecules/SecondaryBanner';


type SecondaryBannerDarkBlockInternalProps = {
  secondaryBanner?: SecondaryBannerProps;
};

type SecondaryBannerDarkBlockPropsValues = {
  '': SecondaryBannerDarkBlockInternalProps;
};

const propValues: SecondaryBannerDarkBlockPropsValues = {
  '': {
    secondaryBanner: {
    },
  },
};

const getProps = (type: string): SecondaryBannerDarkBlockInternalProps => {
  const values: SecondaryBannerDarkBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import React from 'react';
import cx from 'classnames';

import EventCardLight from '../../molecules/EventCardLight';

import styles from './EventCardLightList.module.scss';
import usePresenter from './EventCardLightList.presenter';
import getProps from './EventCardLightList.props';
import { EventCardLightListCombinedProps } from './types';


const EventCardLightList: React.FC<EventCardLightListCombinedProps> = (props) => {
  const {
    className,
    classes,
    eventCardLights,
    listElementRef, // MANUAL OVERRIDE
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.eventCardLightList;

  const eventCardLightViews = eventCardLights?.map((eventCardLight, index) => (
    <EventCardLight
      key={index}
      index={index}
      className={cx(styles.eventCardLight, classes?.eventCardLight)}
      {...internalProps.eventCardLight}
      {...eventCardLight} />
  )) || [];

  return (
    // MANUAL OVERRIDE - added ref
    <div ref={listElementRef} className={cx(currentStyle, className)}>
      {eventCardLightViews}
    </div>
  );
};

export default EventCardLightList;


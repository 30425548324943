import { useEffect, useMemo, useState } from 'react';
import { ToggleBlockCombinedProps, ToggleOptionType } from './types';
import { useHistory, useLocation } from 'react-router-dom';
import { addQueryParam } from '../../../lib/util';
import { ToggleItemValueProps } from '../../atoms/ToggleItem';
import { useAnalyticsManager } from '../../../modules/analytics/useAnalyticsManager';
import { trackSelectContentEvent } from '../../../modules/analytics/util';

const usePresenter = (props: ToggleBlockCombinedProps): ToggleBlockCombinedProps => {
  const { defaultOption, options, toggleFilterName } = props;
  const [selectedOption, setSelectedOption] = useState<ToggleOptionType | undefined>(defaultOption);

  const { trackEvent } = useAnalyticsManager();

  const history = useHistory();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const toggleFilterValue = toggleFilterName && query.get(toggleFilterName);

  useEffect(() => {
    const currentOption = options?.find((option) => (
      option.value === toggleFilterValue
    ));
    setSelectedOption(currentOption || defaultOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, toggleFilterValue]);

  const updateSearchParams = (option: ToggleOptionType) => {
    let params;
    if (toggleFilterName) {
      params = addQueryParam(history.location.search, {
        [toggleFilterName]: option.value,
      });
    }

    if (params) {
      history.push({
        search: params,
      });
    }
  };

  const onToggleItemClick = (option: ToggleOptionType) => {
    trackSelectContentEvent(
      trackEvent,
      'ToggleOption',
      'Filter',
      option.value,
    );
    setSelectedOption(option);
    updateSearchParams(option);
  };

  const toggleItems: (ToggleItemValueProps[] | undefined) = useMemo(() => {
    if (options) {
      return options.map((option) => {
        const isSelected = selectedOption?.title === option.title;
        return {
          label: {
            value: option.title,
            colour: isSelected ? 'BaseLight' : 'ActionBase',
          },
          state: isSelected ? 'Selected' : 'Default',
          onClick: () => onToggleItemClick(option),
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, selectedOption]);

  const updatedBlockProps: ToggleBlockCombinedProps = {
    toggleItemList: {
      toggleItems,
    },
  };
  return {
    ...props,
    ...updatedBlockProps,
  };
};

export default usePresenter;

import React from 'react';
import cx from 'classnames';

import EventCardsBlock from '../../blocks/EventCardsBlock';
import FiltersBlock from '../../blocks/FiltersBlock';
import ImageBlock from '../../blocks/ImageBlock';
import ToggleBlock from '../../blocks/ToggleBlock';

import styles from './SportTeamsLandingPage.module.scss';
import usePresenter from './SportTeamsLandingPage.presenter';
import getProps from './SportTeamsLandingPage.props';
import { SportTeamsLandingPageCombinedProps } from './types';


const SportTeamsLandingPage: React.FC<SportTeamsLandingPageCombinedProps> = (props) => {
  const {
    className,
    classes,
    imageBlock,
    toggleBlock,
    filtersBlock,
    eventCardsBlock,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.sportTeamsLandingPage;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.container, classes?.container)}>
        <ImageBlock
          className={cx(styles.imageBlock, classes?.imageBlock)}
          {...internalProps.imageBlock}
          {...imageBlock}/>
        <ToggleBlock
          className={cx(styles.toggleBlock, classes?.toggleBlock)}
          {...internalProps.toggleBlock}
          {...toggleBlock}/>
        <FiltersBlock
          className={cx(styles.filtersBlock, classes?.filtersBlock)}
          {...internalProps.filtersBlock}
          {...filtersBlock}/>
        <EventCardsBlock
          className={cx(styles.eventCardsBlock, classes?.eventCardsBlock)}
          {...internalProps.eventCardsBlock}
          {...eventCardsBlock}/>
      </div>
    </div>
  );
};

export default SportTeamsLandingPage;


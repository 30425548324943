import { LandingPageBannerBlockProps } from '../../../legacy/blocks/LandingPageBannerBlock';
import { SportTeamsListBlockProps } from '../../../legacy/blocks/SportTeamsListBlock';
import { SportTeamsNearLocationBlockProps } from '../../../legacy/blocks/SportTeamsNearLocationBlock';


type SportsLandingPageInternalProps = {
  landingPageBannerBlock?: LandingPageBannerBlockProps;
  sportTeamsNearLocationBlock?: SportTeamsNearLocationBlockProps;
  sportTeamsListBlock?: SportTeamsListBlockProps;
};

type SportsLandingPagePropsValues = {
  '': SportsLandingPageInternalProps;
};

const propValues: SportsLandingPagePropsValues = {
  '': {
    landingPageBannerBlock: {
    },
    sportTeamsNearLocationBlock: {
      state: 'Filed',
    },
    sportTeamsListBlock: {
    },
  },
};

const getProps = (type: string): SportsLandingPageInternalProps => {
  const values: SportsLandingPageInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import { ButtonProps } from '../../atoms/Button';
import { TextProps } from '../../atoms/Text';
import { FilterProps } from '../Filter';


type SearchResultInternalProps = {
  label?: TextProps;
  searchedValue?: TextProps;
  numberOfMatches?: TextProps;
  eventsMatchString?: TextProps;
  recommendationString?: TextProps;
  button?: ButtonProps;
  filter?: FilterProps;
};

type SearchResultPropsValues = {
  '': SearchResultInternalProps;
};

const propValues: SearchResultPropsValues = {
  '': {
    label: {
      type: 'Subheading',
      size: 'Large',
      style: 'Light',
      colour: 'SubduedDark',
      align: 'Left',
    },
    searchedValue: {
      type: 'Subheading',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    numberOfMatches: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    eventsMatchString: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    recommendationString: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    button: {
      type: 'Text',
      style: 'Text',
      size: 'Small',
    },
    filter: {
      type: 'IconFilter',
    },
  },
};

const getProps = (type: string): SearchResultInternalProps => {
  const values: SearchResultInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import { EventCardLightProps } from '../../molecules/EventCardLight';


type EventCardLightListInternalProps = {
  eventCardLight?: EventCardLightProps;
};

type EventCardLightListPropsValues = {
  '': EventCardLightListInternalProps;
};

const propValues: EventCardLightListPropsValues = {
  '': {
    eventCardLight: {
    },
  },
};

const getProps = (type: string): EventCardLightListInternalProps => {
  const values: EventCardLightListInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

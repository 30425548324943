import { ThumbnailCardProps } from '../../molecules/ThumbnailCard';


type ThumbnailCardListInternalProps = {
  thumbnailCard?: ThumbnailCardProps;
};

type ThumbnailCardListPropsValues = {
  '': ThumbnailCardListInternalProps;
};

const propValues: ThumbnailCardListPropsValues = {
  '': {
    thumbnailCard: {
    },
  },
};

const getProps = (type: string): ThumbnailCardListInternalProps => {
  const values: ThumbnailCardListInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

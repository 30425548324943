import React, { useEffect, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import styles from './Tooltip.module.scss';

interface Props {
  show?: boolean,
  x?: number,
  y?: number,
  followMouse?: boolean,
  children?: React.ReactNode
}
function generateGetBoundingClientRect(x = 500, y = 500) {
  return () => ({
    width: 0,
    height: 0,
    top: y,
    right: x,
    bottom: y,
    left: x,
  });
}
const virtualElement = {
  getBoundingClientRect: generateGetBoundingClientRect(),
};
const Tooltip: React.FC<Props> = ({ children, show = false, x, y, followMouse = false }) => {
  const [popperElement, updatePoperElement] = useState<HTMLDivElement | null>(null);
  const [arrowElement, updateArrowElement] = useState<HTMLDivElement | null>(null);
  const listening = useRef(false);

  const {
    styles: stylesPopper,
    attributes,
    update,
  } = usePopper(virtualElement as Element, popperElement, {
    modifiers: [
      { name: 'arrow', options: { element: arrowElement } },
      {
        name: 'offset',
        options: {
          offset: [followMouse ? 25 : 0, followMouse ? 25 : 0],
        },
      },
    ],
    placement: 'top',
  });

  useEffect(() => {
    const mouseMoveHandler = ({ clientX, clientY }: { clientX: number; clientY: number }) => {
      virtualElement.getBoundingClientRect = generateGetBoundingClientRect(clientX, clientY);
      if (update) {
        void update();
      }
    };
    if (update) {
      if (followMouse && !listening.current) {
        document.addEventListener('mousemove', mouseMoveHandler);
        listening.current = true;
      } else if (x !== 0 && y !== 0) {
        virtualElement.getBoundingClientRect = generateGetBoundingClientRect(x, y);
        void update();
      }
    }
    return () => {
      if (listening.current) {
        document.removeEventListener('mousemove', mouseMoveHandler);
        listening.current = false;
      }
    };
  }, [update, x, y, followMouse]);
  return (
    show ? <div ref={updatePoperElement} style={stylesPopper.popper} {...attributes.popper} className={styles.tooltip}>
      {children}
      <div ref={updateArrowElement} style={stylesPopper.arrow} className={styles.tooltipArrow}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="8"><path fill="#141414" d="M15.96.309a.51.51 0 0 0-.198-.225.56.56 0 0 0-.297-.084H.533a.559.559 0 0 0-.297.084.508.508 0 0 0-.196.225.478.478 0 0 0 .116.545l7.466 7A.536.536 0 0 0 8 8c.14 0 .275-.053.378-.147l7.466-7a.471.471 0 0 0 .116-.544Z"/></svg>
      </div>
    </div> : null
  );
};
export default Tooltip;

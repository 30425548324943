import { TextProps } from '../../atoms/Text';
import { ButtonProps } from '../../atoms/Button';
import { DividerProps } from '../../atoms/Divider';


type TicketInformationInternalProps = {
  title?: TextProps;
  location?: TextProps;
  availability?: TextProps;
  value?: TextProps;
  valueType?: TextProps;
  other?: TextProps;
  divider?: DividerProps;
  pointsValue?: TextProps;
  button?: ButtonProps;
  breakdownText?: TextProps; // MANUAL OVERRIDE
};

type TicketInformationPropsValues = {
  'Default': TicketInformationInternalProps;
  'LargeTitle': TicketInformationInternalProps;
  'MoreDetails': TicketInformationInternalProps;
  'WithPin': TicketInformationInternalProps;
};

const propValues: TicketInformationPropsValues = {
  'Default': {
    title: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    location: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    availability: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    value: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    valueType: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    other: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    // MANUAL OVERRIDE STARTS
    pointsValue: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    // MANUAL OVERRIDE ENDS
    divider: {
      type: 'Horizontal',
      style: 'Thin',
      colour: 'DigitalGrey15', // MANUAL OVERRIDE
    },
  },
  'LargeTitle': {
    title: {
      type: 'Subheading',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    location: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    value: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    valueType: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    other: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    divider: {
      type: 'Horizontal',
      style: 'Thin',
      colour: 'DigitalGrey15', // MANUAL OVERRIDE
    },
    breakdownText: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
  },
  'MoreDetails': {
    title: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    location: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    availability: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    value: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    valueType: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    pointsValue: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    other: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    divider: {
      type: 'Horizontal',
      style: 'Thin',
      colour: 'DigitalGrey10',
    },
  },
  'WithPin': {
    title: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    availability: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    value: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    valueType: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    other: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    button: {
      type: 'Icon',
      style: 'Text',
      size: 'Small',
    },
    divider: {
      type: 'Horizontal',
      style: 'Thin',
      colour: 'DigitalGrey10',
    },
  },
};

const getProps = (type: string): TicketInformationInternalProps => {
  const values: TicketInformationInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

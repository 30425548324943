import { ToggleItemProps } from '../../atoms/ToggleItem';


type ToggleItemListInternalProps = {
  toggleItem?: ToggleItemProps;
};

type ToggleItemListPropsValues = {
  '': ToggleItemListInternalProps;
};

const propValues: ToggleItemListPropsValues = {
  '': {
    toggleItem: {
      state: 'Default',
    },
  },
};

const getProps = (type: string): ToggleItemListInternalProps => {
  const values: ToggleItemListInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import { TextProps } from '../../atoms/Text';
import { ButtonProps } from '../../atoms/Button';
import { IconProps } from '../../atoms/Icon';


type CardInfoBlockInternalProps = {
  blockTitle?: TextProps;
  description?: TextProps;
  mastercard?: IconProps;
  visa?: IconProps;
  cardNumber?: TextProps; // MANUAL OVERRIDE
  expiration?: TextProps; // MANUAL OVERRIDE
  securityCode?: TextProps; // MANUAL OVERRIDE
  button?: ButtonProps;

  // MANUAL OVERRIDE STARTS
  cardNumberErrorMessage?: TextProps;
  // MANUAL OVERRIDE ENDS
};

type CardInfoBlockPropsValues = {
  '': CardInfoBlockInternalProps;
};

const propValues: CardInfoBlockPropsValues = {
  '': {
    blockTitle: {
      type: 'Subheading',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    description: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    mastercard: {
      asset: 'MasterCard',
      style: 'DigitalGrey80',
    },
    visa: {
      asset: 'Visa',
      style: 'DigitalGrey80',
    },
    // MANUAL OVERRIDE STARTS - replaced TextFields with Text
    cardNumber: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
    expiration: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
    securityCode: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
    // added a new prop
    cardNumberErrorMessage: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'Negative',
      align: 'Left',
    },
    // MANUAL OVERRIDE ENDS
    button: {
      type: 'Text',
      style: 'Contained',
      size: 'Large',
    },
  },
};

const getProps = (type: string): CardInfoBlockInternalProps => {
  const values: CardInfoBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

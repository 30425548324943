import { ButtonCombinedProps } from '../../atoms/Button';
import { IconProps } from '../../atoms/Icon';
import { TextProps } from '../../atoms/Text';


type BlockHeaderInternalProps = {
  text?: TextProps;
  button?: ButtonCombinedProps; // MANUAL OVERRIDE
  toolTipIcon?: IconProps; // MANUAL OVERRIDE
};

type BlockHeaderPropsValues = {
  'DarkNoButton': BlockHeaderInternalProps;
  'DarkWithButton': BlockHeaderInternalProps;
  'LightNoButton': BlockHeaderInternalProps;
  'LightWithButton': BlockHeaderInternalProps;
  'LightWithToolTip': BlockHeaderInternalProps;
  'DarkWithToolTip': BlockHeaderInternalProps; // MANUAL OVERRIDE
};

const propValues: BlockHeaderPropsValues = {
  'DarkNoButton': {
    text: {
      type: 'Subheading',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseLight',
      align: 'Left',
    },
  },
  'DarkWithButton': {
    text: {
      type: 'Subheading',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseLight',
      align: 'Left',
    },
    button: {
      type: 'TextIcon',
      style: 'TextWhite',
      size: 'Small',
      // MANUAL OVERRIDE STARTS
      icon: {
        asset: 'ChevronRight',
        style: 'White',
      },
      // MANUAL OVERRIDE ENDS
    },
  },
  'LightNoButton': {
    text: {
      type: 'Subheading',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
  },
  'LightWithButton': {
    text: {
      type: 'Subheading',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    button: {
      type: 'TextIcon',
      style: 'Text',
      size: 'Small',
      // MANUAL OVERRIDE STARTS
      icon: {
        asset: 'ChevronRight',
        style: 'White',
      },
      // MANUAL OVERRIDE ENDS
    },
  },
  'LightWithToolTip': {
    text: {
      type: 'Subheading',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    // MANUAL OVERRIDE
    toolTipIcon: {
      asset: 'InfoFilled',
      style: 'DigitalGrey80',
    },
  },
  // MANUAL OVERRIDE STARTS
  'DarkWithToolTip': {
    text: {
      type: 'Subheading',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseLight',
      align: 'Left',
    },
    // MANUAL OVERRIDE
    toolTipIcon: {
      asset: 'InfoFilled',
      style: 'DigitalGrey80',
    },
  },
  // MANUAL OVERRIDE ENDS
};

const getProps = (type: string): BlockHeaderInternalProps => {
  const values: BlockHeaderInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

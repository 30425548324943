import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import Icon from '../../atoms/Icon';
import Text from '../../atoms/Text';

import styles from './EmptyStateBanner.module.scss';
import usePresenter from './EmptyStateBanner.presenter';
import getProps from './EmptyStateBanner.props';
import { EmptyStateBannerCombinedProps } from './types';


const EmptyStateBanner: React.FC<EmptyStateBannerCombinedProps> = (props) => {
  const {
    className,
    classes,
    icon,
    title,
    message,
    button,
    type, // MANUAL OVERRIDE
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.emptyStateBanner;
  // MANUAL OVERRIDE STARTS
  let buttonView;
  let iconView;

  if (type !== 'NoButton') {
    buttonView = <Button
      className={cx(styles.button, classes?.button)}
      {...internalProps.button}
      {...button} />;
  }

  if (type !== 'NoIcon') {
    iconView = <Icon
      className={cx(styles.icon, classes?.icon)}
      {...internalProps.icon}
      {...icon} />;
  }
  return (
    <div className={cx(currentStyle, className)}>
      {iconView}
      <div className={cx(styles.content, classes?.content)}>
        <div className={styles.text}>
          <Text
            className={cx(styles.title, classes?.title)}
            {...internalProps.title}
            {...title} />
          <Text
            className={cx(styles.message, classes?.message)}
            {...internalProps.message}
            {...message} />
        </div>
        {buttonView}
      </div>
    </div>
  );
  // MANUAL OVERRIDE ENDS
};

export default EmptyStateBanner;




type TextInternalProps = {
};

type TextPropsValues = {
  'BodyExtraExtraSmallRegularDisabledCenter': TextInternalProps;
  'BodyExtraExtraSmallRegularSubduedDarkCenter': TextInternalProps;
  'BodyExtraSmallRegularActionBaseLeft': TextInternalProps;
  'BodyExtraSmallRegularBaseDarkLeft': TextInternalProps;
  'BodyExtraSmallSemiBoldDisabledCenter': TextInternalProps;
  'BodyExtraSmallSemiBoldNegativeLeft': TextInternalProps;
  'BodyExtraSmallSemiBoldSubduedDarkCenter': TextInternalProps;
  'BodyExtraSmallSemiBoldSubduedDarkLeft': TextInternalProps;
  'BodyExtraSmallSemiBoldWarningLeft': TextInternalProps;
  'BodyLargeRegularActionActiveLeft': TextInternalProps;
  'BodyLargeRegularActionBaseCenter': TextInternalProps;
  'BodyLargeRegularActionBaseLeft': TextInternalProps;
  'BodyLargeRegularActionBaseRight': TextInternalProps;
  'BodyLargeRegularActionHoverLeft': TextInternalProps;
  'BodyLargeRegularBaseDarkCenter': TextInternalProps;
  'BodyLargeRegularBaseDarkLeft': TextInternalProps;
  'BodyLargeRegularBaseLightCenter': TextInternalProps;
  'BodyLargeRegularBaseLightLeft': TextInternalProps;
  'BodyLargeRegularDisabledCenter': TextInternalProps;
  'BodyLargeRegularDisabledLeft': TextInternalProps;
  'BodyLargeRegularNegativeLeft': TextInternalProps;
  'BodyLargeRegularSubduedDarkCenter': TextInternalProps;
  'BodyLargeRegularSubduedDarkLeft': TextInternalProps;
  'BodyLargeRegularSubduedLightCenter': TextInternalProps;
  'BodyLargeRegularSubduedLightLeft': TextInternalProps;
  'BodyLargeSemiBoldActionBaseLeft': TextInternalProps;
  'BodyLargeSemiBoldBaseDarkLeft': TextInternalProps;
  'BodyLargeSemiBoldBaseLightLeft': TextInternalProps;
  'BodyLargeSemiBoldDisabledLeft': TextInternalProps;
  'BodyLargeSemiBoldPositiveLeft': TextInternalProps;
  'BodyLargeSemiBoldSubduedDarkLeft': TextInternalProps;
  'BodyLargeSemiBoldSubduedLightLeft': TextInternalProps;
  'BodyMediumRegularActionBaseLeft': TextInternalProps;
  'BodyMediumRegularActionLeft': TextInternalProps; // MANUAL OVERRIDE
  'BodyMediumRegularActionActiveCenter': TextInternalProps; // MANUAL OVERRIDE
  'BodyMediumRegularActionHoverLeft': TextInternalProps;
  'BodyMediumRegularBaseDarkCenter': TextInternalProps;
  'BodyMediumRegularBaseDarkLeft': TextInternalProps;
  'BodyMediumRegularBaseLightCenter': TextInternalProps;
  'BodyMediumRegularBaseLightLeft': TextInternalProps;
  'BodyMediumRegularBrandLeft': TextInternalProps;
  'BodyMediumRegularCharcoal10Left': TextInternalProps;
  'BodyMediumRegularDisabledLeft': TextInternalProps;
  'BodyMediumRegularNegativeLeft': TextInternalProps;
  'BodyMediumRegularPositiveLeft': TextInternalProps;
  'BodyMediumRegularSubduedDarkLeft': TextInternalProps;
  'BodyMediumRegularSubduedDarkRight': TextInternalProps;
  'BodyMediumRegularSubduedLightCenter': TextInternalProps;
  'BodyMediumRegularSubduedLightLeft': TextInternalProps;
  'BodyMediumSemiBoldBaseDarkLeft': TextInternalProps;
  'BodyMediumSemiBoldDisabledCenter': TextInternalProps;
  'BodyMediumSemiBoldPositiveLeft': TextInternalProps;
  'BodyMediumSemiBoldSubduedDarkCenter': TextInternalProps;
  'BodyMediumSemiBoldSubduedDarkLeft': TextInternalProps;
  'BodyMediumSemiBoldPositiveLightLeft': TextInternalProps;
  'BodySmallRegularActionBaseLeft': TextInternalProps;
  'BodySmallRegularBaseLightCenter': TextInternalProps;
  'BodySmallRegularBaseLightLeft': TextInternalProps;
  'BodySmallRegularCharcoal20Left': TextInternalProps;
  'BodySmallRegularDisabledCenter': TextInternalProps;
  'BodySmallRegularNegativeLeft': TextInternalProps;
  'BodySmallRegularSubduedDarkCenter': TextInternalProps;
  'BodySmallRegularSubduedDarkLeft': TextInternalProps;
  'BodySmallRegularSubduedLightCenter': TextInternalProps;
  'BodySmallRegularSubduedLightLeft': TextInternalProps;
  'BodySmallSemiBoldActionLeft': TextInternalProps;
  'BodySmallSemiBoldBaseLightLeft': TextInternalProps;
  'BodySmallSemiBoldDisabledCenter': TextInternalProps;
  'BodySmallSemiBoldNegativeLeft': TextInternalProps;
  'BodySmallSemiBoldPositiveLeft': TextInternalProps;
  'BodySmallSemiBoldSubduedDarkCenter': TextInternalProps;
  'BodySmallSemiBoldSubduedDarkLeft': TextInternalProps;
  'BodySmallSemiBoldWarningLeft': TextInternalProps;
  'ButtonTextMediumRegularActionActiveLeft': TextInternalProps;
  'ButtonTextMediumRegularActionBaseLeft': TextInternalProps;
  'ButtonTextMediumRegularActionHoverLeft': TextInternalProps;
  'ButtonTextMediumRegularBaseLightLeft': TextInternalProps;
  'ButtonTextMediumRegularDisabledLeft': TextInternalProps;
  'ButtonTextMediumRegularUnderlineActionActiveLeft': TextInternalProps;
  'ButtonTextMediumRegularUnderlineActionHoverLeft': TextInternalProps;
  'CardBodyLargeRegularSubduedDarkLeft': TextInternalProps;
  'CardBodyMediumRegularBaseLightLeft': TextInternalProps;
  'CardBodyMediumRegularPositiveLeft': TextInternalProps;
  'CardBodyMediumRegularSubduedDarkLeft': TextInternalProps;
  'CardBodyMediumRegularSubduedLightLeft': TextInternalProps;
  'CardBodySmallRegularSubduedLightLeft': TextInternalProps;
  'ContactBodyLargeRegularSubduedDarkLeft': TextInternalProps;
  'ContactBodyLargeSemiBoldSubduedDarkLeft': TextInternalProps;
  'DecorationLargeRegularSubduedDarkLeft': TextInternalProps;
  'DecorationMediumRegularActionActiveLeft': TextInternalProps;
  'DecorationMediumRegularActionBaseLeft': TextInternalProps;
  'DecorationMediumRegularActionHoverLeft': TextInternalProps;
  'DecorationMediumRegularBaseLightLeft': TextInternalProps;
  'DecorationMediumSemiBoldSubduedDarkLeft': TextInternalProps;
  'DecorationSmallRegularActionActiveLeft': TextInternalProps;
  'DecorationSmallRegularBaseLightLeft': TextInternalProps;
  'MLBCardTitleMediumSemiBoldBaseLightLeft': TextInternalProps;
  'NewBannerBodyMediumRegularBaseLightLeft': TextInternalProps;
  'BannerHeadingSmallSemiBoldActionBaseLeft': TextInternalProps;
  'BannerSubheadingLargeLightBaseLightLeft': TextInternalProps;
  'HeadingSmallLightBaseDarkLeft': TextInternalProps;
  'HeadingSmallRegularBaseLightCenter': TextInternalProps;
  'HeadingSmallRegularSubduedDarkLeft': TextInternalProps;
  'NewBannerHeadingMediumRegularBaseDarkLeft': TextInternalProps;
  'NewBannerHeadingMediumRegularBaseLightLeft': TextInternalProps;
  'NewEventDetailsTitleMediumSemiBoldBaseLightLeft': TextInternalProps;
  'SubheadingLargeLightSubduedDarkCenter': TextInternalProps;
  'SubheadingLargeLightSubduedDarkLeft': TextInternalProps;
  'SubheadingLargeRegularBaseLightCenter': TextInternalProps;
  'SubheadingLargeRegularBaseLightLeft': TextInternalProps;
  'SubheadingLargeRegularSubduedDarkLeft': TextInternalProps;
  'SubheadingLargeRegularSubduedDarkCenter': TextInternalProps; // MANUAL OVERRIDE
  'SubheadingLargeRegularSubduedLightLeft': TextInternalProps;
  'SubheadingLargeSemiBoldActionBaseLeft': TextInternalProps;
  'SubheadingLargeSemiBoldBaseLightLeft': TextInternalProps;
  'SubheadingMediumLightSubduedDarkLeft': TextInternalProps;
  'SubheadingMediumLightSubduedLightLeft': TextInternalProps;
  'SubheadingMediumLightSubduedLightRight': TextInternalProps;
  'SubheadingMediumRegularActionActiveLeft': TextInternalProps;
  'SubheadingMediumRegularActionBaseLeft': TextInternalProps;
  'SubheadingMediumRegularActionHoverLeft': TextInternalProps;
  'SubheadingMediumRegularBaseLightLeft': TextInternalProps;
  'SubheadingMediumRegularDisabledLeft': TextInternalProps;
  'SubheadingMediumRegularSubduedDarkLeft': TextInternalProps;
  'SubheadingMediumRegularSubduedDarkRight': TextInternalProps;
  'SubheadingMediumRegularUnderlineActionHoverLeft': TextInternalProps;
  'SubheadingMediumSemiBoldActionBaseLeft': TextInternalProps;
  'SubheadingSmallRegularBaseLightCenter': TextInternalProps;
  'SubheadingSmallRegularSubduedDarkCenter': TextInternalProps;
  'SubheadingSmallRegularSubduedDarkLeft': TextInternalProps;
  'CardBodyMediumRegularCharcoal10Left': TextInternalProps;
  'BannerSubheadingLargeRegularBaseLightLeft': TextInternalProps;
  'HeadingSmallLightBaseLightLeft': TextInternalProps;
  'HeadingSmallSemiBoldActionBaseLeft': TextInternalProps;
  'HeadingSmallSemiBoldBaseLightLeft': TextInternalProps;
  'BodyMediumRegularSubduedDarkCenter': TextInternalProps;
  'BodyMediumSemiBoldBaseLightLeft': TextInternalProps;
  'NewBannerHeadingLargeRegularBaseDarkLeft': TextInternalProps;
  'NewBannerHeadingLargeRegularBaseLightLeft': TextInternalProps;
  'NewEventDetailsTitleLargeSemiBoldBaseLightLeft': TextInternalProps;
  'SubheadingSmallRegularBaseLightLeft': TextInternalProps;
  'BodyLargeRegularUnderlineActionHoverLeft': TextInternalProps; // MANUAL OVERRIDE
  'BodyExtraSmallRegularSubduedLightCenter': TextInternalProps; // MANUAL OVERRIDE
  'BodyExtraSmallRegularSubduedDarkCenter': TextInternalProps; // MANUAL OVERRIDE
  'DecorationLargeRegularBaseLightLeft': TextInternalProps; // MANUAL OVERRIDE
  'BannerHeadingLargeLightBaseLightLeft': TextInternalProps; // MANUAL OVERRIDE
  'BannerSubheadingSmallRegularBaseLightLeft': TextInternalProps; // MANUAL OVERRIDE

};

const propValues: TextPropsValues = {
  'BodyExtraExtraSmallRegularDisabledCenter': {
  },
  'BodyExtraExtraSmallRegularSubduedDarkCenter': {
  },
  'BodyExtraSmallRegularActionBaseLeft': {
  },
  'BodyExtraSmallRegularBaseDarkLeft': {
  },
  // MANUAL OVERRIDE
  'BodyExtraSmallRegularSubduedLightCenter': {
  }, 
  // MANUAL OVERRIDE
  'BodyExtraSmallRegularSubduedDarkCenter': {
  }, 
  'BodyExtraSmallSemiBoldDisabledCenter': {
  },
  'BodyExtraSmallSemiBoldNegativeLeft': {
  },
  'BodyExtraSmallSemiBoldSubduedDarkCenter': {
  },
  'BodyExtraSmallSemiBoldSubduedDarkLeft': {
  },
  'BodyExtraSmallSemiBoldWarningLeft': {
  },
  'BodyLargeRegularActionActiveLeft': {
  },
  'BodyLargeRegularActionBaseCenter': {
  },
  'BodyLargeRegularActionBaseLeft': {
  },
  'BodyLargeRegularActionBaseRight': {
  },
  'BodyLargeRegularActionHoverLeft': {
  },
  'BodyLargeRegularBaseDarkCenter': {
  },
  'BodyLargeRegularBaseDarkLeft': {
  },
  'BodyLargeRegularBaseLightCenter': {
  },
  'BodyLargeRegularBaseLightLeft': {
  },
  'BodyLargeRegularDisabledCenter': {
  },
  'BodyLargeRegularDisabledLeft': {
  },
  'BodyLargeRegularNegativeLeft': {
  },
  'BodyLargeRegularSubduedDarkCenter': {
  },
  'BodyLargeRegularSubduedDarkLeft': {
  },
  'BodyLargeRegularSubduedLightCenter': {
  },
  'BodyLargeRegularSubduedLightLeft': {
  },
  'BodyLargeSemiBoldActionBaseLeft': {
  },
  'BodyLargeSemiBoldBaseDarkLeft': {
  },
  'BodyLargeSemiBoldBaseLightLeft': {
  },
  'BodyLargeSemiBoldDisabledLeft': {
  },
  'BodyLargeSemiBoldPositiveLeft': {
  },
  'BodyLargeSemiBoldSubduedDarkLeft': {
  },
  'BodyLargeSemiBoldSubduedLightLeft': {
  },
  'BodyMediumRegularActionBaseLeft': {
  },
  // MANUAL OVERRIDE STARTS
  'BodyMediumRegularActionLeft': {
  },
  'BodyMediumRegularActionActiveCenter': {
  },
  // MANUAL OVERRIDE ENDS
  'BodyMediumRegularActionHoverLeft': {
  },
  'BodyMediumRegularBaseDarkCenter': {
  },
  'BodyMediumRegularBaseDarkLeft': {
  },
  'BodyMediumRegularBaseLightCenter': {
  },
  'BodyMediumRegularBaseLightLeft': {
  },
  'BodyMediumRegularBrandLeft': {
  },
  'BodyMediumRegularCharcoal10Left': {
  },
  'BodyMediumRegularDisabledLeft': {
  },
  // MANUAL OVERRIDE STARTS
  'BodyMediumRegularNegativeLeft': {
  },
  // MANUAL OVERRIDE ENDS
  'BodyMediumRegularPositiveLeft': {
  },
  'BodyMediumRegularSubduedDarkLeft': {
  },
  'BodyMediumRegularSubduedDarkRight': {
  },
  'BodyMediumRegularSubduedLightCenter': {
  },
  'BodyMediumRegularSubduedLightLeft': {
  },
  'BodyMediumSemiBoldBaseDarkLeft': {
  },
  'BodyMediumSemiBoldDisabledCenter': {
  },
  'BodyMediumSemiBoldPositiveLeft': {
  },
  'BodyMediumSemiBoldSubduedDarkCenter': {
  },
  'BodyMediumSemiBoldSubduedDarkLeft': {
  },
  'BodyMediumSemiBoldPositiveLightLeft': {
  },
  'BodySmallRegularActionBaseLeft': {
  },
  'BodySmallRegularBaseLightCenter': {
  },
  'BodySmallRegularBaseLightLeft': {
  },
  'BodySmallRegularCharcoal20Left': {
  },
  'BodySmallRegularDisabledCenter': {
  },
  'BodySmallRegularNegativeLeft': {
  },
  'BodySmallRegularSubduedDarkCenter': {
  },
  'BodySmallRegularSubduedDarkLeft': {
  },
  'BodySmallRegularSubduedLightCenter': {
  },
  'BodySmallRegularSubduedLightLeft': {
  },
  'BodySmallSemiBoldActionLeft': {
  },
  'BodySmallSemiBoldBaseLightLeft': {
  },
  'BodySmallSemiBoldDisabledCenter': {
  },
  'BodySmallSemiBoldNegativeLeft': {
  },
  'BodySmallSemiBoldPositiveLeft': {
  },
  'BodySmallSemiBoldSubduedDarkCenter': {
  },
  'BodySmallSemiBoldSubduedDarkLeft': {
  },
  'BodySmallSemiBoldWarningLeft': {
  },
  'ButtonTextMediumRegularActionActiveLeft': {
  },
  'ButtonTextMediumRegularActionBaseLeft': {
  },
  'ButtonTextMediumRegularActionHoverLeft': {
  },
  'ButtonTextMediumRegularBaseLightLeft': {
  },
  'ButtonTextMediumRegularDisabledLeft': {
  },
  'ButtonTextMediumRegularUnderlineActionActiveLeft': {
  },
  'ButtonTextMediumRegularUnderlineActionHoverLeft': {
  },
  'CardBodyLargeRegularSubduedDarkLeft': {
  },
  'CardBodyMediumRegularBaseLightLeft': {
  },
  'CardBodyMediumRegularPositiveLeft': {
  },
  'CardBodyMediumRegularSubduedDarkLeft': {
  },
  'CardBodyMediumRegularSubduedLightLeft': {
  },
  'CardBodySmallRegularSubduedLightLeft': {
  },
  'ContactBodyLargeRegularSubduedDarkLeft': {
  },
  'ContactBodyLargeSemiBoldSubduedDarkLeft': {
  },
  'DecorationLargeRegularSubduedDarkLeft': {
  },
  'DecorationMediumRegularActionActiveLeft': {
  },
  'DecorationMediumRegularActionBaseLeft': {
  },
  'DecorationMediumRegularActionHoverLeft': {
  },
  'DecorationMediumRegularBaseLightLeft': {
  },
  'DecorationMediumSemiBoldSubduedDarkLeft': {
  },
  'DecorationSmallRegularActionActiveLeft': {
  },
  'DecorationSmallRegularBaseLightLeft': {
  },
  'MLBCardTitleMediumSemiBoldBaseLightLeft': {
  },
  'NewBannerBodyMediumRegularBaseLightLeft': {
  },
  'BannerHeadingSmallSemiBoldActionBaseLeft': {
  },
  'BannerSubheadingLargeLightBaseLightLeft': {
  },
  'HeadingSmallLightBaseDarkLeft': {
  },
  'HeadingSmallRegularBaseLightCenter': {
  },
  'HeadingSmallRegularSubduedDarkLeft': {
  },
  'NewBannerHeadingMediumRegularBaseDarkLeft': {
  },
  'NewBannerHeadingMediumRegularBaseLightLeft': {
  },
  'NewEventDetailsTitleMediumSemiBoldBaseLightLeft': {
  },
  'SubheadingLargeLightSubduedDarkCenter': {
  },
  'SubheadingLargeLightSubduedDarkLeft': {
  },
  'SubheadingLargeRegularBaseLightCenter': {
  },
  'SubheadingLargeRegularBaseLightLeft': {
  },
  'SubheadingLargeRegularSubduedDarkLeft': {
  },
  // MANUAL OVERRIDE STARTS
  'SubheadingLargeRegularSubduedDarkCenter': {
  },
  // MANUAL OVERRIDE ENDS
  'SubheadingLargeRegularSubduedLightLeft': {
  },
  'SubheadingLargeSemiBoldActionBaseLeft': {
  },
  'SubheadingLargeSemiBoldBaseLightLeft': {
  },
  'SubheadingMediumLightSubduedDarkLeft': {
  },
  'SubheadingMediumLightSubduedLightLeft': {
  },
  'SubheadingMediumLightSubduedLightRight': {
  },
  'SubheadingMediumRegularActionActiveLeft': {
  },
  'SubheadingMediumRegularActionBaseLeft': {
  },
  'SubheadingMediumRegularActionHoverLeft': {
  },
  'SubheadingMediumRegularBaseLightLeft': {
  },
  'SubheadingMediumRegularDisabledLeft': {
  },
  'SubheadingMediumRegularSubduedDarkLeft': {
  },
  'SubheadingMediumRegularSubduedDarkRight': {
  },
  'SubheadingMediumRegularUnderlineActionHoverLeft': {
  },
  'SubheadingMediumSemiBoldActionBaseLeft': {
  },
  'SubheadingSmallRegularBaseLightCenter': {
  },
  'SubheadingSmallRegularSubduedDarkCenter': {
  },
  'SubheadingSmallRegularSubduedDarkLeft': {
  },
  'CardBodyMediumRegularCharcoal10Left': {
  },
  'BannerSubheadingLargeRegularBaseLightLeft': {
  },
  'HeadingSmallLightBaseLightLeft': {
  },
  'HeadingSmallSemiBoldActionBaseLeft': {
  },
  'HeadingSmallSemiBoldBaseLightLeft': {
  },
  'BodyMediumRegularSubduedDarkCenter': {
  },
  'BodyMediumSemiBoldBaseLightLeft': {
  },
  'NewBannerHeadingLargeRegularBaseDarkLeft': {
  },
  'NewBannerHeadingLargeRegularBaseLightLeft': {
  },
  'NewEventDetailsTitleLargeSemiBoldBaseLightLeft': {
  },
  'SubheadingSmallRegularBaseLightLeft': {
  },
  // MANUAL OVERRIDE
  'BodyLargeRegularUnderlineActionHoverLeft': {
  },
  DecorationLargeRegularBaseLightLeft: {
  },
  BannerHeadingLargeLightBaseLightLeft: {
  },
  BannerSubheadingSmallRegularBaseLightLeft: {
  },
};

const getProps = (type: string): TextInternalProps => {
  const values: TextInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

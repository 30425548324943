import { DividerProps } from '../../atoms/Divider';
import { SeatMapBlockProps } from '../../blocks/SeatMapBlock';
import { SeatSelectionBlockProps } from '../../blocks/SeatSelectionBlock';
import { FilterSettingProps } from '../../organisms/FilterSetting';


type SeatSelectionPageInternalProps = {
  seatSelectionBlock?: SeatSelectionBlockProps;
  filterSetting?: FilterSettingProps;
  divider?: DividerProps;
  seatMapBlock?: SeatMapBlockProps;
  seatSelectionBlockMobile?: SeatSelectionBlockProps; // MANUAL OVERRIDE
};

type SeatSelectionPagePropsValues = {
  '': SeatSelectionPageInternalProps;
};

const propValues: SeatSelectionPagePropsValues = {
  '': {
    seatSelectionBlock: {
    },
    filterSetting: {
    },
    divider: {
      type: 'Vertical',
      style: 'Thin',
      colour: 'DigitalGrey10',
    },
    seatMapBlock: {
    },
    seatSelectionBlockMobile: {}, // MANUAL OVERRIDE
  },
};

const getProps = (type: string): SeatSelectionPageInternalProps => {
  const values: SeatSelectionPageInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

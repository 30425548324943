import { ButtonProps, ButtonCombinedProps } from '../../atoms/Button';
import { TextProps } from '../../atoms/Text';
import { LogoProps } from '../../atoms/Logo';
import { AccountMenuProps } from '../../molecules/AccountMenu';
import { SearchFieldProps } from '../../molecules/SearchField';
import { NavMenuListCombinedProps } from '../NavMenuList/types';


type TopNavInternalProps = {
  logo?: LogoProps;
  accountMenu?: AccountMenuProps;
  searchButton?: ButtonProps;
  backButton?: ButtonCombinedProps; // MANUAL OVERRIDE
  title?: TextProps;
  button?: ButtonCombinedProps; // MANUAL OVERRIDE
  description?: TextProps;
  searchField?: SearchFieldProps;
  navMenuList?: NavMenuListCombinedProps; // MANUAL OVERRIDE
};

type TopNavPropsValues = {
  'Checkout': TopNavInternalProps;
  'SearchInNav': TopNavInternalProps;
  'SignedOut': TopNavInternalProps;
  'TicketSeatSelection': TopNavInternalProps;
  'WithoutSearchInNav': TopNavInternalProps;
};

const propValues: TopNavPropsValues = {
  'Checkout': {
    logo: {
      asset: 'LogoC1EntertainmentWhite',
    },
    accountMenu: {
      state: 'Collapsed',
    },
  },
  'SearchInNav': {
    logo: {
      asset: 'LogoC1EntertainmentWhite',
    },
    searchButton: {
      type: 'Icon',
      style: 'Text',
      size: 'Small',
    },
    accountMenu: {
      state: 'Collapsed',
    },
  },
  'SignedOut': {
    logo: {
      asset: 'LogoC1EntertainmentWhite',
    },
  },
  'TicketSeatSelection': {
    logo: {
      asset: 'LogoC1EntertainmentWhite',
    },
    backButton: {
      type: 'Icon',
      style: 'Text',
      size: 'Small',
      // MANUAL OVERRIDE STARTS
      icon: {
        asset: 'ChevronLeft',
        style: 'White',
      },
      // MANUAL OVERRIDE ENDS
    },
    title: {
      type: 'Subheading',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseLight',
      align: 'Left',
    },
    button: {
      type: 'Icon',
      style: 'Text',
      size: 'Small',
      // MANUAL OVERRIDE STARTS
      icon: {
        asset: 'Info',
        style: 'White',
      },
      // MANUAL OVERRIDE ENDS
    },
    description: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'BaseLight',
      align: 'Left',
    },
    searchField: {
      type: 'SearchBubble',
    },
    accountMenu: {
      state: 'Collapsed',
    },
  },
  'WithoutSearchInNav': {
    logo: {
      asset: 'LogoC1EntertainmentWhite',
    },
    navMenuList: {
      type: 'Tabs',
    },
    accountMenu: {
      state: 'Collapsed',
    },
  },
};

const getProps = (type: string): TopNavInternalProps => {
  const values: TopNavInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import React from 'react';
import cx from 'classnames';
import Dropdown from 'react-bootstrap/Dropdown';

import Tab from '../../atoms/Tab';
import NavMenuItem from '../../molecules/NavMenuItem';

import Submenu from '../Submenu';
import SubmenuWithoutTabs from '../SubmenuWithoutTabs';
import SubmenuWithTabs from '../SubmenuWithTabs';

import customStyles from './Custom.module.scss';
import styles from './NavMenuList.module.scss';
import usePresenter from './NavMenuList.presenter';
import getProps from './NavMenuList.props';
import { NavMenuListCombinedProps } from './types';
import { getClickAccessibilityProps } from '../../../legacy/organisms/TopNav/util';


const NavMenuList: React.FC<NavMenuListCombinedProps> = (props) => {
  const {
    className,
    classes,
    navMenuItems,
    // MANUAL OVERRIDE STARTS
    type,
    tabs,
    openDropdownId,
    data,
    onTabHover,
    onTabFocus,
    onTabLeave,
    onKeyPress,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.navMenuList;

  const tabArray = tabs?.map((tab, index) => {
    let contentView;

    switch (tab.id) {
      case 'sports':
        contentView = (
          <SubmenuWithTabs
            isOpen={openDropdownId === tab.id}
            data={data}
            onItemClicked={onTabLeave}
          />
        );
        break;
      case 'exclusive':
        contentView = (
          <Submenu
            isOpen={openDropdownId === tab.id}
            data={data}
            onItemClicked={onTabLeave}
          />
        );
        break;
      default:
        contentView = (
          <SubmenuWithoutTabs
            id={tab.id}
            data={data}
            onItemClicked={onTabLeave}
            isOpen={openDropdownId === tab.id}
          />
        );
        break;
    }

    const accessibilityProps = getClickAccessibilityProps({
      onClick: onKeyPress,
      onEsc: onTabLeave,
    });
    return (
      <Dropdown
        tabIndex={-1}
        key={index}
        className={customStyles.dropdown}
        onMouseOver={() => onTabHover && onTabHover(tab?.id)}
        onKeyUp={(e) => onTabFocus && onTabFocus(tab?.id)}
        show={openDropdownId === tab.id}
        {...accessibilityProps}
      >
        <Dropdown.Toggle
          id={`main-nav-bar-toggle-button-${tab?.id}`}
          className={customStyles.dropdownToggle}
        >
          <Tab className={styles.tab} key={index} {...internalProps.tab} {...tab} />
        </Dropdown.Toggle>
        {openDropdownId === tab.id ? (
          <Dropdown.Menu renderOnMount={true} className={customStyles.dropdownMenu}>
            {contentView}
          </Dropdown.Menu>
        ) : null}
      </Dropdown>
    );
  });

  const navMenuItemArray = navMenuItems?.map((navMenuItem, index) => (
    <NavMenuItem
      key={index}
      className={cx(styles.navMenuItem, classes?.navMenuItem)}
      {...internalProps.navMenuItem}
      {...navMenuItem} />
  ));
  let view;
  switch (type) {
    case 'Tabs':
      view = tabArray;
      break;
    case 'List':
      view = navMenuItemArray;
      break;
    default:
      break;
  }

  return (
    <div className={cx(currentStyle, className)} onMouseLeave={onTabLeave}>
      {view}
    </div>
  );
};

export default NavMenuList;


import { useTranslation, type TFunction } from 'react-i18next';
import { CHECKOUT_BRAINTREE_FORM, CHECKOUT_NONE_BRAINTREE_FORM, CHECKOUT_SHIPPING_FORM } from '../../../lib/constants';
import {
  addQueryParam,
  CheckoutSteps,
  DateFormats,
  formatDate,
  getFiveDigitsZipCode,
  getTicketListingTranslationKey,
  getTwoDigitsCountryCode,
  handleAmountFormattingString,
  handleDecimalValuesForDisplay,
  handleDisplayUnitName,
  RewardUnits,
} from '../../../lib/util';
import type { EventKeys } from '../../../modules/analytics/types';
import type { Account } from '../../../modules/auth/types';
import type { ListingDetails, Order } from '../../../modules/partnership';
import CardAsset from '../../../resources/legacyIcons/CreditCardBlack.svg';
import GenericErrorAsset from '../../../resources/legacyIcons/GenericError.svg';
import { TextSizeEnum } from '../../atoms/Text/types';
import type { BillingInfoBlockCombinedProps } from '../../blocks/BillingInfoBlock/types';
import type { CustomerInfoBlockCombinedProps } from '../../blocks/CustomerInfoBlock/types';
import type { CustomerInfoReadOnlyBlockCombinedProps } from '../../blocks/CustomerInfoReadOnlyBlock/types';
import type { ErrorBlockCombinedProps } from '../../blocks/ErrorBlock/types';
import type { LegalBlockCombinedProps } from '../../blocks/LegalBlock/types';
import type { LoyaltyBlockCombinedProps } from '../../blocks/LoyaltyBlock/types';
import type { OrderInfoCombinedProps } from '../../blocks/OrderInfo/types';
import type { PaymentInformationReadOnlyBlockCombinedProps } from '../../blocks/PaymentInformationReadOnlyBlock/types';
import type { StepperBlockCombinedProps } from '../../blocks/StepperBlock/types';
import type { CostBreakdownCombinedProps } from '../../molecules/CostBreakdown/types';
import type { StepperItemCombinedProps } from '../../molecules/StepperItem/types';

export type CheckoutBasicProps = {
  customerBasicProps: CustomerInfoBlockCombinedProps;
  billingBasicProps: BillingInfoBlockCombinedProps;
  loyaltyBasicProps: LoyaltyBlockCombinedProps;
  customerInfoROBascProps: CustomerInfoReadOnlyBlockCombinedProps;
  paymentInfoROBascProps: PaymentInformationReadOnlyBlockCombinedProps;
  legalBasicProps: LegalBlockCombinedProps;
  stepperBasicProps: StepperBlockCombinedProps;
  ticketBasicProps: OrderInfoCombinedProps;
  orderErrorBlockBasicProps: ErrorBlockCombinedProps;
};

export type SliderItems = {
  currentSelection: number;
  unit?: RewardUnits | string;
  dollarWorth?: number;
  loaded?: boolean;
};
export type OrderItems = {
  quantity?: number;
  rewardsUsed?: number;
  dollarsUsed?: number;
  order?: Order;
  isRewardsOnly?: boolean;
  isCp1Exclusive?: boolean;
  listing?: ListingDetails;
};

export type CheckoutParams = {
  ticketId: string;
};

/**
 * rounds a real number to based on a given precision
 * slider needs this to control {step} property
 * @param value number to be rounded
 * @param precision how many decimal cases, example: precision=2 can return 1.05
 * @returns rounded value
 */
export const round = (
  value,
  decimalPoints = 0,
) => {
  const p = Math.pow(10, decimalPoints);
  const n = (value * p) * (1 + Number.EPSILON);
  return Math.round(n) / p;
};

/**
 * Rounds a real number down based on a given precision.
 * This is useful for scenarios like controlling the {step} property in sliders.
 *
 * @param {number} value - The number to be rounded down.
 * @param {number} decimalPoints - How many decimal places to round to. Example: decimalPoints=2 can return 1.05.
 * @returns {number} - The rounded down value.
 */
export const roundDown = (value, decimalPoints = 0) => {
  const p = Math.pow(10, decimalPoints);
  const n = (value * p) * (1 + Number.EPSILON);
  return Math.floor(n) / p;
};

export function calculateUsedPoints(
  selection: number,
  available: number,
  sliderMax: number,
): number {
  if (selection > sliderMax) {
    return sliderMax;
  } else if (selection > available) {
    return available;
  }
  return selection;
}

/**
 * To format actively edit rewardpoints
 * @param rewardPoints
 * @param availablePoints
 * @param maximumRewardPointsToBeUsed
 * @param useDecimals
 * @returns {number} formatted number
 */
export const getFormattedRewardPoints = (
  rewardPoints: string,
  availablePoints: number,
  maximumRewardPointsToBeUsed: number,
  useDecimals: boolean,
): number => {
  let formattedValue = calculateUsedPoints(
    +(rewardPoints.replace(/[^0-9.]/g, '') || 0),
    availablePoints,
    maximumRewardPointsToBeUsed,
  );
  if (!useDecimals) {
    formattedValue = round(formattedValue);
  } else {
    formattedValue = round(formattedValue, 2);
  }
  // if it becomes NaN due to bad characters, make it 0
  formattedValue = formattedValue || 0;

  return formattedValue;
};

/**
 * rounds decimal numbers up for slider values
 * @param value
 */
export function roundUp(value: number): number {
  let roundedNumber = value;
  if (value % 1 !== 0) {
    roundedNumber = Math.ceil(value);
  }
  return roundedNumber;
}

/**
 * Finds the block that needs to be shown at a given page
 * @param step current step
 * @param showShipping whether this flopw has shipping info in step 3
 * @returns the enum value for the step
 */
export function getCodeFromStep(
  step: number,
  showShipping: boolean,
): CheckoutSteps {
  let stepCode: CheckoutSteps = CheckoutSteps.CUSTOMERINFO;
  switch (step) {
    case 1:
      stepCode = CheckoutSteps.CUSTOMERINFO;
      break;
    case 2:
      stepCode = CheckoutSteps.BILLINGINFO;
      break;
    case 3:
      stepCode = showShipping
        ? CheckoutSteps.SHIPPINGINFO
        : CheckoutSteps.PAYMENTINFO;
      break;
    case 4:
      stepCode = showShipping
        ? CheckoutSteps.PAYMENTINFO
        : CheckoutSteps.CONFIRMATION;
      break;
    case 5:
      stepCode = CheckoutSteps.CONFIRMATION;
      break;
  }
  return stepCode;
}

export function getFormFromCode(
  code: CheckoutSteps,
  isRewardsOnly: boolean | undefined,
): string {
  if (code === CheckoutSteps.PAYMENTINFO && !isRewardsOnly) {
    return CHECKOUT_BRAINTREE_FORM;
  } else if (code === CheckoutSteps.PAYMENTINFO && isRewardsOnly) {
    return CHECKOUT_NONE_BRAINTREE_FORM;
  }
  if (code === CheckoutSteps.SHIPPINGINFO) {
    return CHECKOUT_SHIPPING_FORM;
  }
  return '';
}

/**
 * Determines starting value for slider based on
 * params about current checkout flow
 * @param isRewardsOnly
 * @param isSplitPay
 * @param sliderMaximum
 * @param useDecimals
 * @param unit
 * @param valuePerPoint
 * @returns
 */
export function getSliderDefaultState(
  isRewardsOnly: boolean | undefined,
  isSplitPay: boolean | undefined,
  sliderMaximum: number,
  useDecimals: boolean,
  valuePerPoint: number,
): SliderItems {
  let startingValue = 0;
  const middleValue = sliderMaximum / 2;
  let isMiddle = false;

  if (isRewardsOnly) {
    startingValue = sliderMaximum;
  } else if (isSplitPay) {
    startingValue = middleValue;
    isMiddle = true;
  } else {
    startingValue = 0;
  }

  return {
    currentSelection:
      !useDecimals && isMiddle ? round(startingValue) : round(startingValue, 2),
    dollarWorth: startingValue * valuePerPoint,
  };
}

/**
 * @param step - the current step in the checkout flow
 * @param query - the current query present
 * @returns exactly the same query as passed, but with step added by 1
 */
export function updateStepInQuery(step: number, query: string): string {
  return addQueryParam(query, { step: step.toString() });
}

/**
 * Formats title to following format:
 * Yankee Stadium, Bronx NY
 */
export function formatTitle(
  venue: string,
  city: string,
  stateCode: string,
): string {
  return venue + ', ' + city + ' ' + stateCode;
}

export const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const convertBasedOnValuePerPoint = (dollarAmount: number | undefined, valuePerPoint: number, useDecimals: boolean) => {
  const value = (dollarAmount || 0) / valuePerPoint;
  return useDecimals ? value : roundUp(value);
};

export const handleDisplayUnitBasedOnRewardOnly = (
  isRewardsOnly: boolean,
  rewardSign: string,
  dollarAmount: number | undefined,
  valuePerPoint: number,
  useDecimals: boolean,
  account: Account | null,
  t: TFunction<'translation', undefined>,
) => {
  return isRewardsOnly ? t('ticketInfo.orderTotal.rewardsOnlyTemplate', {
    value: `${rewardSign}${handleDecimalValuesForDisplay(
      handleAmountFormattingString(
        convertBasedOnValuePerPoint(dollarAmount, valuePerPoint, useDecimals),
      ),
      useDecimals,
    )}`,
    unit: account ? handleDisplayUnitName(account) : '',
  }) : t('ticketInfo.orderTotal.chargeNoCurrencyTemplate', {
    charge: handleDecimalValuesForDisplay(
      formatter.format(dollarAmount || 0),
      useDecimals,
    ),
  });
};

/**
 * To get dollar value for rewards points
 * @param rewardPoints
 * @param valuePerPoint
 * @returns dollar value for reward point
 */
export const getDollarValueForRewardPoints = (rewardPoints: number, valuePerPoint: number): number => {
  return roundDown(rewardPoints * valuePerPoint, 2);
};

/**
 * Checks if the given array of numbers forms a continuous sequence without duplicates or gaps.
 * @param {number[]} numbers - The array of numbers to check.
 * @returns {boolean} True if the numbers form a continuous sequence; otherwise, false.
 */
export const checkAreNumbersInSequence = (numbers: number[]): boolean => {
  // Iterate through the array up to the second to last element
  for (let i = 0; i < numbers.length - 1; i++) {
    if (numbers[i] !== (numbers[i + 1] - 1)) {
      return false; // Return false if a duplicate or gap is found
    }
  }

  // If no gaps or duplicates are found, return true
  return true;
};

export const getSeatInfoFromTicket = (listingDetails?: ListingDetails | null): string => {
  // if listingDetails object is undefiend return empty string
  if (!listingDetails) return '';

  // Extract the seats from listingDetails, defaulting to undefined if listingDetails or pricing is undefined
  const seats: string[] | null | undefined = listingDetails?.pricing.seats;

  // If seats are undefined or the array is empty, return an empty string
  if (!seats?.length) return '';

  if (seats.length === 1) {
    // If there is only one seat, return it. This covers 'TBD' case.
    return seats[0];
  }

  // Separate seats into positive integer seat numbers
  const numericSeats: number[] = seats.filter(seat => {
    const seatNumber = Number(seat);
    return Number.isInteger(seatNumber) && seatNumber > 0;
  }).map(Number);

  // If there are any non-numeric seats, return the seats as a comma-separated string
  if (seats.length !== numericSeats.length) {
    return seats.join(', ');
  }

  // Sort numeric seats in ascending order
  numericSeats.sort((a, b) => a - b);

  // Check if the sorted numeric seats form a continuous sequence
  const areNumbersInSequence: boolean = checkAreNumbersInSequence(numericSeats);

  // If the seat numbers are continuous, return the range as "first-last"
  // Otherwise, return the seats as a comma-separated string
  return areNumbersInSequence
    ? `${numericSeats[0]}-${numericSeats[numericSeats.length - 1]}`
    : seats.join(', ');
};

export const BuildCheckoutProps = (
  account: Account | null,
  listingDetails: ListingDetails | null,
  isHardTicket: boolean,
  isCp1Exclusive: boolean,
  isRewardsOnly: boolean,
  useDecimals: boolean,
  rewardSign: string,
): CheckoutBasicProps => {
  const { t } = useTranslation();
  const customerInfoProps: CustomerInfoBlockCombinedProps = {
    blockTitle: {
      value: t('customerInfo.title'),
    },
    emailField: {
      value: account?.email,
    },
    emailLabel: {
      value: t('customerInfo.email'),
    },
    message: {
      value: t('customerInfo.message'),
    },
    emailToolTip: {
      text: t('customerInfo.emailToolTip'),
      placement: 'auto-end',
    },
    toolTipIcon: {
      icon: {
        asset: 'Info',
        style: 'DigitalGrey80',
      },
      ariaLabelledBy: 'ttid',
      type: 'Icon',
      onClick: () => { },
    },
  };

  const descriptionTextSize: TextSizeEnum = 'Large';
  const billingInfoProps: BillingInfoBlockCombinedProps = {
    blockTitle: {
      value: t('billingInfo.title'),
    },
    firstName: {
      label: {
        value: t('billingInfo.firstName'),
      },
      description: {
        size: descriptionTextSize,
        value: account?.first_name,
      },
    },
    lastName: {
      label: {
        value: t('billingInfo.lastName'),
      },
      description: {
        size: descriptionTextSize,
        value: account?.last_name,
      },
    },
    address: {
      label: {
        value: t('billingInfo.address'),
      },
      description: {
        size: descriptionTextSize,
        value: account?.address.address_line1,
      },
    },
    secondaryAddress: {
      label: {
        value: account?.address.address_line2
          ? t('billingInfo.secondaryAddress')
          : '',
      },
      description: {
        size: descriptionTextSize,
        value: account?.address.address_line2,
      },
    },
    city: {
      label: {
        value: t('billingInfo.city'),
      },
      description: {
        size: descriptionTextSize,
        value: account?.address.city,
      },
    },
    state: {
      label: {
        value: t('billingInfo.state'),
      },
      description: {
        size: descriptionTextSize,
        value: account?.address.state_code,
      },
    },
    postalCode: {
      label: {
        value: t('billingInfo.postalCode'),
      },
      description: {
        size: descriptionTextSize,
        value: account
          ? getFiveDigitsZipCode(
            account.address.country_code,
            account.address.postal_code,
          )
          : '',
      },
    },
    country: {
      label: {
        value: t('billingInfo.country'),
      },
      description: {
        size: descriptionTextSize,
        value: getTwoDigitsCountryCode(account?.address.country_code || ''),
      },
    },
    phoneNumber: {
      label: {
        value: t('billingInfo.phoneNumberMobile'),
      },
      description: {
        size: 'Large',
        value: account?.phone,
      },
    },
    phoneNumberField: {
      label: {
        value: t('billingInfo.phoneNumberMobile'),
      },
      textInput: {
        textValue: account?.phone,
        maxLength: 10,
        removeChar: /[^0-9]/g,
      },
      error: {
        value: t('billingInfo.fieldErrorMessage', {
          field: t('billingInfo.phoneNumber'),
        }),
      },
    },
    phoneNumberToolTip: {
      text: t('billingInfo.phoneNumberToolTip'),
      placement: 'auto-end',
    },
    toolTipIcon: {
      icon: {
        asset: 'Info',
        style: 'DigitalGrey80',
      },
      ariaLabelledBy: 'ttid',
    },
  };

  const loyaltyProps: LoyaltyBlockCombinedProps = {
    blockTitle: {
      value: t('loyalty.title'),
    },
    description: {
      value: t('loyalty.description'),
    },
    highlightMessage: {
      message: {
        value: t('loyalty.insufficientRewards'),
      },
    },
  };
  const customerInfoROProps: CustomerInfoReadOnlyBlockCombinedProps = {
    blockTitle: {
      value: t('customerInfo.title'),
    },
    textItem: {
      label: {
        value: t('customerInfo.email'),
      },
      description: {
        value: account?.email,
      },
    },
  };
  const paymentInfoROProps: PaymentInformationReadOnlyBlockCombinedProps = {
    blockTitle: {
      value: t('billingInfo.readOnly'),
    },
    address: {
      label: {
        value: t('billingInfo.title'),
      },
      description: {
        value: t('billingInfo.smallVersionTemplate', {
          firstName: account?.first_name,
          lastName: account?.last_name,
          address: account?.address.address_line1,
          city: account?.address.city,
          state: account?.address.state_code,
          postalCode: account
            ? getFiveDigitsZipCode(
              account.address.country_code,
              account.address.postal_code,
            )
            : '',
          country: getTwoDigitsCountryCode(account?.address.country_code || ''),
        }),
      },
    },
    phoneNumber: {
      label: {
        value: t('billingInfo.phoneNumber'),
      },
      description: {
        value: account?.phone,
      },
    },
    paymentMethod: {
      label: {
        value: t('billingInfo.paymentMethod'),
      },
      description: {
      },
      cardImage: {
        imageSrc: account?.loyalty_program?.program_logo_url,
        imageFallback: CardAsset,
      },
    },
    rewards: {
      label: {
        value: t('billingInfo.rewardsToRedeem'),
      },
      description: {
      },
    },
  };
  const legalProps: LegalBlockCombinedProps = {
    blockTitle: {
      value: t('legal.title'),
    },
  };
  const valuePerPoint: number = account?.loyalty_program.redeem_per_dollar || 1;
  const stepperItemProps: StepperItemCombinedProps[] = [];
  for (let i = 1; i <= (isHardTicket ? 5 : 4); i++) {
    const code: CheckoutSteps = getCodeFromStep(i, isHardTicket);
    stepperItemProps.push({
      stepNumber: {
        value: t('stepperItems.stepTemplate', { step: i }),
      },
      stepLabel: {
        value: t(`stepperItems.${code}`),
      },
    });
  }
  const stepperBlockProps: StepperBlockCombinedProps = {
    stepperList: {
      stepperItems: stepperItemProps,
    },
  };
  const additionalFees: CostBreakdownCombinedProps = {
    serviceFee: {
      item: {
        value: t('ticketInfo.orderTotal.serviceFee'),
      },
      price: {
        value: handleDisplayUnitBasedOnRewardOnly(
          isRewardsOnly,
          rewardSign,
          listingDetails?.pricing.fulfillment_fee,
          valuePerPoint,
          useDecimals,
          account,
          t,
        ),
      },
    },
    delivery: {
      item: {
        value: t('ticketInfo.orderTotal.delivery'),
      },
      price: {
        value: handleDisplayUnitBasedOnRewardOnly(
          isRewardsOnly,
          rewardSign,
          listingDetails?.pricing.delivery_fee,
          valuePerPoint,
          useDecimals,
          account,
          t,
        ),
      },
    },
    tax: {
      item: {
        value: t('ticketInfo.orderTotal.tax'),
      },
      price: {
        value: handleDisplayUnitBasedOnRewardOnly(
          isRewardsOnly,
          rewardSign,
          listingDetails?.pricing.taxes,
          valuePerPoint,
          useDecimals,
          account,
          t,
        ),
      },
    },
  };

  const seatNumbers: string = getSeatInfoFromTicket(listingDetails);

  const ticketInfoProps: OrderInfoCombinedProps = {
    title1: {
      value: t('ticketInfo.orderTotal.totalCharge'),
    },
    costBreakdown: {
      ordarTotal: {
        item: {
          value: t('ticketInfo.orderTotal.orderTotal'),
        },
        price: {
          value: handleDisplayUnitBasedOnRewardOnly(
            isRewardsOnly,
            rewardSign,
            listingDetails?.pricing.total,
            valuePerPoint,
            useDecimals,
            account,
            t,
          ),
        },
      },
      tickets: {
        item: {
          value: t('ticketInfo.orderTotal.ticketsTemplate', {
            quantity: listingDetails?.pricing.quantity,
            pricePer: handleDisplayUnitBasedOnRewardOnly(
              isRewardsOnly,
              rewardSign,
              listingDetails?.pricing.price_per,
              valuePerPoint,
              useDecimals,
              account,
              t,
            ),
          }),
        },
        price: {
          value: handleDisplayUnitBasedOnRewardOnly(
            isRewardsOnly,
            rewardSign,
            listingDetails?.pricing.subtotal,
            valuePerPoint,
            useDecimals,
            account,
            t,
          ),
        },
      },
      ...additionalFees,
    },
    title2: {
      value: listingDetails?.event.name,
    },
    dateAndTime: {
      value: listingDetails?.event.local_date
        ? formatDate(
          new Date(listingDetails?.event.local_date),
          t,
          DateFormats.TICKETINFO,
        )
        : '',
    },
    venue: {
      value: formatTitle(
        listingDetails?.event.venue.name || '',
        listingDetails?.event.venue.city || '',
        listingDetails?.event.venue.state_code || '',
      ),
    },
    seatInfo: {
      section: {
        label: {
          value: t('ticketInfo.seatInfo.section'),
        },
        description: {
          value:
            listingDetails?.listing.verbose_section_name ||
            listingDetails?.listing.seating.section,
        },
      },
      row: {
        label: {
          value: t('ticketInfo.seatInfo.row'),
        },
        description: {
          value: listingDetails?.listing.seating.row,
        },
      },
      quantity: {
        label: {
          value: t('ticketInfo.seatInfo.quantity'),
        },
        description: {
        },
      },
      seats: seatNumbers ? {
        label: {
          value: t('ticketInfo.seatInfo.seats'),
        },
        description: {
          value: seatNumbers,
        },
      } : undefined,
    },
    sellerNotes: {
      label: {
        value: t('ticketInfo.sellerNotes'),
      },
      description: {
        value: listingDetails?.listing.notes,
      },
    },
    deliveryMethod: {
      label: {
        value: t('ticketInfo.deliveryMethod'),
      },
      description: {
        value: listingDetails
          ? t(getTicketListingTranslationKey(listingDetails, 'delivery_method'))
          : '',
      },
    },
    logo: {
      asset: isCp1Exclusive ? 'LogoC1Default' : 'VividLogo',
    },
  };
  const orderErrorBlockProps: ErrorBlockCombinedProps = {
    image: {
      imageSrc: GenericErrorAsset,
    },
    title: {
      value: t('orderError.title'),
    },
    message: {
      value: t('orderError.message'),
    },
  };
  return {
    customerBasicProps: customerInfoProps,
    billingBasicProps: billingInfoProps,
    loyaltyBasicProps: loyaltyProps,
    customerInfoROBascProps: customerInfoROProps,
    paymentInfoROBascProps: paymentInfoROProps,
    legalBasicProps: legalProps,
    stepperBasicProps: stepperBlockProps,
    ticketBasicProps: ticketInfoProps,
    orderErrorBlockBasicProps: orderErrorBlockProps,
  };
};

export const trackCheckoutEvents = (
  trackEvent: (event: EventKeys, extraData?: Record<string, unknown>) => void,
  eventKey: EventKeys,
  t: TFunction<'translation', undefined>,
  ticketInfo?: ListingDetails,
  order?: Order,
  previousTickets?: number,
): void => {
  let eventData: Record<string, unknown> = {};
  switch (eventKey) {
    case 'add-to-cart':
      eventData = {
        ecommerce: {
          items: ticketInfo?.listing,
        },
      };
      break;

    case 'begin_checkout':
      eventData = {
        ecommerce: {
          items: ticketInfo?.listing,
          currency: ticketInfo?.pricing.currency,
          subtotal: ticketInfo?.pricing.subtotal,
          shipping: ticketInfo?.pricing.delivery_fee,
          service_fee: ticketInfo?.pricing.fulfillment_fee,
          tax: ticketInfo?.pricing.taxes,
          value: ticketInfo?.pricing.price_per,
          total: ticketInfo?.pricing.total,
        },
      };
      break;

    case 'add_payment_info':
      eventData = {
        ecommerce: {
          step_name: 'PaymentAdded',
          items: ticketInfo?.listing,
          currency: ticketInfo?.pricing.currency,
          subtotal: ticketInfo?.pricing.subtotal,
          shipping: ticketInfo?.pricing.delivery_fee,
          service_fee: ticketInfo?.pricing.fulfillment_fee,
          tax: ticketInfo?.pricing.taxes,
          value: ticketInfo?.pricing.price_per,
          total: ticketInfo?.pricing.total,
        },
      };
      break;

    case 'purchase':
      eventData = {
        ecommerce: {
          items: ticketInfo?.listing,
          currency: ticketInfo?.pricing.currency,
          subtotal: ticketInfo?.pricing.subtotal,
          shipping: ticketInfo?.pricing.delivery_fee,
          service_fee: ticketInfo?.pricing.fulfillment_fee,
          tax: ticketInfo?.pricing.taxes,
          value: ticketInfo?.pricing.price_per,
          total: ticketInfo?.pricing.total,
          transaction_id: order?.id,
          loyalty_amount: order?.redeem?.amount,
          payment_amount: order?.payment?.paid,
          production_previously_purchased: previousTickets ? previousTickets > 0 : false,
        },
      };
      break;

    case 'checkout_login':
      eventData = {
        ecommerce: {
          step_name: 'LoginCompleted',
        },
      };
      break;

    case 'add_shipping_info':
      eventData = {
        ecommerce: {
          step_name: 'ShippingAdded',
          shipping_tier: ticketInfo ? t(getTicketListingTranslationKey(ticketInfo, 'delivery_method')) : '',
          items: ticketInfo?.listing,
          currency: ticketInfo?.pricing.currency,
          subtotal: ticketInfo?.pricing.subtotal,
          shipping: ticketInfo?.pricing.delivery_fee,
          service_fee: ticketInfo?.pricing.fulfillment_fee,
          tax: ticketInfo?.pricing.taxes,
          value: ticketInfo?.pricing.price_per,
          total: ticketInfo?.pricing.total,
        },
      };
      break;

    default:
      break;
  }
  trackEvent(eventKey, eventData);
};

import { TextProps } from '../../atoms/Text';
import { TextItemProps } from '../../molecules/TextItem';


type CustomerInfoReadOnlyBlockInternalProps = {
  blockTitle?: TextProps;
  edit?: TextProps;
  textItem?: TextItemProps;
};

type CustomerInfoReadOnlyBlockPropsValues = {
  '': CustomerInfoReadOnlyBlockInternalProps;
};

const propValues: CustomerInfoReadOnlyBlockPropsValues = {
  '': {
    blockTitle: {
      type: 'Subheading',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    edit: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'ActionBase',
      align: 'Right',
    },
    textItem: {
      style: 'SubduedLabel',
    },
  },
};

const getProps = (type: string): CustomerInfoReadOnlyBlockInternalProps => {
  const values: CustomerInfoReadOnlyBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import { useHistory } from 'react-router-dom';
import { NavMenuItemCombinedProps } from './types';

const usePresenter = (props: NavMenuItemCombinedProps): NavMenuItemCombinedProps => {
  const { linkPath, onClick, newTab } = props;
  const history = useHistory();
  const clickAction = () => {
    if (linkPath) {
      if (newTab) {
        window.open(linkPath, '_blank');
      } else {
        history.push(linkPath);
      }
    }
    if (onClick) {
      onClick();
    }
  };
  return {
    ...props,
    onClick: clickAction,
  };
};

export default usePresenter;

import React from 'react';
import cx from 'classnames';

import Divider from '../../atoms/Divider';
import Text from '../../atoms/Text';

import styles from './TicketSelectionInformation.module.scss';
import usePresenter from './TicketSelectionInformation.presenter';
import getProps from './TicketSelectionInformation.props';
import { TicketSelectionInformationCombinedProps } from './types';


const TicketSelectionInformation: React.FC<TicketSelectionInformationCombinedProps> = (props) => {
  const {
    className,
    classes,
    title,
    location, // MANUAL OVERRIDE
    points,
    text1,
    value,
    text2,
    divider,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.ticketSelectionInformation;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.content, classes?.content)}>
        <Text
          className={cx(styles.title, classes?.title)}
          {...internalProps.title}
          {...title}/>
        <div className={cx(styles.priceDetails, classes?.priceDetails)}>
          {/* MANUAL OVERRIDE */}
          <Text
            className={cx(styles.location, classes?.location)}
            {...internalProps.location}
            {...location}/>
          <div className={cx(styles.pointsCost, classes?.pointsCost)}>
            <div className={cx(styles.pointsAmount, classes?.pointsAmount)}>
              <Text
                className={cx(styles.points, classes?.points)}
                {...internalProps.points}
                {...points}/>
              <Text
                className={cx(styles.text1, classes?.text1)}
                {...internalProps.text1}
                {...text1}/>
              <Text
                className={cx(styles.value, classes?.value)}
                {...internalProps.value}
                {...value}/>
              <Text
                className={cx(styles.text2, classes?.text2)}
                {...internalProps.text2}
                {...text2}/>
            </div>
          </div>
        </div>
      </div>
      <Divider
        className={cx(styles.divider, classes?.divider)}
        {...internalProps.divider}
        {...divider}/>
    </div>
  );
};

export default TicketSelectionInformation;


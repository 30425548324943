import React from 'react';
import cx from 'classnames';
import { Modal } from 'react-bootstrap'; // MANUAL OVERRIDE

import MyTicketsBlock from '../../blocks/MyTicketsBlock';

import styles from './MyTicketsPage.module.scss';
import usePresenter from './MyTicketsPage.presenter';
import getProps from './MyTicketsPage.props';
import { MyTicketsPageCombinedProps } from './types';

// MANUAL OVERRIDE START
import PdfDocument from '../ReceiptPage/checkoutOrderPrint/PdfDocumentGenerator';
import PDFDoc from '../ReceiptPage/checkoutOrderPrint/PdfDocument';
import OrderDetails from '../../organisms/OrderDetails';
import ErrorHandler from '../../../modules/error/ErrorHandler';
// MANUAL OVERRIDE END

const MyTicketsPage: React.FC<MyTicketsPageCombinedProps> = (props) => {
  const {
    className,
    classes,
    myTicketsBlock,
    // MANUAL OVERRIDE START
    error,
    viewOrderModal,
    setViewOrderModal,
    setUrl,
    orderDetailsProps,
    isCp1Exclusive,
    cardHolderExclusiveLabel,
    ticketNotice,
    orderDetailsTitle,
    isMobileTicket,
    eventLoading,
    pageLoading,
    // MANUAL OVERRIDE END
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.myTicketsPage;

  if (error) {
    return <ErrorHandler error={error} />;
  }
  let modalView;
  if (viewOrderModal) {
    modalView = (
      <>
        <PdfDocument
          setUrl={setUrl}
          title=""
          document={
            <PDFDoc
              receiptBlock={{
                orderDetails: orderDetailsProps,
                isCp1Exclusive: isCp1Exclusive,
                cardHolderExclusiveLabel: cardHolderExclusiveLabel,
                ticketNotice: ticketNotice,
                orderDetailsTitle: orderDetailsTitle,
                isMobileTicket: isMobileTicket,
              }}
            />
          }
        />
        <Modal
          className={styles.modal}
          dialogClassName={styles.modalDialog}
          contentClassName={styles.modalContent}
          backdrop={'static'}
          show={viewOrderModal}
          onHide={setViewOrderModal}
        >
          <OrderDetails
            className={styles.orderDetailsContainer}
            {...orderDetailsProps}
            isLoading={eventLoading}
          />
        </Modal>
      </>
    );
  }

  return (
    // MANUAL OVERRIDE START add modalView 
    <>
      <div className={cx(currentStyle, className)}>
        <MyTicketsBlock
          className={cx(styles.myTicketsBlock, classes?.myTicketsBlock)}
          {...internalProps.myTicketsBlock}
          {...myTicketsBlock}/>
      </div>
      {modalView}
    </>
    // MANUAL OVERRIDE END
  );
};

export default MyTicketsPage;


import React from 'react';
import cx from 'classnames';

import NewDropdown from '../../atoms/NewDropdown';

import styles from './FiltersBlock.module.scss';
import usePresenter from './FiltersBlock.presenter';
import getProps from './FiltersBlock.props';
import { FiltersBlockCombinedProps } from './types';


const FiltersBlock: React.FC<FiltersBlockCombinedProps> = (props) => {
  const {
    className,
    classes,
    filterOne,
    filterTwo,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.filtersBlock;

  return (
    <div className={cx(currentStyle, className)}>
      <NewDropdown
        className={cx(styles.filterOne, classes?.filterOne)}
        {...internalProps.filterOne}
        {...filterOne}/>
      <NewDropdown
        className={cx(styles.filterTwo, classes?.filterTwo)}
        {...internalProps.filterTwo}
        {...filterTwo}/>
    </div>
  );
};

export default FiltersBlock;


import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import Divider from '../../atoms/Divider';
import Icon from '../../atoms/Icon';
import TeamLogo from '../../atoms/TeamLogo';

import styles from './ThumbnailTextItem.module.scss';
import usePresenter from './ThumbnailTextItem.presenter';
import getProps from './ThumbnailTextItem.props';
import { ThumbnailTextItemCombinedProps } from './types';


const ThumbnailTextItem: React.FC<ThumbnailTextItemCombinedProps> = (props) => {
  const {
    className,
    classes,
    teamLogo,
    button,
    icon,
    divider,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.thumbnailTextItem;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.content, classes?.content)}>
        <TeamLogo
          className={cx(styles.teamLogo, classes?.teamLogo)}
          {...internalProps.teamLogo}
          {...teamLogo}/>
        <Button
          className={cx(styles.button, classes?.button)}
          classes={{
            text: styles.buttonText,
          }}
          {...internalProps.button}
          {...button}/>
        <Icon
          className={cx(styles.icon, classes?.icon)}
          {...internalProps.icon}
          {...icon}/>
      </div>
      <Divider
        className={cx(styles.divider, classes?.divider)}
        {...internalProps.divider}
        {...divider}/>
    </div>
  );
};

export default ThumbnailTextItem;


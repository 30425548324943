import { NewDropdownMenuItemProps } from '../../atoms/NewDropdownMenuItem';


type NewDropdownSelectListInternalProps = {
  newDropdownMenuItem?: NewDropdownMenuItemProps;
};

type NewDropdownSelectListPropsValues = {
  '': NewDropdownSelectListInternalProps;
};

const propValues: NewDropdownSelectListPropsValues = {
  '': {
    newDropdownMenuItem: {
      type: 'IconText',
      state: 'Unselected',
    },
  },
};

const getProps = (type: string): NewDropdownSelectListInternalProps => {
  const values: NewDropdownSelectListInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

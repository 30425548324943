import { DividerProps } from '../../atoms/Divider';
import { EmptyStateBannerProps } from '../../molecules/EmptyStateBanner';
import { FilterProps } from '../../molecules/Filter';
import { ThumbnailCardListProps } from '../../organisms/ThumbnailCardList';


type SportTeamsNearLocationBlockInternalProps = {
  filter?: FilterProps;
  emptyStateBanner?: EmptyStateBannerProps;
  divider?: DividerProps;
  thumbnailCardList?: ThumbnailCardListProps;
};

type SportTeamsNearLocationBlockPropsValues = {
  'Empty': SportTeamsNearLocationBlockInternalProps;
  'Filed': SportTeamsNearLocationBlockInternalProps;
  'Filled': SportTeamsNearLocationBlockInternalProps;
};

const propValues: SportTeamsNearLocationBlockPropsValues = {
  'Empty': {
    filter: {
      type: 'TextIconFilter',
    },
    emptyStateBanner: {
    },
    divider: {
      type: 'Horizontal',
      style: 'Thin',
      colour: 'DigitalGrey15',
    },
  },
  'Filed': {
    filter: {
      type: 'TextIconFilter',
    },
    thumbnailCardList: {
    },
  },
  'Filled': {
    filter: {
      type: 'TextIconFilter',
    },
    thumbnailCardList: {
    },
    divider: {
      type: 'Horizontal',
      style: 'Thin',
      colour: 'DigitalGrey15',
    },
  },
};

const getProps = (type: string): SportTeamsNearLocationBlockInternalProps => {
  const values: SportTeamsNearLocationBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

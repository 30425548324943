import { useContext, useEffect, useRef, useState } from 'react';
import { LinkState } from '../../../modules/navigation/types';
import { TopNavCombinedProps, TopNavTypeEnum } from './types';
import { AuthContext } from '../../../modules/auth';
import { useSuggestionSearchQuery } from '../../../modules/partnership/useSuggestionSearchQuery';
import useRecentSearches from '../../../modules/localstorage/useRecentSearches';
import { TopNavContext } from '../../../modules/topNav';
import { getSearchSuggestions } from '../../../modules/partnership/api';
import { checkIfPathMatch } from '../../../components/layout/MainLayout/utils'; // TODO: change import path when finish import MainLayout util.ts
import { formatDateToApiDate, handleLocation, handleTagFilterForSearch, setEventDescription, useScrollOffset, useWindowSize } from '../../../lib/util';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BREAKPOINTS } from '../../../lib/constants';
import { AccountMenuStateEnum } from '../../molecules/AccountMenu/types';
import { Performer, SearchSuggestionsResponse, Venue } from '../../../modules/partnership';
import { getItemIfMatch } from '../../../modules/navigation/utils';
import { SearchReturnItemCombinedProps } from '../../molecules/SearchReturnItem/types';
import { NavMenuItemCombinedProps } from '../../molecules/NavMenuItem/types';
import { SearchFieldCombinedProps } from '../../molecules/SearchField/types';
import CardAsset from '../../../resources/icons/CreditCard.svg';
import { SearchResultsBlockCombinedProps } from '../../blocks/SearchResultsBlock/types';
import { defaultDateToTodayIfEmpty } from '../../blocks/EventCardsBlock/utils';
import { useAnalyticsManager } from '../../../modules/analytics/useAnalyticsManager';
import { trackSelectContentEvent } from '../../../modules/analytics/util';

export interface LocationStateTopNav {
  hash: string,
  key: string,
  pathname: string
  search: string,
  canGoBack: LinkState,
}

const TopNavBottomContentHeight = 53;

const getTopNavType = (
  pathname: string, pageYOffset: number, isDesktop: boolean, 
): TopNavTypeEnum => {
  const isHomePage = checkIfPathMatch(pathname, '/', true);
  if (isHomePage) {
    return pageYOffset > TopNavBottomContentHeight && !isDesktop ? 'SearchInNav' : 'WithoutSearchInNav';
  }

  const isC1ExclusivePage = checkIfPathMatch(pathname, '/exclusive-events', true);
  const isPerformersPage = checkIfPathMatch(pathname, '/performers/:performerId', true);
  const isVenuesPage = checkIfPathMatch(pathname, '/venues/:venueId', true);
  const isSportsTeamPage = checkIfPathMatch(pathname, ['/nba', '/nhl', '/nfl', '/mls', '/mlb'], true);
  const isEventsPage = checkIfPathMatch(pathname, '/events', false);

  if (isDesktop && (isC1ExclusivePage || isPerformersPage || isVenuesPage || isSportsTeamPage || isEventsPage)) {
    return 'WithoutSearchInNav';
  }
  return 'SearchInNav';
};

const usePresenter = (props: TopNavCombinedProps): TopNavCombinedProps => {
  const { account } = useContext(AuthContext);
  const [ doSearch, queryState ] = useSuggestionSearchQuery('suggestionsearch', getSearchSuggestions);
  const { addRecentSearch } = useRecentSearches();
  const { currentEvent, hasNoEventMap, hasEventDescription, hasEventGuestList, isSoldOutEvent } = useContext(TopNavContext);

  const { trackEvent } = useAnalyticsManager();

  const { pathname } = useLocation();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { listing, onBackButtonClicked, data } = props;
  const { width: innerWidth } = useWindowSize();
  const { scrollY } = useScrollOffset();
  const isDesktop = !!innerWidth && innerWidth >= BREAKPOINTS.desktop;
  const topNavType = getTopNavType(pathname, scrollY || window.screenY, isDesktop);
  const [accountMenuDropdownState, setAccountMenuDropdownState] = useState<AccountMenuStateEnum>('Collapsed');
  const [isSearchNavOpen, setIsSearchNavOpen] = useState<boolean>(false);
  const [isSearchReturnModalOpen, setIsSearchReturnModalOpen] = useState<boolean>(false);
  const [apiSuggestionResults, setSuggestionResults] = useState<SearchSuggestionsResponse | undefined>(queryState.data);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const searchBarRef = useRef<HTMLInputElement>(null);
  const topNavSearchBarRef = useRef<HTMLInputElement>(null);
  const searchNavRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setAccountMenuDropdownState?.('Collapsed');
  }, [location]);

  useEffect(() => {
    if (searchQuery !== '') {
      trackEvent('search', {
        search_term: searchQuery,
      });
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      doSearch({
        q: searchQuery,
        tag_filter: handleTagFilterForSearch(account),
        date_start: formatDateToApiDate(new Date()),
      });
    } 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, doSearch]);

  useEffect(() => {
    if (queryState.data) {
      setSuggestionResults(queryState.data);
    }
  }, [queryState.data]);

  const [showEventDetails, setShowEventDetails] = useState(false);
  const openEventDetails = () => {
    trackSelectContentEvent(
      trackEvent,
      'Header',
      'Button',
    );
    setShowEventDetails(true);
  };

  const closeEventDetails = () => {
    trackSelectContentEvent(
      trackEvent,
      'Header',
      'Button',
      t('topnav.close'),
    );
    setShowEventDetails(false);
  };

  let eventDescription;
  if (currentEvent) {
    eventDescription = setEventDescription(currentEvent);
  }

  const setTextValue = () => {
    if (topNavType === 'TicketSeatSelection' && currentEvent) {
      return currentEvent.name;
    }
    return undefined;
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      const trimmedValue = searchQuery.trim();
      let title = trimmedValue;
      let searchPath = `/search?q=${encodeURIComponent(trimmedValue)}&date_start=${defaultDateToTodayIfEmpty(undefined)}`;

      if (trimmedValue.length) {
        const performer = getItemIfMatch(
          searchQuery,
          apiSuggestionResults?.performers,
        );
        if (performer) {
          title = performer.name;
          searchPath = `/performers/${performer.id}`;
        } else {
          const event = getItemIfMatch(
            searchQuery,
            apiSuggestionResults?.events,
          );
          if (event) {
            title = event.name;
            searchPath = `/events/${event.id}`;
          } else {
            const venue = getItemIfMatch(searchQuery, apiSuggestionResults?.venues);
            if (venue) {
              title = venue.name;
              searchPath = `/venues/${venue.id}`;
            }
          }
        }

        if (addRecentSearch) {
          addRecentSearch({ title: title, path: searchPath });
        }
        setSearchQuery('');
        setIsSearchReturnModalOpen(false);
        setIsSearchNavOpen(false);

        return history.push(searchPath, {
          canGoBack: true,
        });
      }
    }
  };

  const handleSearchValue = ({ target: { value } }): void => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    setSearchQuery(value);
  };

  const clearSearchValue = () => {
    setSearchQuery('');
    searchBarRef.current?.focus();
    topNavSearchBarRef.current?.blur();
    if (!isDesktop) {
      setIsSearchReturnModalOpen(true);
    } else {
      setIsSearchNavOpen(true);
    }
  };

  const returnToSearch = () => {
    clearSearchValue();
    searchBarRef.current?.focus();
  };

  const getItems = (
    category: keyof SearchSuggestionsResponse,
    size: number,
  ): Venue[] | Event[] | Performer[] => {
    if (apiSuggestionResults) {
      const getList: Venue[] | Event[] | Performer[] =
        apiSuggestionResults[category];
      const resultList: Venue[] | Event[] | Performer[] = getList.slice(
        0,
        size,
      );
      return resultList;
    }
    return [];
  };

  const onSearchResultClick = () => {
    setSearchQuery('');
    setSuggestionResults(undefined);
    setIsSearchReturnModalOpen(false);
    setIsSearchNavOpen(false);
  };

  const venueItems = (): SearchReturnItemCombinedProps[] => {
    const venues = getItems('venues', 2);
    return venues?.map((venue): SearchReturnItemCombinedProps => {
      return {
        text: {
          value: venue.name,
        },
        style: 'DarkBackground',
        onEsc: returnToSearch,
        linkPath: `/venues/${venue.id}`,
        onClick: onSearchResultClick,
      };
    });
  };

  const eventItems = (): SearchReturnItemCombinedProps[] => {
    const events = getItems('events', 5);
    return events?.map((event): SearchReturnItemCombinedProps => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      const localDate = setEventDescription(event);
      return {
        text: {
          value: event.name,
        },
        description: {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          value: `${localDate} - ${handleLocation(event.venue)}`,
        },
        style: 'DarkBackground',
        linkPath: `/events/${event.id}`,
        onEsc: returnToSearch,
        onClick: onSearchResultClick,
      };
    });
  };

  const performerItems = (): SearchReturnItemCombinedProps[] => {
    const performers = getItems('performers', 3);
    return (performers as Performer[])?.map((performer): SearchReturnItemCombinedProps => {
      return {
        text: {
          value: performer.name,
        },
        style: 'DarkBackground',
        linkPath: `/performers/${performer.id}`,
        onEsc: returnToSearch,
        onClick: onSearchResultClick,
      };
    });
  };
  const ticketNavText = setTextValue();

  // gets sports, music, comedy & theatre
  const menuListItems: NavMenuItemCombinedProps[] = [];

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  data?.forEach((category) => {
    menuListItems.push({
      title: {
        value: category.name,
      },
    });
  });

  const goBackFunction = () => {
    trackSelectContentEvent(
      trackEvent,
      'Header',
      'Button',
      t('precheckoutBlock.backButton'),
    );
    if (history.action !== 'POP') {
      history.goBack();
    } else {
      history.push('/');
    }
  };
  const isPreCheckoutPage = topNavType === 'TicketSeatSelection' && currentEvent && listing;
  // on pre checkout page redirection should be to last url and in event page it should be home page
  const onBackButtonClick = (isPreCheckoutPage) ? goBackFunction : (onBackButtonClicked || goBackFunction);
  
  const searchFieldProps: SearchFieldCombinedProps = {
    type: 'SearchBubble',
    icon: {
      asset: 'SearchSmall',
    },
    onKeyPress: handleKeyPress,
    textInput: {
      style: 'Clear',
      size: 'Regular',
      inputRef: searchBarRef,
      state: searchQuery ? 'Filled' : 'Empty',
      textPlaceholder: t('search.searchFieldPlaceholder'),
      onClick: (e?: React.MouseEvent<HTMLElement>) => {
        if (isSearchNavOpen) {
          e?.stopPropagation();
        }
        if (!isDesktop) {
          setIsSearchReturnModalOpen(true);
        }
      },
      onTextChanged: handleSearchValue,
      textValue: searchQuery,
      button: {
        type: 'Icon',
        style: 'Text',
        size: 'Small',
        icon: {
          asset: 'Close',
          style: 'White',
        },
        ariaLabel: t('search.eraseSearchScreenReaderText'),
        onClick: clearSearchValue,
      },
      ariaLabel: t('search.menuUnderneathSearchFieldScreenReaderText'),
    },
    onFocus: () => setIsSearchNavOpen(true),
  };

  const searchSuggestionsProps: SearchResultsBlockCombinedProps =  {
    upcomingCategory: {
      type: 'DividerBottom',
      icon: {
        asset: 'Clock',
      },
      title: {
        value: t('search.events'),
      },
    },
    upcomingSearchReturnList: {
      searchReturnItems: eventItems(),
    },
    performersCategory: {
      type: 'DividerBottom',
      icon: {
        asset: 'Shirt',
      },
      title: {
        value: t('search.performers'),
      },
    },
    performersSearchReturnList: {
      
      searchReturnItems: performerItems(),
    },
    venueCategory: {
      type: 'DividerBottom',
      icon: {
        asset: 'Arena',
      },
      title: {
        value: t('search.venues'),
      },
    },
    venueSearchReturnList: {
      searchReturnItems: venueItems(),
    },
  };

  return {
    ...props,
    type: topNavType,
    logo: {
      onClick: () => {
        trackSelectContentEvent(
          trackEvent,
          'Header',
          'Logo',
          t('topnav.capitalOneLogoScreenReaderText'),
        );
      },
      linkPath: '/',
      asset: 'LogoC1EntertainmentWhite',
      assetAlt: t('topnav.capitalOneLogoScreenReaderText'),
    },
    title: {
      value: ticketNavText,
      ariaLabel: ticketNavText,
    },
    description:
      topNavType === 'TicketSeatSelection' && currentEvent
        ? {
          value: eventDescription,
        }
        : {
          type: 'Body',
          size: 'Small',
          style: 'Regular',
          colour: 'BaseLight',
          align: 'Left',
        },
    button: {
      onClick: openEventDetails,
    },
    navMenuList: {
      navMenuItems: menuListItems,
    },
    searchField: {
      ...searchFieldProps,
      textInput: {
        ...searchFieldProps.textInput,
        inputRef: topNavSearchBarRef,
        onTextInputClick: () => {
          trackSelectContentEvent(
            trackEvent,
            'Header',
            'SearchBar',
          );
        },
      },
      divider: {
        colour: 'White',
      },
    },
    backButton: {
      onClick: onBackButtonClick,
      ariaLabel: t('precheckoutBlock.backButton'),
    },
    searchButton: {
      icon: {
        assetAlt: t('search.searchIconScreenReaderText'),
      },
      ariaLabel: t('topnav.searchButtonReaderText'),
      onClick: () => {
        if (!isDesktop) {
          trackSelectContentEvent(
            trackEvent,
            'Header',
            'Button',
            t('topnav.searchButtonReaderText'),
          );
          setIsSearchReturnModalOpen(true);
        }
      },
    },
    eventDetailsModal: {
      event: currentEvent,
      show: showEventDetails,
      closeModal: closeEventDetails,
    },
    menuButton: {
      type: topNavType === 'SearchInNav' ? 'Default' : 'IconOnly',
      ariaLabel: t('topnav.menuButtonReaderText'),
      cardImage: {
        imageSrc: account?.loyalty_program?.program_logo_url,
        imageFallback: CardAsset,
      },
      onClick: () => {
        trackSelectContentEvent(
          trackEvent,
          'Header',
          'AccountMenu',
          t('topnav.menuButtonReaderText'),
        );
        setAccountMenuDropdownState('Expanded');
      },
    },
    accountMenu: {
      accountMenuDropdownState,
      setAccountMenuDropdownState,
      expandButton: {
        icon: {
          asset: 'ChevronDown',
          style: 'White',
        },
      },
    },
    searchReturn: {
      isModalOpen: !isDesktop && isSearchReturnModalOpen,
      searchSuggestions: searchQuery.length ? apiSuggestionResults : undefined,
      searchResult: searchSuggestionsProps,
      searchModalHeader: {
        closeButton: {
          icon: {
            asset: 'ArrowLeft',
            style: 'White',
          },
          onClick: () => {
            trackSelectContentEvent(
              trackEvent,
              'Header',
              'Search',
              t('topnav.close'),
            );
            setIsSearchReturnModalOpen(false);
            topNavSearchBarRef.current?.blur();
          },
        },
        searchField: searchFieldProps,
      },
    },
    searchNavWrapper: {
      searchField: searchFieldProps,
      searchQuery,
      searchSuggestions: apiSuggestionResults,
      searchResult: searchSuggestionsProps,
      showSearchMenu: isDesktop && isSearchNavOpen,
      handleToggle: (isOpen: boolean) => {
        if (!searchNavRef.current?.contains(document.activeElement)) {
          trackSelectContentEvent(
            trackEvent,
            'Header',
            'SearchBar',
          );
          setIsSearchNavOpen(isOpen);
        }
      },
      searchNavRef: searchNavRef,
    },
  };
};

export default usePresenter;

import React from 'react';
import cx from 'classnames';

import Text from '../../atoms/Text';
import OrderDetails from '../../organisms/OrderDetails';

import styles from './ReceiptBlock.module.scss';
import usePresenter from './ReceiptBlock.presenter';
import getProps from './ReceiptBlock.props';
import { ReceiptBlockCombinedProps } from './types';


const ReceiptBlock: React.FC<ReceiptBlockCombinedProps> = (props) => {
  const {
    className,
    classes,
    title,
    orderDetails,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.receiptBlock;

  return (
    <div className={cx(currentStyle, className)}>
      <Text
        className={cx(styles.title, classes?.title)}
        {...internalProps.title}
        {...title}/>
      <OrderDetails
        className={cx(styles.orderDetails, classes?.orderDetails)}
        {...internalProps.orderDetails}
        {...orderDetails}/>
    </div>
  );
};

export default ReceiptBlock;


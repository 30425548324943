import { ButtonProps } from '../../atoms/Button';


type FooterMenuListInternalProps = {
  button?: ButtonProps;
};

type FooterMenuListPropsValues = {
  '': FooterMenuListInternalProps;
};

const propValues: FooterMenuListPropsValues = {
  '': {
    button: {
      type: 'Text',
      style: 'Text',
      size: 'Small',
    },
  },
};

const getProps = (type: string): FooterMenuListInternalProps => {
  const values: FooterMenuListInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

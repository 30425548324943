import { DropdownMenuItemProps } from '../../atoms/DropdownMenuItem';


type DropdownSelectListInternalProps = {
  dropdownMenuItem?: DropdownMenuItemProps;
};

type DropdownSelectListPropsValues = {
  '': DropdownSelectListInternalProps;
};

const propValues: DropdownSelectListPropsValues = {
  '': {
    dropdownMenuItem: {
      type: 'Text',
    },
  },
};

const getProps = (type: string): DropdownSelectListInternalProps => {
  const values: DropdownSelectListInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

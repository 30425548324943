import React from 'react';
import cx from 'classnames';

import Tag from '../Tag';

import styles from './ExclusiveEventBanner.module.scss';
import usePresenter from './ExclusiveEventBanner.presenter';
import getProps from './ExclusiveEventBanner.props';
import { ExclusiveEventBannerCombinedProps } from './types';


const ExclusiveEventBanner: React.FC<ExclusiveEventBannerCombinedProps> = (props) => {
  const {
    className,
    classes,
    imageSrc,
    imageSrcAlt,
    imageFallback,
    tag,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.exclusiveEventBanner;

  return (
    <div className={cx(currentStyle, className)} style={{ backgroundImage: `url(${imageSrc}), url(${imageFallback})` }}>
      <Tag
        className={cx(styles.tag, classes?.tag)}
        {...internalProps.tag}
        {...tag}/>
    </div>
  );
};

export default ExclusiveEventBanner;


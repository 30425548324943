import React from 'react';
import cx from 'classnames';

import Text from '../Text';

import styles from './ToggleItem.module.scss';
import usePresenter from './ToggleItem.presenter';
import getProps from './ToggleItem.props';
import { ToggleItemCombinedProps } from './types';


const ToggleItem: React.FC<ToggleItemCombinedProps> = (props) => {
  const {
    state,
    className,
    classes,
    disabled,
    buttonType,
    buttonForm,
    href,
    target,
    externalLink,
    onClick,
    label,
  } = usePresenter(props);

  const variantName = `${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`toggleItem${variantName}`];


  let componentView;

  switch (variantName) {
    case 'Default':
    case 'Selected': {
      componentView = (
        <button
          role='tab' // MANUAL OVERRIDE
          disabled={disabled}
          type={buttonType}
          form={buttonForm}
          onClick={onClick}
          className={cx(currentStyle, className)}>
          <Text
            className={cx(styles.label, classes?.label)}
            {...internalProps.label}
            {...label}/>
        </button>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default ToggleItem;


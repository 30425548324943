import { TextProps } from '../../atoms/Text';


type InfoHeaderInternalProps = {
  title?: TextProps;
  details?: TextProps;
};

type InfoHeaderPropsValues = {
  '': InfoHeaderInternalProps;
};

const propValues: InfoHeaderPropsValues = {
  '': {
    title: {
      type: 'Subheading',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    details: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
  },
};

const getProps = (type: string): InfoHeaderInternalProps => {
  const values: InfoHeaderInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import { TextProps } from '../Text';


type EventTypeTagInternalProps = {
  label?: TextProps;
};

type EventTypeTagPropsValues = {
  'Blue': EventTypeTagInternalProps;
  'CardholderExclusive': EventTypeTagInternalProps;
};

const propValues: EventTypeTagPropsValues = {
  'Blue': {
    label: {
      type: 'Body',
      size: 'ExtraSmall',
      style: 'Regular',
      colour: 'ActionBase',
      align: 'Left',
    },
  },
  'CardholderExclusive': {
    label: {
      type: 'Body',
      size: 'ExtraSmall',
      style: 'Regular',
      colour: 'ActionBase',
      align: 'Left',
    },
  },
};

const getProps = (type: string): EventTypeTagInternalProps => {
  const values: EventTypeTagInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import { SecondaryBannerProps } from '../../molecules/SecondaryBanner';


type SecondaryBannerLightBlockInternalProps = {
  secondaryBanner?: SecondaryBannerProps;
};

type SecondaryBannerLightBlockPropsValues = {
  '': SecondaryBannerLightBlockInternalProps;
};

const propValues: SecondaryBannerLightBlockPropsValues = {
  '': {
    secondaryBanner: {
    },
  },
};

const getProps = (type: string): SecondaryBannerLightBlockInternalProps => {
  const values: SecondaryBannerLightBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import { PerformerCardProps } from '../../molecules/PerformerCard';


type PerformerCardListInternalProps = {
  performerCard?: PerformerCardProps;
};

type PerformerCardListPropsValues = {
  '': PerformerCardListInternalProps;
};

const propValues: PerformerCardListPropsValues = {
  '': {
    performerCard: {
      style: 'Light',
    },
  },
};

const getProps = (type: string): PerformerCardListInternalProps => {
  const values: PerformerCardListInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

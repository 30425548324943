import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { PerformersPageProps } from '.';
import { getPerformer } from '../../../modules/partnership/api';
import { useEffect } from 'react';

const useInteractor = (props: PerformersPageProps): PerformersPageProps => {

  const { performerId } = useParams<{ performerId: string }>();
  const { data } = useQuery(
    ['searchPerformer', performerId],
    () => getPerformer(performerId),
  );

  const isSports: boolean = data?.segment_name?.toLowerCase() === 'sports';

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    props.setTopDisclaimer?.(data?.disclaimer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.setTopDisclaimer, data]);
  
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return {
    ...props,
    isSports: isSports,
    performerId: parseInt(performerId),
    performer: data,
  };
};
export default useInteractor;
import { TextProps } from '../Text';


type SliderInfoInternalProps = {
  text?: TextProps;
};

type SliderInfoPropsValues = {
  'Dark': SliderInfoInternalProps;
  'Light': SliderInfoInternalProps;
};

const propValues: SliderInfoPropsValues = {
  'Dark': {
    text: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseLight',
      align: 'Center',
    },
  },
  'Light': {
    text: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Center',
    },
  },
};

const getProps = (type: string): SliderInfoInternalProps => {
  const values: SliderInfoInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

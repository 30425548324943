import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import braintree, { Client, HostedFields } from 'braintree-web';
import { getPaymentToken } from '../partnership/api';
import { PaymentTokenResponse } from '../partnership';
import { Props } from '../../lib/types';

export type BraintreeContextValue = {
  error: Error | null;
  loading?: boolean;
  client?: Client;
  instance?: HostedFields;
  setInstance: (instance: HostedFields) => void;
};

const initialContext: BraintreeContextValue = {
  error: null,
  loading: false,
  client: undefined,
  instance: undefined,
  setInstance: () => {},
};

export const BraintreeContext = createContext<BraintreeContextValue>(initialContext);
export const BraintreeProvider: React.FC<Props> = ({ children }) => {
  const { data, error, isLoading } = useQuery<PaymentTokenResponse, Error>('paymentToken', () => getPaymentToken());
  const [client, setClient] = useState<Client>();
  const [instance, updateInstance] = useState<HostedFields>();

  const initBraintree = useCallback(async (authorization: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    const newClient = await braintree.client.create({
      authorization: authorization,
    });
    setClient(newClient);
  }, [setClient]);

  useEffect(() => {
    if (data?.token) {
      void initBraintree(data.token);
    }
  }, [data, initBraintree]);

  const setInstance = (newInstance: HostedFields) => {
    updateInstance(newInstance);
  };

  return (
    <BraintreeContext.Provider value={{
      error: error,
      loading: isLoading,
      client,
      instance,
      setInstance,
    }}>
      {children}
    </BraintreeContext.Provider>
  );
};

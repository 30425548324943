import React, { createContext, useState } from 'react';
import { Event } from '../partnership/types';
import { Props } from '../../lib/types';
export type TopNavContextValue = {
  currentEvent?: Event;
  setCurrentEvent?: (event?: Event) => void;
  hasNoEventMap?: boolean;
  hasEventDescription?: string;
  hasEventGuestList?: boolean;
  setHasNoEventMap?: (hasNoEventMap: boolean) => void;
  setHasEventDescription?: (hasEventDescription: string | undefined) => void;
  setHasEventGuestList?: (hasEventGuestList: boolean | undefined) => void;
  setDisableTopNav?: (isVisible: boolean) => void;
  disableTopNav?: boolean;
  isSoldOutEvent?:boolean;
  setIsSoldOutEvent?: (isSoldOut?: boolean) => void;
};

const initialContext: TopNavContextValue = {
  currentEvent: undefined,
};

export const TopNavContext = createContext<TopNavContextValue>(initialContext);

export const TopNavProvider: React.FC<Props> = ({ children }) => {
  const [currentEvent, setCurrentEvent] = useState<Event | undefined>();
  const [hasNoEventMap, setHasNoEventMap] = useState<boolean>();
  const [hasEventGuestList, setHasEventGuestList] = useState<boolean | undefined>();
  const [hasEventDescription, setHasEventDescription] = useState<string | undefined>();
  const [disableTopNav, setDisableTopNav] = useState<boolean>(false);
  const [isSoldOutEvent, setIsSoldOutEvent] = useState<boolean | undefined>(false);
  const handleCurrentEvent = (event?: Event) => {
    setCurrentEvent(event);
  };
  const setHasNoEventMapValue = (eventHasMap: boolean) => {
    setHasNoEventMap(eventHasMap);
  };

  const setHasEventDescriptionValue = (eventHasDescription: string | undefined) => {
    setHasEventDescription(eventHasDescription);
  };

  const setHasEventGuestListValue = (eventHasGuestList: boolean | undefined) => {
    setHasEventGuestList(eventHasGuestList);
  };

  const setTopNavVisibility = (isVisible: boolean) => {
    setDisableTopNav(isVisible);
  };

  const handleIsSoldOutEvent = (isSoldOut?: boolean) =>{
    setIsSoldOutEvent(isSoldOut);
  };

  return (
    <TopNavContext.Provider value={{
      currentEvent: currentEvent,
      setCurrentEvent: (event?: Event) => handleCurrentEvent(event),
      hasNoEventMap: hasNoEventMap,
      setHasNoEventMap: (eventHasMap: boolean) => setHasNoEventMapValue(eventHasMap),
      hasEventDescription: hasEventDescription,
      setHasEventDescription: (eventHasDescription: string | undefined) => setHasEventDescriptionValue(eventHasDescription),
      hasEventGuestList: hasEventGuestList,
      setHasEventGuestList: (eventHasGuestList: boolean | undefined) => setHasEventGuestListValue(eventHasGuestList),
      disableTopNav: disableTopNav,
      setDisableTopNav: (isVisible: boolean) => setTopNavVisibility(isVisible),
      isSoldOutEvent:isSoldOutEvent,
      setIsSoldOutEvent:(isSoldOut?:boolean) =>handleIsSoldOutEvent(isSoldOut),
    }}>
      {children}
    </TopNavContext.Provider>
  );
};

import { NewDropdownProps } from '../../atoms/NewDropdown';


type FiltersBlockInternalProps = {
  filterOne?: NewDropdownProps;
  filterTwo?: NewDropdownProps;
};

type FiltersBlockPropsValues = {
  '': FiltersBlockInternalProps;
};

const propValues: FiltersBlockPropsValues = {
  '': {
    filterOne: {
      state: 'Closed',
      style: 'Light',
    },
    filterTwo: {
      state: 'Closed',
      style: 'Light',
    },
  },
};

const getProps = (type: string): FiltersBlockInternalProps => {
  const values: FiltersBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

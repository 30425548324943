import { useContext } from 'react';
import { SearchMatchesBlockPresenterProps, SearchMatchesBlockProps } from './types';
import { AuthContext } from '../../../modules/auth';
import { useMutation } from 'react-query';
import { getSearch } from '../../../modules/partnership/api';

const useInteractor = (props: SearchMatchesBlockProps): SearchMatchesBlockPresenterProps => {
  const { account } = useContext(AuthContext);
  const { mutateAsync: fetchSearchResults, isLoading } = useMutation(getSearch);
  return {
    ...props,
    account,
    fetchSearchResults,
    isLoading,
  };
};

export default useInteractor;
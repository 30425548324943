import { AdditionalTicketInformationListProps } from '../../molecules/AdditionalTicketInformationList';
import { CardNavigationProps } from '../../molecules/CardNavigation';
import { TicketQuantitySelectionProps } from '../../molecules/TicketQuantitySelection';
import { TicketSelectionInformationProps } from '../../molecules/TicketSelectionInformation';


type GeneralPrecheckoutInternalProps = {
  cardNavigation?: CardNavigationProps;
  ticketSelectionInformation?: TicketSelectionInformationProps;
  ticketQuantitySelection?: TicketQuantitySelectionProps;
  additionalTicketInformationList?: AdditionalTicketInformationListProps;
};

type GeneralPrecheckoutPropsValues = {
  '': GeneralPrecheckoutInternalProps;
};

const propValues: GeneralPrecheckoutPropsValues = {
  '': {
    cardNavigation: {
      type: 'Default',
      style: 'LightBackground',
    },
    ticketSelectionInformation: {
    },
    ticketQuantitySelection: {
    },
    additionalTicketInformationList: {
    },
  },
};

const getProps = (type: string): GeneralPrecheckoutInternalProps => {
  const values: GeneralPrecheckoutInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import { DayOfWeek, getCertainDay, today, tomorrow } from '../../../modules/date/utils';
import i18n from '../../../modules/locale/i18n';
import { DateFilterOption } from './types';

export const DateFilterOptions: DateFilterOption[] = [
  {
    type: 'Default',
    title: i18n.t('dateFilters.any'),
    value: undefined,
  },
  {
    type: 'Default',
    title: i18n.t('dateFilters.today'),
    value: {
      startDate: today(),
      endDate: today(),
    },
  },
  {
    type: 'Default',
    title: i18n.t('dateFilters.tomorrow'),
    value: {
      startDate: tomorrow(),
      endDate: tomorrow(),
    },
  },
  {
    type: 'Default',
    title: i18n.t('dateFilters.weekend'),
    value: {
      startDate: getCertainDay(DayOfWeek.Friday),
      endDate: getCertainDay(DayOfWeek.Sunday),
    },
  },
  {
    type: 'PickDate',
    title: i18n.t('dateFilters.chooseDate'),
    value: {},
  },
];
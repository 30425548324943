import React from 'react';
import cx from 'classnames';

import styles from './ImageList.module.scss';

import Image, { ImageProps } from '../../atoms/Image';
import { ImageListCombinedProps } from './types';
import usePresenter from './ImageList.presenter';

export const defaultProps = {
  images: [
  ] as ImageProps[],
};

const ImageList: React.FC<ImageListCombinedProps> = (props) => {
  const {
    className,
    classes,
    images,
    listElementRef,
  } = usePresenter(props);

  const imageArray = images?.map((image, index) => (
    <Image
      className={cx(styles.image, classes?.image)}
      key={index}
      index={index}
      {...image} />
  ));

  return (
    <div ref={listElementRef} className={cx(styles.imageList, className)}>
      {imageArray}
    </div>
  );
};

ImageList.defaultProps = defaultProps;

export default ImageList;


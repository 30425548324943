import React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import styles from './Logo.module.scss';
import usePresenter from './Logo.presenter';
import getProps from './Logo.props';
import { LogoCombinedProps } from './types';

import LogoC1DarkIcon from '../../../resources/icons/LogoC1Dark.svg';
import LogoC1DefaultIcon from '../../../resources/icons/LogoC1Default.svg';
import LogoC1EntertainmentBlueIcon from '../../../resources/icons/LogoC1EntertainmentBlue.svg';
import LogoC1EntertainmentWhiteIcon from '../../../resources/icons/LogoC1EntertainmentWhite.svg';
import VividLogoIcon from '../../../resources/icons/VividLogo.svg';
import VividLogoWithColourIcon from '../../../resources/icons/VividLogoWithColour.svg';

const Logo: React.FC<LogoCombinedProps> = (props) => {
  const {
    asset,
    className,
    classes,
    assetAlt,
    linkPath, // MANUAL OVERRIDE
    onClick, // MANUAL OVERRIDE
  } = usePresenter(props);

  const variantName = `${asset}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`logo${variantName}`];

  // MANUAL OVERRIDE STARTS

  let logo = '';
  switch (variantName) {
    case 'LogoC1Dark': {
      logo = LogoC1DarkIcon;
      break;
    }
    case 'LogoC1Default': {
      logo = LogoC1DefaultIcon;
      break;
    }
    case 'LogoC1EntertainmentBlue': {
      logo = LogoC1EntertainmentBlueIcon;
      break;
    }
    case 'LogoC1EntertainmentWhite': {
      logo = LogoC1EntertainmentWhiteIcon;
      break;
    }
    case 'VividLogo': {
      logo = VividLogoIcon;
      break;
    }
    case 'VividLogoWithColour': {
      logo = VividLogoWithColourIcon;
      break;
    }
  }

  if (linkPath) {
    return (
      <Link
        onClick={onClick}
        to={linkPath}>
        <img
          alt={assetAlt}
          src={logo}
          className={cx(currentStyle, className)} />
      </Link>
    );
  }

  return (
    <img
      alt={assetAlt}
      src={logo}
      className={cx(currentStyle, className)} />
  );
  // MANNUAL OVERRIDE ENDS
};

export default Logo;


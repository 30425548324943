import React from 'react';
import { Dropdown } from 'react-bootstrap';


import styles from './SearchNavWrapper.module.scss';
import { SearchNavWrapperProps } from './types';
import SearchResultsBlock from '../SearchResultsBlock';
import SubmenuWithDoubleTabs from '../../organisms/SubmenuWithDoubleTabs';
import SearchField from '../../molecules/SearchField';

const SearchNavWrapper: React.FC<SearchNavWrapperProps> = ({
  searchField,
  searchQuery,
  searchSuggestions,
  searchResult,
  showSearchMenu,
  handleToggle,
  searchNavRef,
}) => {
  let resultView;
  if (searchSuggestions && (searchSuggestions.events.length 
    || searchSuggestions.performers.length || searchSuggestions.venues.length)) {
    resultView = <SearchResultsBlock
      className={styles.searchResult}
      searchSuggestions={searchSuggestions}
      {...searchResult} />;
  }
  
  const contentView = searchQuery ? (
    resultView
  ) : (
    <SubmenuWithDoubleTabs />
  );

  return (
    <Dropdown className={styles.dropdown} show={showSearchMenu} onToggle={handleToggle} ref={searchNavRef}>
      <Dropdown.Toggle as='div' className={styles.dropdownToggle} aria-expanded={undefined}>
        <SearchField
          className={styles.searchField}
          {...searchField} />
      </Dropdown.Toggle>
      {showSearchMenu ? <Dropdown.Menu className={styles.dropdownMenu} >
        {contentView}
      </Dropdown.Menu> : null}
    </Dropdown>
  );
};

export default SearchNavWrapper;
import { ButtonCombinedProps } from '../../atoms/Button';
import { DividerProps } from '../../atoms/Divider';
import { TextProps } from '../../atoms/Text';


type NavMenuItemInternalProps = {
  divider?: DividerProps;
  backButton?: ButtonCombinedProps; // MANUAL OVERRIDE
  title?: TextProps;
  divider1?: DividerProps;
  nextButton?: ButtonCombinedProps; // MANUAL OVERRIDE
};

type NavMenuItemPropsValues = {
  'Back': NavMenuItemInternalProps;
  'Default': NavMenuItemInternalProps;
  'Next': NavMenuItemInternalProps;
};

const propValues: NavMenuItemPropsValues = {
  'Back': {
    divider: {
      type: 'Horizontal',
      style: 'Thin',
      colour: 'DigitalGrey15',
    },
    backButton: {
      type: 'Icon',
      style: 'Text',
      size: 'Small',
      // MANUAL OVERRIDE STARTS
      icon: {
        asset: 'ChevronLeft',
        style: 'White',
      },
      // MANUAL OVERRIDE ENDS
    },
    title: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'BaseLight',
      align: 'Left',
    },
  },
  'Default': {
    divider: {
      type: 'Horizontal',
      style: 'Thin',
      colour: 'DigitalGrey15',
    },
    title: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'BaseLight',
      align: 'Left',
    },
    divider1: {
      type: 'Horizontal',
      style: 'Thin',
      colour: 'DigitalGrey15',
    },
  },
  'Next': {
    divider: {
      type: 'Horizontal',
      style: 'Thin',
      colour: 'DigitalGrey15',
    },
    title: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'BaseLight',
      align: 'Left',
    },
    nextButton: {
      type: 'Icon',
      style: 'Text',
      size: 'Small',
      // MANUAL OVERRIDE STARTS
      icon: {
        asset: 'ChevronRight',
        style: 'White',
      },
      // MANUAL OVERRIDE ENDS
    },
    divider1: {
      type: 'Horizontal',
      style: 'Thin',
      colour: 'DigitalGrey15',
    },
  },
};

const getProps = (type: string): NavMenuItemInternalProps => {
  const values: NavMenuItemInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import React from 'react';
import cx from 'classnames';
import { Modal } from 'react-bootstrap';

import Divider from '../../atoms/Divider';
import SeatMapBlock from '../../blocks/SeatMapBlock';
import SeatSelectionBlock from '../../blocks/SeatSelectionBlock';
import FilterSetting from '../../organisms/FilterSetting';

import styles from './SeatSelectionPage.module.scss';
import usePresenter from './SeatSelectionPage.presenter';
import getProps from './SeatSelectionPage.props';
import { SeatSelectionPageCombinedProps } from './types';
import { MapProvider } from '../../../modules/map';
import Spinner from '../../atoms/Spinner';
import Button from '../../atoms/Button';
import PrecheckoutBlock from '../../blocks/PrecheckoutBlock';
import EmptyStateBanner from '../../molecules/EmptyStateBanner';


const SeatSelectionPage: React.FC<SeatSelectionPageCombinedProps> = (props) => {
  const {
    className,
    classes,
    seatSelectionBlock,
    filterSetting,
    divider,
    seatMapBlock,
    // MANUAL OVERRIDE STARTS
    type,
    event,
    seatSelectionBlockMobile,
    isModalOpen,
    applyFilter,
    resetFilter,
    isExclusive,
    precheckout,
    isLoading,
    isListingsEmpty,
    emptyStateBanner,
    emptyStateBannerDivider,
    isMobile,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles[`seatSelectionPage${type}`];
  // MANUAL OVERRIDE STARTS
  if (isLoading) {
    return (
      <MapProvider>
        <div className={cx(currentStyle, className)}>
          <Spinner />
        </div>
      </MapProvider>
    );
  }

  let view = (
    <div className={styles.leftContainer}>
      <SeatSelectionBlock
        className={styles.seatSelectionBlock}
        {...internalProps.seatSelectionBlock}
        {...seatSelectionBlock}
        event={event}
      />
      <FilterSetting
        className={styles.filterSetting}
        {...internalProps.filterSetting}
        {...filterSetting}
        isExclusive={isExclusive}
      />
    </div>
  );

  let mobileView = (
    <div className={styles.mobileContainer}>
      <SeatSelectionBlock
        className={styles.seatSelectionBlockMobile}
        {...seatSelectionBlockMobile}
        event={event}
      />
    </div>
  );

  if (isListingsEmpty) {
    view = (
      <div className={styles.leftContainer}>
        <EmptyStateBanner
          className={styles.emptyStateBanner}
          {...emptyStateBanner}
        />
      </div>
    );

    mobileView = (
      <>
        <Divider
          className={styles.emptyStateBannerDivider}
          {...emptyStateBannerDivider}
        />
        <div className={styles.mobileContainer}>
          <EmptyStateBanner
            className={styles.emptyStateBanner}
            {...emptyStateBanner}
          />
        </div>
      </>
    );
  }

  if (type === 'Precheckout') {
    view = (
      <div className={styles.leftContainer}>
        <PrecheckoutBlock {...precheckout} />
      </div>
    );
  }

  const filterModalView = (
    <Modal
      className={cx(styles.filterModal, className)}
      dialogClassName={styles.modalDialog}
      contentClassName={styles.modalContent}
      show={isModalOpen}
    >
      <Modal.Body className={styles.modalContent}>
        <FilterSetting
          className={styles.filterSetting}
          {...filterSetting}
          isExclusive={isExclusive}
        />
      </Modal.Body>
      <Modal.Footer className={styles.modalFooter}>
        <Button {...applyFilter} />
        <Button {...resetFilter} />
      </Modal.Footer>
    </Modal>
  );

  return (
    <>
      <MapProvider>
        <div className={cx(currentStyle, className)}>
          {view}
          {/* MANUAL OVERRIDE */}
          <Divider className={styles.divider}
            {...internalProps.divider}
            {...divider} />
          <SeatMapBlock
            className={styles.seatMapBlock}
            isExclusive={isExclusive}
            {...seatMapBlock}
          />
          {mobileView}
        </div>
      </MapProvider>
      {filterModalView}
    </>
  );
};

export default SeatSelectionPage;


import React from 'react';
import cx from 'classnames';

import Text from '../../atoms/Text';
import TextInput from '../../atoms/TextInput';

import styles from './TextFields.module.scss';
import usePresenter from './TextFields.presenter';
import getProps from './TextFields.props';
import { TextFieldsCombinedProps } from './types';


const TextFields: React.FC<TextFieldsCombinedProps> = (props) => {
  const {
    state,
    className,
    classes,
    label,
    textInput,
    error, // MANUAL OVERRIDE - renamed prop
    ariaLive, // MANUAL OVERRIDE
  } = usePresenter(props);

  const variantName = `${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`textFields${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Default': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Text
            className={cx(styles.label, classes?.label)}
            {...internalProps.label}
            {...label}/>
          <TextInput
            className={cx(styles.textInput, classes?.textInput)}
            {...internalProps.textInput}
            {...textInput}/>
        </div>
      );
      break;
    }
    case 'Error': {
      componentView = (
        // MANUAL OVERRIDE - added aria-live to announce error
        <div className={cx(currentStyle, className)} aria-live={ariaLive}>
          <Text
            className={cx(styles.label, classes?.label)}
            {...internalProps.label}
            {...label}/>
          <TextInput
            className={cx(styles.textInput, classes?.textInput)}
            {...internalProps.textInput}
            {...textInput}/>
          {/* MANUAL OVERRIDE - renamed prop */}
          <Text
            className={cx(styles.error, classes?.error)}
            {...internalProps.error}
            {...error}/>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default TextFields;


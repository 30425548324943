import React from 'react';
import cx from 'classnames';

import Text from '../../atoms/Text';

import styles from './InfoOnMultipleLine.module.scss';
import usePresenter from './InfoOnMultipleLine.presenter';
import getProps from './InfoOnMultipleLine.props';
import { InfoOnMultipleLineCombinedProps } from './types';


const InfoOnMultipleLine: React.FC<InfoOnMultipleLineCombinedProps> = (props) => {
  const {
    className,
    classes,
    label,
    buyerName,
    address,
    cityProvinceCountry,
    postalCode,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.infoOnMultipleLine;

  return (
    <div className={cx(currentStyle, className)}>
      <Text
        className={cx(styles.label, classes?.label)}
        {...internalProps.label}
        {...label}/>
      <Text
        className={cx(styles.buyerName, classes?.buyerName)}
        {...internalProps.buyerName}
        {...buyerName}/>
      <Text
        className={cx(styles.address, classes?.address)}
        {...internalProps.address}
        {...address}/>
      <Text
        className={cx(styles.cityProvinceCountry, classes?.cityProvinceCountry)}
        {...internalProps.cityProvinceCountry}
        {...cityProvinceCountry}/>
      <Text
        className={cx(styles.postalCode, classes?.postalCode)}
        {...internalProps.postalCode}
        {...postalCode}/>
    </div>
  );
};

export default InfoOnMultipleLine;


import { useEffect } from 'react';
import { useAnalyticsManager } from '../../../modules/analytics/useAnalyticsManager';
import { trackPageViewEvent } from '../../../modules/analytics/util';
import { HelpPageCombinedProps } from './types';

const usePresenter = (props: HelpPageCombinedProps): HelpPageCombinedProps => {
  const { trackEvent } = useAnalyticsManager();

  useEffect(() => {
    trackPageViewEvent(
      trackEvent,
      'Help Page',
    );
  }, []);
  return props;
};

export default usePresenter;

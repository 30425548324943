import { createSelector } from 'reselect';
import type { MapState, MapStateType } from './types';

export const mapContent = createSelector(
  (state: MapStateType) => {
    return { data: state.data };
  },
  ({ data }) => data,
);

export const getMapState = createSelector(
  (mapState: MapState) => {
    return { mapState: mapState };
  },
  ({ mapState }) => mapState,
);

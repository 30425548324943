import { TextProps } from '../../atoms/Text';
import { LogoProps } from '../../atoms/Logo';
import { CostBreakdownProps } from '../../molecules/CostBreakdown';
import { TextItemProps } from '../../molecules/TextItem';
import { SeatInfoProps } from '../../molecules/SeatInfo';


type OrderInfoInternalProps = {
  title1?: TextProps;
  amount?: TextProps;
  miles?: TextProps;
  costBreakdown?: CostBreakdownProps;
  title2?: TextProps;
  dateAndTime?: TextProps;
  venue?: TextProps;
  seatInfo?: SeatInfoProps;
  sellerNotes?: TextItemProps;
  deliveryMethod?: TextItemProps;
  logo?: LogoProps;
  // MANUAL OVERRIDE STARTS
  faceValue?: TextProps;
  // MANUAL OVERRIDE ENDS
};

type OrderInfoPropsValues = {
  '': OrderInfoInternalProps;
};

const propValues: OrderInfoPropsValues = {
  '': {
    title1: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    amount: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    miles: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    costBreakdown: {
    },
    title2: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    dateAndTime: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    venue: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    seatInfo: {
    },
    sellerNotes: {
      style: 'SubduedLabel',
    },
    deliveryMethod: {
      style: 'SubduedLabel',
    },
    logo: {
      asset: 'VividLogo',
    },
    // MANUAL OVERRIDE STARTS
    faceValue: {
      type: 'Body',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
      size: 'Small',
    },
    // MANUAL OVERRIDE ENDS
  },
};

const getProps = (type: string): OrderInfoInternalProps => {
  const values: OrderInfoInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

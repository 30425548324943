import { ButtonProps } from '../../atoms/Button';
import { TextProps } from '../../atoms/Text';
import { IconProps } from '../../atoms/Icon';
import { ImageProps } from '../../atoms/Image';
import { TeamHomeAwayImageProps } from '../../atoms/TeamHomeAwayImage';
import { EventInfoProps } from '../EventInfo';
import { PerformerInfoProps } from '../PerformerInfo';
import { TicketStatusProps } from '../TicketStatus';


type CardInternalProps = {
  image?: ImageProps;
  eventInfo?: EventInfoProps;
  icon?: IconProps;
  button?: ButtonProps;
  day?: TextProps;
  date?: TextProps;
  year?: TextProps;
  time?: TextProps;
  performerInfo?: PerformerInfoProps;
  teamHomeAwayImage?: TeamHomeAwayImageProps;
  ticketStatus?: TicketStatusProps;
};

type CardPropsValues = {
  'DefaultLong': CardInternalProps;
  'DefaultSquare': CardInternalProps;
  'ExclusiveLong': CardInternalProps;
  'NoThumbnailImageLong': CardInternalProps;
  'TeamsLong': CardInternalProps;
  'TicketStatusLong': CardInternalProps;
};

const propValues: CardPropsValues = {
  'DefaultLong': {
    image: {
    },
    eventInfo: {
      state: 'Default',
      type: 'Default',
    },
    icon: {
      asset: 'ChevronRight',
      style: 'ActionActive',
    },
    button: {
      type: 'Text',
      style: 'Contained',
      size: 'Medium',
    },
  },
  'DefaultSquare': {
    image: {
    },
    eventInfo: {
      state: 'Default',
      type: 'Default',
    },
    // MANUAL OVERRIDE STARTS
    icon: {
      asset: 'ChevronRight',
      style: 'White',
    },
    // MANUAL OVERRIDE ENDS
  },
  'ExclusiveLong': {
    image: {
    },
    eventInfo: {
      state: 'SoldOut',
      type: 'Exclusive',
    },
    icon: {
      asset: 'ChevronRight',
      style: 'White', // MANUAL OVERRIDE
    },
    button: {
      type: 'Text',
      style: 'Contained',
      size: 'Medium',
    },
  },
  'NoThumbnailImageLong': {
    day: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Center',
    },
    date: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    year: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Center',
    },
    time: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Center',
    },
    performerInfo: {
      state: 'Default',
    },
    button: {
      type: 'Text',
      style: 'Contained',
      size: 'Medium',
    },
    icon: {
      asset: 'ChevronRight',
      style: 'ActionBase',
    },
  },
  'TeamsLong': {
    teamHomeAwayImage: {
      type: 'Long',
    },
    eventInfo: {
      state: 'Default',
      type: 'Default',
    },
    icon: {
      asset: 'ChevronRight',
      style: 'ActionActive',
    },
    button: {
      type: 'Text',
      style: 'Contained',
      size: 'Medium',
    },
  },
  'TicketStatusLong': {
    image: {
    },
    ticketStatus: {
      state: 'Positive',
    },
  },
};

const getProps = (type: string): CardInternalProps => {
  const values: CardInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

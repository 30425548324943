import { TextProps } from '../Text';


type EventStatusTagInternalProps = {
  label?: TextProps;
};

type EventStatusTagPropsValues = {
  'JustAnnounced': EventStatusTagInternalProps;
  'LowTickets': EventStatusTagInternalProps;
  'SoldOut': EventStatusTagInternalProps;
};

const propValues: EventStatusTagPropsValues = {
  'JustAnnounced': {
    label: {
      type: 'Body',
      size: 'Small',
      style: 'SemiBold',
      colour: 'Positive',
      align: 'Left',
    },
  },
  'LowTickets': {
    label: {
      type: 'Body',
      size: 'Small',
      style: 'SemiBold',
      colour: 'Warning',
      align: 'Left',
    },
  },
  'SoldOut': {
    label: {
      type: 'Body',
      size: 'Small',
      style: 'SemiBold',
      colour: 'Negative',
      align: 'Left',
    },
  },
};

const getProps = (type: string): EventStatusTagInternalProps => {
  const values: EventStatusTagInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

export enum DayOfWeek {
  Sunday = 0,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
}

export const today = () : Date => {
  const date = new Date();
  return date;
};

export const tomorrow = () : Date => {
  const date = today();
  date.setDate(date.getDate() + 1);
  return date;
};

export const getCertainDay = (dayOfWeek: DayOfWeek) : Date => {
  const date = today();
  date.setDate(date.getDate() + (dayOfWeek + 7 - date.getDay()) % 7);
  return date;
};

export const setStartTime = (date: Date) : Date => {
  date.setHours(0, 0, 0, 0);
  return date;
};

export const setEndTime = (date: Date) : Date => {
  date.setHours(23, 59, 59, 59);
  return date;
};
import axios from 'axios';
import { RegionsQueryParams, UserOrdersResponse } from '.';
import { BFF_URL } from '../../lib/config';
import { ApiError } from '../error/types';
import { resetTimer } from '../userIdle/sessionTimeout';

import {
  EventPageResponse,
  Event,
  PerformerPageResponse,
  PerformersQueryParams,
  SearchSuggestionsResponse,
  RegionPageResponse,
  Venue,
  SearchPageResponse,
  SearchQueryParams,
  SearchSuggestionsQueryParams,
  ListingDetailsQueryParams,
  ListingsQueryParams,
  ListingsResponse,
  Order,
  CategoriesPageResponse,
  PageQueryParams,
  ListingDetails,
  PaymentTokenResponse,
  EventsQueryParams,
  PerformerDetail,
  Performer,
  Region,
} from './types';
import { filerSearchResults, filterEventPage, filterSearchSuggestions, matchesEvent, matchesListingDetails, matchesPerformer } from './utils';
import { handleError } from '../error/utils';

const stripEmptyParams = (params: any): any => {
  if (!params) {
    return undefined;
  }
  const results = {};
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  Object.keys(params).forEach((key) => {
    const value = params[key];
    if (value) {
      results[key] = value;
    }
  });
  return results;
};

export const getPerformers = async (
  params?: PerformersQueryParams,
): Promise<PerformerPageResponse> => {
  try {
    const { data } = await axios.get<PerformerPageResponse>(
      `${BFF_URL}/performers`,
      {
        params: stripEmptyParams(params),
        withCredentials: true,
      },
    );
    resetTimer();
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getTopPerformers = async (
  params?: PerformersQueryParams,
): Promise<Performer[]> => {
  try {
    const { data } = await axios.get<Performer[]>(
      `${BFF_URL}/performers/top`,
      {
        params: stripEmptyParams(params),
        withCredentials: true,
      },
    );
    resetTimer();
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getPerformer = async (performerId: string): Promise<PerformerDetail> => {
  try {
    const { data } = await axios.get<PerformerDetail>(
      `${BFF_URL}/performers/${performerId}`,
      {
        withCredentials: true,
      },
    );
    resetTimer();
    if (matchesPerformer(data)) {
      throw new ApiError({
        code: 404,
        type: 'not found',
        message: 'not found',
      });
    }
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getPerformerOpponents = async (
  performerId: string,
): Promise<PerformerPageResponse> => {
  try {
    const { data } = await axios.get<PerformerPageResponse>(
      `${BFF_URL}/performers/${performerId}/opponents`,
      {
        withCredentials: true,
      },
    );
    resetTimer();
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getEvents = async (params?:EventsQueryParams): Promise<EventPageResponse> => {
  try {
    const { data } = await axios.get<EventPageResponse>(
      `${BFF_URL}/events`,
      {
        params: stripEmptyParams(params),
        withCredentials: true,
      },
    );
    resetTimer();
    return filterEventPage(data);
  } catch (error) {
    throw handleError(error);
  }
};

export const getEvent = async (eventId: string): Promise<Event> => {
  try {
    const { data } = await axios.get<Event>(
      `${BFF_URL}/events/${eventId}`,
      {
        withCredentials: true,
      },
    );
    resetTimer();
    if (matchesEvent(data)) {
      throw new ApiError({
        code: 404,
        type: 'not found',
        message: 'not found',
      });
    }
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getRegions = async (params?: RegionsQueryParams): Promise<Region[]> => {
  try {
    const { data } = await axios.get<Region[]>(
      `${BFF_URL}/regions`,
      {
        params: stripEmptyParams(params),
        withCredentials: true,
      },
    );
    resetTimer();
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getRegionsByIp = async (): Promise<Region[]> => {
  try {
    const { data } = await axios.get<Region[]>(
      `${BFF_URL}/regions/by_ip`,
      {
        withCredentials: true,
      },
    );
    resetTimer();
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getVenue = async (venueId: string): Promise<Venue> => {
  try {
    const { data } = await axios.get<Venue>(
      `${BFF_URL}/venues/${venueId}`,
      {
        withCredentials: true,
      },
    );
    resetTimer();
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getSearch = async (params: SearchQueryParams): Promise<SearchPageResponse> => {
  try {
    const { data } = await axios.get<SearchPageResponse>(
      `${BFF_URL}/search`,
      {
        params: stripEmptyParams(params),
        withCredentials: true,
      },
    );
    resetTimer();
    return filerSearchResults(data);
  } catch (error) {
    throw handleError(error);
  }
};

export const getSearchSuggestions = async (
  params: SearchSuggestionsQueryParams,
): Promise<SearchSuggestionsResponse> => {
  try {
    const { data } = await axios.get<SearchSuggestionsResponse>(
      `${BFF_URL}/search-suggestions`,
      {
        params: stripEmptyParams(params),
        withCredentials: true,
      },
    );
    resetTimer();
    return filterSearchSuggestions(data);
  } catch (error) {
    throw handleError(error);
  }
};

export const getListings = async (params: ListingsQueryParams): Promise<ListingsResponse> => {
  try {
    const { data } = await axios.get<ListingsResponse>(
      `${BFF_URL}/listings`,
      {
        params: stripEmptyParams(params),
        withCredentials: true,
      },
    );
    resetTimer();
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getListingDetails = async (
  eventId: string, listingId: string, params: ListingDetailsQueryParams,
): Promise<ListingDetails> => {
  try {
    const { data } = await axios.get<ListingDetails>(
      `${BFF_URL}/events/${eventId}/listings/${listingId}`,
      {
        params: stripEmptyParams(params),
        withCredentials: true,
      },
    );
    resetTimer();
    if (matchesListingDetails(data)) {
      throw new ApiError({
        code: 404,
        type: 'not found',
        message: 'not found',
      });
    }
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getPaymentToken = async (): Promise<PaymentTokenResponse> => {
  try {
    const { data } = await axios.get<PaymentTokenResponse>(
      `${BFF_URL}/payment/client-token`,
      {
        withCredentials: true,
      },
    );
    resetTimer();
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const createOrder = async (order: Order): Promise<Order> => {
  try {
    const { data } = await axios.post<Order>(
      `${BFF_URL}/orders`,
      order,
      {
        withCredentials: true,
      },
    );
    resetTimer();
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getMyOrders = async (): Promise<UserOrdersResponse> => {
  try {
    const { data } = await axios.get<UserOrdersResponse>(
      `${BFF_URL}/user/orders`,
      {
        withCredentials: true,
      },
    );
    resetTimer();
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getFilesByName = async (params: { orderId?: string, fileName?: string }): Promise<any> => {
  try {
    const { data } = await axios.get<UserOrdersResponse>(
      `${BFF_URL}/orders/${params.orderId}/ticket/${params.fileName}`,
      {
        withCredentials: true,
      },
    );
    resetTimer();
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getCategories = async (
  params: PageQueryParams,
): Promise<CategoriesPageResponse> => {
  try {
    const { data } = await axios.get<CategoriesPageResponse>(
      `${BFF_URL}/categories`,
      {
        params: stripEmptyParams(params),
        withCredentials: true,
      },
    );
    resetTimer();
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

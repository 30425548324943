import React from 'react';
import cx from 'classnames';


import styles from './SeatMapBlock.module.scss';
import usePresenter from './SeatMapBlock.presenter';
import getProps from './SeatMapBlock.props';
import { SeatMapBlockCombinedProps } from './types';
import MapBlock from '../../../modules/map/components/block/MapBlock';


const SeatMapBlock: React.FC<SeatMapBlockCombinedProps> = (props) => {
  const {
    className,
    classes,
    isExclusive, // MANUAL OVERRIDE
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.seatMapBlock;

  return (
    <div className={cx(currentStyle, className)}>
      <MapBlock isExclusive={isExclusive} />
    </div>
  );
};

export default SeatMapBlock;


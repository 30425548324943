import { useTranslation } from 'react-i18next';
import useInteractor from './SportsLandingPage.interactor';
import { SportsLandingPageCombinedProps } from './types';

import { CLOUDFRONT_URL } from '../../../lib/config';
import { ThumbnailTextItemCombinedProps } from '../../../legacy/molecules/ThumbnailTextItem/types';
import { useEffect } from 'react';
import { useAnalyticsManager } from '../../../modules/analytics/useAnalyticsManager';

const usePresenter = (props: SportsLandingPageCombinedProps): SportsLandingPageCombinedProps => {
  const { sportsLeague, location } = useInteractor(props);
  const { t } = useTranslation();
  const { trackEvent } = useAnalyticsManager();


  useEffect(() => {
    trackEvent('view_item_list', {
      ecommerce: {
        item_list_name: 'sport_landing_page_View',
      },
    });
  }, []);
  const thumbnailTextItems: ThumbnailTextItemCombinedProps[] | undefined =
    sportsLeague?.children?.map((team) => {
      return {
        teamLogo: {
          teamInitials: team.teamCode,
          textColour: team.secondaryColor,
          backgroundColour: team.primaryColor,
        },
        button: {
          text: {
            value: team.name,
          },
          href: team.slug,
        },
      };
    });

  return {
    ...props,
    landingPageBannerBlock: {
      landingPageBanner: {
        image: {
          imageSrc: `${CLOUDFRONT_URL}/${sportsLeague?.image}`,
        },
        text: {
          value: t(`sportsLeague.${location}`),
        },
      },
    },
    sportTeamsListBlock: {
      text: {
        value: t('sportsLeague.shopByTeam'),
      },
      thumbnailTextList: {
        thumbnailTextItems: thumbnailTextItems,
      },
    },
  };
};

export default usePresenter;

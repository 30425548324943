import React from 'react';
import cx from 'classnames';

import PerformerCard from '../../molecules/PerformerCard';

import styles from './PerformerCardList.module.scss';
import usePresenter from './PerformerCardList.presenter';
import getProps from './PerformerCardList.props';
import { PerformerCardListCombinedProps } from './types';


const PerformerCardList: React.FC<PerformerCardListCombinedProps> = (props) => {
  const {
    className,
    classes,
    performerCards,
    listElementRef, // MANUAL OVERRIDE
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.performerCardList;

  const performerCardViews = performerCards?.map((performerCard, index) => (
    <PerformerCard
      key={index}
      index={index}
      className={cx(styles.performerCard, classes?.performerCard)}
      {...internalProps.performerCard}
      {...performerCard} />
  )) || [];

  return (
    // MANUAL OVERRIDE - added ref
    <div className={cx(currentStyle, className)} ref={listElementRef}>
      {performerCardViews}
    </div>
  );
};

export default PerformerCardList;


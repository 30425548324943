import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import { FilterProvider } from '../../../modules/filter';
import { ListingsProvider } from '../../../modules/listings';
import { getEvent } from '../../../modules/partnership/api';

import EventPageView from './EventPage';
import { EventPageProps } from './types';

export type { EventPageProps };

const EventPage: React.FC<EventPageProps> = (props) => {
  const { eventId } = useParams<{ eventId: string }>();
  const event = useQuery(['getEvent', eventId], () => getEvent(eventId));

  return (
    <ListingsProvider eventData={event?.data} eventId={eventId}>
      <FilterProvider>
        <EventPageView {...props} />
      </FilterProvider>
    </ListingsProvider>
  );
};

export default EventPage;

import { TicketQuantityCombinedProps } from './types';

const usePresenter = (props: TicketQuantityCombinedProps): TicketQuantityCombinedProps => {
  return {
    ...props,
    minusButton: {
      ...props.minusButton,
      icon: {
        ...props.minusButton?.icon,
        asset: 'Minus',
      },
    },
    plusButton: {
      ...props.plusButton,
      icon: {
        ...props.plusButton?.icon,
        asset: 'Plus',
      },
    },
  };
};

export default usePresenter;

import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { getUnitDisplaySettings, handleDecimalValuesForDisplay, handleAmountFormattingString, handleDisplayUnitName } from '../../../lib/util';
import { AuthContext } from '../../../modules/auth';
import CardAsset from '../../../resources/legacyIcons/CreditCard.svg';

import customStyles from './Custom.module.scss';
import { AccountMenuBlockCombinedProps } from './types';

const usePresenter = (props: AccountMenuBlockCombinedProps): AccountMenuBlockCombinedProps => {
  const { account } = useContext(AuthContext);
  const history = useHistory();
  const { t } = useTranslation();
  const rewardType = account?.loyalty_program?.loyalty_unit_name.toLowerCase();
  const { rewardSign, useDecimals } = getUnitDisplaySettings(rewardType);
  const numberUnits = handleDecimalValuesForDisplay(handleAmountFormattingString(account?.loyalty_program?.number_of_units, !useDecimals), useDecimals);

  return {
    ...props,
    isModalOpen: props.isModalOpen,
    accountMenu: {
      state: props.accountMenu?.state,
      greetingFirstName: {
        value: `${t('topnav.welcome', {
          greetingFirstName: account?.first_name,
        })}`,
      },
      cardImage: {
        imageSrc: account?.loyalty_program?.program_logo_url,
        imageFallback: CardAsset,
      },
      cardNumber: {
        value: `${t('topnav.cardNumber', {
          cardNumber: account?.loyalty_program?.last_four,
        })}`,
      },
      loyaltyPoints: {
        value: `${rewardSign}${numberUnits}  ${handleDisplayUnitName(account)}`,
      },
      closeButton: {
        ...props.accountMenu?.closeButton,
      },
    },
    myTickets: {
      classes: {
        divider1: customStyles.divider1,
      },
      title: {
        value: t('topnav.accountMenu.myTickets'),
      },
      onClick: () => history.push('/mytickets'),
    },
    logOut: {
      title: {
        value: t('topnav.accountMenu.logOut'),
      },
      onClick: () => history.push('/logout'),
    },
  };
};

export default usePresenter;

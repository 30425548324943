import { useContext, useEffect } from 'react';
import { useMutation } from 'react-query';
import { PrecheckoutBlockProps } from '.';
import { AuthContext } from '../../../modules/auth';
import { getMyOrders } from '../../../modules/partnership/api';
import { TopNavContext } from '../../../modules/topNav';
import { PrecheckoutBlockInteractor } from './types';

const useInteractor = (props: PrecheckoutBlockProps): PrecheckoutBlockInteractor => {
  const { account } = useContext(AuthContext);
  const { error, mutateAsync } = useMutation(getMyOrders);
  const { setDisableTopNav } = useContext(TopNavContext);
  useEffect(() => {
    if (setDisableTopNav) {
      if (props.isExclusive) {
        setDisableTopNav(true);
      } else {
        setDisableTopNav(false);
      }
    }
    return () => {
      if (setDisableTopNav) {
        setDisableTopNav(false);
      }
    };
  }, [props?.isExclusive, setDisableTopNav]);

  return {
    account,
    fetchOrders: mutateAsync,
    error: error as Error,
  };
};

export default useInteractor;
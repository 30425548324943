import { useEffect } from 'react';
import { useAnalyticsManager } from '../../../modules/analytics/useAnalyticsManager';
import { trackPageViewEvent } from '../../../modules/analytics/util';
import { SearchMatchPageCombinedProps } from './types';

const usePresenter = (props: SearchMatchPageCombinedProps): SearchMatchPageCombinedProps => {
  const { trackEvent } = useAnalyticsManager();

  useEffect(() => {
    trackPageViewEvent(
      trackEvent,
      'Search Page',
    );
  }, []);

  return {
    ...props,
    searchBlock: {
      searchContainer: {
        state: 'Default',
      },
    },
  };
};

export default usePresenter;

import { Event } from '../../../modules/partnership';

export const filterOutMLB = (items: Event[]) => {
  return items.filter(item => item.taxonomy.genre !== 'MLB Baseball');
};

export const removeDuplicates = (eventsArr: Event[]): Event[] => {
  const eventMap: Record<string, Event> = {};
  eventsArr.forEach((event) => {
    const trimmedName = event.name.replace(/ *\([^)]*\) */g, '');
    if (!eventMap[trimmedName] || eventMap[trimmedName].utc_date > event.utc_date) {
      eventMap[trimmedName] = event;
    }
  });
  return Object.values(eventMap);
};

export const removePastEvents = (eventsArr: Event[]): Event[] => {
  const currentDate = new Date().getTime();
  return eventsArr.filter(item => new Date(item?.utc_date || '').getTime() > currentDate);
};
import React, { useRef } from 'react';
import cx from 'classnames';

import Button from '../Button';

import customStyles from './Custom.module.scss';
import styles from './TextInput.module.scss';
import usePresenter from './TextInput.presenter';
import getProps from './TextInput.props';
import { TextInputCombinedProps } from './types';


const TextInput: React.FC<TextInputCombinedProps> = (props) => {
  const {
    state,
    style,
    size,
    className,
    classes,
    textValue,
    textPlaceholder,
    onTextChanged,
    onValidateText,
    onKeyDown,
    onBlur,
    onClick,
    inputType,
    maxLength,
    disabled,
    button,
    // MANUAL OVERRIDE STARTS
    ariaAtomic,
    ariaLabel,
    ariaLive,
    inputRef,
    removeChar,
    onBlurChanged,
    onFocusChanged,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const variantName = `${state}${style}${size}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`textInput${variantName}`];

  const [inputValue, setInputValue] = React.useState<string | number>('');

  React.useEffect(() => {
    if (inputType === 'number') {
      setInputValue(`${textValue || 0}`);
    } else {
      setInputValue(textValue || '');
    }
  }, [textValue]);

  const localRef = useRef<HTMLInputElement>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    if (removeChar && inputRef && inputRef.current) {
      inputRef.current.value = event.target.value.replace(removeChar, '');
    } else if (removeChar && localRef.current) {
      localRef.current.value = event.target.value.replace(removeChar, '');
    }
    if (onTextChanged) {
      onTextChanged(event);
    }
  };

  const handleFocus = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    if (onFocusChanged) {
      onFocusChanged(event);
    }
  };

  const handleBlur = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    if (onBlurChanged) {
      onBlurChanged(event);
    }
  };
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    if (event.key === 'Enter') {
      const targetElement = event.target as HTMLInputElement;
      const form = targetElement?.form;
      if (form) {
        const index = Array.prototype.indexOf.call(form, event.target);
        (form.elements[index + 1] as HTMLElement).focus();
        (form.elements[index + 1] as HTMLElement).click();
        event.preventDefault();
      }
      // upon enter we must check if there is any blur event waiting to change state
      if (onBlurChanged) {
        onBlurChanged(event as unknown as React.ChangeEvent<HTMLInputElement>);
      }
    }
  };

  // const onInputChanged = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
  //   const validEntry = onValidateText ? onValidateText(event.target.value) : true;
  //   if (validEntry) {
  //     setInputValue(event.target.value);
  //     if (onTextChanged) {
  //       onTextChanged(event.target.value);
  //     }
  //   }
  // };
  let componentView;

  const isLiveRegion = ariaLive && ariaLive;

  const accessibilityView = isLiveRegion !== 'off' ? (
    <div className={customStyles.visuallyHidden} aria-live={ariaLive} aria-atomic={ariaAtomic} aria-label={ariaLabel}>
    </div>
  ) : null;

  switch (variantName) {
    case 'EmptyDefaultCenterRegular':
    case 'EmptyDefaultRegular':
    case 'EmptyDefaultSmall':
    case 'FilledDefaultCenterRegular':
    case 'FilledDefaultRegular':
    case 'FilledDefaultSmall':
    case 'EmptyClearRegular': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <input
            ref={inputRef || localRef} // MANUAL OVERRIDE
            aria-label={isLiveRegion ? undefined : ariaLabel} // MANUAL OVERRIDE
            className={cx(styles.input, classes?.input)}
            placeholder={textPlaceholder}
            value={inputValue}
            onChange={handleChange}
            onKeyDown={onKeyDown}
            onBlur={onBlur}
            onClick={onClick}
            onFocus={handleFocus} // MANUAL OVERRIDE
            disabled={disabled}
            type={inputType}
            maxLength={maxLength} />
          {/* MANUAL OVERRIDE - adding a visually hidden div
            to announce actively edited value in input */}
          {accessibilityView}
        </div>
      );
      break;
    }
    case 'FilledClearRegular': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <input
            ref={inputRef || localRef}
            aria-label={isLiveRegion ? undefined : ariaLabel} // MANUAL OVERRIDE
            className={cx(styles.input, classes?.input)}
            placeholder={textPlaceholder}
            value={inputValue}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onKeyDown={handleKeyPress}
            onClick={onClick}
            disabled={disabled}
            type={inputType}
            maxLength={maxLength} />
          {/* MANUAL OVERRIDE - adding a visually hidden div
            to announce actively edited value in input */}
          {accessibilityView}
          <Button
            className={cx(styles.button, classes?.button)}
            {...internalProps.button}
            {...button} />
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default TextInput;


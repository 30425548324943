import React from 'react';
import cx from 'classnames';

import Text from '../Text';

import styles from './EventStatusTag.module.scss';
import usePresenter from './EventStatusTag.presenter';
import getProps from './EventStatusTag.props';
import { EventStatusTagCombinedProps } from './types';


const EventStatusTag: React.FC<EventStatusTagCombinedProps> = (props) => {
  const {
    type,
    className,
    classes,
    label,
  } = usePresenter(props);

  const variantName = `${type}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`eventStatusTag${variantName}`];

  let componentView;

  switch (variantName) {
    case 'JustAnnounced':
    case 'LowTickets':
    case 'SoldOut': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Text
            className={cx(styles.label, classes?.label)}
            {...internalProps.label}
            {...label}/>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default EventStatusTag;


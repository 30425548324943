import { TopNavTab } from '../../../modules/navigation/types';
import categoryEvents from '../../../modules/navigation/CategoryEvents.json';
import { useLocation } from 'react-router-dom';
import { SportsLandingPageCombinedProps, SportsLandingPagePresenterProps } from './types';

const useInteractor = (props: SportsLandingPageCombinedProps): SportsLandingPagePresenterProps => {
  const data: TopNavTab[] = JSON.parse(JSON.stringify(categoryEvents));
  const location = useLocation();
  const place = location.pathname;
  const sportsObject = data.find((category) => category.id === 'sports');
  const sportsLeague = sportsObject?.children.find((league) => league.slug === place);
  return {
    ...props,
    location: place,
    sportsLeague: sportsLeague,
  };
};

export default useInteractor;

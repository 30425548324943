import { ToggleProps } from '../../atoms/Toggle';
import { TrackProps } from '../../atoms/Track';


type SliderInternalProps = {
  trackEmpty?: TrackProps;
  trackFilled?: TrackProps;
  rightToggle?: ToggleProps;
  leftToggle?: ToggleProps;
  toggle?: ToggleProps;
};

type SliderPropsValues = {
  'Dual': SliderInternalProps;
  'Single': SliderInternalProps;
};

const propValues: SliderPropsValues = {
  'Dual': {
    trackEmpty: {
      state: 'Empty',
    },
    trackFilled: {
      state: 'Filled',
    },
    rightToggle: {
      size: 'Large',
    },
    leftToggle: {
      size: 'Large',
    },
  },
  'Single': {
    trackEmpty: {
      state: 'Empty',
    },
    trackFilled: {
      state: 'Filled',
    },
    toggle: {
      size: 'Large',
    },
  },
};

const getProps = (type: string): SliderInternalProps => {
  const values: SliderInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

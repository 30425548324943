import { ReceiptBlockProps } from '../../blocks/ReceiptBlock';


type ReceiptPageInternalProps = {
  receiptBlock?: ReceiptBlockProps;
};

type ReceiptPagePropsValues = {
  '': ReceiptPageInternalProps;
};

const propValues: ReceiptPagePropsValues = {
  '': {
    receiptBlock: {
    },
  },
};

const getProps = (type: string): ReceiptPageInternalProps => {
  const values: ReceiptPageInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

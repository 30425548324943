import React from 'react';
import cx from 'classnames';

import Text from '../../atoms/Text';
import HighlightMessage from '../../molecules/HighlightMessage';
import RewardOptionsList from '../../organisms/RewardOptionsList';

import styles from './LoyaltyBlock.module.scss';
import usePresenter from './LoyaltyBlock.presenter';
import getProps from './LoyaltyBlock.props';
import { LoyaltyBlockCombinedProps } from './types';


const LoyaltyBlock: React.FC<LoyaltyBlockCombinedProps> = (props) => {
  const {
    className,
    classes,
    blockTitle,
    description,
    highlightMessage,
    rewardOptionsList,
    // MANUAL OVERRIDE STARTS
    showError,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.loyaltyBlock;

  // MANUAL OVERRIDE STARTS - showing error message conditionally
  const errorMessageView = showError ? (
    <HighlightMessage
      className={cx(styles.highlightMessage, classes?.highlightMessage)}
      {...internalProps.highlightMessage}
      {...highlightMessage}/>
  ) : null;
  // MANUAL OVERRIDE ENDS

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.topContent, classes?.topContent)}>
        <Text
          className={cx(styles.blockTitle, classes?.blockTitle)}
          {...internalProps.blockTitle}
          {...blockTitle}/>
        <Text
          className={cx(styles.description, classes?.description)}
          {...internalProps.description}
          {...description}/>
      </div>
      {/* MANUAL OVERRIDE - showing error message conditionally */}
      {errorMessageView}
      <RewardOptionsList
        className={cx(styles.rewardOptionsList, classes?.rewardOptionsList)}
        {...internalProps.rewardOptionsList}
        {...rewardOptionsList}/>
    </div>
  );
};

export default LoyaltyBlock;


/* eslint-disable @typescript-eslint/no-unsafe-call, @typescript-eslint/restrict-plus-operands, @typescript-eslint/no-unsafe-return */
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { MapContext } from '../../..';
import Spinner from '../../../../../legacy/atoms/Spinner';
import { MIN_MAP_ZOOM_HEIGHT } from '../../../../../lib/constants';
import { FilterContext } from '../../../../filter';
import { ListingsContext } from '../../../../listings';
import { STYLES } from '../../../constants';
import StaticImageMap from '../../molecules/StaticImageMap';
import Tooltip from '../../molecules/Tooltip';
import StyledMap from '../../organisms/StyledMap';
import { getTooltipPositionFromDimensions } from '../../../utils';
import ContentLoadErrorBlock from '../../../../../legacy/blocks/ContentLoadErrorBlock';
import { ContentLoadErrorBlockCombinedProps } from '../../../../../legacy/blocks/ContentLoadErrorBlock/types';
export type MapBlockProps = {
  className?: string;
  isExclusive?: boolean;
};
let renders = 0;
const MapBlock: React.FC<MapBlockProps> = ({ className, isExclusive }) => {
  renders++;
  const timer = useRef(0);

  const { t } = useTranslation();
  const { selectors: listingsSelectors, isLoading } =
    useContext(ListingsContext);
  const hasInteractiveMap = !!listingsSelectors.mapJsonFileName;
  const { seatViewObject } = listingsSelectors;
  const [selectedSection, setSelectedSection] = useState<string | undefined>();
  const zoomEnabled = window.innerHeight > MIN_MAP_ZOOM_HEIGHT;

  const {
    state: filterState,
    selectors: {
      filteredSections,
      filteredSectionsWithPrice,
      getHighlightedSections,
    },
    dispatch: { onSectionSelectAction, onResetSectionSelectionAction },
  } = useContext(FilterContext);

  const {
    selectors: { mapContent, mapState },
    //dispatch: { setMinPrice, setHoveredSectionId, setPos, setIsMouseDown, setIsDoubleClicked, setMapState },
    dispatch: { setIsMouseDown, setIsDoubleClicked, setMapState },
  } = useContext(MapContext);

  const [toolTipProps, setToolTipProps] = useState<{
    minPrice: { id: string; value: number };
    pos: { x: number, y: number };
  }>({
    minPrice: mapState.minPrice,
    pos: mapState.pos,
  });

  const clickHandler = useCallback(
    (event: any) => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
      timer.current = window.setTimeout(() => {
        const { id } = event.target;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        if (!filteredSections.includes(id) || mapState.isDragging) return;
        // if (detailsState.listing) {
        //     toggleDetails({ listing: null })
        // }
        onSectionSelectAction({ id });

        if (selectedSection === id) {
          setSelectedSection('');
          onResetSectionSelectionAction();
        } else {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          setSelectedSection(id);
        }
        setMapState({
          ...mapState,
          hoveredSectionId: '',
          minPrice: { id: '', value: 0 },
        });
        // setMinPrice({ id: '', value: 0 });
        // trackEvent('TicketMapUse', 'SectionFilter-TicketMap', 'clicked', id, false)
        // trackEvent(
        //     'FilterUse',
        //     'ResetTicketFilters',
        //     `Zone-${selected.includes(id) ? 'Deselected' : 'Selected'}`,
        //     undefined,
        //     false,
        // )
        /*
        This zoom event makes no sense but thats how they want it
        When a user clicks on a seat view in the map, we send section-tooltip
        */
        // trackEvent('Seat View', 'zoom', 'section-tooltip', undefined, false)
      }, 200);
    },
    [
      filteredSections,
      mapState,
      onResetSectionSelectionAction,
      onSectionSelectAction,
      selectedSection,
      setMapState,
    ],
  );

  const doubleClickHandler = useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    setIsDoubleClicked(true);
  }, []);

  const mouseOverHandler = useCallback((event: any) => {
    const { id } = event.target;
    let minPrice = { id: '', value: 0 };
    if (
      !filteredSections.includes(id as string) ||
      !filteredSectionsWithPrice[id]
    )
      return;

    minPrice = {
      id,
      value: filterState.showAllInPrice
        ? filteredSectionsWithPrice[id].allInPrice
        : filteredSectionsWithPrice[id].price,
    };

    const { x, y, width, height } = event.target.getBoundingClientRect() as DOMRect;
    const position = getTooltipPositionFromDimensions(x, y, width, height);
    setToolTipProps({ minPrice, pos: position });
  }, []);

  const mouseOutHandler = useCallback(() => {
    const minPrice = { id: '', value: 0 };
    setToolTipProps({ minPrice, pos: { x: 0, y: 0 } });
  }, []);

  const contentLoadProps: ContentLoadErrorBlockCombinedProps = useMemo(() => {
    return {
      title: {
        value: t('ErrorPage.ErrorBlock.pleaseTryAgain'),
      },
      message: {
        value: t('ErrorPage.ErrorBlock.unableToLoadContent'),
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const memoSectionsStatus = useMemo(() => {
    return {
      available: {
        style: {
          fill: STYLES.colors.teal['300'],
        },
        hoverStyle: {
          fill: '#f7b4df',
        },
        items: filteredSections,
      },
      selected: {
        style: STYLES.selectedSection,
        items: filteredSections.some((available) =>
          getHighlightedSections.includes(available),
        )
          ? getHighlightedSections
          : [],
      },
      hovered: {
        style: {
          ...STYLES.selectedSection,
          cursor: mapState.isMouseDown ? 'grabbing' : 'pointer',
        },
        items: filteredSections.includes(mapState.hoveredSectionId)
          ? [mapState.hoveredSectionId]
          : [],
      },
    };
  }, [
    filteredSections,
    mapState.hoveredSectionId,
    mapState.isMouseDown,
    getHighlightedSections,
  ]);

  let mapView;
  if (hasInteractiveMap) {
    mapView = (
      <>
        <Tooltip
          show={!!toolTipProps?.minPrice.id}
          {...toolTipProps.pos}
          followMouse={false}
        >
          <span>
            from <strong> ${toolTipProps?.minPrice.value} </strong>
          </span>
        </Tooltip>
        <StyledMap
          jsonMap={mapContent}
          callbacks={{
            onMouseOver: mouseOverHandler,
            onMouseOut: mouseOutHandler,
            onClick: clickHandler,
            onDoubleClick: doubleClickHandler,
            onPointerDownCapture: () => setIsMouseDown(true),
            onPointerUpCapture: () => setIsMouseDown(false),
          }}
          withPanAndZoom={zoomEnabled}
          sectionsStatus={memoSectionsStatus}
        />
      </>
    );
  } else if (seatViewObject?.staticMapUrl && isExclusive) {
    mapView = <StaticImageMap />;
  } else if (isLoading) {
    mapView = <Spinner />;
  } else {
    mapView = <ContentLoadErrorBlock {...contentLoadProps} />;
  }
  return mapView;
};

export default MapBlock;

import { ButtonProps } from '../../atoms/Button';
import { TextProps } from '../../atoms/Text';
import { DividerProps } from '../../atoms/Divider';
import { AccountMenuProps } from '../../molecules/AccountMenu';
import { MenuButtonProps } from '../../molecules/MenuButton';
import { SearchFieldProps } from '../../molecules/SearchField';
import { LogoCombinedProps } from '../../atoms/Logo/types';


type TopNavInternalProps = {
  logo?: LogoCombinedProps; // MANUAL OVERRIDE
  accountMenu?: AccountMenuProps;
  menuButton?: MenuButtonProps;
  searchButton?: ButtonProps;
  backButton?: ButtonProps;
  title?: TextProps;
  button?: ButtonProps;
  description?: TextProps;
  searchField?: SearchFieldProps;
  divider?: DividerProps;
  divider1?: DividerProps;
};

type TopNavPropsValues = {
  'Checkout': TopNavInternalProps;
  'SearchInNav': TopNavInternalProps;
  'SignedOut': TopNavInternalProps;
  'TicketSeatSelection': TopNavInternalProps;
  'WithoutSearchInNav': TopNavInternalProps;
};

const propValues: TopNavPropsValues = {
  'Checkout': {
    logo: {
      asset: 'LogoC1EntertainmentWhite',
    },
    accountMenu: {
      state: 'Collapsed',
    },
    menuButton: {
      type: 'Default',
    },
  },
  'SearchInNav': {
    logo: {
      asset: 'LogoC1EntertainmentWhite',
    },
    searchButton: {
      type: 'Icon',
      style: 'Text',
      size: 'Small',
    },
    menuButton: {
      type: 'Default',
    },
    accountMenu: {
      state: 'Collapsed',
    },
  },
  'SignedOut': {
    logo: {
      asset: 'LogoC1EntertainmentWhite',
    },
  },
  'TicketSeatSelection': {
    logo: {
      asset: 'LogoC1EntertainmentWhite',
    },
    backButton: {
      type: 'Icon',
      style: 'Text',
      size: 'Small',
    },
    title: {
      type: 'Subheading',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseLight',
      align: 'Left',
    },
    button: {
      type: 'Icon',
      style: 'Text',
      size: 'Small',
    },
    description: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'BaseLight',
      align: 'Left',
    },
    searchField: {
      type: 'SearchBubble',
    },
    accountMenu: {
      state: 'Collapsed',
    },
  },
  'WithoutSearchInNav': {
    logo: {
      asset: 'LogoC1EntertainmentWhite',
    },
    searchField: {
      type: 'SearchBubble',
    },
    accountMenu: {
      state: 'Collapsed',
    },
    divider: {
      type: 'Vertical',
      style: 'Thin',
      colour: 'PrimaryBlue60',
    },
    menuButton: {
      type: 'IconOnly',
    },
    divider1: {
      type: 'Horizontal',
      style: 'Thin',
      colour: 'PrimaryBlue60',
    },
  },
};

const getProps = (type: string): TopNavInternalProps => {
  const values: TopNavInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

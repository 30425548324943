import { DividerProps } from '../../atoms/Divider';
import { StepperListProps } from '../../organisms/StepperList';


type StepperBlockInternalProps = {
  stepperList?: StepperListProps;
  divider?: DividerProps;
};

type StepperBlockPropsValues = {
  '': StepperBlockInternalProps;
};

const propValues: StepperBlockPropsValues = {
  '': {
    stepperList: {
    },
    divider: {
      type: 'Horizontal',
      style: 'Thin',
      colour: 'DigitalGrey15',
    },
  },
};

const getProps = (type: string): StepperBlockInternalProps => {
  const values: StepperBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

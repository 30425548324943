import React from 'react';
import cx from 'classnames';

import InstructionLabel from '../../molecules/InstructionLabel';
import SelectTicket from '../../organisms/SelectTicket';
import TicketList from '../../organisms/TicketList';

import styles from './SeatSelectionBlock.module.scss';
import usePresenter from './SeatSelectionBlock.presenter';
import getProps from './SeatSelectionBlock.props';
import { SeatSelectionBlockCombinedProps } from './types';
import Tooltip from '../../../modules/map/components/molecules/Tooltip';


const SeatSelectionBlock: React.FC<SeatSelectionBlockCombinedProps> = (props) => {
  const {
    className,
    classes,
    selectTicket,
    instructionLabel,
    ticketList,
    // MANUAL OVERRIDE STARTS
    handleClick,
    setMinPrice,
    seatNumberAriaLabel,
    toolTipProps,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.seatSelectionBlock;

  return (
    // MANUAL OVERRIDE STARTS
    <div className={cx(currentStyle, className)}
      onClick={() =>
        handleClick
          ? handleClick(
            setMinPrice
              ? setMinPrice({
                id: '',
                value: 0,
              })
              : null,
          )
          : null
      }
    >
      <div aria-live={'assertive'} aria-label={seatNumberAriaLabel}></div>
      <InstructionLabel
        className={cx(styles.instructionLabel, classes?.instructionLabel)}
        {...internalProps.instructionLabel}
        {...instructionLabel}/>
      <TicketList
        className={cx(styles.ticketList, classes?.ticketList)}
        {...internalProps.ticketList}
        {...ticketList}/>
      <Tooltip
        show={Boolean(toolTipProps?.minPrice.id)}
        {...toolTipProps?.pos}
        followMouse={false}
      >
        <span>
          from <strong> ${toolTipProps?.minPrice.value} </strong>
        </span>
      </Tooltip>
    </div>
    // MANUAL OVERRIDE ENDS
  );
};

export default SeatSelectionBlock;


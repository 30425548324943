import { useHistory } from 'react-router-dom';
import { SubmenuTabItemCombinedProps } from './types';

const usePresenter = (props: SubmenuTabItemCombinedProps): SubmenuTabItemCombinedProps => {
  const { onItemClicked, linkPath } = props;
  const history = useHistory();
  const clickAction = () => {
    if (linkPath) {
      history.push(linkPath);
    }
    if (onItemClicked) {
      onItemClicked();
    }
  };
  return {
    ...props,
    onItemClicked: clickAction,
  };
};

export default usePresenter;

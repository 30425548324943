import { DividerProps } from '../../atoms/Divider';
import { TextProps } from '../../atoms/Text';


type PrimaryBannerInternalProps = {
  title?: TextProps;
  divider?: DividerProps;
  message?: TextProps;
};

type PrimaryBannerPropsValues = {
  '': PrimaryBannerInternalProps;
};

const propValues: PrimaryBannerPropsValues = {
  '': {
    title: {
      type: 'NewBannerHeading',
      size: 'Large', // MANUAL OVERRIDE
      style: 'Regular',
      colour: 'BaseLight',
      align: 'Left',
    },
    divider: {
      type: 'Horizontal',
      style: 'Thin2px',
      colour: 'CP1Red',
    },
    message: {
      type: 'NewBannerBody',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseLight',
      align: 'Left',
    },
  },
};

const getProps = (type: string): PrimaryBannerInternalProps => {
  const values: PrimaryBannerInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

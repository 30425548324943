import React from 'react';
import cx from 'classnames';

import Icon from '../../atoms/Icon';
import Text from '../../atoms/Text';

import styles from './DateAndTime.module.scss';
import usePresenter from './DateAndTime.presenter';
import getProps from './DateAndTime.props';
import { DateAndTimeCombinedProps } from './types';


const DateAndTime: React.FC<DateAndTimeCombinedProps> = (props) => {
  const {
    className,
    classes,
    icon,
    text,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.dateAndTime;

  return (
    <div className={cx(currentStyle, className)}>
      <Icon
        className={cx(styles.icon, classes?.icon)}
        {...internalProps.icon}
        {...icon}/>
      <Text
        className={cx(styles.text, classes?.text)}
        {...internalProps.text}
        {...text}/>
    </div>
  );
};

export default DateAndTime;


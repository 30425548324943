import { DropdownSelectProps } from '../../atoms/DropdownSelect';
import { TextProps } from '../../atoms/Text';
import { DropDownStateType } from './types';


type DropdownInternalProps = {
  label?: TextProps;
  dropdownSelect?: DropdownSelectProps;
  error?: TextProps; // MANUAL OVERRIDE
  state?: DropDownStateType; // MANUAL OVERRIDE
};

type DropdownPropsValues = {
  '': DropdownInternalProps;
};

const propValues: DropdownPropsValues = {
  '': {
    label: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
    dropdownSelect: {
      state: 'Empty',
    },
    error: { // MANUAL OVERRIDE
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'Negative',
      align: 'Left',
    },
    state: 'Default', // MANUAL OVERRIDE
  },
};

const getProps = (type: string): DropdownInternalProps => {
  const values: DropdownInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import { TextProps } from '../../atoms/Text';
import { ButtonProps } from '../../atoms/Button';
import { DividerProps } from '../../atoms/Divider';
import { EventStatusTagProps } from '../../atoms/EventStatusTag';
import { EventTypeTagProps } from '../../atoms/EventTypeTag';
import { IconProps } from '../../atoms/Icon';
import { ImageProps } from '../../atoms/Image';


type EventCardVenueInternalProps = {
  image?: ImageProps;
  eventTypeTag?: EventTypeTagProps;
  eventTypeTag1?: EventTypeTagProps;
  eventStatusTag?: EventStatusTagProps;
  titleText?: TextProps;
  dateAndTimeText?: TextProps;
  locationAndPriceText?: TextProps;
  divider?: DividerProps;
  actionText?: TextProps;
  icon?: IconProps;
  button?: ButtonProps;
};

type EventCardVenuePropsValues = {
  '': EventCardVenueInternalProps;
};

const propValues: EventCardVenuePropsValues = {
  '': {
    image: {
    },
    eventTypeTag: {
      type: 'CardholderExclusive',
    },
    eventTypeTag1: {
      type: 'Blue',
    },
    eventStatusTag: {
      type: 'JustAnnounced',
    },
    titleText: {
      type: 'Body',
      size: 'Large',
      style: 'SemiBold',
      colour: 'BaseDark',
      align: 'Left',
    },
    dateAndTimeText: {
      type: 'CardBody',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    locationAndPriceText: {
      type: 'CardBody',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    button: {
      type: 'Text',
      style: 'Contained',
      size: 'Medium',
    },
    divider: {
      type: 'Horizontal',
      style: 'Thin',
      colour: 'DigitalGrey15',
    },
    actionText: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'ActionHover',
      align: 'Left',
    },
    icon: {
      asset: 'ArrowRightFilled',
      style: 'ActionBase',
    },
  },
};

const getProps = (type: string): EventCardVenueInternalProps => {
  const values: EventCardVenueInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

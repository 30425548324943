import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import Divider from '../../atoms/Divider';
import Text from '../../atoms/Text';
import TextInput from '../../atoms/TextInput';

import styles from './Content.module.scss';
import usePresenter from './Content.presenter';
import getProps from './Content.props';
import { ContentCombinedProps } from './types';


const Content: React.FC<ContentCombinedProps> = (props) => {
  const {
    state,
    className,
    classes,
    textRedeem,
    texMiles,
    textMilesFor,
    textDollar,
    button,
    textInput,
    saveButton,
    divider,
    cancelButton,
  } = usePresenter(props);

  const variantName = `${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`content${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Default': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          {/* MANUAL OVERRIDE - wrapped components in a div */}
          <div className={cx(styles.info, classes?.info)}>
            <Text
              className={cx(styles.textRedeem, classes?.textRedeem)}
              // MANUAL OVERRIDE
              classes={{
                value: styles.value,
              }}
              {...internalProps.textRedeem}
              {...textRedeem}/>
            <Text
              className={cx(styles.texMiles, classes?.texMiles)}
              {...internalProps.texMiles}
              {...texMiles}/>
            <Text
              className={cx(styles.textMilesFor, classes?.textMilesFor)}
              // MANUAL OVERRIDE
              classes={{
                value: styles.value,
              }}
              {...internalProps.textMilesFor}
              {...textMilesFor}/>
            <Text
              className={cx(styles.textDollar, classes?.textDollar)}
              {...internalProps.textDollar}
              {...textDollar}/>
          </div>
          <div className={cx(styles.actions, classes?.actions)}>
            <Button
              className={cx(styles.button, classes?.button)}
              {...internalProps.button}
              {...button}/>
          </div>
        </div>
      );
      break;
    }
    case 'Edit': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          {/* MANUAL OVERRIDE - wrapped components in a div */}
          <div className={cx(styles.info, classes?.info)}>
            <Text
              className={cx(styles.textRedeem, classes?.textRedeem)}
              // MANUAL OVERRIDE
              classes={{
                value: styles.value,
              }}
              {...internalProps.textRedeem}
              {...textRedeem}/>
            <TextInput
              className={cx(styles.textInput, classes?.textInput)}
              {...internalProps.textInput}
              {...textInput}/>
            <Text
              className={cx(styles.textMilesFor, classes?.textMilesFor)}
              // MANUAL OVERRIDE
              classes={{
                value: styles.value,
              }}
              {...internalProps.textMilesFor}
              {...textMilesFor}/>
            <Text
              className={cx(styles.textDollar, classes?.textDollar)}
              {...internalProps.textDollar}
              {...textDollar}/>
          </div>
          <div className={cx(styles.actions, classes?.actions)}>
            <Button
              className={cx(styles.saveButton, classes?.saveButton)}
              {...internalProps.saveButton}
              {...saveButton}/>
            <Divider
              className={cx(styles.divider, classes?.divider)}
              {...internalProps.divider}
              {...divider}/>
            <Button
              className={cx(styles.cancelButton, classes?.cancelButton)}
              {...internalProps.cancelButton}
              {...cancelButton}/>
          </div>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default Content;


import React from 'react';
import cx from 'classnames';

import TicketInformation from '../../molecules/TicketInformation';

import styles from './TicketList.module.scss';
import usePresenter from './TicketList.presenter';
import getProps from './TicketList.props';
import { TicketListCombinedProps } from './types';


const TicketList: React.FC<TicketListCombinedProps> = (props) => {
  const {
    style,
    className,
    classes,
    ticketInformations, // MANUAL OVERRIDE
  } = usePresenter(props);

  const variantName = `${style}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`ticketList${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Default':
    case 'WithSpace': {
      const ticketInformationViews = ticketInformations?.map((ticketInformation, index) => (
        <TicketInformation
          key={index}
          className={cx(styles.ticketInformation, classes?.ticketInformation)}
          {...internalProps.ticketInformation}
          {...ticketInformation} />
      )) || [];

      componentView = (
        <div className={cx(currentStyle, className)}>
          {ticketInformationViews}
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default TicketList;


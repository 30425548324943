export const DEFAULT_CONTEXT_VALUES: { state: any; selectors: any; dispatch: any } = {
  state: {},
  selectors: {},
  dispatch: {},
};

export const MAP_VALUES = {
  MIN_SCALE: 1,
  STEP: 0.1,
};


export const STYLES = {
  breakpoint: {
    mobile: 375,
    tablet: 768,
    desktop: 1024,
    desktopXl: 1440,
  },
  headerHeight: {
    mobile: 48,
    desktop: 64,
  },
  disclaimerHeight: 24,
  colors: {
    teal: {
      300: '#78e4e8',
    },
    magenta: {
      200: '#f7b4df',
      400: '#ce3197',
    },
  },
  highlightedListing: {
    backgroundColor: '#f7b4df',
  },
  selectedSection: {
    fill: '#f7b4df',
    stroke: '#ce3197',
    'stroke-width': '2px',
  },
};
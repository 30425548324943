import React from 'react';
import cx from 'classnames';

import Text from '../../atoms/Text';
import Tag from '../Tag';

import styles from './EventInfo.module.scss';
import usePresenter from './EventInfo.presenter';
import getProps from './EventInfo.props';
import { EventInfoCombinedProps } from './types';


const EventInfo: React.FC<EventInfoCombinedProps> = (props) => {
  const {
    state,
    type,
    className,
    classes,
    title,
    date,
    time,
    location,
    price,
    exclusive,
    tag,
  } = usePresenter(props);

  const variantName = `${state}${type}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`eventInfo${variantName}`];

  let componentView;

  switch (variantName) {
    case 'DefaultDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Text
            className={cx(styles.title, classes?.title)}
            {...internalProps.title}
            {...title}/>
          <div className={cx(styles.eventLogistics, classes?.eventLogistics)}>
            <div className={cx(styles.dateAndTime, classes?.dateAndTime)}>
              <Text
                className={cx(styles.date, classes?.date)}
                {...internalProps.date}
                {...date}/>
              <Text
                className={cx(styles.time, classes?.time)}
                {...internalProps.time}
                {...time}/>
            </div>
            <div className={cx(styles.locationAndPrice, classes?.locationAndPrice)}>
              <Text
                className={cx(styles.location, classes?.location)}
                {...internalProps.location}
                {...location}/>
              <Text
                className={cx(styles.price, classes?.price)}
                {...internalProps.price}
                {...price}/>
            </div>
          </div>
        </div>
      );
      break;
    }
    case 'DefaultExclusive': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Tag
            className={cx(styles.exclusive, classes?.exclusive)}
            {...internalProps.exclusive}
            {...exclusive}/>
          <Text
            className={cx(styles.title, classes?.title)}
            {...internalProps.title}
            {...title}/>
          <div className={cx(styles.eventLogistics, classes?.eventLogistics)}>
            <div className={cx(styles.dateAndTime, classes?.dateAndTime)}>
              <Text
                className={cx(styles.date, classes?.date)}
                {...internalProps.date}
                {...date}/>
              <Text
                className={cx(styles.time, classes?.time)}
                {...internalProps.time}
                {...time}/>
            </div>
            <div className={cx(styles.locationAndPrice, classes?.locationAndPrice)}>
              <Text
                className={cx(styles.location, classes?.location)}
                {...internalProps.location}
                {...location}/>
              <Text
                className={cx(styles.price, classes?.price)}
                {...internalProps.price}
                {...price}/>
            </div>
          </div>
        </div>
      );
      break;
    }
    case 'SoldOutDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Text
            className={cx(styles.title, classes?.title)}
            {...internalProps.title}
            {...title}/>
          <div className={cx(styles.eventLogistics, classes?.eventLogistics)}>
            <div className={cx(styles.dateAndTime, classes?.dateAndTime)}>
              <Text
                className={cx(styles.date, classes?.date)}
                {...internalProps.date}
                {...date}/>
              <Text
                className={cx(styles.time, classes?.time)}
                {...internalProps.time}
                {...time}/>
            </div>
            <div className={cx(styles.locationAndStatus, classes?.locationAndStatus)}>
              <Text
                className={cx(styles.location, classes?.location)}
                {...internalProps.location}
                {...location}/>
              <Tag
                className={cx(styles.tag, classes?.tag)}
                {...internalProps.tag}
                {...tag}/>
            </div>
          </div>
        </div>
      );
      break;
    }
    case 'SoldOutExclusive': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Tag
            className={cx(styles.exclusive, classes?.exclusive)}
            {...internalProps.exclusive}
            {...exclusive}/>
          <Text
            className={cx(styles.title, classes?.title)}
            {...internalProps.title}
            {...title}/>
          <div className={cx(styles.eventLogistics, classes?.eventLogistics)}>
            <div className={cx(styles.dateAndTime, classes?.dateAndTime)}>
              <Text
                className={cx(styles.date, classes?.date)}
                {...internalProps.date}
                {...date}/>
              <Text
                className={cx(styles.time, classes?.time)}
                {...internalProps.time}
                {...time}/>
            </div>
            <div className={cx(styles.locationAndStatus, classes?.locationAndStatus)}>
              <Text
                className={cx(styles.location, classes?.location)}
                {...internalProps.location}
                {...location}/>
              <Tag
                className={cx(styles.tag, classes?.tag)}
                {...internalProps.tag}
                {...tag}/>
            </div>
          </div>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default EventInfo;


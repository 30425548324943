import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import Text from '../../atoms/Text';
import TextItem from '../../molecules/TextItem';
import PaymentMethod from '../../molecules/PaymentMethod';

import styles from './PaymentInformationReadOnlyBlock.module.scss';
import usePresenter from './PaymentInformationReadOnlyBlock.presenter';
import getProps from './PaymentInformationReadOnlyBlock.props';
import { PaymentInformationReadOnlyBlockCombinedProps } from './types';


const PaymentInformationReadOnlyBlock: React.FC<PaymentInformationReadOnlyBlockCombinedProps> = (props) => {
  const {
    className,
    classes,
    blockTitle,
    edit,
    address,
    phoneNumber,
    paymentMethod,
    rewards,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.paymentInformationReadOnlyBlock;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.topContent, classes?.topContent)}>
        <Text
          className={cx(styles.blockTitle, classes?.blockTitle)}
          {...internalProps.blockTitle}
          {...blockTitle}/>
        {/* MANUAL OVERRIDE - replaced Text with Button */}
        <Button
          className={cx(styles.edit, classes?.edit)}
          {...internalProps.edit}
          {...edit}/>
      </div>
      <div className={cx(styles.bottomContent, classes?.bottomContent)}>
        <div className={cx(styles.leftContent, classes?.leftContent)}>
          <TextItem
            className={cx(styles.address, classes?.address)}
            {...internalProps.address}
            {...address}/>
          <TextItem
            className={cx(styles.phoneNumber, classes?.phoneNumber)}
            {...internalProps.phoneNumber}
            {...phoneNumber}/>
        </div>
        <div className={cx(styles.rightContent, classes?.rightContent)}>
          <PaymentMethod
            className={cx(styles.paymentMethod, classes?.paymentMethod)}
            {...internalProps.paymentMethod}
            {...paymentMethod}/>
          <TextItem
            className={cx(styles.rewards, classes?.rewards)}
            {...internalProps.rewards}
            {...rewards}/>
        </div>
      </div>
    </div>
  );
};

export default PaymentInformationReadOnlyBlock;


import React, { useEffect, useMemo, useRef, useState } from 'react';
import SimpleMap, { SimpleMapProps } from '../../molecules/SimpleMap';
import SvgPanZoom from '../../molecules/SvgPanZoom';
import styles from './styles.module.scss';

interface StyleSection {
  [property: string]: string
}

interface SectionStatus {
  style?: StyleSection
  hoverStyle?: StyleSection
  items: Array<string>
}

interface SectionsStatus {
  selected: SectionStatus
  available: SectionStatus
  hovered: SectionStatus
  [anyotherStatus: string]: SectionStatus
}

export interface MapProps extends SimpleMapProps {
  sectionsStatus?: SectionsStatus
  withPanAndZoom?: boolean
  specificity?: string
}

const StyledMap: React.FC<MapProps> = ({
  sectionsStatus = {} as SectionsStatus,
  withPanAndZoom = false,
  specificity = 'original-map',
  ...mapProps
}) => {
  const isComponentMounted = useRef(false);
  useEffect(() => {
    isComponentMounted.current = true;
  }, []);

  const mapStyle = useMemo(() => {
    const sectionsStatusArray = Object.entries(sectionsStatus);
    return sectionsStatusArray.map(
      ([status, statusProperties]) => {
        if (!statusProperties.items.length) return null;
        const selector = statusProperties.items
          .map((id: string) => {
            return `.${specificity} path[data-svg-section-id="${id}"]`;
          })
          .join(', ');

        const hoverSelector = statusProperties.items
          .map((id: string) => {
            return `.${specificity} path[data-svg-section-id="${id}"]:hover`;
          })
          .join(', ');
        return (
            <style key={status}>{`
            ${selector} {
              ${Object.entries(statusProperties.style || {})
              .map(([property, value]) => `${property}:${value};`)
              .join('\n')}
              }
            ${hoverSelector} {
                ${Object.entries(statusProperties.hoverStyle || {})
                .map(([property, value]) => `${property}:${value};`)
                .join('\n')}
              }`}
            </style>
        );
      },
    );
  }, [sectionsStatus, specificity]);


  const map = useMemo(() => {
    if (isComponentMounted) {
      return withPanAndZoom ? (
          <SvgPanZoom {...mapProps} className={`${styles.map} ${styles.absolute} ${specificity}`} />
      ) : (
          <SimpleMap {...mapProps} className={`${styles.map} ${specificity}`} />
      );
    }
    return <>Loading...</>;
  }, [mapProps, specificity, withPanAndZoom]);

  return (
        <>
            {mapStyle}
            {mapProps.jsonMap && map}
        </>
  );
};

export default StyledMap;

import { ButtonProps } from '../../atoms/Button';
import { DividerProps } from '../../atoms/Divider';
import { TextProps } from '../../atoms/Text';


type InstructionLabelInternalProps = {
  text?: TextProps;
  divider?: DividerProps;
  button?: ButtonProps;
  divider1?: DividerProps;
};

type InstructionLabelPropsValues = {
  'LargeDefaultSubduedBG': InstructionLabelInternalProps;
  'LargeDefaultWhiteBG': InstructionLabelInternalProps;
  'MediumDefaultWhiteBG': InstructionLabelInternalProps;
  'MediumWithIconWhiteBG': InstructionLabelInternalProps;
};

const propValues: InstructionLabelPropsValues = {
  'LargeDefaultSubduedBG': {
    text: {
      type: 'Subheading',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    divider: {
      type: 'Horizontal',
      style: 'Thin',
      colour: 'DigitalGrey15',
    },
  },
  'LargeDefaultWhiteBG': {
    text: {
      type: 'Subheading',
      size: 'Medium',
      style: 'Light',
      colour: 'SubduedLight',
      align: 'Left',
    },
    divider: {
      type: 'Horizontal',
      style: 'Thin',
      colour: 'DigitalGrey10',
    },
  },
  'MediumDefaultWhiteBG': {
    text: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    divider: {
      type: 'Horizontal',
      style: 'Thin',
      colour: 'DigitalGrey10',
    },
  },
  'MediumWithIconWhiteBG': {
    divider: {
      type: 'Horizontal',
      style: 'Thin',
      colour: 'DigitalGrey10',
    },
    text: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    button: {
      type: 'Icon',
      style: 'Text',
      size: 'Small',
    },
    divider1: {
      type: 'Horizontal',
      style: 'Thin',
      colour: 'DigitalGrey10',
    },
  },
};

const getProps = (type: string): InstructionLabelInternalProps => {
  const values: InstructionLabelInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import { ImageProps } from '../../atoms/Image';
import { AdditionalTicketInformationListProps } from '../../molecules/AdditionalTicketInformationList';
import { CardNavigationProps } from '../../molecules/CardNavigation';
import { TicketInformationProps } from '../../molecules/TicketInformation';
import { TicketQuantitySelectionProps } from '../../molecules/TicketQuantitySelection';


type SportsPrecheckoutInternalProps = {
  cardNavigation?: CardNavigationProps;
  image?: ImageProps;
  ticketInformation?: TicketInformationProps;
  ticketQuantitySelection?: TicketQuantitySelectionProps;
  additionalTicketInformationList?: AdditionalTicketInformationListProps;
};

type SportsPrecheckoutPropsValues = {
  '': SportsPrecheckoutInternalProps;
};

const propValues: SportsPrecheckoutPropsValues = {
  '': {
    cardNavigation: {
      type: 'WithCloseOption',
      style: 'LightBackground',
    },
    image: {
    },
    ticketInformation: {
      type: 'LargeTitle',
      style: 'Default',
    },
    ticketQuantitySelection: {
    },
    additionalTicketInformationList: {
    },
  },
};

const getProps = (type: string): SportsPrecheckoutInternalProps => {
  const values: SportsPrecheckoutInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import { TextProps } from '../../atoms/Text';


type TagInternalProps = {
  label?: TextProps;
};

type TagPropsValues = {
  'InformationRegular': TagInternalProps;
  'InformationSmall': TagInternalProps;
  'NegativeRegular': TagInternalProps;
  'NegativeSmall': TagInternalProps;
  'NeutralSmall': TagInternalProps;
  'PositiveRegular': TagInternalProps;
  'PositiveSmall': TagInternalProps;
  'WarningRegular': TagInternalProps;
  'WarningSmall': TagInternalProps;
};

const propValues: TagPropsValues = {
  'InformationRegular': {
    label: {
      type: 'Body',
      size: 'Small',
      style: 'SemiBold',
      colour: 'Action',
      align: 'Left',
    },
  },
  'InformationSmall': {
    label: {
      type: 'Body',
      size: 'Small',
      style: 'SemiBold',
      colour: 'Action',
      align: 'Left',
    },
  },
  'NegativeRegular': {
    label: {
      type: 'Body',
      size: 'Small',
      style: 'SemiBold',
      colour: 'SubduedDark',
      align: 'Left',
    },
  },
  'NegativeSmall': {
    label: {
      type: 'Body',
      size: 'Small',
      style: 'SemiBold',
      colour: 'Negative',
      align: 'Left',
    },
  },
  'NeutralSmall': {
    label: {
      type: 'Body',
      size: 'Small',
      style: 'SemiBold',
      colour: 'SubduedDark',
      align: 'Left',
    },
  },
  'PositiveRegular': {
    label: {
      type: 'Body',
      size: 'Small',
      style: 'SemiBold',
      colour: 'Positive',
      align: 'Left',
    },
  },
  'PositiveSmall': {
    label: {
      type: 'Body',
      size: 'Small',
      style: 'SemiBold',
      colour: 'Positive',
      align: 'Left',
    },
  },
  'WarningRegular': {
    label: {
      type: 'Body',
      size: 'Small',
      style: 'SemiBold',
      colour: 'SubduedDark',
      align: 'Left',
    },
  },
  'WarningSmall': {
    label: {
      type: 'Body',
      size: 'Small',
      style: 'SemiBold',
      colour: 'SubduedDark',
      align: 'Left',
    },
  },
};

const getProps = (type: string): TagInternalProps => {
  const values: TagInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

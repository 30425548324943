import { SubmenuItemProps } from '../SubmenuItem';


type SubmenuItemListInternalProps = {
  submenuItem?: SubmenuItemProps;
};

type SubmenuItemListPropsValues = {
  '': SubmenuItemListInternalProps;
};

const propValues: SubmenuItemListPropsValues = {
  '': {
    submenuItem: {
    },
  },
};

const getProps = (type: string): SubmenuItemListInternalProps => {
  const values: SubmenuItemListInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import React from 'react';

import { LocaleProvider } from './modules/locale';
import { AuthProvider } from './modules/auth';
import { QueryClient, QueryClientProvider } from 'react-query';
import { RegionProvider } from './modules/region';
import { UserIdleProvider } from './modules/userIdle';
import { TopNavProvider } from './modules/topNav';
import { Props } from './lib/types';

const queryClient = new QueryClient();

const AppProvider: React.FC<Props> = ({ children }) => (
  <LocaleProvider>
    <QueryClientProvider client={queryClient}>
      <UserIdleProvider>
        <AuthProvider>
          <RegionProvider>
              <TopNavProvider>
                {children}
              </TopNavProvider>            
          </RegionProvider>
        </AuthProvider>
      </UserIdleProvider>
    </QueryClientProvider>
  </LocaleProvider>
);

export default AppProvider;

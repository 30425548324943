import { TextProps } from '../../atoms/Text';
import { EventDateStatusProps } from '../EventDateStatus';


type InfoDetailsInternalProps = {
  title?: TextProps;
  eventDateStatus?: EventDateStatusProps;
  venue?: TextProps;
};

type InfoDetailsPropsValues = {
  '': InfoDetailsInternalProps;
};

const propValues: InfoDetailsPropsValues = {
  '': {
    title: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    eventDateStatus: {
      type: 'Default',
    },
    venue: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
  },
};

const getProps = (type: string): InfoDetailsInternalProps => {
  const values: InfoDetailsInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

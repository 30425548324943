import React from 'react';
import cx from 'classnames';

import ToggleItemList from '../../organisms/ToggleItemList';

import styles from './ToggleBlock.module.scss';
import usePresenter from './ToggleBlock.presenter';
import getProps from './ToggleBlock.props';
import { ToggleBlockCombinedProps } from './types';


const ToggleBlock: React.FC<ToggleBlockCombinedProps> = (props) => {
  const {
    className,
    classes,
    toggleItemList,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.toggleBlock;

  return (
    <div className={cx(currentStyle, className)}>
      <ToggleItemList
        className={cx(styles.toggleItemList, classes?.toggleItemList)}
        {...internalProps.toggleItemList}
        {...toggleItemList}/>
    </div>
  );
};

export default ToggleBlock;


import { useHistory } from 'react-router-dom';
import { SubmenuItemCombinedProps } from './types';

const usePresenter = (props: SubmenuItemCombinedProps): SubmenuItemCombinedProps => {
  const { linkPath, onItemClicked } = props;
  const history = useHistory();
  const clickAction = () => {
    if (linkPath) {
      history.push(linkPath);
    }
    onItemClicked?.();
  };
  return {
    ...props,
    onItemClicked: clickAction,
  };
};

export default usePresenter;

import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import Divider from '../../atoms/Divider';
import Text from '../../atoms/Text';

import styles from './TicketQuantity.module.scss';
import usePresenter from './TicketQuantity.presenter';
import getProps from './TicketQuantity.props';
import { TicketQuantityCombinedProps } from './types';


const TicketQuantity: React.FC<TicketQuantityCombinedProps> = (props) => {
  const {
    className,
    classes,
    divider,
    label,
    minusButton, // MANUAL OVERRIDE
    label1,
    plusButton, // MANUAL OVERRIDE
    divider1,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.ticketQuantity;

  return (
    <div className={cx(currentStyle, className)}>
      <Divider
        className={cx(styles.divider, classes?.divider)}
        {...internalProps.divider}
        {...divider}/>
      <div className={cx(styles.content, classes?.content)}>
        <Text
          className={cx(styles.label, classes?.label)}
          {...internalProps.label}
          {...label}/>
        <div className={cx(styles.quantity, classes?.quantity)}>
          {/* MANUAL OVERRIDE */}
          <Button
            className={cx(styles.minusButton, classes?.minusButton)}
            {...internalProps.minusButton}
            {...minusButton}/>
          <Text
            className={cx(styles.label1, classes?.label1)}
            {...internalProps.label1}
            {...label1}/>
          {/* MANUAL OVERRIDE */}
          <Button
            className={cx(styles.plusButton, classes?.plusButton)}
            {...internalProps.plusButton}
            {...plusButton}/>
        </div>
      </div>
      <Divider
        className={cx(styles.divider1, classes?.divider1)}
        {...internalProps.divider1}
        {...divider1}/>
    </div>
  );
};

export default TicketQuantity;


import { ContactUsItemProps } from '../../molecules/ContactUsItem';


type ContactUsInternalProps = {
  contactUsItem?: ContactUsItemProps;
  contactUsItem1?: ContactUsItemProps;
  contactUsItem2?: ContactUsItemProps;
};

type ContactUsPropsValues = {
  '': ContactUsInternalProps;
};

const propValues: ContactUsPropsValues = {
  '': {
    contactUsItem: {
    },
    contactUsItem1: {
    },
    contactUsItem2: {
    },
  },
};

const getProps = (type: string): ContactUsInternalProps => {
  const values: ContactUsInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import React from 'react';
import cx from 'classnames';

import Text from '../../atoms/Text';
import Button from '../../atoms/Button';
import Divider from '../../atoms/Divider';
import EventStatusTag from '../../atoms/EventStatusTag';
import EventTypeTag from '../../atoms/EventTypeTag';
import Icon from '../../atoms/Icon';

import styles from './EventCardNoImage.module.scss';
import usePresenter from './EventCardNoImage.presenter';
import getProps from './EventCardNoImage.props';
import { EventCardNoImageCombinedProps } from './types';


const EventCardNoImage: React.FC<EventCardNoImageCombinedProps> = (props) => {
  const {
    className,
    classes,
    disabled,
    buttonType,
    buttonForm,
    href,
    target,
    externalLink,
    onClick,
    eventTypeTag,
    eventStatusTag,
    titleText,
    dateAndTimeText,
    locationAndPriceText,
    divider,
    actionText,
    icon,
    dateText,
    text,
    button,
    // MANUAL OVERRIDE STARTS
    ariaLabel,
    displayEventTypeTag,
    displayEventStatusTag,
    dividerTwo,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const variantName = '';
  const internalProps = getProps(variantName);
  const currentStyle = styles[`eventCardNoImage${variantName}`];


  // let componentView; // MANUAL OVERRIDE

  const eventTypeTagView = displayEventTypeTag ? (
    <div className={cx(styles.tag, classes?.tag)}>
      <EventTypeTag
        className={cx(styles.eventTypeTag, classes?.eventTypeTag)}
        {...internalProps.eventTypeTag}
        {...eventTypeTag} />
    </div>
  ) : undefined;

  const eventStatusTagView = displayEventStatusTag ? (
    <div className={cx(styles.status, classes?.status)}>
      <EventStatusTag
        className={cx(styles.eventStatusTag, classes?.eventStatusTag)}
        {...internalProps.eventStatusTag}
        {...eventStatusTag} />
    </div>
  ) : undefined;

  const mobileView = (
    <button
      disabled={disabled}
      type={buttonType}
      form={buttonForm}
      onClick={onClick}
      aria-label={ariaLabel}
      // MANUAL OVERRIDE
      className={cx(currentStyle, styles.eventCardNoImageMobile)}>
      <div className={cx(styles.bottomContent, classes?.bottomContent)}>
        <div className={cx(styles.content, classes?.content)}>
          {/* MANUAL OVERRIDE */}
          {eventTypeTagView}
          {/* MANUAL OVERRIDE */}
          {eventStatusTagView}
          <Text
            className={cx(styles.titleText, classes?.titleText)}
            {...internalProps.titleText}
            {...titleText} />
          <div className={cx(styles.eventLogistics, classes?.eventLogistics)}>
            <div className={cx(styles.dateAndTime, classes?.dateAndTime)}>
              <Text
                className={cx(styles.dateAndTimeText, classes?.dateAndTimeText)}
                {...internalProps.dateAndTimeText}
                {...dateAndTimeText} />
            </div>
            <div className={cx(styles.locationAndPrice, classes?.locationAndPrice)}>
              <Text
                className={cx(styles.locationAndPriceText, classes?.locationAndPriceText)}
                {...internalProps.locationAndPriceText}
                {...locationAndPriceText} />
            </div>
          </div>
        </div>
        <div className={cx(styles.actionContent, classes?.actionContent)}>
          <Divider
            className={cx(styles.divider, classes?.divider)}
            {...internalProps.divider}
            {...divider} />
          <div className={cx(styles.textIcon, classes?.textIcon)}>
            <Text
              className={cx(styles.actionText, classes?.actionText)}
              {...internalProps.actionText}
              {...actionText} />
            <Icon
              className={cx(styles.icon, classes?.icon)}
              {...internalProps.icon}
              {...icon} />
          </div>
        </div>
      </div>
    </button>
  );

  const desktopView = (
    <button
      disabled={disabled}
      type={buttonType}
      form={buttonForm}
      onClick={onClick}
      aria-label={ariaLabel}
      // MANUAL OVERRIDE
      className={cx(currentStyle, styles.eventCardNoImageDesktop)}>
      <div className={cx(styles.leftContent, classes?.leftContent)}>
        {/* MANUAL OVERRIDE STYLE CONFLICT */}
        <div className={cx(styles.dateContent, classes?.dateContent)}>
          <Text
            className={cx(styles.dateText, classes?.dateText)}
            {...internalProps.dateText}
            {...dateText} />
          {/* MANUAL OVERRIDE - to show weekday and time only in desktop version */}
          <div className={cx(styles.weekDayAndTime, classes?.weekDayAndTime)}>
            <Text
              className={cx(styles.text, classes?.text)}
              {...internalProps.text}
              {...text} />
          </div>
        </div>
      </div>
      <Divider
        className={cx(styles.dividerTwo, classes?.dividerTwo)}
        {...internalProps.divider}
        {...divider} />
      <div className={cx(styles.bottomContent, classes?.bottomContent)}>
        <div className={cx(styles.content1, classes?.content1)}>
          {/* MANUAL OVERRIDE */}
          {eventTypeTagView}
          {/* MANUAL OVERRIDE */}
          {eventStatusTagView}
          <Text
            className={cx(styles.titleText, classes?.titleText)}
            {...internalProps.titleText}
            {...titleText} />
          <div className={cx(styles.eventLogistics, classes?.eventLogistics)}>
            {/* MANUAL OVERRIDE - to show weekday and time only in desktop version */}
            {/* <div className={cx(styles.dateAndTime, classes?.dateAndTime)}>
              <Text
                className={cx(styles.dateAndTimeText, classes?.dateAndTimeText)}
                {...internalProps.dateAndTimeText}
                {...dateAndTimeText}/>
            </div> */}
            <div className={cx(styles.locationAndPrice, classes?.locationAndPrice)}>
              <Text
                className={cx(styles.locationAndPriceText, classes?.locationAndPriceText)}
                {...internalProps.locationAndPriceText}
                {...locationAndPriceText} />
            </div>
          </div>
        </div>
      </div>
      <Button
        className={cx(styles.button, classes?.button)}
        {...internalProps.button}
        {...button} />
    </button>
  );

  return (
    <div
      data-item="true"
      className={cx(styles.eventCardNoImage, className)}
    >
      {mobileView}
      {desktopView}
    </div>);
};

export default EventCardNoImage;


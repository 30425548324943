import React from 'react';
import cx from 'classnames';

import Text from '../../atoms/Text';
import Slider from '../Slider';

import styles from './SliderFilter.module.scss';
import usePresenter from './SliderFilter.presenter';
import getProps from './SliderFilter.props';
import { SliderFilterCombinedProps } from './types';


const SliderFilter: React.FC<SliderFilterCombinedProps> = (props) => {
  const {
    className,
    classes,
    leftValue,
    slider,
    rightValue,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.sliderFilter;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.value, classes?.value)}>
        <Text
          className={cx(styles.leftValue, classes?.leftValue)}
          {...internalProps.leftValue}
          {...leftValue}/>
        <Slider
          className={cx(styles.slider, classes?.slider)}
          {...internalProps.slider}
          {...slider}/>
        <Text
          className={cx(styles.rightValue, classes?.rightValue)}
          {...internalProps.rightValue}
          {...rightValue}/>
      </div>
      {/* MANUAL OVERRIDE - removed second slider */}
    </div>
  );
};

export default SliderFilter;


import { ImageProps } from '../../atoms/Image';
import { TextProps } from '../../atoms/Text';


type LandingPageBannerInternalProps = {
  image?: ImageProps;
  text?: TextProps;
};

type LandingPageBannerPropsValues = {
  '': LandingPageBannerInternalProps;
};

const propValues: LandingPageBannerPropsValues = {
  '': {
    image: {
    },
    text: {
      type: 'BannerHeading',
      size: 'Small',
      style: 'SemiBold',
      colour: 'ActionBase',
      align: 'Left',
    },
  },
};

const getProps = (type: string): LandingPageBannerInternalProps => {
  const values: LandingPageBannerInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import { TextProps } from '../Text';


type ToggleItemInternalProps = {
  label?: TextProps;
};

type ToggleItemPropsValues = {
  'Default': ToggleItemInternalProps;
  'Selected': ToggleItemInternalProps;
};

const propValues: ToggleItemPropsValues = {
  'Default': {
    label: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'ActionBase',
      align: 'Left',
    },
  },
  'Selected': {
    label: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'BaseLight',
      align: 'Left',
    },
  },
};

const getProps = (type: string): ToggleItemInternalProps => {
  const values: ToggleItemInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import cx from 'classnames';
import React, { type ReactNode } from 'react';
import TextItem from '../TextItem';
import styles from './SeatInfo.module.scss';
import usePresenter from './SeatInfo.presenter';
import getProps from './SeatInfo.props';
import type { SeatInfoCombinedProps } from './types';

const SeatInfo: React.FC<SeatInfoCombinedProps> = (props) => {
  const {
    className,
    classes,
    section,
    row,
    quantity,
    seats,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.seatInfo;

  const seatsView: ReactNode = !!seats && (
    <TextItem
      className={cx(styles.seats, classes?.seats)}
      style='SubduedLabel'
      {...seats}
    />
  );

  return (
    <div className={cx(currentStyle, className)}>
      <TextItem
        className={cx(styles.section, classes?.section)}
        {...internalProps.section}
        {...section} />
      <TextItem
        className={cx(styles.row, classes?.row)}
        {...internalProps.row}
        {...row} />
      <TextItem
        className={cx(styles.quantity, classes?.quantity)}
        {...internalProps.quantity}
        {...quantity} />
      {seatsView}
    </div>
  );
};

export default SeatInfo;

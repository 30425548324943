import React from 'react';
import cx from 'classnames';

import Icon from '../../atoms/Icon';
import Text from '../../atoms/Text';

import styles from './EventsEmptyState.module.scss';
import usePresenter from './EventsEmptyState.presenter';
import getProps from './EventsEmptyState.props';
import { EventsEmptyStateCombinedProps } from './types';


const EventsEmptyState: React.FC<EventsEmptyStateCombinedProps> = (props) => {
  const {
    style,
    className,
    classes,
    icon,
    title,
    message,
  } = usePresenter(props);

  const variantName = `${style}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`eventsEmptyState${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Dark':
    case 'Light':
    case 'LightPerformer': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.content, classes?.content)}>
            <Icon
              className={cx(styles.icon, classes?.icon)}
              {...internalProps.icon}
              {...icon}/>
            <div className={cx(styles.mainMessage, classes?.mainMessage)}>
              <Text
                className={cx(styles.title, classes?.title)}
                {...internalProps.title}
                {...title}/>
              <Text
                className={cx(styles.message, classes?.message)}
                {...internalProps.message}
                {...message}/>
            </div>
          </div>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default EventsEmptyState;


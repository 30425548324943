import { ImageProps } from '../../atoms/Image';
import { SliderButtonProps } from '../../atoms/SliderButton';
import { ImageListProps } from '../../organisms/ImageList';


type C1XEventDetailsSliderBlockInternalProps = {
  image?: ImageProps;
  image1?: ImageProps;
  // MANUAL OVERRIDE STARTS
  imageList?: ImageListProps;
  slideBackwardButton?: SliderButtonProps;
  slideForwardButton?: SliderButtonProps;
  // MANUAL OVERRIDE ENDS
};

type C1XEventDetailsSliderBlockPropsValues = {
  '': C1XEventDetailsSliderBlockInternalProps;
};

const propValues: C1XEventDetailsSliderBlockPropsValues = {
  '': {
    image: {
    },
    image1: {
    },
    // MANUAL OVERRIDE STARTS
    imageList: {
    },
    slideBackwardButton: {
      style: 'Dark',
      type: 'Prev',
    },
    slideForwardButton: {
      style: 'Dark',
      type: 'Next',
    },
    // MANUAL OVERRIDE ENDS
  },
};

const getProps = (type: string): C1XEventDetailsSliderBlockInternalProps => {
  const values: C1XEventDetailsSliderBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import React, { useContext } from 'react';
import categoryEvents from '../../../modules/navigation/CategoryEvents.json';
import { TopNavTab } from '../../../modules/navigation/types';
import { useLocation } from 'react-router-dom';
import { getPerformer, getPerformers } from '../../../modules/partnership/api';
import { useMutation } from 'react-query';
import { RegionContext } from '../../../modules/region';
import { SportTeamsNearLocationBlockCombinedProps, SportTeamsNearLocationBlockPresenterProps } from './types';

const useInteractor = (props: SportTeamsNearLocationBlockCombinedProps): SportTeamsNearLocationBlockPresenterProps => {
  const data: TopNavTab[] = JSON.parse(JSON.stringify(categoryEvents));
  const { pathname } = useLocation();
  const sportsObject = data.find((category) => category.id === 'sports');
  const sportsLeague = sportsObject?.children.find((league) => league.slug === pathname)?.sub_genre_id;
  const performerList = useMutation(getPerformers);
  const performer = useMutation(getPerformer);
  const { currentRegion, allRegions } = useContext(RegionContext);

  return {
    ...props,
    sportsLeague: sportsLeague,
    fetchTeams: performerList.mutateAsync,
    getPerformer: performer.mutateAsync,
    currentRegion: currentRegion,
    locationList: allRegions,
  };

};
export default useInteractor;

import { TextProps } from '../../atoms/Text';


type StepperItemInternalProps = {
  stepNumber?: TextProps;
  stepLabel?: TextProps;
};

type StepperItemPropsValues = {
  'SelectedIncomplete': StepperItemInternalProps;
  'UnselectedComplete': StepperItemInternalProps;
  'UnselectedIncomplete': StepperItemInternalProps;
};

const propValues: StepperItemPropsValues = {
  'SelectedIncomplete': {
    stepNumber: {
      type: 'Body',
      size: 'ExtraSmall',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Center',
    },
    stepLabel: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Center',
    },
  },
  'UnselectedComplete': {
    stepNumber: {
      type: 'Body',
      size: 'ExtraSmall',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Center',
    },
    stepLabel: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Center',
    },
  },
  'UnselectedIncomplete': {
    stepNumber: {
      type: 'Body',
      size: 'ExtraSmall',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Center',
    },
    stepLabel: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Center',
    },
  },
};

const getProps = (type: string): StepperItemInternalProps => {
  const values: StepperItemInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

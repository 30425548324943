import { TabProps } from '../../atoms/Tab';


type TabListInternalProps = {
  tab?: TabProps;
};

type TabListPropsValues = {
  '': TabListInternalProps;
};

const propValues: TabListPropsValues = {
  '': {
    tab: {
      style: 'Rounded',
      colour: 'Dark',
    },
  },
};

const getProps = (type: string): TabListInternalProps => {
  const values: TabListInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

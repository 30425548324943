import { ImageProps } from '../../atoms/Image';
import { ButtonCombinedProps } from '../../atoms/Button';
import { TextProps } from '../../atoms/Text';


type EventDetailsModalInternalProps = {
  button?: ButtonCombinedProps; // MANUAL OVERRIDE
  bannerImage?: ImageProps;
  title?: TextProps;
  eventInfo?: TextProps;
  title1?: TextProps;
  description1?: TextProps;
  description?: TextProps;
};

type EventDetailsModalPropsValues = {
  '': EventDetailsModalInternalProps;
};

const propValues: EventDetailsModalPropsValues = {
  '': {
    button: {
      type: 'Icon',
      style: 'Text',
      size: 'Small',
      // MANUAL OVERRIDE STARTS
      icon: {
        asset: 'Close',
        style: 'DigitalGrey80',
      },
      // MANUAL OVERRIDE ENDS
    },
    bannerImage: {
    },
    title: {
      type: 'Subheading',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    eventInfo: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    title1: {
      type: 'Subheading',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    description1: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
    description: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
  },
};

const getProps = (type: string): EventDetailsModalInternalProps => {
  const values: EventDetailsModalInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import { TagProps } from '../Tag';


type ExclusiveEventBannerInternalProps = {
  tag?: TagProps;
};

type ExclusiveEventBannerPropsValues = {
  '': ExclusiveEventBannerInternalProps;
};

const propValues: ExclusiveEventBannerPropsValues = {
  '': {
    tag: {
      type: 'Information',
      size: 'Regular',
    },
  },
};

const getProps = (type: string): ExclusiveEventBannerInternalProps => {
  const values: ExclusiveEventBannerInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

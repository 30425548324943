import cx from 'classnames';

import customStyles from './Custom.module.scss';
import { CardNavigationCombinedProps } from './types';

const usePresenter = (props: CardNavigationCombinedProps): CardNavigationCombinedProps => {
  const { className, classes, type, style, button } = props;
  const isVariantDefaultLightBG = type === 'Default' && style === 'LightBackground';
  return {
    ...props,
    className: cx(className, isVariantDefaultLightBG ? customStyles.customCardNavigation : ''),
    classes: {
      button: cx(classes?.button, isVariantDefaultLightBG ? customStyles.customButton : ''),
    },
    button: {
      ...button,
      classes: {
        icon: cx(button?.classes?.icon, isVariantDefaultLightBG ? customStyles.customButtonIcon : ''),
      },
    },
  };
};

export default usePresenter;

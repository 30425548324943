import { TextProps } from '../../atoms/Text';


type InfoOnMultipleLineInternalProps = {
  label?: TextProps;
  buyerName?: TextProps;
  address?: TextProps;
  cityProvinceCountry?: TextProps;
  postalCode?: TextProps;
};

type InfoOnMultipleLinePropsValues = {
  '': InfoOnMultipleLineInternalProps;
};

const propValues: InfoOnMultipleLinePropsValues = {
  '': {
    label: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    buyerName: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    address: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    cityProvinceCountry: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    postalCode: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
  },
};

const getProps = (type: string): InfoOnMultipleLineInternalProps => {
  const values: InfoOnMultipleLineInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

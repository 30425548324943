import { PURCHASE_LIMIT_TAG } from '../../../lib/constants';
import { UserOrder } from '../../../modules/partnership';
import { AdditionalTicketInformationListCombinedProps } from '../../molecules/AdditionalTicketInformationList/types';
import { TicketInformationCombinedProps } from '../../molecules/TicketInformation/types';
import { TicketQuantitySelectionCombinedProps } from '../../molecules/TicketQuantitySelection/types';
import { TicketSelectionInformationCombinedProps } from '../../molecules/TicketSelectionInformation/types';

export type TicketProps = {
  ticketInformation?: TicketInformationCombinedProps;
  ticketQuantitySelection?: TicketQuantitySelectionCombinedProps;
  additionalTicketInformationList?: AdditionalTicketInformationListCombinedProps;
  ticketSelectionInformation?: TicketSelectionInformationCombinedProps;
};

export function getEventPurchaseLimit(tags: string[]): number {
  const purchaseLimitTag = tags.find((tag) => tag.startsWith(PURCHASE_LIMIT_TAG)) || '';
  const purchaseLimitTagDetails = purchaseLimitTag.split(':');

  if (purchaseLimitTagDetails.length) {
    return Number(purchaseLimitTagDetails[1]);
  }

  return NaN;
}

export function findPreviousPurchases(previousOrders: UserOrder[], eventId: string): number {
  let previousTickets = 0;
  previousOrders.forEach((order) => {
    if (order.event_id === Number(eventId)) {
      previousTickets += order.quantity;
    }
  });
  return previousTickets;
}

export function formatPoints(pointsValue: number, isMiles: boolean): string {
  return isMiles ? pointsValue.toLocaleString() : `$${Number(pointsValue.toFixed(2)).toLocaleString()}`;
}

export function determineDeliveryOption(deliveryId: number): number {
  switch (deliveryId) {
    case (19):
      return 20;
    case (31):
      return 32;
    default: 
      return deliveryId;
  }
}

import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import Image from '../../atoms/Image';
import Text from '../../atoms/Text';
import SearchResult from '../../molecules/SearchResult';
import EventsCardList from '../../organisms/EventsCardList';

import styles from './SearchMatchesBlock.module.scss';
import usePresenter from './SearchMatchesBlock.presenter';
import getProps from './SearchMatchesBlock.props';
import { SearchMatchesBlockCombinedProps } from './types';
import Spinner from '../../atoms/Spinner';


const SearchMatchesBlock: React.FC<SearchMatchesBlockCombinedProps> = (props) => {
  const {
    state,
    className,
    classes,
    searchResult,
    eventsCardList,
    seeMoreButton,
    image,
    label,
    searchedValue,
    message,
    button,
  } = usePresenter(props);

  const variantName = `${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`searchMatchesBlock${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Match': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <SearchResult
            className={cx(styles.searchResult, classes?.searchResult)}
            {...internalProps.searchResult}
            {...searchResult} />
          <EventsCardList
            className={cx(styles.eventsCardList, classes?.eventsCardList)}
            {...internalProps.eventsCardList}
            {...eventsCardList} />
          <Button
            className={cx(styles.seeMoreButton, classes?.seeMoreButton)}
            {...internalProps.seeMoreButton}
            {...seeMoreButton} />
        </div>
      );
      break;
    }
    case 'NoMatch': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.container, classes?.container)}>
            <Image
              className={cx(styles.image, classes?.image)}
              {...internalProps.image}
              {...image} />
            <div className={cx(styles.content, classes?.content)}>
              <div className={cx(styles.searchResult, classes?.searchResult)}>
                <Text
                  className={cx(styles.label, classes?.label)}
                  {...internalProps.label}
                  {...label} />
                <Text
                  className={cx(styles.searchedValue, classes?.searchedValue)}
                  {...internalProps.searchedValue}
                  {...searchedValue} />
              </div>
              <Text
                className={cx(styles.message, classes?.message)}
                {...internalProps.message}
                {...message} />
            </div>
            <Button
              className={cx(styles.button, classes?.button)}
              {...internalProps.button}
              {...button} />
          </div>
        </div>
      );
      break;
    }
    // MANUAL OVERRIDE Starts
    case 'Loading': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Spinner />
        </div>);
      break;
    }
    // MANUAL OVERRIDE Ends
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default SearchMatchesBlock;


import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import styles from './OnBoardingModal.module.scss';
import OnBoardingContent from '../../molecules/OnBoardingContent';
import { useAnalyticsManager } from '../../../modules/analytics/useAnalyticsManager';
import { trackPageViewEvent } from '../../../modules/analytics/util';

export const defaultProps = {
  show: false,
  handleClose: (): void => {},
};

export type OnBoardingModalProps = {
  handleClose?: () => void;
  show?: boolean;
};

const OnBoardingModal: React.FC<OnBoardingModalProps> = ({
  handleClose,
  show,
}) => {
  const { trackEvent } = useAnalyticsManager();

  useEffect(() => {
    if (show) {
      trackPageViewEvent(
        trackEvent,
        'Onboarding Screen',
      );
    }
  }, [show]);
  
  return (
    <Modal
      className={styles.modal}
      dialogClassName={styles.modalDialog}
      contentClassName={styles.modalContent}
      show={show}
      autoFocus={false}
    >
      <OnBoardingContent handleClose={handleClose} />
    </Modal>
  );
};

OnBoardingModal.defaultProps = defaultProps;

export default OnBoardingModal;

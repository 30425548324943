import React from 'react';
import cx from 'classnames';

import IconTextDescription from '../IconTextDescription';

import styles from './AdditionalTicketInformationList.module.scss';
import usePresenter from './AdditionalTicketInformationList.presenter';
import getProps from './AdditionalTicketInformationList.props';
import { AdditionalTicketInformationListCombinedProps } from './types';


const AdditionalTicketInformationList: React.FC<AdditionalTicketInformationListCombinedProps> = (props) => {
  const {
    className,
    classes,
    iconTextDescriptions,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.additionalTicketInformationList;

  const iconTextDescriptionViews = iconTextDescriptions?.map((iconTextDescription, index) => (
    <IconTextDescription
      key={index}
      className={cx(styles.iconTextDescription, classes?.iconTextDescription)}
      {...internalProps.iconTextDescription}
      {...iconTextDescription} />
  )) || [];

  return (
    <div className={cx(currentStyle, className)}>
      {iconTextDescriptionViews}
    </div>
  );
};

export default AdditionalTicketInformationList;


import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { TERMS_MODAL } from '../../../lib/constants';
import { addQueryParam } from '../../../lib/util';

import { ButtonCombinedProps } from '../../atoms/Button';

import { FooterCombinedProps } from './types';
import { PRIVACY_URL } from '../../../lib/config';

const usePresenter = (props: FooterCombinedProps): FooterCombinedProps => {
  const { t } = useTranslation();
  const history = useHistory();
  const { pathname } = useLocation();
  const showDarkFooter = pathname.startsWith('/exclusive-events') || pathname.startsWith('/events');
  const renderModal = (modalType: string) => {
    const params = addQueryParam(history.location.search, {
      'modal': modalType,
    });
    
    if (params) {
      history.push({
        search: params,
      });
    }
  };

  const buttons: ButtonCombinedProps[] = [];
  buttons.push({
    text: { 
      value: t('footer.help'),
      colour: showDarkFooter ? 'BaseLight' : 'ActionBase',
    },
    type: 'Text',
    style: 'TextWhite',
    size: 'Small',
    href: '/help',
  });
  buttons.push({
    text: {
      value: t('footer.legal'),
      colour: showDarkFooter ? 'BaseLight' : 'ActionBase',
    },
    type: 'Text',
    style: 'TextWhite',
    size: 'Small',
    onClick: () => { renderModal(TERMS_MODAL); },
  });
  buttons.push({
    text: {
      value: t('footer.privacy'),
      colour: showDarkFooter ? 'BaseLight' : 'ActionBase',
    },
    type: 'Text',
    style: 'TextWhite',
    size: 'Small',
    href: PRIVACY_URL,
    target: '_blank',
  });

  return {
    ...props,
    footerMenuList: {
      buttons,
    },
    logo: {
      asset: showDarkFooter ? 'LogoC1Dark' : 'LogoC1Default',
      assetAlt: t('topnav.capitalOneLogoScreenReaderText'),
    },
    style: showDarkFooter ? 'Dark' : 'Default',
  };
};

export default usePresenter;

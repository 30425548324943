import React from 'react';
import cx from 'classnames';

import Image from '../../atoms/Image';
import Button from '../../atoms/Button';
import SliderInfo from '../../atoms/SliderInfo';
import SliderButton from '../../atoms/SliderButton';
import Spinner from '../../atoms/Spinner';
import EventsEmptyState from '../../molecules/EventsEmptyState';
import BlockHeader from '../../organisms/BlockHeader';
import CardSpecificEventsList from '../../organisms/CardSpecificEventsList';
import EventCardDarkList from '../../organisms/EventCardDarkList';
import EventCardLightList from '../../organisms/EventCardLightList';
import EventCardNoImageList from '../../organisms/EventCardNoImageList';
import EventCardVenueList from '../../organisms/EventCardVenueList';
import PerformerCardList from '../../organisms/PerformerCardList';

import customStyles from './Custom.module.scss';
import styles from './EventCardsBlock.module.scss';
import usePresenter from './EventCardsBlock.presenter';
import getProps from './EventCardsBlock.props';
import { EventCardsBlockCombinedProps } from './types';


const EventCardsBlock: React.FC<EventCardsBlockCombinedProps> = (props) => {
  const {
    type,
    state,
    className,
    classes,
    blockHeader,
    cardSpecificEventsList,
    sliderInfo,
    image,
    eventCardDarkList,
    eventsEmptyState,
    eventCardLightList,
    eventCardNoImageList,
    showMoreButton,
    eventCardVenueList,
    performerCardList,
    // MANUAL OVERRIDE STARTS
    shouldDisplay,
    slideBackwardButton,
    slideForwardButton,
    isSnapEnabled,
    hasMorePages,
    spinner,
    setRef,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const variantName = `${type}${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`eventCardsBlock${variantName}`];

  // MANUAL OVERRIDE STARTS
  if (!shouldDisplay) {
    return null;
  }

  const showMoreButtonView = hasMorePages ? (
    <Button
      className={cx(styles.showMoreButton, classes?.showMoreButton)}
      {...internalProps.showMoreButton}
      {...showMoreButton}/>
  ) : null;

  let blockHeaderView;
  let containerView;
  let containerViewWrapper;
  let sliderInfoView;
  let componentView;

  switch (variantName) {
    case 'CardSpecificEventsDarkBlockDefault': {
      blockHeaderView = (
        <BlockHeader
          className={cx(styles.blockHeader, classes?.blockHeader)}
          {...internalProps.blockHeader}
          {...blockHeader}/>
      );
      containerView = (
        <div className={cx(styles.container, classes?.container)}>
          <CardSpecificEventsList
            className={cx(styles.cardSpecificEventsList, classes?.cardSpecificEventsList)}
            {...internalProps.cardSpecificEventsList}
            {...cardSpecificEventsList}/>
        </div>
      );
      break;
    }
    case 'CardSpecificEventsDarkBlockLoading':
    case 'EventsDarkBlockLoading':
    case 'EventsLightBlockLoading':
    case 'PerformersLightBlockLoading': {
      containerView = (
        <div className={cx(currentStyle, className)}>
          <BlockHeader
            className={cx(styles.blockHeader, classes?.blockHeader)}
            {...internalProps.blockHeader}
            {...blockHeader}/>
          <div className={cx(styles.container, classes?.container)}>
            {/* MANUAL OVERRIDE */}
            <Spinner {...spinner}/>
          </div>
        </div>
      );
      break;
    }
    case 'EventsDarkBlockDefault': {
      blockHeaderView = (
        <BlockHeader
          className={cx(styles.blockHeader, classes?.blockHeader)}
          {...internalProps.blockHeader}
          {...blockHeader}/>
      );
      containerView = (
        <div className={cx(styles.container, classes?.container)}>
          <EventCardDarkList
            className={cx(styles.eventCardDarkList, classes?.eventCardDarkList)}
            {...internalProps.eventCardDarkList}
            {...eventCardDarkList}/>
        </div>
      );
      break;
    }
    case 'EventsDarkBlockEmpty':
    case 'EventsLightBlockEmpty':
    case 'PerformersLightBlockEmpty': {
      blockHeaderView = (
        <BlockHeader
          className={cx(styles.blockHeader, classes?.blockHeader)}
          {...internalProps.blockHeader}
          {...blockHeader}/>
      );
      containerView = (
        <div className={cx(styles.container, classes?.container)}>
          <EventsEmptyState
            className={cx(styles.eventsEmptyState, classes?.eventsEmptyState)}
            {...internalProps.eventsEmptyState}
            {...eventsEmptyState}/>
        </div>
      );
      break;
    }
    case 'EventsLightBlockDefault': {
      blockHeaderView = (
        <BlockHeader
          className={cx(styles.blockHeader, classes?.blockHeader)}
          {...internalProps.blockHeader}
          {...blockHeader}/>
      );
      containerView = (
        <div className={cx(styles.container, classes?.container)}>
          <EventCardLightList
            className={cx(styles.eventCardLightList, classes?.eventCardLightList)}
            {...internalProps.eventCardLightList}
            {...eventCardLightList}/>
        </div>
      );
      break;
    }
    case 'EventsNoImageBlockDefault': {
      containerView = (
        <div className={cx(styles.container, classes?.container)}>
          <EventCardNoImageList
            className={cx(styles.eventCardNoImageList, classes?.eventCardNoImageList)}
            {...internalProps.eventCardNoImageList}
            {...eventCardNoImageList}/>
          {/* MANUAL OVERRIDE */}
          {showMoreButtonView}
        </div>
      );
      break;
    }
    case 'EventsNoImageBlockEmpty':
    case 'EventsVenueBlockEmpty': {
      containerView = (
        <div className={cx(styles.container, classes?.container)}>
          <EventsEmptyState
            className={cx(styles.eventsEmptyState, classes?.eventsEmptyState)}
            {...internalProps.eventsEmptyState}
            {...eventsEmptyState}/>
        </div>
      );
      break;
    }
    case 'EventsNoImageBlockLoading':
    case 'EventsVenueBlockLoading': {
      containerView = (
        <div className={cx(styles.container, classes?.container)}>
          {/* MANUAL OVERRIDE */}
          <Spinner {...spinner}/>
        </div>
      );
      break;
    }
    case 'EventsVenueBlockDefault': {
      containerView = (
        <div className={cx(styles.container, classes?.container)}>
          <EventCardVenueList
            className={cx(styles.eventCardVenueList, classes?.eventCardVenueList)}
            {...internalProps.eventCardVenueList}
            {...eventCardVenueList}/>
          {/* MANUAL OVERRIDE */}
          {showMoreButtonView}
        </div>
      );
      break;
    }
    case 'PerformersLightBlockDefault': {
      blockHeaderView = (
        <BlockHeader
            className={cx(styles.blockHeader, classes?.blockHeader)}
            {...internalProps.blockHeader}
            {...blockHeader}/>
      );
      containerView = (
        <div className={cx(styles.container, classes?.container)}>
          <PerformerCardList
            className={cx(styles.performerCardList, classes?.performerCardList)}
            {...internalProps.performerCardList}
            {...performerCardList}/>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  if (isSnapEnabled && state !== 'Loading') {
    containerViewWrapper = (
      <div className={classes?.containerWrapper}>
        {containerView}
        {/* MANUAL OVERRIDE - added slider button */}
        <SliderButton
          className={classes?.slideBackwardButton}
          {...internalProps.slideBackwardButton}
          {...slideBackwardButton} 
        />
        <SliderButton
          className={classes?.slideForwardButton}
          {...internalProps.slideForwardButton}
          {...slideForwardButton}
        />
      </div> 
    );
    sliderInfoView = type === 'CardSpecificEventsDarkBlock' ? null : (
      <SliderInfo
        className={cx(styles.sliderInfo, classes?.sliderInfo)}
        {...sliderInfo} />
    );
  } else {
    containerViewWrapper = containerView;
  }

  return (
    <div ref={ref =>  setRef?.(ref) } className={cx(currentStyle, className, customStyles.eventCardsBlockCommon)}>
      {blockHeaderView}
      {containerViewWrapper}
      {sliderInfoView}
    </div>
  );
  // MANUAL OVERRIDE ENDS
};

export default EventCardsBlock;


import { ButtonProps } from '../../atoms/Button';
import { TextProps } from '../../atoms/Text';
import { TabListProps } from '../../molecules/TabList';
import { FAQListProps } from '../../organisms/FAQList';


type QuestionsBlockInternalProps = {
  title?: TextProps;
  button?: ButtonProps;
  fAQList?: FAQListProps;
};

type QuestionsBlockPropsValues = {
  '': QuestionsBlockInternalProps;
};

const propValues: QuestionsBlockPropsValues = {
  '': {
    title: {
      type: 'Subheading',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    button: {
      type: 'TextIcon',
      style: 'Text',
      size: 'Small',
    },
    fAQList: {
    },
  },
};

const getProps = (type: string): QuestionsBlockInternalProps => {
  const values: QuestionsBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

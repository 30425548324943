import { TextProps } from '../../atoms/Text';
import { TagProps } from '../Tag';


type PerformerInfoInternalProps = {
  title?: TextProps;
  date?: TextProps;
  time?: TextProps;
  location?: TextProps;
  price?: TextProps;
  tag?: TagProps;
  soldOut?: TagProps;
};

type PerformerInfoPropsValues = {
  'Default': PerformerInfoInternalProps;
  'SoldOut': PerformerInfoInternalProps;
};

const propValues: PerformerInfoPropsValues = {
  'Default': {
    title: {
      type: 'CardBody',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    date: {
      type: 'CardBody',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    time: {
      type: 'CardBody',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    location: {
      type: 'CardBody',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    price: {
      type: 'CardBody',
      size: 'Medium',
      style: 'Regular',
      colour: 'Positive',
      align: 'Left',
    },
  },
  'SoldOut': {
    title: {
      type: 'CardBody',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    date: {
      type: 'CardBody',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    time: {
      type: 'CardBody',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    location: {
      type: 'CardBody',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    tag: {
      type: 'Negative',
      size: 'Small',
    },
    soldOut: {
      type: 'Negative',
      size: 'Small',
    },
  },
};

const getProps = (type: string): PerformerInfoInternalProps => {
  const values: PerformerInfoInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

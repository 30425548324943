import { DividerProps } from '../../atoms/Divider';
import { SubmenuItemListProps } from '../../molecules/SubmenuItemList';


type SubmenuWithoutTabsInternalProps = {
  divider?: DividerProps;
  submenuItemList?: SubmenuItemListProps;
  divider1?: DividerProps;
};

type SubmenuWithoutTabsPropsValues = {
  '': SubmenuWithoutTabsInternalProps;
};

const propValues: SubmenuWithoutTabsPropsValues = {
  '': {
    divider: {
      type: 'Horizontal',
      style: 'Thin',
      colour: 'White',
    },
    submenuItemList: {
    },
    divider1: {
      type: 'Horizontal',
      style: 'Thin',
      colour: 'White',
    },
  },
};

const getProps = (type: string): SubmenuWithoutTabsInternalProps => {
  const values: SubmenuWithoutTabsInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import { CardProps } from '../../molecules/Card';


type EventsCardListInternalProps = {
  card?: CardProps;
};

type EventsCardListPropsValues = {
  '': EventsCardListInternalProps;
};

const propValues: EventsCardListPropsValues = {
  '': {
    card: {
      type: 'Default',
      format: 'Long',
    },
  },
};

const getProps = (type: string): EventsCardListInternalProps => {
  const values: EventsCardListInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import { TFunction } from 'react-i18next';
import { PerformerLandingPageCombinedProps } from './types';
import { PerformerDetail, Region } from '../../../modules/partnership';
import { CONTENT_ID } from '../../../lib/constants';
import { ImageBlockValueProps } from '../../blocks/ImageBlock';
import { FiltersBlockValueProps } from '../../blocks/FiltersBlock';
import { EventCardsBlockValueProps } from '../../blocks/EventCardsBlock';
import { PageName } from './constants';

/**
 * Generates image block props using the provided performer details function.
 *
 * @param {PerformerDetail | undefine} performer - The Performer Details.
 * @returns {ImageBlockValueProps} - The image block props.
 */
const getImageBlockProps = (performer?: PerformerDetail): ImageBlockValueProps => {
  return {
    performerPageBanner: {
      imageSrc: performer?.image,
      title: {
        value: performer?.name,
        id: CONTENT_ID,
      },
    },
  };
};

/**
 * Generates filters block props using the provided translation function and regions.
 *
 * @param {TFunction} t - The translation function from i18next.
 * @param {Region[] | undefined} allRegions - The list of all region.
 * @returns {FiltersBlockValueProps} - The filters block props.
 */
const getFiltersBlockProps = (t: TFunction, allRegions?: Region[]): FiltersBlockValueProps => {
  return {
    filterOne: {
      type: 'LocationFilter',
      showAllLocations: true,
      locationList: allRegions,
      defaultOption: {
        id: '',
        value: t('shopByWidget.anyLocation'),
      },
      newDropdownMenu: {
        type: 'Default',
      },
    },
    filterTwo: {
      defaultDateOption: {
        title: t('dateFilters.any'),
        type: 'Default',
      },
      type: 'DateFilter',
    },
  };
};

/**
 * Generates event card block props using the performer id.
 *
 * @param {number | undefined} performerId - The performer id.
 * @returns {EventCardsBlockValueProps} - The event card block props.
 */
const getEventCardsBlockProps = (performerId?: number): EventCardsBlockValueProps => {
  return {
    defaultParams: {
      performer_id: performerId,
    },
    eventCardNoImageList: {
    },
    showMoreButton: {
    },
    pageName: PageName,
    pageCarouselIndex: 1,
  };
};

/**
 * Generates performer landing page props using the provided translation function, account details and regions.
 *
 * @param {Account | undefined} props - The required props.
 * @param {TFunction} t - The translation function from i18next.
 * @param {string} allRegions - The list of all region.
 * @returns {PerformerLandingPageCombinedProps} - The performer landing page block props.
 */
const getPerformerLandingPageProps =
  (
    props: PerformerLandingPageCombinedProps,
    t: TFunction,
    allRegions?: Region[],
  ): PerformerLandingPageCombinedProps => {
    const { performerId, performer } = props;

    return {
      ...props,
      imageBlock: getImageBlockProps(performer),
      filtersBlock: getFiltersBlockProps(t, allRegions),
      eventCardsBlock: getEventCardsBlockProps(performerId),
    };
  };

export default {
  getPerformerLandingPageProps,
  getEventCardsBlockProps,
  getFiltersBlockProps,
  getImageBlockProps,
};
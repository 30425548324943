import { RadioItemProps } from '../../molecules/RadioItem';


type RewardOptionsListInternalProps = {
  radioItem?: RadioItemProps;
};

type RewardOptionsListPropsValues = {
  '': RewardOptionsListInternalProps;
};

const propValues: RewardOptionsListPropsValues = {
  '': {
    radioItem: {
      type: 'NotApply',
      state: 'Default',
    },
  },
};

const getProps = (type: string): RewardOptionsListInternalProps => {
  const values: RewardOptionsListInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

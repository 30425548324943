import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import PriceItem from '../PriceItem';

import customStyles from './Custom.module.scss';
import styles from './CostBreakdown.module.scss';
import usePresenter from './CostBreakdown.presenter';
import getProps from './CostBreakdown.props';
import { CostBreakdownCombinedProps } from './types';


const CostBreakdown: React.FC<CostBreakdownCombinedProps> = (props) => {
  const {
    className,
    classes,
    ordarTotal,
    tickets,
    serviceFee,
    delivery,
    button,
    // MANUAL OVERRIDE STARTS
    tax,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.costBreakdown;

  return (
    <div className={cx(currentStyle, className)}>
      <PriceItem
        className={cx(styles.ordarTotal, classes?.ordarTotal)}
        {...internalProps.ordarTotal}
        {...ordarTotal}/>
      <PriceItem
        className={cx(styles.tickets, classes?.tickets)}
        {...internalProps.tickets}
        {...tickets}/>
      <PriceItem
        className={cx(styles.serviceFee, classes?.serviceFee)}
        {...internalProps.serviceFee}
        {...serviceFee}/>
      <PriceItem
        className={cx(styles.delivery, classes?.delivery)}
        {...internalProps.delivery}
        {...delivery}/>
      {/* MANUAL OVERRIDE STARTS - added tax */}
      <PriceItem
        className={cx(customStyles.tax, classes?.tax)}
        {...internalProps.tax}
        {...tax}/>
      {/* MANUAL OVERRIDE ENDS */}
      {/* <div className={cx(styles.promoCodeContainer, classes?.promoCodeContainer)}>
        <Button
          className={cx(styles.button, classes?.button)}
          {...internalProps.button}
          {...button}/>
      </div> */}
    </div>
  );
};

export default CostBreakdown;


import { IconProps } from '../../atoms/Icon';
import { ImageProps } from '../../atoms/Image';
import { TextProps } from '../../atoms/Text';


type PerformerCardInternalProps = {
  image?: ImageProps;
  titleText?: TextProps;
  icon?: IconProps;
};

type PerformerCardPropsValues = {
  'Dark': PerformerCardInternalProps;
  'Light': PerformerCardInternalProps;
  'DarkDefault': PerformerCardInternalProps;
  'LightDefault': PerformerCardInternalProps;
};

const propValues: PerformerCardPropsValues = {
  'Dark': {
    image: {
    },
    titleText: {
      type: 'Body',
      size: 'Large',
      style: 'SemiBold',
      colour: 'BaseDark',
      align: 'Left',
    },
    icon: {
      asset: 'ArrowRightFilled',
      style: 'ActionHover',
    },
  },
  'Light': {
    image: {
    },
    titleText: {
      type: 'Body',
      size: 'Large',
      style: 'SemiBold',
      colour: 'BaseDark',
      align: 'Left',
    },
    icon: {
      asset: 'ArrowRightFilled',
      style: 'ActionBase',
    },
  },
  'DarkDefault': {
    image: {
    },
    titleText: {
      type: 'Body',
      size: 'Large',
      style: 'SemiBold',
      colour: 'SubduedDark',
      align: 'Left',
    },
    icon: {
      asset: 'ArrowRightFilled',
      style: 'ActionHover',
    },
  },
  'LightDefault': {
    image: {
    },
    titleText: {
      type: 'Body',
      size: 'Large',
      style: 'SemiBold',
      colour: 'SubduedDark',
      align: 'Left',
    },
    icon: {
      asset: 'ArrowRightFilled',
      style: 'ActionBase',
    },
  },
};

const getProps = (type: string): PerformerCardInternalProps => {
  const values: PerformerCardInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

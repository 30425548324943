import React from 'react';
import cx from 'classnames';

import Image from '../../atoms/Image';
import AdditionalTicketInformationList from '../../molecules/AdditionalTicketInformationList';
import CardNavigation from '../../molecules/CardNavigation';
import TicketInformation from '../../molecules/TicketInformation';
import TicketQuantitySelection from '../../molecules/TicketQuantitySelection';

import styles from './SportsPrecheckout.module.scss';
import usePresenter from './SportsPrecheckout.presenter';
import getProps from './SportsPrecheckout.props';
import { SportsPrecheckoutCombinedProps } from './types';


const SportsPrecheckout: React.FC<SportsPrecheckoutCombinedProps> = (props) => {
  const {
    className,
    classes,
    cardNavigation,
    image,
    ticketInformation,
    ticketQuantitySelection,
    additionalTicketInformationList,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.sportsPrecheckout;

  return (
    <div className={cx(currentStyle, className)}>
      <CardNavigation
        className={cx(styles.cardNavigation, classes?.cardNavigation)}
        // MANUAL OVERRIDE STARTS
        classes={{
          icon: styles.icon,
        }}
        // MANUAL OVERRIDE ENDS
        {...internalProps.cardNavigation}
        {...cardNavigation}/>
      <Image
        className={cx(styles.image, classes?.image)}
        {...internalProps.image}
        {...image}/>
      <TicketInformation
        className={cx(styles.ticketInformation, classes?.ticketInformation)}
        {...internalProps.ticketInformation}
        {...ticketInformation}/>
      <TicketQuantitySelection
        className={cx(styles.ticketQuantitySelection, classes?.ticketQuantitySelection)}
        {...internalProps.ticketQuantitySelection}
        {...ticketQuantitySelection}/>
      <AdditionalTicketInformationList
        className={cx(styles.additionalTicketInformationList, classes?.additionalTicketInformationList)}
        {...internalProps.additionalTicketInformationList}
        {...additionalTicketInformationList}/>
    </div>
  );
};

export default SportsPrecheckout;


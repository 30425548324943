import { IconTextDescriptionProps } from '../IconTextDescription';


type AdditionalTicketInformationListInternalProps = {
  iconTextDescription?: IconTextDescriptionProps;
};

type AdditionalTicketInformationListPropsValues = {
  '': AdditionalTicketInformationListInternalProps;
};

const propValues: AdditionalTicketInformationListPropsValues = {
  '': {
    iconTextDescription: {
      type: 'Default',
    },
  },
};

const getProps = (type: string): AdditionalTicketInformationListInternalProps => {
  const values: AdditionalTicketInformationListInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import { TextProps } from '../../atoms/Text';
import { TextInputProps } from '../../atoms/TextInput';


type TextFieldsInternalProps = {
  label?: TextProps;
  textInput?: TextInputProps;
  error?: TextProps; // MANUAL OVERRIDE
};

type TextFieldsPropsValues = {
  'Default': TextFieldsInternalProps;
  'Error': TextFieldsInternalProps;
};

const propValues: TextFieldsPropsValues = {
  'Default': {
    label: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
    textInput: {
      state: 'Filled',
      style: 'Default',
      size: 'Regular',
    },
  },
  'Error': {
    label: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
    textInput: {
      state: 'Filled',
      style: 'Default',
      size: 'Regular',
    },
    error: { // MANUAL OVERRIDE - renamed prop
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'Negative',
      align: 'Left',
    },
  },
};

const getProps = (type: string): TextFieldsInternalProps => {
  const values: TextFieldsInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

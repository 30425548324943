import React from 'react';
import cx from 'classnames';

import Text from '../../atoms/Text';

import styles from './PerformerPageBanner.module.scss';
import usePresenter from './PerformerPageBanner.presenter';
import getProps from './PerformerPageBanner.props';
import { PerformerPageBannerCombinedProps } from './types';


const PerformerPageBanner: React.FC<PerformerPageBannerCombinedProps> = (props) => {
  const {
    className,
    classes,
    imageSrc,
    imageSrcAlt,
    imageFallback,
    title,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.performerPageBanner;

  return (
    <div className={cx(currentStyle, className)} style={{ 
      backgroundImage: `linear-gradient(180deg, rgba(0, 19, 43, 0) 0%, rgba(0, 19, 43, 1) 100%), url(${imageSrc}), url(${imageFallback})`,  // MANUAL OVERRIDE
    }}>
      <Text
        className={cx(styles.title, classes?.title)}
        {...internalProps.title}
        {...title}/>
    </div>
  );
};

export default PerformerPageBanner;


import { TextProps } from '../../atoms/Text';
import { TagProps } from '../Tag';


type EventDateStatusInternalProps = {
  date?: TextProps;
  time?: TextProps;
  tag?: TagProps;
};

type EventDateStatusPropsValues = {
  'Default': EventDateStatusInternalProps;
  'WithTag': EventDateStatusInternalProps;
};

const propValues: EventDateStatusPropsValues = {
  'Default': {
    date: {
      type: 'CardBody',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    time: {
      type: 'CardBody',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
  },
  'WithTag': {
    tag: {
      type: 'Neutral',
      size: 'Small',
    },
    date: {
      type: 'CardBody',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    time: {
      type: 'CardBody',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
  },
};

const getProps = (type: string): EventDateStatusInternalProps => {
  const values: EventDateStatusInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import { TextProps } from '../../atoms/Text';


type ToolTipInternalProps = {
  text?: TextProps;
};

type ToolTipPropsValues = {
  '': ToolTipInternalProps;
};

const propValues: ToolTipPropsValues = {
  '': {
    text: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseLight',
      align: 'Left',
    },
  },
};

const getProps = (type: string): ToolTipInternalProps => {
  const values: ToolTipInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

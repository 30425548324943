import React from 'react';
import cx from 'classnames';

import Tab from '../../atoms/Tab';

import styles from './TabList.module.scss';
import usePresenter from './TabList.presenter';
import getProps from './TabList.props';
import { TabListCombinedProps } from './types';


const TabList: React.FC<TabListCombinedProps> = (props) => {
  const {
    className,
    classes,
    tabs,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.tabList;

  const tabViews = tabs?.map((tab, index) => (
    <Tab
      key={index}
      className={cx(styles.tab, classes?.tab)}
      {...internalProps.tab}
      {...tab} />
  )) || [];

  return (
    <div className={cx(currentStyle, className)}>
      {tabViews}
    </div>
  );
};

export default TabList;


import { useEffect } from 'react';
import { useAnalyticsManager } from '../../../modules/analytics/useAnalyticsManager';
import { trackPageViewEvent } from '../../../modules/analytics/util';
import useInteractor from './PerformersPage.interactor';
import { PerformersPageProps } from './types';

export type PerformersPagePresenterProps = PerformersPageProps;

const usePresenter = (props: PerformersPagePresenterProps): PerformersPagePresenterProps => {
  const { isSports, performer, performerId } = useInteractor(props);

  const { trackEvent } = useAnalyticsManager();

  useEffect(() => {
    if (performer) {
      trackPageViewEvent(
        trackEvent,
        'Performer',
        {
          performer_id: performerId?.toString(),
          performer: performer?.name,
        },
      );
    }
  }, [performer]);

  return {
    ...props,
    isSports,
    performerId,
    performer,
  };
};

export default usePresenter;

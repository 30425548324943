import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import Text from '../../atoms/Text';

import styles from './SecondaryBanner.module.scss';
import usePresenter from './SecondaryBanner.presenter';
import getProps from './SecondaryBanner.props';
import { SecondaryBannerCombinedProps } from './types';


const SecondaryBanner: React.FC<SecondaryBannerCombinedProps> = (props) => {
  const {
    className,
    classes,
    imageSrc,
    imageSrcAlt,
    imageFallback,
    title,
    message,
    button,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.secondaryBanner;

  return (
    <div className={cx(currentStyle, className)} style={{ backgroundImage: `url(${imageSrc}), url(${imageFallback})` }}>
      <div className={cx(styles.messageContent, classes?.messageContent)}>
        <Text
          className={cx(styles.title, classes?.title)}
          {...internalProps.title}
          {...title}/>
        <Text
          className={cx(styles.message, classes?.message)}
          {...internalProps.message}
          {...message}/>
        <Button
          className={cx(styles.button, classes?.button)}
          {...internalProps.button}
          {...button}/>
      </div>
    </div>
  );
};

export default SecondaryBanner;


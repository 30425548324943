import { useReducer } from 'react';
import { createSelector } from 'reselect';
import { Listing } from '../partnership';
import { Action, ActionType, defaultState, FilterState, OnHighlightSectionAction, OnPriceFilterChangeAction, OnQuantityFilterChangeAction, OnSelectSectionAction, OnShowAllInPriceChangeAction, OnSortFilterChangeAction, OnTypeFilterChangeAction, ResetFiltersAction, ResetSectionAction, SectionDefinition, SectionsPrice, SortFilterNames, TypeFilterName } from './types';


export const filterResetAction = (dispatch: React.Dispatch<ResetFiltersAction>) => {
  dispatch({ type: ActionType.RESET_FILTERS });
};

export const typeFilterChangeAction = (
  dispatch: React.Dispatch<OnTypeFilterChangeAction>,
  payload: { filterName: TypeFilterName; filterValue: boolean },
) => {
  dispatch({ type: ActionType.ON_TYPE_FILTER_CHANGE, payload: payload });
};

export const quantityFilterChangeAction = (
  dispatch: React.Dispatch<OnQuantityFilterChangeAction>,
  payload: { quantity: number },
) => {
  dispatch({ type: ActionType.ON_QUANTITY_FILTER_CHANGE, payload: payload });
};

export const sortFilterChangeAction = (
  dispatch: React.Dispatch<OnSortFilterChangeAction>,
  payload: { name: SortFilterNames },
) => {
  dispatch({ type: ActionType.ON_SORT_FILTER_CHANGE, payload: payload });
};

export const priceFilterChangeAction = (
  dispatch: React.Dispatch<OnPriceFilterChangeAction>,
  payload: { field: string; value: number },
) => {
  dispatch({ type: ActionType.ON_PRICE_FILTER_CHANGE, payload: payload });
};

export const sectionSelectFilterChangeAction = (
  dispatch: React.Dispatch<OnSelectSectionAction>,
  payload: SectionDefinition,
) => {
  dispatch({ type: ActionType.ON_SELECT_SECTION, payload: payload });
};

export const sectionSelectionResetAction = (dispatch: React.Dispatch<ResetSectionAction>) => {
  dispatch({ type: ActionType.RESET_SECTION_SELECTION });
};

export const sectionHighlightAction = (dispatch: React.Dispatch<OnHighlightSectionAction>, payload: SectionDefinition) => {
  dispatch({ type: ActionType.ON_HIGHLIGHT_SECTION, payload: payload });
};

export const showAllInPriceChangeAction = (dispatch: React.Dispatch<OnShowAllInPriceChangeAction>) => {
  dispatch({ type: ActionType.ON_SHOW_ALL_IN_PRICE_CHANGE });
};

// Externally-visible signature for an invalid action
export function throwActionTypeError(t: never): never;

// Implementation signature
/* istanbul ignore next */
export function throwActionTypeError(t: Action) {
  throw new Error(`Unknown action type ${t}`);
}

const cloneFilterState = (state: FilterState): FilterState => {
  return {
    typeFilterState: {
      superSeller: state.typeFilterState.superSeller,
      greatDeal: state.typeFilterState.greatDeal,
      instantDelivery: state.typeFilterState.instantDelivery,
    },
    quantityFilterState: {
      quantity: state.quantityFilterState.quantity,
    },
    sortFilterState: {
      name: state.sortFilterState.name,
    },
    priceFilterState: {
      currentMin: state.priceFilterState.currentMin,
      currentMax: state.priceFilterState.currentMax,
    },
    selectedSectionsFilterState: [],
    highlightedSectionsState: [],
    showAllInPrice: state.showAllInPrice,
  };
};

export const useFilterReducer = (): [FilterState, React.Dispatch<Action>] => {
  const defaultClone: FilterState = cloneFilterState(defaultState);

  return useReducer((state: FilterState, action: Action) => {
    const dataClone = cloneFilterState(state);
    switch (action.type) {
      case ActionType.ON_HIGHLIGHT_SECTION:
        // If this action is fired with an empty string as the ID, reset highlighted state
        // For example, backButtonHandler in components/ticket-details
        if (action.payload.id === '') {
          dataClone.highlightedSectionsState = [];

          // If value exists in state already, remove it. Otherwise, add it
        } else if (dataClone.highlightedSectionsState.find(({ id }) => id === action.payload.id)) {
          dataClone.highlightedSectionsState = dataClone.highlightedSectionsState.filter(
            ({ id }) => id !== action.payload.id,
          );
        } else {
          dataClone.highlightedSectionsState = [
            ...dataClone.highlightedSectionsState,
            { id: action.payload.id },
          ];
        }
        return dataClone;
      case ActionType.ON_SELECT_SECTION:
        // If value exists in state already, remove it. Otherwise, add it
        if (dataClone.selectedSectionsFilterState.find(({ id }) => id === action.payload.id)) {
          dataClone.selectedSectionsFilterState = dataClone.selectedSectionsFilterState.filter(
            ({ id }) => id !== action.payload.id,
          );
        } else {
          dataClone.selectedSectionsFilterState = [
            ...dataClone.selectedSectionsFilterState,
            { id: action.payload.id },
          ];
        }
        dataClone.highlightedSectionsState = [];
        return dataClone;
      case ActionType.ON_TYPE_FILTER_CHANGE:
        switch (action.payload.filterName.name) {
          case 'superSeller':
            dataClone.typeFilterState.superSeller = action.payload.filterValue;
            break;
          case 'greatDeal':
            dataClone.typeFilterState.greatDeal = action.payload.filterValue;
            break;
          default:
            dataClone.typeFilterState.instantDelivery = action.payload.filterValue;
            break;
        }
        return dataClone;
      case ActionType.ON_QUANTITY_FILTER_CHANGE:
        dataClone.quantityFilterState.quantity = action.payload.quantity;
        return dataClone;
      case ActionType.ON_SORT_FILTER_CHANGE:
        dataClone.sortFilterState.name = action.payload.name;
        return dataClone;
      case ActionType.ON_PRICE_FILTER_CHANGE:
        switch (action.payload.field) {
          case 'currentMax':
            dataClone.priceFilterState.currentMax = action.payload.value;
            break;
          case 'currentMin':
            dataClone.priceFilterState.currentMin = action.payload.value;
            break;
        }
        return dataClone;
      case ActionType.ON_SHOW_ALL_IN_PRICE_CHANGE:
        dataClone.showAllInPrice = !dataClone.showAllInPrice;
        // if (!!dataClone.showAllInPrice) {
        //     dataClone.priceFilterState.currentMin = globalPriceObject.lowestAllInPrice
        //     dataClone.priceFilterState.currentMax = globalPriceObject.highestAllInPrice
        // } else {
        //     dataClone.priceFilterState.currentMin = globalPriceObject.lowestPrice
        //     dataClone.priceFilterState.currentMax = globalPriceObject.highestPrice
        // }
        return dataClone;
      case ActionType.RESET_FILTERS:
        // if (globalPriceObject) {
        //     defaultClone.priceFilterState.currentMax = globalPriceObject.highestPrice
        //     defaultClone.priceFilterState.currentMin = globalPriceObject.lowestPrice
        // }
        return defaultClone;

      case ActionType.RESET_SECTION_SELECTION:
        dataClone.selectedSectionsFilterState = [];
        return dataClone;
      default:
        /* istanbul ignore next <--- Unreachable code so no coverage is needed */
        throwActionTypeError(action);
    }
  }, defaultClone);
};

const naturalSort = (a: string, b: string) => {
  if (isNaN(Number(a)) && isNaN(Number(b))) return a < b ? -1 : a == b ? 0 : 1;
  // both are string
  else if (isNaN(Number(a))) return 1;
  // only a is a string
  else if (isNaN(Number(b))) return -1;
  // only b is a string
  else return Number(a) - Number(b); // both are num
};

export const inRange = (x: number, range: number[]) => {
  return (x - range[0]) * (x - range[1]) <= 0;
};

export const filteredListingsSelectorMap = createSelector(
  (listings: Listing[], state: FilterState) => ({
    listings,
    state,
  }),
  ({ listings, state }) => {
    let filteredListings = listings;
    filteredListings = [...filteredListings];
    // filter logic -- use state to know which filters to apply
    // show only great deals
    // if (state.typeFilterState.greatDeal === true) {
    //     filteredListings = filteredListings.filter((listing) => listing.isGreatDeal)
    // }

    // show only instant delivery
    // if (state.typeFilterState.instantDelivery === true) {
    //     filteredListings = filteredListings.filter((listing) => listing.isInstantDelivery)
    // }

    // show only listings from super sellers
    // if (state.typeFilterState.superSeller === true) {
    //     filteredListings = filteredListings.filter((listing) => listing.isPreferredSeller)
    // }

    // show only listings where user can buy tthe quanity they've selected
    if (state.quantityFilterState.quantity != 0) {
      filteredListings = filteredListings.filter((listing) => {
        return listing.quantity >= state.quantityFilterState.quantity;
        // return listing.quantityOptionsList.includes(state.quantityFilterState.quantity)
      });
    }

    // Price Filters
    // if (!!priceRangeObject && state.priceFilterState.currentMin !== 0 && state.priceFilterState.currentMax !== 0) {
    //     /**
    //      * This is to allow the inputs to be empty. See the `price-slider` component for more reasoning
    //      * around the logic used here.
    //      *
    //      * Range min/max need to be the smallest/largest values possible to ensure our filteredListings
    //      * array is filtered appropriately.
    //      */
    //     const rangeMin = state.priceFilterState.currentMin === Infinity ? 0 : state.priceFilterState.currentMin
    //     const rangeMax =
    //         state.priceFilterState.currentMax === -Infinity
    //             ? Number.MAX_SAFE_INTEGER
    //             : state.priceFilterState.currentMax
    //     const range = [rangeMin, rangeMax]

    //     if (!!state.showAllInPrice) {
    //         // filter based off all in prices
    //         if (
    //             state.priceFilterState.currentMin > priceRangeObject.lowestAllInPrice ||
    //             state.priceFilterState.currentMax < priceRangeObject.highestAllInPrice
    //         ) {
    //             // if yes, show only listings in the price range a user has selected
    //             filteredListings = filteredListings.filter((listing) => {
    //                 return inRange(listing.allInPrice ?? 0, range)
    //             })
    //         }
    //     } else {
    //         // filter based off regular prices
    //         if (
    //             state.priceFilterState.currentMin > priceRangeObject.lowestPrice ||
    //             state.priceFilterState.currentMax < priceRangeObject.highestPrice
    //         ) {
    //             // if yes, show only listings in the price range a user has selected
    //             filteredListings = filteredListings.filter((listing) => {
    //                 return inRange(listing.price_per, range)
    //             })
    //         }
    //     }
    // }

    //  Sort listings in the way a user has selected
    if (state.sortFilterState.name === SortFilterNames.PRICE) {
      // sort by price lowest to highest
      filteredListings = filteredListings.sort((a, b) => a.price_per - b.price_per);
    }
    if (state.sortFilterState.name === SortFilterNames.ROW) {
      // sort by row lowest to highest
      filteredListings = filteredListings.sort((a, b) => naturalSort(a.row.toLowerCase(), b.row.toLowerCase()));
    }
    if (state.sortFilterState.name === SortFilterNames.SECTION) {
      // sort by section lowest to highest
      filteredListings = filteredListings.sort((a, b) =>
        naturalSort(a.section.toLowerCase(), b.section.toLowerCase()),
      );
    }
    return { listings: filteredListings, state };
  },
);

export const filteredListingsSelector = createSelector(filteredListingsSelectorMap, ({ listings, state }) => {
  if (state.selectedSectionsFilterState.length > 0) {
    return listings.filter((listing) =>
      state.selectedSectionsFilterState.some(({ id }) => id === listing.section),
    );
  }
  return listings;
});

export const filteredSectionsWithPrice = createSelector(filteredListingsSelectorMap, ({ listings }) => {
  return listings.reduce((acc, item) => {
    if (!acc[item.section] || acc[item.section].price > item.price_per) {
      acc[item.section] = {
        price: item.price_per,
        allInPrice: item.price_per ? item.price_per : 0,
      };
    }
    return acc;
  }, {} as SectionsPrice);
});

export const filteredSections = createSelector(filteredSectionsWithPrice, (_filteredSectionsWithPrice) => {
  return Object.keys(_filteredSectionsWithPrice);
});

export const getCurrentSortByValueSelector = createSelector(
  (state: FilterState) => state.sortFilterState.name,
  (name) => {
    switch (name) {
      case SortFilterNames.SECTION:
        return 'section';
      case SortFilterNames.ROW:
        return 'row';
      case SortFilterNames.PRICE:
        return 'price';
    }
  },
);

export const getHighlightedSections = createSelector(
  (state: FilterState) => ({
    selected: state.selectedSectionsFilterState.map(({ id }) => id),
    highlighted: state.highlightedSectionsState.map(({ id }) => id),
  }),
  ({ selected, highlighted }) => (highlighted.length > 0 ? highlighted : selected.length > 0 ? selected : []),
);

export const getCurrentSortByButtonTextSelector = createSelector(
  (state: FilterState) => state.sortFilterState.name,
  (name) => {
    switch (name) {
      case SortFilterNames.SECTION:
        return 'Sort by Section';
      case SortFilterNames.ROW:
        return 'Sort by Row';
      case SortFilterNames.PRICE:
        return 'Sort by Price';
    }
  },
);

export const getViewResultsButtonTextSelector = createSelector(
  (filteredListings: Listing[]) => filteredListings,
  (filteredListings) => {
    const numberOfFilteredListings = filteredListings.length;
    if (numberOfFilteredListings > 0) {
      return `View ${numberOfFilteredListings} Result${numberOfFilteredListings !== 1 ? 's' : ''}`;
    }
    return 'No Results';
  },
);

// export const hasGreatDealsSelector = createSelector(
//     (listings: Listing[]) => ({ listings }),
//     ({ listings }) => listings.some((listing: Listing) => listing.isGreatDeal),
// )

import { DividerProps } from '../../atoms/Divider';
import { TextInputProps } from '../../atoms/TextInput';
import { NewDropdownSelectListProps } from '../NewDropdownSelectList';


type NewDropdownMenuInternalProps = {
  newDropdownSelectList?: NewDropdownSelectListProps;
  textInput?: TextInputProps;
  divider?: DividerProps;
};

type NewDropdownMenuPropsValues = {
  'Default': NewDropdownMenuInternalProps;
  'WithSearch': NewDropdownMenuInternalProps;
};

const propValues: NewDropdownMenuPropsValues = {
  'Default': {
    newDropdownSelectList: {
    },
  },
  'WithSearch': {
    textInput: {
      state: 'Empty',
      style: 'ClearWithIcon',
      size: 'Regular',
    },
    divider: {
      type: 'Horizontal',
      style: 'Thin',
      colour: 'DigitalGrey5',
    },
    newDropdownSelectList: {
    },
  },
};

const getProps = (type: string): NewDropdownMenuInternalProps => {
  const values: NewDropdownMenuInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import { SearchContainerProps } from '../../organisms/SearchContainer';


type SearchBlockInternalProps = {
  searchContainer?: SearchContainerProps;
};

type SearchBlockPropsValues = {
  '': SearchBlockInternalProps;
};

const propValues: SearchBlockPropsValues = {
  '': {
    searchContainer: {
      state: 'Default',
    },
  },
};

const getProps = (type: string): SearchBlockInternalProps => {
  const values: SearchBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import { TextProps } from '../../atoms/Text';
import { HighlightMessageProps } from '../../molecules/HighlightMessage';
import { RewardOptionsListProps } from '../../organisms/RewardOptionsList';


type LoyaltyBlockInternalProps = {
  blockTitle?: TextProps;
  description?: TextProps;
  highlightMessage?: HighlightMessageProps;
  rewardOptionsList?: RewardOptionsListProps;
};

type LoyaltyBlockPropsValues = {
  '': LoyaltyBlockInternalProps;
};

const propValues: LoyaltyBlockPropsValues = {
  '': {
    blockTitle: {
      type: 'Subheading',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    description: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    highlightMessage: {
      type: 'Error',
    },
    rewardOptionsList: {
    },
  },
};

const getProps = (type: string): LoyaltyBlockInternalProps => {
  const values: LoyaltyBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

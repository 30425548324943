import { ButtonCombinedProps } from '../../atoms/Button';
import { IconProps } from '../../atoms/Icon';
import { TextProps } from '../../atoms/Text';


type TicketAlertModalInternalProps = {
  closeButton?: ButtonCombinedProps;
  icon?: IconProps;
  title?: TextProps;
  message?: TextProps;
  primaryButton?: ButtonCombinedProps;
  className?: string;
  open?: boolean;
};

type TicketAlertModalPropsValues = {
  '': TicketAlertModalInternalProps;
};

const propValues: TicketAlertModalPropsValues = {
  '': {
    closeButton: {
      type: 'Icon',
      style: 'Text',
      size: 'Small',
      icon: {
        asset: 'Close',
        style: 'DigitalGrey80',
      },
    },
    icon: {
      asset: 'Warning',
      style: 'DigitalGrey80',
    },
    title: {
      type: 'Heading',
      size: 'Small',
      style: 'Light',
      colour: 'BaseDark',
      align: 'Left',
    },
    message: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
    primaryButton: {
      type: 'Text',
      style: 'ContainedGreen',
      size: 'Large',
    },
  },
};

const getProps = (type: string): TicketAlertModalInternalProps => {
  const values: TicketAlertModalInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import React from 'react';
import cx from 'classnames';

import SubmenuTabItem from '../SubmenuTabItem';

import styles from './SportsMenuTabList.module.scss';
import usePresenter from './SportsMenuTabList.presenter';
import getProps from './SportsMenuTabList.props';
import { SportsMenuTabListCombinedProps } from './types';


const SportsMenuTabList: React.FC<SportsMenuTabListCombinedProps> = (props) => {
  const {
    className,
    classes,
    submenuTabItems,
    // MANUAL OVERRIDE STARTS
    setHoveredLeague,
    activeTab,
    focusable,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.sportsMenuTabList;

  const submenuTabItemViews = submenuTabItems?.map((submenuTabItem, index) => {
    const isActive: boolean = submenuTabItem.id === activeTab;
    return (
      <SubmenuTabItem
        focusable={focusable && isActive}
        onMouseOver={() => {
          if (setHoveredLeague) {
            setHoveredLeague(submenuTabItem.id);
          }
        }}
        id={submenuTabItem.id}
        key={index}
        className={cx(isActive ? styles.submenuTabItemActive : styles.submenuTabItem, classes?.submenuTabItem)}
        {...internalProps.submenuTabItem}
        {...submenuTabItem} />
    );
  }) || [];

  return (
    <div className={cx(currentStyle, className)}>
      {submenuTabItemViews}
    </div>
  );
};

export default SportsMenuTabList;


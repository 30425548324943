import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { CURRENT_LOCATION_FALLBACK_VALUE, QUERY_DATE_FROM, QUERY_DATE_TO, QUERY_HOME_OR_AWAY } from '../../../lib/constants';
import { addQueryParam, formatDateToApiDate } from '../../../lib/util';

import { NewDropdownMenuCombinedProps } from '../../organisms/NewDropdownMenu/types';
import { IconProps } from '../Icon';
import { IconAssetEnum } from '../Icon/types';
import { NewDropdownMenuItemCombinedProps } from '../NewDropdownMenuItem/types';

import { DateFilterOptions } from './constants';
import { DropdownOption, DropdownValue, NewDropdownCombinedProps, SelectedDropdownOption } from './types';
import { dynamicRegionList, formatSelectedDate, formatWeekendDropdownTitle, getRegion } from './utils';
import { useAnalyticsManager } from '../../../modules/analytics/useAnalyticsManager';
import { trackSelectContentEvent } from '../../../modules/analytics/util';

const usePresenter = (props: NewDropdownCombinedProps): NewDropdownCombinedProps => {
  const { 
    currentRegion, defaultOption, defaultDateOption, optionFilterList, locationList, 
    showAllLocations, setOptionFilterId, text, type, showUseMyLocation,
  } = props;
  const { trackEvent } = useAnalyticsManager();
  const history = useHistory();
  const { t } = useTranslation();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const homeAwayFilter = query.get(QUERY_HOME_OR_AWAY);
  const dateFrom = query.get(QUERY_DATE_FROM);
  const dateTo = query.get(QUERY_DATE_TO);
  const regionId = query.get('region_id');
  const defaultLocation = getRegion(locationList, regionId);

  const [selectedDateOption, setSelectedDateOption] = useState<SelectedDropdownOption | undefined>(undefined);
  const [selectedOption, setSelectedOption] = useState<DropdownOption | undefined>(undefined);
  const [isDropdownDisabled, setIsDropdownDisabled] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);

  useEffect(() => {
    let currentOption;
    switch (type) {
      case 'DateFilter':
        if (dateFrom && dateTo) {
          currentOption = DateFilterOptions.find((option) => {
            if (option.value?.startDate && option.value.endDate) {
              return formatDateToApiDate(option.value?.startDate) === dateFrom &&
                formatDateToApiDate(option.value?.endDate) === dateTo;
            }
            return false;
          });
  
          if (!currentOption) {
            const startDate = new Date(dateFrom.replace(/-/g, '/')); // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
            const endDate = new Date(dateTo.replace(/-/g, '/')); // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
            currentOption = {
              value: {
                startDate,
                endDate,
              },
              title: t('dateFilters.selectedDateRange', {
                startDate: formatSelectedDate(startDate),
                endDate: formatSelectedDate(endDate),
              }),
            };
          } else if (currentOption.title === t('dateFilters.weekend')
            && currentOption.value?.startDate
            && currentOption.value.endDate
          ) {
            currentOption = {
              ...currentOption,
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              title: formatWeekendDropdownTitle(currentOption),
            };
          }
          
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setSelectedDateOption(currentOption || defaultDateOption);
        break;

      case 'LocationFilter': 
      case 'SearchLocationFilter': 
        currentOption = defaultLocation && {
          value: (defaultLocation.displayName ?? defaultLocation.city) || t('shopByWidget.anyLocation'),
          id: String(defaultLocation.id),
        };
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setSelectedOption(currentOption || defaultOption);
        break;

      case 'CategoryFilter':
        setSelectedOption(selectedOption || defaultOption);
        break;

      default:
        break;
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultOption]);

  const updateSearchParams = (filterValue: DropdownValue) => {
    const { value, startDate, endDate } = filterValue;

    let params;
    if (type === 'SearchLocationFilter' || type === 'LocationFilter') {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      params = addQueryParam(history.location.search, {
        region_id: value || '',
      });
    }

    if (type === 'CategoryFilter') {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      params = addQueryParam(history.location.search, {
        category: value || '',
      });
    }

    if (type === 'DateFilter') {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      params = addQueryParam(history.location.search, {
        [QUERY_DATE_FROM]: startDate ? formatDateToApiDate(startDate) : '',
        [QUERY_DATE_TO]: endDate ? formatDateToApiDate(endDate) : '',
      });
    }

    if (params) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      history.push({
        search: params,
      });
    }
  };

  // To reset the value when dropdown is disabled
  useEffect(() => {
    if (homeAwayFilter && (type === 'LocationFilter' || type === 'SearchLocationFilter')) {
      setIsDropdownDisabled(true);
      setSelectedOption(defaultOption);
      setShowMenu(false);
      setOptionFilterId?.(undefined);
      updateSearchParams({
        value: undefined,
      });
      return;
    }

    setIsDropdownDisabled(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [homeAwayFilter]);

  const dateOptions: NewDropdownMenuItemCombinedProps[] = DateFilterOptions.map((option) => {
    const isSelected = option.title !== t('dateFilters.chooseDate')
      && selectedDateOption?.value?.startDate?.getTime() === option.value?.startDate?.getTime()
      && selectedDateOption?.value?.endDate?.getTime() === option.value?.endDate?.getTime();
    return {
      state: isSelected ? 'Selected' : 'Unselected',
      text: {
        colour: isSelected ? 'ActionBase' : 'BaseDark',
        value: option.title,
      },
      hideIcon: true,
      onClick: () => {
        let title = option.title;
        if (option.title === t('dateFilters.weekend')) {
          title = formatWeekendDropdownTitle(option);
        }
        setSelectedDateOption({
          title: title,
          value: option.value,
          type: option.type,
        });
        if (option.type === 'PickDate') {
          setShowCalendar(true);
          return;
        }
        updateSearchParams({
          startDate: option.value?.startDate,
          endDate: option.value?.endDate,
        });
        setShowMenu(false);
      },
    } as NewDropdownMenuItemCombinedProps;
  });

  const handleFilterOptions = (): DropdownOption[] | undefined => {
    switch (type) {
      case 'LocationFilter':
      case 'SearchLocationFilter':
        return locationList?.map(region => ({
          id: String(region.id),
          value: region.displayName ?? region.city,
        }));
    
      default:
        return optionFilterList;
    }
  };

  let filterOptions = handleFilterOptions() || [];

  if (type === 'SearchLocationFilter') {
    filterOptions = searchQuery && filterOptions && searchQuery.length > 2
      ? dynamicRegionList(filterOptions, searchQuery)
      : [];
  }

  const onOptionsFilterClicked = (option: DropdownOption) => {
    trackSelectContentEvent(
      trackEvent,
      'Filter',
      'FilterOption',
      option.value,
    );
    setSearchQuery(undefined);
    setShowMenu(false);
    setSelectedOption({
      id:  option.id,
      value: option.value,
    });
    updateSearchParams({ value: option.id });
    if (setOptionFilterId) {
      setOptionFilterId(option.id);
    }
  };

  const getIcon = (isSelected: boolean, asset?: IconAssetEnum): IconProps | undefined => {
    if (asset) {
      return {
        asset,
        style: isSelected ? 'ActionBase' : 'DigitalGrey100',
      };
    }
  };

  const locationFilterOptions: NewDropdownMenuItemCombinedProps[] = useMemo(() => {
    const dropdownMenuItems: NewDropdownMenuItemCombinedProps[] = filterOptions.map((option) => {
      const isSelected = selectedOption?.id === option.id.toString();
      return {
        state: isSelected ? 'Selected' : 'Unselected',
        icon: getIcon(isSelected, 'LocationPin'),
        hideIcon: type === 'CategoryFilter',
        text: {
          colour: isSelected ? 'ActionBase' : 'BaseDark',
          value: option.value,
        },
        onClick: () => {
          onOptionsFilterClicked(option);
        },
      } as NewDropdownMenuItemCombinedProps;
    });

    if (showUseMyLocation) {
      const isDefaultSelected = currentRegion
        ? selectedOption?.value === currentRegion.displayName
        : selectedOption?.id === CURRENT_LOCATION_FALLBACK_VALUE;

      const useMyLocationOptions: NewDropdownMenuItemCombinedProps = {
        state: isDefaultSelected ? 'Selected' : 'Unselected',
        icon: getIcon(isDefaultSelected, 'MyLocation'),
        text: {
          colour: isDefaultSelected ? 'ActionBase' : 'BaseDark',
          value: t('regionFilters.useMyCurrentLocation'),
        },
        onClick: () => {
          const myLocationOption = {
            value: currentRegion?.displayName || t('shopByWidget.anyLocation'),
            id: currentRegion ? String(currentRegion.id) : CURRENT_LOCATION_FALLBACK_VALUE,
          } as DropdownOption;
          onOptionsFilterClicked(myLocationOption);
        },
        trailingIcon: {
          asset: 'CheckmarkInCircleFilled', // MANUAL OVERRIDE
          style: 'Green60', // MANUAL OVERRIDE
        },
      };

      return [useMyLocationOptions, ...dropdownMenuItems];
    } else if (showAllLocations) {
      const isAllLocationOptionSelected = selectedOption?.value === t('shopByWidget.anyLocation');
      const allLocationsOptions: NewDropdownMenuItemCombinedProps = {
        state: isAllLocationOptionSelected ? 'Selected' : 'Unselected',
        icon: {
          asset: 'LocationPin',
          style: isAllLocationOptionSelected ? 'ActionBase' : 'DigitalGrey100',
        },
        text: {
          colour: isAllLocationOptionSelected ? 'ActionBase' : 'BaseDark',
          value: t('shopByWidget.anyLocation'),
        },
        onClick: () => {
          const allLocationsOption = {
            value: t('shopByWidget.anyLocation'),
            id: '',
          } as DropdownOption;
          onOptionsFilterClicked(allLocationsOption);
        },
      };
      return [allLocationsOptions, ...dropdownMenuItems];
    } else {
      return dropdownMenuItems;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption, searchQuery, currentRegion, locationList, selectedDateOption, showUseMyLocation, t, type, optionFilterList]);

  let dropdownMenuProps;
  switch (type) {
    case 'DateFilter':
      dropdownMenuProps = {
        type: 'Default',
        newDropdownSelectList: {
          newDropdownMenuItems: dateOptions,
        },
      } as NewDropdownMenuCombinedProps;
      break;

    case 'LocationFilter':
      dropdownMenuProps = {
        newDropdownSelectList: {
          newDropdownMenuItems: locationFilterOptions,
        },
      } as NewDropdownMenuCombinedProps;
      break;

    case 'SearchLocationFilter':
      dropdownMenuProps = {
        type: 'WithSearch',
        newDropdownSelectList: {
          newDropdownMenuItems: locationFilterOptions,
        },
        textInput: {
          textPlaceholder: t('regionFilters.searchPlaceHolder'),
          textValue: searchQuery,
          onTextChanged: (event) => setSearchQuery(event.target.value),
        },
      } as NewDropdownMenuCombinedProps;
      break;
    
    case 'CategoryFilter':
      dropdownMenuProps = {
        type: 'Default',
        newDropdownSelectList: {
          newDropdownMenuItems: locationFilterOptions,
        },
      } as NewDropdownMenuCombinedProps;
      break;
    default:
      break;
  }

  const handleFilterToggle = (isOpen: boolean) => {
    if (!isOpen) {
      setShowCalendar(false);
    }
    setSearchQuery(undefined);
    setShowMenu(isOpen);
  };

  const filterButtonText = type === 'DateFilter' ? selectedDateOption?.title : selectedOption?.value;

  return {
    ...props,
    disabled: isDropdownDisabled,
    showMenu: !isDropdownDisabled && showMenu,
    showCalendar,
    newDropdownMenu: {
      ...props.newDropdownMenu,
      ...dropdownMenuProps,
    },
    text: {
      ...text,
      value: filterButtonText,
    },
    icon: {
      ...props.icon,
      asset: showMenu ? 'ChevronUp' : 'ChevronDown',
    },
    handleToggle: handleFilterToggle,
    state: isDropdownDisabled ? 'Disabled' : (showMenu ? 'Open' : 'Closed'),
    onClick: () => {
      if (!isDropdownDisabled) {
        trackSelectContentEvent(
          trackEvent,
          'Filter',
          'Button',
          filterButtonText,
        );
        setShowMenu(true);
      }
    },
    setDateRange: (dates: Date[]) => {
      const [startDate, endDate] = dates;
      if (startDate && endDate) {
        updateSearchParams({ startDate, endDate });
        const dateFilterTitle = t('dateFilters.selectedDateRange', {
          startDate: formatSelectedDate(startDate),
          endDate: formatSelectedDate(endDate),
        });
        setSelectedDateOption({
          title: dateFilterTitle,
          value: {
            startDate,
            endDate,
          },
        });
        setShowMenu(false);
        setShowCalendar(false);
      }
    },
  };
};

export default usePresenter;

import { DividerProps } from '../../atoms/Divider';
import { IconProps } from '../../atoms/Icon';
import { TextInputProps } from '../../atoms/TextInput';


type SearchFieldInternalProps = {
  icon?: IconProps;
  textInput?: TextInputProps;
  divider?: DividerProps;
};

type SearchFieldPropsValues = {
  'SearchBubble': SearchFieldInternalProps;
  'SearchLine': SearchFieldInternalProps;
};

const propValues: SearchFieldPropsValues = {
  'SearchBubble': {
    icon: {
      asset: 'SearchSmall',
      style: 'Charcoal20',
    },
    textInput: {
      state: 'Empty',
      style: 'Clear',
      size: 'Regular',
    },
  },
  'SearchLine': {
    icon: {
      asset: 'SearchSmall',
      style: 'White',
    },
    textInput: {
      state: 'Empty',
      style: 'Clear',
      size: 'Regular',
    },
    divider: {
      type: 'Horizontal',
      style: 'Thick',
      colour: 'White',
    },
  },
};

const getProps = (type: string): SearchFieldInternalProps => {
  const values: SearchFieldInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import { TextProps } from '../../atoms/Text';
import { DividerProps } from '../../atoms/Divider';
import { ButtonProps } from '../../atoms/Button';


type SearchReturnItemInternalProps = {
  text?: TextProps;
  description?: TextProps;
  divider?: DividerProps;
  nextButton?: ButtonProps;
  divider1?: DividerProps;
};

type SearchReturnItemPropsValues = {
  'DarkBackground': SearchReturnItemInternalProps;
  'LightBackground': SearchReturnItemInternalProps;
};

const propValues: SearchReturnItemPropsValues = {
  'DarkBackground': {
    text: {
      type: 'Decoration', // MANUAL OVERRIDE
      size: 'Large',
      style: 'Regular',
      colour: 'BaseLight',
      align: 'Left',
    },
    description: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'BaseLight',
      align: 'Left',
    },
    divider: {
      type: 'Horizontal',
      style: 'Thin',
      colour: 'White',
    },
  },
  'LightBackground': {
    divider: {
      type: 'Horizontal',
      style: 'Thin',
      colour: 'DigitalGrey15',
    },
    text: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'BaseLight',
      align: 'Left',
    },
    description: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'BaseLight',
      align: 'Left',
    },
    nextButton: {
      type: 'Icon',
      style: 'Text',
      size: 'Small',
    },
    divider1: {
      type: 'Horizontal',
      style: 'Thin',
      colour: 'DigitalGrey15',
    },
  },
};

const getProps = (type: string): SearchReturnItemInternalProps => {
  const values: SearchReturnItemInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import { IconProps } from '../Icon';


type SliderButtonInternalProps = {
  icon?: IconProps;
};

type SliderButtonPropsValues = {
  'DarkNext': SliderButtonInternalProps;
  'DarkPrev': SliderButtonInternalProps;
  'LightNext': SliderButtonInternalProps;
  'LightPrev': SliderButtonInternalProps;
};

const propValues: SliderButtonPropsValues = {
  'DarkNext': {
    icon: {
      asset: 'ChevronRight',
      style: 'ActionBase',
    },
  },
  'DarkPrev': {
    icon: {
      asset: 'ChevronLeft',
      style: 'ActionBase',
    },
  },
  'LightNext': {
    icon: {
      asset: 'ChevronRight',
      style: 'ActionBase',
    },
  },
  'LightPrev': {
    icon: {
      asset: 'ChevronLeft',
      style: 'ActionBase',
    },
  },
};

const getProps = (type: string): SliderButtonInternalProps => {
  const values: SliderButtonInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import { handleAmountFormattingString, handleDecimalValuesForDisplay, RewardUnits } from '../../../lib/util';
import { Account } from '../../../modules/auth/types';

export const getFormattedRewardsValue = (
  account: Account | undefined,
  loyaltyPrice?: number,
) => {
  return account?.loyalty_program.loyalty_unit_name.toLowerCase() === RewardUnits.MILES.toLowerCase()
    ? handleAmountFormattingString(loyaltyPrice)
    : `$${handleDecimalValuesForDisplay(handleAmountFormattingString(loyaltyPrice), true)}`;
};

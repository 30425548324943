import React from 'react';
import cx from 'classnames';

import SearchCategories from '../../molecules/SearchCategories';
import SearchReturnList from '../../organisms/SearchReturnList';

import styles from './SearchResultsBlock.module.scss';
import usePresenter from './SearchResultsBlock.presenter';
import getProps from './SearchResultsBlock.props';
import { SearchResultsBlockCombinedProps } from './types';


const SearchResultsBlock: React.FC<SearchResultsBlockCombinedProps> = (props) => {
  const {
    className,
    classes,
    upcomingCategory,
    upcomingSearchReturnList,
    performersCategory,
    performersSearchReturnList,
    venueCategory,
    venueSearchReturnList,
    searchSuggestions, // MANUAL OVERRIDE
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.searchResultsBlock;

  let eventView;
  let performerView;
  let venueView;

  if (searchSuggestions?.events.length) {
    eventView = (
      <>
        <SearchCategories
          className={cx(styles.upcomingCategory, classes?.upcomingCategory)}
          {...internalProps.upcomingCategory}
          {...upcomingCategory}/>
        <SearchReturnList
          className={cx(styles.upcomingSearchReturnList, classes?.upcomingSearchReturnList)}
          {...internalProps.upcomingSearchReturnList}
          {...upcomingSearchReturnList}/>
      </>
    );
  }

  if (searchSuggestions?.performers.length) {
    performerView = (
      <>
        <SearchCategories
          className={cx(styles.performersCategory, classes?.performersCategory)}
          {...internalProps.performersCategory}
          {...performersCategory}/>
        <SearchReturnList
          className={cx(styles.performersSearchReturnList, classes?.performersSearchReturnList)}
          {...internalProps.performersSearchReturnList}
          {...performersSearchReturnList}/>
      </>
    );
  }

  if (searchSuggestions?.venues.length) {
    venueView = (
      <>
        <SearchCategories
          className={cx(styles.venueCategory, classes?.venueCategory)}
          {...internalProps.venueCategory}
          {...venueCategory}/>
        <SearchReturnList
          className={cx(styles.venueSearchReturnList, classes?.venueSearchReturnList)}
          {...internalProps.venueSearchReturnList}
          {...venueSearchReturnList}/>
      </>
    );
  }

  if (!eventView) {
    return (
      <div className={cx(styles.searchResultsBlock, className)}>
        <div className={styles.menuContainer}>
          <div className={styles.leftContent}>
            {performerView}
            {venueView}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.menuContainer, classes?.menuContainer)}>
        <div className={cx(styles.leftContent, classes?.leftContent)}>
          {eventView}
        </div>
        <div className={cx(styles.rightContent, classes?.rightContent)}>
          {performerView}
          {venueView}
        </div>
        <div className={cx(styles.searchResultsBlockMobile, className)}>
          {performerView}
          {eventView}
          {venueView}
        </div>
      </div>
    </div>
  );
};

export default SearchResultsBlock;


import React from 'react';
import cx from 'classnames';
import { Modal } from 'react-bootstrap';

import Image from '../../atoms/Image';
import Button from '../../atoms/Button';
import Text from '../../atoms/Text';

import styles from './EventDetailsModal.module.scss';
import usePresenter from './EventDetailsModal.presenter';
import getProps from './EventDetailsModal.props';
import { EventDetailsModalCombinedProps } from './types';


const EventDetailsModal: React.FC<EventDetailsModalCombinedProps> = (props) => {
  const {
    className,
    classes,
    button,
    bannerImage,
    title,
    eventInfo,
    title1,
    description1,
    description,
    // MANUAL OVERRIDE STARTS
    show,
    closeModal,
    eventInfoMobile,
    bannerImageMobile,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.eventDetailsModal;

  return (
    <Modal
      className={styles.modal}
      dialogClassName={styles.modalDialog}
      show={show}
      onHide={closeModal}
    >
      <div className={cx(currentStyle, className)}>
        <div className={cx(styles.buttonContainer, classes?.buttonContainer)}>
          <Button
            className={cx(styles.button, classes?.button)}
            {...internalProps.button}
            {...button}/>
        </div>

        <div className={styles.mobile}>
          <div className={styles.topContent}>
            <Image 
              className={styles.bannerImage}
              {...internalProps.bannerImage}
              {...bannerImageMobile} />
            <div className={styles.rightContent}>
              <div className={styles.description}>
                <Text 
                  className={styles.title}
                  {...internalProps.title}
                  {...title} />
                <Text 
                  className={styles.eventInfo}
                  {...internalProps.eventInfo}
                  {...eventInfoMobile} />
              </div>
            </div>
          </div>
          <div className={styles.bottomContent}>
            <Text 
              className={styles.title1}
              {...internalProps.title1}
              {...title1} />
            <Text 
              className={styles.description1}
              {...internalProps.description1}
              {...description} />
          </div>
        </div>

        <div className={styles.desktop}>
          <Image 
            className={styles.bannerImage} 
            {...internalProps.bannerImage}
            {...bannerImage} />
          <div className={styles.rightContent}>
            <div className={styles.content}>
              <Text 
                className={styles.title}
                {...internalProps.title}
                {...title} />
              <Text 
                className={styles.eventInfo}
                {...internalProps.eventInfo}
                {...eventInfo} />
            </div>
            <Text 
              className={styles.subHeadingTitle}
              {...internalProps.title1}
              {...title1} />
            <Text 
              className={styles.description}
              {...internalProps.description}
              {...description} />
          </div>
        </div>
      </div>
    </Modal>

  );
};

export default EventDetailsModal;


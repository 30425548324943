import { AdditionalTicketInformationListProps } from '../../molecules/AdditionalTicketInformationList';
import { CardNavigationProps } from '../../molecules/CardNavigation';
import { TicketInformationProps } from '../../molecules/TicketInformation';
import { TicketQuantitySelectionProps } from '../../molecules/TicketQuantitySelection';


type GeneralPrecheckoutInternalProps = {
  cardNavigation?: CardNavigationProps;
  ticketInformation?: TicketInformationProps; // MANUAL OVERRIDE
  ticketQuantitySelection?: TicketQuantitySelectionProps;
  additionalTicketInformationList?: AdditionalTicketInformationListProps;
};

type GeneralPrecheckoutPropsValues = {
  '': GeneralPrecheckoutInternalProps;
};

const propValues: GeneralPrecheckoutPropsValues = {
  '': {
    cardNavigation: {
      type: 'Default',
      style: 'LightBackground',
    },
    ticketInformation: {
      type: 'LargeTitle', // MANUAL OVERRIDE
    },
    ticketQuantitySelection: {
    },
    additionalTicketInformationList: {
    },
  },
};

const getProps = (type: string): GeneralPrecheckoutInternalProps => {
  const values: GeneralPrecheckoutInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

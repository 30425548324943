import React from 'react';
import cx from 'classnames';

import Divider from '../../atoms/Divider';
import SportsMenuTabList from '../../molecules/SportsMenuTabList';
import SubmenuItemList from '../../molecules/SubmenuItemList';

import styles from './SubmenuWithDoubleTabs.module.scss';
import usePresenter from './SubmenuWithDoubleTabs.presenter';
import getProps from './SubmenuWithDoubleTabs.props';
import { SubmenuWithDoubleTabsCombinedProps } from './types';
import SubmenuWithoutTabs from '../SubmenuWithoutTabs';
import SubmenuWithTabs from '../SubmenuWithTabs';


const SubmenuWithDoubleTabs: React.FC<SubmenuWithDoubleTabsCombinedProps> = (props) => {
  const {
    className,
    classes,
    divider,
    sportsMenuTabList,
    dividerOne,
    sportsMenuTabList1,
    submenuItemListOne,
    submenuItemListTwo,
    // MANUAL OVERRIDE STARTS
    activeMenu,
    submenuWithTabs,
    submenuWithoutTabs,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.submenuWithDoubleTabs;

  // MANUAL OVERRIDE STARTS
  let activeMenuView;
  switch (activeMenu?.id) {
    case 'sports':
      activeMenuView = (
        <SubmenuWithTabs
          {...submenuWithTabs}
        />
      );
      break;
    default:
      activeMenuView = (
        <SubmenuWithoutTabs
          {...submenuWithoutTabs}
        />
      );
      break;
  }
  // MANUAL OVERRIDE ENDS

  return (
    <div className={cx(currentStyle, className)}>
      <Divider
        className={cx(styles.divider, classes?.divider)}
        {...internalProps.divider}
        {...divider}/>
      <div className={cx(styles.content, classes?.content)}>
        <div className={cx(styles.leftContent, classes?.leftContent)}>
          <SportsMenuTabList
            className={cx(styles.sportsMenuTabList, classes?.sportsMenuTabList)}
            {...internalProps.sportsMenuTabList}
            {...sportsMenuTabList}/>
        </div>
        <Divider
          className={cx(styles.dividerOne, classes?.dividerOne)}
          {...internalProps.dividerOne}
          {...dividerOne}/>
        <div className={cx(styles.spacerContent, classes?.spacerContent)}/>
        <div className={cx(styles.rightContent, classes?.rightContent)}>
          {/* MANUAL OVERRIDE */}
          {activeMenuView}
        </div>
      </div>
      <Divider
        className={cx(styles.divider, classes?.divider)}
        {...internalProps.divider}
        {...divider}/>
    </div>
  );
};

export default SubmenuWithDoubleTabs;


import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import Text from '../../atoms/Text';
import Icon from '../../atoms/Icon';
import Image from '../../atoms/Image';
import TeamHomeAwayImage from '../../atoms/TeamHomeAwayImage';
import EventInfo from '../EventInfo';
import PerformerInfo from '../PerformerInfo';
import TicketStatus from '../TicketStatus';

import styles from './Card.module.scss';
import usePresenter from './Card.presenter';
import getProps from './Card.props';
import { CardCombinedProps } from './types';

import { ReactComponent as IconIcon } from '../../../resources/icons/Icon.svg';
import { Link } from 'react-router-dom';

const Card: React.FC<CardCombinedProps> = (props) => {
  const {
    type,
    format,
    className,
    classes,
    image,
    eventInfo,
    icon,
    button,
    day,
    date,
    year,
    time,
    performerInfo,
    teamHomeAwayImage,
    ticketStatus,
    // MANUAL OVERRIDE STARTS
    linkPath,
    ariaLabel,
    handleCardClick,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  // MANUAL OVERRIDE
  const linkPathValue = linkPath || '';

  const variantName = `${type}${format}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`card${variantName}`];

  let componentView;

  switch (variantName) {
    case 'DefaultLong':
    case 'ExclusiveLong': {
      componentView = (
        // MANUAL OVERRIDE - replace div with Link
        <Link
          data-item="true"
          title={ariaLabel}
          to={{ pathname: linkPathValue, state: { canGoBack: true } }}
          className={cx(currentStyle, className)}
        >
          <Image
            className={cx(styles.image, classes?.image)}
            {...internalProps.image}
            {...image}/>
          <div className={cx(styles.content, classes?.content)}>
            <EventInfo
              className={cx(styles.eventInfo, classes?.eventInfo)}
              {...internalProps.eventInfo}
              {...eventInfo}/>
            <Icon
              className={cx(styles.icon, classes?.icon)}
              {...internalProps.icon}
              {...icon}/>
            <Button
              className={cx(styles.button, classes?.button)}
              {...internalProps.button}
              {...button}/>
          </div>
        </Link>
      );
      break;
    }
    case 'DefaultSquare': {
      componentView = (
        // MANUAL OVERRIDE - replace div with Link
        <Link
          data-item="true"
          title={ariaLabel}
          to={{ pathname: linkPathValue, state: { canGoBack: true } }}
          className={cx(currentStyle, className)}
        >
          <Image
            className={cx(styles.image, classes?.image)}
            {...internalProps.image}
            {...image}/>
          <div className={cx(styles.content, classes?.content)}>
            <EventInfo
              className={cx(styles.eventInfo, classes?.eventInfo)}
              {...internalProps.eventInfo}
              {...eventInfo}/>
            {/* MANUAL OVERRIDE - added icon */}
            <Icon
              className={cx(styles.icon, classes?.icon)}
              {...internalProps.icon}
              {...icon}/>
          </div>
        </Link>
      );
      break;
    }
    case 'NoThumbnailImageLong': {
      componentView = (
        // MANUAL OVERRIDE - replace div with Link
        <Link
          data-item="true"
          title={ariaLabel}
          to={{ pathname: linkPathValue, state: { canGoBack: true } }}
          className={cx(currentStyle, className)}
        >
          <div className={cx(styles.leftContent, classes?.leftContent)}>
            <Text
              className={cx(styles.day, classes?.day)}
              {...internalProps.day}
              {...day}/>
            <Text
              className={cx(styles.date, classes?.date)}
              {...internalProps.date}
              {...date}/>
            <Text
              className={cx(styles.year, classes?.year)}
              {...internalProps.year}
              {...year}/>
            <Text
              className={cx(styles.time, classes?.time)}
              {...internalProps.time}
              {...time}/>
          </div>
          <div className={cx(styles.content, classes?.content)}>
            <PerformerInfo
              className={cx(styles.performerInfo, classes?.performerInfo)}
              {...internalProps.performerInfo}
              {...performerInfo}/>
            <Button
              className={cx(styles.button, classes?.button)}
              {...internalProps.button}
              {...button}/>
            <Icon
              className={cx(styles.icon, classes?.icon)}
              {...internalProps.icon}
              {...icon}/>
          </div>
        </Link>
      );
      break;
    }
    case 'TeamsLong': {
      componentView = (
        // MANUAL OVERRIDE - replace div with Link
        <Link
          data-item="true"
          title={ariaLabel}
          to={{ pathname: linkPathValue, state: { canGoBack: true } }}
          className={cx(currentStyle, className)}
        >
          <TeamHomeAwayImage
            className={cx(styles.teamHomeAwayImage, classes?.teamHomeAwayImage)}
            {...internalProps.teamHomeAwayImage}
            {...teamHomeAwayImage}/>
          <div className={cx(styles.content, classes?.content)}>
            <EventInfo
              className={cx(styles.eventInfo, classes?.eventInfo)}
              {...internalProps.eventInfo}
              {...eventInfo}/>
            <Icon
              className={cx(styles.icon, classes?.icon)}
              {...internalProps.icon}
              {...icon}/>
            <Button
              className={cx(styles.button, classes?.button)}
              {...internalProps.button}
              {...button}/>
          </div>
        </Link>
      );
      break;
    }
    case 'TicketStatusLong': {
      componentView = (
        // MANUAL OVERRIDE - added on click event
        <div className={cx(currentStyle, className)} onClick={handleCardClick}>
          <Image
            className={cx(styles.image, classes?.image)}
            {...internalProps.image}
            {...image}/>
          <div className={cx(styles.content, classes?.content)}>
            <TicketStatus
              className={cx(styles.ticketStatus, classes?.ticketStatus)}
              {...internalProps.ticketStatus}
              {...ticketStatus}/>
          </div>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default Card;


import { useEffect, useState } from 'react';
import { displayLeagues, generateSportsMenuItem, getInitialItems, populateWithChildren } from '../../../modules/navigation/utils';
import { NavMenuItemCombinedProps } from '../../molecules/NavMenuItem/types';
import { NavMenuListCombinedProps } from './types';
import { useTranslation } from 'react-i18next';
import { useAnalyticsManager } from '../../../modules/analytics/useAnalyticsManager';
import { useLocation } from 'react-router-dom';
import { MobileMenuItem, MobileMenuState, TopNavTab } from '../../../modules/navigation/types';
import categoryEvents from '../../../modules/navigation/CategoryEvents.json';

export const leagueSlugsWithAllLink = ['/nhl', '/nfl', '/nba', '/mls', '/mlb'];

const usePresenter = (props: NavMenuListCombinedProps): NavMenuListCombinedProps => {
  const { setIsSelectedNavMenuItem } = props;
  const data: TopNavTab[] = JSON.parse(JSON.stringify(categoryEvents));
  const { t } = useTranslation();
  const { trackEvent } = useAnalyticsManager();
  const location = useLocation();
  const initialMenuState: MobileMenuState = { items: getInitialItems(data) };
  const [ menuState, setMenuState ] = useState(initialMenuState);

  useEffect(() => {
    setMenuState(initialMenuState);
    setIsSelectedNavMenuItem?.(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const onItemClick = (id: string) => {
    // check for case when selecting to reverse
    if (id === menuState.selected?.id) {
      if (menuState.selected?.type === 'league') {
        setIsSelectedNavMenuItem?.(true);
        return setMenuState({
          selected: generateSportsMenuItem(),
          items: displayLeagues(data),
        });
      }
      if (menuState.selected?.type === 'category') {
        setIsSelectedNavMenuItem?.(false);
        return setMenuState(initialMenuState);
      }
    }

    const selectedItem = menuState.items.find((item) => item.id === id);
    let items: MobileMenuItem[] | undefined;
    if (selectedItem) {
      // display the submenu items for particular category or league
      items = populateWithChildren(data, selectedItem.type, id);
    }
    setMenuState({ selected: selectedItem, items: items ? items : [] });
    setIsSelectedNavMenuItem?.(!!selectedItem);
  };

  // construct the mobile navigation links
  const menuListItems: NavMenuItemCombinedProps[] = [];
  if (menuState.selected) {
    // push item that allows user to go back
    menuListItems.push({
      type: 'Back',
      title: {
        value: menuState.selected.title,
      },
      backButton: {
        icon: {
          asset: 'ChevronLeft',
          style: 'ActionBase',
        },
      },
      onClick: () => {
        if (menuState.selected) {
          onItemClick(menuState.selected.id);
        }
      },
    });
    const {
      selected: { id, title, type },
    } = menuState;
    // add extra menu list item for certain leagues with 'All' prefix
    if (type === 'league' && leagueSlugsWithAllLink.includes(id)) {
      menuListItems.push({
        type: 'Default',
        title: {
          value: `${t('search.all')} ${title}`,
        },
        linkPath: `${id}`,
      });
    }
  }

  menuState.items.forEach((item) => {
    menuListItems.push({
      type: item.type === 'item' ? 'Default' : 'Next',
      title: {
        value: item.title,
      },
      onClick: () => {
        if (item.type === 'item') {
          if (item.id.startsWith('/exclusive-events') && item.title !== 'Presales') {
            trackEvent('select_content_c1x_navigation-menu');
          }
          
          setMenuState(initialMenuState);
          return;
        }

        onItemClick(item.id);
      },
      nextButton: {
        icon: {
          asset: 'ChevronRight',
          style: 'DigitalGrey100',
        },
      },
      newTab: item.title === 'Presales',
      linkPath: item.type === 'item' ? item.id : undefined,
    });
  });
  return {
    ...props,
    navMenuItems: menuListItems,
  };
};

export default usePresenter;

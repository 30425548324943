import { EventKeys } from './types';

export const trackSelectContentEvent = (
  trackEvent: (event: EventKeys, extraData?: Record<string, unknown>) => void,
  contentType: string,
  clickLocation: string,
  clickText?: string,
  extraData?: Record<string, unknown>,
) => {
  const data = {
    content_type: contentType,
    click_location: clickLocation,
    click_text: clickText,
    ...extraData,
  };
  trackEvent('select_content', data);
};

export const trackErrorEvent = (
  trackEvent: (event: EventKeys, extraData?: Record<string, unknown>) => void,
  code: string | number,
  message: string,
  extraData?: Record<string, unknown>,
) => {
  const errorPageUrl = window.location.href;
  const data = {
    error_code: code,
    error_message: message,
    error_url: errorPageUrl,
    ...extraData,
  };
  trackEvent('error', data);
};

export const trackPageViewEvent = (
  trackEvent: (event: EventKeys, extraData?: Record<string, unknown>) => void,
  pageType: string,
  extraData?: Record<string, unknown>,
) => {
  const pageTitle = document.title;
  const pageUrl = window.location.href;
  const data = {
    page_title: pageTitle,
    url_path: pageUrl,
    page_type: pageType,
    ...extraData,
  };
  trackEvent('page_view', data);
};

export const trackScreenViewEvent = (
  trackEvent: (event: EventKeys, extraData?: Record<string, unknown>) => void,
  pageType: string,
  extraData?: Record<string, unknown>,
) => {
  const pageTitle = document.title;
  const pageUrl = window.location.href;
  const data = {
    page_title: pageTitle,
    url_path: pageUrl,
    page_type: pageType,
    ...extraData,
  };
  trackEvent('screen_view', data);
};
import { ButtonProps } from '../../atoms/Button';
import { ImageProps } from '../../atoms/Image';
import { TextProps } from '../../atoms/Text';


type ErrorBlockInternalProps = {
  image?: ImageProps;
  title?: TextProps;
  message?: TextProps;
  button?: ButtonProps;
  signOutButton?: ButtonProps; // MANUAL OVERRIDE
};

type ErrorBlockPropsValues = {
  '': ErrorBlockInternalProps;
};

const propValues: ErrorBlockPropsValues = {
  '': {
    image: {
    },
    title: {
      type: 'Subheading',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Center', // MANUAL OVERRIDE
    },
    message: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Center',
    },
    button: {
      type: 'Text',
      style: 'Contained',
      size: 'Large',
    },
    // MANUAL OVERRIDE STARTS
    signOutButton: {
      type: 'Text',
      style: 'Text',
      size: 'Medium',
    },
    // MANUAL OVERRIDE ENDS
  },
};

const getProps = (type: string): ErrorBlockInternalProps => {
  const values: ErrorBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import React from 'react';
import cx from 'classnames';

import FAQItem from '../../molecules/FAQItem';

import styles from './FAQList.module.scss';
import usePresenter from './FAQList.presenter';
import getProps from './FAQList.props';
import { FAQListCombinedProps } from './types';


const FAQList: React.FC<FAQListCombinedProps> = (props) => {
  const {
    className,
    classes,
    fAQItems,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.fAQList;

  const fAQItemViews = fAQItems?.map((fAQItem, index) => (
    <FAQItem
      key={index}
      className={cx(styles.fAQItem, classes?.fAQItem)}
      {...internalProps.fAQItem}
      {...fAQItem} />
  )) || [];

  return (
    <div className={cx(currentStyle, className)}>
      {fAQItemViews}
    </div>
  );
};

export default FAQList;


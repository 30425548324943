import { TextProps } from '../../atoms/Text';
import { ButtonProps } from '../../atoms/Button';


type LegalBlockInternalProps = {
  blockTitle?: TextProps;
  description?: TextProps;
  button?: ButtonProps;
};

type LegalBlockPropsValues = {
  '': LegalBlockInternalProps;
};

const propValues: LegalBlockPropsValues = {
  '': {
    blockTitle: {
      type: 'Subheading',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    description: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
    button: {
      type: 'Text',
      style: 'ContainedGreen',
      size: 'Large',
    },
  },
};

const getProps = (type: string): LegalBlockInternalProps => {
  const values: LegalBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import { TextProps } from '../../atoms/Text';
import { OrderDetailsProps } from '../../organisms/OrderDetails';


type ReceiptBlockInternalProps = {
  title?: TextProps;
  orderDetails?: OrderDetailsProps;
};

type ReceiptBlockPropsValues = {
  '': ReceiptBlockInternalProps;
};

const propValues: ReceiptBlockPropsValues = {
  '': {
    title: {
      type: 'Subheading',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    orderDetails: {
    },
  },
};

const getProps = (type: string): ReceiptBlockInternalProps => {
  const values: ReceiptBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

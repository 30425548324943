import React from 'react';
import cx from 'classnames';

import ClickableSection from '../../atoms/ClickableSection';
import Divider from '../../atoms/Divider';
import Image from '../../atoms/Image';
import Text from '../../atoms/Text';

import styles from './TicketAvailabilityCardSoldOut.module.scss';
import usePresenter from './TicketAvailabilityCardSoldOut.presenter';
import getProps from './TicketAvailabilityCardSoldOut.props';
import { TicketAvailabilityCardSoldOutCombinedProps } from './types';


const TicketAvailabilityCardSoldOut: React.FC<TicketAvailabilityCardSoldOutCombinedProps> = (props) => {
  const {
    className,
    classes,
    image,
    label,
    message,
    divider,
    clickableSection,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.ticketAvailabilityCardSoldOut;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.content, classes?.content)}>
        <div className={cx(styles.mainMessage, classes?.mainMessage)}>
          <Image
            className={cx(styles.image, classes?.image)}
            {...internalProps.image}
            {...image}/>
          <Text
            className={cx(styles.label, classes?.label)}
            {...internalProps.label}
            {...label}/>
          <Text
            className={cx(styles.message, classes?.message)}
            {...internalProps.message}
            {...message}/>
        </div>
        <Divider
          className={cx(styles.divider, classes?.divider)}
          {...internalProps.divider}
          {...divider}/>
      </div>
      <ClickableSection
        className={cx(styles.clickableSection, classes?.clickableSection)}
        {...internalProps.clickableSection}
        {...clickableSection}/>
    </div>
  );
};

export default TicketAvailabilityCardSoldOut;


import React from 'react';
import cx from 'classnames';
import MaintenanceMaintenanceAsset from '../../../resources/icons/Maintenance.svg';

import styles from './MaintenanceBlock.module.scss';
import Image from '../../atoms/Image';
import Text from '../../atoms/Text';

import { useTranslation } from 'react-i18next';
import { TextCombinedProps } from '../../atoms/Text';
import getProps from './MaintenanceBlock.props';
import { MaintenanceBlockCombinedProps } from './types';
import usePresenter from './MaintenanceBlock.presenter';


const MaintenanceBlock: React.FC<MaintenanceBlockCombinedProps> = (props) => {
  const {
    className,
    classes,
    image,
    title,
    message,
  } = usePresenter(props);

  const { t } = useTranslation();
  const titleProps: TextCombinedProps = {
    value: t('maintenance.title'),
  };

  const content: React.ReactNode[] = [
    t('maintenance.message'),
  ];

  const messageProps: TextCombinedProps = {
    value: content,
  };

  const internalProps = getProps('');
  const currentStyle = styles.maintenanceBlock;
  return (
    <div className={cx(currentStyle, className)}>
      <Image
        className={cx(styles.image, classes?.image)}
        imageSrc={MaintenanceMaintenanceAsset}
        {...internalProps.image}
        {...image}
      />
      <div className={styles.content}>
        <Text
          className={cx(styles.title, classes?.title)}
          {...title}
          {...internalProps.title}
          {...titleProps} />
        <Text
          className={cx(styles.message, classes?.message)}
          {...message}
          {...internalProps.message}
          {...messageProps} />
      </div>
    </div>
  );
};

export default MaintenanceBlock;

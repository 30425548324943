import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';

import styles from './FooterMenuList.module.scss';
import usePresenter from './FooterMenuList.presenter';
import getProps from './FooterMenuList.props';
import { FooterMenuListCombinedProps } from './types';


const FooterMenuList: React.FC<FooterMenuListCombinedProps> = (props) => {
  const {
    className,
    classes,
    buttons,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.footerMenuList;

  const buttonViews = buttons?.map((button, index) => (
    <Button
      key={index}
      className={cx(styles.button, classes?.button)}
      {...internalProps.button}
      {...button} />
  )) || [];

  return (
    <div className={cx(currentStyle, className)}>
      {buttonViews}
    </div>
  );
};

export default FooterMenuList;


import React from 'react';
import cx from 'classnames';

import ToggleItem from '../../atoms/ToggleItem';

import styles from './ToggleItemList.module.scss';
import usePresenter from './ToggleItemList.presenter';
import getProps from './ToggleItemList.props';
import { ToggleItemListCombinedProps } from './types';


const ToggleItemList: React.FC<ToggleItemListCombinedProps> = (props) => {
  const {
    className,
    classes,
    toggleItems,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.toggleItemList;

  const toggleItemViews = toggleItems?.map((toggleItem, index) => (
    <ToggleItem
      key={index}
      className={cx(styles.toggleItem, classes?.toggleItem)}
      {...internalProps.toggleItem}
      {...toggleItem} />
  )) || [];

  return (
    <div className={cx(currentStyle, className)}>
      {toggleItemViews}
    </div>
  );
};

export default ToggleItemList;


import { useHistory } from 'react-router-dom';
import { SearchReturnItemCombinedProps } from './types';

const usePresenter = (props: SearchReturnItemCombinedProps): SearchReturnItemCombinedProps => {
  const { onClick, linkPath } = props;
  const history = useHistory();
  const clickAction = () => {
    if (linkPath) {
      history.push(linkPath);
    }
    if (onClick) {
      onClick();
    }
  };
  return {
    ...props,
    onClick: clickAction,
  };
};

export default usePresenter;

import React from 'react';
import cx from 'classnames';


import styles from './Image.module.scss';
import usePresenter from './Image.presenter';
import getProps from './Image.props';
import { ImageCombinedProps } from './types';


const Image: React.FC<ImageCombinedProps> = (props) => {
  const {
    className,
    classes,
    imageSrc,
    imageSrcAlt,
    imageFallback,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.image;

  return (
    <div className={cx(currentStyle, className)} style={{ backgroundImage: `url(${imageSrc}), url(${imageFallback})` }}/>
  );
};

export default Image;


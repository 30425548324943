import React from 'react';
import cx from 'classnames';

import Icon from '../../atoms/Icon';
import Text from '../../atoms/Text';
import { getClickAccessibilityProps } from '../../organisms/TopNav/util';
import Content from '../Content';

import styles from './RadioItem.module.scss';
import usePresenter from './RadioItem.presenter';
import getProps from './RadioItem.props';
import { RadioItemCombinedProps } from './types';


const RadioItem: React.FC<RadioItemCombinedProps> = (props) => {
  const {
    type,
    state,
    className,
    classes,
    icon,
    title,
    // MANUAL OVERRIDE - removed text
    content1,
    // MANUAL OVERRIDE STARTS
    ariaLabel,
    onClick,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const variantName = `${type}${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`radioItem${variantName}`];

  // MANUAL OVERRIDE STARTS
  const accessibilityProps = getClickAccessibilityProps(
    {
      onClick,
    },
    true,
    'radio',
  );
  accessibilityProps['aria-label'] = ariaLabel;
  // hide from accessbility when editing reward points
  if (content1?.state === 'Edit') {
    accessibilityProps.role = 'none';
    accessibilityProps.tabIndex = -1;
  } else {
    // added checked indicator
    accessibilityProps['aria-checked'] = state === 'Selected';
  }

  // MANUAL OVERRIDE ENDS

  let componentView;

  switch (variantName) {
    case 'ApplyDefault':
    case 'ApplySelected': {
      componentView = (
        // MANUAL OVERRIDE - to announce the details for screen readers
        <div className={cx(currentStyle, className)}
          onClick={onClick} 
          {...accessibilityProps}>
          <Icon
            className={cx(styles.icon, classes?.icon)}
            {...internalProps.icon}
            {...icon}/>
          <div className={cx(styles.card, classes?.card)}>
            <div className={cx(styles.content, classes?.content)}>
              <Text
                className={cx(styles.title, classes?.title)}
                {...internalProps.title}
                {...title}/>
              {/* MANUAL OVERRIDE - removed text */}
            </div>
            <Content
              className={cx(styles.content1, classes?.content1)}
              {...internalProps.content1}
              {...content1}/>
          </div>
        </div>
      );
      break;
    }
    case 'NotApplyDefault':
    case 'NotApplySelected': {
      componentView = (
        // MANUAL OVERRIDE - to announce the details for screen readers
        <div className={cx(currentStyle, className)}
          onClick={onClick}  
          {...accessibilityProps}>
          <Icon
            className={cx(styles.icon, classes?.icon)}
            {...internalProps.icon}
            {...icon}/>
          <div className={cx(styles.card, classes?.card)}>
            <div className={cx(styles.content, classes?.content)}>
              <Text
                className={cx(styles.title, classes?.title)}
                {...internalProps.title}
                {...title}/>
            </div>
          </div>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default RadioItem;


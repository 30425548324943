import { handleVariableImage, setEventDescription } from '../../../lib/util';
import { EventDetailsModalCombinedProps } from './types';

const usePresenter = (props: EventDetailsModalCombinedProps): EventDetailsModalCombinedProps => {
  const { event, closeModal } = props;

  return {
    ...props,
    button: {
      onClick: closeModal,
    },
    bannerImage: {
      imageSrc: event && handleVariableImage(event, 'modal', 'desktop'),
    },
    bannerImageMobile: {
      imageSrc: event && handleVariableImage(event, 'modal', 'mobile'),
    },
    title: {
      value: event?.venue.name,
    },
    title1: {
      value: event?.name,
    },
    eventInfo: {
      value: event && setEventDescription(event),
    },
    eventInfoMobile: {
      value: event && setEventDescription(event, true),
    },
    description: {
      value: event?.supplemental_data && event.supplemental_data.event_description ? event.supplemental_data.event_description : event?.description,
    },
  };
};

export default usePresenter;

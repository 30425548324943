import { SearchCategoriesProps } from '../../molecules/SearchCategories';
import { SearchReturnListProps } from '../../organisms/SearchReturnList';


type SearchResultsBlockInternalProps = {
  searchCategories?: SearchCategoriesProps;
  searchReturnList?: SearchReturnListProps;
  searchCategories1?: SearchCategoriesProps;
  searchReturnList1?: SearchReturnListProps;
  searchCategories2?: SearchCategoriesProps;
  searchReturnList2?: SearchReturnListProps;
  searchCategories3?: SearchCategoriesProps;
  searchReturnList3?: SearchReturnListProps;
};

type SearchResultsBlockPropsValues = {
  '': SearchResultsBlockInternalProps;
};

const propValues: SearchResultsBlockPropsValues = {
  '': {
    searchCategories: {
      type: 'DividerBottom',
    },
    searchReturnList: {
    },
    searchCategories1: {
      type: 'DividerBottom',
    },
    searchReturnList1: {
    },
    searchCategories2: {
      type: 'DividerBottom',
    },
    searchReturnList2: {
    },
    searchCategories3: {
      type: 'DividerBottom',
    },
    searchReturnList3: {
    },
  },
};

const getProps = (type: string): SearchResultsBlockInternalProps => {
  const values: SearchResultsBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

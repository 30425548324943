import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import Divider from '../../atoms/Divider';
import Icon from '../../atoms/Icon';

import styles from './CardNavigation.module.scss';
import usePresenter from './CardNavigation.presenter';
import getProps from './CardNavigation.props';
import { CardNavigationCombinedProps } from './types';


const CardNavigation: React.FC<CardNavigationCombinedProps> = (props) => {
  const {
    type,
    style,
    className,
    classes,
    button,
    divider,
    icon,
  } = usePresenter(props);

  const variantName = `${type}${style}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`cardNavigation${variantName}`];

  let componentView;

  switch (variantName) {
    case 'DefaultLightBackground': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.content, classes?.content)}>
            <Button
              className={cx(styles.button, classes?.button)}
              {...internalProps.button}
              {...button}/>
          </div>
          <Divider
            className={cx(styles.divider, classes?.divider)}
            {...internalProps.divider}
            {...divider}/>
        </div>
      );
      break;
    }
    case 'WithCloseOptionLightBackground': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.content, classes?.content)}>
            <Button
              className={cx(styles.button, classes?.button)}
              {...internalProps.button}
              {...button}/>
            <Icon
              className={cx(styles.icon, classes?.icon)}
              {...internalProps.icon}
              {...icon}/>
          </div>
          <Divider
            className={cx(styles.divider, classes?.divider)}
            {...internalProps.divider}
            {...divider}/>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default CardNavigation;


import { ButtonProps } from '../../atoms/Button';
import { DividerProps } from '../../atoms/Divider';
import { TextProps } from '../../atoms/Text';
import { TextInputProps } from '../../atoms/TextInput';


type ContentInternalProps = {
  textRedeem?: TextProps;
  texMiles?: TextProps;
  textMilesFor?: TextProps;
  textDollar?: TextProps;
  button?: ButtonProps;
  textInput?: TextInputProps;
  saveButton?: ButtonProps;
  divider?: DividerProps;
  cancelButton?: ButtonProps;
};

type ContentPropsValues = {
  'Default': ContentInternalProps;
  'Edit': ContentInternalProps;
};

const propValues: ContentPropsValues = {
  'Default': {
    textRedeem: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    texMiles: {
      type: 'Body',
      size: 'Medium',
      style: 'SemiBold',
      colour: 'PositiveLight',
      align: 'Left',
    },
    textMilesFor: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    textDollar: {
      type: 'Body',
      size: 'Medium',
      style: 'SemiBold',
      colour: 'PositiveLight',
      align: 'Left',
    },
    button: {
      type: 'Text',
      style: 'Text',
      size: 'Small',
    },
  },
  'Edit': {
    textRedeem: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    textInput: {
      state: 'Filled',
      style: 'DefaultCenter',
      size: 'Regular',
    },
    textMilesFor: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    textDollar: {
      type: 'Body',
      size: 'Medium',
      style: 'SemiBold',
      colour: 'Positive',
      align: 'Left',
    },
    saveButton: {
      type: 'Text',
      style: 'Text',
      size: 'Small',
    },
    divider: {
      type: 'Vertical',
      style: 'Thin',
      colour: 'DigitalGrey15',
    },
    cancelButton: {
      type: 'Text',
      style: 'Text',
      size: 'Small',
    },
  },
};

const getProps = (type: string): ContentInternalProps => {
  const values: ContentInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import { PURCHASE_LIMIT_TAG } from '../../../lib/constants';
import { calculateTicketPrices } from '../../../lib/util';
import type { Event, ListingDetails, UserOrder } from '../../../modules/partnership';
import type { AdditionalTicketInformationListCombinedProps } from '../../molecules/AdditionalTicketInformationList/types';
import type { TicketInformationCombinedProps } from '../../molecules/TicketInformation/types';
import type { TicketQuantitySelectionCombinedProps } from '../../molecules/TicketQuantitySelection/types';

export type TicketProps = {
  ticketInformation?: TicketInformationCombinedProps;
  ticketQuantitySelection?: TicketQuantitySelectionCombinedProps;
  additionalTicketInformationList?: AdditionalTicketInformationListCombinedProps;
};

export function getEventPurchaseLimit(tags: string[]): number {
  const purchaseLimitTag =
    tags.find((tag) => tag.startsWith(PURCHASE_LIMIT_TAG)) || '';
  const purchaseLimitTagDetails = purchaseLimitTag.split(':');

  if (purchaseLimitTagDetails.length) {
    return Number(purchaseLimitTagDetails[1]);
  }

  return NaN;
}

export function findPreviousPurchases(
  previousOrders: UserOrder[],
  eventId: string,
): number {
  let previousTickets = 0;
  previousOrders.forEach((order) => {
    if (order.event_id === Number(eventId)) {
      previousTickets += order.quantity;
    }
  });
  return previousTickets;
}

export function formatPoints(pointsValue: number, isMiles: boolean): string {
  return isMiles
    ? pointsValue.toLocaleString()
    : `$${Number(pointsValue.toFixed(2)).toLocaleString()}`;
}

export function determineDeliveryOption(deliveryId: number): number {
  switch (deliveryId) {
    case 19:
      return 20;
    case 31:
      return 32;
    default:
      return deliveryId;
  }
}

export const calculateListingDetailsPrices = (listingDetails?: ListingDetails, overrideShowAllInPrice?: boolean) => {
  const showAllInPrice = overrideShowAllInPrice || !!listingDetails?.preselect_delivery_method;
  return calculateTicketPrices(listingDetails?.listing, showAllInPrice);
};

export const checkIsEventInStates = (event: Event | undefined, stateCodes: string[]): boolean => {
  const eventStateCode: string | undefined = event?.venue.state_code;
  return !!eventStateCode && stateCodes.some((stateCode: string) => stateCode.toLowerCase() === eventStateCode.toLowerCase());
};

import { DividerProps } from '../../atoms/Divider';
import { AccountMenuProps } from '../../molecules/AccountMenu';
import { NavMenuListProps } from '../../organisms/NavMenuList';


type AccountMenuBlockInternalProps = {
  accountMenu?: AccountMenuProps;
  navMenuList?: NavMenuListProps;
  divider?: DividerProps;
  profileMenuList?: NavMenuListProps;
};

type AccountMenuBlockPropsValues = {
  '': AccountMenuBlockInternalProps;
};

const propValues: AccountMenuBlockPropsValues = {
  '': {
    accountMenu: {
      state: 'Expanded',
    },
    navMenuList: {
    },
    divider: {
      type: 'Horizontal',
      style: 'Thin',
      colour: 'DigitalGrey10',
    },
    profileMenuList: {
    },
  },
};

const getProps = (type: string): AccountMenuBlockInternalProps => {
  const values: AccountMenuBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import { TextProps } from '../../atoms/Text';
import { EventTypeTagProps } from '../../atoms/EventTypeTag';


type CXEventInfoHeaderInternalProps = {
  eventTypeTag?: EventTypeTagProps;
  title?: TextProps;
  date?: TextProps;
  time?: TextProps;
  location?: TextProps;
};

type CXEventInfoHeaderPropsValues = {
  '': CXEventInfoHeaderInternalProps;
};

const propValues: CXEventInfoHeaderPropsValues = {
  '': {
    eventTypeTag: {
      type: 'CardholderExclusive',
    },
    title: {
      type: 'NewEventDetailsTitle',
      size: 'Large',
      style: 'SemiBold',
      colour: 'BaseLight',
      align: 'Left',
    },
    date: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'Charcoal10',
      align: 'Left',
    },
    time: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'Charcoal10',
      align: 'Left',
    },
    location: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'Charcoal10',
      align: 'Left',
    },
  },
};

const getProps = (type: string): CXEventInfoHeaderInternalProps => {
  const values: CXEventInfoHeaderInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

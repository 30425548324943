import React, { useContext } from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import Text from '../../atoms/Text';
import SliderFilter from '../../molecules/SliderFilter';
import TicketQuantity from '../../molecules/TicketQuantity';
import ToggleSetting from '../../molecules/ToggleSetting';

import styles from './FilterSetting.module.scss';
import usePresenter from './FilterSetting.presenter';
import getProps from './FilterSetting.props';
import { FilterSettingCombinedProps } from './types';


const FilterSetting: React.FC<FilterSettingCombinedProps> = (props) => {
  const {
    className,
    classes,
    label,
    button,
    ticketQuantity,
    sliderFilter,
    toggleSetting,
    // MANUAL OVERRIDE STARTS
    isExclusive,
    showSlider,
    handleClick,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.filterSetting;

  // MANUAL OVERRIDE STARTS
  let sliderFilterView;
  if (!isExclusive && showSlider) {
    sliderFilterView = (
      <SliderFilter
        className={cx(styles.sliderFilter, classes?.sliderFilter)}
        {...internalProps.sliderFilter}
        {...sliderFilter}/>
    );
  }
  // MANUAL OVERRIDE ENDS

  return (
    <div onClick={handleClick} className={cx(currentStyle, className)}>
      <div className={cx(styles.topContent, classes?.topContent)}>
        <Text
          className={cx(styles.label, classes?.label)}
          {...internalProps.label}
          {...label}/>
        <Button
          className={cx(styles.button, classes?.button)}
          {...internalProps.button}
          {...button}/>
      </div>
      <TicketQuantity
        className={cx(styles.ticketQuantity, classes?.ticketQuantity)}
        {...internalProps.ticketQuantity}
        {...ticketQuantity}/>
      {/* MANUAL OVERRIDE */}
      {sliderFilterView}
      {/* MANUAL OVERRIDE */}
      {/* <ToggleSetting
        className={cx(styles.toggleSetting, classes?.toggleSetting)}
        {...internalProps.toggleSetting}
        {...toggleSetting}/> */}
    </div>
  );
};

export default FilterSetting;


import { useHistory, useLocation } from 'react-router-dom';
import printJS from 'print-js';
import useInteractor from './MyTicketsPage.interactor';
import { IconDescription, MyTicketsPageCombinedProps, MyTicketsPageValueProps } from './types';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import { TopNavTab } from '../../../modules/navigation/types';
import { Event, UserOrder } from '../../../modules/partnership';
import { DateFormats, addQueryParam, capitalize, formatDate, getFiveDigitsZipCode, getNetworkTagType, getOrderDetailsTranslationKey, getTwoDigitsCountryCode, getUnitDisplaySettings, handleAmountFormattingString, handleDateFormat, handleDecimalValuesForDisplay, handleDisplayUnitName, handleLocation, handleTimeFormat, isAndroidDevice, isEventGuestList, isFirefoxBrowser, isSafariBrowser } from '../../../lib/util';
import { IconTextDescriptionValueProps } from '../../molecules/IconTextDescription';
import { OrderDetailsValueProps } from '../../organisms/OrderDetails';
import myTicketsStyles from './MyTicketsPage.module.scss';
import { REWARDS_ONLY_TAG } from '../../../lib/constants';
import { ticketStatus } from './utils';
import { TabCombinedProps } from '../../atoms/Tab/types';
import { TextColourEnum } from '../../atoms/Text/types';
import { formatTitle, formatter } from '../CheckoutManualPage/CheckoutManualPage.utils';
import CardAsset from '../../../resources/legacyIcons/CreditCardBlack.svg';
import { CardCombinedProps } from '../../molecules/Card/types';
import { useAnalyticsManager } from '../../../modules/analytics/useAnalyticsManager';
import { trackErrorEvent, trackPageViewEvent, trackScreenViewEvent, trackSelectContentEvent } from '../../../modules/analytics/util';
import { ApiError } from '../../../modules/error/types';

const usePresenter = (props: MyTicketsPageCombinedProps): MyTicketsPageCombinedProps => {
  const { data, account, downloadTickets, getEvent, pageLoading } = useInteractor();
  const history = useHistory();
  const { search } = useLocation();
  const { t } = useTranslation();

  const { trackEvent } = useAnalyticsManager();

  useEffect(() => {
    trackPageViewEvent(
      trackEvent,
      'My Tickets Page',
    );
  }, []);

  const shouldDownloadPDF = isAndroidDevice() || isSafariBrowser() || isFirefoxBrowser();

  const upComingEvents: TopNavTab = {
    id: '', // id no id then use up-coming events
    name: t('myTickets.upComingEvents'),
    children: [],
  };
  const pastEvents: TopNavTab = {
    id: 'past',
    name: t('myTickets.pastEvents'),
    children: [],
  };
  const categories: TopNavTab[] = [upComingEvents, pastEvents];
  const [selectedTab, setSelectedTab] = useState(
    categories ? categories[0].id : 'all',
  );
  const [myTickets, setMyTickets] = useState<UserOrder[]>([]);
  const isUpComingTab = useRef(false); // component wise variable whose value remains consistent on re-renders

  const [pdfurl, setUrl] = useState('');
  const [viewOrderModal, setViewOrderModal] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState<
  { order: UserOrder; orderConfirmed: boolean } | undefined
  >();
  const [isGuestList, setIsGuestList] = useState<boolean>();
  const rewardType =
    account?.loyalty_program?.loyalty_unit_name.toLowerCase();
  const { rewardSign, useDecimals } = getUnitDisplaySettings(rewardType);
  /* isRewardsOnly is used to check if the event has a tag 'LOYALTY_REWARDS_PAYMENT'
  and if it does then we are hiding PaymentMethod and Amount in $ from MyTicket Modal.*/
  const [isRewardsOnly, setIsRewardsOnly] = useState<boolean>();
  /* isRewardsOnlyPayment check is added if payment is made using only the loyalty unit (for Split Payment Events)
  in this case also need to hide both blocks of payment and amount in $ from MyTicket Modal.*/
  const [isRewardsOnlyPayment, setIsRewardsOnlyPayment] = useState<boolean>();
  const handleAdditonalInfo = (
    order: UserOrder,
  ): IconTextDescriptionValueProps[] => {
    const confirmationPagePath: string = getOrderDetailsTranslationKey(
      order.stock_type,
      String(order.delivery.id),
      'confirmation_page',
    );
    let iconDescriptionList: IconDescription[] = [];
    const confirmationPageOptions: string[] = t(`${confirmationPagePath}`, {
      customerEmail: order.email,
      customerMobilePhone: account?.phone,
      customerShippingAddress: t(
        'deliveryInformation.ticketDelivery.customerAddressTemplate',
        {
          address: account?.address.address_line1,
          city: account?.address.city,
          state: account?.address.state_code,
          postalCode: account
            ? getFiveDigitsZipCode(
              account.address.country_code,
              account.address.postal_code,
            )
            : '',
          country: getTwoDigitsCountryCode(
            account?.address.country_code || '',
          ),
        },
      ),
      returnObjects: true,
    });
    if (isGuestList) {
      iconDescriptionList = [
        {
          asset: undefined,
          description: `${t(
            'orderConfirmation.guestListEventOrderNotes.title',
          )}`,
        },
        {
          asset: undefined,
          description: `• ${t(
            'orderConfirmation.guestListEventOrderNotes.email',
            {
              email: order.email,
            },
          )}`,
        },
        {
          asset: undefined,
          description: `• ${t(
            'orderConfirmation.guestListEventOrderNotes.name',
          )}`,
        },
        {
          asset: undefined,
          description: `• ${t(
            'orderConfirmation.guestListEventOrderNotes.onSiteEvent',
          )}`,
        },
        {
          asset: undefined,
          description: `• ${t(
            'orderConfirmation.guestListEventOrderNotes.virtualEvent',
          )}`,
        },
      ];
    } else if (
      confirmationPageOptions &&
      typeof confirmationPageOptions !== 'string'
    ) {
      // No stocktype for old orders
      iconDescriptionList = confirmationPageOptions?.map((option) => {
        return {
          asset: undefined,
          description: `• ${option}`,
        };
      });
      iconDescriptionList.unshift({
        asset: undefined,
        description: t(
          getOrderDetailsTranslationKey(
            order.stock_type,
            String(order.delivery.id),
            'delivery_header',
          ),
        ),
      });
    }

    const iconTextDescriptionProps: IconTextDescriptionValueProps[] =
      iconDescriptionList?.map((iconDescription): IconTextDescriptionValueProps => {
        return {
          icon: iconDescription.asset
            ? {
              asset: iconDescription.asset,
            }
            : {
              className: myTicketsStyles.hideIcons,
            },
          description: {
            value: iconDescription.description,
          },
        };
      });
    return iconTextDescriptionProps;
  };

  useEffect(() => {
    const doFetchEvent = async () => {
      try {
        if (getEvent && selectedTicket) {
          const response: Event = await getEvent(
            selectedTicket.order.event_id.toString(),
          );
          setIsRewardsOnly(response?.tags?.includes(REWARDS_ONLY_TAG));
          setIsGuestList(
            isEventGuestList(
              response?.tags || [],
              selectedTicket.order.stock_type,
              selectedTicket.order.delivery.id,
            ),
          );
        }
      } catch (error) {
        if (error && ApiError.isApiError(error)) {
          trackErrorEvent(
            trackEvent,
            error.code,
            error.message,
          );
        }
        // TODO
      }
    };
    void doFetchEvent();
  }, [selectedTicket, getEvent]);

  useEffect(() => {
    let filterdTickets: UserOrder[] | undefined = [];
    const currentDate = new Date().getTime();
    if (search === '?category=past') {
      setSelectedTab('past');
      isUpComingTab.current = false;
      filterdTickets = data?.orders.filter(
        (item) =>
          new Date(item?.event?.utc_date || '').getTime() < currentDate,
      );
    } else {
      isUpComingTab.current = true;
      filterdTickets = data?.orders.filter(
        (item) =>
          new Date(item?.event?.utc_date || '').getTime() >= currentDate,
      );
    }
    setMyTickets(filterdTickets || []);
  }, [search, data]);

  const handleTabClick = (id: string) => {
    setSelectedTab(id);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const newSearch = addQueryParam(search, {
      category: id,
    });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    history.push({
      search: newSearch,
    });
  };
  const tabList: TabCombinedProps[] = [];
  // const categoryDropdownOptions: DropdownOption[] = [];
  if (categories) {
    categories.forEach((item) => {
      const selectedCategory = item.id === selectedTab;
      // categoryDropdownOptions.push({
      //   title: item.name,
      //   type: 'SelectValue',
      //   value: {
      //     value: item.id,
      //   },
      // });

      tabList.push({
        type: selectedCategory ? 'Active' : 'Default',
        text: {
          value: item.name,
        },
        onClick: () => {
          trackSelectContentEvent(
            trackEvent,
            'MyTickets Page',
            'Tab',
            item.name,
          );
          handleTabClick(item.id);
        },
      });
    });
  }

  const handleCardClick = (order: UserOrder) => {
    trackSelectContentEvent(
      trackEvent,
      'MyTickets Page',
      'Order',
      order.event?.name,
    );
    const orderConfirmed =
      (order.ticket_files && order.ticket_files.length) ||
      order.delivery.tracking
        ? true
        : false;
    if (
      order.loyalty_details?.transactions?.length > 0 &&
      order.total_charge.toFixed(2) ===
        order.loyalty_details.transactions[0].transaction_amount_usd.toFixed(
          2,
        )
    ) {
      setIsRewardsOnlyPayment(true);
    } else {
      setIsRewardsOnlyPayment(false);
    }
    setSelectedTicket({ order: order, orderConfirmed: orderConfirmed });
    setViewOrderModal(true);
    trackScreenViewEvent(
      trackEvent,
      'Ticket Details',
    );
  };

  const eventCards = myTickets.map((item): CardCombinedProps => {
    const [ticketStatusText, ticketStatusType, statusTextColor] = ticketStatus(
      item.order_status.orderStatusId,
    );
    return {
      handleCardClick: () => handleCardClick(item),
      type: 'TicketStatus',
      image: {
        imageSrc: item.event?.image,
      },
      ticketStatus: {
        state: 'Negative',
        date: {
          className: myTicketsStyles.dateCreated,
          value: `${t('myTickets.orderDateTitle')} ${new Date(
            item.order_date,
          ).toLocaleDateString('en-US')}`,
        },
        title: {
          value: item.event?.name,
        },
        eventDateStatus: {
          type: 'Default',
          date: {
            value: handleDateFormat(item.event?.local_date || new Date()),
          },
          time: {
            value: t('exclusiveWidget.atTime', {
              time: handleTimeFormat(item.event?.local_date || new Date()),
            }),
          },
        },
        location: {
          value: item.event && handleLocation(item.event.venue),
        },
        quantity: {
          value: t('myTickets.quantity'),
        },
        amount: {
          value: item.quantity,
        },
        tag: {
          type: ticketStatusType,
          label: {
            value: t(ticketStatusText),
            colour: statusTextColor,
          },
        },
      },
      linkPath: `/events/${item.id}`,
    };
  });
  let orderDetailsView: OrderDetailsValueProps = {};
  if (selectedTicket) {
    const downloadButtonText = selectedTicket.order.mobile_scan_enabled
      ? t('orderDetails.download')
      : t('orderDetails.trackOrder');
    orderDetailsView = {
      showCloseButton: true,
      closeButton: {
        icon: {
          asset: 'Close',
          style: 'DigitalGrey80',
        },
        onClick: () => {
          trackSelectContentEvent(
            trackEvent,
            'MyTickets Page',
            'Order Details Modal',
            t('topnav.close'),
          );
          setViewOrderModal(false);
        },
      },
      sellerNotes: {
        label: {
          value: selectedTicket.order.ticket_description
            ? t('orderConfirmation.sellerNotes')
            : '',
        },
        description: {
          value: selectedTicket.order.ticket_description,
        },
      },
      infoHeader: {
        title: {
          value: t('orderConfirmation.header'),
        },
        details: {
          value: t('orderDetails.detailSubHeader', {
            ticketId: selectedTicket.order.id,
            orderDate: formatDate(
              new Date(selectedTicket.order.order_date),
              t,
              DateFormats.CONFIRMATION,
            ),
          }),
        },
      },
      ticketInfoTitle: {
        value: t('orderConfirmation.ticketInfoTitle'),
      },
      showHighlight: true,
      highlightMessage: {
        message: {
          value: t('orderDetails.highlightMessage'),
        },
      },
      printButton: {
        icon: {
          asset: 'Printer',
          style: 'ActionBase',
        },
        text: {
          value: t('orderConfirmation.print'),
        },
        onClick: () => {
          trackSelectContentEvent(
            trackEvent,
            'MyTickets Page',
            'Order Details Modal',
            t('orderConfirmation.print'),
          );
          if (pdfurl) {
            // for android devices the blob pdf url will not open a print dialog on the same browser tab,
            // also, for safari and firefox browsers, print button shows empty as that's a known issue,
            // in order to make it work
            // we are downloading the pdf file so user can open that and print using native print dialog
            if (shouldDownloadPDF) {
              const a = document.createElement('a');
              a.href = pdfurl;
              a.download = `VividSeats_receipt_${selectedTicket?.order?.event?.id}`;
              a.style.position = 'fixed';
              a.target = '_blank';
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
            } else { // for rest of the platforms (desktop, ios)
              printJS(pdfurl);
            }
          }
        },
      },
      infoDetails: {
        title: {
          value: selectedTicket.order.event?.name,
        },
        eventDateStatus: {
          type: 'Default',
          date: {
            value: selectedTicket.order.event?.local_date
              ? formatDate(
                new Date(selectedTicket.order.event?.local_date),
                t,
                DateFormats.TICKETINFO,
              )
              : '',
          },
        },
        venue: {
          value: formatTitle(
            selectedTicket.order.event?.venue.name || '',
            selectedTicket.order.event?.venue.city || '',
            selectedTicket.order.event?.venue.state_code || '',
          ),
        },
      },
      seatInfo: {
        section: {
          label: {
            value: t('ticketInfo.seatInfo.section'),
          },
          description: {
            value: selectedTicket.order.section,
          },
        },
        row: {
          label: {
            value: t('ticketInfo.seatInfo.row'),
          },
          description: {
            value: selectedTicket.order.row,
          },
        },
        quantity: {
          label: {
            value: t('ticketInfo.seatInfo.quantity'),
          },
          description: {
            value: selectedTicket.order.quantity,
          },
        },
      },
      showLogo: false,
      deliveryInfoTitle: {
        value: t('orderConfirmation.deliveryInfoTitle'),
      },
      additionalTicketInformationList: {
        iconTextDescriptions: handleAdditonalInfo(selectedTicket.order),
      },
      deliveryDateInfo: selectedTicket.order.delivery.tracking
        ? {
          style: 'SubduedLabel',
          label: {
            value: 'Tracking Number',
          },
          description: {
            value: selectedTicket.order.delivery.tracking,
          },
        }
        : {},
      showDownloadButton: selectedTicket.orderConfirmed,
      downloadButton: {
        text: {
          value: downloadButtonText,
        },
        href: selectedTicket.order.delivery.tracking
          ? `https://www.ups.com/track?tracknum=${selectedTicket.order.delivery.tracking}`
          : '',
        onClick:
          selectedTicket.order.ticket_files &&
          selectedTicket.order.ticket_files.length
            ? () => {
              trackSelectContentEvent(
                trackEvent,
                'Order Deatails Modal',
                'Button',
                downloadButtonText,
              );
              // eslint-disable-next-line @typescript-eslint/no-unsafe-call
              selectedTicket.order.ticket_files?.map((file) => {
                return void downloadTickets({
                  orderId: String(selectedTicket.order.id),
                  fileName: file,
                });
              });
            }
            : undefined,
        newTab: selectedTicket?.order?.delivery?.tracking ? true : false,
      },
      billingInfoTitle: {
        value: t('orderConfirmation.billingInfoTitle'),
      },
      infoOnMultipleLine: {
        label: {
          value: t('billingInfo.title'),
        },
        address: {
          value: t('billingInfo.smallVersionTemplate', {
            firstName: selectedTicket.order.billingAddress.firstName,
            lastName: selectedTicket.order.billingAddress.lastName,
            address: selectedTicket.order.billingAddress.address1,
            city: selectedTicket.order.billingAddress.city,
            state: selectedTicket.order.billingAddress.state,
            postalCode: getFiveDigitsZipCode(
              selectedTicket.order.billingAddress.country,
              selectedTicket.order.billingAddress.zip,
            ),
            country: selectedTicket.order.billingAddress.country,
          }),
        },
      },
      phoneNumber: {
        label: {
          value: t('billingInfo.phoneNumber'),
        },
        description: {
          value: selectedTicket.order.billingAddress.phoneNumber,
        },
      },
      paymentMethod:
        isRewardsOnly || isRewardsOnlyPayment
          ? undefined
          : {
            cardImage: {
              imageSrc:
                  selectedTicket.order.loyalty_details.program_logo_url,
              imageFallback: CardAsset,
            },
            label: {
              value: t('billingInfo.paymentMethod'),
            },
            description: {
              value: capitalize(getNetworkTagType(account)),
            },
          },
      totalCardPayment:
        isRewardsOnly || isRewardsOnlyPayment
          ? undefined
          : {
            label: {
              value: t('billingInfo.totalCardPayment'),
            },
            description: {
              value: formatter.format(
                (selectedTicket.order.total_charge || 0) -
                    (selectedTicket.order.loyalty_details.transactions[0]
                      ?.transaction_amount_usd || 0),
              ),
            },
          },
      rightContent:
        selectedTicket.order.loyalty_details.transactions &&
        selectedTicket.order.loyalty_details.transactions.length
          ? {
            label: {
              value: t('billingInfo.rewardsRedeemed'),
            },
            description: {
              value: t('billingInfo.rewardsTemplate', {
                value: `${rewardSign}${handleDecimalValuesForDisplay(
                  handleAmountFormattingString(
                    selectedTicket.order.loyalty_details.transactions[0]
                      .transaction_amount,
                  ),
                  useDecimals,
                )}`,
                unit: handleDisplayUnitName(account),
              }),
            },
          }
          : {},
    };
  }
  const pageProps: MyTicketsPageValueProps = {
    myTicketsBlock: {
      isLoading: pageLoading,
      state: eventCards.length ? 'Filled' : 'Empty',
      title: {
        value: t('myTickets.title'),
      },
      tabList: {
        tabs: tabList,
      },
      eventsCardList: {
        cards: eventCards,
      },
      emptyStateBanner: {
        title: {
          value: isUpComingTab.current
            ? t('myTickets.emptyUpComingTitle')
            : t('myTickets.emptyPastTitle'),
        },
        message: {
          value: isUpComingTab.current
            ? t('myTickets.emptyUpComingDescription')
            : t('myTickets.emptyPastDescription'),
        },
        button: {
          text: {
            value: t('myTickets.emptyUpComingButtonText'),
          },
          href: '/',
        },
      },
    },
    viewOrderModal: viewOrderModal,
    orderDetailsProps: orderDetailsView,
    setViewOrderModal: () => setViewOrderModal(false),
    setUrl: setUrl,
    isCp1Exclusive:
      selectedTicket?.order?.event?.tags?.includes('C1_EXCLUSIVE'),
    cardHolderExclusiveLabel: t('orderConfirmation.cardHolderLabel'),
    ticketNotice: t('orderConfirmation.ticketNotice'),
    orderDetailsTitle: t('orderConfirmation.title'),
    isMobileTicket: selectedTicket?.order.mobile_scan_enabled, // Discard once VSCO-433 is implemented
  };

  return {
    ...props,
    ...pageProps,
  };
};

export default usePresenter;

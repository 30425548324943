import React from 'react';
import cx from 'classnames';
import { OverlayTrigger, Tooltip as BootstrapToolTip } from 'react-bootstrap';
import { Placement } from 'react-bootstrap/esm/Overlay';
import './bootstrap.scss';


export type ToolTipProps = {
  className?: string,
  text?: string,
  placement? :Placement,
  children?:React.ReactNode,
  ariaLabel?: string;
  toolTipId?: string;
  toolTipAccessibilityContent?: string;
};

export const defaultProps = {
  text: '',
  placement:'auto' as Placement,
};

const renderToolTip = (props) => (
  <BootstrapToolTip
    id={props.toolTipId}
    role={'tooltip'}
    {...props}
  >
    {props.text}
  </BootstrapToolTip>
);

const ToolTip: React.FC<ToolTipProps> = ({
  text,
  className,
  placement,
  children,
  ariaLabel,
  toolTipId,
  toolTipAccessibilityContent,
}) => {

  return (
    <OverlayTrigger
      key="bottom"
      placement={placement}
      transition={false}
      overlay={renderToolTip({ className, text, toolTipId })}
    >
    {  
      ({ ...props }) => (
        <button 
          aria-describedby={toolTipId} 
          aria-label={ariaLabel} 
          {...props}
        >
          {children}
          <div id={toolTipId} className='toolTipAccessibilityContent'>{toolTipAccessibilityContent}</div>
        </button>
      )
    }
    </OverlayTrigger>
  );
};

ToolTip.defaultProps = defaultProps;

export default ToolTip;

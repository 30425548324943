import React from 'react';
import cx from 'classnames';

import Text from '../../atoms/Text';

import styles from './PriceItem.module.scss';
import usePresenter from './PriceItem.presenter';
import getProps from './PriceItem.props';
import { PriceItemCombinedProps } from './types';


const PriceItem: React.FC<PriceItemCombinedProps> = (props) => {
  const {
    style,
    className,
    classes,
    item,
    price,
  } = usePresenter(props);

  const variantName = `${style}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`priceItem${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Dark':
    case 'Light': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Text
            className={cx(styles.item, classes?.item)}
            {...internalProps.item}
            {...item}/>
          <Text
            className={cx(styles.price, classes?.price)}
            {...internalProps.price}
            {...price}/>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default PriceItem;


import { TextProps } from '../../atoms/Text';
import { ButtonPairListProps } from '../../molecules/ButtonPairList';
import { SearchFieldProps } from '../../molecules/SearchField';


type SearchContainerInternalProps = {
  searchField?: SearchFieldProps;
  label?: TextProps;
  buttonPairList?: ButtonPairListProps;
};

type SearchContainerPropsValues = {
  'Default': SearchContainerInternalProps;
  'ShowRecentSearches': SearchContainerInternalProps;
};

const propValues: SearchContainerPropsValues = {
  'Default': {
    searchField: {
      type: 'SearchLine',
    },
  },
  'ShowRecentSearches': {
    searchField: {
      type: 'SearchLine',
    },
    label: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'BaseLight',
      align: 'Left',
    },
    buttonPairList: {
    },
  },
};

const getProps = (type: string): SearchContainerInternalProps => {
  const values: SearchContainerInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

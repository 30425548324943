import axios, { AxiosRequestConfig } from 'axios';
import { useQuery, UseQueryResult } from 'react-query';

interface UseQueryHookParams<InitialDataType> {
  endpoint: string
  params?: AxiosRequestConfig['params']
  cacheKey?: string
  initialFetchDisabled?: boolean
  initialData?: InitialDataType
  enabled?: boolean
}

const useQueryHook = <DataType>({
  endpoint,
  params,
  cacheKey,
  initialFetchDisabled,
  initialData,
  enabled = true,
}: UseQueryHookParams<DataType>): {
    error?: string | null
    data?: DataType | undefined
    fetching: boolean
    loadingOrFetching: boolean
    refetch: () => Promise<UseQueryResult>
  } => {

  const fn = async (): Promise<DataType> => {
    const res = await axios.get<DataType>(endpoint, { params });
    return res.data;
  };

  const { data, isLoading, isFetching, error, refetch } = useQuery<DataType, string & Error>(
    [cacheKey || endpoint, params, initialFetchDisabled],
    () => fn(),
    { enabled, initialData, refetchOnMount: false, retryDelay: 0, retry: false, keepPreviousData: true },
  );
  return {
    data,
    error: error?.message ? error.message : typeof error === 'string' ? error : null,
    fetching: isLoading,
    loadingOrFetching: isLoading || isFetching,
    refetch,
  };
};

export default useQueryHook;

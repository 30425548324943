import React, { useContext, useEffect } from 'react';
import {
  RouteProps,
  Route,
  useLocation,
  useHistory,
  Redirect,
} from 'react-router-dom';
import Cookie from 'js-cookie';
import { AuthContext } from '.';
import { UnauthorizedRedirect } from './AuthContext';
import ErrorHandler from '../error';
import { useAnalyticsManager } from '../analytics/useAnalyticsManager';
import { displayMaintenanceWindow } from '../../lib/util';

const CheckUserAccess: React.FC<RouteProps> = (props) => {
  const { account, loading, error, verifyAccessToken } = useContext(AuthContext);
  const { search, pathname } = useLocation();
  const query = new URLSearchParams(search);
  const assertion = query.get('assertion');
  const code = query.get('code');
  const lastFour = query.get('lastFour');
  const history = useHistory();
  const redirectUrl = Cookie.get('redirect_url');

  // tracking marketing visit
  const { trackEvent } = useAnalyticsManager();
  const utmSource = query.get('utm_source');
  const utmTerm = query.get('utm_term');
  const utmMedium = query.get('utm_medium');
  const utmCampaign = query.get('utm_campaign');
  const utmTarget = query.get('utm_target');
  const utmAdgroupId = query.get('utm_adgroup_id');
  const utmKeywordId = query.get('utm_keyword_id');
  const utmContent = query.get('utm_content');

  useEffect(() => {
    if (utmSource) {
      trackEvent('marketing_visit', {
        utm: {
          medium: utmMedium,
          source: utmSource,
          campaign: utmCampaign,
          term: utmTerm,
          adgroupId: utmAdgroupId,
          target: utmTarget,
          keywordId: utmKeywordId,
          content: utmContent,
        },
      });
    }
  }, [utmSource]);

  useEffect(() => {
    if (code && !loading) {
      verifyAccessToken({ access_token: code, token_type: 'code' });
      trackEvent('login');
      history.replace({});
    }
    if (assertion && !loading) {
      verifyAccessToken({ access_token: assertion, last_four: lastFour });
      trackEvent('login');
      query.delete('assertion');
      history.replace({
        search: query.toString(),
      });
    }
  }, [assertion, lastFour, loading, verifyAccessToken, history, code]);

  const inMaintenance = displayMaintenanceWindow();

  if (loading || assertion || code) {
    return <Route {...props} />;
  }
  if (pathname === '/help') {
    return <Route {...props} />;
  }
  if (error) {
    return <ErrorHandler isAuthError={true} error={error} />;
  }
  if (!account && !inMaintenance) {
    return <UnauthorizedRedirect redirectUrl={window.location.href} />;
  }
  if (redirectUrl) {
    Cookie.remove('redirect_url');
    window.location.assign(redirectUrl);
    return null;
  }
  return <Route {...props} />;
};


const ProtectedRoute: React.FC<RouteProps> = (props) => {
  return <CheckUserAccess {...props} />;
};

export default ProtectedRoute;

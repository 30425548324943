import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import Text from '../../atoms/Text';

import styles from './NavMenuItem.module.scss';
import usePresenter from './NavMenuItem.presenter';
import getProps from './NavMenuItem.props';
import { NavMenuItemCombinedProps } from './types';
import { useHistory } from 'react-router-dom';
import { getClickAccessibilityProps } from '../../organisms/TopNav/utils';


const NavMenuItem: React.FC<NavMenuItemCombinedProps> = (props) => {
  const {
    type,
    className,
    classes,
    backButton,
    title,
    nextButton,
    // MANUAL OVERRIDE STARTS
    onClick,
    linkPath,
    newTab,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const variantName = `${type}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`navMenuItem${variantName}`];

  let componentView;

  const history = useHistory();
  const clickAction = () => {
    if (linkPath) {
      if (newTab) {
        window.open(linkPath, '_blank');
      } else {
        history.push(linkPath);
      }
    }
    onClick?.();
  };
  const accessibilityProps = getClickAccessibilityProps({
    onClick: clickAction,
  }, true, 'menuitem');

  switch (variantName) {
    case 'Back': {
      componentView = (
        <div className={cx(currentStyle, className)}
          onClick={clickAction}
          {...accessibilityProps}
        >
          <div className={cx(styles.content, classes?.content)}>
            <Button
              className={cx(styles.backButton, classes?.backButton)}
              {...internalProps.backButton}
              {...backButton}/>
            <Text
              className={cx(styles.title, classes?.title)}
              {...internalProps.title}
              {...title}/>
          </div>
        </div>
      );
      break;
    }
    case 'Critical':
    case 'Default': {
      componentView = (
        <div className={cx(currentStyle, className)}          
          onClick={clickAction}
          {...accessibilityProps}
        >
          <div className={cx(styles.content, classes?.content)}>
            <Text
              className={cx(styles.title, classes?.title)}
              {...internalProps.title}
              {...title}/>
          </div>
        </div>
      );
      break;
    }
    case 'Next': {
      componentView = (
        <div className={cx(currentStyle, className)}
          onClick={clickAction}
          {...accessibilityProps}
        >
          <div className={cx(styles.content, classes?.content)}>
            <Text
              className={cx(styles.title, classes?.title)}
              {...internalProps.title}
              {...title}/>
            <Button
              className={cx(styles.nextButton, classes?.nextButton)}
              {...internalProps.nextButton}
              {...nextButton}/>
          </div>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default NavMenuItem;


import React from 'react';
import cx from 'classnames';
import Dropdown from 'react-bootstrap/Dropdown';

import Button from '../../atoms/Button';
import Text from '../../atoms/Text';
import Logo from '../../atoms/Logo';
import AccountMenu from '../../molecules/AccountMenu';
import SearchField from '../../molecules/SearchField';
import NavMenuList from '../../organisms/NavMenuList';

import customStyles from './Custom.module.scss';
import styles from './TopNav.module.scss';
import usePresenter from './TopNav.presenter';
import getProps from './TopNav.props';
import { TopNavCombinedProps } from './types';
import { SEARCH_ICON_ON_TAB_BAR, getClickAccessibilityProps } from './util';
import SearchBlock from '../../blocks/SearchBlock';
import EventDetailsModal from '../EventDetailsModal';


const TopNav: React.FC<TopNavCombinedProps> = (props) => {
  const {
    type,
    className,
    classes,
    logo,
    accountMenu,
    searchButton,
    backButton,
    title,
    button,
    description,
    searchField,
    navMenuList,
    // MANUAL OVERRIDE STARTS
    eventDetailsModal,
    searchBlock,
    openDropdownId,
    onTabHover,
    onTabFocus,
    onSearchIconFocus,
    onTabLeave,
    onKeyPress,
    data,
    onSearchIconHover,
    onSearchIconLeave,
    showEventInfoButton,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const variantName = `${type}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`topNav${variantName}`];

  // for use in pages other than homepage
  const accessibilityProps = getClickAccessibilityProps({
    onClick: onKeyPress,
    onEsc: onSearchIconLeave,
  });

  const searchButtonView = (
    <>
      <Dropdown
        className={customStyles.dropdown}
        onMouseOver={onSearchIconHover}
        onMouseLeave={onSearchIconLeave}
        onKeyUp={(e) =>
          onSearchIconFocus && onSearchIconFocus(SEARCH_ICON_ON_TAB_BAR)
        }
        show={openDropdownId === SEARCH_ICON_ON_TAB_BAR}
        {...accessibilityProps}
      >
        <Dropdown.Toggle>
          <Button
            className={cx(styles.searchButton, classes?.searchButton)}
            {...internalProps.searchButton}
            {...searchButton}/>
        </Dropdown.Toggle>
        {openDropdownId === SEARCH_ICON_ON_TAB_BAR ? (
          <Dropdown.Menu className={customStyles.dropdownMenu} align="right">
            <SearchBlock {...searchBlock} />
          </Dropdown.Menu>
        ) : null}
      </Dropdown>

      {/* Hover dropdown for desktop view */}
      <Dropdown
        className={customStyles.desktopDropdown}
        onMouseOver={onSearchIconHover}
        onMouseLeave={onSearchIconLeave}
        onKeyUp={(e) =>
          onSearchIconFocus && onSearchIconFocus(SEARCH_ICON_ON_TAB_BAR)
        }
        show={openDropdownId === SEARCH_ICON_ON_TAB_BAR}
        {...accessibilityProps}
      >
        <Dropdown.Toggle>
          <Button
            className={cx(styles.searchButton, classes?.searchButton)}
            {...internalProps.searchButton}
            {...searchButton}/>
        </Dropdown.Toggle>
        {openDropdownId === SEARCH_ICON_ON_TAB_BAR ? (
          <Dropdown.Menu className={customStyles.dropdownMenu} align="right">
            <SearchBlock {...searchBlock} />
          </Dropdown.Menu>
        ) : null}
      </Dropdown>
    </>
  );

  let componentView;

  switch (variantName) {
    // MANUAL OVERRIDE - separate case for Checkout
    case 'Checkout': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.logoContainer, classes?.logoContainer)}>
            <Logo
              className={cx(styles.logo, classes?.logo)}
              {...internalProps.logo}
              {...logo}/>
          </div>
          <AccountMenu
            className={cx(styles.accountMenu, classes?.accountMenu)}
            {...internalProps.accountMenu}
            {...accountMenu}/>
        </div>
      );
      break;
    }
    case 'WithoutSearchInNav': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.logoContainer, classes?.logoContainer)}>
            <Logo
              className={cx(styles.logo, classes?.logo)}
              {...internalProps.logo}
              {...logo}/>
          </div>
          {/* MANUAL OVERRIDE STARTS */}
          <NavMenuList
            className={styles.navMenuList}
            {...internalProps.navMenuList}
            {...navMenuList}
            data={data}
            openDropdownId={openDropdownId}
            onTabHover={onTabHover}
            onTabLeave={onTabLeave}
            onTabFocus={onTabFocus}
            onKeyPress={onKeyPress}
          />
          {/* MANUAL OVERRIDE ENDS */}
          <AccountMenu
            className={cx(styles.accountMenu, classes?.accountMenu)}
            {...internalProps.accountMenu}
            {...accountMenu}/>
        </div>
      );
      break;
    }
    case 'SearchInNav': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.logoContainer, classes?.logoContainer)}>
            <Logo
              className={cx(styles.logo, classes?.logo)}
              {...internalProps.logo}
              {...logo}/>
          </div>
          {/* MANUAL OVERRIDE STARTS */}
          <NavMenuList
            className={styles.navMenuList}
            {...internalProps.navMenuList}
            {...navMenuList}
            data={data}
            openDropdownId={openDropdownId}
            onTabHover={onTabHover}
            onTabLeave={onTabLeave}
            onTabFocus={onTabFocus}
            onKeyPress={onKeyPress}
          />
          {/* MANUAL OVERRIDE ENDS */}
          {searchButtonView}
          <AccountMenu
            className={cx(styles.accountMenu, classes?.accountMenu)}
            {...internalProps.accountMenu}
            {...accountMenu}/>
        </div>
      );
      break;
    }
    case 'SignedOut': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.logoContainer, classes?.logoContainer)}>
            <Logo
              className={cx(styles.logo, classes?.logo)}
              {...internalProps.logo}
              {...logo}/>
          </div>
        </div>
      );
      break;
    }
    case 'TicketSeatSelection': {
      // MANUAL OVERRIDE -  To hide the event details modal button when event with map has no event description
      const infoButtonView = showEventInfoButton ? (
        <Button
          className={cx(styles.button, classes?.button)}
          {...internalProps.button}
          {...button}/>
      ) : null;
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.logoContainer, classes?.logoContainer)}>
            <Logo
              className={cx(styles.logo, classes?.logo)}
              {...internalProps.logo}
              {...logo}/>
          </div>
          <Button
            className={cx(styles.backButton, classes?.backButton)}
            {...internalProps.backButton}
            {...backButton}/>
          <div className={cx(styles.content, classes?.content)}>
            <div className={cx(styles.topTitle, classes?.topTitle)}>
              <Text
                className={cx(styles.title, classes?.title)}
                {...internalProps.title}
                {...title}/>
              {/* MANUAL OVERRIDE - conditional rendering of info button */}
              {infoButtonView}
            </div>
            <Text
              className={cx(styles.description, classes?.description)}
              {...internalProps.description}
              {...description}/>
          </div>
          {/* MANUAL OVERRIDE - Removed search field */}
          {/* <SearchField
            className={cx(styles.searchField, classes?.searchField)}
            {...internalProps.searchField}
            {...searchField}/> */}
          <AccountMenu
            className={cx(styles.accountMenu, classes?.accountMenu)}
            {...internalProps.accountMenu}
            {...accountMenu}/>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return (
    <>
      {componentView}
      <EventDetailsModal {...eventDetailsModal} />
    </>
  );
};

export default TopNav;


import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import { EventPageProps } from '.';

import { getEvent } from '../../../modules/partnership/api';
import useRecentlyViewedEvents from '../../../modules/localstorage/useRecentlyViewEvents';
import { ListingsContext } from '../../../modules/listings';
import { TopNavContext } from '../../../modules/topNav';
import { AuthContext } from '../../../modules/auth';
import { CP1_EXCLUSIVE_TAG, GUEST_LIST_EVENT } from '../../../lib/constants';
import { checkSoldOut } from '../../../lib/util';
import { EventPagePresenterProps } from './types';

const useInteractor = (props: EventPageProps): EventPagePresenterProps => {
  const { eventId } = useParams<{ eventId: string }>();
  const { account } = useContext(AuthContext);
  const eventData = useQuery(['getEvent', eventId], () => getEvent(eventId));
  const { setCurrentEvent, setHasNoEventMap, setHasEventGuestList, setHasEventDescription, setIsSoldOutEvent } = useContext(TopNavContext);
  const { selectors: listingsSelectors, isLoading: isContextLoading } = useContext(ListingsContext);

  const hasNoInteractiveMap = !listingsSelectors.mapJsonFileName;
  const isExclusive = eventData.data?.tags?.includes(CP1_EXCLUSIVE_TAG);
  const isGuestList = eventData.data?.tags?.includes(GUEST_LIST_EVENT);
  const isSoldOut = eventData.data && checkSoldOut(eventData.data);
  const hasNoEventMap = !!((isExclusive && hasNoInteractiveMap) || (isExclusive && isSoldOut));
  useEffect(() => {
    if (eventData && eventData.data && setCurrentEvent) {
      setCurrentEvent(eventData.data);
    }
    return () => {
      if (setCurrentEvent) {
        setCurrentEvent(undefined);
      }
    };
  }, [eventData, eventData.data, setCurrentEvent]);
  if (setHasNoEventMap) {
    setHasNoEventMap(hasNoEventMap);
  }
  if (setHasEventGuestList) {
    setHasEventGuestList(isGuestList);
  }
  if (setHasEventDescription) {
    setHasEventDescription(eventData?.data?.supplemental_data?.event_description);
  }
  if (setIsSoldOutEvent) {
    setIsSoldOutEvent(isSoldOut);
  }    
  const { addRecentEvent } = useRecentlyViewedEvents();

  return {
    ...props,
    account,
    isLoading: eventData.isLoading,
    isContextLoading,
    eventId,
    event: eventData.data,
    addRecentEvent,
    showGeneralAdmissionPage: (isGuestList && isExclusive) || isSoldOut,
  };
};

export default useInteractor;
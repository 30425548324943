import { ButtonProps } from '../../atoms/Button';
import { TextProps } from '../../atoms/Text';
import { SliderFilterProps } from '../../molecules/SliderFilter';
import { TicketQuantityProps } from '../../molecules/TicketQuantity';
import { ToggleSettingProps } from '../../molecules/ToggleSetting';


type FilterSettingInternalProps = {
  label?: TextProps;
  button?: ButtonProps;
  ticketQuantity?: TicketQuantityProps;
  sliderFilter?: SliderFilterProps;
  toggleSetting?: ToggleSettingProps;
};

type FilterSettingPropsValues = {
  '': FilterSettingInternalProps;
};

const propValues: FilterSettingPropsValues = {
  '': {
    label: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    button: {
      type: 'Icon',
      style: 'Text',
      size: 'Small',
    },
    ticketQuantity: {
    },
    sliderFilter: {
    },
    toggleSetting: {
    },
  },
};

const getProps = (type: string): FilterSettingInternalProps => {
  const values: FilterSettingInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

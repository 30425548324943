import { DividerProps } from '../../atoms/Divider';
import { SportsMenuTabListProps } from '../../molecules/SportsMenuTabList';
import { SubmenuItemListProps } from '../../molecules/SubmenuItemList';


type SubmenuWithDoubleTabsInternalProps = {
  divider?: DividerProps;
  sportsMenuTabList?: SportsMenuTabListProps;
  dividerOne?: DividerProps;
  sportsMenuTabList1?: SportsMenuTabListProps;
  dividerTwo?: DividerProps;
  submenuItemListOne?: SubmenuItemListProps;
  submenuItemListTwo?: SubmenuItemListProps;
  divider1?: DividerProps;
};

type SubmenuWithDoubleTabsPropsValues = {
  '': SubmenuWithDoubleTabsInternalProps;
};

const propValues: SubmenuWithDoubleTabsPropsValues = {
  '': {
    divider: {
      type: 'Horizontal',
      style: 'Thin',
      colour: 'White',
    },
    sportsMenuTabList: {
    },
    dividerOne: {
      type: 'Vertical',
      style: 'Thin',
      colour: 'DigitalGrey5',
    },
    sportsMenuTabList1: {
    },
    dividerTwo: {
      type: 'Vertical',
      style: 'Thin',
      colour: 'DigitalGrey5',
    },
    submenuItemListOne: {
    },
    submenuItemListTwo: {
    },
    divider1: {
      type: 'Horizontal',
      style: 'Thin',
      colour: 'White',
    },
  },
};

const getProps = (type: string): SubmenuWithDoubleTabsInternalProps => {
  const values: SubmenuWithDoubleTabsInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

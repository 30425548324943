import { useEffect, useRef, useState } from 'react';
import { QUERY_DATE_FROM, QUERY_DATE_TO } from '../../../lib/constants';
import { addQueryParam, formatDateToApiDate } from '../../../lib/util';
import { ButtonCombinedProps } from '../../atoms/Button/types';
import { DropdownMenuItemCombinedProps } from '../../atoms/DropdownMenuItem/types';
import { TextCombinedProps } from '../../atoms/Text/types';
import { DropdownOption, FilterCombinedProps, SelectedOption } from './types';
import { formatStartEndDateOption } from './utils';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const usePresenter = (props: FilterCombinedProps): FilterCombinedProps => {
  const {
    filterType,
    filterTitle,
    filterName,
    defaultOption,
    options,
    onTextChange,
    filterIcon,
    defaultSelectedOption,
    type,
  } = props;
  const history = useHistory();
  const { search } = useLocation();
  const { t } = useTranslation();
  const isInputFocused = useRef(false);
  const query = new URLSearchParams(search);
  // filter value based on query property name
  const filterNameValue = filterName ? query.get(filterName) : undefined;
  // filter date filter values based on query property names
  const dateFrom = query.get(QUERY_DATE_FROM);
  const dateTo = query.get(QUERY_DATE_TO);

  const [showDropdown, setShowDropdown] = useState(false);
  const [showTextInput, setShowTextInput] = useState(false);
  const [selectedOption, setSelectedOption] = useState<
  SelectedOption | undefined
  >(defaultOption);
  const [selectedSearchOption, setSelectedSearchOption] = useState<
  SelectedOption | undefined
  >();
  const [selectedDateOption, setSelectedDateOption] = useState<
  SelectedOption | undefined
  >();

  useEffect(() => {
    if (options) {
      let currentOption: DropdownOption | undefined;
      if (filterType === 'DateFilter') {
        if (dateFrom && dateTo) {
          currentOption = options.find((option) => {
            if (option.value?.startDate && option.value.endDate) {
              return (
                formatDateToApiDate(option.value?.startDate) === dateFrom &&
                formatDateToApiDate(option.value?.endDate) === dateTo
              );
            }
            return false;
          });

          if (!currentOption) {
            const startDate = new Date(dateFrom.replace(/-/g, '/')); //https://stackoverflow.com/questions/4310953/invalid-date-in-safari
            const endDate = new Date(dateTo.replace(/-/g, '/')); //https://stackoverflow.com/questions/4310953/invalid-date-in-safari
            currentOption = {
              type: 'SelectValue',
              title: formatStartEndDateOption(startDate, endDate),
              value: {
                startDate,
                endDate,
              },
            };
            setSelectedDateOption(currentOption);
          }
        }
      } else if (filterType === 'SearchLocationFilter') {
        setSelectedSearchOption(defaultSelectedOption);
      } else {
        currentOption = options.find((option) => {
          if (option.value?.value) {
            return option.value?.value === filterNameValue;
          }
          return false;
        });
      }
      setSelectedOption(currentOption || defaultOption);
    }
  }, [defaultOption, filterType, filterNameValue, options, dateFrom, dateTo]);

  const updateSelectedOption = (option: SelectedOption) => {
    setSelectedOption(option);
    setShowDropdown(false);

    if (option.value) {
      const { value, startDate, endDate } = option.value;
      let params;
      if (filterType === 'DateFilter') {
        params = addQueryParam(history.location.search, {
          [QUERY_DATE_FROM]: startDate ? formatDateToApiDate(startDate) : '',
          [QUERY_DATE_TO]: endDate ? formatDateToApiDate(endDate) : '',
        });
      } else {
        if (filterName) {
          params = addQueryParam(history.location.search, {
            [filterName]: value || '',
          });
        }
      }

      if (params) {
        history.push({
          search: params,
        });
      }
    }
  };

  const handleUpdateDateRange = (dates: Date[]) => {
    const [startDate, endDate] = dates;
    if (startDate && endDate) {
      const newOption: SelectedOption = {
        title: formatStartEndDateOption(startDate, endDate),
        value: {
          startDate,
          endDate,
        },
      };
      setSelectedDateOption(newOption);
      updateSelectedOption(newOption);
    }
  };

  const showHideTextInput = (show: boolean) => {
    if (show) {
      setShowTextInput(true);
    } else {
      setShowTextInput(false);
      if (onTextChange) {
        onTextChange('');
      }
    }
  };

  const handleToggle = (isOpen: boolean) => {
    setShowDropdown(isOpen);
    updateSelectedOption({
      ...selectedDateOption,
      title: defaultOption?.title || '',
      value: defaultOption?.value,
      showCalendar: false,
    });
    if (!isOpen && !isInputFocused.current) {
      setShowTextInput(false);
    }
  };

  const dropdownMenuItems: DropdownMenuItemCombinedProps[] = [];
  if (options) {
    const combinedOptions = [...options];
    if (selectedDateOption) {
      combinedOptions.push({
        type: 'SelectValue',
        title: selectedDateOption.title,
        value: selectedDateOption.value,
      });
    }
    combinedOptions.forEach((option) => {
      // don't render the SearchInput
      if (option.type === 'SearchInput') {
        return;
      }
      const optionSelected =
        option.title ===
        (selectedSearchOption?.title || selectedOption?.title);
      dropdownMenuItems.push({
        type: optionSelected ? 'IconText' : 'Text',
        text: {
          value: option.dropdownTitle || option.title,
          colour: optionSelected ? 'ActionHover' : 'BaseDark',
        },
        ariaLabel: optionSelected
          ? t('shopByWidget.selectedOptionAnnouncement', {
            option: option.title,
          })
          : '',
        onDropdownMenuItemClicked: () => {
          if (option.type === 'PickDate') {
            setSelectedOption({
              title: option.title,
              showCalendar: true,
            });
          } else if (option.type === 'SearchResult') {
            showHideTextInput(false);
            const resultOption = {
              title: option.title,
              value: option.value,
            };
            setSelectedSearchOption(resultOption);
            updateSelectedOption(resultOption);
          } else {
            if (
              dateFrom &&
              dateTo &&
              filterType === 'DateFilter' &&
              option.value?.startDate &&
              option.value.endDate
            ) {
              if (
                dateFrom !== formatDateToApiDate(option.value?.startDate) &&
                dateTo !== formatDateToApiDate(option.value?.endDate)
              ) {
                setSelectedDateOption(undefined);
              }
            } else {
              setSelectedDateOption(undefined);
            }
            setSelectedSearchOption(undefined);
            showHideTextInput(false);
            updateSelectedOption({
              title: option.title,
              value: option.value,
            });
          }
        },
      });
    });
  }
  const onTextInputChange = ({ target: { value } }) => {
    if (onTextChange) {
      onTextChange(value);
    }
  };

  const nonDefaultOption =
    (selectedSearchOption?.title || selectedOption?.title) !==
    defaultOption?.title;

  const dropdownButton: ButtonCombinedProps | undefined = !nonDefaultOption
    ? {
      type: 'Icon',
      style: 'Contained',
      size: 'Small',
      icon: {
        asset: 'ChevronDown',
        style:
            type !== 'TextIconFilter'
              ? 'ActionActive'
              : 'DigitalGrey80',
      },
      onClick: () => {
        if (filterType === 'SearchLocationFilter') {
          showHideTextInput(true);
        }
        setShowDropdown(true);
      },
    }
    : undefined;

  const closeButton: ButtonCombinedProps | undefined = nonDefaultOption
    ? {
      type: 'Icon',
      style: 'Text',
      size: 'Small',
      icon: {
        asset: 'CloseCircle',
        style:
            props.type !== 'TextIconFilter'
              ? 'ActionHover'
              : 'DigitalGrey100',
      },
      onClick: () => {
        updateSelectedOption({
          title: defaultOption?.title || '',
          value: defaultOption?.value,
          showCalendar: false,
        });
        setSelectedSearchOption(undefined);
        setShowDropdown(false);
      },
    }
    : undefined;

  // ?? TextIconFilter are using different variants which is not what figma shows
  const textProps: TextCombinedProps =
    props.type !== 'TextIconFilter'
      ? {
        type: 'Body',
        size: 'Large',
        style: 'Regular',
        colour: 'ActionHover',
        align: 'Left',
      }
      : {
        type: 'Subheading',
        size: 'Medium',
        style: 'Regular',
        colour: 'SubduedDark',
        align: 'Left',
      };

  const buttonTextProps: TextCombinedProps =
    props.type !== 'TextIconFilter'
      ? {
        type: 'Decoration',
        size: 'Medium',
        style: 'Regular',
        colour: 'ActionHover',
        align: 'Left',
      }
      : {
        type: 'Decoration',
        size: 'Large',
        style: 'Regular',
        colour: 'SubduedDark',
        align: 'Left',
      };

  return {
    ...props,
    icon: {
      asset: filterIcon,
    },
    type: props.type,
    showDropdown: showDropdown,
    showCalendar: selectedOption?.showCalendar,
    showTextInput: showTextInput,
    text: {
      ...textProps,
      value: filterTitle,
    },
    textInput: {
      state: 'Filled',
      style: 'Default',
      size: 'Small',
      textPlaceholder: t('search.searchLocations'),
      onTextChanged: onTextInputChange,
      onFocusChanged: () => (isInputFocused.current = true),
      onBlurChanged: () => (isInputFocused.current = false),
      button: {
        icon: {
          asset: 'CloseCircle',
          style: 'ActionActive',
        },
        onClick: () => {
          setShowDropdown(false);
          showHideTextInput(false);
        },
      },
    },
    filterButton: {
      text: {
        ...buttonTextProps,
        value: selectedSearchOption?.title || selectedOption?.title,
      },
      onClick: () => {
        if (filterType === 'SearchLocationFilter') {
          showHideTextInput(true);
        }
        setShowDropdown(true);
      },
    },
    dropdownButton: dropdownButton,
    closeButton: closeButton,
    dropdownSelectList: {
      dropdownMenuItems: dropdownMenuItems,
    },
    handleToggle: handleToggle,
    setRange: handleUpdateDateRange,
  };
};

export default usePresenter;

import React from 'react';
import cx from 'classnames';
import { Modal } from 'react-bootstrap';

import Divider from '../../atoms/Divider';
import AccountMenu from '../../molecules/AccountMenu';
import NavMenuList from '../../organisms/NavMenuList';

import styles from './AccountMenuBlock.module.scss';
import customStyles from './Custom.module.scss';
import usePresenter from './AccountMenuBlock.presenter';
import getProps from './AccountMenuBlock.props';
import { AccountMenuBlockCombinedProps } from './types';
import NavMenuItem from '../../molecules/NavMenuItem';


const AccountMenuBlock: React.FC<AccountMenuBlockCombinedProps> = (props) => {
  const {
    accountMenu,
    className,
    classes,
    navMenuList,
    divider,
    profileMenuList,
    // MANUAL OVERRIDE STARTS
    isModalOpen,
    isSelectedNavMenuItem,
    logOut,
    myTickets,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.accountMenuBlock;

  // MANUAL OVERRIDE STARTS
  const bottomNavMenuListView = !isSelectedNavMenuItem ? (
    <>
      <Divider
        className={cx(styles.divider, classes?.divider)}
        {...internalProps.divider}
        {...divider} />
      <div className={cx(styles.profileMenuList, classes?.profileMenuList)} role='menu'>
        <NavMenuItem
          className={styles.myTickets}
          {...myTickets} />
        <NavMenuItem
          className={styles.logOut}
          {...logOut} />
      </div>
    </>
  ) : null;
  return (
    <Modal className={cx(styles.accountMenuBlock, customStyles.accountMenuBlock, className)}
      dialogClassName={customStyles.modalDialog}
      contentClassName={customStyles.modalContent}
      show={isModalOpen}
    >
      <Modal.Header className={customStyles.modalHeader}>
        <div className={cx(styles.topContent, classes?.topContent)}>
          <AccountMenu
            className={cx(styles.accountMenu, classes?.accountMenu)}
            {...accountMenu}
            renderType='Element' />
        </div>
      </Modal.Header>
      <Modal.Body className={customStyles.modalBody}>
        <NavMenuList
          className={cx(styles.navMenuList, classes?.navMenuList)}
          {...navMenuList} />
        {bottomNavMenuListView}
      </Modal.Body>
      <Modal.Footer className={customStyles.modalFooter}>
      </Modal.Footer>
    </Modal>
  );
  // MANUAL OVERRIDE ENDS
};

export default AccountMenuBlock;


import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from './Spinner.module.scss';

export type Style = 'light' | 'dark';

export type SpinnerProps = {
  className?: string;
  style?: Style;
};

const Spinner: React.FC<SpinnerProps> = ({
  className,
  style = 'dark',
}) => {
  const { t } = useTranslation();
  return (
    <div className={cx(styles.container, className)} >
      <div role="alert" aria-atomic="true" className={style === 'light' ? styles.spinnerLight : styles.spinner }>
        <span className={styles.hideLoadingTextFromScreen}>{t('loading.loadingMessageForScreenReader')}</span>
      </div>
    </div>
  );
};

export default Spinner;

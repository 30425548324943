import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import Text from '../../atoms/Text';
import ButtonPairList from '../../molecules/ButtonPairList';
import SearchField from '../../molecules/SearchField';

import styles from './SearchModalHeader.module.scss';
import usePresenter from './SearchModalHeader.presenter';
import getProps from './SearchModalHeader.props';
import { SearchModalHeaderCombinedProps } from './types';


const SearchModalHeader: React.FC<SearchModalHeaderCombinedProps> = (props) => {
  const {
    state,
    className,
    classes,
    closeButton,
    searchField,
    label,
    buttonPairList,
  } = usePresenter(props);

  const variantName = `${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`searchModalHeader${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Default': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Button
            className={cx(styles.closeButton, classes?.closeButton)}
            {...internalProps.closeButton}
            {...closeButton}/>
          <SearchField
            className={cx(styles.searchField, classes?.searchField)}
            {...internalProps.searchField}
            {...searchField}/>
        </div>
      );
      break;
    }
    case 'ShowRecentSearches': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.topContent, classes?.topContent)}>
            <Button
              className={cx(styles.closeButton, classes?.closeButton)}
              {...internalProps.closeButton}
              {...closeButton}/>
            <SearchField
              className={cx(styles.searchField, classes?.searchField)}
              {...internalProps.searchField}
              {...searchField}/>
          </div>
          <div className={cx(styles.bottomContent, classes?.bottomContent)}>
            <Text
              className={cx(styles.label, classes?.label)}
              {...internalProps.label}
              {...label}/>
            <ButtonPairList
              className={cx(styles.buttonPairList, classes?.buttonPairList)}
              {...internalProps.buttonPairList}
              {...buttonPairList}/>
          </div>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default SearchModalHeader;


import React, { RefObject } from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import { useSnapItem } from '../../../modules/snap/SnapListContext/useSnapItem';

import Icon from '../../atoms/Icon';
import Image from '../../atoms/Image';
import Text from '../../atoms/Text';

import styles from './PerformerCard.module.scss';
import usePresenter from './PerformerCard.presenter';
import getProps from './PerformerCard.props';
import { PerformerCardCombinedProps } from './types';


const PerformerCard: React.FC<PerformerCardCombinedProps> = (props) => {
  const {
    style,
    state,
    className,
    classes,
    disabled,
    buttonType,
    buttonForm,
    href,
    target,
    externalLink,
    onClick,
    image,
    titleText,
    icon,
    // MANUAL OVERRIDE START
    index,
    ariaLabel,
    // MANUAL OVERRIDE END
  } = usePresenter(props);

  // MANUAL OVERRIDE STARTS
  const { elementRef } = useSnapItem(index);
  // MANUAL OVERRIDE ENDS

  const variantName = `${style}${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`performerCard${variantName}`];


  let componentView;

  switch (variantName) {
    case 'Dark':
    case 'Light':
    case 'DarkDefault':
    case 'LightDefault': {
      componentView = (
        // MANUAL OVERRIDE - replaced button with Link
        <Link
          ref={elementRef as RefObject<HTMLAnchorElement>} //eslint-disable-line @typescript-eslint/no-unnecessary-type-assertion
          data-item="true"
          title={ariaLabel}
          to={{ pathname: href || '', state: { canGoBack: true } }}
          onClick={onClick}
          className={cx(currentStyle, className)}>
          <div className={cx(styles.topContent, classes?.topContent)}>
            <Image
              className={cx(styles.image, classes?.image)}
              {...internalProps.image}
              {...image}/>
          </div>
          <div className={cx(styles.bottomContent, classes?.bottomContent)}>
            <div className={cx(styles.content, classes?.content)}>
              <Text
                className={cx(styles.titleText, classes?.titleText)}
                {...internalProps.titleText}
                {...titleText}/>
              <Icon
                className={cx(styles.icon, classes?.icon)}
                {...internalProps.icon}
                {...icon}/>
            </div>
          </div>
        </Link>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default PerformerCard;


import React, { useLayoutEffect, useMemo, useRef } from 'react';
import cx from 'classnames';

import Card from '../../molecules/Card';

import styles from './EventsCardList.module.scss';
import usePresenter from './EventsCardList.presenter';
import getProps from './EventsCardList.props';
import { EventsCardListCombinedProps } from './types';


const EventsCardList: React.FC<EventsCardListCombinedProps> = (props) => {
  const {
    className,
    classes,
    cards,
    shouldUpdateScroll, // MANUAL OVERRIDE
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.eventsCardList;

  // MANUAL OVERRIDE STARTS
  const containerRef = useRef<HTMLDivElement>(null);

  const scrollTo = useMemo(() => {
    if (shouldUpdateScroll) {
      const nodeElements =
        containerRef.current?.querySelectorAll('[data-item]');
      if (nodeElements) {
        return nodeElements[nodeElements.length - 1];
      }
    }
    return undefined;
  }, [shouldUpdateScroll]);

  useLayoutEffect(() => {
    if (scrollTo) {
      // Scroll to element with should be in view after rendering
      scrollTo.scrollIntoView();
    }
  }, [scrollTo, cards]);
  // MANUAL OVERRIDE ENDS

  const cardViews = cards?.map((card, index) => (
    <Card
      key={index}
      className={cx(styles.card, classes?.card)}
      {...internalProps.card}
      {...card} />
  )) || [];

  return (
    <div ref={containerRef} className={cx(currentStyle, className)}>
      {cardViews}
    </div>
  );
};

export default EventsCardList;


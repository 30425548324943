import { ImageProps } from '../../atoms/Image';
import { ButtonProps } from '../../atoms/Button';
import { SliderButtonCombinedProps } from '../../atoms/SliderButton/types';
import { SliderInfoProps } from '../../atoms/SliderInfo';
import { EventsEmptyStateProps } from '../../molecules/EventsEmptyState';
import { BlockHeaderProps } from '../../organisms/BlockHeader';
import { CardSpecificEventsListProps } from '../../organisms/CardSpecificEventsList';
import { EventCardDarkListProps } from '../../organisms/EventCardDarkList';
import { EventCardLightListProps } from '../../organisms/EventCardLightList';
import { EventCardNoImageListProps } from '../../organisms/EventCardNoImageList';
import { EventCardVenueListProps } from '../../organisms/EventCardVenueList';
import { PerformerCardListProps } from '../../organisms/PerformerCardList';


type EventCardsBlockInternalProps = {
  blockHeader?: BlockHeaderProps;
  cardSpecificEventsList?: CardSpecificEventsListProps;
  sliderInfo?: SliderInfoProps;
  image?: ImageProps;
  eventCardDarkList?: EventCardDarkListProps;
  eventsEmptyState?: EventsEmptyStateProps;
  eventCardLightList?: EventCardLightListProps;
  eventCardNoImageList?: EventCardNoImageListProps;
  showMoreButton?: ButtonProps;
  eventCardVenueList?: EventCardVenueListProps;
  performerCardList?: PerformerCardListProps;
  slideBackwardButton?: SliderButtonCombinedProps;
  slideForwardButton?: SliderButtonCombinedProps;
};

type EventCardsBlockPropsValues = {
  'CardSpecificEventsDarkBlockDefault': EventCardsBlockInternalProps;
  'CardSpecificEventsDarkBlockEmpty': EventCardsBlockInternalProps;
  'CardSpecificEventsDarkBlockLoading': EventCardsBlockInternalProps;
  'EventsDarkBlockDefault': EventCardsBlockInternalProps;
  'EventsDarkBlockEmpty': EventCardsBlockInternalProps;
  'EventsDarkBlockLoading': EventCardsBlockInternalProps;
  'EventsLightBlockDefault': EventCardsBlockInternalProps;
  'EventsLightBlockEmpty': EventCardsBlockInternalProps;
  'EventsLightBlockLoading': EventCardsBlockInternalProps;
  'EventsNoImageBlockDefault': EventCardsBlockInternalProps;
  'EventsNoImageBlockEmpty': EventCardsBlockInternalProps;
  'EventsNoImageBlockLoading': EventCardsBlockInternalProps;
  'EventsVenueBlockDefault': EventCardsBlockInternalProps;
  'EventsVenueBlockEmpty': EventCardsBlockInternalProps;
  'EventsVenueBlockLoading': EventCardsBlockInternalProps;
  'PerformersLightBlockDefault': EventCardsBlockInternalProps;
  'PerformersLightBlockEmpty': EventCardsBlockInternalProps;
  'PerformersLightBlockLoading': EventCardsBlockInternalProps;
};

const propValues: EventCardsBlockPropsValues = {
  'CardSpecificEventsDarkBlockDefault': {
    blockHeader: {
      style: 'Dark',
      type: 'NoButton',
    },
    cardSpecificEventsList: {
    },
    sliderInfo: {
      type: 'Dark',
    },
    // MANUAL OVERRIDE STARTS
    slideBackwardButton: {
      style: 'Light',
      type: 'Prev',
      icon: {
        asset: 'ChevronLeft',
        style: 'ActionBase',
      },
    },
    slideForwardButton: {
      style: 'Light',
      type: 'Next',
      icon: {
        asset: 'ChevronRight',
        style: 'ActionBase',
      },
    },
    // MANUAL OVERRIDE ENDS
  },
  // MANUAL OVERRIDE STARTS
  'CardSpecificEventsDarkBlockEmpty': {
    blockHeader: {
      style: 'Dark',
      type: 'NoButton',
    },
    eventsEmptyState: {
      style: 'Dark',
    },
  },
  // MANUAL OVERRIDE ENDS
  'CardSpecificEventsDarkBlockLoading': {
    blockHeader: {
      style: 'Dark',
      type: 'NoButton',
    },
    image: {
    },
  },
  'EventsDarkBlockDefault': {
    blockHeader: {
      style: 'Dark',
      type: 'NoButton',
    },
    eventCardDarkList: {
    },
    sliderInfo: {
      type: 'Dark',
    },
    // MANUAL OVERRIDE STARTS
    slideBackwardButton: {
      style: 'Light',
      type: 'Prev',
      icon: {
        asset: 'ChevronLeft',
        style: 'ActionBase',
      },
    },
    slideForwardButton: {
      style: 'Light',
      type: 'Next',
      icon: {
        asset: 'ChevronRight',
        style: 'ActionBase',
      },
    },
    // MANUAL OVERRIDE ENDS
  },
  'EventsDarkBlockEmpty': {
    blockHeader: {
      style: 'Dark',
      type: 'NoButton',
    },
    eventsEmptyState: {
      style: 'Dark',
    },
  },
  'EventsDarkBlockLoading': {
    blockHeader: {
      style: 'Dark',
      type: 'NoButton',
    },
    image: {
    },
  },
  'EventsLightBlockDefault': {
    blockHeader: {
      style: 'Light',
      type: 'WithToolTip',
    },
    eventCardLightList: {
    },
    sliderInfo: {
      type: 'Light',
    },
    // MANUAL OVERRIDE STARTS
    slideBackwardButton: {
      style: 'Light',
      type: 'Prev',
      icon: {
        asset: 'ChevronLeft',
        style: 'ActionBase',
      },
    },
    slideForwardButton: {
      style: 'Light',
      type: 'Next',
      icon: {
        asset: 'ChevronRight',
        style: 'ActionBase',
      },
    },
    // MANUAL OVERRIDE ENDS
  },
  'EventsLightBlockEmpty': {
    blockHeader: {
      style: 'Light',
      type: 'WithToolTip',
    },
    eventsEmptyState: {
      style: 'Light',
    },
  },
  'EventsLightBlockLoading': {
    blockHeader: {
      style: 'Light',
      type: 'WithToolTip',
    },
    image: {
    },
  },
  'EventsNoImageBlockDefault': {
    eventCardNoImageList: {
    },
    showMoreButton: {
      type: 'Text',
      style: 'Text',
      size: 'Small',
    },
    // MANUAL OVERRIDE STARTS
    slideBackwardButton: {
      style: 'Light',
      type: 'Prev',
      icon: {
        asset: 'ChevronLeft',
        style: 'ActionBase',
      },
    },
    slideForwardButton: {
      style: 'Light',
      type: 'Next',
      icon: {
        asset: 'ChevronRight',
        style: 'ActionBase',
      },
    },
    // MANUAL OVERRIDE ENDS
  },
  'EventsNoImageBlockEmpty': {
    eventsEmptyState: {
      style: 'Light',
    },
  },
  'EventsNoImageBlockLoading': {
    image: {
    },
  },
  'EventsVenueBlockDefault': {
    eventCardVenueList: {
    },
    showMoreButton: {
      type: 'Text',
      style: 'Text',
      size: 'Small',
    },
  },
  'EventsVenueBlockEmpty': {
    eventsEmptyState: {
      style: 'Light',
    },
  },
  'EventsVenueBlockLoading': {
    image: {
    },
  },
  'PerformersLightBlockDefault': {
    blockHeader: {
      style: 'Light',
      type: 'WithToolTip',
    },
    performerCardList: {
    },
    sliderInfo: {
      type: 'Light',
    },
    // MANUAL OVERRIDE STARTS
    slideBackwardButton: {
      style: 'Light',
      type: 'Prev',
      icon: {
        asset: 'ChevronLeft',
        style: 'ActionBase',
      },
    },
    slideForwardButton: {
      style: 'Light',
      type: 'Next',
      icon: {
        asset: 'ChevronRight',
        style: 'ActionBase',
      },
    },
    // MANUAL OVERRIDE ENDS
  },
  'PerformersLightBlockEmpty': {
    blockHeader: {
      style: 'Light',
      type: 'WithToolTip',
    },
    eventsEmptyState: {
      style: 'LightPerformer',
    },
  },
  'PerformersLightBlockLoading': {
    blockHeader: {
      style: 'Light',
      type: 'WithToolTip',
    },
    image: {
    },
  },
};

const getProps = (type: string): EventCardsBlockInternalProps => {
  const values: EventCardsBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import { TextProps } from '../../atoms/Text';
import { CXEventInfoHeaderProps } from '../../molecules/CXEventInfoHeader';
import { TicketListProps } from '../../organisms/TicketList';


type TicketTypeSelectionBlockInternalProps = {
  text?: TextProps;
  ticketList?: TicketListProps;
  cXEventInfoHeader?: CXEventInfoHeaderProps;
  description?: TextProps;
};

type TicketTypeSelectionBlockPropsValues = {
  '': TicketTypeSelectionBlockInternalProps;
};

const propValues: TicketTypeSelectionBlockPropsValues = {
  '': {
    text: {
      type: 'Subheading',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseLight',
      align: 'Left',
    },
    ticketList: {
      style: 'WithSpace',
    },
    cXEventInfoHeader: {
    },
    description: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'Charcoal10',
      align: 'Left',
    },
  },
};

const getProps = (type: string): TicketTypeSelectionBlockInternalProps => {
  const values: TicketTypeSelectionBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

import { RecentSearch } from '../localstorage/useRecentSearches';
import { Event, Performer, Venue } from '../partnership';
import { MobileMenuItem, MobileMenuItemTypeType, TopNavTab } from './types';

export const displayLeagues = (data: TopNavTab[]): MobileMenuItem[] => {
  const sportsObject = data.find((category) => category.id === 'sports');
  const list: MobileMenuItem[] = [];
  sportsObject?.children.forEach((child) =>
    list.push({
      type: 'league',
      title: child.name,
      id: child.slug,
    } as MobileMenuItem),
  );
  return list;
};

export const generateSportsMenuItem = (): MobileMenuItem => ({
  type: 'category',
  id: 'sports',
  title: 'Sports',
});

export const getInitialItems = (data: TopNavTab[]): MobileMenuItem[] => {
  return data.map((category) => {
    return {
      type: 'category',
      title: category.name,
      id: category.id,
    };
  });
};
export const getListOfCategoryOptions = (
  data: TopNavTab[],
  id: string,
): MobileMenuItem[] => {
  const cat = data.find((category) => category.id === id);
  if (!cat) return [];
  const list: MobileMenuItem[] = cat?.children.map((child) => {
    return {
      type: id === 'sports' ? 'league' : 'item',
      title: child.name,
      id: child?.slug || child?.url || '',
    };
  });
  return list;
};

export const getListOfTeams = (
  data: TopNavTab[],
  id: string,
): MobileMenuItem[] | undefined => {
  const sportsObject = data.find((category) => category.id === 'sports');
  const leagueObject = sportsObject?.children.find(
    (league) => league.slug === id,
  );
  return (
    leagueObject &&
    leagueObject?.children &&
    leagueObject.children.map((team) => ({
      type: 'item',
      title: team.name,
      id: team.slug,
    }))
  );
};

export const populateWithChildren = (
  data: TopNavTab[],
  type: MobileMenuItemTypeType,
  id: string,
): MobileMenuItem[] | undefined => {
  switch (type) {
    case 'category': {
      return getListOfCategoryOptions(data, id);
    }
    case 'league': {
      return getListOfTeams(data, id);
    }
    default:
      break;
  }
  return undefined;
};

export const getItemIfMatch = (
  text: string,
  results: Venue[] | Performer[] | Event[] | undefined,
): Venue | Performer | Event | undefined => {
  type SearchResult = { id: number; name: string };
  const searchResult: SearchResult[] | undefined = results;
  const lowercaseText = text.toLowerCase();
  const result = searchResult?.find(
    (element) => element.name.toLowerCase() === lowercaseText,
  );
  return result;
};
import React from 'react';
import cx from 'classnames';

import styles from './EventPage.module.scss';
import TicketSelectionPage from '../../pages/TicketSelectionPage';
import SeatSelectionPageLegacy from '../../../legacy/pages/SeatSelectionPage';
import Spinner from '../../atoms/Spinner';

import { EventPageProps } from '.';
import usePresenter from './EventPage.presenter';

const EventPage: React.FC<EventPageProps> = (props) => {
  const {
    className,
    event,
    isLoading,
    showGeneralAdmissionPage,
    isContextLoading,
  } = usePresenter(props);

  if (isLoading || isContextLoading) {
    return <Spinner />;
  }
  const seatSelection = (
    <SeatSelectionPageLegacy className={styles.seatSelectionPage}
    event={event} 
    />
  );
  const ticketSelection = (
    <TicketSelectionPage className={cx(styles.ticketSelectionPage, className)}
    event={event} 
    />
  );

  return showGeneralAdmissionPage ? ticketSelection : seatSelection;
};

export default EventPage;

import { ButtonProps } from '../../atoms/Button';
import { IconProps } from '../../atoms/Icon';
import { TextProps } from '../../atoms/Text';


type AlertModalInternalProps = {
  closeButton?: ButtonProps;
  icon?: IconProps;
  title?: TextProps;
  message?: TextProps;
  secondaryButton?: ButtonProps;
  primaryButton?: ButtonProps;
};

type AlertModalPropsValues = {
  '': AlertModalInternalProps;
};

const propValues: AlertModalPropsValues = {
  '': {
    closeButton: {
      type: 'Icon',
      style: 'Text',
      size: 'Small',
    },
    icon: {
      asset: 'Warning',
      style: 'DigitalGrey80',
    },
    title: {
      type: 'Heading',
      size: 'Small',
      style: 'Light',
      colour: 'BaseDark',
      align: 'Left',
    },
    message: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
    secondaryButton: {
      type: 'Text',
      style: 'Text',
      size: 'Medium',
    },
    primaryButton: {
      type: 'Text',
      style: 'ContainedGreen',
      size: 'Large',
    },
  },
};

const getProps = (type: string): AlertModalInternalProps => {
  const values: AlertModalInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;

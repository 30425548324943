import React from 'react';
import cx from 'classnames';

import Text from '../../atoms/Text';
import EventDateStatus from '../EventDateStatus';

import styles from './InfoDetails.module.scss';
import usePresenter from './InfoDetails.presenter';
import getProps from './InfoDetails.props';
import { InfoDetailsCombinedProps } from './types';


const InfoDetails: React.FC<InfoDetailsCombinedProps> = (props) => {
  const {
    className,
    classes,
    title,
    eventDateStatus,
    venue,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.infoDetails;

  return (
    <div className={cx(currentStyle, className)}>
      <Text
        className={cx(styles.title, classes?.title)}
        {...internalProps.title}
        {...title}/>
      <div className={cx(styles.eventLogistics, classes?.eventLogistics)}>
        <EventDateStatus
          className={cx(styles.eventDateStatus, classes?.eventDateStatus)}
          {...internalProps.eventDateStatus}
          {...eventDateStatus}/>
        <Text
          className={cx(styles.venue, classes?.venue)}
          {...internalProps.venue}
          {...venue}/>
      </div>
    </div>
  );
};

export default InfoDetails;


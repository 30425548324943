import React from 'react';
import cx from 'classnames';


import styles from './TeamLogo.module.scss';
import usePresenter from './TeamLogo.presenter';
import getProps from './TeamLogo.props';
import { TeamLogoCombinedProps } from './types';


const TeamLogo: React.FC<TeamLogoCombinedProps> = (props) => {
  const {
    className,
    classes,
    teamInitials,
    backgroundColour, // MANUAL OVERRIDE
    textColour, // MANUAL OVERRIDE
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.teamLogo;

  return (
    <div aria-hidden="true" className={cx(currentStyle, className)} style={{ background: `${backgroundColour}` }}>
      <p className={cx(styles.teamInitials, classes?.teamInitials)} style={{ color: `${textColour}` }}>
        {teamInitials}
      </p>
    </div>
  );
};

export default TeamLogo;


import React from 'react';
import cx from 'classnames';

import Divider from '../../atoms/Divider';
import Icon from '../../atoms/Icon';
import Text from '../../atoms/Text';

import styles from './SearchCategories.module.scss';
import usePresenter from './SearchCategories.presenter';
import getProps from './SearchCategories.props';
import { SearchCategoriesCombinedProps } from './types';


const SearchCategories: React.FC<SearchCategoriesCombinedProps> = (props) => {
  const {
    type,
    className,
    classes,
    icon,
    title,
    divider,
  } = usePresenter(props);

  const variantName = `${type}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`searchCategories${variantName}`];

  let componentView;

  switch (variantName) {
    case 'DividerBottom': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.content, classes?.content)}>
            <Icon
              className={cx(styles.icon, classes?.icon)}
              {...internalProps.icon}
              {...icon}/>
            <Text
              className={cx(styles.title, classes?.title)}
              {...internalProps.title}
              {...title}/>
          </div>
          <Divider
            className={cx(styles.divider, classes?.divider)}
            {...internalProps.divider}
            {...divider}/>
        </div>
      );
      break;
    }
    case 'DividerTop': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Divider
            className={cx(styles.divider, classes?.divider)}
            {...internalProps.divider}
            {...divider}/>
          <div className={cx(styles.content, classes?.content)}>
            <Icon
              className={cx(styles.icon, classes?.icon)}
              {...internalProps.icon}
              {...icon}/>
            <Text
              className={cx(styles.title, classes?.title)}
              {...internalProps.title}
              {...title}/>
          </div>
        </div>
      );
      break;
    }
    case 'DividerTopWhite': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Divider
            className={cx(styles.divider, classes?.divider)}
            {...internalProps.divider}
            {...divider}/>
          <div className={cx(styles.content, classes?.content)}>
            <div className={cx(styles.info, classes?.info)}>
              <Text
                className={cx(styles.title, classes?.title)}
                {...internalProps.title}
                {...title}/>
            </div>
          </div>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default SearchCategories;


import { useEffect, useState } from 'react';
import { FAQItemCombinedProps } from './types';

const usePresenter = (props: FAQItemCombinedProps): FAQItemCombinedProps => {
  const [expand, setExpand] = useState(false);
  const onClick = () => {
    setExpand((p) => !p);
  };
  
  useEffect(() => {
    if (props.shouldCollapseAll) {
      setExpand(false);
    }
  }, [props.shouldCollapseAll]);

  const blockProps: FAQItemCombinedProps = {
    ...props,
    state: expand ? 'Expanded' : 'Collapsed',
    icon: {
      asset: expand ? 'ChevronUp' : 'ChevronDown',
    },
    onClick: onClick,
  };
  return blockProps;
};

export default usePresenter;

import React from 'react';
import cx from 'classnames';

import RadioItem from '../../molecules/RadioItem';

import styles from './RewardOptionsList.module.scss';
import usePresenter from './RewardOptionsList.presenter';
import getProps from './RewardOptionsList.props';
import { RewardOptionsListCombinedProps } from './types';


const RewardOptionsList: React.FC<RewardOptionsListCombinedProps> = (props) => {
  const {
    className,
    classes,
    radioItems,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.rewardOptionsList;

  const radioItemViews = radioItems?.map((radioItem, index) => (
    <RadioItem
      key={index}
      className={cx(styles.radioItem, classes?.radioItem)}
      {...internalProps.radioItem}
      {...radioItem} />
  )) || [];

  return (
    <div className={cx(currentStyle, className)}>
      {radioItemViews}
    </div>
  );
};

export default RewardOptionsList;


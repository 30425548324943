import React from 'react';
import cx from 'classnames';

import ContactUsItem from '../../molecules/ContactUsItem';

import styles from './ContactUs.module.scss';
import usePresenter from './ContactUs.presenter';
import getProps from './ContactUs.props';
import { ContactUsCombinedProps } from './types';


const ContactUs: React.FC<ContactUsCombinedProps> = (props) => {
  const {
    className,
    classes,
    contactUsItem,
    // MANUAL OVERRIDE STARTS
    vividEmail,
    vividChat,
    vividPhone,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.contactUs;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.content, classes?.content)}>
        <div className={cx(styles.leftcontent, classes?.leftcontent)}>
          <ContactUsItem
            className={cx(styles.contactUsItem, classes?.contactUsItem)}
            {...internalProps.contactUsItem}
            {...vividEmail}/>
          <ContactUsItem
            className={cx(styles.contactUsItem1, classes?.contactUsItem1)}
            {...internalProps.contactUsItem1}
            {...vividChat}/>
        </div>
        <div className={cx(styles.rightContent, classes?.rightContent)}>
          <ContactUsItem
            className={cx(styles.contactUsItem, classes?.contactUsItem)}
            {...internalProps.contactUsItem}
            {...vividPhone}/>
          <ContactUsItem
            className={cx(styles.contactUsItem1, classes?.contactUsItem1)}
            {...internalProps.contactUsItem1}
            {...contactUsItem}/>
        </div>
        <div className={styles.mobilecontent}>
          <ContactUsItem
            className={cx(styles.contactUsItem, classes?.contactUsItem)}
            {...internalProps.contactUsItem}
            {...vividEmail}/>
          <ContactUsItem
            className={cx(styles.contactUsItem, classes?.contactUsItem)}
            {...internalProps.contactUsItem}
            {...vividPhone}/>
          <ContactUsItem
            className={cx(styles.contactUsItem1, classes?.contactUsItem1)}
            {...internalProps.contactUsItem1}
            {...vividChat}/>
          <ContactUsItem
            className={cx(styles.contactUsItem1, classes?.contactUsItem1)}
            {...internalProps.contactUsItem1}
            {...contactUsItem}/>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;


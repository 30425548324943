import React, { useMemo } from 'react';
import cx from 'classnames';
import VirtualScroll from 'react-dynamic-virtual-scroll';

import TicketInformation from '../../molecules/TicketInformation';

import styles from './TicketList.module.scss';
import usePresenter from './TicketList.presenter';
import getProps from './TicketList.props';
import { TicketListCombinedProps } from './types';


const TicketList: React.FC<TicketListCombinedProps> = (props) => {
  const {
    className,
    classes,
    ticketInformations,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.ticketList;

  // MANUAL OVERRIDE STARTS
  return useMemo(
    () => (
      <VirtualScroll
        className={cx(currentStyle, className)}
        minItemHeight={40}
        buffer={10}
        length={10}
        totalLength={ticketInformations?.length}
        renderItem={(rowIndex) => (
          <TicketInformation
            key={rowIndex}
            className={cx(styles.ticketInformation, classes?.ticketInformation)}
            {...internalProps.ticketInformation}
            {...ticketInformations?.[rowIndex]} />
        )}
      />
    ),
    [ticketInformations],
  );
  // MANUAL OVERRIDE ENDS
};

export default TicketList;


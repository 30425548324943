import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import Image from '../../atoms/Image';
import Text from '../../atoms/Text';

import styles from './ErrorBlock.module.scss';
import usePresenter from './ErrorBlock.presenter';
import getProps from './ErrorBlock.props';
import { ErrorBlockCombinedProps } from './types';


const ErrorBlock: React.FC<ErrorBlockCombinedProps> = (props) => {
  const {
    className,
    classes,
    image,
    title,
    message,
    // MANUAL OVERRIDE STARTS
    button,
    signOutButton,
    showSignOutButton,
    showMessage = true, // only need to hide for 2 specific error messages
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.errorBlock;

  // MANUAL OVERRIDE STARTS
  const signOutButtonView = showSignOutButton ? (
    <Button
      className={cx(styles.button, classes?.signOutButton)}
      {...internalProps.signOutButton}
      {...signOutButton}/>
  ) : null;

  const messageView = showMessage ? (
    <Text
      className={cx(styles.message, classes?.message)}
      {...internalProps.message}
      {...message}/>
  ) : null;
  // MANUAL OVERRIDE ENDS

  return (
    <div className={cx(currentStyle, className)}>
      <Image
        className={cx(styles.image, classes?.image)}
        {...internalProps.image}
        {...image}/>
      <div className={cx(styles.content, classes?.content)}>
        <Text
          className={cx(styles.title, classes?.title)}
          {...internalProps.title}
          {...title}/>
        {/* MANUAL OVERRIDE */}
        {messageView}
      </div>
      <Button
        className={cx(styles.button, classes?.button)}
        {...internalProps.button}
        {...button}/>
      {/* MANUAL OVERRIDE */}
      {signOutButtonView}
    </div>
  );
};

export default ErrorBlock;
